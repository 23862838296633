import {
  DialogContent,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import defaultAvatar from '../../../../../../assets/images/defaultAvatar.png'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogWidth: {
    maxWidth: '700px',
  },
}))

export default function AddEditPartnerAdmin(props) {
  const classes = useStyles()

  const { open = '', handleReset, title = '' } = props
  //Schema
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required(' First Name is required'),
    lastName: Yup.string()
      .trim()
      .required('Last Name is required'),
    email: Yup.string()
      .trim()
      .required('Email line 2 is required'),
  })

  //state
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
  }

  const userTypeList = [
    { value: 'TW Admin', label: 'TW Admin' },
    { value: 'Partner Admin', label: 'Partner Admin' },
    { value: 'Org Admin', label: 'Org Admin' },
    { value: 'General', label: 'General' },
  ]

  const handleLogin = (formData) => {}
  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const { firstName = '', lastName = '', email = '' } = values
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item sm={12} lg={3} md={3}>
                <TextField
                  id="name-helper-text"
                  label="First Name"
                  value={firstName}
                  onChange={handleChange}
                  fullWidth
                  name="firstName"
                  error={errors.firstName && touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ''
                  }
                />
              </Grid>
              <Grid item sm={12} lg={3} md={3}>
                <TextField
                  id="name-helper-text"
                  label="Last Name"
                  value={lastName}
                  onChange={handleChange}
                  fullWidth
                  name="lastName"
                  error={errors.lastName && touched.lastName}
                  helperText={
                    errors.lastName && touched.lastName ? errors.lastName : ''
                  }
                />
              </Grid>
              <Grid item sm={12} lg={3} md={3}>
                <TextField
                  id="name-helper-text"
                  label="Email"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  name="email"
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                />
              </Grid>

              <Grid item xs={3}>
                <InputLabel id="order">User Type</InputLabel>
                <Select
                  labelId="order"
                  id="order"
                  name="order"
                  //value={order}
                  onChange={handleChange}
                  fullWidth
                  label="order"
                  //error={errors.order && touched.order}
                  //helperText={
                  // errors.order && touched.order ? errors.order : ""
                  //}
                >
                  {/* {orderList.map(rec=> <MenuItem value={rec.zOrderID}>{rec.zName}</MenuItem>)} */}
                  {userTypeList.map((rec) => (
                    <MenuItem value={rec.value}>{rec.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={3}>
                <InputLabel id="order">Status</InputLabel>
                <Select
                  labelId="order"
                  id="order"
                  name="order"
                  //value={order}
                  onChange={handleChange}
                  fullWidth
                  label="order"
                  //error={errors.order && touched.order}
                  //helperText={
                  // errors.order && touched.order ? errors.order : ""
                  //}
                >
                  {/* {orderList.map(rec=> <MenuItem value={rec.zOrderID}>{rec.zName}</MenuItem>)} */}
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value={'inactive'}>InActive</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}></Grid>
              {/* <Grid item xs={3}> */}
              <Grid item sm={12} lg={3} md={3}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}>
                  {title == 'Add Partner Admin' ? 'Send Invite' : 'Save'}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>

        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
