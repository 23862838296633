import React, { useEffect, useState } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  InputLabel,
  FormLabel,
  Select,
  MenuItem,
  Accordion,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../common/CustomButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import SnoozeIcon from '@material-ui/icons/Snooze'
import AlarmIcon from '@material-ui/icons/AddAlarm'
import { InputAdornment } from '@material-ui/core'
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers'
import {
  LAUNCH_360_PROJECT_URL,
  GET_USER_RELATIONSHIP_LIST,
  GET_BYPASSROSTER_DETAILS,
} from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import { useSnackbar } from 'notistack'
import { CustomMessage } from '../../../../../function/HelperFunction'
import { useHistory, useParams } from 'react-router'
import {
  getUserId,
  getOrganizationId,
  authHeader,
} from '../../../../common/CustomFunction'
import { useLayoutEffect } from 'react'
import CustomLoader from '../../../../common/CustomLoader'
import { SAVE_CURRENT_STEP } from '../../../../../redux/action/ActionType'
import { useDispatch } from 'react-redux'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  grid: {
    padding: '6px',

    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  boxBorder: {
    borderRight: '2px solid #eee',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: '140',
  },
  launchBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function LaunchProject(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isRosterupload, setisRosterupload] = useState(false)
  const [clearedDate, handleClearedDateChange] = useState(null)
  const [selectedDate, handleDateChange] = useState(
    new Date('2019-01-01T18:54')
  )
  const [launch, setLaunch] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const previousProjectLocal = localStorage.getItem('PREVIOUS_PROJECT')
  console.log(previousProjectLocal, 'prepreviousProjectLocal')
  const { id = '' } = useParams()
  console.log('id-->', id)
  const [getLaunchProject, setGetLaunchproject] = useState([])
  const [loading, setLoading] = useState(false)
  const handleLaunch = async () => {
    setLaunch(true)
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')
    const options = {
      method: 'GET',
      headers: authHeader(),

      url: LAUNCH_360_PROJECT_URL,
      params: {
        ProjectID: parseInt(id),
        UserID: getUserId(),
        OrganizationID: getOrganizationId(),
        zCustomerFlag: true,
        testEmail: null,
        SurveyLinkURL: '' + window.location.origin + '/tasks/view/',
        //http://localhost:3000/tasks/view/23135?taskId=347901
        //https://app.cantoo.us/tasks/view/23135?taskId=347901
      },
    }
    // API(options).then(response=> {
    //    if(response.status === 200) {
    //        history.push("/projects");
    //      CustomMessage(
    //       'Successfull Project Launch',
    //       'success',
    //       enqueueSnackbar
    //     ) //call toster
    //    } else if(response.status === "Error") {

    //    }
    // }).catch(err=> {
    //   if(err.response){
    //     alert.error(err.response.data.message)
    //   }
    // })
    try {
      const response = await API(options)
      if (response.status === 200) {
        // history.push(`/projects`)
        history.push(`/projects/view/${id}`)
        dispatch({ type: SAVE_CURRENT_STEP, payload: 0 })
        CustomMessage('Successful Project Launch', 'success', enqueueSnackbar) //call toster
      }
    } catch (error) {
      history.push(`/projects`)
      // history.push(`/projects/view/${id}`)
      CustomMessage('Successful Project Launch', 'success', enqueueSnackbar) //call toster
      /* if (error.response) {
        console.error('errrorrrr', error.response)
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
        setLaunch(false)
      }*/
    }
  }

  const checnkRoster = async () => {
    setLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_USER_RELATIONSHIP_LIST,
      params: {
        ProjectID: parseInt(id),
      },
    }
    try {
      const responst = await API(config)
      const { data } = responst.data
      console.log(data)
      if (data.length) {
        console.log(data.length)
        setisRosterupload(true)
      } else {
        console.log(data.length)

        setisRosterupload(false)
      }
      setLoading(false)
    } catch (er) {
      console.error(er)
    }
  }

  const getBypassRosterDetails = async () => {
    setLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: GET_BYPASSROSTER_DETAILS,
      params: {
        ProjectID: parseInt(id),
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        setGetLaunchproject(data)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    checnkRoster()
    getBypassRosterDetails()
    if (previousProjectLocal) {
      console.log('string', isRosterupload)
    }
  }, [previousProjectLocal])
  console.log(
    !isRosterupload,
    !getLaunchProject[0]?.zByPassRoster,
    'getLaunchProject'
  )

  return (
    <Grid item xs={12}>
      <CustomLoader loading={loading ? true : false} />
      <div>
        <Paper className={classes.paper}>
          {/* Table tools */}
          <Toolbar className={classes.root}>
            <Typography
              className={classes.tblHead}
              variant="h6"
              id="tableTitle"
              component="div">
              Launch Project
            </Typography>
          </Toolbar>
          <Divider />
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            {loading ? (
              ''
            ) : previousProjectLocal ? (
              <Grid
                item
                xs={12}
                className={classes.boxBorder}
                justifyContent="center">
                <p
                  style={{
                    textAlign: 'center',
                    fontWeight: '600',
                    fontSize: '24px',
                    marginBottom: '0px',
                  }}>
                  {isRosterupload || getLaunchProject[0]?.zByPassRoster
                    ? 'Your Project is Ready to Launch!'
                    : 'Warning: A roster is required in order to launch the project. Please add a roster'}
                </p>
                {getLaunchProject[0]?.zByPassRoster ? (
                  <p style={{ textAlign: 'center' }}>
                    {' '}
                    You selected to bypass the roster for this project.
                    <br />
                    Add participants and send invitations from the project
                    dashboard.
                  </p>
                ) : (
                  ' '
                )}
                <div className={classes.launchBtnContainer}>
                  <CustomButton
                    colour="primaryButton"
                    disabled={
                      !getLaunchProject[0]?.zByPassRoster && !isRosterupload
                    }
                    onClick={handleLaunch}>
                    Launch Now
                  </CustomButton>
                </div>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                className={classes.boxBorder}
                justifyContent="center">
                <p style={{ textAlign: 'center', fontWeight: '600' }}>
                  {isRosterupload
                    ? 'Your Project is Ready to Launch!'
                    : 'Warning: A roster is required in order to launch the project. Please add a roster'}
                </p>
                <div className={classes.launchBtnContainer}>
                  <CustomButton
                    colour="primaryButton"
                    disabled={launch || !isRosterupload}
                    onClick={handleLaunch}>
                    Launch Now
                  </CustomButton>
                </div>
              </Grid>
            )}
            {/* <Grid item xs={6}>
              <center>
                <p style={{fontWeight:"600"}}>Schedule launch date</p>
                <CustomButton className={classes.dateBtn} type="submit">
                  Set Launch Date
                </CustomButton>
                <div style={{ marginTop: "2rem" }}>
                  <KeyboardDateTimePicker
                    value={selectedDate}
                    variant="inline"
                    onChange={handleDateChange}
                    label="Launch date"
                    onError={console.log}
                    minDate={new Date("2018-01-01T00:00")}
                    format="MM/DD/yyyy hh:mm a"
                  />
                </div>
              </center>
            </Grid> */}
          </Grid>
        </Paper>
      </div>
    </Grid>
  )
}
