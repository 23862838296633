import { Divider, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { API } from '../../helpers/API'
import { TEAM_REPORT } from '../../helpers/constants/ApiRoutes'
import Image from '../../helpers/favicon.jpg'
import logo from '../../assets/images/logo.png'
import { authHeader } from '../common/CustomFunction'
import ActionPopup from './ActionPopup'
import { object } from 'underscore'
import { Box } from '@mui/material'
import { color } from '@material-ui/system'
const useStyles = makeStyles((theme) => ({
  container: {
    // height: '200px',
  },
  logoBlock: {
    width: '200px',
    height: 'auto',
    marginBottom: '1.5rem',
    objectFit: 'contain',
  },
}))
function TeamReport() {
  const { data } = useParams()
  const classes = useStyles()
  // eyJQcm9qZWN0SUQiOiIyNDQxMCIsIlVzZXJJRCI6IjIxMDA1MyJ9
  const [reportData, setreportData] = useState({})
  const [aflag, setActionFlag] = useState(false)
  const [title, setTitle] = useState('')
  const [actionText, setActionText] = useState('')
  let newdata = {
    ProjectID: 24410,
    UserID: 212653,
  }

  const getReportData = async () => {
    const config = {
      method: 'POST',
      // headers: authHeader(),

      url: TEAM_REPORT,
      data: {
        ...JSON.parse(Buffer.from(data, 'base64').toString('binary')),
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        setreportData(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getReportData()
  }, [])
  const handleReset = () => {
    setActionFlag(false)
  }
  let reportGroup = {}
  let tempNAme = ''
  let textNumber = 1
  let textNumbers = 1
  reportData?.dimensionPage?.forEach((v) => {
    console.log('text v', v, reportData)
    if (v?.dimensionName) {
      tempNAme = v?.dimensionName
      reportGroup[tempNAme] = {
        name: v.dimensionName,
        ...v,
      }
    }
    if (v.Text) {
      if (v.Text.startsWith('Meaning')) {
        reportGroup[tempNAme] = {
          ...(reportGroup[tempNAme] || {}),
          ['textmean' + textNumbers]: v?.Text,
        }
        textNumbers++
      }
      console.log('vtext', v.Text.startsWith('Meaning'))
      reportGroup[tempNAme] = {
        ...(reportGroup[tempNAme] || {}),
        ['text' + textNumber]: v?.Text,
      }
      textNumber++
      console.log('vtext one', reportGroup)
    }
    if (v?.length != 0) {
      textNumber = 1
      textNumbers = 1
      reportGroup[tempNAme] = {
        ...(reportGroup[tempNAme] || {}),
        reportdata: v,
      }
    }
  })
  console.log('report data final', reportGroup)
  const border = {
    borderRadius: '12px',
    background: '#FFFFFF',
    boxShadow: '5px 5px 13px #c5c5c5,-5px -5px 13px #fbfbfb',
    padding: '13px 0px',

    height: 'fit-content',
    width: '58rem',
    margin: '1rem 0px',
  }
  const borderfi = {
    borderRadius: '12px',
    background: '#FFFFFF',
    boxShadow: '5px 5px 13px #c5c5c5,-5px -5px 13px #fbfbfb',
    padding: '0px 0px',

    height: 'fit-content',
    width: '58rem',
    margin: '1rem 0px',
  }
  let sclaesize = 6

  let scaleSize = useMemo(() => {
    return reportData?.scalingInformation?.length
  }, [reportData?.scalingInformation])

  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.hiddensplitspan_one_${c}:before {
  content: '' !important;
  position: absolute;
  height: 30px;
  top:25%;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 120}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.hiddensplitspan_two_${c}:before {
  content: '' !important;
  position: absolute;
  top:25%;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 120}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })
  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.hiddensplitspan_four_${c}:before {
  content: '' !important;
  position: absolute;
  height: 30px;
  top:25%;
  width: 2px;
  background: #BDBDBD;
  left: ${(12 / sclaesize) * 10}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })
  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.hiddensplitspan_three_${c}:before {
  content: '' !important;
  position: absolute;
  height: 30px;
  top:25%;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 120}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })
  let widthSiz = 600
  let tmp = [
    { sval: 0, count: 0 },
    { sval: 1, count: 1 },
    { sval: 2, count: 0 },
    { sval: 3, count: 1 },
    { sval: 4, count: 0 },
    { sval: 5, count: 1 },
  ]
  const BarGraph = (props) => {
    const {
      value = 100,
      color = '#21D59B',
      precent = 300,
      chartWidth = 300,
      iid,
    } = props

    return (
      <>
        <svg
          class="chart"
          style={{ width: chartWidth, zIndex: 9999 }}
          height="17">
          <g transform="translate(0,0)">
            <rect
              style={{ fill: '#0062FF' }}
              width={chartWidth}
              height="10"></rect>
            <rect style={{ fill: color }} width={precent} height="10"></rect>

            <text
              style={{
                fill: 'white',
                font: '10px sans-serif',
                'text-anchor': 'end',
              }}
              x="47"
              y="9.5"
              dy=".35em"></text>
          </g>
        </svg>

        {/* {iid&&iid.map((r,i)=>{
        return(
          <text
          className={`hiddensplitspan_two_${i}`}>{r.zUserCount}</text>

        )
      })} */}
      </>
    )
  }
  const precentage = (roundPercent) => {
    return `${roundPercent},100`
    // return roundDraw + ' 999'
  }
  console.log('pre', scaleSize)
  let precentda = (reportData?.OverallTeamEffectiveness / scaleSize) * 100
  const actionGuideFun = (e) => {
    console.log('trget', e.currentTarget.dataset.title)
    setActionFlag(true)
    setTitle(e.currentTarget.dataset.title)
    setActionText(e.currentTarget.dataset.text)
  }

  return (
    <div style={{ background: 'white', width: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <div style={{ ...border }}>
          <Grid
            container
            direction="row"
            style={{ padding: '0 2rem', margin: '10px 0' }}>
            <Grid item xs={2} sm={3}>
              {' '}
              <div className={classes.logoBlock}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ width: '100%', height: '100%' }}
                />
              </div>
            </Grid>
            <br />
            <Grid
              //  style={{ textAlign: 'center'}}
              item
              xs={10}
              sm={9}>
              <Typography variant="h4">Team Survey Report</Typography>
              <br />
              <Typography variant="h5">
                Project Name:{' '}
                {reportData
                  ? reportData.drProject
                    ? reportData.drProject[0].zName
                    : ''
                  : ''}
              </Typography>
              {reportData
                ? reportData.drProject
                  ? reportData.drProject[0].zDescription
                  : ''
                : ''}
              <br />
              Project Due Date:{' '}
              {reportData
                ? reportData.drProject
                  ? reportData.drProject[0].zDueDateFormat
                  : ''
                : ''}
              <br />
            </Grid>

            <br />
            <Divider />
          </Grid>
        </div>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <div style={{ ...border }}>
          <Grid item xs={12} sm={12}>
            <Grid>
              <div style={{ ...borderfi }}>
                <Grid style={{ padding: '2rem' }}>
                  <Grid
                    container
                    direction="row"
                    //  style={{ padding: '0 2rem', margin: '10px 0' }}
                  >
                    <Grid item xs={2} sm={9}>
                      <Typography variant="h4">
                        {reportData ? reportData.strOverallTitleLit : ''}
                      </Typography>
                    </Grid>
                    <br />
                    <Grid item xs={10} sm={3}>
                      <Typography>
                        {reportData ? reportData.strTotlRspsLit : ''}
                      </Typography>
                    </Grid>

                    <br />
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12}></Grid>
                  <br />
                  <Grid>{reportData ? reportData.strOvwDesc : ''}</Grid>
                  <br />
                  <Typography variant="h4">
                    Overall Team Score
                    {/* :  */}
                    {/* {reportData?.OverallTeamEffectiveness} */}
                  </Typography>
                  <Grid
                    style={{ marginTop: '10px' }}
                    container
                    spacing={5}
                    justifyContent="space-evenly"
                    className={classes.container}
                    direction="row">
                    <Grid item sm={2} style={{ marginTop: '10px' }}>
                      <div style={{ width: '100%' }} class="single-chart">
                        <svg viewBox="0 0 36 36" class="circular-chart orange">
                          <path
                            class="circle-bg"
                            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <path
                            class="circle"
                            stroke-dasharray={precentage(precentda)}
                            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <text x="18" y="20.35" class="percentage">
                            {console.log(
                              'missing one',
                              reportData
                                ? reportData.OverallEffectivenessText
                                  ? reportData.OverallEffectivenessText[0].Text
                                  : ''
                                : ''
                            )}
                            {reportData?.OverallTeamEffectiveness}
                          </text>
                        </svg>
                      </div>
                    </Grid>
                    <Grid sm={10} style={{ marginTop: '23px' }}>
                      {reportData
                        ? reportData.OverallEffectivenessText
                          ? reportData.OverallEffectivenessText[0].Text.split(
                              ':'
                            )[1]
                          : ''
                        : ''}
                    </Grid>
                  </Grid>
                </Grid>
              </div>

              <Grid
                style={{ marginTop: '10px' }}
                container
                spacing={5}
                justifyContent="space-evenly"
                className={classes.container}
                direction="row">
                <div style={{ ...border }}>
                  <Grid sm={10} style={{ padding: '1rem' }}>
                    <Typography variant="h4">
                      {reportData && reportData.strSummRsltsLit
                        ? reportData.strSummRsltsLit
                        : ''}
                    </Typography>
                  </Grid>

                  <Grid xs={6} sm={6}></Grid>
                  <Grid
                    xs={6}
                    sm={6}
                    style={{
                      float: 'right',
                      // marginRight: '14rem',
                      marginRight: '9.3rem',
                      marginBottom: '1rem',
                    }}>
                    {Array.from(Array(sclaesize).keys()).map((c, i) => {
                      return (
                        <text
                          style={{
                            // padding: '17px'
                            padding: '24px',
                          }}
                          aria-current="stedsadp"
                          className={`hiddensplitspan_two_${c}`}>
                          {i}
                        </text>
                      )
                    })}
                  </Grid>
                  {console.log('count', reportData.dtItemCounts)}
                  {reportData &&
                    reportData.dtAvgTeam &&
                    reportData.dtAvgTeam.map((rec, i) => {
                      let precentagesum = (rec.zAvgScore / scaleSize) * 220

                      return (
                        <Grid
                          container
                          direction="row"
                          style={{ padding: '0 2rem', margin: '10px 0' }}>
                          <Grid xs={1} sm={1}></Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            style={{ textAlignLast: 'end' }}>
                            {' '}
                            {rec.zName}
                          </Grid>
                          <Grid xs={1} sm={1}></Grid>

                          <br />
                          <Grid
                            style={{ textAlign: 'center' }}
                            item
                            xs={12}
                            sm={1}>
                            {rec.zAvgScore}
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={4}
                            // sm={3}
                            style={{
                              // width: widthSiz,
                              display: 'flex',
                              flexDirection: 'row',
                              position: 'relative',
                            }}>
                            <BarGraph
                              value={rec.zAvgScore}
                              chartWidth={widthSiz}
                              precent={precentagesum}
                            />

                            {Array.from(Array(sclaesize).keys()).map((c) => {
                              return (
                                <span
                                  aria-current="stedsadp"
                                  className={`hiddensplitspan_one_${c}`}></span>
                              )
                            })}
                            <br />
                            <br />
                          </Grid>

                          <br />
                          <Divider />
                        </Grid>
                      )
                    })}
                </div>
              </Grid>

              {Object.entries(reportGroup).map((re) => {
                let precent = (re[1].average / scaleSize) * 100
                console.log('re', re[1].respondentCount)
                let titleText = re[0]
                console.log('tit', titleText)
                return (
                  <div style={{ ...border }}>
                    <Grid>
                      <Grid
                        style={{ marginTop: '10px', textAlign: 'center' }}
                        container
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between">
                        <Grid
                          container
                          alignItems="center"
                          direction="row"
                          justifyContent="space-between"
                          sm={12}>
                          <Grid sm={2} style={{ padding: '1rem' }}>
                            <Typography variant="h5">{re[0]}</Typography>
                          </Grid>
                          <Grid sm={4}></Grid>

                          <Grid
                            sm={5}
                            style={{
                              padding: '20px',
                              // maxWidth: '104.666667%',
                            }}>
                            {' '}
                            <div
                              style={{
                                'border-color': '#00CC01',
                                'border-style': 'solid',
                                'border-radius': '12px',
                                display: 'flex',
                                'align-items': 'center',
                                'justify-content': 'center',
                                width: 'fit-content',
                                float: 'right',
                              }}>
                              <Typography
                                style={{
                                  display: 'flex',
                                  'align-items': 'center',
                                  'font-size': '22px',
                                  color: '#00CC01',
                                  padding: '5px',
                                }}
                                data-title={titleText}
                                data-text={re[1].text1}
                                onClick={actionGuideFun}>
                                <img src={Image} />
                                {/* Learning Resource */}
                                Action Guide
                              </Typography>
                            </div>
                          </Grid>
                          {/* <Grid sm={1}>

                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={5}
                        justifyContent="space-evenly"
                        className={classes.container}
                        direction="row"
                        alignItems="center">
                        <Grid item sm={2}>
                          <div style={{ width: '100%' }} class="single-chart">
                            {/* <Typography variant="h5" >{re[0]}</Typography> */}

                            <svg
                              viewBox="0 0 36 36"
                              class="circular-chart orange">
                              <path
                                class="circle-bg"
                                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <path
                                class="circle"
                                stroke-dasharray={precentage(precent)}
                                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                              />
                              <text x="18" y="20.35" class="percentage">
                                {re[1].average}
                              </text>
                            </svg>
                          </div>
                        </Grid>
                        <Grid item sm={9}>
                          {re[1].textmean1 ? re[1].textmean1.split(':')[1] : ''}
                        </Grid>
                        <Grid item sm={2}>
                          {' '}
                        </Grid>
                        {/* <Grid item sm={9}>
                          {re[1].text1}
                        </Grid> */}
                      </Grid>
                      <br />
                      <Grid xs={6} sm={6}></Grid>

                      <Grid
                        container
                        direction="row"
                        //  style={{ padding: '0 2rem', margin: '10px 0' }}
                      >
                        <Grid item xs={2} sm={7}>
                          <Typography variant="h4">
                            {/* {reportData ? reportData.strOverallTitleLit : ''} */}
                          </Typography>
                        </Grid>
                        <br />
                        <Grid item xs={10} sm={5}>
                          <Typography
                            style={{
                              marginBottom: '1rem',
                              marginLeft: '3.5rem',
                            }}>
                            Total Survey Respondents:{' '}
                            {re[1] ? re[1].respondentCount : ''}
                          </Typography>
                        </Grid>

                        <br />
                        <Divider />
                      </Grid>
                      <Grid
                        xs={6}
                        sm={6}
                        style={{
                          float: 'right',
                          marginRight: '0.4rem',
                          marginBottom: '1rem',
                        }}>
                        {Array.from(Array(sclaesize).keys()).map((c, i) => {
                          return (
                            <text
                              style={{ padding: '24px' }}
                              aria-current="stedsadp"
                              className={`hiddensplitspan_two_${c}`}>
                              {i}
                            </text>
                          )
                        })}
                      </Grid>
                      <Grid
                        style={{ marginTop: '10px' }}
                        container
                        direction="column">
                        {re[1].reportdata.map((v, index) => {
                          console.log(re[1].reportdata, v, 'vv')
                          let artmp = []

                          let foun1 = reportData.dtItemCounts.filter(
                            (rec) => rec.zItemID == v.zItemID
                          )
                          if (foun1) {
                            foun1.map((r, i) => {
                              console.log('cir', r.zUserCount)
                            })
                          }
                          let foun = reportData.dtItemCounts.find(
                            (rec) => rec.zItemID == v.zItemID
                          ).zUserCount
                          let founsel = reportData.dtItemCounts.find(
                            (rec) => rec.zItemID == v.zItemID
                          ).zSelectedValue
                          console.log('founding crt', foun1, foun, founsel)
                          let precentage = (v.zAvgScore / scaleSize) * 290
                          return (
                            <Grid
                              container
                              direction="row"
                              style={{
                                padding: '0 2rem',
                                margin: '5px 0',
                                position: 'relative',
                                marginBottom: '2rem ',
                              }}>
                              <Grid item xs={12} sm={7}>
                                {' '}
                                {index + 1}. {v.zText}
                              </Grid>
                              <Grid
                                style={{ textAlign: 'center' }}
                                item
                                xs={12}
                                sm={1}>
                                {v.zAvgScore}
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{
                                  // width: widthSiz,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  position: 'relative',
                                }}>
                                <BarGraph
                                  value={v.zAvgScore}
                                  precent={precentage}
                                  iid={foun1}
                                />
                                {Array.from(Array(sclaesize).keys()).map(
                                  (c) => {
                                    return (
                                      <>
                                        <span
                                          aria-current="stedsadp"
                                          className={`hiddensplitspan_three_${c}`}></span>
                                      </>
                                    )
                                  }
                                )}

                                {/* <Typo
                    graphy>{foun}</Typography> */}
                              </Grid>
                              {/* {Array.from(Array(sclaesize).keys()).map((c) => {
                      return (
                        <>
                        <text
                          aria-current="stedsadp"
                          className={`hiddensplitspan_three_${c}`}
                          >{3}</text>
                          
                          </>
                      )
                    })} */}

                              <Grid xs={6} sm={6}></Grid>
                              <Grid
                                xs={6}
                                sm={6}
                                style={{
                                  float: 'right',
                                  marginLeft: '34rem',
                                  // marginBottom: '1rem ',
                                }}>
                                <Box sx={{ position: 'absolute', top: '45%' }}>
                                  {tmp &&
                                    tmp.map((re, i) => {
                                      let ff = foun1.find(
                                        (val) =>
                                          val.zSelectedValue == tmp[i].sval
                                      )
                                      console.log(ff, 'ffff')
                                      if (ff) {
                                        tmp[i].count = foun1.find(
                                          (val) =>
                                            val.zSelectedValue == tmp[i].sval
                                        ).zUserCount
                                      } else {
                                        tmp[i].count = 0
                                      }
                                      return console.log('k')
                                    })}
                                  {console.log('tmp', tmp)}
                                  {tmp &&
                                    tmp.map((re, i) => {
                                      return (
                                        <text
                                          style={{
                                            padding: '28px',
                                            fontSize: 'smaller',
                                          }}>
                                          {re.count == 0 ? '' : re.count}
                                        </text>
                                      )
                                    })}
                                </Box>
                              </Grid>

                              <Divider />
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  </div>
                )
              })}
            </Grid>
            <Grid sm={9}>
              <Divider />
            </Grid>
          </Grid>
          <Grid>
            <div style={{ ...borderfi }}>
              <Grid style={{ padding: '2rem' }}>
                <Grid
                  container
                  direction="row"
                  //  style={{ padding: '0 2rem', margin: '10px 0' }}
                >
                  <Grid item xs={2} sm={12} style={{ width: '100%' }}>
                    <h1>Open-Ended Comments </h1>
                    <hr />
                    {reportData?.dtOpenEnded?.map((item) => {
                      return (
                        <>
                          <Grid
                            style={{
                              marginTop: `${item.QuestionText ? '4rem' : ''}`,
                            }}>
                            <Typography variant="h6">
                              <h2>{item ? item.QuestionText : ''}</h2>
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              variant="h6"
                              style={{
                                fontSize: '95%',
                                background: '#f0f0f0',
                              }}>
                              <p> {item ? item.ResponseText : ''}</p>
                            </Typography>
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>
                  <br />
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={12}></Grid>
                <br />
              </Grid>
            </div>
          </Grid>
        </div>
      </Grid>
      {aflag && (
        <ActionPopup
          aflag={aflag}
          title={title}
          handleReset={handleReset}
          actionText={actionText}
        />
      )}
    </div>
  )
}

export default TeamReport
