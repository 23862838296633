export const RESPOND_SURVEY_ITEMS = 'Respond to Survey items'
export const NEW_RESPOND_SURVEY_ITEMS='Respond to 360 Survey'
export const COMPLETE_THINKWISE_PROJECT_SETUP =
  'Complete ThinkWise360 Project Setup'
export const COMPLETE_COMPETENCY_MODEL_PROJECT_SETUP =
  'Complete Competency Model Project Setup'
export const CLOSE_SURVEY_GET_RESULTS = 'Close Survey and get Results '
export const COMPLETE_TEAM_PERFORMANCE_SURVEY =
  'Complete Team Performance Survey'
export const FINALIZE_TEAM_PERFORMANCE_SURVEY =
  'Finalize Team Performance Survey'
export const COMPLETE_SETUP_OF_TEAM_SURVEY = 'Complete Setup of Team Survey'
