import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  TextField,
} from '@material-ui/core'
import * as Yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CustomAddCategory from '../CustomAddCategory'
import CustomAddDescription from '../CustomAddDescription'
import CustomAddSubCategoryDescription from '../CustomAddSubCategoryDescription'
import EditCustomCategory from '../EditCustomCategory'
import EditCustomSubCategory from '../EditCustomSubCategory'
import CustomItemModal from '../CustomItemModal'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import {
  ADD_ITEM_URL,
  ADD_SUB_CATEGORY_URL,
  DELETE_CUSTOME_ITEM,
  DELETE_CUSTOM_CATEGORY_URL,
  DELETE_CUSTOM_SUB_CATEGORY,
  GET_CUSTOM_CATEGORY_URL,
  GET_CUSTOM_ITEMS_BY_CATEGORY,
  GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
  GET_CUSTOM_SUB_CATEGORY_URL,
  GET_ITEMS_BY_SUBCATEGORY_URL,
  GET_SUB_CATEGORY,
  GET_SUB_CATEGORY_BYID,
  GET_SUB_CATEGORY_ITEM,
  UPDATE_ITEM_URL,
  UPDATE_SUB_CATEGORY,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useDispatch, useSelector } from 'react-redux'
import {
  ADD_ALL_CUSTOM_CATEGORY,
  CHECKED_ITEM_LIST,
  DELETE_CUSTOM_CATEGORY,
  SAVE_CUSTOM_CATEGORY,
  SAVE_CUSTOM_CATEGORY_ITEMS,
  SAVE_CUSTOM_CATEGORY_LIST,
  SAVE_CUSTOM_SUB_CATEGORY_ITEMS,
  SAVE_CUSTOM_SUB_CATEGORY_LIST,
  SAVE_SELECTED_ITEMS,
  SELECT_CUSTOM_CATEGORY,
  UNCHECK_ALL_CUSTOM_CATEGORY,
  UNCHECK_ALL_THINKWISE_ITEM,
  SELECTED_SUB_ITEMS,
} from '../../../../../../redux/action/ActionType'
import {
  addCustomCategory,
  addCustomSubCategory,
  deleteCustomCategory,
  deleteCustomSubCategory,
  getCustomCategory,
} from '../../../../../../redux/action'
import { unstable_batchedUpdates } from 'react-dom'
import { authHeader, getOrganizationId, getUserId } from '../../../../../common/CustomFunction'
import { useFormik } from 'formik'
import CustomButton from '../../../../../common/CustomButton'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
  },

  accHeadSelected: {
    backgroundColor: '#64B5F6',
    color: '#000',
    height: '64px',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
    '& .MuiSvgIcon-root': {
      color: '#000',
    },
  },

  //   accHeadSelected :{
  //     '&.MuiAccordionSummary-content' :{
  // justifyContent : "space-between",
  // alignItems: "center"
  //     }

  //   },

  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
    height: '64px',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
  },
  subHead: {
    backgroundColor: '#eee',
    height: '64px',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnSelected: {
    backgroundColor: '#64B5F6',
    color: '#000',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },

  listItemfont: {
    fontFamily: 'Nunito-Regular !important',
  },

  listItemStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#1565C0',
      color: '#fff',
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1565C0',
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
  },
  listItemSelectedStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      fontFamily: 'Nunito-Regular !important',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '10px',
  },
  accordiandetailsBtn: {
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))

function deleteCustomItem(id) {
  const deleteConfig = {
    method: 'DELETE',
    headers: authHeader(),

    url: DELETE_CUSTOME_ITEM,
    params: {
      ItemID: id,
    },
  }
  return API(deleteConfig).then((response) => {
    if (response.status == 200) {
      return true
    }
  })
}

const InnerAccordianItem = ({
  subCategory,
  classes,
  value,
  competencyId,
  handleSubDescModal,
  zLevelID,
  // handleEditSubCategoryModal,
  // handleItem,
}) => {
  const { zCapabilityID, zDescription, zName } = subCategory
  const dispatch = useDispatch()
  const SubCategoryItems = useSelector((state) => state.CustomSubCategoryItems)
  const [isEditSubCategory, setEditSubCategory] = useState(false)
  const [isItemModal, setItemModal] = useState(false)
  const [itemModalTitle, setItemModalTitle] = useState('')
  const [itemData, setItemData] = useState('')
  const [isDescModal, setDescModal] = useState(false)
  const subCategoryItems =
    SubCategoryItems['SUB_CATEGORY_ITEMS_' + zCapabilityID]

  const deleteItem = (itemId) => {
    deleteCustomItem(itemId).then((response) => {
      response && getSubCategoryItems(zCapabilityID)
    })
  }

  const getSubCategoryItems = async (subCategoryId) => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
      params: {
        SubCategoryId: subCategoryId,
        LevelID: 3,
      },
    }
    const response = await API(getConfig)
    if (response.status == 200) {
      const { data } = response.data
      let currentItems = { ...SubCategoryItems }
      currentItems['SUB_CATEGORY_ITEMS_' + subCategoryId] = data

      dispatch({ type: SAVE_CUSTOM_SUB_CATEGORY_ITEMS, payload: currentItems })
    }
  }

  const CheckedItemList = useSelector((state) => state.CheckedItemList)

  const handleItem = (e, item) => {
    if (e.target.checked) {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        const temp2 = subCategoryItems

        temp2.forEach((item) => {
          if (!temp.find((val) => val.itemId === item.zItemID)) {
            temp.push({
              itemId: item.zItemID,
              categoryId: 0,
              subCategoryId: zCapabilityID,
            })
          }
        })
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: zCapabilityID,
          categoryId: 0,
        }
        temp.push(arr)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    } else {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        temp = temp.filter(
          (checkedItem) => checkedItem.subCategoryId !== zCapabilityID
        )
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: zCapabilityID,
          categoryId: 0,
        }
        temp = temp.filter((val) => val.itemId != arr.itemId)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    }
  }

  const unCheckSeletedItem = () => {}

  return (
    <>
      <Accordion
        key={`acc_inside_${value}`}
        style={{ margin: '0 -14px 0.5rem -15px' }}>
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls={`item_inside_${value}-content`}
          id={`item_inside_${value}-header`}
          className={classes.accHeadInside}>
          <Typography className={classes.heading}>{zName}</Typography>
          <Box>
            {!zDescription ? (
              <Button
                className={classes.toggleDescBtnInside}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                onClick={(e) => {
                  e.stopPropagation()
                  setDescModal(true)
                }}>
                Add Description
              </Button>
            ) : (
              ''
            )}
            <Button
              className={classes.toggleDescBtnInside}
              startIcon={<AddIcon className={classes.toggleDescIcon} />}
              onClick={(e) => {
                e.stopPropagation()
                setItemData('')
                setItemModalTitle('Add Item')
                setItemModal(true)
              }}>
              Add Item
            </Button>
            <IconButton
              aria-label="edit-subCategory"
              className={classes.icoBtnInside}
              size="small"
              data-title="Edit Sub Category"
              onClick={(e) => {
                e.stopPropagation()
                setEditSubCategory(true)
              }}>
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.icoBtnInside}
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(deleteCustomSubCategory(subCategory.zCapabilityID))
              }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <Typography className={classes.accDesc}>{zDescription}</Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {subCategoryItems && subCategoryItems.length && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="all"
                      checked={
                        CheckedItemList
                          ? CheckedItemList.filter(
                              (checkedItem) =>
                                checkedItem.subCategoryId === zCapabilityID
                            ).length === subCategoryItems.length
                            ? true
                            : false
                          : false
                      }
                      onClick={(e) => handleItem(e)}
                    />
                  }
                  label="All"
                />
              )}
              {subCategoryItems
                ? subCategoryItems.map((subCategoryItem) => {
                    return (
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                name={subCategoryItem.zItemID}
                                checked={
                                  CheckedItemList
                                    ? CheckedItemList.find(
                                        (checkedItem) =>
                                          checkedItem.itemId ===
                                          subCategoryItem.zItemID
                                      )
                                      ? true
                                      : false
                                    : false
                                }
                                onClick={(e) => handleItem(e, subCategoryItem)}
                              />
                            }
                            label={subCategoryItem.zCaption}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation()
                              setItemData(subCategoryItem)
                              setItemModalTitle('Edit Item')
                              setItemModal(true)
                            }}>
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              deleteItem(subCategoryItem.zItemID)

                              // if(response) {
                              //   getSubCategoryItems(subCategory.zCapabilityID)
                              // }
                            }}
                            style={{ marginLeft: '1rem' }}>
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })
                : ''}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <CustomItemModal
        open={isItemModal}
        title={itemModalTitle}
        itemData={itemData}
        zLevelID={zLevelID}
        subCategoryId={zCapabilityID}
        handleReset={() => setItemModal(false)}
      />

      <EditCustomSubCategory
        open={isEditSubCategory}
        handleReset={() => setEditSubCategory(false)}
        title={'Edit SubCategory'}
        capabilityId={zCapabilityID}
        description={zDescription}
        name={zName}
      />

      <CustomAddSubCategoryDescription
        open={isDescModal}
        subCategory={subCategory}
        handleReset={() => setDescModal(false)}
      />
    </>
  )
}

let SubCategoryinitial = {
  userID: 0,
  caption: '',
  itemLevelID: 0,
  categoryId: 0,
}

const AccordionContainer = ({
  category,
  subcategory,
  CategoryItems,
  checkedCategory,
  zLevelID,
}) => {
  const { zCompetencyID, zDescription, zName } = category
  // let selectedCategoryList =
  //   subcategory['CUSTOM_CATEGORY_LIST_' + zCompetencyID] || []
  //console.log("zcompetencyID", zCompetencyID)
  const categoryItems = CategoryItems['CATEGORY_ITEMS_' + zCompetencyID]
  const [updateFlag, setUpdateFlag] = useState(false)
  const [isCategoryModal, setCategoryModal] = useState(false)
  const [isItemModal, setItemModal] = useState(false)
  const [isDescModal, setDescModal] = useState(false)
  const [subCategoryState, setsubCategoryState] = useState({
    ...SubCategoryinitial,
    itemLevelID: zLevelID,
    categoryId: zCompetencyID,
  })
  const [subCategory, setsubCategory] = useState([])
  const [subCategoryOpen, setsubCategoryOpen] = useState(false)
  const subCategoryClose = () => setsubCategoryOpen((val) => !val)
  const [modalTitle, setModalTitle] = useState('')
  const [itemData, setItemData] = useState('')
  const classes = useStyles()
  const dispatch = useDispatch()

  const deleteItem = (itemId) => {
    deleteCustomItem(itemId).then((response) => {
      response && getCategoryItems(zCompetencyID)
    })
  }

  const getCategoryItems = async (categoryId) => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_ITEMS_BY_CATEGORY,
      params: {
        CategoryId: categoryId,
        LevelID: zLevelID,
      },
    }
    const response = await API(getConfig)
    if (response.status == 200) {
      const { data } = response.data
      let currentItems = { ...CategoryItems }
      currentItems['CATEGORY_ITEMS_' + categoryId] = data
      dispatch({ type: SAVE_CUSTOM_CATEGORY_ITEMS, payload: currentItems })
    }
  }

  const CheckedItemList = useSelector((state) => state.CheckedItemList)

  const getCustomCategoryList = () => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_CATEGORY_URL,
      params: {
        UserId: getUserId(),
      },
    }
    API(getConfig).then((res) => {
      if (res.status === 200) {
        const { data } = res.data
        dispatch({
          type: SAVE_CUSTOM_CATEGORY_LIST,
          payload: data,
        })
      }
    })
  }

  const handleItem = (e, item) => {
    if (e.target.checked) {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        const temp2 = categoryItems
        temp2.forEach((item) => {
          if (!temp.find((val) => val.itemId === item.zItemID)) {
            temp.push({
              itemId: item.zItemID,
              categoryId: zCompetencyID,
              subCategoryId: 0,
            })
          }
        })
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: 0,
          categoryId: zCompetencyID,
        }
        temp.push(arr)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    } else {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        temp = temp.filter(
          (checkedItem) => checkedItem.categoryId !== zCompetencyID
        )
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: 0,
          categoryId: zCompetencyID,
        }
        temp = temp.filter((val) => val.itemId != arr.itemId)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    }
  }

  const removeCustomSeleteditem = (e) => {
    const id = e.currentTarget.dataset
    console.log(id)
  }

  const getSubcategory = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SUB_CATEGORY_BYID,
      params: {
        CategoryId: zCompetencyID,
      },
    }
    try {
      const response = await API(config)
      const { data } = response.data
      console.log(response, 'response')
      setsubCategory(data)
    } catch (er) {
      console.error(er)
    }
  }

  const successCall = () => {
    getSubcategory()
  }
  useEffect(() => {
    getSubcategory()
  }, [])

  return (
    <>
      <Accordion
        key={`acc_${'value'}`}
        className={classes.acc}
        // style={{
        //   display: selectedIndex.includes(`list_${value}`) ? '' : 'none',
        // }}
      >
        <AccordionSummary
          className={`${
            checkedCategory.includes(zCompetencyID)
              ? classes.accHeadSelected
              : classes.accHead
          }`}
          // expandIcon={
          //   <ExpandMoreIcon
          //     style={{
          //       color: `${
          //         checkedCategory.includes(zCompetencyID) ? '#000' : '#fff'
          //       }`,
          //     }}
          //   />
          // }
        >
          <Typography className={classes.heading}>{zName}</Typography>

          <Box>
            {!zDescription && (
              <Button
                className={`${
                  checkedCategory.includes(zCompetencyID)
                    ? classes.toggleDescBtnSelected
                    : classes.toggleDescBtn
                }`}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                onClick={(e) => {
                  e.stopPropagation()
                  setDescModal(true)
                }}>
                Add Description
              </Button>
            )}
            {!(categoryItems?.length > 0) && (
              <Button
                className={`${
                  checkedCategory.includes(zCompetencyID)
                    ? classes.toggleDescBtnSelected
                    : classes.toggleDescBtn
                }`}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                data-title="Add Sub Category"
                onClick={(e) => {
                  e.stopPropagation()
                  subCategoryClose()
                }}
                onFocus={(e) => e.stopPropagation()}>
                Add Subcategory
              </Button>
            )}
            {!(subCategory?.length > 0) && (
              <Button
                className={`${
                  checkedCategory.includes(zCompetencyID)
                    ? classes.toggleDescBtnSelected
                    : classes.toggleDescBtn
                }`}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                // data-title="Add Item"
                onClick={(e) => {
                  e.stopPropagation()
                  setItemData('')
                  setModalTitle('Add Item')
                  setItemModal(true)
                }}
                // onFocus={(e) => e.stopPropagation()}
              >
                Add Item
              </Button>
            )}

            <IconButton
              aria-label="edit-category"
              className={classes.icoBtn}
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                //setOnEditVar("nextEdit")
                setUpdateFlag(true)
                setCategoryModal(true)

                // handleEditCategoryModal()
              }}
              onFocus={(e) => e.stopPropagation()}>
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.icoBtn}
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(deleteCustomCategory(zCompetencyID))
                dispatch({
                  type: DELETE_CUSTOM_CATEGORY,
                  payload: category.zCompetencyID,
                })
              }}
              // onFocus={(e) => e.stopPropagation()}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

            <IconButton>
              <ExpandMoreIcon
                style={{
                  color: `${
                    checkedCategory.includes(zCompetencyID) ? '#000' : '#fff'
                  }`,
                }}
              />
            </IconButton>
          </Box>
        </AccordionSummary>

        {subCategory.length ? (
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>{zDescription}</Typography>
            {subCategory.map((subCat) => {
              return (
                <SubcategoryAccodian
                  subCat={subCat}
                  classes={classes}
                  zLevelID={zLevelID}
                  successCallsubcategory={successCall}
                />
              )
            })}
          </AccordionDetails>
        ) : (
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>{zDescription}</Typography>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {categoryItems && !!categoryItems.length && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="all"
                        checked={
                          CheckedItemList && categoryItems
                            ? CheckedItemList.filter(
                                (checkedItem) =>
                                  checkedItem.categoryId === zCompetencyID
                              ).length === categoryItems.length
                              ? true
                              : false
                            : false
                        }
                        onClick={(e) => handleItem(e)}
                      />
                    }
                    label="All"
                  />
                )}
                {categoryItems
                  ? categoryItems.map((categoryItem) => {
                      return (
                        <Grid container spacing={12}>
                          <Grid item xs={10}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="primary"
                                  name={categoryItem.zItemID}
                                  checked={
                                    CheckedItemList
                                      ? CheckedItemList.find(
                                          (checkedItem) =>
                                            checkedItem.itemId ===
                                            categoryItem.zItemID
                                        )
                                        ? true
                                        : false
                                      : false
                                  }
                                  onClick={(e) => handleItem(e, categoryItem)}
                                />
                              }
                              label={categoryItem.zCaption}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            className={classes.accordiandetailsBtn}>
                            <Box>
                              <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => {
                                  setItemData(categoryItem)
                                  setModalTitle('Edit Item')
                                  setItemModal(true)
                                }}>
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                data-comit={categoryItem.zCompetencyID}
                                onClick={() => {
                                  deleteItem(categoryItem.zItemID)
                                }}
                                // onClick={removeCustomSeleteditem}
                                style={{ marginLeft: '1rem' }}>
                                <DeleteIcon fontSize="inherit" />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      )
                    })
                  : ''}
              </FormGroup>
            </FormControl>

            {subcategory.map((sub, index) => {
              if (sub.zCompetencyID === zCompetencyID) {
                return (
                  <InnerAccordianItem
                    key={`item-${index}`}
                    index={index}
                    value={index}
                    competencyId={zCompetencyID}
                    subCategory={sub}
                    zLevelID={zLevelID}
                    classes={classes}
                    // expandedInside={expandedInside}
                    // handleSubDescModal={handleSubDescModal}
                    // handleEditSubCategoryModal={handleEditSubCategoryModal}
                    // handleItem={handleItem}
                  />
                )
              }
            })}
          </AccordionDetails>
        )}
      </Accordion>

      <EditCustomCategory
        // onEdit = {onEditVar}
        updateFlag={updateFlag}
        open={isCategoryModal}
        competencyId={zCompetencyID}
        handleReset={() => {
          getCustomCategoryList()
          setCategoryModal(false)
        }}
        name={zName}
        description={zDescription}
      />

      <CustomItemModal
        open={isItemModal}
        title={modalTitle}
        itemData={itemData}
        zLevelID={zLevelID}
        categoryId={zCompetencyID}
        handleReset={() => setItemModal(false)}
      />
      <SubCategoryModal
        open={subCategoryOpen}
        handleClose={subCategoryClose}
        itemState={subCategoryState}
        classes={classes}
        successCall={successCall}
      />
      <CustomAddDescription
        open={isDescModal}
        category={category}
        handleReset={() => {
          getCustomCategoryList()
          setDescModal(false)
        }}
      />
    </>
  )
}

export default function CustomItems(props) {
  const { zLevelID } = props
  const [editModal, setEditModal] = useState(false)
  //const [onEditVar, setOnEditVar] = useState("")
  const [updateFlag, setUpdateFlag] = useState(false)
  // const [categName, setCategName] = useState("")
  const classes = useStyles()
  const CustomCategoryList = useSelector((state) => state.CustomCategoryList)
  const ThinkwiseCustomselectedlist = useSelector(
    (state) => state.ThinkwiseCustomselectedlist
  )
  const ThinkwiseGroupcategoryReducer = useSelector(
    (state) => state.CustomGroupcategoryReducer
  )
  const CustomSubCategoryList = useSelector(
    (state) => state.CustomSubCategoryList
  )
  let selectItemfield = ThinkwiseCustomselectedlist.map(
    (val) => val.zCompetencyID
  )
  const dispatch = useDispatch()

  function handleListItemClick(event) {
    const { id } = event.currentTarget.dataset

    let newcategoryList = [...CustomCategoryList]

    let selectedCategory = newcategoryList.find(
      (val) => val.zCompetencyID == id
    )

    if (selectItemfield.includes(parseInt(id))) {
      dispatch({ type: DELETE_CUSTOM_CATEGORY, payload: id })
    } else {
      dispatch({ type: SELECT_CUSTOM_CATEGORY, payload: selectedCategory })
    }
  }

  const selectAllItems = (e) => {
    if (e.target.checked === true) {
      dispatch({
        type: ADD_ALL_CUSTOM_CATEGORY,
        payload: CustomCategoryList,
      })
    } else {
      dispatch({ type: UNCHECK_ALL_CUSTOM_CATEGORY, payload: [] })
    }
  }

  const CategoryItems = useSelector((state) => state.CustomCategoryItems)
  const CustomSubCategoyItems = useSelector(
    (state) => state.CustomSubCategoryItems
  )
  const CheckedItems = useSelector((state) => state.CheckedItemList)
  let checkedCategory = []
  CheckedItems.forEach((checkedItem) => {
    const category = CustomCategoryList.find(
      (category) => category.zCompetencyID === checkedItem.categoryId
    )
    if (category) {
      checkedCategory.push(category.zCompetencyID)
    }
    const subCategory = CustomSubCategoryList.find(
      (subCategory) => subCategory.zCapabilityID === checkedItem.subCategoryId
    )
    if (subCategory) {
      checkedCategory.push(subCategory.zCompetencyID)
    }
  })
  let customChekeditem = []
  let newCheck = CustomCategoryList.map((value) => value.zCompetencyID)
  //zCompetencyID
  CheckedItems.forEach((cat) => {
    // console.log(cat, newCheck)
    if (newCheck.includes(cat.categoryId)) {
      customChekeditem.push(cat)
    }
  })

  console.log(newCheck, CheckedItems, CustomCategoryList, 'CheckedItems')
  const addCategory = () => {
    setUpdateFlag(false)
    setEditModal(true)
  }

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      style={{ marginTop: '2px' }}>
      <Grid item xs={3}>
        <Button
          className={classes.addCatBtn}
          startIcon={<AddIcon />}
          onClick={addCategory}>
          Add Category
        </Button>
        <List
          component="nav"
          aria-label="secondary mailbox folder"
          className={classes.listItemfont}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="all"
                checked={
                  selectItemfield
                    ? selectItemfield.length === CustomCategoryList.length
                      ? true
                      : false
                    : false
                }
                onClick={(e) => selectAllItems(e)}
              />
            }
            label="Select All"
          />
          {CustomCategoryList.map((category, index) => {
            return (
              <ListItem
                key={`list_item_${index}`}
                button
                selected={selectItemfield.includes(category.zCompetencyID)}
                data-id={category.zCompetencyID}
                onClick={handleListItemClick}
                className={`${
                  checkedCategory.includes(category.zCompetencyID)
                    ? classes.listItemSelectedStyle
                    : classes.listItemStyle
                }`}>
                <ListItemText primary={category.zName} />
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item xs={9}>
        <p>
          Custom Items Selected : <b>{customChekeditem.length}</b>
        </p>

        {ThinkwiseCustomselectedlist.map((category, index) => {
          if (
            selectItemfield.find(
              (selectItem) => selectItem === category.zCompetencyID
            )
          ) {
            return (
              <AccordionContainer
                category={category}
                checkedCategory={checkedCategory}
                classes={classes}
                // key={`item-${index}`}
                // value={index}
                zLevelID={zLevelID}
                subcategory={CustomSubCategoryList}
                CategoryItems={CategoryItems}
                // accordianId={category.zCompetencyID}
                // handleItemModal={handleItemModal}
                // selectedIndex={selectedIndex}
                // selectedCategory={selectedCategory}
                // handleItem={(e) => handleItem(e, category.accordian)}
              />
            )
          }
        })}
      </Grid>

      <EditCustomCategory
        open={editModal}
        //onEdit = {onEditVar}
        updateFlag={updateFlag}
        //competencyId={zCompetencyID}
        handleReset={() => {
          // getCustomCategoryList();
          setEditModal(false)
        }}
      />
    </Grid>
  )
}

const SubcategoryAccodian = (props) => {
  const { classes, subCat, zLevelID, successCallsubcategory } = props
  let ItemInitialValue = {
    caption: '',
    subCategoryId: subCat.zCapabilityID,
    UserId: getUserId(),
    itemLevelID: zLevelID,
  }
  const dispatch = useDispatch()
  const [openSubItem, setopenSubItem] = useState(false)
  const [subCategoyitemlist, setsubCategoyitemlist] = useState([])
  const [updateSubcategoryFlag, setupdateSubcategoryFlag] = useState(false)
  const CheckedItemList = useSelector((state) => state.CheckedItemList)
  const [updateSubcategoryState, setupdateSubcategoryState] = useState({
    subCategoryID: 0,
    caption: '',
  })
  const SelectedSubitems = useSelector((state) => state.SelectedSubitems)
  const CustomCategoryList = useSelector((state) => state.CustomCategoryList)
  const [updateCategoryModal, setupdateCategoryModal] = useState(false)
  const closeOpenmodal = () => setupdateCategoryModal((val) => !val)
  const [updateflage, setupdateflage] = useState(false)
  const subitemClose = () => setopenSubItem((val) => !val)

  const addSubcategroy = () => {
    setitemState({ ...ItemInitialValue })
    setupdateflage(false)
    subitemClose()
  }
  const [itemState, setitemState] = useState({
    ...ItemInitialValue,
  })

  const getSubcategory = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ITEMS_BY_SUBCATEGORY_URL,
      params: {
        CapabilityID: subCat.zCapabilityID,
        LevelID: zLevelID,
      },
    }

    // return
    try {
      const response = await API(config)
      const { data } = response.data
      setsubCategoyitemlist(data)
    } catch (er) {
      console.error(er)
    }
  }
  const successCall = () => {
    getSubcategory()
  }

  useEffect(() => {
    if (subCategoyitemlist.length) {
      console.log(CustomCategoryList, subCategoyitemlist)
    }
  }, [subCategoyitemlist])

  const editItem = (e) => {
    const { name, id } = e.currentTarget.dataset

    setitemState((val) => ({ ...val, caption: name, zItemID: id }))
    setupdateflage(true)
    subitemClose()
  }
  const deleteItem = async (id) => {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_CUSTOME_ITEM,
      params: {
        ItemID: id,
      },
    }
    try {
      const response = await API(deleteConfig)

      getSubcategory()
    } catch (er) {
      console.error(er)
    }
  }

  const updateSubcategory = (e) => {
    const { id, name } = e.currentTarget.dataset
    // return null
    setupdateSubcategoryState((val) => ({
      ...val,
      subCategoryID: id,
      caption: name,
    }))
    setupdateSubcategoryFlag(true)
    closeOpenmodal()
  }
  const deleteAlert = (e) => {
    const { id } = e.currentTarget.dataset
    Swal.fire({
      title: 'Are you want to delete Item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(id)
      }
    })
  }

  const updateSuccesscall = () => {
    successCallsubcategory()
    setupdateSubcategoryFlag(false)
  }

  const checkSubitem = (e, subCat, item) => {
    const newChek = [...CheckedItemList]
    const newSubselected = [...SelectedSubitems]
    let allValue = newChek.map((val) => val.itemId)
    if (item == 'All') {
      if (e.target.checked) {
        subCategoyitemlist.forEach((value) => {
          if (!allValue.includes(value.zItemID)) {
            newChek.push({
              subCategoryId: value.zCapabilityID,
              itemId: value.zItemID,
              categoryId: subCat.zCompetencyID,
            })
          }
        })

        dispatch({ type: CHECKED_ITEM_LIST, payload: newChek })
      } else {
        let SubUnselect = subCategoyitemlist.map((val) => val.zItemID)
        let unSelctall = newChek.filter(
          (filet) => !SubUnselect.includes(filet.itemId)
        )
        dispatch({ type: CHECKED_ITEM_LIST, payload: unSelctall })
      }
    } else {
      // let newSub = subCategoyitemlist.find((val) => val.zItemID == item)
      newSubselected.push(item)

      if (allValue.includes(item.zItemID)) {
        let Remove = newChek.filter((value) => value.itemId !== item.zItemID)

        dispatch({ type: CHECKED_ITEM_LIST, payload: Remove })
      } else {
        newChek.push({
          subCategoryId: item.zCapabilityID,
          itemId: item.zItemID,
          categoryId: subCat.zCompetencyID,
        })
        dispatch({ type: CHECKED_ITEM_LIST, payload: newChek })
      }
      console.log(newSubselected)
      dispatch({ type: SELECTED_SUB_ITEMS, payload: [item] })
    }
  }
  useEffect(() => {
    getSubcategory()
  }, [])
  return (
    <>
      <Accordion
        key={`acc_inside_${''}`}
        style={{ margin: '0 -14px 0.5rem -15px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`item_inside_${'value'}-content`}
          id={`item_inside_${'value'}-header`}
          className={classes.subHead}>
          <Typography className={classes.heading}> {subCat.zName}</Typography>
          <Box>
            {' '}
            <Button
              className={classes.toggleDescBtnInside}
              onClick={(e) => {
                e.stopPropagation()

                addSubcategroy()
              }}
              startIcon={<AddIcon className={classes.toggleDescIcon} />}>
              Add Item
            </Button>
            <Button
              className={classes.toggleDescBtnInside}
              data-name={subCat.zName}
              data-id={subCat.zCapabilityID}
              onClick={(e) => {
                e.stopPropagation()
                updateSubcategory(e)
              }}
              startIcon={<EditIcon className={classes.toggleDescIcon} />}>
              Update sub category
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          {/* <Typography className={classes.accDesc}>{'zDescription'}</Typography> */}
          <FormControl component="fieldset" className={classes.formControl}>
            {/* <List
              component="nav"
              aria-label="secondary mailbox folder"
              className={classes.listItemfont}> */}
            {!!subCategoyitemlist.length && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="all"
                      onChange={(e) => checkSubitem(e, subCat, 'All')}
                    />
                  }
                  label="All"
                />
              </FormGroup>
            )}

            {subCategoyitemlist.map((item, index) => {
              let marked = CheckedItemList.map(
                (value) => value.itemId
              ).includes(item.zItemID)
              return (
                <>
                  {' '}
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={marked}
                            onChange={(e) => checkSubitem(e, subCat, item)}
                          />
                        }
                        label={item.zCaption}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-name={item.zCaption}
                        data-id={item.zItemID}
                        onClick={(e) => {
                          editItem(e)
                        }}>
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-id={item.zItemID}
                        onClick={(e) => {
                          deleteAlert(e)
                          // deleteItem(subCategoryItem.zItemID)
                          // if(response) {
                          //   getSubCategoryItems(subCategory.zCapabilityID)
                          // }
                        }}
                        style={{ marginLeft: '1rem' }}>
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )
            })}
            {/* </List> */}
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <SubCategoryModal
        open={updateCategoryModal}
        handleClose={closeOpenmodal}
        itemState={updateSubcategoryState}
        classes={classes}
        successCall={updateSuccesscall}
        updateFlag={updateSubcategoryFlag}
      />
      <SubCategoryItemModal
        open={openSubItem}
        itemState={itemState}
        classes={classes}
        updateflage={updateflage}
        handleClose={subitemClose}
        successCall={successCall}
      />
    </>
  )
}
const itamSchema = Yup.object({
  caption: Yup.string().required('Item name is required'),
})
const SubCategoryModal = (props) => {
  const {
    open,
    handleClose,
    itemState,
    classes,
    successCall,
    updateFlag,
  } = props
  const addSubcategory = async (values) => {
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_SUB_CATEGORY_URL,
      data: {
        competencyID: values.categoryId,
        // userID: getUserId(),
        organizationID: getOrganizationId(),

        name: values.caption,
      },
    }

    try {
      await API(config)
      successCall()
      handleClose()
    } catch (er) {
      console.error(er)
    }
  }
  const updateSubcategory = async (data) => {
    const config = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_SUB_CATEGORY,
      data: {
        subCategoryID: data.subCategoryID,
        name: data.caption,
      },
    }
    try {
      await API(config)
      successCall()
      handleClose()
    } catch (er) {
      console.error(er)
    }
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: { ...itemState },
    enableReinitialize: true,
    validationSchema: itamSchema,
    onSubmit: (values) =>
      updateFlag ? updateSubcategory(values) : addSubcategory(values),
  })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      fullWidth="500"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {updateFlag ? 'Update sub category' : 'Add Sub Category'}{' '}
      </DialogTitle>
      <DialogContent dividers>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={10}>
              <TextField
                id="designation-helper-text"
                label="Sub Category Name"
                value={values.caption}
                onChange={handleChange}
                fullWidth
                name="caption"
                error={errors.caption && touched.caption}
                helperText={
                  errors.caption && touched.caption ? errors.caption : ''
                }
              />
            </Grid>
            <Grid item xs={2}>
              <CustomButton
                colour="primaryButton"
                type="submit"
                onClick={handleSubmit}
                style={{ marginTop: '1.5rem' }}>
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

const subCategorySchema = Yup.object({
  caption: Yup.string().required('Category name is required'),
})

const SubCategoryItemModal = (props) => {
  const {
    open,
    handleClose,
    itemState,
    classes,
    successCall,
    updateflage,
  } = props
  const AddItem = async (values) => {
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_ITEM_URL,
      data: {
        ...values,
        organizationID: getOrganizationId(),
      },
    }

    try {
      await API(config)
      successCall()
      handleClose()
    } catch (er) {
      console.error(er)
    }
  }
  const updateItem = async (values) => {
    console.log(values)
    const config = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_ITEM_URL,
      data: {
        itemID: values.zItemID,
        caption: values.caption,
      },
    }
    try {
      const response = await API(config)
      successCall()
      handleClose()
    } catch (er) {
      console.error(er)
    }
  }
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: { ...itemState },
    enableReinitialize: true,
    validationSchema: subCategorySchema,
    onSubmit: (values) => (updateflage ? updateItem(values) : AddItem(values)),
  })
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      className={classes.root}
      fullWidth="500"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {updateflage ? 'Update Item' : 'Add Item'}{' '}
      </DialogTitle>
      <DialogContent dividers>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={10}>
              <TextField
                id="designation-helper-text"
                label="Item Name"
                value={values.caption}
                onChange={handleChange}
                fullWidth
                name="caption"
                error={errors.caption && touched.caption}
                helperText={
                  errors.caption && touched.caption ? errors.caption : ''
                }
              />
            </Grid>
            <Grid item xs={2}>
              <CustomButton
                colour="primaryButton"
                type="submit"
                onClick={handleSubmit}
                style={{ marginTop: '1.5rem' }}>
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
