import * as types from '../action/ActionType'
export default function RosterReducer(state = {}, action) {
  const { type, payload, actionnew } = action
  switch (type) {
    case types.ROSTER_LIST:
      return (state = payload)
    case types.ADD_ROSTER_FIELD:
      let newState = { ...state }
      newState[payload] = {
        ...newState[payload],
        isAdd: true,
      }
      return newState

    case types.REMOVE_ROSTER_FIELD:
      let newState2 = { ...state }
      newState2[payload] = {
        ...newState2[payload],
        isAdd: false,
      }
      return newState2

    case types.EDIT_ROSTER_FIELD:
      let editRosterState = { ...state }
      editRosterState[payload] = {
        ...editRosterState[payload],
        isEdit: actionnew,
      }
      return editRosterState

    case types.DELETE_ROSTER_FIELD:
      let deleteRosterState = { ...state }
      deleteRosterState[payload] = {
        ...deleteRosterState[payload],
        isDelete: true,
      }
      return deleteRosterState

    default:
      return state
  }
}
