export const PAGE_NOT_FOUND_PATH = '*'
export const SIGN_IN_PATH = '/signin'
export const SIGN_IN_PATH_NEW = '/Newsignin'
export const VALID_SIGN_IN_PATH = '/signin/:id'
export const VALIDATION_SIGN_IN_PATH = '/signin/:id?:taskId/:loginkey'
export const SIGN_UP_PATH = '/signup'
export const ADMIN_SIGN_UP = '/adminsignup'
export const FORGOT_PASSWORD_PATH = '/forgot-password'
export const RESET_PASSWORD_PATH = '/reset-password/:id'
export const NEW_PASSWORD_PATH = '/new-password'
export const INITIAL_LAYOUT_PATH = '/'
export const DASHBOARD_PATH = '/dashboard'

export const PROJECTS_PATH = '/projects'
export const CREATE_NEW_360_PATH = '/projects/create_new_360'
export const PROJECT_SETUP_TYPE = '/projects/setup'
export const PROJECT_EDIT_PATH = '/projects/edit'
export const PROJECT_DETAILS_PATH = '/projects/view/:id'
export const PROJECT_REPORT_PATH = '/reports'
export const USER_REPORT_LIST_PATH = '/userreports'
//report
export const THREESIXTYREPORT = '/individualreport'

//Task
export const TASK_PATH = '/tasks'
export const TASK_VIEW_PATH = '/tasks/view'

//Profile
export const PROFILE_PATH = '/profile'

//Account and user
export const ACCOUNT_AND_USER_PATH = '/accountusers'

//teamsurvey
export const TEAMSURVEYPATH = '/teamsurveyprojects'
export const BASICINFORMATION = TEAMSURVEYPATH + '/setup'
export const TEAMSURVEYVIEW = TEAMSURVEYPATH + '/view'
export const TEAMSURVEYTASK = '/teamsurveytask'
export const TEAMENDUSER = TEAMSURVEYTASK + '/task'
export const TEAMSURVEYREPORT = '/teamreport'
export const TEAM_NEWUSER = '/newaccount'

export const CHECKING_RUTE = DASHBOARD_PATH + '/checking'
export const ROSTER_FORM = '/projectspecificurlforparticipantaddition'
export const THREESIXTYREPORTSELF = '/individualreportself'
export const THREESIXTYREPORTWITHSELF = '/individualreportwithself'