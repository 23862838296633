import { DialogContent } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { API } from '../../../../../helpers/API'
import {
  ADD_ITEM_URL,
  GET_CUSTOM_ITEMS_BY_CATEGORY,
  GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
  UPDATE_ITEM_URL,
} from '../../../../../helpers/constants/ApiRoutes'
import {
  getAllcustomItem,
  getCustomCategory,
} from '../../../../../redux/action'
import CustomButton from '../../../../common/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  SAVE_CUSTOM_CATEGORY_ITEMS,
  SAVE_CUSTOM_SUB_CATEGORY_ITEMS,
} from '../../../../../redux/action/ActionType'
import { authHeader, getOrganizationId, getUserId } from '../../../../common/CustomFunction'
import { useParams } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default function CustomItemModal(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const CustomCategoryItems = useSelector((state) => state.CustomCategoryItems)
  const CustomSubCategoryItems = useSelector(
    (state) => state.CustomSubCategoryItems
  )

  const {
    open = '',
    handleReset,
    title,
    categoryId,
    subCategoryId,
    itemData,
    zLevelID,
  } = props
  //Schema
  const validationSchema = Yup.object({
    itemName: Yup.string().required('Item name is required'),
  })
  const { id: projectId } = useParams()

  //state
  const initialValues = {
    itemName: itemData ? itemData.zCaption : '',
  }

  const getCategoryItems = async () => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_ITEMS_BY_CATEGORY,
      params: {
        CategoryId: categoryId,
        LevelID: zLevelID,
      },
    }
    const response = await API(getConfig)
    if (response.status == 200) {
      const { data } = response.data
      let currentItems = { ...CustomCategoryItems }
      currentItems['CATEGORY_ITEMS_' + categoryId] = data

      dispatch({ type: SAVE_CUSTOM_CATEGORY_ITEMS, payload: currentItems })
      await handleReset()
    }
  }

  const getSubCategoryItems = async () => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
      params: {
        SubCategoryId: subCategoryId,
        LevelID: zLevelID,
      },
    }
    const response = await API(getConfig)
    if (response.status == 200) {
      const { data } = response.data
      let currentItems = { ...CustomSubCategoryItems }
      currentItems['SUB_CATEGORY_ITEMS_' + subCategoryId] = data

      dispatch({ type: SAVE_CUSTOM_SUB_CATEGORY_ITEMS, payload: currentItems })
      await handleReset()
    }
  }

  const saveItem = async (formData) => {
    const { itemName } = formData
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_ITEM_URL,
      data: {
        // userID: getUserId(),
        organizationID: getOrganizationId(),
        caption: itemName,
        itemLevelID: zLevelID,
        subCategoryId: subCategoryId ? subCategoryId : 0,
        categoryId: categoryId ? categoryId : 0,
      },
    }
    try {
      const response = await API(postConfig)
      if (response && response.data) {
        dispatch(getAllcustomItem(projectId))

        handleReset()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateItem = async (formData) => {
    const { itemName } = formData
    const postConfig = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_ITEM_URL,
      data: {
        itemId: itemData.zItemID,
        caption: itemName,
      },
    }
    try {
      const response = await API(postConfig)
      if (response && response.data) {
        // dispatch(getCustomCategory())
        categoryId && getCategoryItems()
        subCategoryId && getSubCategoryItems()
      }
    } catch (e) {
      console.log(e)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) =>
      title == 'Add Item' ? saveItem(values) : updateItem(values),
  })

  const { itemName = '' } = values
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={10}>
                <TextField
                  id="designation-helper-text"
                  label="Item Name"
                  value={itemName}
                  onChange={handleChange}
                  fullWidth
                  name="itemName"
                  error={errors.itemName && touched.itemName}
                  helperText={
                    errors.itemName && touched.itemName ? errors.itemName : ''
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ marginTop: '1.5rem' }}>
                  Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
