import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  FORGOT_PASSWORD_PATH,
  SIGN_UP_PATH,
  PROJECTS_PATH,
  DASHBOARD_PATH,
  NEW_PASSWORD_PATH,
  TASK_PATH,
  TEAMENDUSER,
} from '../helpers/constants/RoutePaths'
import { useFormik } from 'formik'
import { debounce } from 'underscore'
import NewPassword from '../components/NewPassword'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import logo from '../assets/images/logo.png'
import {
  GET_PARTNER_LIST_URL,
  GET_USER_BY_NAME_EMAIL,
  LOGIN_URL,
  ROSTER_WARNING,
  UPDATE_USERNAME_PASSWORD_PROFILE,
  USER_VERIFY_BY_LOGIN_KEY,
  GET_ALL_ORGANIZATION,
  TEAM_GETURL,
  TEAM_ADD_TAST_USER,
  TEAM_ADD_ORGAN_USER,
} from '../helpers/constants/ApiRoutes'
import { CustomMessage, locationParams } from '../function/HelperFunction'
import { API } from '../helpers/API'
import { authHeader, getQueryParamsByName } from './common/CustomFunction'
import Autocomplete from '@material-ui/lab/Autocomplete'

// Custom Checkbox
const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.primaryColor,
    '&$checked': {
      color: theme.primaryColor,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />)

//Schema
const validationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('First name is required'),
  lastName: Yup.string()
    .trim()
    .required('Last name is required'),
  email: Yup.string()
    .trim()
    .email('Invalid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmpassword: Yup.string()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same'
      ),
    })
    .required('Confirm Password Required'),
})

//state

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f2f2',
  },
  signbg: {
    display: 'flex',
    justifyContent: 'center',
  },
  signinbtn: {
    marginTop: '1rem',
    width: '50%',
    backgroundColor: theme.btnPrimary,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.btnPrimary,
    },
  },
  title: {
    fontFamily: 'Nunito-Regular',
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  linkBlock: {
    margin: '1rem',
  },
  formBlock: {
    margin: '7px',
  },
  card: {
    boxShadow: theme.outShadow,
  },
  container: {
    padding: '8.5rem 0 8.5rem 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
  logoBlock: {
    width: '125px',
    height: '35px',
    marginBottom: '1.5rem',
  },
}))

export default function Newusersignup(props) {
  const classes = useStyles()
  const [isValidId, setValid] = useState(false)
  const [flag, setFlag] = useState(false)
  const [taskUser, setTaskUser] = useState(false)
  const [userDetail, setUserDetail] = useState({})
  const [orgName, setorgName] = useState('')
  const location = useLocation()
  const { state } = location
  const { orgid, ProjectID, domainname } = state

  const [disAble, setdisAble] = useState(false)
  const { id } = useParams()
  const [organizationList, setorganizationList] = useState([])
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const handleLogin = async (formData) => {
    localStorage.setItem('LOG_EMAIL', formData.email)

    const config = {
      method: 'POST',
      url: TEAM_ADD_ORGAN_USER,

      data: {
        organizationID: orgid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
      },
    }
    try {
      const response = await API(config)
      const { status, data, token, UserData } = response.data
      const {
        zUserID = '',
        zOrganizationID = '',
        zFirstName = '',
        zLastName = '',
        zUserRoleID = '',
        zOrderID = '',
      } = UserData[0] || {}
      console.log(UserData)

      localStorage.setItem('AUTH_TOKEN', token)
      localStorage.setItem('CANTOODOMAIN', formData.domain)
      localStorage.setItem('ORGANIZATION_NAME', orgName)
      localStorage.setItem('LOG_USER_ID', zUserID)
      localStorage.setItem('LOG_ORGANIZATION_ID', zOrganizationID)
      localStorage.setItem('LOG_USER_FIRSTNAME', zFirstName)
      localStorage.setItem('LOG_USER_LASTNAME', zLastName)
      localStorage.setItem('USER_ROLE_ID', zUserRoleID)
      localStorage.setItem('ORDER_ID', zOrderID)
      const addUserconfig = {
        method: 'GET',
        url: TEAM_ADD_TAST_USER,
        //  headers: authHeader(),

        params: {
          ProjectID: ProjectID,
          UserID: zUserID,
        },
      }
      const adduserResponse = await API(addUserconfig)
      const { Project, TaskID } = adduserResponse.data
      history.push(`${TEAMENDUSER}/${Project}/${TaskID}`)
    } catch (er) {
      if (er.response) {
        let { data } = er.response
        console.log(data)
        CustomMessage(data.Message, 'error', enqueueSnackbar) //call toster
        //enqueueSnackbar('Error')
      }
    }
  }

  //Formik
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmpassword: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const { email = '', password = '', cpassword = '', domain = '' } = values

  const getUserDetails = async (email, orgId) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_USER_BY_NAME_EMAIL,
      params: {
        email,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      let { data } = response.data
      data = data.filter((rec) => rec.orgId === orgId)
      if (!data[0].lastLoginDate) {
        setTaskUser(true)
        setUserDetail(data[0])
      } else {
        validationSchema.fields.cpassword = ''
      }
    }
  }

  const verifyUserByLoginKey = async (loginKey) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: USER_VERIFY_BY_LOGIN_KEY,
      params: {
        Loginkey: loginKey,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      const { data } = response.data
      getUserDetails(data[0].zEmail, data[0].zOrganizationID)
      setValues((val) => ({
        ...val,
        email: data[0].zEmail,
        password: '',
      }))
    }
  }

  useEffect(() => {
    if (props.match.params.id == 12345) {
      setValid(true)
    }
  }, [props.match.params.id])

  useEffect(() => {
    if (id) {
      verifyUserByLoginKey(id)
      setdisAble(true)
    } else {
      validationSchema.fields.cpassword = ''
    }
  }, [])

  const getOrganization = async (value, newData) => {
    console.log(value)
    const config = {
      method: 'GET',
      url: GET_ALL_ORGANIZATION,
      params: {
        Name: value,
        Email: newData.email,
        count: 20,
      },
    }
    try {
      const response = await API(config)
      console.log(response)
      const { result } = response.data
      const { table1 } = result
      let or = table1.map((value) => ({
        label: value.zOrganizationName,
        title: value.zOrganizationName,
        domain: value?.zDomain,
      }))
      setorganizationList(or)
    } catch (er) {
      console.error(er)
    }
  }

  const organizationSearch = useRef(
    debounce((value, values) => getOrganization(value, values), 500)
  ).current
  const organiZationhandle = (e) => {
    organizationSearch(e.target.value, values)
  }
  const organizationSelect = (e, value) => {
    setValues((val) => ({ ...val, domain: value?.domain || '' }))
    setorgName(value.title)
  }

  return (
    <div className={classes.root}>
      {!flag && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className={classes.container}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <center>
                  <div className={classes.logoBlock}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>

                  <Typography
                    className={classes.title}
                    color="subtitle2"
                    gutterBottom>
                    Welcome!
                  </Typography>
                  <Typography variant="body2" component="p">
                    Sign in to your account
                  </Typography>
                </center>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.formBlock}>
                      <TextField
                        id="email-helper-text"
                        label="First name"
                        value={values.firstName}
                        onChange={handleChange}
                        fullWidth
                        name="firstName"
                        disabled={disAble}
                        error={errors.firstName && touched.firstName}
                        helperText={
                          errors.firstName && touched.firstName
                            ? errors.firstName
                            : ''
                        }
                      />
                      <TextField
                        id="email-helper-text"
                        label="Last name"
                        value={values.lastName}
                        onChange={handleChange}
                        fullWidth
                        name="lastName"
                        disabled={disAble}
                        error={errors.lastName && touched.lastName}
                        helperText={
                          errors.lastName && touched.lastName
                            ? errors.lastName
                            : ''
                        }
                      />
                      <TextField
                        id="email-helper-text"
                        label="Email Address"
                        value={email}
                        onChange={handleChange}
                        fullWidth
                        name="email"
                        disabled={disAble}
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : ''
                        }
                      />
                      <TextField
                        type="password"
                        id="password"
                        label="Password"
                        value={password}
                        onChange={handleChange}
                        fullWidth
                        name="password"
                        error={errors.password && touched.password}
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : ''
                        }
                      />
                      <TextField
                        type="password"
                        id="confirmpassword"
                        label="Confirm password"
                        value={values.confirmpassword}
                        onChange={handleChange}
                        fullWidth
                        name="confirmpassword"
                        error={
                          errors.confirmpassword && touched.confirmpassword
                        }
                        helperText={
                          errors.confirmpassword && touched.confirmpassword
                            ? errors.confirmpassword
                            : ''
                        }
                      />
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.signbg}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.signinbtn}>
                          Sign Up
                        </Button>
                      </Grid>
                      {/* <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={()=>handleSample()}
                      >
                      sample
                    </Button> */}
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {flag && <NewPassword />}
    </div>
  )
}
