import { DialogContent } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import defaultAvatar from '../../../../../../assets/images/defaultAvatar.png'
import { UPDATE_USERNAME_PASSWORD_PROFILE } from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import {
  authHeader,
  getAlertToast,
  getEmail,
  getFirstName,
  getLastName,
  getUserId,
} from '../../../../../common/CustomFunction'
import Swal from 'sweetalert2'
import { useState } from 'react'
import CustomLoader from '../../../../../common/CustomLoader'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default function ProfileEdit(props) {
  const classes = useStyles()

  const { open = '', handleReset } = props
  const [loading, setLoading] = useState(false)
  //Schema
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string()
      .trim()
      .required('Last name is required'),
    // email: Yup.string().required('Email is required'),
    password: Yup.string()
      .trim()
      .required('Password is required'),
    repassword: Yup.string()
      .trim()
      .required('Retype Password is required'),
  })
  //state
  const initialValues = {
    firstName: getFirstName(),
    lastName: getLastName(),
    email: getEmail(),
    password: '',
    repassword: '',
  }

  const profileUpdate = async (values) => {
    setLoading(true)
    const { firstName = '', lastName = '', password = '' } = values
    const config = {
      method: 'PUT',
      url: UPDATE_USERNAME_PASSWORD_PROFILE,
      headers: authHeader(),

      data: {
        userID: getUserId(),
        firstName: firstName,
        lastName: lastName,
        password: password,
        luid: getUserId(),
      },
    }
    try {
      const response = await API(config)
      const { status } = response.data
      if (status == 'Success') {
        Swal.fire(
          getAlertToast('success', 'Profile Details Updated successfully')
        )
        handleReset()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => profileUpdate(values),
  })

  const {
    firstName = '',
    lastName = '',
    email = '',
    password = '',
    repassword = '',
  } = values

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Edit Profile
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={6}>
                <TextField
                  id="name-helper-text"
                  label="First Name"
                  value={firstName}
                  onChange={handleChange}
                  fullWidth
                  name="firstName"
                  error={errors.firstName && touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ''
                  }
                />
                <TextField
                  id="name-helper-text"
                  label="last Name"
                  value={lastName}
                  onChange={handleChange}
                  fullWidth
                  name="lastName"
                  error={errors.lastName && touched.lastName}
                  helperText={
                    errors.lastName && touched.lastName ? errors.lastName : ''
                  }
                />
                <TextField
                  id="name-helper-text"
                  label="Email"
                  value={email}
                  onChange={handleChange}
                  disabled
                  fullWidth
                  name="email"
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                />

                <TextField
                  id="name-helper-text"
                  label="Password"
                  value={password}
                  onChange={handleChange}
                  fullWidth
                  name="password"
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password ? errors.password : ''
                  }
                />
                <TextField
                  id="name-helper-text"
                  label="Retype Password"
                  value={repassword}
                  onChange={handleChange}
                  fullWidth
                  name="repassword"
                  error={errors.repassword && touched.repassword}
                  helperText={
                    errors.repassword && touched.repassword
                      ? errors.repassword
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={4}>
                <center>
                  <img src={defaultAvatar} alt="logo" width="60" height="60" />
                </center>
                <Button
                  variant="outlined"
                  style={{
                    textTransform: 'none',
                    fontSize: '0.5',
                    float: 'right',
                  }}>
                  Profile Image
                </Button>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ marginTop: '6rem', float: 'right' }}>
                  {loading && <CustomLoader loading={loading} />} Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
