import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { SIGN_IN_PATH } from '../helpers/constants/RoutePaths'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import logo from '../assets/images/logo.png'
import { MINIMUM_SIX_CHARACTERS } from '../helpers/constants/ValidationRegex'
import { CustomMessage, locationParams } from '../function/HelperFunction'
import { API } from '../helpers/API'
import {
  USER_VERIFY_BY_LOGIN_KEY,
  GET_ALL_ORGANIZATION,
  ADMIN_ORG_USER_SIGNUP,
} from '../helpers/constants/ApiRoutes'
import { authHeader, getOrganizationId } from './common/CustomFunction'
import TranslateComp from '../helpers/constants/TranslateComp'
import { useState } from 'react'

//Schema
const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Give valid email'),
  password: Yup.string()
    .required('Password is required')
    .matches(
      MINIMUM_SIX_CHARACTERS,
      'Password must contain at least six characters'
    ),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .test('passwords-match', 'Password mismatch', function(value) {
      return this.parent.password === value
    }),
})

//state
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f2f2',
  },
  signinbtn: {
    marginTop: '1rem',
    width: '50%',
    backgroundColor: theme.btnPrimary,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.btnPrimary,
    },
  },
  title: {
    fontWeight: 600,
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  linkBlock: {
    margin: '1rem',
  },
  formBlock: {
    margin: '7px',
  },
  signbg: {
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    boxShadow: theme.outShadow,
  },
  container: {
    padding: '7.3rem 0 7.3rem 0',
    width: '100%',
  },
  logoBlock: {
    width: '125px',
    height: '35px',
    marginBottom: '1.5rem',
  },
}))

export default function AdminSignup() {
  const classes = useStyles()

  const handleLogin = async (formData) => {
    const config = {
      method: 'POST',
      url: ADMIN_ORG_USER_SIGNUP,
      data: {
        organizationID: 0,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
      },
    }
    try {
      const response = await API(config)
      console.log('response', response)
    } catch (error) {
      console.error(error)
    }
  }
  const search = window.location.search

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      organization: 'Apptomate',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const {
    firstName = '',
    lastName = '',
    email = '',
    organization = '',
    password = '',
    confirmPassword = '',
  } = values

  const getOrganization = async (email) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ALL_ORGANIZATION,
      params: {
        Email: email,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      console.log(response.data)
    }
  }

  const userVerifyDetails = async (loginKey) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: USER_VERIFY_BY_LOGIN_KEY,
      params: {
        Loginkey: loginKey,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      const { data } = response.data
      getOrganization(data[0].zEmail)
    }
  }

  useEffect(() => {
    var searchURL = locationParams(window.location.search) || {}
    if (searchURL?.ref) {
      /*setAccess(searchURL?.ref);*/
      userVerifyDetails(searchURL?.ref)
    }
  }, [search])
  const [trafl, setfl] = useState(true)
  return (
    <div className={classes.root}>
      {/* {trafl && <TranslateComp />} */}
      <TranslateComp />
      {/* <div id="google_translate_element"></div> */}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}
        className={classes.container}>
        <Grid item lg={4} md={4} sm={6} xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <center>
                <div className={classes.logoBlock}>
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: '100%', height: '100%' }}
                  />
                </div>
                <Typography
                  className={classes.title}
                  color="subtitle2"
                  gutterBottom>
                  Create An Account
                </Typography>
              </center>
              <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.formBlock}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      value={firstName}
                      onChange={handleChange}
                      fullWidth
                      name="firstName"
                      error={errors.firstName && touched.firstName}
                      helperText={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : ''
                      }
                    />

                    <TextField
                      id="lastName-helper-text"
                      label="Last Name"
                      value={lastName}
                      onChange={handleChange}
                      fullWidth
                      name="lastName"
                      error={errors.lastName && touched.lastName}
                      helperText={
                        errors.lastName && touched.lastName
                          ? errors.lastName
                          : ''
                      }
                    />

                    {/* <TextField
                      id="organization-helper-text"
                      label="Organization"
                      value={organization}
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      name="organization"
                      error={errors.organization && touched.organization}
                      helperText={
                        errors.organization && touched.organization
                          ? errors.organization
                          : ''
                      }
                    /> */}

                    <TextField
                      id="email-helper-text"
                      label="Email Address"
                      value={email}
                      onChange={handleChange}
                      fullWidth
                      name="email"
                      error={errors.email && touched.email}
                      helperText={
                        errors.email && touched.email ? errors.email : ''
                      }
                    />

                    <TextField
                      type="password"
                      id="password"
                      label="Password"
                      value={password}
                      onChange={handleChange}
                      fullWidth
                      name="password"
                      error={errors.password && touched.password}
                      helperText={
                        errors.password && touched.password
                          ? errors.password
                          : ''
                      }
                    />

                    <TextField
                      type="password"
                      id="confirmPassword"
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={handleChange}
                      fullWidth
                      name="confirmPassword"
                      error={errors.confirmPassword && touched.confirmPassword}
                      helperText={
                        errors.confirmPassword && touched.confirmPassword
                          ? errors.confirmPassword
                          : ''
                      }
                    />
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={classes.signbg}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.signinbtn}>
                        Sign Up
                      </Button>
                    </Grid>
                    <center>
                      <div className={classes.linkBlock}>
                        <Link to={SIGN_IN_PATH} className={classes.linkStyle}>
                          Back To Sign In
                        </Link>
                      </div>
                    </center>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}
