import React, { useEffect, useState } from 'react'

function Sample() {
  const [count, setcount] = useState(0)
  const changeScame = () => {
    setcount((v) => v + 1)
  }

  console.log(' ', Firstanme(), secondName())
  function Firstanme() {
    console.log('dasdasd')
  }

  function secondName() {
    console.log('se')
  }
  return (
    <div>
      <button onClick={changeScame}>hihih</button>

      {count % 2 == 0 ? <FirstComp /> : <Second />}
    </div>
  )
}

export default Sample

const FirstComp = () => {
  useEffect(() => {
    console.log('first')

    return () => {
      console.log('firsteclean')
    }
  }, [])

  return <div>Sample</div>
}

const Second = () => {
  useEffect(() => {
    console.log('second')

    return () => {
      console.log('secondclean')
    }
  }, [])
  return <div>Sample3</div>
}
