import {
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Skeleton } from '@mui/material'
import { useFormik } from 'formik'
import React from 'react'
import { useState } from 'react'
import { isThinkwiseAdmin } from '../../../../../../common/CustomFunction'
const useStyles = makeStyles((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'right',
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'auto',
  },
  tabWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
  },
  skillBtn: {
    width: '90px',
    height: '20px',
    marginLeft: '1rem',
    backgroundColor: '#1E88E5',
    textTransform: 'none',
    fontSize: '9px',
    color: '#9ECCF4',
  },
  cardStyle: {
    display: 'block',
    width: '19vw',
    transitionDuration: '0.3s',
    height: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardContentStyle: {
    padding: '6px',
  },
  partnerInfoCard: {
    height: '100%',
    maxHeight: '500px',
    overflow: 'auto',
  },
  titleheading: {
    fontFamily: 'Nunito-SemiBold !important',
  },
}))

const Partner = ({
  partners,
  handlePartner,
  handleAddEditPartner,
  partnerId,
}) => {
  const classes = useStyles()
  // const [partnerVal, setVal] = useState('')
  // let partnerVal = ''
  const handlePartnerDrop = (e, id) => {
    console.log('e', e, id, e.currentTarget.dataset.name)
    // setVal(e.currentTarget.dataset.name)
    handleChange(e)
    handlePartner(id)
  }
  const initialValues = {
    partnerVal: partnerId,
  }
  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
  })
  const { partnerVal = '' } = values
  console.log('partner val', partnerVal)
  return (
    <Card className={classes.cardStyle}>
      <CardContent className={classes.cardContentStyle}>
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div"
            style={{ float: 'left', fontSize: '0.9rem', padding: '0px' }}>
            <b className={classes.titleheading}>Partners</b>
            <> </>
            {isThinkwiseAdmin() && (
              <Button
                color="default"
                startIcon={<AddIcon />}
                style={{
                  float: 'right',
                  textTransform: 'none',
                  padding: '0px',
                }}
                data-title="Add Partner"
                onClick={handleAddEditPartner}>
                <span> Add Partner</span>
              </Button>
            )}
          </Typography>
        </Toolbar>
        {/* {partners && partners.length
          ? partners.map((partner, i) => {
              return ( */}
        <Select
          labelId="partern_label"
          id="partnerVal"
          name="partnerVal"
          value={partnerVal}
          onChange={(e) => {
            handlePartnerDrop(e, e.target.value)
          }}
          fullWidth
          label="Select Scale"
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}>
          {partners &&
            partners.length &&
            partners.map((partner) => (
              <MenuItem value={partner.zPartnerID} data-name={partner.zname}>
                {partner.zName}
              </MenuItem>
            ))}
        </Select>
        {/* )
            })
          : ''} */}
        {/* {partners && partners.length ? (
          <List dense className={classes.root}>
            {partners.map((partner) => {
              return (
                <ListItem
                  key={partner.zPartnerID}
                  style={{
                    cursor: 'pointer',
                    fontFamily: 'Nunito-Regular !important',
                  }}
                  button
                  onClick={() => handlePartner(partner.zPartnerID)}>
                  <ListItemText
                    id={`partner-${partner.zPartnerID}`}
                    primary={partner.zName}
                  />
                </ListItem>
              )
            })}
          </List>
        ) : (
          <>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() => (
              <Skeleton height={30} />
            ))}
          </>
        )} */}
      </CardContent>
    </Card>
  )
}

export default Partner
