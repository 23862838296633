import * as types from '../action/ActionType'
export default function Selectedcategory(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.API_SELECTED_ITEMS:
      console.log(payload, 'payload')
      return (state = payload)
    default:
      return state
  }
}
