import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Box,
  Breadcrumbs,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import logo from '../../assets/images/company_logo.jpg'
import EditIcon from '@material-ui/icons/Edit'
import CustomButton from '../common/CustomButton'
import { useAlert } from 'react-alert'
import {
  GET_LAUNCH_DATE,
  GET_PROJECT_URL,
  GET_SCALE_RATING_BY_SCALE_ID,
  GET_TEAM_DETAILS,
  GET_TEAM_PROGRESS,
  GET_TEAM_RESPOTANT,
  GET_TEAM_SCALE,
  GET_TEAM_SURVEYLINK,
  PROJECT_STATUS_REPORT,
  SAVE_ORGANIZATION_TEMPLATE_URL,
  TEAM_GET_PULSE,
  TEAM_REMAINDERMAIL,
  TEAM_REMAINDER_ALL,
} from '../../helpers/constants/ApiRoutes'
import { useParams } from 'react-router'
import { API } from '../../helpers/API'
import { useSnackbar } from 'notistack'
import { authHeader, getDomain, getUserId } from '../common/CustomFunction'
import Tsresponant from './Tsresponant'
import Tsreport from './Tsreport'
import Tsadmin from './Tsadmin'
import Tsupdateprojectinfor from './Tsupdateprojectinfor'
import moment from 'moment'
import Tsremaildermodal from './Tsremaildermodal'
import AddTeamMember from './Addteammemers/Addteammemers'

const useStyles = makeStyles((theme) => ({
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  projectinfotab: {
    borderBottom: '1px solid #dee2e7',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: '10px',
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontWeight: '600',
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
  },
  mainheading: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  inactive: {
    fontFamily: 'Nunito-Regular !important',
    color: '#1565C0 !important',
  },
  projecthearing: {
    fontFamily: 'Roboto-Bold !important',
    fontSize: '16px',
  },
  tab: {
    fontFamily: 'Roboto-Medium !important',
  },
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)
let formInitial = {
  OwnerID: '',
  OrganizerID: '',
  Name: '',
  Description: '',
}
export default function Tsview() {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [survey, setSurvey] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const [projectStatusReportData, setProjectStatusReportData] = useState([])
  const [scaleTypeLists, setScaleTypeLists] = useState([])
  const [projectInfo, setprojectInfo] = useState(formInitial)
  const [responantList, setresponantList] = useState([])
  const [teamProgress, setteamProgress] = useState({})
  const [teamsurveyLink, setteamsurveyLink] = useState('')
  const [projectTimeline, setprojectTimeline] = useState([])
  const [launchDate, setselectedDate] = useState()
  const [addFlag, setAddFlag] = useState(false)

  let { tsprojectid } = useParams()

  const columns = [
    {
      id: 'participantFirstName',
      displayName: 'Participant FirstName',
    },
    {
      id: 'participantLastName',
      displayName: 'Participant LastName',
    },
    { id: 'raterEmail', displayName: 'Rater Email' },
    { id: 'raterFirstName', displayName: 'Rater FirstName' },
    { id: 'raterLastName', displayName: 'Rater LastName' },
    {
      id: 'raterRelationship',
      displayName: 'Rater Relationship',
    },
    { id: 'taskStatus', displayName: 'Task Status' },
    { id: 'url', displayName: 'url' },
  ]

  const csvReport = {
    filename: `${projectDetails.zName}_ProjectStatus.csv`,
    columns: columns,
    datas: projectStatusReportData,
  }

  const getProject = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_DETAILS,
      params: {
        projectID: tsprojectid,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        const {
          zOwnerID,
          zOrganizerID,
          zDescription,
          zName,
          zDueDateFormat,
        } = data.projectDetails[0]
        setProjectDetails(data.projectDetails[0])
        setprojectInfo({
          OwnerID: zOwnerID,
          OrganizerID: zOrganizerID,
          Name: zName,
          Description: zDescription,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getProjectStatusReport = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_REMAINDERMAIL,

      params: {
        ProjectID: tsprojectid,
        // EmailText: '<h1 >hello</h1>',

        EmailText: '',
        subject: '',
        UserID: getUserId(),
        // UserIdentityName: "",
        BaseUrl: teamsurveyLink,
        RemindAllUser: true,
        sendTest: false,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
      }
    } catch (e) {
      console.log(e)
    }
  }

  const addMembers = () => {
    setAddFlag(!addFlag)
  }

  const viewSurvey = () => {
    setSurvey(!survey)
  }
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const { enqueueSnackbar } = useSnackbar()
  const alert = useAlert()
  const [remindFlag, setRemindFlag] = useState(false)
  const reminderFun = (value) => {
    if (value == 'sent') {
      // CustomMessage('User Invited', 'success', enqueueSnackbar)
      alert.success('Invitation sent')
    }
    setRemindFlag(!remindFlag)
  }
  const [editFlag, setFlag] = useState(false)
  const editProject = () => {
    setFlag(true)
  }
  const handleReset = () => {
    setFlag(false)
  }

  const getteamProgress = async (tempFlag) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_PROGRESS,
      params: {
        projectID: tsprojectid,
        UserId: getUserId(),
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setteamProgress(response.data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getteamSurveyLink = async () => {
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: GET_TEAM_SURVEYLINK,
      data: {
        ProjectID: tsprojectid,
        Baseurl: `http://${getDomain()}`,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        let getId = data?.SurveyURL.split('/')
        const [lastNumber] = getId.slice(-1)
        setteamsurveyLink(window.location.origin + '?UrlID=' + lastNumber)
      }
    } catch (e) {
      console.log(e)
    }
  }
  //GET_TEAM_RESPOTANT
  const getteamRespotant = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_RESPOTANT,
      params: {
        ProjectID: tsprojectid,
        UserId: getUserId(),
        tab: 'Respondents',
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setresponantList(data.TabList)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const successCall = () => {
    getteamRespotant()
  }
  const getteamScale = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_SCALE,
      params: {
        ProjectID: tsprojectid,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setScaleTypeLists(data.scalingInformation)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const projectSuccess = () => {
    getProject()
    setFlag(false)
  }
  //TEAM_GET_PULSE
  const getProjectPlus = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_GET_PULSE,
      params: {
        ProjectID: tsprojectid,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response.data
      if (response) {
        setprojectTimeline(data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getLaunchDate = async () => {
    const config = {
      method: 'GET',
      url: GET_LAUNCH_DATE,
      headers: authHeader(),
      params: {
        ProjectID: tsprojectid,
      },
    }
    const response = await API(config)
    const { data } = response.data

    console.log('response', `${data[0].zLaunchDate} ${data[0].zLaunchTime}`)
    setselectedDate(data[0].zLaunchDate)
  }
  useEffect(() => {
    getProjectPlus()
    getProject()
    getteamSurveyLink()
    getteamProgress()
    getteamScale()
    getteamRespotant()
    getLaunchDate()
  }, [])

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6} lg={6} md={6}>
          <Typography className={classes.mainheading} variant="h6" gutterBottom>
            Team Pulse - {projectDetails ? projectDetails.zName : 'N/A'}
          </Typography>

          {/* <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.inactive} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.inactive} color="textPrimary">
              Team Pulse
            </Typography>
            <Typography className={classes.inactive} color="textPrimary">
              Projects
            </Typography>
            <Typography style={{ color: '#0000008A' }} color="textPrimary">
              {projectDetails ? projectDetails.zName : 'N/A'}
            </Typography>
          </Breadcrumbs> */}
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          {/* <div
            className={classes.sideHead}
            style={{ display: 'flex', justifyContent: 'right' }}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div> */}
        </Grid>
        <Grid item xs={2}>
          {/* {isInvitationSent ? (
            <>
            <CustomButton
              className={classes.dateBtn}
              type="submit"
              startIcon={<CheckCircleOutlineIcon />}
              endIcon={<CloseIcon onClick={reminderFun} />}
            >
              Invitation Sent
            </CustomButton>
            </>
          ) : (
            ""
          )} */}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <Grid item xs={12} style={{ marginTop: '1rem', padding: '10px' }}>
          {/* Table tools */}
          <Toolbar className={classes.root}>
            <Typography
              className={classes.tblHead}
              variant="h6"
              id="tableTitle"
              component="div">
              Project Information
              <> </>
              <IconButton
                aria-label="delete"
                className={classes.icoBtnInside}
                size="small"
                onClick={editProject}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            </Typography>
          </Toolbar>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={3}
          style={{
            width: '100%',
            margin: 0,
            padding: '0px 20px',
          }}>
          <Grid item xs={2}>
            <p style={{ fontWeight: 'bold' }}>
              <b className={classes.projecthearing}>
                {projectDetails ? projectDetails.zName : 'N/A'}
              </b>
            </p>
            <span>{projectDetails ? projectDetails.zDescription : 'N/A'}</span>
          </Grid>

          <Grid item xs={2}>
            <b>
              {' '}
              <p className={classes.projecthearing}>Launch Date:</p>
            </b>
            <span>
              <table>
                {/* {projectTimeline.map((v, i) => {
                  return (
                    <tr>
                      {' '}
                      <td>Pulse:{i + 1}</td>
                      <td>{v.zTimeLine}</td>{' '}
                    </tr>
                  )
                })} */}

                {projectDetails
                  ? moment(projectDetails.zLaunchDate).format('MM/DD/YYYY')
                  : ''}
                {projectDetails.zLaunchTime ? ' / ' : ' '}
                {projectDetails ? projectDetails.zLaunchTime : ''}
              </table>
            </span>
          </Grid>
          <Grid item xs={2}>
            <b>
              {' '}
              <p className={classes.projecthearing}>Due Date:</p>
            </b>
            <span>
              <table>
                {projectDetails
                  ? moment(projectDetails.zDueDateFormat).format('MM/DD/YYYY')
                  : ''}
              </table>
            </span>
          </Grid>
          <Grid item xs={2}>
            <b>
              <p className={classes.projecthearing}>Survey Adminstrator:</p>
            </b>
            <span>
              {projectDetails ? projectDetails.zOrganizerName : 'N/A'}
            </span>
          </Grid>
          <Grid item xs={2}>
            <b>
              <p className={classes.projecthearing}>Project Status:</p>
            </b>
            <span>
              {projectDetails ? projectDetails.zProjectStatusName : 'N/A'}
            </span>
          </Grid>
          <Grid item xs={2}>
            <b>
              <p className={classes.projecthearing}>Progress:</p>
            </b>
            <span>{projectDetails ? teamProgress?.ProgressText : 'N/A'}</span>
          </Grid>

          {/* <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={viewSurvey}
            >
              View Survey
            </Button>
          </Grid> 
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="Save as Organizational Template"
            />
            </Grid>*/}
          <Grid item xs={10}>
            {projectDetails && (
              <Typography>LINK TO THIS SURVEY:{teamsurveyLink}</Typography>
            )}
          </Grid>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            {/* <CustomButton
              className={classes.dateBtn}
              type="submit"
              onClick={getProjectStatusReport}>
              Remind All Incomplete
            </CustomButton> */}
          </Grid>

          <Grid item xs={12}>
            <CusTab
              value={activeTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto"
              className={classes.projectinfotab}>
              <Tab
                className={classes.tab}
                label="TEAM MEMBERS"
                {...a11yProps(0)}
              />
              <Tab className={classes.tab} label="REPORT" {...a11yProps(0)} />
              <Tab className={classes.tab} label="ADMIN" {...a11yProps(0)} />
            </CusTab>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={activeTab} index={0}>
              <Grid item xs={3} style={{ float: 'right', paddingRight: '5px' }}>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.headBtn}
                  onClick={addMembers}>
                  + Add
                </Button>
              </Grid>{' '}
              <Tsresponant
                successCall={successCall}
                responantList={responantList}
                projectDetails={projectDetails}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <Tsreport projectDetails={projectDetails} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Tsadmin
                projectDetails={projectDetails}
                scaleTypeLists={scaleTypeLists}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Paper>
      {editFlag && (
        <Tsupdateprojectinfor
          dueDate={projectDetails}
          open={editFlag}
          handleReset={handleReset}
          formdata={projectInfo}
          responsuccessCall={projectSuccess}
        />
      )}
      {survey && ''
      // <ViewSurveyDetails
      //   open={survey}
      //   handleReset={viewSurvey}
      //   title={'View Survey'}
      // />
      }
      {addFlag && (
        <AddTeamMember
          open={addFlag}
          teamsurveyLink={teamsurveyLink}
          handleReset={() => {
            addMembers()
          }}
        />
      )}
    </div>
  )
}
