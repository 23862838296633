import * as types from '../action/ActionType'
import { THINKWISEINITIAL_STORE } from '../store/IntialStore'
export default function ThinkwiseCustomselectedlist(
  state = THINKWISEINITIAL_STORE,
  action
) {
  const { type, payload } = action
  switch (type) {
    case types.SELECT_CUSTOM_CATEGORY:
      return [...state, payload]
    case types.DELETE_CUSTOM_CATEGORY:
      let newState = [...state]
      let updates = newState.filter((item) => item.zCompetencyID != payload)
      return (state = updates)
    case types.ADD_ALL_CUSTOM_CATEGORY:
      return (state = payload)
    case types.UNCHECK_ALL_CUSTOM_CATEGORY:
      return (state = [])
    case types.APICUSTOM_SELCTEDITEM:
      return (state = payload)
    default:
      return state
  }
}
