import React, { Fragment, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  AppBar,
  Menu,
  MenuItem,
  Toolbar,
  IconButton,
  Badge,
  InputBase,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import NotificationsIcon from '@material-ui/icons/Notifications'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  DASHBOARD_PATH,
  PROFILE_PATH,
  SIGN_IN_PATH,
} from '../../helpers/constants/RoutePaths'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ProfileEdit from '../thinkwise_360/projects/steps/item_selection/Profile/ProfileEdit'
import { useSnackbar } from 'notistack'
import { CustomMessage } from '../../function/HelperFunction'
import logo from '../../assets/images/logo.png'
import clogo from '../../assets/images/company_logo.jpg'
import { authHeader, getOrganizationId } from '../common/CustomFunction'
import { GET_ORGANIZATION_LOGO_URL } from '../../helpers/constants/ApiRoutes'
import { API } from '../../helpers/API'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  toolmenu: {
    background: 'black !important',
  },
}))

export default function Navbar(props) {
  const { classes } = props
  let history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const styleclasses = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)
  const [editFlag, setEditFlag] = useState(false)
  const [orgLogoLoading, setOrgLogoLoading] = useState(false)
  const [orgLogo, setOrgLogo] = useState('')

  const orgLogoSelector = useSelector((state) => state.AccountsUsersReducer)

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
    //history.push(PROFILE_PATH)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const isMenuOpen = Boolean(anchorEl)

  const logOutFun = () => {
    // history.push(DASHBOARD_PATH)

    sessionStorage.clear()
    localStorage.clear()
    history.push(SIGN_IN_PATH)
    CustomMessage('Successful Logout', 'success', enqueueSnackbar) //call toster
  }
  let Logout = () => {
    setAnchorEl(null)
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to signout',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        logOutFun()
      }
    })
  }
  const handleReset = () => {
    setEditFlag(!editFlag)
  }

  const getOrangizationLogo = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORGANIZATION_LOGO_URL,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    setOrgLogoLoading(true)
    try {
      const response = await API(getconfig)
      const { status, data } = response.data

      if (response.status == 200) {
        setOrgLogoLoading(false)
        setOrgLogo(data[0])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setOrgLogoLoading(false)
    }
  }

  useEffect(() => {
    getOrangizationLogo()
  }, [orgLogoSelector])
  return (
    <Fragment>
      <AppBar position="inherit" className={clsx(classes.appBar)}>
        <Toolbar
          className={classes.toolmenu}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
          <div></div>
          <div
            // className={classes.sideHead}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginRight: '750',
            }}>
            {/* <img src={clogo} alt="logo" width="100" height="60" /> */}
            {orgLogo ? (
              <img
                src={orgLogo?.zLogoURL || ''}
                alt="logo"
                width="auto"
                height="40"
              />
            ) : (
              ''
            )}
          </div>

          <div className={classes.iconMenus}>
            {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge
                badgeContent={17}
                color="secondary"
                classes={{ badge: classes.badgeColor }}>
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge
                badgeContent={4}
                color="secondary"
                classes={{ badge: classes.badgeColor }}>
                <ChatBubbleIcon />
              </Badge>
            </IconButton> */}
            {/* <Link
              to={{
                pathname: '/profile',
                // state: { editFlag: true }
              }}
              className={styleclasses.linkStyle}>
              <IconButton aria-label="" color="inherit">
                <AccountCircleIcon />
              </IconButton>
            </Link> */}

            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={'primary-search-account-menu'}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit">
              <MoreVertIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={'primary-search-account-menu'}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}>
        {/* <Link to={PROFILE_PATH} className={styleclasses.linkStyle}> */}
        <MenuItem onClick={handleReset}>Profile</MenuItem>
        {/* </Link> */}
        {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
        <MenuItem onClick={Logout}>Logout</MenuItem>
      </Menu>
      {editFlag == true && (
        <ProfileEdit open={editFlag} handleReset={handleReset} />
      )}
    </Fragment>
  )
}
