import { Chart } from 'chart.js'
import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  FrameAnchorType,
  Media,
  Header,
  HeadingLevel,
  HorizontalPositionAlign,
  ImageRun,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  VerticalPositionAlign,
  PageNumber,
  WidthType,
  OverlapType,
  RelativeVerticalPosition,
  RelativeHorizontalPosition,
  TableAnchorType,
  TableLayoutType,
  TabStopType,
  TabStopPosition,
  VerticalAlign,
  SectionType,
} from 'docx'

import { saveAs } from 'file-saver'
import threesixty from '../../../../../../assets/images/arrow.png'
import threesixtySurvey from '../../../../../../assets/images/word/360survey.png'
import {
  generateChart,
  generatePrepostchart,
} from '../../../../../../function/GenerateChart'
import meena from './meean1.json'
import moment from 'moment'
export const PrepostReport = async (datablob) => {

  const headerImage = await fetch(threesixty).then((response) =>
    response.blob()
  )
  const surveyLogo = await fetch(threesixtySurvey).then((response) =>
    response.blob()
  )
  const {
    strAsrSummResName,
    strAsrSummResDesc,
    resultByCompentency,
    maxScale,
    minScale,
    resultByCompCategory,
    strRaterCatDesc,
    strSummaryResults,
    strByRespCat,
    reportTitle,
    reportName,
    projectName,
    organizationName,
    projectCompletionDate,
    projectManager,
  } = datablob
  const { endDate1, endDate2 } = projectCompletionDate
  const { projectName1, projectName2 } = projectName
  const borderNone = {
    top: {
      style: BorderStyle.NIL,
      space: 2,
    },
    bottom: {
      style: BorderStyle.NIL,
      space: 2,
    },
    left: {
      style: BorderStyle.NIL,
      space: 2,
    },
    right: {
      style: BorderStyle.NIL,
      space: 2,
    },
  }

  const fullWidth = {
    size: 100,
    type: WidthType.PERCENTAGE,
  }

  // Detail items table forming starts here

  // Detailed Item data forming ends here

  // Open Ended data forming starts here

  // Get the chart's base64 image string

  //const projectUser = data.ProjectUser[0]
  //const headerText = projectUser.zOrganizationName         const headerImage = await fetch(threesixty).then((response) =>

  const FONT_STYLE = 'Calibri'
  let scales = []

  const pageTtile = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 31,
          font: FONT_STYLE,
        }),
      ],
      thematicBreak: true,
    })
  }
  const leaveoneline = () => {
    return new Paragraph('')
  }
  const heading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 28,
          font: FONT_STYLE,
        }),
      ],
    })
  }
  const normalheading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 20,
          font: FONT_STYLE,
        }),
      ],
    })
  }

  const centerHeading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 30,
          font: FONT_STYLE,
        }),
      ],
      alignment: AlignmentType.CENTER,
    })
  }
  const pageHeader = () => {
    return {
      default: new Header({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: organizationName || '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['Page ', PageNumber.CURRENT],
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['360 Report ', 'Test User'],
                          }),

                          // ...datablob.map((val) => {
                          //   console.log(val)
                          // return
                          new ImageRun({
                            data: headerImage,
                            transformation: {
                              width: 30,
                              height: 30,
                            },
                          }),
                          // }),
                        ],

                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: AlignmentType.CENTER,
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: fullWidth,
          }),
        ],
      }),
    }
  }

  console.log(datablob)
  const normalText = (text) => {
    return new Paragraph({
      text: text,
    })
  }

  const pageFooter = () => {
    return {
      default: new Footer({
        children: [
          new Paragraph({
            text: '',
          }),
        ],
      }),
    }
  }

  const compHeading = (text = '') => {
    return new TextRun({
      text: text,
      size: 16,
      font: FONT_STYLE,
      bold: true,
      color: 'cccccc',
      allCaps: true,
    })
  }

  const slash = () => {
    return new TextRun({
      text: '|',
      size: 16,
      font: FONT_STYLE,
      bold: true,
      color: 'cccccc',
      allCaps: true,
    })
  }

  const normalTextRun = (text) => {
    return new TextRun({
      text: `\t${text}\t`,
      size: 18,
      font: FONT_STYLE,
    })
  }

  const Mailpage = {
    headers: {
      default: new Header({
        children: [
          new Paragraph({
            text: organizationName,
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.LEFT,
          }),
        ],
      }),
    },
    children: [
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: surveyLogo,
                        transformation: {
                          width: 100,
                          height: 100,
                        },
                      }),
                    ],
                    verticalAlign: VerticalPositionAlign.CENTER,
                  }),
                ],
                width: {
                  size: 30,
                  type: WidthType.DXA,
                },
                borders: borderNone,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '360 Group Report',
                    heading: HeadingLevel.TITLE,
                  }),
                  new Paragraph({
                    text: reportTitle,
                    heading: HeadingLevel.TITLE,
                  }),
                ],
                borders: borderNone,
                verticalAlign: VerticalPositionAlign.CENTER,
              }),
            ],
          }),
        ],
        float: {
          relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
          relativeVerticalPosition: RelativeVerticalPosition.CENTER,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        layout: TableLayoutType.FIXED,
      }),
    ],
    footers: {
      default: new Footer({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Name',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: FONT_STYLE,
                            size: 29,
                            style: 'Strong',
                            text: projectName1,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: FONT_STYLE,
                            size: 29,
                            style: 'Strong',
                            text: projectName2,
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                          new TextRun({
                            text: 'Report Data',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                          new TextRun({
                            text: moment(new Date()).format('MM/DD/YYYY'),
                            font: FONT_STYLE,
                            style: 'Strong',
                            size: 22,
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Manager',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: projectManager,

                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: projectManager,

                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project  Completion Date',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: endDate1,
                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: endDate2,
                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: fullWidth,
          }),
        ],
      }),
    },
  }

  const keyFrame = (val) => {
    return new Paragraph({
      frame: {
        position: {
          x: 1000,
          y: val * 1000,
        },
        width: 8000,
        height: 1000,
        anchor: {
          horizontal: FrameAnchorType.MARGIN,
          vertical: FrameAnchorType.MARGIN,
        },
        alignment: {
          x: HorizontalPositionAlign.CENTER,
          y: VerticalPositionAlign.TOP,
        },
      },
      border: {
        top: {
          color: 'auto',
          space: 1,
          value: 'single',
          size: 6,
        },
        bottom: {
          color: 'auto',
          space: 1,
          value: 'single',
          size: 6,
        },
        left: {
          color: 'auto',
          space: 1,
          value: 'single',
          size: 6,
        },
        right: {
          color: 'auto',
          space: 1,
          value: 'single',
          size: 6,
        },
      },
      children: [
        new TextRun(`Hello World${val}`),
        new TextRun({
          text: 'Foo Bar',
          bold: true,
        }),
        new TextRun({
          text: '\tGithub is the best',
          bold: true,
        }),
      ],
    })
  }

  let NONAME = 'NONAME'
  let compantencyData = {}

  resultByCompentency.forEach((comp) => {
    if (comp.categoryType) {
      if (compantencyData[comp.categoryType]) {
        compantencyData[comp.categoryType] = {
          ...compantencyData[comp.categoryType],
          data: [
            ...compantencyData[comp.categoryType].data,
            {
              average: comp.average,
              cababilityName: comp.cababilityName,
              categoryType: comp.categoryType,
              compentencyName: comp.compentencyName,
              method: comp.method,
              response: comp.response,
            },
          ],
        }
      } else {
        compantencyData[comp.categoryType] = {
          head: comp.compentencyName,
          subheader: comp.categoryType,
          data: [
            {
              average: comp.average,
              cababilityName: comp.cababilityName,
              categoryType: comp.categoryType,
              compentencyName: comp.compentencyName,
              method: comp.method,
              response: comp.response,
            },
          ],
        }
      }
    } else {
      if (compantencyData[NONAME + comp.compentencyName]) {
        compantencyData[NONAME + comp.compentencyName] = {
          ...compantencyData[NONAME + comp.compentencyName],
          data: [
            ...compantencyData[NONAME + comp.compentencyName].data,
            {
              average: comp.average,
              cababilityName: comp.cababilityName,
              categoryType: comp.categoryType,
              compentencyName: comp.compentencyName,
              method: comp.method,
              response: comp.response,
            },
          ],
        }
      } else {
        compantencyData[NONAME + comp.compentencyName] = {
          head: comp.compentencyName,
          subheader: comp.categoryType,
          data: [
            {
              average: comp.average,
              cababilityName: comp.cababilityName,
              categoryType: comp.categoryType,
              compentencyName: comp.compentencyName,
              method: comp.method,
              response: comp.response,
            },
          ],
        }
      }
    }
  })
  let compentenct = []

  await Promise.all(
    Object.values(compantencyData).map(async (values, index) => {
      const { data } = values
      let labelData = data.map((val) => {
        if (val.method) {
          return val.cababilityName + val.response || ''
        } else {
          return val.cababilityName + val.method
        }
      })

      let dataValue = data.map((val) => {
        if (val.average > 0) {
          return val.average
        } else {
          return 0
        }
      })
      // await compentenct.push(
      //   new Paragraph({
      //     children: [
      //       new TextRun({
      //         text: values.head + ':' + values.subheader,
      //         bold: true,
      //         size: 31,
      //         font: FONT_STYLE,
      //       }),
      //     ],
      //   })
      // )
      await compentenct.push(
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: values.head + ':' + values.subheader || '',
                          bold: true,
                          size: 31,
                          font: FONT_STYLE,
                        }),
                      ],
                    }),
                    new Paragraph({
                      children: [
                        new ImageRun({
                          data: await generateChart(
                            { label: labelData, data: dataValue },
                            Date.now() + index,
                            {
                              minimumvalue: minScale,
                              maximumvalue: maxScale,
                            }
                          ),
                          transformation: {
                            width: 550,
                            height: 210,
                          },
                        }),
                      ],
                    }),
                  ],
                  borders: borderNone,
                }),
              ],
            }),
          ],
        }),
        leaveoneline(),
        leaveoneline()
      )
    })
  )

  let compantencydatacategory = {}
  let capabliteInnerdata = {}
  let CAPABLITYNONAME = 'CAPABLITYNONAME'
  resultByCompCategory.forEach((comp) => {
    let innerdada = {}
    compantencydatacategory[
      comp.categoryType || NONAME + comp.compentencyName
    ] = {
      ...compantencydatacategory[
        comp.categoryType || NONAME + comp.compentencyName
      ],
      head: comp.categoryType,
      name: comp.compentencyName,
      cababilityName: comp.cababilityName,
      data: [
        ...(compantencydatacategory[
          comp.categoryType || NONAME + comp.compentencyName
        ]?.data || []),
        {
          method: comp.method,
          response: comp.response,
          cababilityName: comp.cababilityName,
          average: comp.average,
        },
      ],
    }
  })

  let allcategorydata = []
  Object.values(compantencydatacategory).forEach((catego) => {
    let temp = {
      cababilityName: catego.cababilityName,
      head: catego.head,
      name: catego.name,

      data: {},
    }
    console.log(catego)
    let sample = {}
    catego.data.forEach((inside) => {
      sample[inside.cababilityName] = [
        ...(sample[inside.cababilityName] || []),
        {
          method: inside.method,
          response: inside.response,
          avg: inside.average,
        },
      ]
    })
    temp.data = sample
    allcategorydata.push(temp)
  })

  let newcompentecy = []
  await Promise.all(
    allcategorydata.map(async (all, index) => {
      let label = []
      let value = []
      Object.entries(all.data).forEach((da) => {
        da[1].forEach((va) => {
          label.push(va.response + '' + va.method)
          if (va.avg > 0) {
            value.push(va.avg)
          } else {
            value.push(0)
          }
        })
      })
      console.log(all.name + ':' + all.head || '', label, value)
      await newcompentecy.push(
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: all.name + ':' + all.head || '',
                          bold: true,
                          size: 31,
                          font: FONT_STYLE,
                        }),
                      ],
                    }),
                    leaveoneline(),
                    new Paragraph({
                      children: [
                        new ImageRun({
                          data: await generatePrepostchart(
                            { label: label, data: value },
                            Date.now() - index,
                            {
                              minimumvalue: minScale,
                              maximumvalue: maxScale,
                            },

                            true,
                            'x'
                          ),
                          transformation: {
                            width: 300,
                            height: 200,
                          },
                        }),
                      ],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  borders: borderNone,
                }),
              ],
            }),
          ],
        }),
        leaveoneline(),
        leaveoneline()
      )
    })
  )
  const compantencybyrespontnet = {
    headers: pageHeader(),
    footers: pageFooter(),

    children: [
      pageTtile(strSummaryResults + '-' + strAsrSummResName),
      normalheading(strRaterCatDesc),
      leaveoneline(),
      ...newcompentecy,
    ],
  }
  const compantency = {
    headers: pageHeader(),
    footers: pageFooter(),

    children: [
      pageTtile(strAsrSummResName),
      normalheading(strAsrSummResDesc),
      leaveoneline(),
      ...compentenct,
    ],
  }
  //   const doc = new Document({
  //     sections: [compantency],
  //   })

  const dojc = new Document({
    sections: [
      // compantency,
      {
        headers: pageHeader(),
        footers: pageFooter(),
        properties: {
          type: SectionType.CONTINUOUS,
        },
        children: [
          new ImageRun({
            data: datablob,
            transformation: {
              width: 200,
              height: 200,
            },
          }),
        ],
      },
    ],
  })

  //   const doc = new Document({
  //     sections: [compantency],
  //   })

  const doc = new Document({
    sections: [Mailpage, compantency, compantencybyrespontnet],
  })
  const input = new Document({
    sections: [Mailpage, compantency, compantencybyrespontnet],
  })
 
  return {
    documnt: doc,
    name: ` ${reportTitle}_360Prepost_${Date.now()}` + '.doc',
  }
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, 'prepost.docx')
  })

  //   const doc2 = new Document();
  // const logo = Media.addImage(doc, fs.readFileSync("myImage.png"), 200, 200); // ERROR HERE: "fs.readFileSync is not a function
  // doc2.addSection({children: [new Paragraph(logo)]});
  // const download = () => {
  //     // download function...
  // };
}
