import React, { useEffect } from 'react'
import { createRef } from 'react'
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export'
import CustomButton from '../../../../../common/CustomButton'
import { makeStyles } from '@material-ui/core/styles'
import { useState } from 'react'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { PROJECT_SUMMARY_REPORT } from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
const useStyles = makeStyles((theme) => ({
  buttona: {
    background: '#64B5F6 !important',
  },
}))
export default function SummaryReportFileWhole(props) {
  const classes = useStyles()
  const [sheet1, setSummaryReportsheet1] = useState()
  const [sheet2, setSummaryReportsheet2] = useState()
  const [loading, setLoading] = useState(false)
  const { projectname = '', organizerName, expFlag = false, projectId } = props
  const getProjectSummaryReport = async (projectId) => {
    setLoading(true)
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: PROJECT_SUMMARY_REPORT,
      params: {
        UserID: getUserId(),
        ProjectID: projectId,
      },
    }
    try {
      const response = await API(getconfig)
      // const response = projectsummary
      const { data } = response
      if (response) {
        let userArray = []
        data.ReportUser_LstByProjectt.map((el, i) => {
          let nameObj = {}
          nameObj['firstName'] = el.zFirstName
          nameObj['lastName'] = el.zLastName

          userArray.push(nameObj)
        })
        const result = userArray.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.firstName === thing.firstName && t.lastName === thing.lastName
            )
        )
        // const objEntries = Object.entries(data.ReportAssessmentScoring360_GetItem);
        // console.log("objectEnt", objEntries)
        // const objectEntriesss = Object.fromEntries(objEntries)
        // console.log("ObjectEntriesss",objectEntriesss);
        let resultArray = []
        result.map((el, i) => {
          //let resultObj = {...objectEntriesss}
          // resultObj['firstName'] = el.firstName
          // resultObj['lastName'] = el.lastName
          // resultObj['ind'] = i+1
          resultArray.push(el)
        })
        let allItemsStat = []
        data.ReportAssessmentScoring360_GetItem.map((el, i) => {
          let newObj = {}
          newObj['zInd'] = i + 1
          newObj['zCompetencyName'] = el.zCompetencyName
          newObj['zCompetencyID'] = el.zCompetencyID
          newObj['zCapabilityName'] =
            el.zCapabilityName == null ? '' : el.zCapabilityName
          newObj['zCapabilityID'] = el.zCapabilityID
          newObj['zCaption'] = el.zCaption
          newObj['zRelationshipID'] = el.zRelationshipID
          newObj['zAverage'] = el.zAverage

          allItemsStat.push(newObj)
        })

        const projectRelationshipProject = data.ReportRelationship_LstByProject
        const projectUsers = data.ReportUser_LstByProjectt.filter(
          (rec) =>
            rec.zProjectUserTypeID === 3 && rec.zProjectUserStatusID === 3
        )
        const item = data.ReportAssessmentScoring360_GetItem
        const customItem = data.ReportAssessmentScoring360_GetCustomItem
        console.log(projectRelationshipProject)
        // setProjectRelationship(projectRelationshipProject)

        item.map((el, i) => {
          let newObj = {}
          newObj['zInd'] = i + 1
          newObj['zCompetencyName'] = el.zCompetencyName
          newObj['zCompetencyID'] = el.zCompetencyID
          newObj['zCapabilityName'] =
            el.zCapabilityName == 'null' ? '' : el.zCapabilityName
          newObj['zCapabilityID'] = el.zCapabilityID
          newObj['zCaption'] = el.zCaption
          newObj['zRelationshipID'] = el.zRelationshipID
          newObj['zAverage'] = el.zAverage

          allItemsStat.push(newObj)
        })
        setSummaryReportsheet2(allItemsStat)

        let temp = {}
        // sheet 1 data forming
        console.log(projectUsers)
        let individualItemStat = []
        let responseData = []
        await Promise.all(
          projectUsers.map(async (projectUser, index) => {
            if (projectUser.zProjectUserTypeName === 'Subject') {
              const getconfig = {
                method: 'GET',
                headers: authHeader(),

                url: PROJECT_SUMMARY_REPORT,
                params: {
                  ProjectID: projectId,
                  UserID: projectUser.zUserID,
                },
              }

              const response = await API(getconfig)
              responseData.push({
                response: response,
                projectUser: projectUser,
              })
            }
          })
        )
        let allRelationShip = {}
        responseData.map((el, i) => {
          const { data } = el.response
          const { FinalCalc, ReportRelationship_LstByProject } = data
          const { projectUser } = el
          const { zFirstName, zLastName } = projectUser
          let relationshipData = {
            Average: [],
          }
          ReportRelationship_LstByProject.forEach((value) => {
            relationshipData[value.zName] = []
            allRelationShip[value.zName] = []
          })

          let newFinalcalc = FinalCalc.filter(
            (value) =>
              value.zRptComp == 0 && value.zParticipantID == projectUser.zUserID
          )
          let finalvalueSplit = {}
          newFinalcalc.forEach((value) => {
            relationshipData[value.zCategoryNm] = [
              ...(relationshipData[value?.zCategoryNm] || []),
              { ...value, zCapabilityNm: value.zCapabilityNm || 'Total' },
            ]
            finalvalueSplit[value?.zCapabilityNm || 'Total'] = {
              ...(finalvalueSplit[value?.zCapabilityNm] || {}),
              ...value,
              zCapabilityNm: value?.zCapabilityNm || 'Total',
            }
          })
          Object.entries(finalvalueSplit).forEach((value) => {
            // console.log(
            //   relationshipData[value[1].zCategoryNm].find(
            //     (el) => el.zCapabilityNm === value[1].zCapabilityNm
            //   )
            // )
            let data = []
            let rel = {}
            Object.entries(relationshipData).forEach((el) => {
              el[1].forEach((datapoint) => {
                console.log(datapoint)
                if (datapoint.zCapabilityNm == value[0]) {
                  if (el[0] == 'Self') {
                    rel[el[0]] = datapoint.zAverage
                  } else {
                    rel[el[0]] =
                      datapoint.zNumberOfRatings < 3
                        ? datapoint.zAverage
                        : datapoint.zAverage
                  }
                }
              })
              // console.log(nevalue, projectUser.zUserID)
              // data.push(el[1].filter((value) => value.zCategoryNm === value[0]))
            })

            // console.log(relationshipData, projectUser.zUserID, value[0], rel)

            individualItemStat.push({
              ...value[1],
              zCapabilityName: value[0] == null ? '' : value[0],
              zFirstName,
              zLastName,

              ...rel,
            })
          })
        })

        // setProjectRelationship(Object.keys(allRelationShip))
        setSummaryReportsheet1(individualItemStat)
        let narr = []
        individualItemStat.map((rex, i) => {
          console.log('rec.zfirstName', rex.zFirstName)
          const { zFirstName, zLastName } = rex
          narr.push({ zFirstName, zLastName })
        })
        console.log('firstName', narr)
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getProjectSummaryReport(projectId)
  }, [])
  console.log('project', sheet1, sheet2)
  useEffect(() => {
    if (sheet1) {
      sheet1.unshift({
        'Average (excl. Self)': '',
        'Direct Report': '',
        Manager: '',
        Other: '',
        Peer: '',
        Self: '',
        oid: '',
        zAverage: '',
        zCapabilityID: '',
        zCapabilityName: '',
        zCapabilityNm: '',
        zCategoryNm: '',
        zCompetencyID: '',
        zCompetencyNm: '',
        zFirstName: '',
        zLastName: '',
        zManagerFlag: '',
        zNumberOfRatings: '',
        zParticipantID: '',
        zRelationshipID: '',
        zRptCap: '',
        zRptComp: '',
        zSortOrder: '',
      })
    }
    sheet1 &&
      sheet1.map((rec, i) => {
        let tp = ''
        if (i > 0) {
          tp = i
        }
        return (
          (rec.zCompetencyNm = rec.zCompetencyNm.toUpperCase()), (rec.oid = tp)
        )
      })
  }, [sheet1])

  useEffect(() => {
    if (sheet2) {
      sheet2.unshift({
        zAverage: '',
        zCapabilityID: '',

        zCapabilityName: '',
        zCaption: '',
        zCompetencyID: '',
        zCompetencyName: '',
        zInd: '',
        zRelationshipID: '',
      })
    }
    sheet2 &&
      sheet2.map((rec, i) => {
        return (rec.zCompetencyName = rec.zCompetencyName.toUpperCase())
      })
  }, [sheet2])

  const _exporter = React.createRef()
  const _exporter1 = React.createRef()
  const excelExport = () => {
    save(_exporter, _exporter1)
  }
  const save = (component, component1) => {
    const options = component.current.workbookOptions()
    const options1 = component1.current.workbookOptions()
    options.sheets[0] = options.sheets[0]
    options.sheets[1] = options1.sheets[0]

    options.sheets[0].title = 'Individual Scores All Scores'
    options.sheets[1].title = 'All Items Stats'
    const rows = options.sheets[1].rows
    // rows[1].unshift({cells:[{ value: "",
    // background: "#fffff",
    // colSpan: 7,
    // color: "#fff",
    // rowSpan: 1}]})
    rows.forEach((row) => {
      console.log('row', row)
      row.height = 40
      if (row.type === 'data') {
        row.cells.forEach((cell) => {
          // cell.height=350;
          // cell.wrap=true
        })
      }
    })
    component.current.save(options)
  }
  useEffect(() => {
    if (expFlag == true) {
      excelExport()
    }
  }, [])
  return (
    <>
      <div>
        <CustomButton
          className={classes.buttona}
          colour="primaryButton"
          onClick={excelExport}>
          {!loading ? 'Download' : 'Loading...'}
        </CustomButton>
        {/* <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        onClick={excelExport}
      >
        Export to Excel
      </button> */}

        <ExcelExport
          data={sheet1}
          fileName={`ThinkWise360Summary_${projectname}.xlsx`}
          ref={_exporter}
          headerPaddingCellOptions={{
            color: '#58a83a',
            background: '#58a83a',
            fontSize: '20px',
          }}>
          <ExcelExportColumn
            field="zFirstName"
            title="Participant First Name"
            width={100}
            cellOptions={{ fontSize: '11px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zLastName"
            title="Participant Last Name"
            width={100}
            cellOptions={{ fontSize: '11px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="oid"
            title="Competency Number"
            width={40}
            cellOptions={{ fontSize: '11px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          {/* i+1 for above */}
          <ExcelExportColumn
            field="zCompetencyNm"
            title="Competency"
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zCapabilityID"
            title="Category Number"
            width={40}
            cellOptions={{ fontSize: '11px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zCapabilityName"
            title="Category"
            cellOptions={{ bold: true, fontSize: '11px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field=""
            title=""
            width={20}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Average (excl. Self)"
            title="Average (excluding Self)"
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
            width={30}
          />
          <ExcelExportColumn
            field="Self"
            title="Self"
            width={20}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Colleague"
            title="Colleague"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Direct Report"
            title="Direct Report"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Manager"
            title="Manager"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Manager"
            title="Manager 2"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Manager"
            title="Manager 3"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Peer"
            title="Peer"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="Other"
            title="Other"
            width={60}
            cellOptions={{ bold: true, fontSize: '12px' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
        </ExcelExport>
        <ExcelExport
          data={sheet2}
          fileName={`${projectname}_SummaryReport.xlsx`}
          ref={_exporter1}>
          <ExcelExportColumn
            field="zCompetencyName"
            title="Competency"
            cellOptions={{ verticalAlign: 'center' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zInd"
            title="Item Number"
            cellOptions={{ verticalAlign: 'center' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zCapabilityID"
            width={60}
            title="Category Number"
            cellOptions={{ verticalAlign: 'center' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zCapabilityName"
            title="Category"
            cellOptions={{ verticalAlign: 'center' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn
            field="zCaption"
            title="Item"
            cellOptions={{ verticalAlign: 'center' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
          <ExcelExportColumn field="" title="" width={20} />
          <ExcelExportColumn
            field="zAverage"
            title={organizerName}
            width={30}
            cellOptions={{ verticalAlign: 'center' }}
            headerCellOptions={{
              textAlign: 'center',
              background: '#fffff',
              color: '#111010',
            }}
          />
        </ExcelExport>
      </div>
    </>
  )
}
