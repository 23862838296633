import {
  DialogContent,
  FormHelperText,
  MenuItem,
  Select,
  FormControl,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import defaultAvatar from '../../../../../../assets/images/defaultAvatar.png'
import { useAlert } from 'react-alert'
import {
  ACCOUNT_USER_SETUP,
  ADD_ORGANISATION_USER,
  INSERT_USER_BY_ORDER_ID,
  UPDATE_USER_BY_USER_ID,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useSnackbar } from 'notistack'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { useState } from 'react'
import CustomLoader from '../../../../../common/CustomLoader'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogWidth: {
    maxWidth: '700px',
  },
}))

export default function AddEditUser(props) {
  console.log(props.orders)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const {
    open = '',
    handleReset,
    title = '',
    orgId = '',
    orders,
    orderInfo,
    userFirstName,
    userLastName,
    userEmail,
    userStatus,
    selectedUserId,
    userAdminRoleId,
  } = props
  const orderList = orders.filter(
    (rec) => rec.zNumberOfLicenses > rec.zCurrentUserCount
  )
  //Schema

  var validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required(' First Name is required'),
    lastName: Yup.string()
      .trim()
      .required('Last Name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required'),
    // password: Yup.string().trim().required("Password is required"),
    // order: Yup.string().trim().required("Order is required"),
    //active: Yup.string().trim().required("Status is required"),
    userAdminRoleID: Yup.string()
      .trim()
      .required('AdminRole is required'),
  })

  // console.log("userStatus", userStatus)

  //state
  const initialValues = {
    firstName: title == 'Edit User' ? userFirstName : '',
    lastName: title == 'Edit User' ? userLastName : '',
    email: title == 'Edit User' ? userEmail : '',
    order: '',
    password: '',
    active: title == 'Edit User' ? userStatus : '',
    userAdminRoleID: 'Edit User' ? userAdminRoleId : '',
  }

  const userTypeList = [
    { value: '8', label: 'Hiring Admin' },
    { value: '4', label: 'Learning Pack Admin' },
    { value: '3', label: 'Organizational Admin' },
    { value: '5', label: 'Org Admin + LP' },
    { value: '7', label: 'Project Admin' },
    { value: '2', label: 'Partner Admin' },
    { value: '6', label: 'Partner Admin + LP' },
  ]
  //const alert = useAlert();
  const handleSaveUser = async (formData) => {
    setLoading(true)
    const {
      firstName,
      lastName,
      email,
      order,
      password,
      userAdminRoleID,
      active,
    } = formData
    console.log(
      'userAdminRoleon call',
      userAdminRoleID,
      'parsed',
      parseInt(userAdminRoleID)
    )
    var status = ''
    if (active === 'true') {
      status = true
    } else {
      status = false
    }

    if (title == 'Edit User') {
      const putConfig = {
        method: 'PUT',
        headers: authHeader(),

        url: UPDATE_USER_BY_USER_ID,
        data: {
          userID: selectedUserId,
          organizationID: orgId,
          firstName: firstName,
          lastName: lastName,
          email: email,
          phoneNumber: 'string',
          alternatePhoneNumber: 'string',
          userRoleID: 0,
          userAdminRoleID: parseInt(userAdminRoleID),
          coachFlag: true,
          systemUserFlag: true,
          active: status,
          luid: getUserId(),
          orderID: 0,
          chgResourcesFlag: true,
          hiringGroups: 0,
          mbTypeID: 0,
          mbtiVisibleFlag: true,
        },
        // url: ADD_ORGANISATION_USER,
        // data: {
        //   ddUser: -1,
        //   userID: 0,
        //   organizationID: orgId,
        //   firstName,
        //   lastName,
        //   email,
        //   systemUserFlag: true,
        //   active: true
        // }
      }
      try {
        const response = await API(putConfig)
        if (response.status === 200) {
          handleReset()
          //alert.success("User Invited");
          CustomMessage('User Updated', 'success', enqueueSnackbar) //call toster
        }
      } catch (error) {
        if (error.response) {
          console.error('errrorrrr', error.response)
          let { data } = error.response
          console.log('errorData', data)
          CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
        }
      } finally {
        setLoading(false)
      }
    } else {
      const config = {
        method: 'POST',
        headers: authHeader(),

        url: ADD_ORGANISATION_USER,
        data: {
          //orderId:order,
          //password:password,
          Email: email,
          FirstName: firstName,
          LastName: lastName,
          ddUser: -1,
          userID: getUserId(),
          systemUserFlag: true,
          active: status,
          organizationID: orgId,
          userAdminRoleID: parseInt(userAdminRoleID),
          BaseUrl: '' + window.location.origin + '/reset-password/',
          adminUserID: getUserId(),
          //   organizationID: orgId,
          //   firstName,
          //   lastName,
          //   email,
          //   systemUserFlag: true,
          //   active: true
        },
        // url: ADD_ORGANISATION_USER,
        // data: {
        //   ddUser: -1,
        //   userID: 0,
        //   organizationID: orgId,
        //   firstName,
        //   lastName,
        //   email,
        //   systemUserFlag: true,
        //   active: true
        // }
      }
      try {
        const response = await API(config)
        console.log('response.data', response.data.Message)
        //console.log(response.status);
        if (response.status === 200) {
          if (response.data.Message == 'Email already exisits') {
            CustomMessage(
              'Email already exisits, Try with other email',
              'error',
              enqueueSnackbar
            ) //call toster
          } else {
            handleReset()
            //alert.success("User Invited");
            CustomMessage('User Invited', 'success', enqueueSnackbar) //call toster
          }
        }
      } catch (error) {
        if (error.response) {
          console.error('errrorrrr', error.response)
          let { data } = error.response
          console.log('errorData', data)
          CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
        }
      }
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleSaveUser(values),
  })

  const {
    firstName = '',
    password = '',
    lastName = '',
    email = '',
    order = '',
    active = '',
    userAdminRoleID = '',
  } = values
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off">
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="First Name"
                  value={firstName}
                  onChange={handleChange}
                  fullWidth
                  name="firstName"
                  error={errors.firstName && touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Last Name"
                  value={lastName}
                  onChange={handleChange}
                  fullWidth
                  name="lastName"
                  error={errors.lastName && touched.lastName}
                  helperText={
                    errors.lastName && touched.lastName ? errors.lastName : ''
                  }
                />
              </Grid>
              {/*}  {title == "Add User" ?
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Password"
                  value={password}
                  type="password"
                  onChange={handleChange}
                  fullWidth
                  name="password"
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password ? errors.password : ""
                  }
                />
              </Grid>:""
                }
                { title == "Add User" ?
                   <Grid item xs={4} className={classes.grid}>                 
                     <InputLabel>Order</InputLabel>
                     <Select
                       labelId="scale-label"
                       value={order}
                       name="order"
                       // defaultValue="Self"
                       onChange={handleChange}
                       label="SelectRater Relationship"
                       fullWidth>
                       {orders &&
                         orders.map((record, i) => {                        
                           const {zOrderTypeName = '',zName,zOrderID } = record;
                           if(zOrderTypeName =="admin"){
                            return <MenuItem value={zOrderID}>{zName}</MenuItem>
                           }                                                    
                         })}
                     </Select>
                  
                 </Grid>:""
                }

              */}
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Email"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  name="email"
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="order">User Admin Type</InputLabel>
                <Select
                  labelId="userAdminRoleID"
                  id="userAdminRoleID"
                  name="userAdminRoleID"
                  value={userAdminRoleID}
                  onChange={handleChange}
                  fullWidth
                  label="order"
                  error={errors.userAdminRoleID && touched.userAdminRoleID}
                  helperText={
                    errors.userAdminRoleID && touched.userAdminRoleID
                      ? errors.userAdminRoleID
                      : ''
                  }>
                  {/* {orderList.map(rec=> <MenuItem value={rec.zOrderID}>{rec.zName}</MenuItem>)} */}
                  {userTypeList.map((rec) => (
                    <MenuItem value={rec.value}>{rec.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <InputLabel id="active">Status</InputLabel>
                <Select
                  labelId="active"
                  id="active"
                  name="active"
                  value={active}
                  onChange={handleChange}
                  fullWidth
                  label="status"
                  error={errors.active && touched.active}
                  helperText={
                    errors.active && touched.active ? errors.active : ''
                  }>
                  {/* {orderList.map(rec=> <MenuItem value={rec.zOrderID}>{rec.zName}</MenuItem>)} */}
                  <MenuItem value="true">Active</MenuItem>
                  <MenuItem value="false">InActive</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}>
                  {loading && <CustomLoader loading={loading} />}{' '}
                  {title == 'Add User' ? 'Send Invite' : 'Save'}
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
