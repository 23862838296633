import React, { useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Chart } from 'chart.js'
import { PrepostReport } from './PrepostReport'

function GenerateModul(props) {
  const { open, modalTogle } = props
  let canvasRef = useRef()
  const generateChart = async (value, i) => {
    const { label, data } = value
    var canvas = document.createElement('canvas')

    canvas.id = `CursorLayer_${i}`
    canvas.width = 1224
    canvas.height = 768
    canvas.style.zIndex = 8
    canvas.style.position = 'absolute'
    canvas.style.border = '1px solid'

    var body = document.getElementsByTagName('div')[0]
    // console.log(document.getElementById('dia'))
    // document
    //   .getElementsByTagName('div')
    //   .namedItem('dia')
    //   .appendChild(canvas)
    body.appendChild(canvas)
    let cursorLayer = document.getElementById(`CursorLayer_${i}`)

    console.log(cursorLayer)

    var myChart = new Chart(cursorLayer.getContext('2d'), {
      type: 'bar',
      data: {
        labels: label,
        datasets: [
          {
            label: '# of Votes',
            data: data,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(255,99,132,1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
          },
        ],
      },
      options: {
        // indexAxis: 'y',

        scales: {
          x: {
            suggestedMin: 0,
            suggestedMax: 10,
            title: {
              font: {
                size: 14,
                weight: 'bold',
              },
              color: 'black',
            },

            ticks: {
              font: {
                size: 50,
                weight: 'bold',
              },
              color: 'black',
            },
          },

          y: {
            title: {
              font: {
                size: 14,
                weight: 'bold',
              },
              color: 'black',
            },

            ticks: {
              beginAtZero: true,
              mirror: true,

              font: {
                size: 50,
                weight: 'bold',
              },
              color: 'black',
            },
          },
        },
      },
    })
    return await delay(myChart, cursorLayer)
  }

  function delay(myChart, cursorLayer) {
    return new Promise(function(resolve) {
      setTimeout(() => {
        console.log('time')
        var a = document.createElement('a')

        resolve(myChart.toBase64Image())
        // a.href = myChart.toBase64Image()
        // a.download = 'my_file_name.png'
        // a.click()
        var el = cursorLayer
        el.remove()
      }, 1000)
    })
  }
  let Forarrat = [
    { label: ['one', 'two', 'three'], data: [1, 2, 3] },
    { label: ['four', 'five', 'six'], data: [4, 5, 6] },
    { label: ['one', 'two', 'three'], data: [1, 2, 3] },
    { label: ['four', 'five', 'six'], data: [4, 5, 6] },
    { label: ['one', 'two', 'three'], data: [1, 2, 3] },
    { label: ['four', 'five', 'six'], data: [4, 5, 6] },
    { label: ['one', 'two', 'three'], data: [1, 2, 3] },
    { label: ['four', 'five', 'six'], data: [4, 5, 6] },
    { label: ['one', 'two', 'three'], data: [1, 2, 3] },
    { label: ['four', 'five', 'six'], data: [4, 5, 6] },
  ]
  const Gener = async () => {
    let imageArray = []

    try {
      await Promise.all(
        Forarrat.map(async (value, index) => {
          let data = await generateChart(value, index)
          imageArray.push(data)
        })
      )
    } catch (er) {
      console.log(er)
    }
    // console.log(imageArray)
    PrepostReport(imageArray)
    modalTogle()
  }

  useEffect(() => {
    Gener()
  }, [])

  return (
    <Dialog
      open={open}
      onClose={modalTogle}
      style={{ height: '100%', width: '100%' }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {"Use Google's location service?"}
      </DialogTitle>
      <DialogContent style={{ height: '100%', width: '100%' }}>
        {/* <canvas
              ref={canvasRef}
              id="myChart"
              width="600"
              height="600"></canvas> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={modalTogle} color="primary">
          Disagree
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GenerateModul
