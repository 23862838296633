import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
// import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import Toolbar from '@mui/material/Toolbar'
// import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Link } from '@material-ui/core'
import logo from '../../../../assets/images/logo.png'
import Navbar from '../../../layout/Navbar'
import Sidebar from '../../../layout/Sidebar'
// import { Route, Switch } from 'react-router'
// import {
//   ACCOUNT_AND_USER_PATH,
//   CREATE_NEW_360_PATH,
//   DASHBOARD_PATH,
//   INITIAL_LAYOUT_PATH,
//   PROFILE_PATH,
//   PROJECTS_PATH,
//   PROJECT_DETAILS_PATH,
//   PROJECT_EDIT_PATH,
//   PROJECT_SETUP_TYPE,
//   TASK_PATH,
//   TASK_VIEW_PATH,
//   PROJECT_REPORT_PATH,
//   TEAMSURVEYPATH,
//   BASICINFORMATION,
//   TEAMSURVEYVIEW,
//   TEAMSURVEYTASK,
//   TEAMENDUSER,
// } from '../../helpers/constants/RoutePaths'
// import Dashboard from '../dashboard'
// import Projects from '../thinkwise_360/projects'
// import CreateNew360 from '../thinkwise_360/projects/CreateNew360'
// import ViewProjectDetails from '../thinkwise_360/projects/steps/item_selection/ProjectView/ViewProjectDetails'
// import TaskIndex from '../thinkwise_360/projects/steps/item_selection/TaskItems/TaskIndex'
// import TaskDetails from '../thinkwise_360/projects/steps/item_selection/TaskItems/Newtaskdetail'
// // import TaskDetails from '../thinkwise_360/projects/steps/item_selection/TaskItems/TaskDetails'

// import ProfileIndex from '../thinkwise_360/projects/steps/item_selection/Profile/ProfileIndex'
// import AccountUser from '../thinkwise_360/projects/steps/item_selection/AccountUsers/AccountUser'
import { positions, Provider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
// import ChooseSetupType from '../thinkwise_360/projects/ChooseSetupType'
// import { useDispatch } from 'react-redux'
// import { getCategory, getCustomCategory, taskList } from '../../redux/action'
// import {
//   authHeader,
//   getUserId,
//   getUserRoleId,
//   PrivateRoute,
// } from '../common/CustomFunction'
// import ProjectthreeSixtyReport from '../thinkwise_360/projects/steps/item_selection/ProjectReport/ProjectthreeSixtyReport'
// import Teamsurveyprojectlist from '../Teamsurvey/Teamsurveyprojectlist'
// import Teamsurveyindex from '../Teamsurvey/Teamsurveyindex'
// import Tsview from '../Teamsurvey/Tsview'
// import Tstask from '../Teamsurvey/Tstask'
// import Tsendusertask from '../Teamsurvey/Tsendusertask'
// import {
//   TEAM_ADD_TAST_USER,
//   TEAM_GETURL,
// } from '../../helpers/constants/ApiRoutes'
// import {API} from '../../helpers/API'
// import {
//   useHistory,
//   useLocation,
// } from 'react-router-dom/cjs/react-router-dom.min'
// import { locationParams } from '../../../'
import RosterFormNew from './RosterFormNew'
import ThanksForSubmit from './ThanksForSubmit'
import { color } from '@mui/system'
const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: 'none',
  },
  root: {
    flexGrow: 1,
  },
  sideHead: {
    backgroundColor: theme.secondaryColor,
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sideFoot: {
    backgroundColor: theme.secondaryColor,
    height: '64px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  grow: {
    flexGrow: 1,
  },
  iconMenus: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
    },
  },
  appBar: {
    backgroundColor: '#fff',
    color: '#767778',
    [theme.breakpoints.down('xs')]: {
      display: 'contents',
    },
  },
  drawerPaper: {
    position: 'relative',
    background: theme.primaryColor,
    whiteSpace: 'nowrap',
    border: 'none',
    width: '100%',
    height: `calc(100vh - 17vh)`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  badgeColor: {
    backgroundColor: theme.btnPrimary,
  },
  avtSection: {
    width: '60px',
    float: 'left',
    padding: '8px',
  },
  statusDot: {
    height: '7px',
    width: '7px',
    backgroundColor: 'green',
    borderRadius: '50%',
    display: 'inline-block',
  },
  menuSection: {
    minHeight: '100%',
    backgroundColor: theme.primaryColor,
    paddingTop: '21px',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // maxHeight: "626px",
    minHeight: '80vh',
    // overflow: 'auto',
    color: '#F9F9FC !important',
    // overflowX: 'hidden',
  },
  footer: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    color: 'white',
    height: '64px',
  },
  footMenu: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
  },
  footMenuContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ffffff',
    height: '64px',
  },
  footMenuItem: {
    display: 'inline',
    marginLeft: '8px',
    marginRight: '8px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
  linkColor: {
    color: '#767778',
  },
  footBlock: {
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  sideBar: {
    backgroundColor: theme.primaryColor,
    '& .MuiDrawer-paper': {
      overflow: 'unset',
    },
  },
  toolbarwhite: {
    backgroundColor: 'white !important',
    padding: '0px !important',
    justifyContent: 'space-between',
  },
  backappbar: {
    backgroundColor: 'white !important',
  },
  fullgrid: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      padding: '0px !important',
    },
  },
  moblogoapp: {
    display: 'none',

    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      width: '110px',
      height: '30px',
    },
  },
}))

const drawerWidth = 200
export default function RosterLayout(props) {
  const classes = useStyles()
  const navbarProps = { classes }
  const sidebarProps = { classes }
  //   const dispatch = useDispatch()
  //   const history = useHistory()
  //   const location = useLocation()

  const options = {
    timeout: 2000,
    position: positions.TOP_RIGHT,
  }

  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [thanksSubmit, setThanksSubmit] = useState(false)
  console.log(thanksSubmit ? 'thanks' : 'roster', 'dfgnmxfgh')
  const drawer = (
    <div>
      <Grid className={classes.sideBar}>
        <div className={classes.sideHead}>
          {/* <img src={logo} alt="logo" width="auto" height="30px" /> */}
        </div>
      </Grid>
    </div>
  )
  return (
    <Box className={classes.fullgrid} id="hello">
      <CssBaseline />
      <AppBar
        className={classes.backappbar}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
        <Toolbar className={classes.toolbarwhite}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            // onClick={handleDrawerToggle}
            sx={{ mr: 1, ml: 1, display: { sm: 'none' } }}>
            <MenuRoundedIcon style={{ color: '#232F3E' }} />
          </IconButton>
          {/* <div>
            <img src={logo} alt="logo" className={classes.moblogoapp} />
          </div> */}
          {/* <Navbar {...navbarProps} /> */}
        </Toolbar>
      </AppBar>
      <Box
        className={classes.fullbox}
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          //   container={container}
          variant="temporary"
          open={mobileOpen}
          //   onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              overflow: 'hidden',
              width: drawerWidth,
              background: '#1B2430',
            },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              overflow: 'hidden',
              width: drawerWidth,
              background: '#1B2430',
            },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: { lg: 3, md: 3, xs: 0 },
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}>
        <Toolbar />
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className={classes.body}>
          <Container
            maxWidth="xxl"
            className={classes.container}
            style={{ padding: '0px', background: thanksSubmit ? 'none' : '' }}>
            <Provider template={AlertTemplate} {...options}>
              {thanksSubmit ? (
                <ThanksForSubmit />
              ) : (
                <RosterFormNew setThanksSubmit={setThanksSubmit} />
              )}
            </Provider>
          </Container>
          {/* Footer */}
          <div className={classes.footMenuContainer}>
            <ul className={classes.footMenu}>
              <li className={classes.footMenuItem}>
                <Link className={classes.linkColor} href="#home">
                  Support
                </Link>
              </li>
              <li className={classes.footMenuItem}>
                <Link className={classes.linkColor} href="#news">
                  Help Center
                </Link>
              </li>
              <li className={classes.footMenuItem}>
                <Link className={classes.linkColor} href="#contact">
                  Privacy
                </Link>
              </li>
              <li className={classes.footMenuItem}>
                <Link className={classes.linkColor} href="#about">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
          <div class="canvasgraphcontainer">
            <div class="canvasgraph" id="canvasgraph" data-status="open"></div>
          </div>
        </Grid>
      </Box>
    </Box>
  )
}
