import * as types from '../action/ActionType'
export default function Dragableselecteditem(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.GET_ALL_SELECTED_ITEM:
      return (state = payload)

    case types.DRAGGED_ITEM:
      return (state = [...payload])
    default:
      return state
  }
}
