import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Button,
  Tab,
  Tabs,
  Box,
  Fab,
  TextField,
  Select,
} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Link } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'

import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../../../../common/CustomBreadcrumbs'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import CustomButton from '../../../../../common/CustomButton'
import { TASK_VIEW_PATH } from '../../../../../../helpers/constants/RoutePaths'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'

import EditIcon from '@material-ui/icons/Edit'
import { withStyles } from '@material-ui/styles'
import EditPartner from './EditPartner'
import AddEditPartnerAdmin from './AddEditPartnerAdmin'
import AddEditUser from './AddEditUser'
import AddEditOrganization from './AddEditOrganization'
import AddEditOrder from './AddEditOrder'
import { API } from '../../../../../../helpers/API'
import {
  DELETE_ORDER_URL,
  GET_ORGANIZATION_LOGO_URL,
  GET_ORG_INFO_URL,
  GET_ORG_PARTNER_ID,
  GET_PARNTER_LOGO_URL,
  GET_PARTNER_INFO_URL,
  GET_PARTNER_LIST_URL,
  GET_USER_LIST_BY_ORG_URL,
  LOGO_UPLOAD_PARTNER_ORG_URL,
  ORDER_BY_ORG_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import Partner from './Partner'
import Address from '../../../../../common/Address'
import {
  authHeader,
  getAlertToast,
  getOrganizationId,
  getPartner,
  isThinkwiseAdmin,
} from '../../../../../common/CustomFunction'
import {
  CustomPageLoading,
  getComparator,
  stableSort,
} from '../../../../../../function/HelperFunction'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { styled } from '@mui/material/styles'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import { GET_ORGANIZATION_LOGO } from '../../../../../../redux/action/ActionType'

const Input = styled('input')({
  display: 'none',
})
const useStyles = makeStyles((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    // position: "absolute",
    pointerEvents: 'none',
    display: 'flex',
    // alignItems: "center",
    justifyContent: 'right',
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontFamily: 'Nunito-SemiBold !important',
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 245,
    overflowX: 'auto',
  },
  tabWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tabContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
  },
  skillBtn: {
    width: '90px',
    height: '20px',
    marginLeft: '1rem',
    backgroundColor: '#1E88E5',
    textTransform: 'none',
    fontSize: '9px',
    color: '#9ECCF4',
  },
  cardStyle: {
    display: 'block',
    width: '19vw',
    transitionDuration: '0.3s',
    height: '100%',
    maxHeight: '500px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardContentStyle: {
    padding: '6px',
  },
  partnerInfoCard: {
    height: '100%',
    maxHeight: '500px',
    overflow: 'auto',
  },
  // tabcardStyle: {
  //   display: "block",
  //   width: "19vw",
  //   transitionDuration: "0.3s",
  //   height: "100%",
  // },
  mainHeading: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#000000DE !important',
    opacity: '1',
  },
  selected: {
    textAlign: ' left',
    color: ' #1565C0 !important',
    opacity: ' 1',
    fontFamily: 'Nunito-Regular !important',
  },
  unselected: {
    color: '#0000008A !important',
  },
  titleheading: {
    fontFamily: 'Nunito-SemiBold !important',
    fontSize: '17px !important',
  },
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

//Table Columns
const tableColumns = [
  { id: 'zFirstName', title: 'First Name' },
  { id: 'zFirstName', title: 'Last Name' },
  { id: 'email', title: 'Email' },
  { id: 'status', title: 'Status' },
  { id: 'action', title: 'Action' },
]
//second Table Columns
const tableColumnsec = [
  { id: 'zFirstName', title: 'First Name', sort: true },
  { id: 'zLastName', title: 'Last Name', sort: true },
  { id: 'zEmail', title: 'Email', sort: true },
  { id: 'status', title: 'Status', sort: false },
  { id: 'userType', title: 'User Admin Type', sort: false },
  { id: 'action', title: 'Action', sort: false },
]

const organizationCreateInitial = {
  organizationID: 0,
  name: '',
  addressLine1: 'address1',
  addressLine2: 'address2',
  city: 'tirunelveli',
  stateId: 3,
  zipCode: '332424',
  webSite: 'string',
  customerFlag: true,
  allowCustomItemsFlag: true,
  allowCompModelSelectionFlag: true,
  showAllTasks360Flag: true,
  force360ScaleId: 0,
  orgDefinedCompetencyFlag: true,
  showDevObjectiveFlag: true,
  active: true,
  noEmailToFriendThinkBox: true,
  viewIncomplete360Report: true,
  multiLanguage: true,
  domain: 'test.thinkwiseinc.com',
  partnerId: 71,
  luid: 1,
  competencySearchTitle: 'string',
  devSurveyReportText: 'string',
  hrSelectiveReporting: true,
}
const initialValues = {
  orgVal: '',
}
export default function AccountUser() {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [activeTab1, setActiveTab1] = useState(0)
  const [editFlag, setEditFlag] = useState(false)
  const [adminFlag, setAdminFlag] = useState(false)
  const [userFlag, setAddUserFlag] = useState(false)
  const [organFlag, setOrganFlag] = useState(false)
  const [orgInfo, setorgInfo] = useState({})
  const [orgUsers, setOrgUsers] = useState([])
  const [orgUsersResult, setOrgUsersResult] = useState([])
  const [organizationList, setorganizationList] = useState([])
  const [title, setTitle] = useState('')
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const [selectedUserId, setSelectedUSerId] = useState(0)
  const [userAdminRoleId, setUserAdminRoleId] = useState(0)
  const [partners, setPartners] = useState([])
  const [partnerId, setpartnerId] = useState('')
  const [partnerInfo, setPartnerInfo] = useState([])
  const [searchPartner, setSearchPartner] = useState('')
  const [searchOrganisation, setSearchOrganisation] = useState('')
  const [orders, setOrders] = useState([])
  const [orderInfo, setOrderInfo] = useState([])
  const [isOrderModal, setOrderModal] = useState(false)
  const [loading, setloading] = useState(false)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('orderNo')
  const [parternerList, setparternerList] = useState([])
  const [formValues, setFormValues] = useState(initialValues)
  const [OrgVal, setVal] = useState({})
  const [partnerLogo, setPartnerLogo] = useState('')
  const [partnerLogoLoading, setPartnerLogoLoading] = useState(false)
  const [orgLogo, setOrgLogo] = useState('')
  const [orgLogoLoading, setOrgLogoLoading] = useState(false)
  const [logoCount, setLogoCount] = useState(1)

  const orgLogoSelector = useSelector((state) => state.AccountsUsersReducer)

  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: { ...formValues, orgVal: orgInfo.zOrganizationID },
    enableReinitialize: true,
  })
  const { orgVal = '' } = values
  const handleOrgDrop = (e, id) => {
    handleChange(e)
    console.log('2208 value', e.target.value)
    organizationClick(id)
  }

  // useEffect(() => {
  //   if (orgInfo) {
  //     setValues({ ...values, orgVal: orgInfo.zOrganizationID })
  //   }
  // }, [orgInfo])
  console.log('value of orf', values, history.goBack.length)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const [userorder, setuserOrder] = React.useState('asc')
  const [userorderBy, setuserOrderBy] = React.useState('zFirstName')
  const userhandleRequestSort = (event, property) => {
    const isAsc = userorderBy === property && userorder === 'asc'
    setuserOrder(isAsc ? 'desc' : 'asc')
    setuserOrderBy(property)
  }

  //Order Table Columns
  let orderTableColumns = [
    { id: 'zcppInvoice', title: 'Order Number', sort: true },
    { id: 'zName', title: 'Order Name', sort: true },
    { id: 'zDescription', title: 'Description', sort: true },
    { id: 'zOrderTypeName', title: 'Products', sort: true },
    { id: 'zNumberOfLicenses', title: 'Quantity', sort: true },
  ]
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleTabChange1 = (event, newValue) => {
    setActiveTab1(newValue)
  }

  if (isThinkwiseAdmin()) {
    orderTableColumns.push({ id: 'action', title: 'Action' })
  }

  const tableHeadProps = { tableColumns }
  const tableHeadPropsec = { tableColumns: tableColumnsec }
  const orderTableHeaders = { tableColumns: orderTableColumns }

  const handleAddEditPartner = (e) => {
    setEditFlag(!editFlag)
    e && setTitle(e.currentTarget.dataset.title)
  }

  const handlePartner = (partnerId) => {
    console.log(partnerId, 'partnerId')
    getPartnerInfo(partnerId)
    //api
    setpartnerId(partnerId)
    organizationStore(partnerId)
  }

  const handlePartnerSearch = (e) => {
    setSearchPartner(e.target.value)
  }

  const handleOrganisationSearch = (e) => {
    setSearchOrganisation(e.target.value)
  }
  const addPartnerAdmin = (e) => {
    setAdminFlag(!adminFlag)
    setTitle(e.currentTarget.dataset.title)
  }
  const addEditUser = (e) => {
    setAddUserFlag(!userFlag)
    e && e.currentTarget && setTitle(e.currentTarget.dataset.title || '')
    e && e.currentTarget && setUserFirstName(e.currentTarget.dataset.firstName)
    e && e.currentTarget && setUserLastName(e.currentTarget.dataset.lastName)
    e && e.currentTarget && setUserEmail(e.currentTarget.dataset.zEmail)
    e && e.currentTarget && setUserPassword(e.currentTarget.dataset.zPassword)
    e && e.currentTarget && setUserStatus(e.currentTarget.dataset.zStatus)
    e && e.currentTarget && setSelectedUSerId(e.currentTarget.dataset.userID)
    e &&
      e.currentTarget &&
      setUserAdminRoleId(e.currentTarget.dataset.userAdminRoleID)

    // console.log(e.currentTarget.dataset.firstName, e.currentTarget.dataset.lastName ,e.currentTarget.dataset.zEmail ,e.currentTarget.dataset.zStatus)
  }
  const addEditOrgan = (e) => {
    setOrganFlag((val) => !val)
    e && setTitle(e.currentTarget.dataset.title)
  }
  const addEditOrder = (e, order) => {
    setOrderModal(true)
    setTitle(e.currentTarget.dataset.title)
    if (order) {
      setOrderInfo(order)
    } else {
      setOrderInfo([])
    }
  }

  // Get Partner Information
  async function getPartnerInfo(partnerId) {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PARTNER_INFO_URL,
      params: {
        zPartnerId: partnerId,
      },
    }
    try {
      const response = await API(getconfig)
      const { partnerInfo } = response.data

      if (response.status == 200) {
        setPartnerInfo(partnerInfo)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Get Partner List
  async function getPartnerlist() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PARTNER_LIST_URL,
    }
    setloading(true)
    try {
      const response = await API(getconfig)
      const { partnerList } = response.data

      if (response.status == 200) {
        setparternerList(partnerList)
        const partner = partnerList.find(
          (partner) => partner.zPartnerID == getPartner()
        )

        if (isThinkwiseAdmin()) {
          setPartners(partnerList)
          organizationStore(partner.zPartnerID)
          setpartnerId(partner.zPartnerID)
          getPartnerInfo(partner.zPartnerID)
        } else {
          setPartners([partner])
          organizationStore(partner.zPartnerID)
          setpartnerId(partner.zPartnerID)
          getPartnerInfo(partner.zPartnerID)
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setloading(false)
    }
  }
  const organizationStore = async (id) => {
    const getpartherconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORG_PARTNER_ID,
      params: {
        PartnerId: id,
      },
    }
    setloading(true)

    try {
      const response2 = await API(getpartherconfig)

      const { data, status } = response2.data
      if (status == 'Success') {
        console.log('data on organization', data, id, status)
        console.log('chk org id', data[0].zOrganizationID)

        const organization = data.find((org) => {
          return org.zOrganizationID == getOrganizationId()
        })
        console.log('organi', organization)
        console.log(
          'chk org id',
          data,
          data[0].zOrganizationName
          // organization.zOrganizationID
        )
        if (organization) {
          console.log('have id', organization.zOrganizationID)
          organizationClick(
            organization?.zOrganizationID || getOrganizationId()
          )
        } else {
          organizationClick(data[0]?.zOrganizationID || getOrganizationId())
        }
        // organizationClick(
        //   organization.zOrganizationID
        //     ? organization.zOrganizationID
        //     : data[0]?.zOrganizationID || getOrganizationId()
        // )
        if (isThinkwiseAdmin()) {
          //console.log("if org", data)
          setorganizationList(data)
        } else {
          //console.log("else org", organization)
          const organization = data.find((org) => {
            return org.zOrganizationID == getOrganizationId()
          })
          setorganizationList([organization])
        }
      }
    } catch (e) {
      console.log(e)
      setorganizationList([])
      setorgInfo({})
    } finally {
      setloading(false)
    }
  }
  //

  const organizationInfo = async (id) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORG_INFO_URL,
      params: {
        OrganizationId: id,
      },
    }
    setloading(true)

    try {
      const response = await API(config)
      const { data } = response.data
      setorgInfo(data)
      console.log('data', data)
      // setVal(data.zOrganizationID)
    } catch (e) {
      console.log(e)
    } finally {
      setloading(false)
    }
  }

  const organizationUsers = async (id) => {
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: GET_USER_LIST_BY_ORG_URL,
      params: {
        OrgID: id,
      },
    }
    setloading(true)

    try {
      const response = await API(config)
      const { data } = response.data
      setOrgUsers(data)
      setOrgUsersResult(data)
    } catch (e) {
      setOrgUsers([])
      setOrgUsersResult([])
      console.log(e)
    } finally {
      setloading(false)
    }
  }

  const organizationOrders = async (id) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: ORDER_BY_ORG_URL,
      params: {
        OrgID: id,
      },
    }
    setloading(true)

    try {
      const response = await API(config)
      const { Order_LstByOrg } = response.data
      setOrders(Order_LstByOrg)
    } catch (e) {
      setOrders([])
      console.log(e)
    } finally {
      setloading(false)
    }
  }

  const deleteOrder = async (id) => {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_ORDER_URL,
      params: {
        OrderID: id,
      },
    }
    const response = await API(deleteConfig)
    if (response.status === 200) {
      organizationOrders(orgInfo.zOrganizationID)
    }
  }

  const organizationClick = (id) => {
    console.log(id, 'id')
    organizationInfo(id)
    organizationUsers(id)
    organizationOrders(id)
  }

  useEffect(() => {
    getPartnerlist()
    getPartnerLogo()
    getOrangizationLogo()
  }, [])

  useEffect(() => {
    if (searchOrganisation) {
      setOrgUsersResult((val) =>
        orgUsers.filter((data) => data.zEmail.includes(searchOrganisation))
      )
      setOrders((val) =>
        val.filter((data) => data.zName.includes(searchOrganisation))
      )
    }
  }, [searchOrganisation])

  const handleSearchOrg = () => {
    if (searchOrganisation) {
      setOrgUsersResult(
        orgUsers.filter((data) => data.zEmail.includes(searchOrganisation))
      )
      setOrders((val) =>
        val.filter((data) => data.zName.includes(searchOrganisation))
      )
    }
  }
  const checkFun = (e) => {
    console.log(e)
  }
  const handleFileChange = async (e, orgId) => {
    e.persist()
    setLogoCount(logoCount + 1)
    let file = e
    if (!file.target.files[0].name.match(/.(jpg|jpeg|png)$/i)) {
      Swal.fire(
        getAlertToast('error', 'Please upload jpeg,jpg,png file format only.')
      )
    } else {
      let formdata = new FormData()
      var reader = new FileReader()
      reader.readAsDataURL(file.target.files[0])
      reader.onload = async function(e) {
        var image = new Image()

        image.src = e.target.result

        image.onload = async function() {
          var height = this.height
          var width = this.width

          if (height > 1000 || width > 1000) {
            Swal.fire(
              getAlertToast(
                'error',
                'PNG or JPG no larger than 1000px tall and wide'
              )
            )
          } else {
            formdata.append('file', file.target.files[0])
            // formdata.append('fileAccess', 1)
            // formdata.append('IsPartner', !orgId ? true : false)
            // formdata.append('PartnerID', !orgId ? partnerId : '')
            // formdata.append('OrganizationID', orgId ? orgId : '')
            try {
              const option = {
                method: 'POST',
                url: LOGO_UPLOAD_PARTNER_ORG_URL,
                params: {
                  fileAccess: 1,
                  IsPartner: !orgId ? true : false,
                  PartnerID: !orgId ? partnerId : '',
                  OrganizationID: orgId ? orgId : '',
                },
                data: formdata,
                headers: authHeader(),
                'content-type': 'multipart/form-data',
              }
              let response = await API(option)
              const { status, filePath, message } = response.data

              if (status === 'Success') {
                Swal.fire(getAlertToast('success', message))
                getPartnerLogo()
                getOrangizationLogo()

                dispatch({
                  type: GET_ORGANIZATION_LOGO,
                  payload: orgLogoSelector + 1,
                })
              }
            } catch (error) {
              console.error(error)
            }
          }
        }
      }
    }
  }

  const getPartnerLogo = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PARNTER_LOGO_URL,
      params: { PartnerID: getPartner() },
    }
    setPartnerLogoLoading(true)
    try {
      const response = await API(getconfig)
      const { status, data } = response.data
      console.log('data0', data[0])
      if (response.status == 200) {
        setPartnerLogoLoading(false)
        setPartnerLogo(data[0])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setPartnerLogoLoading(false)
    }
  }

  const getOrangizationLogo = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORGANIZATION_LOGO_URL,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    setOrgLogoLoading(true)
    try {
      const response = await API(getconfig)
      const { status, data } = response.data

      if (response.status == 200) {
        setOrgLogoLoading(false)
        setOrgLogo(data[0])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setOrgLogoLoading(false)
    }
  }

  return (
    <div>
      <Grid container spacing={1}>
        {loading && <CustomPageLoading />}
        {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Typography variant="h6" className={classes.mainHeading} gutterBottom>
            {'Accounts & Users'}
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.selected} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              Admin
            </Typography>
            <Typography className={classes.unselected} color="textPrimary">
              {'Accounts & Users'}
            </Typography>
          </Breadcrumbs>
        </Grid> */}
        {/* <Grid item xl={6} lg={6} md={6} sm={12} xs={12}> */}
        {/* <div className={classes.sideHead}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12}>
          <Divider />
        </Grid> */}
      </Grid>
      <Grid container spacing={3} style={{ marginTop: '8px' }}>
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Partner
            partners={partners}
            handleAddEditPartner={handleAddEditPartner}
            handlePartner={handlePartner}
            partnerId={partnerId}
          />
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <Card className={classes.partnerInfoCard}>
            <CardContent>
              <Typography className={classes.titleheading}>
                Partner Information
              </Typography>
              <Divider />
              <Toolbar className={classes.root}>
                <Typography
                  className={classes.tblHead}
                  variant="h6"
                  style={{ fontSize: '24px' }}
                  id="tableTitle"
                  component="div">
                  {partnerInfo.name}
                  <> </>
                  <IconButton
                    aria-label="delete"
                    className={classes.icoBtnInside}
                    size="small"
                    onClick={handleAddEditPartner}
                    data-title="Edit Partner">
                    <EditIcon fontSize="inherit" />
                  </IconButton>
                  <br />
                  <small
                    style={{
                      fontFamily: 'Nunito-Regular !important',
                      fontSize: '16px',
                    }}>
                    {partnerInfo.website}
                  </small>
                </Typography>
                <div className={classes.sideHead}>
                  {partnerLogo ? (
                    <img
                      src={partnerLogo?.zLogoURL || ''}
                      alt="logo"
                      width="auto"
                      height="40px"
                    />
                  ) : (
                    ''
                  )}
                  <Button
                    color="default"
                    variant="outlined"
                    aria-label="upload picture"
                    id="partnerInformationFile"
                    name="partnerInformationFile"
                    component="label"
                    key={logoCount}
                    sx={{ p: '7px' }}>
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      id="partnerInformationfile"
                      name="partnerInformationfile"
                      onChange={(e) => {
                        handleFileChange(e)
                      }}
                    />
                    Add/Edit Logo
                  </Button>
                </div>
              </Toolbar>
              <div>
                <>
                  <Grid container className={classes.tabWrapper}>
                    <Grid xs={8} className={classes.tabContainer}>
                      <CusTab
                        value={activeTab}
                        onChange={handleTabChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="auto">
                        {/* <Tab label="PARTNER ADMINS" {...a11yProps(0)} /> */}
                        <Tab label="PARTNER INFO" {...a11yProps(0)} />
                      </CusTab>
                    </Grid>
                    {/* <Grid xs={4} className={classes.tabContainer}>
                      <TextField
                        id="searchPartner"
                        placeholder="Search"
                        value={searchPartner}
                        onChange={(e) => handlePartnerSearch(e)}
                        fullWidth
                        name="searchPartner"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid> */}
                  </Grid>
                </>
              </div>
              <Divider />
              <div>
                <TabPanel value={activeTab} index={0}>
                  <div style={{ paddingLeft: '30px' }}>
                    <Address
                      name={partnerInfo.name}
                      addressLine1={partnerInfo.addressLine1}
                      addressLine2={partnerInfo.addressLine2}
                      city={partnerInfo.city}
                      state={partnerInfo.state}
                      zipcode={partnerInfo.zipCode}
                      websiteUrl={partnerInfo.website}
                    />
                  </div>
                </TabPanel>
              </div>
            </CardContent>
          </Card>
        </Grid>

        {/* second one */}
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
          <Card className={classes.cardStyle}>
            <CardContent className={classes.cardContentStyle}>
              <Toolbar style={{ padding: '0px' }}>
                <Typography
                  className={classes.tblHead}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                  style={{ float: 'left', fontSize: '0.9rem', padding: '8px' }}>
                  <b className={classes.titleheading}> Organizations</b>
                  <></>
                  {isThinkwiseAdmin() && (
                    <Button
                      size="sm"
                      color="default"
                      startIcon={<AddIcon />}
                      style={{
                        float: 'right',
                        textTransform: 'none',
                        padding: '0px',
                      }}
                      onClick={addEditOrgan}
                      data-title="Add Organization">
                      <span style={{ fontSize: '0.8rem' }}>
                        Add Organization
                      </span>
                    </Button>
                  )}
                </Typography>
              </Toolbar>
              {/* <List dense className={classes.root}> */}
              {/* {organizationList.map((value) => { */}
              {/* const labelId = `checkbox-list-secondary-label-${value}` */}
              {/*  */}
              {/* return ( */}
              <>
                {console.log(
                  'values of orgval',
                  values.orgVal ? values.orgVal : ''
                )}
                <Select
                  labelId="orgVal-label"
                  id="orgVal"
                  name="orgVal"
                  value={values && values.orgVal ? values.orgVal : ''}
                  onChange={(e) => {
                    handleOrgDrop(
                      e,
                      e.target.value
                      // value.zOrganizationID,
                      // value.zOrganizationName
                    )
                  }}
                  fullWidth
                  label="Select Scale"
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}>
                  {console.log('org len', organizationList.length)}
                  {organizationList &&
                    organizationList.length > 0 &&
                    organizationList.map((value) => (
                      <MenuItem value={value.zOrganizationID}>
                        {value.zOrganizationName}
                      </MenuItem>
                    ))}
                  {organizationList && organizationList.length == 0 && (
                    <MenuItem>{'Organization not available'}</MenuItem>
                  )}
                </Select>
                {/* <ListItem
                        onClick={() => organizationClick(value.zOrganizationID)}
                        key={value.zOrganizationID}
                        button>
                        <ListItemText
                          id={value.zOrganizationID}

                          primary={value.zOrganizationName}
                        />
                      </ListItem> */}
              </>
              {/* ) */}
              {/* })} */}
              {/* </List> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
          <Card
            style={{
              minHeight: '500px',
              maxHeight: '500px',
              overflow: 'auto',
            }}>
            <CardContent>
              <Typography className={classes.titleheading}>
                Organization Information
              </Typography>
              <Divider />
              <Toolbar className={classes.root}>
                <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                  <Typography
                    className={classes.tblHead}
                    variant="h6"
                    id="tableTitle"
                    component="div">
                    {orgInfo.zName}
                    <IconButton
                      aria-label="delete"
                      className={classes.icoBtnInside}
                      size="small">
                      <EditIcon
                        fontSize="inherit"
                        onClick={addEditOrgan}
                        data-title="Edit Organization"
                      />
                    </IconButton>
                    <br />
                    <small>{orgInfo.zDomain}</small>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  // className={classes.sideHead}
                >
                  <div className={classes.sideHead}>
                    {orgLogo ? (
                      <img
                        src={orgLogo?.zLogoURL || ''}
                        alt="logo"
                        width="auto"
                        height="40px"
                      />
                    ) : (
                      ''
                    )}{' '}
                    <Button
                      color="default"
                      variant="outlined"
                      aria-label="upload picture"
                      id="organizationLogoFile"
                      name="organizationLogoFile"
                      component="label"
                      key={logoCount}
                      sx={{ p: '7px' }}>
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        id="organizationLogoFile"
                        name="organizationLogoFile"
                        onChange={(e) => {
                          handleFileChange(e, orgInfo.zOrganizationID)
                        }}
                      />
                      Add/Edit Logo
                    </Button>
                  </div>
                </Grid>
              </Toolbar>
              <div>
                <>
                  <Grid container className={classes.tabWrapper}>
                    <Grid xs={7} className={classes.tabContainer}>
                      <CusTab
                        value={activeTab1}
                        onChange={handleTabChange1}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab label="USERS" {...a11yProps(0)} />
                        <Tab label="ORDERS" {...a11yProps(0)} />
                        <Tab label="ORG INFO" {...a11yProps(0)} />
                      </CusTab>
                    </Grid>
                    <Grid xs={4} className={classes.tabContainer}>
                      <TextField
                        id="searchOrganisation"
                        placeholder="Search"
                        value={searchOrganisation}
                        onChange={(e) => handleOrganisationSearch(e)}
                        fullWidth
                        name="searchOrganisation"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <IconButton onClick={handleSearchOrg}>
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              </div>
              <Divider />
              <div>
                <TabPanel value={activeTab1} index={0}>
                  <Button
                    color="default"
                    startIcon={<AddIcon />}
                    style={{ float: 'right', textTransform: 'none' }}
                    onClick={addEditUser}
                    data-title="Add User">
                    <b> Add User</b>
                  </Button>
                  <TableContainer
                    component={Paper}
                    className={classes.tablecontainer}>
                    <Table
                      stickyHeader
                      size="small"
                      className={classes.customTable}>
                      {/* Table Header */}
                      <CustomTableHeader
                        {...tableHeadPropsec}
                        order={userorder}
                        orderBy={userorderBy}
                        onRequestSort={userhandleRequestSort}
                      />
                      {/* Table Header */}

                      {/* Table Body */}
                      <TableBody>
                        {orgUsersResult && orgUsersResult.length
                          ? stableSort(
                              orgUsersResult,
                              getComparator(userorder, userorderBy)
                            ).map((record, index) => {
                              return (
                                <>
                                  <TableRow>
                                    <TableCell>{record.zFirstName}</TableCell>
                                    <TableCell>{record.zLastName}</TableCell>
                                    <TableCell>{record.zEmail}</TableCell>
                                    <TableCell>Active</TableCell>
                                    <TableCell>
                                      {record.zUserAdminRoleID == 8
                                        ? 'Hiring Admin'
                                        : record.zUserAdminRoleID == 4
                                        ? 'Learning Pack Admin'
                                        : record.zUserAdminRoleID == 3
                                        ? 'Organization Admin'
                                        : record.zUserAdminRoleID == 5
                                        ? 'Org Admin + LP'
                                        : record.zUserAdminRoleID == 7
                                        ? 'Project Admin'
                                        : record.zUserAdminRoleID == 2
                                        ? 'Partner Admin'
                                        : record.zUserAdminRoleID == 1
                                        ? 'ThinkWise Admin'
                                        : record.zUserAdminRoleID == 6
                                        ? 'Partner Admin + LP'
                                        : 'None'}
                                    </TableCell>
                                    <TableCell>
                                      <IconButton
                                        aria-label="delete"
                                        size="small">
                                        <EditIcon
                                          fontSize="inherit"
                                          onClick={addEditUser}
                                          data-title="Edit User"
                                          data-firstName={record.zFirstName}
                                          data-lastName={record.zLastName}
                                          data-zEmail={record.zEmail}
                                          data-zStatus="true"
                                          data-userID={record.zUserID}
                                          data-userAdminRoleID={
                                            record.zUserAdminRoleID
                                          }
                                        />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        style={{ marginLeft: '1rem' }}>
                                        <DeleteIcon fontSize="inherit" />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                </>
                              )
                            })
                          : ''}
                        {/* <CustomTableEmptyRecord
                      columnsCount={tableColumns.length}
                    /> */}
                      </TableBody>
                      {/* Table Body */}
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel value={activeTab1} index={1}>
                  {isThinkwiseAdmin() && (
                    <Button
                      color="default"
                      startIcon={<AddIcon />}
                      style={{ float: 'right', textTransform: 'none' }}
                      onClick={addEditOrder}
                      data-title="Add Order">
                      <b>New Order</b>
                    </Button>
                  )}
                  <TableContainer
                    component={Paper}
                    className={classes.tablecontainer}>
                    <Table
                      stickyHeader
                      size="small"
                      className={classes.customTable}>
                      {/* Table Header */}
                      <CustomTableHeader
                        {...orderTableHeaders}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      {/* Table Header */}

                      {/* Table Body */}
                      {console.log('order', orders)}
                      <TableBody>
                        {stableSort(orders, getComparator(order, orderBy)).map(
                          (order, index) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell>{order.zcppInvoice}</TableCell>
                                  <TableCell>{order.zName}</TableCell>
                                  <TableCell>{order.zDescription}</TableCell>
                                  <TableCell>{order.zOrderTypeName}</TableCell>
                                  <TableCell>
                                    {order.zNumberOfLicenses}
                                  </TableCell>
                                  {isThinkwiseAdmin() && (
                                    <TableCell>
                                      <IconButton
                                        aria-label="delete"
                                        size="small">
                                        <EditIcon
                                          fontSize="inherit"
                                          onClick={(e) =>
                                            addEditOrder(e, order)
                                          }
                                          data-title="Edit Order"
                                        />
                                      </IconButton>
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() =>
                                          deleteOrder(order.zOrderID)
                                        }
                                        style={{ marginLeft: '1rem' }}>
                                        <DeleteIcon fontSize="inherit" />
                                      </IconButton>
                                    </TableCell>
                                  )}
                                </TableRow>
                              </>
                            )
                          }
                        )}
                      </TableBody>
                      {/* Table Body */}
                    </Table>
                  </TableContainer>
                </TabPanel>
                <TabPanel value={activeTab1} index={2}>
                  <div style={{ paddingLeft: '30px' }}>
                    <Address
                      name={orgInfo.zName}
                      addressLine1={orgInfo.zAddressLine1}
                      addressLine2={orgInfo.zAddressLine2}
                      city={orgInfo.zCity}
                      state={orgInfo.state}
                      zipcode={orgInfo.zZipCode}
                      websiteUrl={orgInfo.zDomain}
                    />
                  </div>
                </TabPanel>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {editFlag && (
        <EditPartner
          open={editFlag}
          partnerId={title === 'Edit Partner' ? partnerId : ''}
          partnerInfo={title === 'Edit Partner' ? partnerInfo : ''}
          handleReset={() => {
            handleAddEditPartner()
            getPartnerlist()
            getPartnerInfo(partnerId)
          }}
          title={title}
          // partnerId= {partnerId}
        />
      )}
      {adminFlag && (
        <AddEditPartnerAdmin
          open={adminFlag}
          handleReset={addPartnerAdmin}
          title={title}
        />
      )}
      {userFlag && (
        <AddEditUser
          open={userFlag}
          handleReset={() => {
            addEditUser()
            organizationUsers(orgInfo.zOrganizationID)
          }}
          orders={orders}
          title={title}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userEmail={userEmail}
          userPassword={userPassword}
          userStatus={userStatus}
          selectedUserId={selectedUserId}
          orgId={orgInfo.zOrganizationID}
          userAdminRoleId={userAdminRoleId}
        />
      )}
      {organFlag && (
        <AddEditOrganization
          // value={organizationCreateInitial}
          open={organFlag}
          partnerid={partnerId}
          orgInfo={title === 'Edit Organization' ? orgInfo : []}
          handleReset={() => {
            setOrganFlag(false)
            organizationStore(partnerId)
          }}
          title={title}
        />
      )}
      {isOrderModal && (
        <AddEditOrder
          open={isOrderModal}
          partnerId={partnerId}
          orgId={orgInfo.zOrganizationID}
          orderInfo={orderInfo}
          handleReset={() => {
            setOrderModal(false)
            organizationOrders(orgInfo.zOrganizationID)
          }}
          title={title}
        />
      )}
    </div>
  )
}
