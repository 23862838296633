import * as types from '../action/ActionType'
import produce from 'immer'
import {
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from '../action/Typeconstent'

export default function NewselectedThinkwiseItems(state = {}, action) {
  const { type, payload = {} } = action
  const { compentencyid = '', id = '', capabilityid = '' } = payload

  let CATEGORYNAME = THINKWISE_CATEGORY + compentencyid
  let SUBCATEGORYNAME = THINKWISE_SUB_CATEGORY + capabilityid
  switch (type) {
    case types.NEW_SELECTED_THINKWISE_ITEMS_ID:
      let copySTate = { ...state }
      copySTate[CATEGORYNAME] = {
        ...(copySTate[CATEGORYNAME] || {}),
      }

      if (copySTate[CATEGORYNAME]?.[SUBCATEGORYNAME]?.includes(id)) {
        copySTate[CATEGORYNAME][SUBCATEGORYNAME] = copySTate[CATEGORYNAME][
          SUBCATEGORYNAME
        ].filter((va) => va != id)
      } else {
        //length
        copySTate[CATEGORYNAME][SUBCATEGORYNAME] = [
          ...(copySTate[CATEGORYNAME]?.[SUBCATEGORYNAME] || []),
          id,
          // _lengthval,
        ]
      }
      if (!copySTate[CATEGORYNAME]?.[SUBCATEGORYNAME].length) {
        delete copySTate[CATEGORYNAME]?.[SUBCATEGORYNAME]
      }
      if (!Object.keys(copySTate[CATEGORYNAME])) {
        delete copySTate[CATEGORYNAME]
      }
      return copySTate
    case types.NEW_SELECTED_THINKWISE_ITEMS_ALL_ID:
      const { allItem } = payload
      let allState = { ...state }

      allState[CATEGORYNAME] = { ...(allState[CATEGORYNAME] || {}) }
      allState[CATEGORYNAME][SUBCATEGORYNAME] = allItem
      if (!allState[CATEGORYNAME]?.[SUBCATEGORYNAME].length) {
        delete allState[CATEGORYNAME]?.[SUBCATEGORYNAME]
      }
      if (!Object.keys(allState[CATEGORYNAME])) {
        delete allState[CATEGORYNAME]
      }
      return allState
    case types.NEW_API_SELECTED_DATA:
      return (state = payload)

    case types.NEW_DELETE_THINKWISE:
      let deletedState = { ...state }
      delete deletedState[CATEGORYNAME]
      return (state = deletedState)
    default:
      return state
  }
}
