import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import Swal from 'sweetalert2'
import CustomButton from '../../../../../common/CustomButton'
import AddParticipant from './AddParticipant'
import ListParticipant from './ListParticipant'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  CustomMessage,
  CustomPageLoading,
  getComparator,
  stableSort,
} from '../../../../../../../src/function/HelperFunction'
import AddRoster from './AddRoster'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import EditIcon from '@material-ui/icons/Edit'
import TableHead from '@material-ui/core/TableHead'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import ReminderEmail from './ReminderEmail'

import {
  GET_TASK_PARTICIPANT_LIST,
  GET_USER_RELATIONSHIP_LIST,
  RELATIONSHIP_LIST_URL,
  LIST_PARTICIPANT,
  LIST_RESPONDANT,
  COUNT_PARTICIPANT,
  SENT_INVITATION_TEST,
  GET_TEAM_RESPOTANT,
  SEND_ORIGINAL_EMAIL_URL,
  GET_ADMIN_APPROVAL_DETAILS,
  GET_PARTICIPANT_APPROVE_DETAILS,
  GET_BYPASSROSTER_DETAILS,
  POST_UPSERT_ROSTER_FORM,
  UPSERT_ADMIN_APPROVEL,
  SAVE_MANAGE_ROSTER,
  GET_RESPONDENT_SEND_GRID_ALL_DETAILS,
  SEND_GRID_RESPONDENT_INSERT,
  GET_RESPONDENT_SEND_GRID_DETAILS,
} from '../../../../../../helpers/constants/ApiRoutes'
import {
  authHeader,
  getUserId,
  getAlertToast,
} from '../../../../../common/CustomFunction'
import { API } from '../../../../../../helpers/API'
import { useParams } from 'react-router-dom'
import Addrater from './Addrater'
import Editrater from './Editrater'
import Deleterater from './Deleterater'
import {
  ADD_ROSTER_FIELD,
  ROSTER_LIST,
  EDIT_ROSTER_FIELD,
  DELETE_ROSTER_FIELD,
  REMOVE_ROSTER_FIELD,
} from '../../../../../../redux/action/ActionType'
import { useDispatch, useSelector } from 'react-redux'
import { CheckBox } from '@mui/icons-material'
import CustomLoader from '../../../../../common/CustomLoader'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useLayoutEffect } from 'react'
import { set } from 'date-fns'
import EmailInformation from './EmailInformation'
import { async } from 'q'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
    padding: 0,
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    /*height: '64px',*/
  },
  CheckBoxApprove: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  accHead11: {
    backgroundColor: '#64B5F6',
    color: '#fff',
  },
  acc: {
    marginBottom: '1rem',
    width: '100%',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      maxWidth: '150px',
      padding: '6px 2px 6px 10px',
      overflow: 'hidden',
    },
  },
  tablegrid: {
    width: '100%',
  },
  container: {
    columnWidth: '200px',
  },
}))
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

const ParticipantInitial = [
  {
    firstName: 'sathish',
    lastName: 'k',
    rater: 'rather',
    list: [
      {
        firstName: 'name1',
        lastName: 'name2',
        email: 'sample@123',
        status: 'Not Started 0%-0 of 109 items',
        relationship: 'Rater Relationship',
      },
    ],
  },
]
const validationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .trim()
    .required('Email is required'),
  rater: Yup.string()
    .trim()
    .required('Rater relationship is required'),
})
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  list: [
    {
      firstName: 'name1',
      lastName: 'name2',
      email: 'sample@123',
      status: 'Not Started 0%-0 of 109 items',
      relationship: 'Rater Relationship',
    },
  ],
  rater: 'Self',
}
export default function DetailsRoster(props) {
  console.log(props, 'propsss')
  const { enqueueSnackbar } = useSnackbar()
  const [participantList, setparticipantList] = useState(ParticipantInitial)
  const [logsuccess, setLog] = useState(false)
  const [editOption, seteditOption] = useState(false)
  const [addOption, setaddOption] = useState(true)
  const [collapse, setcollapse] = useState(false)
  const [view, setView] = useState(true)
  const [remindFlag, setRemindFlag] = useState(false)
  let listlen = 0

  const [id, setid] = useState('')
  const [viewByparticpant, setviewByparticpant] = useState([])
  const [count, setCount] = useState([])
  const [loading, setLoading] = useState(false)

  const collapseToggle = () => setcollapse((val) => !val)
  const dispatch = useDispatch()
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('zRespondantFirstName')
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const classes = useStyles()
  const [data, setDataValue] = useState([initialValues])
  const [accor, setAccor] = useState([])
  const [addFlag, setFlag] = useState(false)
  const [addRosterFlag, setRosterFlag] = useState(false)
  const [expandedList, setExpanded] = useState([])
  const [activeTab, setActiveTab] = useState(0)
  const [roster, setRoster] = useState(0)
  const [pid, setPid] = useState(0)
  const [raterList, setRaterList] = useState([])
  const [participantListNew, setparticipantListNew] = useState([])
  const [respondantList, setrespondantList] = useState([])
  const rosterList = useSelector((s) => s.RosterReducer)
  console.log('rosterlist', rosterList)
  let params = useParams()
  const [deleterater, setDeleterater] = useState(false)
  const [adminApprovalCheck, setAdminApprovalCheck] = useState(false)
  const [adminApprovedRoster, setAdminApprovedRoster] = useState(false)
  const [participantDetails, setParticipantDetails] = useState([])
  const [approvedTable, setApprovedTable] = useState([])
  const [userRelationShipListData, setUserRelationShipListData] = useState([])
  console.log(userRelationShipListData, 'userRelationShipListData')
  console.log(participantDetails, 'participantDetails')
  const [openInformation, setOpenInfromation] = useState(false)
  console.log(
    !!adminApprovedRoster,
    adminApprovalCheck,
    'setAdminApprovalCheck'
  )
  const userAdminCheck = localStorage.getItem('USER_ADMIN_ROLE')
  const tableColumns = [
    { id: 'zRespondantLastName', title: 'Last Name', sort: true },
    { id: 'zRespondantFirstName', title: 'First Name', sort: true },
    { id: 'zRespondantEmail', title: 'Email', sort: true },
    { id: 'zRelationshipName', title: 'Rater Relationship', sort: true },
    { id: 'zProgressText', title: 'Status', sort: true },
    { id: 'invitation', title: 'Invitation' },
    { id: 'action', title: '' },
  ]

  const tableColumns1 = [
    { id: 'zRespondantLastName', title: 'Last Name', sort: true },
    { id: 'zRespondantFirstName', title: 'First Name', sort: true },
    { id: 'zRespondantEmail', title: 'Email', sort: true },
    { id: 'zRelationshipName', title: 'Rater Relationship', sort: true },
    { id: 'zProgressText', title: 'Status', sort: true },
    { id: 'EmailStatus', title: 'Email Status' },
    { id: 'invitation', title: 'Invitation' },
    { id: 'action', title: '' },
  ]

  const tableHeadProps = { tableColumns }
  const tableHeadProps1 = { tableColumns1 }

  useEffect(() => {
    localStorage.setItem(
      'ADMIN_APPROVAL',
      adminApprovalCheck[0]?.zAdminApproval
    )
  }, [adminApprovalCheck])

  const deleteraterDialog = (value) => {
    if (value == 'sent') {
      // CustomMessage('User Invited', 'success', enqueueSnackbar)
      alert.success('Invitation sent')
    }
    setDeleterater(!deleterater)
  }

  const reminderFun = (e) => {
    e.stopPropagation()
    const { rosterid } = e.currentTarget.dataset
    console.log('id', rosterid, e.currentTarget.dataset.rosterid)
    setRoster(rosterid)
    setRemindFlag(!remindFlag)
  }

  const resetFun = (value) => {
    if (value == 'sent') {
      Swal.fire({
        title: "<h5 style='color:#fff'>Email was sent successfully...</h5>",
        background: '#00cc00',
      })
      setRemindFlag(!remindFlag)
      setRoster(0)
    } else {
      setRemindFlag(!remindFlag)
      setRoster(0)
    }
  }

  const addParticipant = () => {
    setid('')
    setFlag(true)
  }

  const addRoster = () => {
    setRosterFlag(true)
  }

  const handleAddParticipant = (e) => {
    setFlag(!addFlag)
  }

  const handleAddRoster = (e) => {
    setRosterFlag(!addRosterFlag)
  }

  const informationShow = (resid, participantId) => {
    console.log('hiiiiii')
    respondentSendGridDetails(resid, participantId)
  }

  const closeInformation = () => {
    console.log('jjiiii')
    setOpenInfromation(!openInformation)
  }

  const addRater = (e) => {
    e.stopPropagation()

    const { rosterid } = e.currentTarget.dataset
    dispatch({ type: ADD_ROSTER_FIELD, payload: rosterid })

    // newRosterList[rosterid] = [...newRosterList[rosterid]]
    // setid('7')
  }

  const resetRater = (rosterid) => {
    dispatch({ type: REMOVE_ROSTER_FIELD, payload: rosterid })

    // newRosterList[rosterid] = [...newRosterList[rosterid]]
    // setid('7')
  }

  const sendReminder = (e) => {
    e.stopPropagation()
    Swal.fire('Are you sure send Reminder?', {
      buttons: ['No', 'Yes'],
    }).then((value) => {})
    // newRosterList[rosterid] = [...newRosterList[rosterid]]
    // setid('7')
  }

  const sendOriginal = (e) => {
    e.stopPropagation()
    const { name, lname, mail, resid, userid } = e.currentTarget.dataset
    Swal.fire({
      title: 'ARE YOU SURE?',
      text:
        'You have selected to send the original invite to ' +
        name +
        ' ' +
        lname +
        ' (' +
        mail +
        ').',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        postSendGridRespondentInsert(resid, userid)
      } else {
      }
    })
  }

  const handleSaveRoster = async () => {
    const {
      projectID,
      LoginUserID,
      ParticipantID,
      handleReset,
      getParticipantList,
    } = props
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_MANAGE_ROSTER,
      data: {
        userID: '',
        projectID: params?.id,
        dueDate: props.projectDetails.zDueDateFormat,
        chkSendEmail: true,
        baseURL: window.location.origin,
      },
    }
    const response = await API(postConfig)
    if (response.status === 200) {
      Swal.fire({
        title: "<h5 style='color:#fff'>Changes were saved successfully.</h5>",
        background: '#00cc00',
      }).then((value) => {})
      getCountParticipant()
      //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
      //getParticipantList();
      // handleReset()
    }
  }

  const postSendGridRespondentInsert = async (resid, userid) => {
    setLoading(true)
    const config = {
      method: 'POST',
      url: SEND_GRID_RESPONDENT_INSERT,
      data: {
        zParticipantID: userid,
        zProjectID: params?.id,
        zRespondentID: resid,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'responseee')
      const { data } = response
      console.log('.response', data)
      sendOrginalmail(resid, userid, data?.data)
      // if (data.status === 'Success') {

      // }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const sendOrginalmail = async (resid, userid, SendgridID) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: SEND_ORIGINAL_EMAIL_URL,
      params: {
        // subject: null,
        // text: null,
        UserID: resid,

        respondentID: resid,
        BaseUrl: '' + window.location.origin + '/signin/',
        // originalEmailTemplateID: parseInt(0),
        // userSendEmail: true,
        ProjectID: params?.id,
        RespondentSendgridID: SendgridID,
        // testEmail: mail,
        // surveyLinkURL: '' + window.location.origin + '/signin/',
        // sendTest: false,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        Swal.fire({
          title: "<h5 style='color:#fff'>Email was sent successfully</h5>",
          background: '#00cc00',
        }).then((value) => {})
        //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
        //getParticipantList()
      }
    } catch (error) {
      CustomMessage('Email Not Send', 'error', enqueueSnackbar) //call toster
      console.log(error)
    }
  }

  const editRater = (e) => {
    e.stopPropagation()
    console.log(e.currentTarget.dataset.rosterid)
    const { rosterid, rid } = e.currentTarget.dataset
    seteditOption(!editOption)
    setPid(rid)
    dispatch({
      type: EDIT_ROSTER_FIELD,
      payload: rosterid,
      actionnew: editOption,
    })
  }

  const deleteRater = (e) => {
    e.stopPropagation()
    setDeleterater(true)

    // console.log(e.currentTarget);
    const { rosterid, rid } = e.currentTarget.dataset
    setPid(rid)

    console.log('ros delete', rosterid)
    dispatch({ type: DELETE_ROSTER_FIELD, payload: rosterid })

    // newRosterList[rosterid] = [...newRosterList[rosterid]]
    // setid('7')
  }

  const handleAccordion = (value) => {
    let currentList = [...expandedList]
    console.log(value)
    //let currentList = []
    if (!currentList.includes(value)) {
      currentList.push(value)
      setExpanded(currentList)
    } else {
      currentList = currentList.filter((list) => list != value)
      setExpanded(currentList)
    }
  }

  const handleAllAccordion = () => {
    let currentList = [...expandedList]
    for (let i = 0; i < 2; i++) {
      if (!currentList.includes(`list_${i}`)) {
        currentList.push(`list_${i}`)
      }
    }
    setExpanded(currentList)
  }

  const AddRoster2 = (form) => {
    setparticipantList((val) => [...val, form])
    setValues(initialValues)
    setFlag(false)
  }
  const cancelEdit = (e) => {
    console.log(e.currentTarget.dataset)
    const { rosterid, rid } = e.currentTarget.dataset

    seteditOption(!editOption)
    setPid(rid)
    dispatch({
      type: EDIT_ROSTER_FIELD,
      payload: rosterid,
      actionnew: editOption,
    })
  }

  //rater list
  const getRaterRelation = async () => {
    const ratconfig = {
      method: 'GET',

      url: RELATIONSHIP_LIST_URL,
      headers: authHeader(),
      params: {
        UserID: getUserId(),
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      if (status == 'Success') {
        setRaterList(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getParticipant = async () => {
    const ratconfig = {
      method: 'GET',

      url: LIST_PARTICIPANT,
      headers: authHeader(),
      params: {
        ProjectID: params?.id,
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      if (status == 'Success') {
        setparticipantListNew(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  console.log('part', participantListNew)
  const getRespondant = async () => {
    const ratconfig = {
      method: 'GET',

      // url: LIST_RESPONDANT,
      url: GET_TEAM_RESPOTANT,
      headers: authHeader(),
      params: {
        ProjectID: params?.id,
        UserId: getUserId(),
        tab: 'Respondents',
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, TabList } = response.data
      console.log('respond', data)
      if (status == 'Success') {
        setrespondantList(TabList)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getCountParticipant = async () => {
    setLoadingRes(true)
    const ratconfig = {
      method: 'GET',

      url: COUNT_PARTICIPANT,
      headers: authHeader(),
      params: {
        ProjectID: params?.id,
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      if (status == 'Success') {
        setCount(data)
        console.log('count', data)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingRes(false)
    }
  }

  // Get Participant List
  const getParticipantList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      // url: GET_TASK_PARTICIPANT_LIST,
      url: GET_USER_RELATIONSHIP_LIST,
      params: {
        // RespondentID: getUserId(),
        // ProjectID: params?.id
        ProjectID: params?.id,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'repiooiu')
      const { status, data } = response?.data
      console.log(data, 'daaaaaaaaaaj')
      if (status == 'Success') {
        const userRelationShipList = {}
        data?.map((rec) => {
          console.log(rec, 'recccccccc')
          if (
            userRelationShipList &&
            userRelationShipList[rec.zParticipantID]
          ) {
            console.log('1111111111222')
            userRelationShipList[rec.zParticipantID].data.push(rec)
          } else {
            console.log('2222222222111')
            userRelationShipList[rec.zParticipantID] = {
              zParticipantID: rec.zParticipantID,
              isAdd: false,
              isEdit: false,
              isDelete: false,
              data: [rec],
            }
          }
        })
        setUserRelationShipListData(data)
        dispatch({ type: ROSTER_LIST, payload: userRelationShipList })
        // setRosterList(userRelationShipList)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getParticipantApproveDetails = async () => {
    const origanizationID = localStorage.getItem('LOG_ORGANIZATION_ID')
    const ParticipantID = localStorage.getItem('PARTICIPANT_ID')
    const loginUserID = localStorage.getItem('LOG_USER_ID')
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: GET_PARTICIPANT_APPROVE_DETAILS,
      params: {
        // UserID: loginUserID,
        // respondentID: resid,
        ProjectID: params?.id,
        // ParticipantID
      },
    }
    try {
      const response = await API(config)
      const { status, data } = response
      console.log(status, data, 'resposeadmin')
      if (status === 200) {
        setParticipantDetails(data)
        // setApprovedTable(data?.zAdminApproved)
        //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
        //getParticipantList()
        // setLoading(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const getAdminApprovalDetails = async () => {
    const ratconfig = {
      method: 'GET',
      url: GET_BYPASSROSTER_DETAILS,
      headers: authHeader(),
      params: {
        ProjectID: params?.id,
        // UserId: getUserId(),
        // tab: 'Respondents',
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      console.log('response.data', data)
      if (status == 'Success') {
        setAdminApprovalCheck(data)
        props.setAdditionLink(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getRespondentSendGridAllDdetails = async () => {
    const ratconfig = {
      method: 'GET',
      url: GET_RESPONDENT_SEND_GRID_ALL_DETAILS,
      headers: authHeader(),
      params: {
        ProjectID: params?.id,
        // UserId: getUserId(),
        // tab: 'Respondents',
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      console.log('response.data', data)
      if (status == 'Success') {
        setRespondentSendGridAllDdetails(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const respondentSendGridDetails = async (resid, userid) => {
    const ratconfig = {
      method: 'GET',
      url: GET_RESPONDENT_SEND_GRID_DETAILS,
      headers: authHeader(),
      params: {
        ProjectID: params?.id,
        respondentID: resid,
        ParticipantID: userid,
        // UserId: getUserId(),
        // tab: 'Respondents',
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      console.log('response.data', data)
      if (status === 'Success') {
        setEmailStatusInfomation(() => data)
        if (data?.length <= 0) {
          CustomMessage('No Data Found', 'error', enqueueSnackbar) //call toster
        } else {
          setOpenInfromation((prev) => !prev)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  const postUpsertRoster = async (id) => {
    setLoading(true)
    const config = {
      method: 'POST',
      url: POST_UPSERT_ROSTER_FORM,
      data: {
        participantID: id,
        projectID: params?.id,
        isApproved: true,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'responseee')
      const { data } = response
      console.log('response', data)
      if (data.status === 'Success') {
        // setUpsertBypassRoster(data)
        CustomMessage(data?.message, 'success', enqueueSnackbar) //call toster
        handleSaveRoster()
        getParticipantApproveDetails()
        // setTimeout(() => {
        //   window.location.reload()
        // }, 500)
      }
      // setLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  const upsertAdminApprovel = async (id) => {
    const config = {
      method: 'POST',
      url: UPSERT_ADMIN_APPROVEL,
      headers: authHeader(),
      data: {
        projectID: params?.id,
        adminApproval: !adminApprovalCheck[0]?.zAdminApproval === true ? 1 : 0,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'responseee')
      const { data } = response
      console.log('response', data)
      if (data.status === 'Success') {
        // setUpsertBypassRoster(data)
        CustomMessage(data?.message, 'success', enqueueSnackbar) //call toster
        getAdminApprovalDetails()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAdminApprovalDetails()
    getParticipantApproveDetails()
    getRaterRelation()
    getParticipantList()
    getParticipant()
    getCountParticipant()
    getRespondant()
    getRespondentSendGridAllDdetails()
  }, [])
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => AddRoster2(values),
  })

  console.log('list', rosterList)

  const handleChangeRoaster = (e, type) => {
    setAdminApprovalCheck([
      { ...adminApprovalCheck[0], zAdminApproval: e.target.checked },
    ])
    upsertAdminApprovel()
  }
  const handleApproved = (e, index, participantID) => {
    // const AdminChecked = (adminApprovedRoster[index].zAdminApproved =
    //   e.target.checked)
    // setAdminApprovedRoster([{ ...adminApprovedRoster, AdminChecked }])
    postUpsertRoster(participantID)
  }
  const [array1State, setArray1State] = useState([])
  const [array2State, setArray2State] = useState([])
  const [approvedParticipantList, setApprovedParticipantList] = useState([])
  const [unApprovedParticipantList, setUnApprovedParticipantList] = useState([])
  const [emailStatusInfomation, setEmailStatusInfomation] = useState(null)
  const [loadingRes, setLoadingRes] = useState(false)
  const [
    respondentSendGridAllDdetails,
    setRespondentSendGridAllDdetails,
  ] = useState([])
  console.log(
    unApprovedParticipantList,
    'unApprovedParticipantList',
    approvedParticipantList
  )
  const approvedList = []
  const unApprovedList = []

  useEffect(() => {
    if (userRelationShipListData.length > 0) {
      for (let index = 0; index < participantDetails?.data?.length; index++) {
        if (participantDetails?.data[index].zIsApproved) {
          approvedList.push(participantDetails?.data[index])
        } else {
          unApprovedList.push(participantDetails?.data[index])
        }
      }
      console.log(approvedList, unApprovedList, 'unApprovedList')

      console.log(
        userRelationShipListData,
        approvedList?.zParticipantID,
        'listofdata'
      )
      const array1 = []
      const array2 = []
      for (let index = 0; index < userRelationShipListData.length; index++) {
        for (let i = 0; i < approvedList.length + unApprovedList.length; i++) {
          if (
            userRelationShipListData[index].zParticipantID ==
            approvedList[i]?.zParticipantID // approved
          ) {
            array1.push(userRelationShipListData[index])
            break
          } else if (
            userRelationShipListData[index].zParticipantID ===
            unApprovedList[i]?.zParticipantID //unapproved
          ) {
            array2.push(userRelationShipListData[index])
            break
          } else {
            array1.push(userRelationShipListData[index])
          }
        }
      }
      console.log(array1, array2, 'array1array1array1')

      setArray1State(array1)
      setArray2State(array2)
    }
  }, [userRelationShipListData, participantDetails])

  useEffect(() => {
    // Create a Set to store unique IDs
    const uniqueIds = new Set()
    // Filter the array to remove duplicates
    const uniqueApproveList = array1State?.filter((obj) => {
      console.log(obj, 'objjjj')
      if (!uniqueIds.has(obj?.zParticipantID)) {
        uniqueIds.add(obj?.zParticipantID)
        return true
      }
    })
    setApprovedParticipantList(uniqueApproveList)

    const unApproveUniqueList = array2State?.filter((obj2) => {
      console.log(obj2, 'objjjj22')
      if (!uniqueIds.has(obj2.zParticipantID)) {
        uniqueIds.add(obj2.zParticipantID)
        return true
      }
    })
    setUnApprovedParticipantList(unApproveUniqueList)
  }, [array1State, array2State, userRelationShipListData])

  console.log(approvedParticipantList, 'approvedParticipantList')
  console.log(unApprovedParticipantList, 'unApprovedParticipantList')

  const labelStyle = {
    fontSize: '14px',
  }
  const handleResetParticipant = () => {
    handleAddParticipant()
    getAdminApprovalDetails()
    getParticipantApproveDetails()
    getRaterRelation()
    getParticipantList()
    getParticipant()
    getCountParticipant()
    getRespondant()
    getRespondentSendGridAllDdetails()
    // getPartnerlist()
    //getPartnerInfo(partnerId)
  }
  return (
    <>
      {<CustomLoader loading={loading ? true : false} />}
      <Grid>
        {userAdminCheck && !!adminApprovalCheck[0]?.zByPassRoster ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!adminApprovalCheck[0]?.zAdminApproval}
                onChange={(e) => handleChangeRoaster(e)}
                color="primary"
                //  disabled={!byPassRosterDetails[0]?.zByPassRoster}
              />
            }
            style={{ marginLeft: '10px' }}
            label=" Require Roster Approval by Admin"
          />
        ) : (
          ''
        )}
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ marginTop: '2px', padding: '0px 30px' }}>
          <Grid item xs={3}>
            <p onClick={() => setView(!view)} style={{ cursor: 'pointer' }}>
              {view == true ? 'View All Respondents' : 'View All Participants'}
            </p>
          </Grid>
          <Grid item xs={6}></Grid>
          {view == true ? (
            <Grid item xs={3}>
              {/*<Button
            variant="contained"
            color="default"
            className={classes.headBtn}
            startIcon={<AddIcon />}
            onClick={addRoster}
            style={{ float: 'right' }}>
            Add Roster
          </Button>*/}
              <Button
                variant="contained"
                color="default"
                className={classes.headBtn}
                startIcon={<AddIcon />}
                onClick={addParticipant}
                style={{ float: 'right' }}>
                Add Participant
              </Button>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="default"
                className={classes.headBtn}
                startIcon={<AddIcon />}
                onClick={addParticipant}
                style={{ float: 'right' }}>
                Add Participant
              </Button>
            </Grid>
          )}
          {loadingRes && <CustomPageLoading loading={loadingRes} />}
          {!!adminApprovalCheck[0]?.zByPassRoster ? (
            <Grid style={{ width: '100%' }}>
              <Grid style={{ overflow: 'auto' }}>
                {Object.values(unApprovedParticipantList).map(
                  (element, key, index) => {
                    const { zParticipantID, zParticipantFullName } = element
                    console.log(
                      unApprovedParticipantList,
                      'unApprovedParticipantList'
                    )
                    console.log('unApprovedParticipantListlllllllllllll')
                    let data1 = []
                    let isAddFlag = false
                    let isEditFlag = false
                    let isDeleteFlag = false
                    let participId
                    let data = rosterList[zParticipantID]
                    console.log('setlist', element)
                    let l = toString(zParticipantID)
                    if (data) {
                      const { isAdd, isEdit, isDelete, zParticipantID } = data

                      isAddFlag = isAdd
                      isEditFlag = isEdit
                      isDeleteFlag = isDelete
                      participId = zParticipantID
                      data1 = rosterList[zParticipantID].data
                    }
                    var ccount = 0
                    var tcount = 0
                    var rname = ''
                    return (
                      <>
                        {view == true && (
                          <Accordion
                            key={`acc_${zParticipantID}`}
                            expanded={
                              expandedList.includes(`list_${zParticipantID}`)
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleAccordion(`list_${zParticipantID}`)
                            }
                            className={classes.acc}
                            fullWidth>
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: '#fff' }} />
                              }
                              aria-controls={`item_${zParticipantID}-content`}
                              id={`item_${zParticipantID}-header`}
                              className={classes.accHead11}
                              // style={{ backgroundColor: 'pink !important' }}
                            >
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'start',
                                }}>
                                <Typography
                                  className={classes.heading}
                                  style={{
                                    fontSize: '16px',
                                    whiteSpace: 'nowrap',
                                  }}>
                                  {zParticipantFullName}
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <div className={classes.container}>
                                  {count.map((participant, i) => {
                                    const {
                                      zRelationshipName,
                                      zCompleteCount,
                                      zTotalCount,
                                    } = participant
                                    if (
                                      participant.zParticipantID ==
                                      zParticipantID
                                    ) {
                                      listlen = listlen + 1
                                    }
                                    return participant.zParticipantID ==
                                      zParticipantID ? (
                                      // && listlen <= 4
                                      <>
                                        <Typography>
                                          {zRelationshipName}: {zCompleteCount}{' '}
                                          of {zTotalCount} Complete
                                        </Typography>
                                      </>
                                    ) : (
                                      <>
                                        <Typography></Typography>
                                      </>
                                    )
                                  })}
                                </div>
                              </Grid>
                              {!!adminApprovalCheck[0]?.zAdminApproval ? (
                                <Grid className={classes.CheckBoxApprove}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        // checked={e.target.checked}
                                        onChange={(e) =>
                                          handleApproved(
                                            e,
                                            index,
                                            zParticipantID
                                          )
                                        }
                                        color="white"
                                        //  disabled={!byPassRosterDetails[0]?.zByPassRoster}
                                      />
                                    }
                                    style={{
                                      whiteSpace: 'nowrap',
                                      margin: '0px',
                                    }}
                                  />
                                  <span>
                                    <p>Approve Paricipant’s Roster</p>
                                  </span>
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid
                                item
                                xs={3}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  alignItems: 'center',
                                }}>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'end',
                                  }}>
                                  <Button
                                    className={classes.toggleDescBtn}
                                    startIcon={
                                      <AddIcon
                                        className={classes.toggleDescIcon}
                                      />
                                    }
                                    onKeyPress={(e) => {
                                      e.zParticipantID === 'Enter' &&
                                        e.preventDefault()
                                    }}
                                    data-rosterid={zParticipantID}
                                    style={{
                                      background: '#64B5F6',
                                      fontSize: '14px',
                                    }}
                                    onClick={
                                      // e.stopPropagation()
                                      addRater
                                    }>
                                    Add Rater
                                  </Button>
                                </div>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accDetail}>
                              <Grid item xs={12}>
                                <TableContainer
                                  component={Paper}
                                  className={classes.tablecontainer}>
                                  <Table
                                    stickyHeader
                                    size="small"
                                    className={classes.customTable}
                                    id="roster-head">
                                    {/* Table Header */}
                                    <CustomTableHeader
                                      {...tableHeadProps}
                                      order={order}
                                      orderBy={orderBy}
                                      onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                      {data1 &&
                                        stableSort(
                                          data1,
                                          getComparator(order, orderBy)
                                        ).map((val, key) => {
                                          const {
                                            zStatusName,
                                            zRespondantFirstName,
                                            zRespondantLastName,
                                            zRespondantEmail,
                                            zRelationshipName,
                                            zProjectUserRelationshipID,
                                            zProgressText,
                                            zRespondentID,
                                            zParticipantID,
                                          } = val
                                          console.log(val, 'kkkkkkkkkkkkkkkk')

                                          return (
                                            <>
                                              <TableRow
                                                data-id={zParticipantID}>
                                                <TableCell align="left">
                                                  {zRespondantLastName}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {zRespondantFirstName}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {zRespondantEmail}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {zRelationshipName}{' '}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {zStatusName}
                                                  <br />
                                                  {zProgressText}
                                                </TableCell>
                                                <TableCell align="center">
                                                  {props.projectStatus ==
                                                  'Completed' ? (
                                                    ''
                                                  ) : (
                                                    <>
                                                      <Button
                                                        size="sm"
                                                        color="default"
                                                        style={{
                                                          textTransform: 'none',
                                                          padding: '2px',
                                                        }}
                                                        data-title="Send Original"
                                                        data-name={
                                                          zRespondantFirstName
                                                        }
                                                        data-lname={
                                                          zRespondantLastName
                                                        }
                                                        data-mail={
                                                          zRespondantEmail
                                                        }
                                                        data-resid={
                                                          zRespondentID
                                                        }
                                                        data-userid={
                                                          zParticipantID
                                                        }
                                                        onClick={
                                                          // e.stopPropagation()
                                                          sendOriginal
                                                        }>
                                                        <span>
                                                          Send Original
                                                        </span>
                                                      </Button>
                                                      <Divider variant="fullWidth" />
                                                      <Button
                                                        size="sm"
                                                        color="default"
                                                        style={{
                                                          textTransform: 'none',
                                                          padding: '2px',
                                                        }}
                                                        data-title="Send Remainder"
                                                        data-rosterid={
                                                          zRespondentID
                                                        }
                                                        onClick={reminderFun}>
                                                        <span>
                                                          Send Reminder
                                                        </span>
                                                      </Button>
                                                    </>
                                                  )}
                                                </TableCell>
                                                <TableCell align="left">
                                                  <IconButton
                                                    aria-label="edit"
                                                    size="small">
                                                    <EditIcon
                                                      onKeyPress={(e) => {
                                                        e.zParticipantID ===
                                                          'Enter' &&
                                                          e.preventDefault()
                                                      }}
                                                      data-rosterid={participId}
                                                      data-rid={
                                                        zProjectUserRelationshipID
                                                      }
                                                      onClick={
                                                        // e.stopPropagation()
                                                        editRater
                                                      }
                                                      fontSize="inherit"
                                                    />
                                                  </IconButton>

                                                  <IconButton
                                                    aria-label="delete"
                                                    size="small"
                                                    style={{
                                                      marginLeft: '1rem',
                                                    }}>
                                                    <DeleteIcon
                                                      onKeyPress={(e) => {
                                                        e.key === 'Enter' &&
                                                          e.preventDefault()
                                                      }}
                                                      data-rosterid={
                                                        zParticipantID
                                                      }
                                                      data-rid={
                                                        zProjectUserRelationshipID
                                                      }
                                                      onClick={
                                                        // e.stopPropagation()
                                                        deleteRater
                                                      }
                                                      fontSize="inherit"
                                                    />
                                                  </IconButton>
                                                </TableCell>
                                              </TableRow>
                                              {/* {id && ( */}

                                              {isEditFlag &&
                                                zProjectUserRelationshipID ==
                                                  pid && (
                                                  <TableRow>
                                                    <TableCell
                                                      align="left"
                                                      colSpan={12}>
                                                      <Grid
                                                        className=""
                                                        container
                                                        direction="row">
                                                        <Editrater
                                                          classes={classes}
                                                          raterList={raterList}
                                                          firstName={
                                                            zRespondantFirstName
                                                          }
                                                          lastName={
                                                            zRespondantLastName
                                                          }
                                                          email={
                                                            zRespondantEmail
                                                          }
                                                          relatonshipName={
                                                            zRelationshipName
                                                          }
                                                          relationshipName={
                                                            zRelationshipName
                                                          }
                                                          relationshipID={
                                                            zProjectUserRelationshipID
                                                          }
                                                          userID={participId}
                                                          RespondantID={
                                                            zRespondentID
                                                          }
                                                          projectDetails={
                                                            props.projectDetails
                                                          }
                                                          projectID={params.id}
                                                          LoginUserID={getUserId()}
                                                          getParticipantList={
                                                            getParticipantList
                                                          }
                                                          editRater={editRater}
                                                          cancelEdit={
                                                            cancelEdit
                                                          }
                                                          view={view}
                                                        />
                                                      </Grid>
                                                    </TableCell>
                                                  </TableRow>
                                                )}

                                              {isDeleteFlag &&
                                                zProjectUserRelationshipID ==
                                                  pid && (
                                                  <TableRow>
                                                    <TableCell
                                                      align="left"
                                                      colSpan={12}>
                                                      <Grid
                                                        className=""
                                                        container
                                                        direction="row">
                                                        <Deleterater
                                                          classes={classes}
                                                          raterList={raterList}
                                                          firstName={
                                                            zRespondantFirstName
                                                          }
                                                          lastName={
                                                            zRespondantLastName
                                                          }
                                                          email={
                                                            zRespondantEmail
                                                          }
                                                          relationshipName={
                                                            zRelationshipName
                                                          }
                                                          open={deleterater}
                                                          projectName={
                                                            props.projectName
                                                          }
                                                          projectDetails={
                                                            props.projectDetails
                                                          }
                                                          UserRelationshipID={
                                                            zProjectUserRelationshipID
                                                          }
                                                          userID={participId}
                                                          handleReset={(
                                                            value
                                                          ) => {
                                                            deleteraterDialog(
                                                              value
                                                            )
                                                            getParticipantList()
                                                            getParticipant()
                                                            getCountParticipant()
                                                          }}
                                                        />
                                                      </Grid>
                                                    </TableCell>
                                                  </TableRow>
                                                )}
                                            </>
                                          )
                                        })}

                                      {isAddFlag && (
                                        <TableRow>
                                          <TableCell align="left" colSpan={12}>
                                            <Addrater
                                              classes={classes}
                                              raterList={raterList}
                                              projectID={params.id}
                                              LoginUserID={getUserId()}
                                              projectDetails={
                                                props.projectDetails
                                              }
                                              getParticipantList={
                                                getParticipantList
                                              }
                                              handleReset={() => {
                                                // handleAddParticipant()
                                                getParticipantList()
                                                getParticipant()
                                                getCountParticipant()
                                                // getPartnerlist()
                                                //getPartnerInfo(partnerId)
                                              }}
                                              ParticipantID={zParticipantID}
                                              OrganizationID={
                                                props.OrganizationID
                                              }
                                              addRater={addRater}
                                              handleResetRater={(rosterid) => {
                                                resetRater(rosterid)
                                              }}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )}
                      </>
                    )
                  }
                )}
              </Grid>
              <Grid
                style={{
                  // maxHeight: '16rem',
                  overflow: 'auto',
                  marginTop: '5rem',
                }}>
                {approvedParticipantList?.map((element, key, index) => {
                  const { zParticipantID, zParticipantFullName } = element
                  let data1 = []
                  let isAddFlag = false
                  let isEditFlag = false
                  let isDeleteFlag = false
                  let participId
                  let data = rosterList[zParticipantID]

                  let l = toString(zParticipantID)
                  if (data) {
                    const { isAdd, isEdit, isDelete, zParticipantID } = data

                    isAddFlag = isAdd
                    isEditFlag = isEdit
                    isDeleteFlag = isDelete
                    participId = zParticipantID
                    data1 = rosterList[zParticipantID].data
                    console.log(data1, 'data1', participId)
                  }
                  var ccount = 0
                  var tcount = 0
                  var rname = ''
                  return (
                    <>
                      {view == true && (
                        <Accordion
                          key={`acc_${key}`}
                          expanded={
                            expandedList.includes(`list_${key}`) ? true : false
                          }
                          onChange={() => handleAccordion(`list_${key}`)}
                          className={classes.acc}
                          fullWidth>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: '#fff' }} />
                            }
                            aria-controls={`item_${key}-content`}
                            id={`item_${key}-header`}
                            className={classes.accHead}>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                              }}>
                              <Typography
                                className={classes.heading}
                                style={{
                                  fontSize: '16px',
                                  whiteSpace: 'nowrap',
                                }}>
                                {zParticipantFullName}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <div className={classes.container}>
                                {count.map((participant, i) => {
                                  const {
                                    zRelationshipName,
                                    zCompleteCount,
                                    zTotalCount,
                                  } = participant
                                  if (
                                    participant.zParticipantID == zParticipantID
                                  ) {
                                    listlen = listlen + 1
                                  }
                                  return participant.zParticipantID ==
                                    zParticipantID ? (
                                    // && listlen <= 4
                                    <>
                                      <Typography>
                                        {zRelationshipName}: {zCompleteCount} of{' '}
                                        {zTotalCount} Complete
                                      </Typography>
                                    </>
                                  ) : (
                                    <>
                                      <Typography></Typography>
                                    </>
                                  )
                                })}
                              </div>
                            </Grid>
                            <Grid className={classes.CheckBoxApprove}>
                              <FormControlLabel
                                disabled
                                control={
                                  <Checkbox
                                    //   checked={
                                    //     !!adminApprovedRoster[0]?.zAdminApproved
                                    //   }
                                    // onChange={(e) => handleApproved(e, index)}
                                    color="white"
                                    checked
                                    style={{ color: '#FFF' }}
                                  />
                                }
                                style={{ margin: '0px', color: '#FFF' }}
                              />
                              <span>
                                <p>Approved</p>
                              </span>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                              }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}>
                                <Button
                                  className={classes.toggleDescBtn}
                                  startIcon={
                                    <AddIcon
                                      className={classes.toggleDescIcon}
                                    />
                                  }
                                  onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault()
                                  }}
                                  style={{ fontSize: '14px' }}
                                  data-rosterid={zParticipantID}
                                  onClick={
                                    // e.stopPropagation()
                                    addRater
                                  }>
                                  Add Rater
                                </Button>
                              </div>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails className={classes.accDetail}>
                            <Grid item xs={12}>
                              <TableContainer
                                component={Paper}
                                className={classes.tablecontainer}>
                                <Table
                                  stickyHeader
                                  size="small"
                                  className={classes.customTable}
                                  id="roster-head">
                                  {/* Table Header */}
                                  <CustomTableHeader
                                    {...tableHeadProps1}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                  />
                                  <TableBody>
                                    {data1 &&
                                      stableSort(
                                        data1,
                                        getComparator(order, orderBy)
                                      ).map((val, key) => {
                                        console.log(val, 'valuelis')
                                        const {
                                          zStatusName,
                                          zRespondantFirstName,
                                          zRespondantLastName,
                                          zRespondantEmail,
                                          zRelationshipName,
                                          zProjectUserRelationshipID,
                                          zProgressText,
                                          zRespondentID,
                                          zParticipantID,
                                        } = val
                                        console.log()

                                        const data12 = []
                                        const array = []
                                        return (
                                          <>
                                            <TableRow data-id={key}>
                                              <TableCell align="left">
                                                {zRespondantLastName}
                                              </TableCell>
                                              <TableCell align="left">
                                                {zRespondantFirstName}
                                              </TableCell>
                                              <TableCell align="left">
                                                {zRespondantEmail}
                                              </TableCell>
                                              <TableCell align="left">
                                                {zRelationshipName}{' '}
                                              </TableCell>
                                              <TableCell align="left">
                                                {zStatusName}
                                                <br />
                                                {zProgressText}
                                              </TableCell>
                                              <TableCell align="center">
                                                {respondentSendGridAllDdetails.map(
                                                  //Approved condition
                                                  (data, index) => {
                                                    if (
                                                      data.zRespondentID ==
                                                        zRespondentID &&
                                                      data.zParticipantID ==
                                                        zParticipantID
                                                    ) {
                                                      if (
                                                        array.every(
                                                          (item) =>
                                                            item !=
                                                            data.zRespondentID
                                                        )
                                                      ) {
                                                        array.push(
                                                          data.zRespondentID
                                                        )
                                                      }
                                                      if (
                                                        array.every(
                                                          (item) =>
                                                            item ==
                                                            data.zRespondentID
                                                        )
                                                      ) {
                                                        data12.push(data)
                                                      }

                                                      // multiple email status condition
                                                      let responce = data12?.filter(
                                                        (item) => {
                                                          if (
                                                            item?.zEvent ==
                                                            'delivered'
                                                          ) {
                                                            return item?.zEvent
                                                          }
                                                          // if(data12.every(
                                                          //   (data) =>
                                                          //     data.zEvent == "processed" && data.zEvent != "delivered"
                                                          //     )){
                                                          //       return item?.zEvent
                                                          // }
                                                        }
                                                      )
                                                      return (
                                                        <>
                                                          {responce.length >
                                                          0 ? (
                                                            <Button
                                                              style={{
                                                                padding:
                                                                  '0px !important',
                                                              }}
                                                              onClick={() =>
                                                                informationShow(
                                                                  zRespondentID,
                                                                  zParticipantID
                                                                )
                                                              }>
                                                              <Grid
                                                                style={{
                                                                  display:
                                                                    'flex',
                                                                  justifyContent:
                                                                    'center',
                                                                  alignItems:
                                                                    'center',
                                                                }}>
                                                                <Box
                                                                  sx={{
                                                                    width:
                                                                      '10px',
                                                                    height:
                                                                      '10px',
                                                                    borderRadius:
                                                                      '50%',
                                                                    backgroundColor:
                                                                      data?.zEvent ===
                                                                      'dropped'
                                                                        ? 'red'
                                                                        : data?.zEvent ===
                                                                          'processed'
                                                                        ? 'yellow'
                                                                        : data?.zEvent ===
                                                                          'delivered'
                                                                        ? 'green'
                                                                        : data?.zEvent ===
                                                                          'bounce'
                                                                        ? 'blue'
                                                                        : data?.zEvent ===
                                                                          'deferred '
                                                                        ? 'blue'
                                                                        : 'blue',
                                                                    marginRight:
                                                                      '8px',
                                                                  }}></Box>
                                                                <span
                                                                  style={{
                                                                    textTransform:
                                                                      'capitalize',
                                                                  }}>
                                                                  {data?.zEvent ===
                                                                  'dropped'
                                                                    ? 'Not Delivered'
                                                                    : data?.zEvent}
                                                                </span>
                                                              </Grid>
                                                            </Button>
                                                          ) : (
                                                            <>
                                                              {responce.length ? (
                                                                <Button
                                                                  style={{
                                                                    padding:
                                                                      '0px !important',
                                                                  }}
                                                                  onClick={() =>
                                                                    informationShow(
                                                                      zRespondentID,
                                                                      zParticipantID
                                                                    )
                                                                  }>
                                                                  <Grid
                                                                    style={{
                                                                      display:
                                                                        'flex',
                                                                      justifyContent:
                                                                        'center',
                                                                      alignItems:
                                                                        'center',
                                                                    }}>
                                                                    <Box
                                                                      sx={{
                                                                        width:
                                                                          '10px',
                                                                        height:
                                                                          '10px',
                                                                        borderRadius:
                                                                          '50%',
                                                                        backgroundColor:
                                                                          data?.zEvent ===
                                                                          'dropped'
                                                                            ? 'red'
                                                                            : data?.zEvent ===
                                                                              'processed'
                                                                            ? 'yellow'
                                                                            : data?.zEvent ===
                                                                              'delivered'
                                                                            ? 'green'
                                                                            : data?.zEvent ===
                                                                              'bounce'
                                                                            ? 'blue'
                                                                            : data?.zEvent ===
                                                                              'deferred '
                                                                            ? 'blue'
                                                                            : 'blue',
                                                                        marginRight:
                                                                          '8px',
                                                                      }}></Box>
                                                                    <span
                                                                      style={{
                                                                        textTransform:
                                                                          'capitalize',
                                                                      }}>
                                                                      {
                                                                        responce[0]
                                                                          ?.zEvent
                                                                      }
                                                                    </span>
                                                                  </Grid>
                                                                </Button>
                                                              ) : (
                                                                ''
                                                              )}
                                                            </>
                                                          )}
                                                        </>
                                                      )
                                                    }
                                                  }
                                                )}
                                              </TableCell>

                                              <TableCell align="left">
                                                {props.projectStatus ==
                                                'Completed' ? (
                                                  ''
                                                ) : (
                                                  <>
                                                    <Button
                                                      size="sm"
                                                      color="default"
                                                      style={{
                                                        textTransform: 'none',
                                                        padding: '2px',
                                                      }}
                                                      data-title="Send Original"
                                                      data-name={
                                                        zRespondantFirstName
                                                      }
                                                      data-lname={
                                                        zRespondantLastName
                                                      }
                                                      data-mail={
                                                        zRespondantEmail
                                                      }
                                                      data-resid={zRespondentID}
                                                      data-userid={
                                                        zParticipantID
                                                      }
                                                      onClick={
                                                        // e.stopPropagation()
                                                        sendOriginal
                                                      }>
                                                      <span>Send Original</span>
                                                    </Button>
                                                    <Divider variant="fullWidth" />
                                                    <Button
                                                      size="sm"
                                                      color="default"
                                                      style={{
                                                        textTransform: 'none',
                                                        padding: '2px',
                                                      }}
                                                      data-title="Send Remainder"
                                                      data-rosterid={
                                                        zRespondentID
                                                      }
                                                      onClick={reminderFun}>
                                                      <span>Send Reminder</span>
                                                    </Button>
                                                  </>
                                                )}
                                              </TableCell>
                                              <TableCell align="left">
                                                <IconButton
                                                  aria-label="edit"
                                                  size="small">
                                                  <EditIcon
                                                    onKeyPress={(e) => {
                                                      e.key === 'Enter' &&
                                                        e.preventDefault()
                                                    }}
                                                    data-rosterid={participId}
                                                    data-rid={
                                                      zProjectUserRelationshipID
                                                    }
                                                    onClick={
                                                      // e.stopPropagation()
                                                      editRater
                                                    }
                                                    fontSize="inherit"
                                                  />
                                                </IconButton>

                                                <IconButton
                                                  aria-label="delete"
                                                  size="small"
                                                  style={{
                                                    marginLeft: '1rem',
                                                  }}>
                                                  <DeleteIcon
                                                    onKeyPress={(e) => {
                                                      e.key === 'Enter' &&
                                                        e.preventDefault()
                                                    }}
                                                    data-rosterid={
                                                      zParticipantID
                                                    }
                                                    data-rid={
                                                      zProjectUserRelationshipID
                                                    }
                                                    onClick={
                                                      // e.stopPropagation()
                                                      deleteRater
                                                    }
                                                    fontSize="inherit"
                                                  />
                                                </IconButton>
                                              </TableCell>
                                            </TableRow>
                                            {/* {id && ( */}

                                            {isEditFlag &&
                                              zProjectUserRelationshipID ==
                                                pid && (
                                                <TableRow>
                                                  <TableCell
                                                    align="left"
                                                    colSpan={12}>
                                                    <Grid
                                                      className=""
                                                      container
                                                      direction="row">
                                                      <Editrater
                                                        classes={classes}
                                                        raterList={raterList}
                                                        firstName={
                                                          zRespondantFirstName
                                                        }
                                                        lastName={
                                                          zRespondantLastName
                                                        }
                                                        email={zRespondantEmail}
                                                        relatonshipName={
                                                          zRelationshipName
                                                        }
                                                        relationshipName={
                                                          zRelationshipName
                                                        }
                                                        relationshipID={
                                                          zProjectUserRelationshipID
                                                        }
                                                        userID={participId}
                                                        RespondantID={
                                                          zRespondentID
                                                        }
                                                        projectDetails={
                                                          props.projectDetails
                                                        }
                                                        projectID={params.id}
                                                        LoginUserID={getUserId()}
                                                        getParticipantList={
                                                          getParticipantList
                                                        }
                                                        editRater={editRater}
                                                        cancelEdit={cancelEdit}
                                                      />
                                                    </Grid>
                                                  </TableCell>
                                                </TableRow>
                                              )}

                                            {isDeleteFlag &&
                                              zProjectUserRelationshipID ==
                                                pid && (
                                                <TableRow>
                                                  <TableCell
                                                    align="left"
                                                    colSpan={12}>
                                                    <Grid
                                                      className=""
                                                      container
                                                      direction="row">
                                                      <Deleterater
                                                        classes={classes}
                                                        raterList={raterList}
                                                        firstName={
                                                          zRespondantFirstName
                                                        }
                                                        lastName={
                                                          zRespondantLastName
                                                        }
                                                        email={zRespondantEmail}
                                                        relationshipName={
                                                          zRelationshipName
                                                        }
                                                        open={deleterater}
                                                        projectName={
                                                          props.projectName
                                                        }
                                                        projectDetails={
                                                          props.projectDetails
                                                        }
                                                        UserRelationshipID={
                                                          zProjectUserRelationshipID
                                                        }
                                                        userID={participId}
                                                        handleReset={(
                                                          value
                                                        ) => {
                                                          deleteraterDialog(
                                                            value
                                                          )
                                                          getParticipantList()
                                                          getParticipant()
                                                          getCountParticipant()
                                                        }}
                                                      />
                                                    </Grid>
                                                  </TableCell>
                                                </TableRow>
                                              )}
                                          </>
                                        )
                                      })}

                                    {isAddFlag && (
                                      <TableRow>
                                        <TableCell align="left" colSpan={12}>
                                          <Addrater
                                            classes={classes}
                                            raterList={raterList}
                                            projectID={params.id}
                                            LoginUserID={getUserId()}
                                            projectDetails={
                                              props.projectDetails
                                            }
                                            getParticipantList={
                                              getParticipantList
                                            }
                                            handleReset={() => {
                                              // handleAddParticipant()
                                              getParticipantList()
                                              getParticipant()
                                              getCountParticipant()
                                              // getPartnerlist()
                                              //getPartnerInfo(partnerId)
                                            }}
                                            ParticipantID={zParticipantID}
                                            OrganizationID={
                                              props.OrganizationID
                                            }
                                            addRater={addRater}
                                            handleResetRater={(rosterid) => {
                                              resetRater(rosterid)
                                            }}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </>
                  )
                })}
              </Grid>
            </Grid>
          ) : (
            Object.values(participantListNew).map((element, key, index) => {
              // const { firstName, lastName, list, rater } = element
              // console.log("element", element)
              // console.log("key", key)
              // const { zParticipantFullName, zParticipantEmail } = rosterList[
              //   element
              // ].data[0]
              console.log(element, 'elemets')
              console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjelemets')
              const {
                // isAdd,
                // data = [],
                zParticipantID,
                // isEdit,
                // isDelete,
                zUserFullName,
                zUserID,
              } = element
              let data1 = []
              let isAddFlag = false
              let isEditFlag = false
              let isDeleteFlag = false
              let participId
              let data = rosterList[zUserID]

              let l = toString(zUserID)
              if (data) {
                const { isAdd, isEdit, isDelete, zParticipantID } = data

                isAddFlag = isAdd
                isEditFlag = isEdit
                isDeleteFlag = isDelete
                participId = zParticipantID
                data1 = rosterList[zUserID].data
              }
              console.log(data1, 'dat1aaaaadat1aaaaa')
              console.log(data, 'datadatadatadatadata')
              // const {
              //   zParticipantFullName,
              //   zCompletedItemCnt,
              //   zTotalItemCnt,
              //   zParticipantEmail,
              //   zStatusName,
              // } = data[0] || {}
              var ccount = 0
              var tcount = 0
              var rname = ''
              return (
                <>
                  {view == true && (
                    <Accordion
                      key={`acc_${key}`}
                      expanded={
                        expandedList.includes(`list_${key}`) ? true : false
                      }
                      onChange={() => handleAccordion(`list_${key}`)}
                      className={classes.acc}
                      fullWidth>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: '#fff' }} />
                        }
                        aria-controls={`item_${key}-content`}
                        id={`item_${key}-header`}
                        className={classes.accHead}>
                        <Grid item xs={3}>
                          <Typography className={classes.heading}>
                            {zUserFullName}
                          </Typography>
                        </Grid>
                        {/* {count.map((participant, i) => {
                  const {
                    zRelationshipName,
                    zCompleteCount,
                    zTotalCount,
                  } = participant
                  return participant.zParticipantID == zUserID && i <= 4 ? (
                    <Grid item xs={3}>
                      <Typography>hi</Typography>
                    </Grid>
                  ) : participant.zParticipantID == zUserID && ll >= 5 ? (
                    <Grid item xs={3}>
                      <Typography>hello</Typography>
                    </Grid>
                  ) : (
                    ''
                  )
                })} */}
                        <Grid item xs={6}>
                          <div className={classes.container}>
                            {count.map((participant, i) => {
                              const {
                                zRelationshipName,
                                zCompleteCount,
                                zTotalCount,
                              } = participant
                              if (participant.zParticipantID == zUserID) {
                                listlen = listlen + 1
                              }
                              return participant.zParticipantID == zUserID ? (
                                // && listlen <= 4
                                <>
                                  <Typography>
                                    {zRelationshipName}: {zCompleteCount} of{' '}
                                    {zTotalCount} Complete
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography></Typography>
                                </>
                              )
                            })}
                          </div>
                        </Grid>
                        {/* {listlen >= 5 ? (
                  <Grid item xs={3}>
                    {count.map((participant, i) => {
                      const {
                        zRelationshipName,
                        zCompleteCount,
                        zTotalCount,
                      } = participant
    
                      return participant.zParticipantID == zUserID ? (
                        <Typography>hi</Typography>
                      ) : (
                        ''
                      )
                    })}
                  </Grid>
                ) : (
                  ''
                )} */}

                        <Grid item xs={3}>
                          <div
                            style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                              className={classes.toggleDescBtn}
                              startIcon={
                                <AddIcon className={classes.toggleDescIcon} />
                              }
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              data-rosterid={zUserID}
                              onClick={
                                // e.stopPropagation()
                                addRater
                              }>
                              Add Rater
                            </Button>
                          </div>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accDetail}>
                        <Grid item xs={12}>
                          <TableContainer
                            component={Paper}
                            className={classes.tablecontainer}>
                            <Table
                              stickyHeader
                              size="small"
                              className={classes.customTable}
                              id="roster-head">
                              {/* Table Header */}
                              <CustomTableHeader
                                {...tableHeadProps1}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                              />
                              {/* Table Header */}

                              {/* Table Body */}
                              <TableBody>
                                {data1 &&
                                  stableSort(
                                    data1,
                                    getComparator(order, orderBy)
                                  ).map((val, key) => {
                                    const {
                                      zStatusName,
                                      zRespondantFirstName,
                                      zRespondantLastName,
                                      zRespondantEmail,
                                      zRelationshipName,
                                      zProjectUserRelationshipID,
                                      zProgressText,
                                      zRespondentID,
                                      zParticipantID,
                                    } = val
                                    console.log('relval', val)
                                    const data12 = []
                                    const array = []
                                    return (
                                      <>
                                        <TableRow data-id={key}>
                                          <TableCell align="left">
                                            {zRespondantLastName}
                                          </TableCell>
                                          <TableCell align="left">
                                            {zRespondantFirstName}
                                          </TableCell>
                                          <TableCell align="left">
                                            {zRespondantEmail}
                                          </TableCell>
                                          <TableCell align="left">
                                            {zRelationshipName}{' '}
                                          </TableCell>
                                          <TableCell align="left">
                                            {zStatusName}
                                            <br />
                                            {zProgressText}
                                          </TableCell>
                                          <TableCell align="center">
                                            {respondentSendGridAllDdetails.map(
                                              //upload inprogress
                                              (data, index) => {
                                                if (
                                                  data.zRespondentID ==
                                                    zRespondentID &&
                                                  data.zParticipantID ==
                                                    zParticipantID
                                                ) {
                                                  if (
                                                    array.every(
                                                      (item) =>
                                                        item !=
                                                        data.zRespondentID
                                                    )
                                                  ) {
                                                    array.push(
                                                      data.zRespondentID
                                                    )
                                                  }
                                                  if (
                                                    array.every(
                                                      (item) =>
                                                        item ==
                                                        data.zRespondentID
                                                    )
                                                  ) {
                                                    data12.push(data)
                                                  }

                                                  // multiple email status condition
                                                  let responce = data12?.filter(
                                                    (item) => {
                                                      if (
                                                        item?.zEvent ==
                                                        'delivered'
                                                      ) {
                                                        return item?.zEvent
                                                      }
                                                      // if(data12.every(
                                                      //   (data) =>
                                                      //     data.zEvent == "processed" && data.zEvent != "delivered"
                                                      //     )){
                                                      //       return item?.zEvent
                                                      // }
                                                    }
                                                  )
                                                  return (
                                                    <>
                                                      {responce.length > 0 ? (
                                                        <Button
                                                          style={{
                                                            padding:
                                                              '0px !important',
                                                          }}
                                                          onClick={() =>
                                                            informationShow(
                                                              zRespondentID,
                                                              zParticipantID
                                                            )
                                                          }>
                                                          <Grid
                                                            style={{
                                                              display: 'flex',
                                                              justifyContent:
                                                                'center',
                                                              alignItems:
                                                                'center',
                                                            }}>
                                                            <Box
                                                              sx={{
                                                                width: '10px',
                                                                height: '10px',
                                                                borderRadius:
                                                                  '50%',
                                                                backgroundColor:
                                                                  data?.zEvent ===
                                                                  'dropped'
                                                                    ? 'red'
                                                                    : data?.zEvent ===
                                                                      'processed'
                                                                    ? 'yellow'
                                                                    : data?.zEvent ===
                                                                      'delivered'
                                                                    ? 'green'
                                                                    : data?.zEvent ===
                                                                      'bounce'
                                                                    ? 'blue'
                                                                    : data?.zEvent ===
                                                                      'deferred '
                                                                    ? 'blue'
                                                                    : 'blue',
                                                                marginRight:
                                                                  '8px',
                                                              }}></Box>
                                                            <span
                                                              style={{
                                                                textTransform:
                                                                  'capitalize',
                                                              }}>
                                                              {data?.zEvent ===
                                                              'dropped'
                                                                ? 'Not Delivered'
                                                                : data?.zEvent}
                                                            </span>
                                                          </Grid>
                                                        </Button>
                                                      ) : (
                                                        <>
                                                          {responce.length ? (
                                                            <Button
                                                              style={{
                                                                padding:
                                                                  '0px !important',
                                                              }}
                                                              onClick={() =>
                                                                informationShow(
                                                                  zRespondentID,
                                                                  zParticipantID
                                                                )
                                                              }>
                                                              <Grid
                                                                style={{
                                                                  display:
                                                                    'flex',
                                                                  justifyContent:
                                                                    'center',
                                                                  alignItems:
                                                                    'center',
                                                                }}>
                                                                <Box
                                                                  sx={{
                                                                    width:
                                                                      '10px',
                                                                    height:
                                                                      '10px',
                                                                    borderRadius:
                                                                      '50%',
                                                                    backgroundColor:
                                                                      data?.zEvent ===
                                                                      'dropped'
                                                                        ? 'red'
                                                                        : data?.zEvent ===
                                                                          'processed'
                                                                        ? 'yellow'
                                                                        : data?.zEvent ===
                                                                          'delivered'
                                                                        ? 'green'
                                                                        : data?.zEvent ===
                                                                          'bounce'
                                                                        ? 'blue'
                                                                        : data?.zEvent ===
                                                                          'deferred '
                                                                        ? 'blue'
                                                                        : 'blue',
                                                                    marginRight:
                                                                      '8px',
                                                                  }}></Box>
                                                                <span
                                                                  style={{
                                                                    textTransform:
                                                                      'capitalize',
                                                                  }}>
                                                                  {
                                                                    responce[0]
                                                                      ?.zEvent
                                                                  }
                                                                </span>
                                                              </Grid>
                                                            </Button>
                                                          ) : (
                                                            ''
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )
                                                }
                                              }
                                            )}
                                          </TableCell>
                                          <TableCell align="center">
                                            {props.projectStatus ==
                                            'Completed' ? (
                                              ''
                                            ) : (
                                              <>
                                                <Button
                                                  size="sm"
                                                  color="default"
                                                  style={{
                                                    textTransform: 'none',
                                                    padding: '2px',
                                                  }}
                                                  data-title="Send Original"
                                                  data-name={
                                                    zRespondantFirstName
                                                  }
                                                  data-lname={
                                                    zRespondantLastName
                                                  }
                                                  data-mail={zRespondantEmail}
                                                  data-resid={zRespondentID}
                                                  data-userid={zUserID}
                                                  onClick={
                                                    // e.stopPropagation()
                                                    sendOriginal
                                                  }>
                                                  <span>Send Original</span>
                                                </Button>
                                                <Divider variant="fullWidth" />
                                                <Button
                                                  size="sm"
                                                  color="default"
                                                  style={{
                                                    textTransform: 'none',
                                                    padding: '2px',
                                                    whiteSpace: 'nowrap',
                                                  }}
                                                  data-title="Send Remainder"
                                                  data-rosterid={zRespondentID}
                                                  onClick={reminderFun}>
                                                  <span>Send Reminder</span>
                                                </Button>
                                              </>
                                            )}
                                          </TableCell>
                                          <TableCell align="left">
                                            <Grid style={{ display: 'flex' }}>
                                              <Grid>
                                                <IconButton
                                                  aria-label="edit"
                                                  size="small">
                                                  <EditIcon
                                                    onKeyPress={(e) => {
                                                      e.key === 'Enter' &&
                                                        e.preventDefault()
                                                    }}
                                                    data-rosterid={participId}
                                                    data-rid={
                                                      zProjectUserRelationshipID
                                                    }
                                                    onClick={
                                                      // e.stopPropagation()
                                                      editRater
                                                    }
                                                    fontSize="inherit"
                                                  />
                                                </IconButton>
                                              </Grid>
                                              <Grid>
                                                <IconButton
                                                  aria-label="delete"
                                                  size="small"
                                                  style={{
                                                    marginLeft: '1rem',
                                                  }}>
                                                  <DeleteIcon
                                                    onKeyPress={(e) => {
                                                      e.key === 'Enter' &&
                                                        e.preventDefault()
                                                    }}
                                                    data-rosterid={zUserID}
                                                    data-rid={
                                                      zProjectUserRelationshipID
                                                    }
                                                    onClick={
                                                      // e.stopPropagation()
                                                      deleteRater
                                                    }
                                                    fontSize="inherit"
                                                  />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        </TableRow>
                                        {/* {id && ( */}

                                        {isEditFlag &&
                                          zProjectUserRelationshipID == pid && (
                                            <TableRow>
                                              <TableCell
                                                align="left"
                                                colSpan={12}>
                                                <Grid
                                                  className=""
                                                  container
                                                  direction="row">
                                                  <Editrater
                                                    classes={classes}
                                                    raterList={raterList}
                                                    firstName={
                                                      zRespondantFirstName
                                                    }
                                                    lastName={
                                                      zRespondantLastName
                                                    }
                                                    email={zRespondantEmail}
                                                    relatonshipName={
                                                      zRelationshipName
                                                    }
                                                    relationshipName={
                                                      zRelationshipName
                                                    }
                                                    relationshipID={
                                                      zProjectUserRelationshipID
                                                    }
                                                    userID={participId}
                                                    RespondantID={zRespondentID}
                                                    projectDetails={
                                                      props.projectDetails
                                                    }
                                                    projectID={params.id}
                                                    LoginUserID={getUserId()}
                                                    getParticipantList={
                                                      getParticipantList
                                                    }
                                                    editRater={editRater}
                                                    cancelEdit={cancelEdit}
                                                  />
                                                </Grid>
                                              </TableCell>
                                            </TableRow>
                                          )}

                                        {isDeleteFlag &&
                                          zProjectUserRelationshipID == pid && (
                                            <TableRow>
                                              <TableCell
                                                align="left"
                                                colSpan={12}>
                                                <Grid
                                                  className=""
                                                  container
                                                  direction="row">
                                                  <Deleterater
                                                    classes={classes}
                                                    raterList={raterList}
                                                    firstName={
                                                      zRespondantFirstName
                                                    }
                                                    lastName={
                                                      zRespondantLastName
                                                    }
                                                    email={zRespondantEmail}
                                                    relationshipName={
                                                      zRelationshipName
                                                    }
                                                    open={deleterater}
                                                    projectName={
                                                      props.projectName
                                                    }
                                                    projectDetails={
                                                      props.projectDetails
                                                    }
                                                    UserRelationshipID={
                                                      zProjectUserRelationshipID
                                                    }
                                                    userID={participId}
                                                    handleReset={(value) => {
                                                      deleteraterDialog(value)
                                                      getParticipantList()
                                                      getParticipant()
                                                      getCountParticipant()
                                                    }}
                                                  />
                                                </Grid>
                                              </TableCell>
                                            </TableRow>
                                          )}

                                        {/* <Addcomponent
                                  values={values}
                                  errors={errors}
                                  submit={handleSubmit}
                                /> */}

                                        {/* )} */}
                                      </>
                                    )
                                  })}

                                {isAddFlag && (
                                  <TableRow>
                                    <TableCell align="left" colSpan={12}>
                                      <Addrater
                                        classes={classes}
                                        raterList={raterList}
                                        projectID={params.id}
                                        LoginUserID={getUserId()}
                                        projectDetails={props.projectDetails}
                                        getParticipantList={getParticipantList}
                                        handleReset={() => {
                                          // handleAddParticipant()
                                          getParticipantList()
                                          getParticipant()
                                          getCountParticipant()
                                          // getPartnerlist()
                                          //getPartnerInfo(partnerId)
                                        }}
                                        ParticipantID={zUserID}
                                        OrganizationID={props.OrganizationID}
                                        addRater={addRater}
                                        handleResetRater={(rosterid) => {
                                          resetRater(rosterid)
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              )
            })
          )}

          {view == false && (
            <ListParticipant
              open={addFlag}
              raterList={raterList}
              participantList={participantListNew}
              respondantList={respondantList}
              getRespondant={getRespondant}
              respondentSendGridAllDdetails={respondentSendGridAllDdetails}
              informationShow={informationShow}
              projectDetails={props.projectDetails}
              getParticipantList={getParticipantList}
              getParticipant={getParticipant}
              getCountParticipant={getCountParticipant}
            />
          )}

          {openInformation && (
            <EmailInformation
              open={openInformation}
              handleReset={() => {
                closeInformation()
              }}
              emailStatusInfomation={emailStatusInfomation}
            />
          )}
          {addFlag && (
            <AddParticipant
              open={addFlag}
              raterList={raterList}
              projectDetails={props.projectDetails}
              LoginUserID={getUserId()}
              projectID={params.id}
              OrganizationID={props.OrganizationID}
              setFlag={setFlag}
              // getParticipantList={getParticipantList}
              handleReset={handleResetParticipant}
            />

            // <Grid container spacing={3} direction="row">
            //   {/* <div> */}
            //   <Grid item xs={12}>
            //     {/* {accor.map((element, key) => ( */}
            //     <Accordion
            //       key={`accor_${'key'}`}
            //       expanded={collapse}
            //       className={classes.acc}
            //       fullWidth>
            //       <AccordionSummary
            //         expandIcon={
            //           <ExpandMoreIcon
            //             onClick={collapseToggle}
            //             style={{ color: '#fff' }}
            //           />
            //         }
            //         aria-controls={`item_${'key'}-content`}
            //         id={`item_${'key'}-header`}
            //         className={classes.accHead}>
            //         <Grid item xs={10}>
            //           <Typography className={classes.heading}>
            //             First Name Last Name
            //           </Typography>
            //         </Grid>
            //         <Grid item xs={2}></Grid>
            //       </AccordionSummary>
            //       <Addcomponent
            //         values={values}
            //         errors={errors}
            //         submit={handleSubmit}
            //       />
            //     </Accordion>
            //     {/* ))} */}
            //   </Grid>
            //   {/* </div> */}
            // </Grid>
          )}

          {remindFlag && (
            <ReminderEmail
              open={remindFlag}
              projectDetails={props.projectDetails}
              handleReset={(value) => resetFun(value)}
              rosterid={roster}
            />
          )}

          {addRosterFlag && (
            <AddRoster
              open={addRosterFlag}
              raterList={raterList}
              respondantList={respondantList}
              participantListNew={participantListNew}
              projectID={params.id}
              LoginUserID={getUserId()}
              handleReset={() => {
                handleAddRoster()
                getParticipantList()
                // getPartnerlist()
                //getPartnerInfo(partnerId)
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
