import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import {
  Box,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CustomButton from '../common/CustomButton'
import CloseIcon from '@material-ui/icons/Close'
import { useParams } from 'react-router-dom'
import { authHeader, getDomain, getUserId } from '../common/CustomFunction'
import { API } from '../../helpers/API'
import { UPDATE_TEAM_RESPOTANT_USER } from '../../helpers/constants/ApiRoutes'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
    '& ..MuiDialog-paperScrollPaper': {
      maxHeight: '400px',
    },
    '& .MuiDialogContent-dividers': {
      borderBottom: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  editsavebtn: {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

function Tsupdateresponant(props) {
  const classes = useStyles()
  const { handleReset, open, formdata, responsuccessCall } = props
  const { tsprojectid } = useParams()

  const validationSchema = Yup.object({
    FirstName: Yup.string()
      .trim()
      .required('First name is required'),
    LastName: Yup.string().required('Last name is required'),
    Email: Yup.string()
      .trim()
      .required('Email is required'),
  })
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      ...formdata,
      ProjectID: tsprojectid,
      Domain: getDomain(),
      BaseUrl: window.location.origin,
      LUID: getUserId(),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => updateuser(values),
  })

  const updateuser = async (values) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: UPDATE_TEAM_RESPOTANT_USER,
      params: {
        ...values,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        responsuccessCall()
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Edit Team Member
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={5}>
                <TextField
                  id="name-helper-text"
                  label="First Name"
                  value={values.FirstName}
                  onChange={handleChange}
                  fullWidth
                  name="FirstName"
                  error={errors.FirstName && touched.FirstName}
                  helperText={
                    errors.FirstName && touched.FirstName
                      ? errors.FirstName
                      : ''
                  }
                />
              </Grid>

              {/* <Grid item xs={2}></Grid> */}

              <Grid item xs={5}>
                <TextField
                  id="name-helper-text"
                  label="Last Name"
                  value={values.LastName}
                  onChange={handleChange}
                  fullWidth
                  name="LastName"
                  error={errors.LastName && touched.LastName}
                  helperText={
                    errors.LastName && touched.LastName ? errors.LastName : ''
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="name-helper-text"
                  label="Email"
                  value={values.Email}
                  onChange={handleChange}
                  fullWidth
                  type={'email'}
                  name="Email"
                  error={errors.Email && touched.Email}
                  helperText={errors.Email && touched.Email ? errors.Email : ''}
                />
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <CustomButton
                    className={classes.editsavebtn}
                    colour="primaryButton"
                    type="submit"
                    onClick={handleSubmit}>
                    Save
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}

export default Tsupdateresponant
