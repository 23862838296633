import * as types from '../action/ActionType'
import produce from 'immer'
export default function NewthinkwiseReducer(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.NEW_THINKWISE_ITEM:
      return (state = payload)
    // case types.NEW_SELECTED_THINKWISE_ITEM:
    //   return (state = payload)

    default:
      return state
  }
}
