import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Autocomplete, FormLabel } from '@mui/material'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'

import { useFormik } from 'formik'
import { API } from '../../helpers/API'
import DateFnsUtils from '@date-io/date-fns'

import { useHistory } from 'react-router'

import { useSnackbar } from 'notistack'
import {
  authHeader,
  getFirstName,
  getLastName,
  getOrganizationId,
  getUserId,
} from '../common/CustomFunction'
import {
  ADMIN_LIST,
  GET_PARTICIPANT_LEVEL_URL,
  GET_SURVEY_ADMINSTRATOR_URL,
  SAVE_TEAMSURVEY,
  TEAM_GET_TIMELINE,
  TEAM_SAVETIMELINE,
  TEAM_UPDATE_TIMELINE,
  UPDATE_TEAMSURVEY,
} from '../../helpers/constants/ApiRoutes'
import {
  TEAMSURVEY_CURRENTSTEP,
  TEAMSURVEY_TEMP_CURRENTSTEP,
} from '../../redux/action/ActionType'
import { BASICINFORMATION } from '../../helpers/constants/RoutePaths'
import { CustomMessage } from '../../function/HelperFunction'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontWeight: '600',
  },
  formControl: {
    width: '100%',
  },
  labelName: {
    fontFamily: 'Nunito-Regular !important',
  },
  menuBlock: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',

    listStyleType: 'none',
    margin: 0,
    padding: 0,
    marginTop: '10px',
  },
  menuItem: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '130px',
    border: '2px solid #b9b6b6',
    padding: '6px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}))

//Schema
const validationSchema = Yup.object({
  Name: Yup.string()
    .trim()
    .required('Project Name is required'),
  // DueDate: Yup.string()
  //   .trim()
  //   .required('Due Date is required'),
  // OwnerID: Yup.string()
  //   .trim()
  //   .required('Survey administrator is required'),
})

export default function Tsinformation(props) {
  const classes = useStyles()

  const [participantLevelList, setParticipantLevel] = useState()
  const { ProjectInfo, getProjectInformation } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const tempTAbchange = useSelector((state) => state.Teamtempcurrentstepreducer)

  const { tsprojectid } = useParams()
  const [projectType, setprojectType] = useState({ id: 0, name: 'Monthly' })
  const { enqueueSnackbar } = useSnackbar()
  const [surveyAdminlist, setsurveyAdminlist] = useState([])

  const currentStep = useSelector((state) => state.Teamsurveycurrentstepreducer)
  console.log('project info', ProjectInfo)
  // const currentProjectInfo = useSelector(state => state.CurrentProjectInfo)
  const handleChangeFun = (e) => {
    console.log('date', e)
    setselectedDate(e)
    // handleChange(e)
  }
  //Sign Up
  const saveFormValues = async (formValues) => {
    console.log('form', formValues, selectedDate)
    if (selectedDate == null) {
      CustomMessage('Please Select Due Date', 'error', enqueueSnackbar)
    } else {
      const postConfig = {
        method: 'POST',
        headers: authHeader(),

        url: tsprojectid ? UPDATE_TEAMSURVEY : SAVE_TEAMSURVEY,
        params: {
          ...(tsprojectid && {
            ProjectID: tsprojectid,
          }),
          ...formValues,
          DueDate: moment(selectedDate).format('YYYY-MM-DD'),
          OrganizerID: getUserId(),
          LUID: getUserId(),
        },
      }
      try {
        const response = await API(postConfig)
        if (response && response.data) {
          if (!tsprojectid) {
            // const addProjectconfig = {
            //   method: 'POST',
            //   url: TEAM_SAVETIMELINE,
            //   params: {
            //     ProjectID: response.data.ProjectId,
            //     UserID: getUserId(),
            //     TimeLineType: projectType.name,
            //   },
            // }
            // const projectCOnfig = await API(addProjectconfig)

            history.push(BASICINFORMATION + '/' + response.data.ProjectId)
            console.log(currentStep, tempTAbchange, 'tempTAbchange2')
            if (currentStep == tempTAbchange) {
              console.log(currentStep, tempTAbchange, 'tempTAbchangeif')

              dispatch({
                type: TEAMSURVEY_CURRENTSTEP,
                payload: tempTAbchange + 1,
              })
              dispatch({
                type: TEAMSURVEY_TEMP_CURRENTSTEP,
                payload: tempTAbchange + 1,
              })
              console.log(currentStep, tempTAbchange, 'tempTAbchangeif3')
            } else {
              console.log(currentStep, tempTAbchange, 'tempTAbchangeelse')

              dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: tempTAbchange })
              dispatch({
                type: TEAMSURVEY_TEMP_CURRENTSTEP,
                payload: tempTAbchange,
              })
            }
          } else {
            // const updateProjectconfig = {
            //   method: 'POST',
            //   headers: authHeader(),
            //   url: TEAM_UPDATE_TIMELINE,
            //   params: {
            //     ProjectID: tsprojectid,
            //     UserID: getUserId(),
            //     TimeLineType: projectType.name,
            //     TimeLineTypeID: projectType.id,
            //     Active: true,
            //   },
            // }
            // const projectCOnfig = await API(updateProjectconfig)
            if (currentStep == tempTAbchange) {
              dispatch({
                type: TEAMSURVEY_CURRENTSTEP,
                payload: tempTAbchange + 1,
              })
              dispatch({
                type: TEAMSURVEY_TEMP_CURRENTSTEP,
                payload: tempTAbchange + 1,
              })
            } else {
              console.log('else part', tempTAbchange)
              dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: tempTAbchange })
              dispatch({
                type: TEAMSURVEY_TEMP_CURRENTSTEP,
                payload: tempTAbchange,
              })
            }
          }
          console.log(response.data.ProjectId)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  const projectHandlechange = async (e) => {
    setprojectType((v) => ({ ...v, name: e.target.value }))
    // setprojectType()
  }

  const changeDateField = (date) => {}
  async function getPartipantLevel() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PARTICIPANT_LEVEL_URL,
    }
    try {
      const response = await API(getconfig)
      if (response && response.data) {
        setParticipantLevel(response.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getSurveyAdministrator() {
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADMIN_LIST,
      data: {
        OrganizationID: getOrganizationId(),
        ProjectTypeID: 7,
      },
    }
    try {
      const response = await API(getconfig)
      if (response.status === 200) {
        setsurveyAdminlist(response.data.UserList)
      }
    } catch (e) {
      console.log(e)
    }
  }

  //Formik
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: {
      ...ProjectInfo,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => saveFormValues(values),
  })
  const [selectedDate, setselectedDate] = useState(values.DueDate)

  console.log(errors)

  let dueDateValue = null

  const projectSubmit = () => {
    console.log('project')
  }
  useEffect(() => {
    setselectedDate(values.DueDate)
  }, [values.DueDate])
  //  console.log(scaleRatings)
  const getTimeline = async () => {
    const addProjectconfig = {
      method: 'GET',
      headers: authHeader(),
      url: TEAM_GET_TIMELINE,
      params: {
        ProjectID: tsprojectid,
      },
    }
    const projectCOnfig = await API(addProjectconfig)
    const { data } = projectCOnfig.data

    const { zTImeLineType, zTimeLineTypeID } = data[0] || {}
    setprojectType({ id: zTimeLineTypeID, name: zTImeLineType })
  }

  useEffect(() => {
    if (tsprojectid) getTimeline()
  }, [tsprojectid])
  console.log('values', values, errors)
  useEffect(() => {
    getPartipantLevel()
    getSurveyAdministrator()
    getProjectInformation()
  }, [])

  const adminHandle = (e) => {
    console.log(e.target.value)
  }
  console.log(values)

  return (
    <div>
      <Paper className={classes.paper}>
        {/* Table tools */}
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div">
            Project Information
          </Typography>
        </Toolbar>
        <Divider />
        <form
          noValidate
          autoComplete="off"
          name="form_1"
          className="form1"
          id="form_1"
          onSubmit={handleSubmit}>
          <Grid
            container
            spacing={5}
            direction="row"
            style={{ marginTop: '2px', marginLeft: '30px' }}>
            <Grid item xs={5}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="Name"
                    label="Project Name:"
                    value={values.Name}
                    className={classes.labelName}
                    onChange={handleChange}
                    fullWidth
                    name="Name"
                    error={errors.Name && touched.Name}
                    helperText={errors.Name && touched.Name ? errors.Name : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="Description"
                    label="Survey Description:"
                    value={values.Description}
                    onChange={handleChange}
                    className={classes.labelName}
                    fullWidth
                    name="Description"
                    error={errors.Description && touched.Description}
                    helperText={
                      errors.Description && touched.Description
                        ? errors.Description
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Select Due Date:</InputLabel>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        margin="normal"
                        id="DueDate"
                        // helperText="select hours only"
                        // label="Select Due Date:"
                        minDate={new Date()}
                        minutesStep={0}
                        format="MM/dd/yyyy"
                        ampm={true}
                        // value={null}
                        value={
                          selectedDate ? new Date(selectedDate) : values.DueDate
                        }
                        onChange={handleChangeFun}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        disablePast
                        error={false}
                        helperText={null}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>
                {/* <Grid item xs={6}>
                  <FormControl
                    className={classes.formControl}
                    error={errors.participantLevel && touched.participantLevel}>
                    <InputLabel
                      className={classes.labelName}
                      id="surveyAdministrator-label">
                      Survey Administrator:
                    </InputLabel>
                    <Select
                      labelId="surveyAdministrator-label"
                      id="values.OwnerID"
                      className={classes.labelName}
                      name="OwnerID"
                      value={values.OwnerID}
                      onChange={handleChange}
                      disabled={true}
                      fullWidth
                      label="surveyAdminstrator">
                      

                      {surveyAdminlist.map((v) => {
                        return (
                          <MenuItem value={v.zUserID}>{v.zFullName}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>
                      {errors.OwnerID && touched.OwnerID ? errors.OwnerID : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid> */}
                <Grid xs={6}></Grid>
                <Grid item xs={6}></Grid>
                <Grid xs={6}></Grid>

                <Grid xs={6}></Grid>
              </Grid>
            </Grid>
            {/* <Grid item xs={5}>
              <InputLabel
                style={{
                  'font-weight': 'bolder',
                  height: '34px',
                  background: '#F6F6F6',
                  display: 'flex',
                  'align-items': 'center',
                }}
                id="ss-label">
                Project Timeline:
              </InputLabel>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {' '}
                      This sets the timing for the delivery of each of the four
                      pulse surveys.
                    </FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={projectType.name}
                      onChange={projectHandlechange}>
                      <FormControlLabel
                        value="Monthly"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Bimonthly"
                        control={<Radio />}
                        label="Bimonthly"
                      />
                      <FormControlLabel
                        value="Quarterly"
                        control={<Radio />}
                        label="Quarterly"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </form>
      </Paper>
    </div>
  )
}
