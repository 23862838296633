import * as types from '../action/ActionType'
export default function CheckedItemList(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.CHECKED_ITEM_LIST:
      return (state = payload)
    case types.CHECKED_SORTED_LIST:
      return (state = payload)

    default:
      return state
  }
}
