import {
  Breadcrumbs,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useParams } from 'react-router-dom'
import {
  GET_TEAM_DETAILS,
  GET_TEAM_SCALE,
  TEAM_GET_ASWERED_OPENEND,
  TEAM_GET_OPENEND,
  TEAM_GET_QUESTION,
  TEAM_SUBMIT_SUYVEY,
  TEAM_UPDATE_OPENENDANSWER,
  TEAM_UPDATE_QUESTION,
} from '../../helpers/constants/ApiRoutes'
import { authHeader, getUserId } from '../common/CustomFunction'
import { API } from '../../helpers/API'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircle'
import { TASK_PATH } from '../../helpers/constants/RoutePaths'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    margin: '0px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  successBtn: {
    fill: theme.successColor,
  },
  descriptoion: {
    fontFamily: 'Roboto-Medium !important',
    fontSize: '16px !important',
    font: 'normal normal medium 16px/24px Roboto !important',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  pageheading: {
    color: '#000000DE !important',
    fontSize: '24px',
  },
  selected: {
    textAlign: ' left',
    color: ' #1565C0 !important',
    opacity: ' 1',
    fontFamily: 'Nunito-Regular !important',
  },
  unselected: {
    color: '#0000008A !important',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    minHeight: '0px !important',
  },
  expanded: {
    padding: '8px',
    margin: '0px !important',
  },
  paper: {
    width: '100%',
    padding: '10px',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
    boxShadow: 'none',
  },
  customTable: {
    // '& .MuiTableCell-sizeSmall': {
    //   padding: '20px',
    // },
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',
    height: '100%',
  },

  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    height: '100%',
    lineHeight: '1.5',
    cursor: 'pointer',
    // or borderTop: '1px solid red'
  },
  tableCellHover: {
    // padding: "0px 8px",
    background: 'white',
    // visibility: 'hidden',
    // padding:
    //   '6px 6px 6px 6px',
    height: '4rem',
    textAlign: 'center',
    padding: 'unset !important',
    // display: '',
    width: '300px',
    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    backgroundColor: 'white',

    lineHeight: '1.5',

    // or borderTop: '1px solid red'
  },
  tableCellk: {
    borderWidth: 1,
    borderColor: 'rgb(195, 195, 195)',
    borderStyle: 'solid',
    height: '100%',
    lineHeight: '1.5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
    },
  },
  tableRow: {
    height: 50,
  },
  roottitle: {
    fontFamily: 'Nunito-SemiBold !important',
    fontSize: '17px !important',
  },
  subheading: {
    fontFamily: 'Roboto-Regular !important',
  },
  subheadingk: {
    fontFamily: 'Roboto-Regular !important',
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      fontSize: '15px',
    },
  },
  showndisplay: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  participantd: {
    display: 'initial',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  subnamek: {
    marginTop: '1rem',
    marginLeft: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '0rem',
      marginLeft: '0rem',
    },
  },
  listcell: {
    justifyContent: 'center',
    display: 'flex',
    margin: 'auto',
    alignContent: 'center',

    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
      cursor: 'pointer',
    },
  },
  handlebg: {
    background: 'transparent !important',
    cursor: 'grab',
    '&:hover': {
      backgroundColor: 'rgb(218 218 218)',
      cursor: 'grab',
    },
  },
  projectDetails: {
    color: 'black',
  },
}))
let rowperpage = 12
function Tsendusertask() {
  const classes = useStyles()
  const [questionList, setquestionList] = useState([])
  const { taskid, taskuserid } = useParams()
  const history = useHistory()
  const [requiredQuestion, setrequiredQuestion] = useState({})
  const [openendanswerd, setopenendanswerd] = useState({})
  const [changesFlag, setchangesFlag] = useState(false)
  const [openEndquestions, setopenEndquestions] = useState([])
  const [currentpage, setcurrentpage] = useState(0)
  const [projectDetails, setprojectDetails] = useState({
    projectname: 'projectname',
    desc: 'description',
  })
  const [openEndflag, setopenEndflag] = useState(false)
  const [confirm, setconfirm] = useState(false)
  const [scaleList, setscaleList] = useState([])
  const scaleSize = useMemo(() => {
    return scaleList.length
  }, [scaleList])
  const totalCoune = useMemo(() => {
    return questionList.length
  }, [questionList])
  const getQuest = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_GET_QUESTION,
      params: {
        RespondenID: getUserId(),
        ProjectID: taskid,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        setquestionList(
          data.performancescalingInformation.map((val, index) => ({
            ...val,
            qname: index + 1,
          }))
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      // setLoading(false)
    }
  }

  const getScale = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_SCALE,
      params: {
        ProjectID: taskid,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        setscaleList(data.scalingInformation)
      }
    } catch (e) {
      console.log(e)
    } finally {
      // setLoading(false)
    }
  }
  const SurveyTable = useCallback((value) => {
    const { zName, zDescription } = value
    return (
      <>
        {' '}
        <p style={{ margin: '0px 0px' }}>{zName}</p>
        <p style={{ margin: '0px 0px' }}>{zDescription || ''}</p>
      </>
    )
  }, [])

  const updateScale = async (e) => {
    const { itemid, id } = e.currentTarget.dataset
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_UPDATE_QUESTION,
      params: {
        ProjectID: taskid,
        RespondentID: getUserId(),
        ItemRatingList: itemid + '_' + id,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        getQuest()
      }
    } catch (e) {
      console.log(e)
    } finally {
      // setLoading(false)
    }
  }
  const handleChangePageBack = (e) => {
    setopenEndflag(false)
    setcurrentpage((val) => val - 1)
  }
  const handleChangePage = (e) => {
    const { type, qustionback = '' } = e.currentTarget.dataset
    let newRequired = { ...requiredQuestion }
    let isRequired = false
    questionList
      .slice(currentpage * rowperpage, currentpage * rowperpage + rowperpage)
      .forEach((val) => {
        if (!val.zScaleRatingID) {
          isRequired = true
          newRequired[val.zItemID] = true
        }
      })
    setrequiredQuestion(newRequired)
    if (isRequired) {
      return null
    }
    if (currentpage * rowperpage + rowperpage >= totalCoune) {
      if (type == 'next') {
        setopenEndflag(true)
      } else {
        setopenEndflag(false)
      }
      if (qustionback) {
        setcurrentpage((val) => val - 1)
      }
    } else {
      if (type == 'next') {
        setcurrentpage((val) => val + 1)
      } else {
        setcurrentpage((val) => val - 1)
      }
    }
  }
  const getOpenendQuestion = async (e) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_GET_OPENEND,
      params: {
        ProjectID: taskid,
        UserId: getUserId(),
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        setopenEndquestions(data.openEndedQuestionsList)

        // setopenendanswerd
      }
    } catch (e) {
      console.log(e)
    } finally {
      // setLoading(false)
    }
  }
  // TEAM_GET_ASWERED_OPENEND
  const getOpenendAnswered = async (e) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_GET_ASWERED_OPENEND,
      params: {
        ProjectID: taskid,
        RespondentID: getUserId(),
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        // setopenEndquestions(data.performopenEndedQuestion)

        let answers = {}
        data.performopenEndedQuestion.forEach((val) => {
          answers[val.zOpenEndedQuestionID] = val.zResponseText
        })
        setopenendanswerd(answers)
      }
    } catch (e) {
      console.log(e)
    } finally {
      // setLoading(false)
    }
  }
  const gotoOpenend = () => {
    setopenEndflag(true)
  }
  const gotoQuestion = () => {
    setopenEndflag(false)
  }

  const openendHandle = (e) => {
    const { qid } = e.currentTarget.dataset
    const { value } = e.target
    let copyAnswer = { ...openendanswerd }

    copyAnswer[qid] = value
    if (!copyAnswer[qid]) {
      delete copyAnswer[qid]
    }
    setchangesFlag(true)
    setopenendanswerd(copyAnswer)
    // console.log(qid, value, e.target)
  }
  const saveOpenendAnswer = async () => {
    // TEAM_UPDATE_OPENENDANSWER

    await Promise.all(
      Object.entries(openendanswerd).map(async (val) => {
        const getconfig = {
          method: 'POST',
          headers: authHeader(),

          url: TEAM_UPDATE_OPENENDANSWER,
          params: {
            ProjectID: taskid,
            RespondentID: getUserId(),
            OpenEndedQuestionID: val[0],
            text: val[1],
          },
        }
        await API(getconfig)
      })
    )
    setchangesFlag(false)
  }

  //TEAM_SUBMIT_SUYVEY
  const submitFinal = () => {
    Swal.fire({
      title: 'Congratulations!',
      text:
        'You completed all of the survey items. Please click below to submit.',
      // icon: 'warning',
      // showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText:
        '<div style="height: 38px;align-items: center;display: flex;" ><h1>Submit Survey</h1></div>',
      cancelButtonColor: '#d33',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (changesFlag) {
          saveOpenendAnswer()
        }
        triggerSUbmit()
      }
    })
  }
  const triggerSUbmit = async () => {
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_SUBMIT_SUYVEY,
      params: {
        TaskUserID: taskuserid,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        // setopenendanswerd
        history.push(TASK_PATH)
      }
    } catch (e) {
      console.log(e)
    } finally {
      // setLoading(false)
    }
  }

  const handleConfirmchanges = (e) => {
    setconfirm(e.target.checked)
  }
  const getProject = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_DETAILS,
      params: {
        projectID: taskid,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        const { zDescription, zName } = data.projectDetails[0]
        setprojectDetails({ projectname: zName, desc: zDescription })
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getQuest()
    getScale()
    getOpenendQuestion()
    getOpenendAnswered()
    getProject()
  }, [])

  return (
    <div>
      <Grid container spacing={1}>
        {/* <Grid item xs={6} lg={6} md={6}>
          <Typography className={classes.pageheading} variant="h6" gutterBottom>
            Tasks - Team survey
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.selected} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              Tasks
            </Typography>
            <Typography className={classes.unselected} color="textPrimary">
              {projectDetails.projectname}
            </Typography>
          </Breadcrumbs>
        </Grid> */}
        {/* <Grid item xs={6} lg={6} md={6} style={{ textAlign: 'right' }}>
          <div className={classes.sideHead}> */}
        {/* <img src={logo} alt="logo" width="100" height="60" /> */}
        {/* </div>
        </Grid> */}
        {/* <Grid item xs={12}>
          <Divider />
        </Grid> */}
        <Paper className={classes.paper}>
          <Grid container direction="row" xs={12}>
            <Grid item xs={6}>
              <Typography variant="h6" className={classes.projectDetails}>
                {projectDetails.projectname}
              </Typography>
              <Typography className={classes.projectDetails}>
                {' '}
                {projectDetails.desc}
              </Typography>
            </Grid>
            <Grid item xs={3}></Grid>
            {/* <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              xs={3}>
              <Button
                data-type="back"
                disabled={currentpage == 0}
                onClick={handleChangePage}
                variant="contained"
                data-qustionback={!openEndflag ? 'back' : ''}
                color="secondary">
                Back
              </Button>

              <Button
                onClick={handleChangePage}
                disabled={openEndflag}
                variant="contained"
                data-type="next"
                color="primary">
                Next
              </Button>
            </Grid> */}
          </Grid>
          <br />
          <Grid item xs={12}>
            <Typography>
              You are being asked to complete this survey about how your team
              functions and performs together. Thus, when answering the
              questions, please describe how you believe the team is doing as a
              whole, instead of thinking about any individual. Usually your
              first reaction and answer to an item is most accurate. Please rate
              the extent to which you agree with the following statements. Our
              team…
            </Typography>
            <br />
            <Table
              stickyHeader
              size="small"
              className={classes.customTable}
              style={{
                borderColor: '#C3C3C3',
                borderCollapse: 'collapse',
                marginBottom: '1rem',
              }}>
              {!openEndflag &&
                questionList
                  .slice(
                    currentpage * rowperpage,
                    currentpage * rowperpage + rowperpage
                  )

                  .map((v, index) => {
                    return (
                      <>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={scaleSize}>
                              <Grid container>
                                <Grid item>
                                  <CheckCircleOutlineIcon
                                    style={{
                                      color: v.zScaleRatingID
                                        ? 'green'
                                        : requiredQuestion[v.zItemID]
                                        ? 'red'
                                        : 'white',
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography>
                                    {' '}
                                    {v.qname}. {v.zText}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {scaleList.map((scaleRating) => {
                              return (
                                <>
                                  <TableCell
                                    data-id={scaleRating.zScaleRatingID}
                                    data-itemid={v.zItemID}
                                    onClick={updateScale}
                                    className={classes.tableCell}
                                    style={{
                                      backgroundColor: '#D0E9FC',
                                      background:
                                        v.zScaleRatingID ==
                                        scaleRating.zScaleRatingID
                                          ? '#1565C0'
                                          : '#64B5F64D',
                                      color:
                                        v.zScaleRatingID ==
                                        scaleRating.zScaleRatingID
                                          ? 'white'
                                          : 'black',
                                      borderColor: '#C3C3C3',
                                      // padding:
                                      //   '6px 6px 6px 6px',
                                      height: '1rem',
                                      textAlign: 'center',
                                      width: '300px',
                                    }}>
                                    <Grid>{SurveyTable(scaleRating)}</Grid>
                                  </TableCell>
                                </>
                              )
                            })}
                          </TableRow>
                        </TableHead>
                        <br />
                      </>
                    )
                  })}
            </Table>
            <Grid container direction="row" xs={12}>
              <Grid item xs={9}></Grid>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                xs={3}>
                <Button
                  data-type="back"
                  disabled={currentpage == 0}
                  onClick={handleChangePageBack}
                  variant="contained"
                  data-qustionback={!openEndflag ? 'back' : ''}
                  color="secondary">
                  Back
                </Button>

                <Button
                  onClick={handleChangePage}
                  disabled={openEndflag}
                  variant="contained"
                  data-type="next"
                  color="primary">
                  Next
                </Button>
              </Grid>
            </Grid>
            {/* {!openEndflag && (
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={totalCoune}
                rowsPerPage={rowperpage}
                page={currentpage}
                onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )} */}
            {openEndflag &&
              openEndquestions.map((val, index) => {
                return (
                  <div style={{ marginBottom: '2.5rem' }}>
                    <Typography>
                      {index + 1}. {val.zQuestionText}
                    </Typography>
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      // label="Multiline"
                      onChange={openendHandle}
                      inputProps={{
                        'data-qid': val.zOpenEndedQuestionID,
                      }}
                      value={openendanswerd[val.zOpenEndedQuestionID] || ''}
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                  </div>
                )
              })}
            {totalCoune < currentpage * rowperpage + rowperpage && (
              <div>
                {openEndflag ? (
                  <>
                    {/* <Button
                      onClick={gotoQuestion}
                      variant="contained"
                      color="secondary">
                      Prev
                    </Button>
                    <Button
                      onClick={saveOpenendAnswer}
                      variant="contained"
                      color="primary">
                      Save
                    </Button> */}
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <FormLabel component="legend">
                        Thank you for taking time to participate in our survey.
                        We truly value the information you have provided. By
                        checking the box below and clicking SUBMIT, I understand
                        my ratings are final and cannot be changed.
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={confirm}
                              onChange={handleConfirmchanges}
                              name="gilad"
                            />
                          }
                          label="Yes, all ratings are final."
                        />
                      </FormGroup>
                      <Button
                        onClick={submitFinal}
                        variant="contained"
                        disabled={!confirm}
                        color="primary">
                        submit
                      </Button>
                    </FormControl>
                  </>
                ) : (
                  <Button
                    onClick={gotoOpenend}
                    variant="contained"
                    color="primary">
                    Next
                  </Button>
                )}
              </div>
            )}
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}

export default Tsendusertask
