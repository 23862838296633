import React, { Fragment, useEffect, useState } from 'react'
// import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Zoom from '@material-ui/core/Zoom'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import {
  getUserRoleId,
  isOrganizationAdmin,
  isOrg_Lp_Admin,
  isParterAdmin,
  isPartner_Lp_Admin,
  isThinkwiseAdmin,
  isLearningPackAdmin,
  isProjectAdmin,
  getUserAdminRole,
  getAdminRoleId,
} from '../common/CustomFunction'
import {
  TEAMSURVEYPATH,
  TEAMSURVEYTASK,
} from '../../helpers/constants/RoutePaths'
import AssessmentIcon from '@mui/icons-material/Assessment'
const menuList = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: DashboardIcon,
  },
  {
    name: 'Tasks',
    icon: AssignmentTurnedInIcon,
    url: '/tasks',

    // subMenues: [
    //   {
    //     name: '360 Surveytasks',
    //     url: '/tasks',
    //     // icon: AssignmentTurnedInIcon,
    //   },
    //   { name: 'Team Surveytasks', url: TEAMSURVEYTASK },
    // ],
  },
  {
    name: 'Reports',
    icon: AssessmentIcon,
    url: '/userreports',
  },
  {
    name: 'ThinkWise 360',
    icon: AutorenewIcon,
    subMenues: [
      {
        name: 'Projects',
        url: '/projects',
      },
      {
        name: '360 Reports',
        url: '/reports',
      },
    ],
  },
  {
    name: 'Team survey',
    icon: GroupWorkIcon,
    subMenues: [
      {
        name: 'Projects',
        url: TEAMSURVEYPATH,
      },
    ],
  },
  {
    name: 'Admin',
    icon: SupervisorAccountIcon,
    subMenues: [
      {
        name: 'Accounts & Users',
        url: '/accountusers',
      },
    ],
  },
]

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  iconStyle: {
    color: '#fff',
  },
  listTextStyle: {
    color: '#fff',
    fontSize: '13px',
    textDecorationLine: 'none',
    fontWeight: '500',
    fontFamily: 'Nunito-Regular !important',
  },
  listItemStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#fff',
      '& $listTextStyle': {
        color: theme.primaryColor,
      },
      '& $iconStyle': {
        color: theme.primaryColor,
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#fff',
      '& $listTextStyle': {
        color: theme.primaryColor,
      },
      '& $iconStyle': {
        color: theme.primaryColor,
      },
    },
    '&:hover': {
      backgroundColor: theme.secondaryColor,
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
    // '&:MuiListItemText-root':{
    //   minWidth: 1,
    // }
  },
}))

// #rbp Menu List
export default function ListItems({ activeMenu, handleDrawerDependsOnScreen }) {
  console.log('called listitems')
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const [currentUrl, setUrl] = useState()
  const userRoleId = getUserRoleId()

  const handleClick = (name) => {
    if (name === open) {
      setOpen(false)
    } else {
      setOpen(name)
    }
  }

  let menuName = ''
  useEffect(() => {
    menuList.forEach((menu) => {
      menuName = menu.name
      if (menu && menu.subMenues) {
        menu.subMenues.forEach((subMenu) => {
          if (subMenu && subMenu.url.split('/')[1] == activeMenu) {
            setOpen(menuName)
            return false
          }
        })
      } else {
        setOpen(menuName)
      }
    })
  }, [activeMenu])

  return (
    <div>
      {menuList !== undefined &&
        menuList.map(({ name, url, icon, subMenues }, index) => {
          const DynamicIconComponent = icon
          let selectedMenu = false
          if (url && activeMenu === url.split('/')[1]) {
            selectedMenu = true
          }

          return (
            <>
              {subMenues &&
              (isOrg_Lp_Admin() ||
                isParterAdmin() ||
                isOrganizationAdmin() ||
                isPartner_Lp_Admin() ||
                isThinkwiseAdmin() ||
                isLearningPackAdmin() ||
                isProjectAdmin()) ? (
                <Fragment>
                  <ListItem button onClick={() => handleClick(name)}>
                    <ListItemIcon>
                      {DynamicIconComponent && (
                        <DynamicIconComponent className={classes.iconStyle} />
                      )}
                    </ListItemIcon>
                    <ListItemText style={{ minWidth: 'unset' }}>
                      <span className={classes.listTextStyle}>{name}</span>
                    </ListItemText>
                    <ListItemIcon style={{ minWidth: 'auto' }}>
                      {open === name ? (
                        <ExpandLess className={classes.iconStyle} />
                      ) : (
                        <ExpandMore className={classes.iconStyle} />
                      )}
                    </ListItemIcon>
                  </ListItem>
                  <Collapse
                    in={open === name ? true : false}
                    timeout="auto"
                    unmountOnExit>
                    <List component="div" disablePadding>
                      {subMenues.map(({ name: sub_name, url: sub_url }) => {
                        const selectedSubMenu =
                          activeMenu === sub_url.split('/')[1] ? true : false
                        return (
                          <ListItem
                            data-route_path={sub_url}
                            onClick={handleDrawerDependsOnScreen}
                            className={`${classes.listItemStyle} ${classes.nested}`}
                            button
                            selected={selectedSubMenu}
                            key={`sub_menu_${sub_url}`}>
                            <ListItemText>
                              <span className={classes.listTextStyle}>
                                {sub_name}
                              </span>
                            </ListItemText>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Collapse>
                </Fragment>
              ) : (isOrg_Lp_Admin() ||
                  isParterAdmin() ||
                  isOrganizationAdmin() ||
                  isPartner_Lp_Admin() ||
                  isThinkwiseAdmin() ||
                  isLearningPackAdmin() ||
                  isProjectAdmin() ||
                  name === 'Tasks') &&
                name != 'Reports' ? (
                <ListItem
                  data-route_path={url}
                  onClick={handleDrawerDependsOnScreen}
                  className={classes.listItemStyle}
                  button
                  selected={selectedMenu}>
                  <ListItemIcon>
                    {DynamicIconComponent && (
                      <DynamicIconComponent className={classes.iconStyle} />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <span className={classes.listTextStyle}>{name}</span>
                  </ListItemText>
                </ListItem>
              ) : getUserRoleId() == 0 &&
                getAdminRoleId() == 0 &&
                name === 'Reports' ? (
                <ListItem
                  data-route_path={url}
                  onClick={handleDrawerDependsOnScreen}
                  className={classes.listItemStyle}
                  button
                  selected={selectedMenu}>
                  <ListItemIcon>
                    {DynamicIconComponent && (
                      <DynamicIconComponent className={classes.iconStyle} />
                    )}
                  </ListItemIcon>
                  <ListItemText>
                    <span className={classes.listTextStyle}>{name}</span>
                  </ListItemText>
                </ListItem>
              ) : (
                ''
              )}
            </>
          )
        })}
    </div>
  )
}
