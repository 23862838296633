import React, { useState, useEffect, useCallback } from 'react'
import {
  AccordionDetails,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import CustomButton from '../../../../../common/CustomButton'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  SAVE_RATER,
  ADD_ROSTER,
  SAVE_MANAGE_ROSTER,
  SAVE_ROSTER_RATER_EXEMAIL,
  ADD_PARTICIPANT_OR_RATER_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import { useFormik } from 'formik'
import { API } from '../../../../../../helpers/API'
import CancelIcon from '@material-ui/icons/Cancel'
import Swal from 'sweetalert2'
import { authHeader, getAlertToast } from '../../../../../common/CustomFunction'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
function Addrater(props) {
  const { enqueueSnackbar } = useSnackbar()
  const { classes, raterList } = props
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required'),
    rater: Yup.string()
      .trim()
      .required('Rater relationship is required'),
  })

  const handleAddRater = async (formData) => {
    const { firstName, lastName, email, rater } = formData

    const { projectID, LoginUserID, OrganizationID } = props
    const postConfig = {
      method: 'POST',
      headers: authHeader(),
      url: SAVE_ROSTER_RATER_EXEMAIL,
      data: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        LUID: LoginUserID,
        ProjectID: projectID,
        organizationID: OrganizationID,
        respondentORParticipant: 1,
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        addParticipantUserNew(response.data)
        handleAddRaterNew(response.data, rater)
        // await handleUpdatePartner()
      }
    } catch (error) {
      //handleReset();

      CustomMessage(
        'This email already exist, Try with other email',
        'error',
        enqueueSnackbar
      )
    }
  }

  const addParticipantUserNew = async (data) => {
    // setLoading(true)
    console.log('logging', data)
    const { UserID, zUserID } = data.data[0]

    const { projectID, LoginUserID, OrganizationID } = props

    const postConfig = {
      method: 'GET',
      headers: authHeader(),

      url: ADD_PARTICIPANT_OR_RATER_URL,
      params: {
        // LUID: LoginUserID,
        ProjectID: projectID,
        UserID: zUserID,
        RespondentOrParticipant: 1,
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        console.log(response, 'responcedata')

        // await handleUpdatePartner()
      }
    } catch (error) {
      //handleReset();
      // setError('This email already in participant, Try with other email')
      // setError('Participant is already on the roster, Try with other email')
    } finally {
      // setLoading(false)
    }
  }

  const handleAddRaterNew = async (data, rater) => {
    const { UserID, zUserID } = data.data[0]
    const {
      projectID,
      LoginUserID,
      ParticipantID,
      handleReset,
      getParticipantList,
    } = props
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_ROSTER,
      data: {
        userID: LoginUserID,
        ProjectID: projectID,
        participantID: ParticipantID,
        relationshipID: rater,
        respondentID: zUserID,
      },
    }
    const response = await API(postConfig)
    if (response.status === 200) {
      handleSaveRoster()
    }
  }

  const handleSaveRoster = async () => {
    const {
      projectID,
      LoginUserID,
      ParticipantID,
      handleReset,
      getParticipantList,
    } = props
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_MANAGE_ROSTER,
      data: {
        userID: '',
        projectID: projectID,
        dueDate: props.projectDetails.zDueDateFormat,
        chkSendEmail: true,
        baseURL: window.location.origin,
      },
    }
    const response = await API(postConfig)
    if (response.status === 200) {
      Swal.fire({
        title: "<h5 style='color:#fff'>Changes were saved successfully.</h5>",
        background: '#00cc00',
      }).then((value) => {})
      //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
      //getParticipantList();
      handleReset()
    }
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: { firstName: '', lastName: '', email: '', rater: '' },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleAddRater(values)
    },
  })
  const { firstName = '', lastName = '', email = '', rater = '' } = values || {}
  const relationShipHandle = (e) => {
    console.log(e)
  }

  const sendRequest = useCallback(async () => {
    resetForm()
  })

  return (
    <AccordionDetails style={{ padding: '0px' }} className={classes.accDetail}>
      {/* {data &&
                  data.map((record, index) => {
                    return ( */}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
          // direction="row"
          // justifyContent="center"

          style={{ marginTop: '2px' }}>
          <Grid item xs={2} className={classes.grid}>
            <TextField
              id="lastName"
              label="Last Name:"
              value={lastName}
              onChange={handleChange}
              fullWidth
              name="lastName"
              error={errors.lastName && touched.lastName}
              helperText={
                errors.lastName && touched.lastName ? errors.lastName : ''
              }
            />
          </Grid>
          <Grid item xs={2} className={classes.grid}>
            <TextField
              id="firstName"
              label="First Name:"
              value={firstName}
              onChange={handleChange}
              fullWidth
              name="firstName"
              error={errors.firstName && touched.firstName}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : ''
              }
            />
          </Grid>

          <Grid item xs={2} className={classes.grid}>
            <TextField
              id="email"
              label="Email:"
              value={email}
              onChange={handleChange}
              fullWidth
              name="email"
              error={errors.email && touched.email}
              helperText={errors.email && touched.email ? errors.email : ''}
            />
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            <FormControl
              className={classes.formControl}
              error={errors.scale && touched.scale}>
              <InputLabel>Rater Relationship</InputLabel>
              <Select
                labelId="scale-label"
                value={rater}
                name="rater"
                // defaultValue="Self"
                onChange={handleChange}
                label="SelectRater Relationship"
                fullWidth>
                {raterList &&
                  raterList.map((record, i) => {
                    const { zName = '', zRelationshipID = '' } = record
                    return <MenuItem value={zRelationshipID}>{zName}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <CustomButton colour="primaryButton" type="submit">
              Save
            </CustomButton>
            {/* {logsuccess ? (
              <IconButton aria-label="edit" size="small">
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : (
              ''
            )} */}

            <IconButton
              aria-label="delete"
              size="small"
              style={{ marginLeft: '1rem' }}>
              <CancelIcon
                onClick={() => props.handleResetRater(props.ParticipantID)}
                fontSize="inherit"
              />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      {/* );
                  })} */}
    </AccordionDetails>
  )
}

export default Addrater
