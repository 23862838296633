import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { isObject, useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import defaultAvatar from '../../../../../../assets/images/defaultAvatar.png'
import State from '../../../../../common/State'
import { CREATE_ORG_URL } from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useEffect, useState } from 'react'
import {
  authHeader,
  isThinkwiseAdmin,
} from '../../../../../common/CustomFunction'
import CustomLoader from '../../../../../common/CustomLoader'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

//Schema

export default function AddEditOrganization(props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const {
    open = '',
    handleReset,
    title = '',
    value,
    partnerid,
    orgInfo,
  } = props

  let checkCreateOrEdit = Object.prototype.toString.call(orgInfo)

  // const [enterpriceCustomer, setEnterPriceCustomer] = useState(false)

  const validationSchema = Yup.object({
    organizationName: Yup.string()
      .trim()
      .required('OrganizationName is required'),
    mailAddressLine1:
      checkCreateOrEdit === '[object Array]' &&
      Yup.string()
        .trim()
        .required('Mailing Address Line1 is required'),
    mailAddressLine2:
      checkCreateOrEdit === '[object Array]' &&
      Yup.string()
        .trim()
        .required('Mailing Address Line2 is required'),
    zCity:
      checkCreateOrEdit === '[object Array]' &&
      Yup.string().required('City is required'),
    state: Yup.string()
      .trim()
      .required('State is required'),
    zipCode: Yup.string()
      .trim()
      .required('zip code is required'),
    // phoneNumber:
    //   checkCreateOrEdit === '[object Array]' &&
    //   Yup.string()
    //     .trim()
    //     .required('Phone Number is required'),
    zWebSite: Yup.string()
      .trim()
      .required('Website is required'),
  })

  useEffect(() => {
    // orgInfo && setEnterPriceCustomer(orgInfo.zCustomerFlag)
  }, [])

  const initialValues = {
    organizationID: orgInfo ? orgInfo.zOrganizationID : '',
    organizationName: orgInfo ? orgInfo.zName : '',
    mailAddressLine1: orgInfo ? orgInfo.zAddressLine1 : '',
    mailAddressLine2: orgInfo ? orgInfo.zAddressLine1 : '',
    zCity: orgInfo?.zCity || '',
    state: orgInfo ? orgInfo.zStateID : '',
    zipCode: orgInfo ? orgInfo.zZipCode : '',
    zWebSite: orgInfo ? orgInfo.zWebSite : '',
    customerFlag: orgInfo?.zCustomerFlag,
    allowCustomItemsFlag: true,
    allowCompModelSelectionFlag: true,
    showAllTasks360Flag: true,
    force360ScaleId: 0,
    orgDefinedCompetencyFlag: true,
    showDevObjectiveFlag: true,
    active: true,
    noEmailToFriendThinkBox: true,
    viewIncomplete360Report: orgInfo?.zViewIncomplete360Report,
    multiLanguage: true,
    domain: orgInfo
      ? orgInfo.zDomain
        ? orgInfo.zDomain.split('.')[0]
        : ''
      : '',
    luid: 1,
    phoneNumber: '',
    competencySearchTitle: '',
    devSurveyReportText: '',
    hrSelectiveReporting: true,
  }

  //state

  const createOrganization = async (formData) => {
    setLoading(true)
    const {
      organizationName,
      city,
      state,
      mailAddressLine1,
      mailAddressLine2,
      domain,
      zipCode,
      viewIncomplete360Report,
      customerFlag,
      zWebSite,
      zCity,
    } = formData

    console.log(domain)

    const getconfig = {
      method: 'POST',
      url: CREATE_ORG_URL,
      headers: authHeader(),

      data: {
        organizationID: orgInfo ? orgInfo.zOrganizationID : 0,
        name: organizationName,
        addressLine1: mailAddressLine1,
        addressLine2: mailAddressLine2,
        city: zCity,
        stateId: state,
        zipCode,
        webSite: zWebSite,
        customerFlag: customerFlag,
        allowCustomItemsFlag: true,
        allowCompModelSelectionFlag: true,
        showAllTasks360Flag: true,
        force360ScaleId: 0,
        orgDefinedCompetencyFlag: true,
        showDevObjectiveFlag: true,
        active: true,
        noEmailToFriendThinkBox: true,
        viewIncomplete360Report: viewIncomplete360Report,
        multiLanguage: true,
        domain: `${domain.toLowerCase()}.thinkwiseinc.com`,
        partnerId: partnerid,
        luid: 1,
        competencySearchTitle: '',
        devSurveyReportText: '',
        hrSelectiveReporting: true,
      },
    }
    try {
      const response = await API(getconfig)

      if (response.status === 200) {
        await handleReset()
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  //Formik
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    // initialValues: {
    //   ...value,
    //   partnerId: partnerid,
    //   luid: 2,
    //   forcedTemplateProjectId: 1,
    //   force360ScaleId: 1,
    //   forcePerfReviewScaleId: 1,
    // },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => createOrganization(values),
  })

  console.log(orgInfo?.zViewIncomplete360Report)
  const {
    // comboOrganization = 0,need to
    organizationRoleId = 0,
    name = '',
    mailAddressLine1 = '',
    mailAddressLine2 = '',
    zCity = '',
    state = '',
    zipCode = '',
    webSite = '',
    forcedTemplateProjectId = 0,
    force360ScaleId = 0,
    forcePerfReviewScaleId = 0,
    domain = '',
    organizationName = '',
    phoneNumber = '',
    luid = 0,
    competencySearchTitle = 'string',
    devSurveyReportText = 'string',
    hrAssessmentResetMonths = 0,
    publicCompanyFlag = false,
    customerFlag = orgInfo?.zCustomerFlag,
    allowCustomItemsFlag = false,
    allowCompModelSelectionFlag = false,
    forceTemplateSelectionFlag = false,
    orgDefinedCompetencyFlag = false,
    noEmailToFriendThinkBox = false,
    showDevObjectiveFlag = false,
    viewIncomplete360Report = orgInfo?.zViewIncomplete360Report,
    hrSelectiveReporting = false,
    showAllTasks360Flag = false,
    multiLanguage = false,
    active = false,
    zWebSite = '',
  } = values

  const checkboxHandle = (event) => {
    const { checked, name } = event.target
    setValues((val) => ({ ...val, [name]: checked }))
  }
  const checkBoxlabel = {
    publicCompanyFlag: 'Public company flag',
    customerFlag: 'Customerflag',
    allowCustomItemsFlag: 'allow custom Items flag',
    allowCompModelSelectionFlag: 'Allow compmodel selection flag',
    forceTemplateSelectionFlag: 'Force template selection flag',
    orgDefinedCompetencyFlag: 'Org defined competency flag',
    noEmailToFriendThinkBox: 'No email to friend think box',
    showDevObjectiveFlag: 'show dev object 360 report',
    viewIncomplete360Report: 'View incomplete 360 report',
    hrSelectiveReporting: 'Hr selective reporting',
    showAllTasks360Flag: 'Show all tasks 360 flag',
    multiLanguage: 'Multti language',
    active: 'active',
  }

  const CheckBoxControl = (props) => {
    const { label } = props
    return (
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            name={label}
            onChange={checkboxHandle}
            checked={values[label]}
          />
        }
        label={checkBoxlabel[label]}
      />
    )
  }

  console.log(orgInfo, values, 'orgInfo')

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Organization Name"
                  value={organizationName}
                  onChange={handleChange}
                  fullWidth
                  name="organizationName"
                  error={errors.organizationName && touched.organizationName}
                  helperText={
                    errors.organizationName && touched.organizationName
                      ? errors.organizationName
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  disabled={isThinkwiseAdmin() ? false : true}
                  control={
                    <Checkbox
                      color="primary"
                      name="customerFlag"
                      checked={customerFlag}
                      onChange={(e) => checkboxHandle(e)}
                    />
                  }
                  label="Enterprise Customer"
                />
              </Grid>
              <Grid item xs={4}>
                {title == 'Add Organization' ? (
                  <Button
                    color="default"
                    variant="outlined"
                    style={{ textTransform: 'none', float: 'right' }}>
                    <b> Add Logo</b>
                  </Button>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Mailing Address"
                  value={mailAddressLine1}
                  onChange={handleChange}
                  fullWidth
                  name="mailAddressLine1"
                  error={errors.mailAddressLine1 && touched.mailAddressLine1}
                  helperText={
                    errors.mailAddressLine1 && touched.mailAddressLine1
                      ? errors.mailAddressLine1
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Mailing Address Line 2"
                  value={mailAddressLine2}
                  onChange={handleChange}
                  fullWidth
                  name="mailAddressLine2"
                  error={errors.mailAddressLine2 && touched.mailAddressLine2}
                  helperText={
                    errors.mailAddressLine2 && touched.mailAddressLine2
                      ? errors.mailAddressLine2
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="City"
                  value={zCity}
                  onChange={handleChange}
                  fullWidth
                  name="zCity"
                  error={errors.zCity && touched.zCity}
                  helperText={errors.zCity && touched.zCity ? errors.zCity : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <State name="state" value={state} handleChange={handleChange} />
                {/* <TextField
                  id="name-helper-text"
                  label="State"
                  value={state}
                  onChange={handleChange}
                  fullWidth
                  name="state"
                  error={errors.state && touched.state}
                  helperText={errors.state && touched.state ? errors.state : ""}
                /> */}
              </Grid>
              {/*  <Grid item xs={4}>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  name="country"
                  // value={country}
                  onChange={handleChange}
                  fullWidth
                  label="Country"
                  error={errors.country && touched.country}
                  helperText={
                    errors.country && touched.country ? errors.country : ''
                  }>
                  <MenuItem value="">Select Country</MenuItem>
                </Select>
                </Grid>*/}
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Zip Code"
                  value={zipCode}
                  onChange={handleChange}
                  fullWidth
                  name="zipCode"
                  error={errors.zipCode && touched.zipCode}
                  helperText={
                    errors.zipCode && touched.zipCode ? errors.zipCode : ''
                  }
                />
              </Grid>
              {/* <Grid item xs={4}></ssssGrid> */}
              {/* <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  name="phoneNumber"
                  error={errors.phoneNumber && touched.phoneNumber}
                  helperText={
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : ''
                  }
                />
              </Grid> */}
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Organization Website"
                  value={zWebSite}
                  onChange={handleChange}
                  fullWidth
                  name="zWebSite"
                  error={errors.zWebSite && touched.zWebSite}
                  helperText={
                    errors.zWebSite && touched.zWebSite ? errors.zWebSite : ''
                  }
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox color="primary" name="all" defaultChecked />
                  }
                  label="Billing Address Same As Mailing Address"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Billing Address"
                  // value={billAddress}
                  onChange={handleChange}
                  fullWidth
                  name="billAddress"
                  error={errors.billAddresss && touched.billAddress}
                  helperText={
                    errors.billAddress && touched.billAddress
                      ? errors.billAddress
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Billing Address Line 2"
                  // value={billAddress2}
                  onChange={handleChange}
                  fullWidth
                  name="billAddress2"
                  error={errors.billAddress2 && touched.billAddress2}
                  helperText={
                    errors.billAddress2 && touched.billAddress2
                      ? errors.billAddress2
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="City"
                  // value={city1}
                  onChange={handleChange}
                  fullWidth
                  name="city1"
                  error={errors.city1 && touched.city1}
                  helperText={errors.city1 && touched.city1 ? errors.city1 : ""}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="State"
                  // value={state1}
                  onChange={handleChange}
                  fullWidth
                  name="state1"
                  error={errors.state1 && touched.state1}
                  helperText={
                    errors.state1 && touched.state1 ? errors.state1 : ""
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <InputLabel id="country1">Country</InputLabel>
                <Select
                  labelId="country1"
                  id="country1"
                  name="country1"
                  // value={country1}
                  onChange={handleChange}
                  fullWidth
                  label="Country"
                  error={errors.country1 && touched.country1}
                  helperText={
                    errors.country1 && touched.country1 ? errors.country1 : ""
                  }
                >
                  <MenuItem value="">Select Country</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Zip Code"
                  // value={zipCode1}
                  onChange={handleChange}
                  fullWidth
                  name="zipCode1"
                  error={errors.zipCode1 && touched.zipCode1}
                  helperText={
                    errors.zipCode1 && touched.zipCode1 ? errors.zipCode1 : ""
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid> */}
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'end' }}>
                <TextField
                  id="name-helper-text"
                  label="Your Thinkwise Domain"
                  value={domain}
                  onChange={handleChange}
                  fullWidth
                  name="domain"
                  disabled={isThinkwiseAdmin() ? false : true}
                  error={errors.domain && touched.domain}
                  helperText={
                    errors.domain && touched.domain ? errors.domain : ''
                  }
                />
                <span>.thinkwiseinc.com</span>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ color: 'rgb(150 150 150)' }}>
                  Your Cantoo Domain
                </Typography>
                <Typography style={{ color: 'rgb(150 150 150)' }}>
                  Note <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Typography style={{ color: 'rgb(150 150 150)' }}>
                  Use the thinkwise sub domain name under Organization field
                  when trying accessing the new application.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  // disabled={isThinkwiseAdmin() ? false : true}
                  control={
                    <Checkbox
                      color="primary"
                      name="viewIncomplete360Report"
                      checked={viewIncomplete360Report}
                      onChange={(e) => checkboxHandle(e)}
                    />
                  }
                  label="View incomplete 360 report"
                />
              </Grid>{' '}
              <Grid item xs={4}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ float: 'right' }}>
                  {loading && <CustomLoader loading={loading} />} Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}

//  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
//    <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Organization Name"
//          value={name}
//          onChange={handleChange}
//          fullWidth
//          name="name"
//          error={errors.name && touched.name}
//          helperText={errors.name && touched.name ? errors.name : ""}
//        />
//      </Grid>
//      <Grid item xs={4}></Grid>
//      <Grid item xs={4}>
//        {title == "Add Organization" ? (
//          <Button
//            color="default"
//            variant="outlined"
//            style={{ textTransform: "none", float: "right" }}
//          >
//            <b> Add Logo</b>
//          </Button>
//        ) : (
//          ""
//        )}
//      </Grid>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Mailing Address"
//          value={addressLine1}
//          onChange={handleChange}
//          fullWidth
//          name="addressLine1"
//          error={errors.addressLine1 && touched.addressLine1}
//          helperText={
//            errors.addressLine1 && touched.addressLine1
//              ? errors.addressLine1
//              : ""
//          }
//        />
//      </Grid>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Mailing Address Line 2"
//          value={addressLine2}
//          onChange={handleChange}
//          fullWidth
//          name="addressLine2"
//          error={errors.addressLine2 && touched.addressLine2}
//          helperText={
//            errors.addressLine2 && touched.addressLine2
//              ? errors.addressLine2
//              : ""
//          }
//        />
//      </Grid>
//      <Grid item xs={4}></Grid>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="City"
//          value={city}
//          onChange={handleChange}
//          fullWidth
//          name="city"
//          error={errors.city && touched.city}
//          helperText={errors.city && touched.city ? errors.city : ""}
//        />
//      </Grid>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Zip Code"
//          value={zipCode}
//          onChange={handleChange}
//          fullWidth
//          name="zipCode"
//          error={errors.zipCode && touched.zipCode}
//          helperText={errors.zipCode && touched.zipCode ? errors.zipCode : ""}
//        />
//      </Grid>
//      <Grid item xs={4}></Grid>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Organization Website"
//          value={webSite}
//          onChange={handleChange}
//          fullWidth
//          name="webSite"
//          error={errors.webSite && touched.webSite}
//          helperText={errors.webSite && touched.webSite ? errors.webSite : ""}
//        />
//      </Grid>
//      {/* competencySearchTitle = "string", devSurveyReportText = "string", */}
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Compeency Search Title"
//          value={competencySearchTitle}
//          onChange={handleChange}
//          fullWidth
//          name="competencySearchTitle"
//          error={errors.competencySearchTitle && touched.competencySearchTitle}
//          helperText={
//            errors.competencySearchTitle && touched.competencySearchTitle
//              ? errors.competencySearchTitle
//              : ""
//          }
//        />
//      </Grid>

//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Dev Survey Report Text"
//          value={devSurveyReportText}
//          onChange={handleChange}
//          fullWidth
//          name="devSurveyReportText"
//          error={errors.devSurveyReportText && touched.devSurveyReportText}
//          helperText={
//            errors.devSurveyReportText && touched.devSurveyReportText
//              ? errors.devSurveyReportText
//              : ""
//          }
//        />
//      </Grid>
//      {/* <Grid item xs={12}>
//               <FormControlLabel
//                 control={
//                   <Checkbox color="primary" name="all" defaultChecked />
//                 }
//                 label="Billing Address Same As Mailing Address"
//               />
//             </Grid> */}
//      {/* <Grid item xs={4}></Grid> */}
//      <Grid item xs={4}>
//        <State name="stateId" handleChange={handleChange} />
//      </Grid>
//      <Grid item xs={4}></Grid>
//      <Grid item xs={4}></Grid>
//      <Grid item xs={12}></Grid>
//      <Grid item xs={4}>
//        <TextField
//          id="name-helper-text"
//          label="Thinkwise Domain"
//          value={domain}
//          onChange={handleChange}
//          fullWidth
//          name="domain"
//          error={errors.domain && touched.domain}
//          helperText={errors.domain && touched.domain ? errors.domain : ""}
//        />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="publicCompanyFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="customerFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="allowCustomItemsFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="allowCompModelSelectionFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="forceTemplateSelectionFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="orgDefinedCompetencyFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="noEmailToFriendThinkBox" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="showDevObjectiveFlag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="viewIncomplete360Report" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="hrSelectiveReporting" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="showAllTasks360Flag" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="multiLanguage" />
//      </Grid>
//      <Grid item xs={4}>
//        <CheckBoxControl label="active" />
//      </Grid>
//      <Grid item xs={4}>
//        <CustomButton
//          colour="primaryButton"
//          type="submit"
//          onClick={handleSubmit}
//          style={{ float: "right" }}
//        >
//          Save
//        </CustomButton>
//      </Grid>
//    </Grid>
//  </form>;
