import * as types from '../action/ActionType'
export default function AccountsUsersReducer(state = 1, action) {
  const { type, payload } = action
  switch (type) {
    case types.GET_ORGANIZATION_LOGO:
      return (state = payload)
    default:
      return state
  }
}
