import { Typography } from '@material-ui/core'
import { Skeleton } from '@mui/material'
import React from 'react'
import submitsurvey from '../../../../../../../../assets/images/submit_survey.png'

const SubmitSurvey = () => {
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        When I am finished with the survey?
      </Typography>
      <Typography variant="p">
        You are finished when you have rated each Participant for all of the
        items in the survey.
        <br />
        An item is complete when you see the green check mark next to the
        number.
        <br />
        When all items are complete, you will be prompted to submit the survey.{' '}
      </Typography>
      {submitsurvey ? (
        <img
          src={submitsurvey}
          style={{ height: 'auto', width: '100%', paddingTop: '20px' }}
        />
      ) : (
        <Skeleton width="100%" height={400} />
      )}
    </div>
  )
}

export default SubmitSurvey
