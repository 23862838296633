import React, { useEffect, useMemo, useState } from 'react'
import threesixty from '../assets/images/arrow.png'
import threesixtySurvey from '../assets/images/word/360survey.png'
import compentencysample from './360images/SummaryResultsByRespondentGroup.png'
import TOPTENIMAGE from './360images/Top10.png'
import openEndimage from './360images/OpenEnded.png'
import { API } from '../helpers/API'
import { sanitize } from 'dompurify'

import {
  compentencysampleImageblob,
  imagebloburl,
  Main,
  toptensampleImageStoreImageblob,
  detailssampleImageStoreImageblob,
  openendImageStoreImageblob,
} from './imageblob'
function Individualreporthtml(props) {
  const [data] = props.Individual

  let summeryImage = imagebloburl[0] || ''
  let compentencysampleImage = compentencysampleImageblob[0] || ''
  let toptensampleImageStoreImage = toptensampleImageStoreImageblob[0] || ''
  let detailSampleimage = detailssampleImageStoreImageblob[0] || ''
  let openendSampleimage = openendImageStoreImageblob[0] || ''
  const {
    strReportTitle,
    strPName,
    strReportDesc,
    organizationName,
    projectName,
    ownerName,
    endDate,
    dueDateFormat,
    addSummaryCompetencyInstructions = [],
    addSummaryResults = [],
    addSummaryRespondentInstructions = [],
    addMatrix = [],
    addCompetencyResults = [],
    addTop10Instructions = [],
    addTop10 = [],
    addDataItems = [],
    addOpenEndedInstructions = [],
    addOpenEnded = [],
    addDetailedInstructions = [],
  } = data
  const FONT_STYLE = 'Calibri'
  const [firstDetailsreport] = addDetailedInstructions
  const {
    title: detailTitle,
    description: detailDesc,
    strGlobCompBp: globalCOm,
    strSuppCompBp: suppCOmp,
    strAdiListBp3,
    strAdiListBp4,
    strAdiListBp5,
    strAdiListBp6,
    strAdiListBp7,
  } = firstDetailsreport || {}
  const [firstSummeryCompetencyInstruction] = addSummaryCompetencyInstructions
  const [firstSummeryResult] = addSummaryResults
  const {
    title: summerySampletitle,
    description: summerySampledescription,
    addSummaryCompetencyInstructionsData = [],
  } = firstSummeryCompetencyInstruction || {}
  const {
    title: summeryresulttitle,
    description: summeryresultdescription,
    addSummaryResultsData = [],
  } = firstSummeryResult || {}

  const [ScaleDetails] = addSummaryCompetencyInstructionsData
  const {
    strAsciScaleIntro,
    strAsciListBp1,
    strAsciListBp2,
    strAsciListBp3,
    data: scaleData,
  } = ScaleDetails || {}
  const [addSummaryRespondentFirstdata] = addSummaryRespondentInstructions
  const {
    title: summeryresultinstructiontitle,
    description: summeryresultinstructiondescription,
    addSummaryRespondentInstructionsData = [],
  } = addSummaryRespondentFirstdata || {}
  const [
    firstDatarespondentinstructionContent,
  ] = addSummaryRespondentInstructionsData
  const {
    strAsriListBp1,
    strAsriListBp2,
    strAsriListBp3,
    strAsriListBp4,
    strAddNotes,
    strAsriAnListBp1,
    strAsriAnListBp2,
    strAsriAnListBp3,
  } = firstDatarespondentinstructionContent || {}
  const [firstaddCompetencyResults] = addCompetencyResults
  const {
    title: firstaddCompetencyResultstitle,
    description: firstaddCompetencyResultsdescription,
    addCompetencyResultsData = [],
  } = firstaddCompetencyResults || {}
  const [firstaddTop10Instructions] = addTop10Instructions
  const {
    title: strAddNotesttitle,
    description: strAddNotestdescription,
    ati10sDesc2,
    reportImagePath,
    strGlobCompBp,
    strSuppCompBp,
    strAtiListBp3,
    strAtiListBp4,
    strAtiListBp5,
    strAddNotes: strAddNotesfirstaddTop10Instructions,
    strAtiAnListBp1,
    strAtiAnListBp2,
    strAtiAnListBp3,
    strAtiAnListBp4,
  } = firstaddTop10Instructions || {}

  const [addMatrixFirstdata] = addMatrix
  const {
    title: martixtitle,
    description: martixdescription,
    categoryNm = [],
    scale,
    addMatrixDetail = [],

    scale: colordata,
  } = addMatrixFirstdata || {}
  const [firstaddTop10] = addTop10
  const {
    strAtTopName,
    strAtTopDesc,
    strAtBotName,
    strAtBotDesc,
    strAtGapName,
    addSummaryTopTable = [],
    addSummaryTopTableBottom = [],
    addSummaryTopTableGap = [],
    addSummaryTbResources = [],
    strAtGapDesc2,
  } = firstaddTop10 || {}
  const [firstaddDataItems] = addDataItems
  const {
    title: firstaddDataItemstitle,
    description: firstaddDataItemsdescription,
    dataItem = [],
    dataItemsCustom = [],
  } = firstaddDataItems || {}

  const [firstaddOpenEndedInstructions] = addOpenEndedInstructions
  const {
    title: openendtitle,
    description: openenddescription,
    strOeiListBp1,
    strOeiListBp2,
  } = firstaddOpenEndedInstructions || {}
  const [firstaddOpenEnded] = addOpenEnded
  const {
    title: firstaddOpenEndedtitle,
    description: firstaddOpenEndeddescription,
    addOpenEndedData,
  } = firstaddOpenEnded || {}

  const [firstvalue] = addSummaryTbResources
  const { addSummaryTbResourceTable = [], strAtRsrcHdr } = firstvalue || {}
  let marginnnone = { margin: 'unset' }
  let halfwidth = { width: '50%' }
  let boldheader = {}
  function componentToHex(c) {
    var hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
  }

  function rgbToHex(value) {
    let splitcode = value?.split(',')

    return (
      componentToHex(parseInt(splitcode[0])) +
      componentToHex(parseInt(splitcode[1])) +
      componentToHex(parseInt(splitcode[2]))
    )
  }

  const HeaderAndDescribtion = (props) => {
    const { headerTitle, headerDescrib } = props

    return (
      <>
        {' '}
        <h2 style={{ ...marginnnone }}>{headerTitle}</h2>
        <hr />
        <h4 style={{ ...marginnnone }}>{headerDescrib}</h4>
      </>
    )
  }

  const BarGraph = (props) => {
    const {
      value = 30,
      color = 'fffff',
      precent = 30,
      chartWidth = 300,
    } = props
    return (
      <svg class="chart" style={{ width: chartWidth }} height="17">
        <g transform="translate(0,0)">
          <rect
            style={{ fill: '#' + color }}
            width={precent}
            height="17"></rect>
          <text
            style={{
              fill: 'white',
              font: '10px sans-serif',
              'text-anchor': 'end',
            }}
            x="47"
            y="9.5"
            dy=".35em">
            {value}
          </text>
        </g>
      </svg>
    )
  }
  let splitAllsummery = {}
  let summarymin = 0
  let summarymax = 0
  addSummaryResultsData.forEach((summ, index) => {
    if (index == 0) {
      summarymin = summ.min
      summarymax = summ.max
    }

    if (summarymin >= summ.min) {
      summarymin = summ.min
    }
    if (summarymax <= summ.max) {
      summarymax = summ.max
    }
    splitAllsummery[summ.campName] = {
      name: summ.campName,
      data: [
        ...(splitAllsummery[summ.campName]?.data || []),
        {
          ...summ,
        },
      ],
    }
  })
  console.log(splitAllsummery, 'splitAllsummery')

  let splitmin = 0
  let splitmax = 0
  let matrixDatavalue = {}

  let headerdata = {}
  let headerColorCode = {}
  addMatrixDetail.forEach((value) => {
    headerdata[value.categoryNm] = []
    if (value.headercolor && value.headerFont) {
      headerColorCode[value.strComporCapNm] = {
        headerColor: value.headercolor || '',
        headerFont: value.headerFont,
      }
    }
    matrixDatavalue[value.strComporCapNm] = [
      ...(matrixDatavalue[value?.strComporCapNm] || []),
      value,
    ]
  })
  let splitcompetencyResult = {}
  let commin = 0
  let commax = 0
  addCompetencyResultsData.forEach((summ, index) => {
    if (index == 0) {
      commin = summ.min
      commax = summ.max
    }

    if (commin >= summ.min) {
      commin = summ.min
    }
    if (commax <= summ.max) {
      commax = summ.max
    }
    splitcompetencyResult[summ.strCompNm + summ.strCapNm] = {
      name: summ.strCompNm + summ.strCapNm,
      subname: summ.strCapNm || '',
      data: [
        ...(splitcompetencyResult[summ.strCompNm + summ.strCapNm]?.data || []),
        {
          ...summ,
        },
      ],
    }
  })
  let alldetailedResult = []
  let splitdetailedResult = {}
  dataItem.forEach((data) => {
    splitdetailedResult[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResult[data.strCompNm + data.strCapNm]?.datavalues ||
          []),
        ...data.dataItemsData,
      ],
    }
  })
  let customItem = {}
  dataItemsCustom
    // .map((va) => ({ ...va, strCompNm: 'THINKWISE HPBP FOLLOW-UP QUESTIONS' }))
    .forEach((data) => {
      splitdetailedResult[data.strCompNm + data.strCapNm] = {
        strCapNm: data.strCapNm,
        strCompNm: data.strCompNm,
        datavalues: [
          ...(splitdetailedResult[data.strCompNm + data.strCapNm]?.datavalues ||
            []),

          ...data.dataItemsCustomData.map((val) => {
            return {
              ...val,
              average: parseFloat(val.average).toFixed(2),
            }
          }),
        ],
      }
    })

  let splitdetailed = Object.values(splitdetailedResult).map((sp) => {
    let detailedObject = {}
    sp.datavalues.forEach((da, index) => {
      if (index == 0) {
        splitmin = da.min
        splitmax = da.max
      }

      if (splitmin >= da.min) {
        splitmin = da.min
      }
      if (splitmax <= da.max) {
        splitmax = da.max
      }

      detailedObject[da.newText] = {
        zCaption: da.newText,
        data: [
          ...(detailedObject[da.newText]?.data || []),
          { ...da, splitmin, splitmax },
        ],
      }
    })
    return { ...sp, datavalues: Object.values(detailedObject) }
  })

  let openendQuestions = {}

  addOpenEndedData.forEach((add) => {
    openendQuestions[add.zQuestionText] = {
      name: add.zQuestionText,
      data: [
        ...(openendQuestions[add.zQuestionText]?.data || []),
        { ...add.addOpenEndedDataSub[0] },
      ],
    }
  })
  const colorKeyStyle = {
    height: '3rem',
    width: '3rem',
    'text-align': 'center',
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
  }
  const colorTableDivstyle = {
    height: '8rem',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
  }

  let allValues = []
  let sclaesize = scaleData.length

  return (
    <html lang="en" id="sampleone">
      <head>
        <meta charSet="utf-8" />
        <title>360 Report</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"></link>
      </head>

      <body
        style={{
          'justify-content': 'center',
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Roboto',
        }}>
        <div style={halfwidth}>
          <div style={{ height: '3rem' }}> {organizationName}</div>
          <div
            style={{
              height: '20rem',
              display: 'flex',
              'align-items': 'center',
            }}>
            <div>
              <h3 style={{ ...marginnnone, 'font-weight': '100' }}>
                360 Report
              </h3>
              <h3 style={marginnnone}>{strPName}</h3>
              <h3 style={marginnnone}>{strReportDesc}</h3>
            </div>
          </div>
          <div
            style={{
              height: '10rem',
              display: 'flex',
              'justify-content': 'space-between',
            }}>
            <div>
              Project Name <br />
              <p style={{ fontWeight: '400', ...marginnnone }}>{projectName}</p>
              <br />
              Project Manager
              <br />
              <p style={{ fontWeight: '400', ...marginnnone }}>{ownerName}</p>
            </div>
            <div>
              Report Date <br />
              <p style={{ fontWeight: '400', ...marginnnone }}>{endDate}</p>
              <br />
              Project Due Date <br />
              <p style={{ fontWeight: '400', ...marginnnone }}>
                {dueDateFormat}
              </p>
            </div>
          </div>
          <div>
            <HeaderAndDescribtion
              headerTitle={summerySampletitle}
              headerDescrib={summerySampledescription}
            />
            <br></br>
            <h4 style={{ ...marginnnone }}>Scale</h4>
            <p style={{ ...marginnnone }}>{strAsciScaleIntro}</p>
            <table style={{ margin: 0, width: '100%' }}>
              <thead style={{ height: '62px', 'text-align': 'center' }}>
                {scaleData.map((v) => {
                  return (
                    <td style={{ background: '#' + rgbToHex(v.color) }}>
                      {v.zName}
                      <br />
                      {v.zDescription}
                    </td>
                  )
                })}
              </thead>
            </table>
            <img
              src={summeryImage}
              style={{ width: '100%' }}
              id="summary"
              alt="hello"
            />
            <br />
            1. {strAsciListBp1}
            <br />
            2. {strAsciListBp2}
            <br />
            3. {strAsciListBp3}
          </div>
          <LineBreak />

          <div>
            <HeaderAndDescribtion
              headerTitle={summeryresulttitle}
              headerDescrib={summeryresultdescription}
            />
            <br />
            <br />
            <br />
            <table style={{ width: '100%', background: 'gainsboro' }}>
              {Object.entries(splitAllsummery).map((split) => {
                return (
                  <>
                    <tr>
                      <th style={{ 'text-align': 'right', width: '40%' }}>
                        {split[0]}
                      </th>
                      <th></th>
                    </tr>
                    {split[1].data.map((summ) => {
                      let widthSiz = 300
                      let newSize = (summ.average / summarymax) * widthSiz

                      return (
                        <tr>
                          <td style={{ 'text-align': 'right', width: '40%' }}>
                            {summ.zCategoryN}
                          </td>
                          <td
                            style={{
                              width: widthSiz,
                              display: 'flex',
                              flexDirection: 'row',
                            }}>
                            {summ.average >= 0 ? (
                              <BarGraph
                                value={summ.average}
                                color={rgbToHex(summ?.color || '255,255,255')}
                                precent={newSize || widthSiz}
                              />
                            ) : (
                              <p
                                style={{
                                  'font-size': '8px',
                                  'font-weight': '700',
                                }}>
                                Average (excl. Self) 1 Less than 3 respondents,
                                rating included in Average'
                              </p>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </>
                )
              })}
            </table>
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={summeryresultinstructiontitle}
              headerDescrib={summeryresultinstructiondescription}
            />
            <br></br>
            <img
              src={compentencysampleImage}
              style={{ width: '100%' }}
              id="summacxcry"
              alt="hello"
            />
            <br></br>
            <br />
            <ol type="1">
              <li>{strAsriListBp1}</li>

              <li>{strAsriListBp2}</li>

              <li>{strAsriListBp3}</li>

              <li>{strAsriListBp4}</li>
            </ol>
            <strong> {strAddNotes}</strong>
            <br />
            <ul>
              <li>{strAsriAnListBp1}</li>
              <li>{strAsriAnListBp2}</li>
              <li>{strAsriAnListBp3}</li>
            </ul>
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={martixtitle}
              headerDescrib={martixdescription}
            />
            <br></br>
            <div>
              <div
                style={{
                  display: 'flex',
                  ...colorTableDivstyle,
                  width: 'max-content',
                  'flex-direction': 'column',
                  background: '#E7E9EB',
                }}>
                <div style={{ 'align-self': 'baseline' }}>Color Key</div>
                <div style={{ display: 'flex', margin: '0px 40px' }}>
                  {colordata.map((val) => {
                    return (
                      <div
                        className="littlebox"
                        style={{
                          ...colorKeyStyle,
                          background: '#' + rgbToHex(val.clr || '192,192,192'),
                        }}>
                        {val.scale}
                      </div>
                    )
                  })}
                </div>
                <div>
                  {' '}
                  * Indicates a competency with less than 3 respondents.
                  <br></br>Data is included in Average scores (see other report
                  sections).'
                </div>
              </div>
              <br></br>

              <table style={{ width: '70%', background: 'white' }}>
                <tr>
                  <th></th>
                  {Object.entries(headerdata).map((split) => {
                    return (
                      <>
                        <th style={{ width: '2.5rem' }}>
                          {' '}
                          <p
                            style={{
                              'writing-mode': 'vertical-rl',
                              'text-orientation': 'mixed',

                              margin: 0,
                            }}>
                            {' '}
                            {split}
                          </p>
                        </th>
                      </>
                    )
                  })}
                </tr>

                {Object.entries(matrixDatavalue).map((value, index) => {
                  const { headerColor, headerFont } =
                    headerColorCode[value[0]] || {}

                  return (
                    <tr
                      style={{
                        background:
                          '#' + rgbToHex(headerColor || '255,255,255'),
                      }}>
                      <td
                        style={{
                          'font-weight': headerFont ? 'bolder' : '200',
                        }}>
                        {value[0].replace('ZZZZZZZZZZZZZZZZZZZZ', '')}
                      </td>
                      {value[1].map((val) => {
                        return (
                          <td
                            style={{
                              background: '#' + rgbToHex(val.color || '0,0,0'),
                            }}>
                            {val.avg}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </table>
            </div>
            <LineBreak />
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={firstaddCompetencyResultstitle}
              headerDescrib={firstaddCompetencyResultsdescription}
            />
            <br></br>
            {Object.values(splitcompetencyResult).map((summ, index) => {
              const [, second = ''] = summ.name.split(':') || []

              return (
                <div>
                  <p> {summ.name}</p>
                  <hr />
                  <table
                    style={{
                      width: '100%',
                      background: !second ? 'gainsboro' : '#fffff',
                    }}>
                    {summ.data.map((values) => {
                      let widthSiz = 400
                      let newSize = (values.average / commax) * widthSiz
                      return (
                        <tr>
                          <td>{values.zCategoryNm}</td>
                          <td>{values.ratingCount}</td>
                          <td
                            style={{
                              width: widthSiz,
                              display: 'flex',
                              flexDirection: 'row',
                            }}>
                            {' '}
                            <BarGraph
                              value={values.average || 'N/A'}
                              color={rgbToHex(values?.color || '255,255,255')}
                              precent={newSize}
                              chartWidth={widthSiz}
                            />
                            |
                          </td>
                        </tr>
                      )
                    })}
                  </table>
                </div>
              )
            })}
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={strAddNotesttitle}
              headerDescrib={strAddNotestdescription}
            />
            <br />
            <br />
            <br />
            <p>{ati10sDesc2}</p>
            <img
              src={toptensampleImageStoreImage}
              style={{ width: '100%' }}
              id="summary"
              alt="hello"
            />
            <br />
            <ol type="1">
              <li>{strGlobCompBp}</li>

              <li>{strSuppCompBp}</li>

              <li>{strAtiListBp3}</li>

              <li>{strAtiListBp4}</li>
              <li>{strAtiListBp5}</li>
            </ol>
            <strong> {strAddNotesfirstaddTop10Instructions}</strong>
            <br />
            <ul>
              <li>{strAtiAnListBp1}</li>
              <li>{strAtiAnListBp2}</li>
              <li>{strAtiAnListBp3}</li>
              <li>{strAtiAnListBp4}</li>
            </ul>
          </div>
          <div>
            <HeaderAndDescribtion
              headerTitle={strAtTopName}
              headerDescrib={strAtTopDesc}
            />
            <br />
            <br />
            <br />
            {addSummaryTopTable.map((top) => {
              let widthSiz = 400
              let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
              let average2 =
                (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz

              return (
                <div>
                  <p>
                    {top.competencyName}|{top.capabilityName}|{top.zCaption}
                  </p>
                  <hr />
                  <table>
                    <tr>
                      <td> {top.zCompareGroupName}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.averageRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.average || 'N/A'}
                          color={rgbToHex(top?.color || '255,255,255')}
                          precent={average}
                          chartWidth={widthSiz}
                        />
                        |
                      </td>
                    </tr>
                    <tr>
                      <td>{top.zCompareGroupNameSelf}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.selfRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.selfRating || 'N/A'}
                          color={rgbToHex(top?.colorself || '255,255,255')}
                          precent={average2}
                          chartWidth={widthSiz}
                        />
                        |
                      </td>
                    </tr>
                  </table>
                  <h3>
                    <strong>{strAtRsrcHdr}</strong>
                  </h3>
                  {addSummaryTbResourceTable.map((res) => {
                    return (
                      <div>
                        {res.competencyName + '|' + res.capabilityName}{' '}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <ul>
                            <li>
                              <a
                                href={res.linkurl.replace(
                                  'Hostname',
                                  localStorage.getItem('CANTOODOMAIN')
                                )}>
                                {res.resName}
                              </a>
                              {res.zDescription}
                            </li>
                          </ul>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <div>
            <HeaderAndDescribtion
              headerTitle={strAtBotName}
              headerDescrib={strAddNotestdescription}
            />
            <br />
            <br />
            <br />
            {addSummaryTopTableBottom.map((top) => {
              let widthSiz = 400
              let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
              let average2 =
                (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz

              return (
                <div>
                  <p>
                    {top.competencyName}|{top.capabilityName}|{top.zCaption}
                  </p>
                  <hr />
                  <table>
                    <tr>
                      <td> {top.zCompareGroupName}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.averageRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.average || 'N/A'}
                          color={rgbToHex(top?.color || '255,255,255')}
                          precent={average}
                          chartWidth={widthSiz}
                        />
                        |
                      </td>
                    </tr>
                    <tr>
                      <td>{top.zCompareGroupNameSelf}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.selfRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.selfRating || 'N/A'}
                          color={rgbToHex(top?.colorself || '255,255,255')}
                          precent={average2}
                          chartWidth={widthSiz}
                        />
                        |
                      </td>
                    </tr>
                  </table>
                </div>
              )
            })}
          </div>
          <div>
            <HeaderAndDescribtion
              headerTitle={strAtGapName}
              headerDescrib={strAtGapDesc2}
            />
            <br />
            <br />
            <br />
            {addSummaryTopTableGap.map((top) => {
              let widthSiz = 400
              let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
              let average2 =
                (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz

              return (
                <div>
                  <p>
                    {top.competencyName}|{top.capabilityName}|{top.zCaption}
                  </p>
                  <hr />
                  <table>
                    <tr>
                      <td> {top.zCompareGroupName}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.averageRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.average || 'N/A'}
                          color={rgbToHex(top?.color || '255,255,255')}
                          precent={average}
                          chartWidth={widthSiz}
                        />
                        |
                      </td>
                    </tr>
                    <tr>
                      <td>{top.zCompareGroupNameSelf}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.selfRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.selfRating || 'N/A'}
                          color={rgbToHex(top?.colorself || '255,255,255')}
                          precent={average2}
                          chartWidth={widthSiz}
                        />
                        |
                      </td>
                    </tr>
                  </table>
                </div>
              )
            })}
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={detailTitle}
              headerDescrib={detailDesc}
            />
            <br />
            <br />
            <br />
            <img
              src={detailSampleimage}
              style={{ width: '100%' }}
              id="summacxcry"
              alt="hello"
            />
            <br />
            <ol type="1">
              <li>{globalCOm}</li>
              <li>{suppCOmp}</li>
              <li>{strAsriListBp3}</li>
              <li>{strAsriListBp4}</li>
              <li>{strAdiListBp3}</li>
              <li>{strAdiListBp4}</li>
              <li>{strAdiListBp5}</li>
              <li>{strAdiListBp6}</li>
              <li>{strAdiListBp7}</li>
            </ol>
          </div>

          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={firstaddDataItemstitle}
              headerDescrib={firstaddDataItemsdescription}
            />
            <br />
            <br />
            <br />
            {splitdetailed.map((spli) => {
              return (
                <div>
                  <div>{spli.strCompNm + ':' + spli.strCapNm}</div>

                  {spli.datavalues.map((da, index) => {
                    return (
                      <div>
                        <div style={{ background: 'gainsboro' }}>
                          {index + 1},{da.zCaption}
                          <hr />
                        </div>
                        <br />
                        <table>
                          {da.data.map((gr) => {
                            let widthSiz = 400
                            let newSize = (gr.average / splitmax) * widthSiz
                            return (
                              <tr>
                                <th
                                  style={{
                                    'text-align': 'right',
                                  }}>
                                  {gr.itemNorm}
                                </th>
                                <th
                                  style={{
                                    width: '3rem',
                                    'text-align': 'center',
                                  }}>
                                  {gr.ratingCount}
                                </th>
                                <th
                                  style={{
                                    width: widthSiz,
                                    display: 'flex',
                                    flexDirection: 'row',
                                  }}>
                                  <BarGraph
                                    value={gr.average || 'N/A'}
                                    color={rgbToHex(gr?.color || '255,255,255')}
                                    precent={newSize}
                                    chartWidth={widthSiz}
                                  />
                                  |
                                </th>
                              </tr>
                            )
                          })}
                        </table>
                        <br />
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={openendtitle}
              headerDescrib={openenddescription}
            />
            <br />
            <br />
            <br />
            <img
              src={openendSampleimage}
              style={{ width: '100%' }}
              id="summary"
              alt="hello"
            />
            <br />
            <ol type="1">
              <li>{strOeiListBp1}</li>
              <li>{strOeiListBp2}</li>
            </ol>
          </div>
          <LineBreak />
          <div>
            <HeaderAndDescribtion
              headerTitle={firstaddOpenEndedtitle}
              headerDescrib={firstaddOpenEndeddescription}
            />
            <br />
            <br />
            <br />
            {Object.values(openendQuestions).map((op) => {
              return (
                <div>
                  <strong>{op.name?.split('##')[0] || ''}</strong>
                  {op.data.map((indata) => {
                    return (
                      <div style={{ background: '#7489ab' }}>
                        <p>{indata.zRelationShip}</p>
                        <p>{indata.zAns}</p>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </body>
    </html>
  )
}

export default Individualreporthtml

const LineBreak = () => {
  return (
    <>
      {' '}
      <br />
      <br />
      <br />
      <br />
    </>
  )
}
