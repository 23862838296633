import { DialogContent } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../../../common/CustomButton";
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function SurveySuccess(props) {
  const classes = useStyles();

  const { open = "", handleReset } = props;

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500"
      >
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <center>
            <Typography gutterBottom>
              <h2>Congratulations!</h2>
              <p>
                You completed all of the survey items for each participant.
                Please click to below to submit
              </p>
            </Typography>
            <CustomButton
              colour="primaryButton"
              type="submit"
              onClick={handleReset}
              size="large"
            >
              Submit Survey
            </CustomButton>
          </center>
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  );
}
