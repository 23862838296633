import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import {
  FORGOT_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  DASHBOARD_PATH,
  TASK_PATH,
} from '../helpers/constants/RoutePaths'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import logo from '../assets/images/logo.png'
import {
  RESET_PASSWORD,
  GET_PARTNER_LIST_URL,
  LOGIN_URL,
  GET_USER_BY_NAME_EMAIL,
} from '../helpers/constants/ApiRoutes'
import { API } from '../helpers/API'
import { useParams } from 'react-router'
import { CustomMessage, locationParams } from '../function/HelperFunction'
import {
  USER_VERIFY_BY_LOGIN_KEY,
  GET_ALL_ORGANIZATION,
} from '../helpers/constants/ApiRoutes'
import { authHeader } from './common/CustomFunction'
//Schema
const validationSchema = Yup.object({
  newPassword: Yup.string()
    .trim()
    .required('Password is required'),
  confirmPassword: Yup.string()
    .trim()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

//state
const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f2f2',
  },
  signbg: {
    display: 'flex',
    justifyContent: 'center',
  },
  signinbtn: {
    marginTop: '1rem',
    width: '50%',
    backgroundColor: theme.btnPrimary,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.btnPrimary,
    },
  },
  title: {
    fontWeight: 600,
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  linkBlock: {
    margin: '1rem',
  },
  formBlock: {
    margin: '7px',
  },
  card: {
    boxShadow: theme.outShadow,
  },
  container: {
    // padding: "8.5rem 0 8.5rem 0",
    width: '100%',
    height: '101vh',
  },
  logoBlock: {
    width: '125px',
    height: '35px',
    marginBottom: '1.5rem',
  },
}))

export default function NewPassword() {
  const [email, setEmail] = useState('')
  const [access, setAccess] = useState('')
  const [organization, setOrganization] = useState('')
  const [organizationID, setOrganizationID] = useState(0)
  const [user, setUser] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const [domain, setDomain] = useState('')
  const [passwordReset, setResetFlag] = useState(false)
  const search = window.location.search
  const classes = useStyles()
  // const { id: paramsId } = useParams()
  var { id } = useParams()
  console.log('log key', id)
  const history = useHistory()

  const handleReset = async (formData) => {
    const config = {
      method: 'GET',
      url: RESET_PASSWORD,
      params: {
        Email: email,
        LoginKey: access,
        Password: formData.newPassword,
        ConfirmPassword: formData.newPassword,
        userID: user,
        OrgID: organizationID,
        Domain: domain,
        // zBaseUrl: "",
        // zReturnUrl: ""
      },
    }
    const response = await API(config)
    console.log(response.data)
    if (response.status) {
      handleLogin(formData.newPassword)
    }
  }

  const handleLogin = async (password) => {
    localStorage.setItem('LOG_EMAIL', email)
    const config = {
      method: 'POST',
      url: LOGIN_URL,
      data: {
        userName: email,
        password: password,
        domain: `${domain}`,
      },
    }
    try {
      const response = await API(config)
      const { status, data, token } = response.data
      if (status == 'Success') {
        const {
          zUserID = '',
          zOrganizationID = '',
          zFirstName = '',
          zLastName = '',
          zUserRoleID = '',
          zOrderID = '',
        } = data

        localStorage.setItem('AUTH_TOKEN', token)
        localStorage.setItem('CANTOODOMAIN', domain)

        localStorage.setItem('LOG_USER_ID', zUserID)
        localStorage.setItem('LOG_ORGANIZATION_ID', zOrganizationID)
        localStorage.setItem('LOG_USER_FIRSTNAME', zFirstName)
        localStorage.setItem('LOG_USER_LASTNAME', zLastName)
        localStorage.setItem('USER_ROLE_ID', zUserRoleID)
        localStorage.setItem('ORDER_ID', zOrderID)

        if (zUserRoleID != 0) {
          const config = {
            method: 'GET',
            headers: authHeader(),

            url: GET_USER_BY_NAME_EMAIL,
            params: {
              Email: email,
            },
          }
          const partnerConfig = {
            method: 'GET',
            url: GET_PARTNER_LIST_URL,
            headers: authHeader(),
          }
          const partnerResponse = await API(partnerConfig)
          const response = await API(config)
          if (response.status === 200) {
            const { data } = response.data
            const currentUser = data.find((user) => user.orderID === zOrderID)
            localStorage.setItem('USER_ADMIN_ROLE', currentUser.userAdminRole)

            //     localStorage.setItem('PARTNER', data.Partner)
            // if(formData.email === "derek.estrop@emerge-consult.com"){
            //     localStorage.setItem('USER_ADMIN_ROLE', "ThinkWise Admin")
            //     localStorage.setItem('PARTNER', data.Partner)
            // } else {
            //     localStorage.setItem('USER_ADMIN_ROLE', data.UserAdminRole)
            //     localStorage.setItem('PARTNER', data.Partner)
            // }
            if (partnerResponse.status === 200) {
              const { partnerList } = partnerResponse.data
              const partner = partnerList.find(
                (val) => val.zName === currentUser.partner
              )
              localStorage.setItem('PARTNER', partner.zPartnerID)
            }
            history.push(DASHBOARD_PATH)
          }
        }
        history.push(TASK_PATH)
        CustomMessage('Successful Login', 'success', enqueueSnackbar) //call toster
      }
    } catch (error) {
      if (error.response) {
        console.error('errrorrrr', error.response)
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
        //enqueueSnackbar('Error')
      }
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleReset(values),
  })

  const { newPassword = '', confirmPassword = '' } = values

  const getOrganization = async (valus, oID) => {
    console.log(oID)
    const config = {
      method: 'GET',
      url: GET_ALL_ORGANIZATION,
      params: {
        Email: valus,
      },
    }
    try {
      const response = await API(config)
      const { result } = response.data
      const { table1 } = result

      let or = table1.map((value) => {
        console.log(oID)
        console.log(value)
        if (oID == value.zOrganizationID) {
          console.log('value', value)
          // setOrganization(value.zOrganizationName)
          setDomain(value?.zDomain)
        }
      })
    } catch (er) {
      console.error(er)
    }
  }

  const userVerifyDetails = async (loginKey) => {
    console.log('calling')
    const config = {
      method: 'GET',
      url: USER_VERIFY_BY_LOGIN_KEY,
      params: {
        Loginkey: loginKey,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      setAccess(loginKey)
      const { data } = response.data
      setEmail(data[0].zEmail)
      console.log('email', data[0].zEmail)
      setOrganization(response.data.OrganizationName)
      setOrganizationID(data[0].zOrganizationID)
      setDomain(response.data.Domain)
      setUser(data[0].zUserID)

      //getOrganization(data[0].zEmail,data[0].zOrganizationID);
    }
  }

  useEffect(() => {
    var searchURL = locationParams(window.location.search) || {}
    if (searchURL?.ref) {
      /*setAccess(searchURL?.ref);*/
      userVerifyDetails(searchURL?.ref)
    }
  }, [search])
  useEffect(() => {
    if (id) {
      userVerifyDetails(id)
    }
  }, [id])

  return (
    <div className={classes.root}>
      {!passwordReset ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className={classes.container}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <center>
                  <div className={classes.logoBlock}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>

                  <Typography
                    className={classes.title}
                    color="subtitle2"
                    gutterBottom>
                    Set Password
                  </Typography>
                </center>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.formBlock}>
                      <TextField
                        id="email-helper-text"
                        label="Email Address"
                        value={email}
                        onChange={handleChange}
                        fullWidth
                        disabled={true}
                        name="email"
                      />
                      <TextField
                        id="email-helper-text"
                        label="Organization"
                        value={organization}
                        onChange={handleChange}
                        fullWidth
                        disabled={true}
                        name="organization"
                      />
                      <TextField
                        id="newPassword"
                        label="Password"
                        value={newPassword}
                        onChange={handleChange}
                        fullWidth
                        type="password"
                        name="newPassword"
                        error={errors.newPassword && touched.newPassword}
                        helperText={
                          errors.newPassword && touched.newPassword
                            ? errors.newPassword
                            : ''
                        }
                      />
                      <TextField
                        id="confirmPassword"
                        label="Confirm Password"
                        value={confirmPassword}
                        onChange={handleChange}
                        fullWidth
                        name="confirmPassword"
                        type="password"
                        error={
                          errors.confirmPassword && touched.confirmPassword
                        }
                        helperText={
                          errors.email && touched.confirmPassword
                            ? errors.confirmPassword
                            : ''
                        }
                      />
                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.signbg}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.signinbtn}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className={classes.container}>
          <Grid item xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <center>
                  <div className={classes.logoBlock}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>

                  <Typography
                    className={classes.title}
                    color="subtitle2"
                    gutterBottom>
                    Password added successfully
                  </Typography>
                </center>
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.formBlock}>
                    <center>
                      <div className={classes.linkBlock}>
                        <Link to={SIGN_IN_PATH} className={classes.linkStyle}>
                          Login
                        </Link>
                      </div>
                    </center>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
