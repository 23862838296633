import { Typography } from '@material-ui/core'
import React from 'react'

const SectionHeading = ({ title, bgColor }) => {
  return (
    <Typography
      variant="h6"
      id="tableTitle"
      component="div"
      style={{
        backgroundColor: bgColor ? bgColor : '#F6F6F6',
        padding: '10px',
        fontFamily: 'Roboto-Regular !important',
      }}>
      {title ? title : 'Section Heading'}
    </Typography>
  )
}

export default SectionHeading
