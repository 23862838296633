import { DialogContent } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { API } from '../../../../../helpers/API'
import {
  ADD_CATEGORY_URL,
  GET_CUSTOM_CATEGORY_URL,
  UPDATE_CATEGORY,
} from '../../../../../helpers/constants/ApiRoutes'
import {
  addCustomCategory,
  getAllOrgCustomItems,
  getAllcustomItem,
  getCustomCategory,
} from '../../../../../redux/action'
import CustomButton from '../../../../common/CustomButton'
import { useDispatch } from 'react-redux'
import { SAVE_CUSTOM_CATEGORY_LIST } from '../../../../../redux/action/ActionType'
import { authHeader, getOrganizationId, getUserId } from '../../../../common/CustomFunction'
import { useParams } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default function EditCustomCategory(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id: projectId } = useParams()

  const { open, handleReset, value = {}, updateFlag } = props
  //Schema
  // console.log("updateFlag", updateFlag )
  const validationSchema = Yup.object({
    categoryName: Yup.string()
      .trim()
      .required('Category name is required'),
    // categoryDescription: Yup.string().required(
    //   "Category description is required"
    // ),
  })

  //state
  const initialValues = {
    ...value,
  }

  const handleLogin = async (formData) => {
    const { categoryName, categoryDescription } = formData
    //console.log("formDataLogin", formData)
    const putConfig = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_CATEGORY,
      data: {
        categoryID: value.capablityid,
        name: categoryName,
        description: categoryDescription || '',
      },
    }
    try {
      const response = await API(putConfig)
      if (response && response.data) {
        // dispatch(getCustomCategory());
        dispatch(getAllcustomItem(projectId))

        await handleReset()
      }
    } catch (e) {
      console.log(e)
    }
  }

  // const getCustomCategoryList = () => {
  //   const getConfig = {
  //     method: 'GET',
  //   headers: authHeader(),

  //     url: GET_CUSTOM_CATEGORY_URL,
  //     params: {
  //       UserId: getUserId(),
  //     }
  //   }
  //   API(getConfig).then(res=> {
  //     if(res.status === 200) {
  //       const { data } = res.data;
  //       dispatch({
  //         type: SAVE_CUSTOM_CATEGORY_LIST,
  //         payload: data,
  //       })
  //     }
  //   })
  // }

  const addtoCustomCategoryList = (formData) => {
    // dispatch(addCustomCategory())
    // getCustomCategoryList()
    const { categoryName, categoryDescription } = formData

    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_CATEGORY_URL,
      data: {
        // userID: getUserId(),
        organizationID: getOrganizationId(),

        name: categoryName,
      },
    }
    API(postConfig).then((response) => {
      if (response.status === 200) {
        dispatch(getAllcustomItem(projectId))
        dispatch(getAllOrgCustomItems(projectId))

      }
    })
    setValues({
      categoryName: '',
      categoryDescription: '',
    })
    handleReset()
  }

  //Formik
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) =>
      updateFlag ? handleLogin(values) : addtoCustomCategoryList(values),
  })

  const { categoryName = '', categoryDescription = '' } = values
  const { question = '' } = values

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {updateFlag ? 'Edit Category' : 'Add category'}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={10}>
                <TextField
                  id="name-helper-text"
                  label="Category Name"
                  value={categoryName}
                  onChange={handleChange}
                  fullWidth
                  name="categoryName"
                  error={errors.categoryName && touched.categoryName}
                  helperText={
                    errors.categoryName && touched.categoryName
                      ? errors.categoryName
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={2}></Grid>

              {updateFlag ? (
                <Grid item xs={10}>
                  <TextField
                    id="designation-helper-text"
                    label="Category Description"
                    value={categoryDescription}
                    onChange={handleChange}
                    fullWidth
                    name="categoryDescription"
                    error={
                      errors.categoryDescription && touched.categoryDescription
                    }
                    helperText={
                      errors.categoryDescription && touched.categoryDescription
                        ? errors.categoryDescription
                        : ''
                    }
                  />
                </Grid>
              ) : null}
              <Grid item xs={2}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ marginTop: '1.5rem' }}>
                  Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
