import {
  DialogContent,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { IconButton, Box } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import { API } from '../../../../../../helpers/API'
import {
  getUserId,
  getAlertToast,
  authHeader,
  getOrganizationId,
} from '../../../../../common/CustomFunction'
import {
  ADD_PARTICIPANT_USER,
  ADD_PARTICIPANT,
  SAVE_RATER,
  ADD_ROSTER,
  SAVE_MANAGE_ROSTER,
  SAVE_ROSTER_RATER_EXEMAIL,
  ADD_PARTICIPANT_OR_RATER_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import Swal from 'sweetalert2'
import { CustomPageLoading } from '../../../../../../function/HelperFunction'
import CustomLoader from '../../../../../common/CustomLoader'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
}))

export default function AddParticipant(props) {
  console.log(props, 'AddParticipantAddParticipantAddParticipant')
  const classes = useStyles()
  const { submit } = props
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [remLoading, setRemLoading] = useState(false)

  const {
    open = '',
    handleReset,
    raterList,
    LoginUserID,
    projectID,
    setFlag,
  } = props

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required('Name is required'),
    lastName: Yup.string()
      .trim()
      .required('LastName is required'),
    email: Yup.string()
      .trim()
      .required('Email is required'),
  })

  const handleAddParticipantUser = async (formData) => {
    setLoading(true)
    setRemLoading(true)
    const { firstName, lastName, email } = formData

    const { projectID, LoginUserID, OrganizationID } = props

    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_ROSTER_RATER_EXEMAIL,
      data: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        LUID: LoginUserID,
        ProjectID: projectID,
        organizationID: OrganizationID,
        respondentORParticipant: 3,
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        // handleAddRaterNew(response.data)
        addParticipantUserNew(response.data)
        // await handleUpdatePartner()
      }
    } catch (error) {
      //handleReset();
      // setError('This email already in participant, Try with other email')
      setError('Participant is already on the roster, Try with other email')
    } finally {
      setLoading(false)
    }
  }
  const addParticipantUserNewwithOne = async (data) => {
    setLoading(true)
    console.log('logging', data)
    const { UserID, zUserID } = data.data[0]

    const { projectID, LoginUserID, OrganizationID } = props

    const postConfig = {
      method: 'GET',
      headers: authHeader(),

      url: ADD_PARTICIPANT_OR_RATER_URL,
      params: {
        // LUID: LoginUserID,
        ProjectID: projectID,
        UserID: zUserID,
        RespondentOrParticipant: 1,

        // organizationID,
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        handleAddRaterNew(data)
        // await handleUpdatePartner()
      }
    } catch (error) {
      //handleReset();
      // setError('This email already in participant, Try with other email')
      setError('Participant is already on the roster, Try with other email')
    } finally {
      setLoading(false)
    }
  }
  const addParticipantUserNew = async (data) => {
    setLoading(true)
    console.log('logging', data)
    // const { UserID, zUserID } = data.data[0]
    const { firstName, lastName, email, rater } = data
    const { projectID, LoginUserID, OrganizationID } = props

    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_PARTICIPANT_OR_RATER_URL,
      data: {
        // LUID: LoginUserID, ProjectID: projectID,
        UserID: parseInt(getUserId()),
        participantFirstName: firstName,
        participantLastName: lastName,
        participantEmail: email,
        participantOrRespondent: 3,
        baseURL: '' + window.location.origin + '/signin/',
        organizationID: parseInt(getOrganizationId()),
        projectID: parseInt(projectID),
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        // addParticipantUserNewwithOne(data)
        // handleAddRaterNew(data)
        // await handleUpdatePartner()
        setFlag(false)

        Swal.fire({
          title:
            "<h5 style='color:#fff'>Participant was added successfully.</h5>",
          background: '#00cc00',
        }).then((value) => {
          if (value.isConfirmed == true) {
            handleReset()
          }
          console.log('value', value)
        })
      }
    } catch (error) {
      //handleReset();
      // setError('This email already in participant, Try with other email')
      setError('Participant is already on the roster, Try with other email')
    } finally {
      setLoading(false)
    }
  }
  const handleAddRaterNew = async (data) => {
    setLoading(true)
    const { UserID, zUserID } = data.data[0]
    console.log('participant', zUserID, data.data)
    const {
      projectID,
      LoginUserID,
      ParticipantID,
      handleReset,
      getParticipantList,
    } = props
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_ROSTER,
      data: {
        userID: LoginUserID,
        ProjectID: projectID,
        participantID: zUserID,
        relationshipID: 1,
        respondentID: zUserID,
      },
    }
    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        setLoading(false)
        // Swal.fire({
        //   title: "<h5 style='color:#fff'>Participant was added successfully.</h5>",
        //   background: '#00cc00',
        // }
        // ).then((value) => {})
        // //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
        // //getParticipantList();
        // handleReset();
        handleSaveRoster()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSaveRoster = async () => {
    setLoading(true)
    const {
      projectID,
      LoginUserID,
      ParticipantID,
      handleReset,
      getParticipantList,
    } = props
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_MANAGE_ROSTER,
      data: {
        userID: '',
        projectID: projectID,
        dueDate: props.projectDetails.zDueDateFormat,
        chkSendEmail: true,
        baseURL: window.location.origin,
      },
    }
    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        setLoading(false)
        Swal.fire({
          title:
            "<h5 style='color:#fff'>Participant was added successfully.</h5>",
          background: '#00cc00',
        }).then((value) => {})
        //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
        // getParticipantList();
        handleReset()
      }
    } catch (error) {
    } finally {
      setLoading(false)
      setRemLoading(false)
    }
  }

  const handleAddParticipant = async (userId) => {
    // console.log("useIdwhile adding", userId)
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_PARTICIPANT,
      data: {
        respondentORParticipant: 2,
        projectID: projectID,
        userID: userId,
      },
    }
    const response = await API(postConfig)
    if (response.status === 200) {
      Swal.fire(getAlertToast('success', 'New user Added Successfully'))
      handleReset()
    }
  }

  //state
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    rater: '',
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => addParticipantUserNew(values),
  })

  const { firstName = '', lastName = '', email = '', rater = '' } = values

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Add Participant
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={6} className={classes.grid}>
                <TextField
                  id="firstName"
                  label="First Name:"
                  value={firstName}
                  onChange={handleChange}
                  fullWidth
                  required
                  name="firstName"
                  error={errors.firstName && touched.firstName}
                  helperText={
                    errors.firstName && touched.firstName
                      ? errors.firstName
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={6} className={classes.grid}>
                <TextField
                  id="lastName"
                  label="Last Name:"
                  value={lastName}
                  onChange={handleChange}
                  fullWidth
                  required
                  name="lastName"
                  error={errors.lastName && touched.lastName}
                  helperText={
                    errors.lastName && touched.lastName ? errors.lastName : ''
                  }
                />
              </Grid>
              <Grid item xs={6} className={classes.grid}>
                <TextField
                  id="email"
                  label="Email:"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  required
                  name="email"
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'red' }}>{error}</Typography>
              </Grid>
              <Grid item xs={4}>
                {loading && <CustomPageLoading loading={loading} />}
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={submit}
                  style={{ float: 'right' }}>
                  {/* {remLoading && <CustomLoader loading={remLoading} />
                  } */}
                  Add Participant
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
