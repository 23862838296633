import Axios from 'axios'
import TimeOutPopup from './TimeOutPopup'

const API = Axios.create()
let isTimeout = false
API.interceptors.response.use(
  (response) => {
    const currentDate = new Date()
    const oneHourInMilliseconds = 60 * 60 * 1000 // 60 minutes * 60 seconds * 1000 milliseconds
    const newDate = new Date(currentDate.getTime() + oneHourInMilliseconds)
    console.log(currentDate, 'currentDatecurrentDate', newDate)
    console.log(currentDate.getMinutes() == newDate.getMinutes(), 'newDate')
    setTimeout(() => {
      localStorage.setItem('WARNING_MSG', true)
      window.location.reload(true)
      return () => clearInterval()
    }, oneHourInMilliseconds)
    console.log('Current Date:', currentDate)
    console.log('New Date (One Hour Ahead):', newDate)

    let apitime = new Date(localStorage.getItem('API_TIME')) || new Date()
    var endDate = new Date()
    var seconds =
      (endDate.getTime() - (apitime?.getTime() || new Date())) / 1000
    console.log(seconds, 'seconds')
    // if (60 * 60 * 1000 < seconds) {
    //   console.log('doc', document.referrer)

    //   // Chkonce()
    //   // const history = useHistory()

    //   // history.go(0)
    //   localStorage.clear()
    //   // sessionStorage.clear()
    //   // sessionStorage.removeItem('AUTH_TOKEN')

    //   if (!window.location.href.includes('signin')) {
    //     window.location.replace('/signin')
    //   }
    // } else {
    //   localStorage.setItem('API_TIME', new Date())
    // }
    return response
  },
  (error) => {
    console.error('error', error)
    const { status, data } = error.response
    console.log('error1', status)
    if (status == 401) {
      isTimeout = true
      console.log('error2', status)
      localStorage.setItem('WARNING_MSG', true)
      // window.location.reload(true)

      const oneHourInMilliseconds = 60 * 60 * 1000 // 60 minutes * 60 seconds * 1000 milliseconds

      setTimeout(() => {
        localStorage.setItem('WARNING_MSG', true)
        window.location.reload(true)
        return () => clearInterval()
      }, oneHourInMilliseconds)

      return <TimeOutPopup openfl={true} />
      //   localStorage.clear()
      //   // sessionStorage.clear()
      //   localStorage.removeItem('AUTH_TOKEN')
      //   if (!window.location.href.includes('signin')) {
      //     window.location.replace('/signin')
      //   }
    }
    return Promise.reject(error)
  }
)
export { API, isTimeout }
