import React from 'react'
import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  FrameAnchorType,
  Media,
  Header,
  HeadingLevel,
  HorizontalPositionAlign,
  ImageRun,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  VerticalPositionAlign,
  PageNumber,
  WidthType,
  OverlapType,
  RelativeVerticalPosition,
  RelativeHorizontalPosition,
  TableAnchorType,
  TableLayoutType,
  TabStopType,
  TabStopPosition,
  VerticalAlign,
  HeightRule,
  convertInchesToTwip,
  LevelFormat,
} from 'docx'

import { saveAs } from 'file-saver'

import logo from '../assets/images/logo.png'
import barchart from '../assets/images/word/barchart.png'
import barchart2 from '../assets/images/word/barchart2.png'
import threesixty from '../assets/images/arrow.png'
import threesixtySurvey from '../assets/images/word/360survey.png'
import GroupReportImage from './group.json'
import { Chart } from 'chart.js'
import { generateChart } from './GenerateChart'
import group from './group.json'
async function GroupReport(reportData, scaleList) {
  // console.log(reportData)
  let strIntroScaleText =
    "The scale utilized by all respondents on all 360's was a "
  let Scale = 'Scale'
  let ScaleLength = scaleList.length
  const {
    addIntroduction360 = [],
    addOverallCompetency360 = [],
    addTopBottom2ByRatercat360 = [],
    addSuppComps360 = [],
    addBehaviorScores360 = [],
    addIndiScoresByComp360 = [],
  } = reportData
  const [threesixtyHearing] = addIntroduction360
  const [Compentency] = addOverallCompetency360
  const [toptwobottomtwo] = addTopBottom2ByRatercat360
  const {
    title: toptwoTitle = '',
    description: toptwoDescription = '',
    addTopBottom2ByRatercat360DataSub = [],
  } = toptwobottomtwo || {}

  const [firstSuportingdata] = addSuppComps360
  const {
    title: addSuppCompsTitle = '',
    description: addSuppCompsDescription = '',
    addSuppComps360Data = [],
  } = firstSuportingdata || {}
  const {
    title: competencicyTitle = '',
    description: compentencyDescription = '',
    addOverallCompetency360Datas = [],
  } = Compentency || {}
  const [firstBehavior] = addBehaviorScores360
  const [firstIndividual] = addIndiScoresByComp360
  const {
    title: individualTitle = '',
    description: individualDescription = '',
    addIndiScoresByComp360DataLastArr = [],
  } = firstIndividual || {}
  const [firstDatainlastArr] = addIndiScoresByComp360DataLastArr
  const { addIndiScoresByComp360DataLast = [] } = firstDatainlastArr || {}
  const {
    title: firstBehaviorCompsTitle = '',
    description: firstBehaviorCompsDescription = '',
    addBehaviorScores360Data = [],
  } = firstBehavior || {}
  const [firstCompentancy] = addOverallCompetency360Datas
  const { strAocHdr: firstCompentancyHeading = '' } = firstCompentancy || {}
  const {
    organizationName = '',
    projectName = '',
    ownerName = '',
    endDate = '',
    dueDateFormat = '',
    title: threesixtyTitle = '',
    description: threesixtyDescription = '',
    strIntroPartRespHdr: participantsHeading = '',
    strIntroPartCount: participanttCount = '',
    strIntroRespCount: respondentsCount = '',
    scaleIdName: staleType = '',
    scaleRatingIdDesc: scaleS = '',
    scaleRatingIdName: scaleName = '',
    strIntroScaleFtr: threNote = '',
    strIntroModelHdr: modalHeading = '',
    strIntroModelComps: modalDescription = '',
    addIntroduction360Data = [],
  } = threesixtyHearing || {}

  const FONT_STYLE = 'Calibri'
  const headerImage = await fetch(threesixty).then((response) =>
    response.blob()
  )
  const surveyLogo = await fetch(threesixtySurvey).then((response) =>
    response.blob()
  )
  const fullWidth = {
    size: 100,
    type: WidthType.PERCENTAGE,
  }

  const borderNone = {
    top: {
      style: BorderStyle.NIL,
      space: 2,
    },
    bottom: {
      style: BorderStyle.NIL,
      space: 2,
    },
    left: {
      style: BorderStyle.NIL,
      space: 2,
    },
    right: {
      style: BorderStyle.NIL,
      space: 2,
    },
  }

  const Mailpage = {
    headers: {
      default: new Header({
        children: [
          new Paragraph({
            text: organizationName,
            heading: HeadingLevel.TITLE,
            alignment: AlignmentType.LEFT,
          }),
        ],
      }),
    },
    children: [
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: surveyLogo,
                        transformation: {
                          width: 100,
                          height: 100,
                        },
                      }),
                    ],
                    verticalAlign: VerticalPositionAlign.CENTER,
                  }),
                ],
                width: {
                  size: 30,
                  type: WidthType.DXA,
                },
                borders: borderNone,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '360 Group Report',
                    heading: HeadingLevel.TITLE,
                  }),
                  new Paragraph({
                    text: projectName,
                    heading: HeadingLevel.TITLE,
                  }),
                  new Paragraph({
                    text: 'CONFIDENTIAL',
                    heading: HeadingLevel.TITLE,
                  }),
                ],
                borders: borderNone,
                verticalAlign: VerticalPositionAlign.CENTER,
              }),
            ],
          }),
        ],
        float: {
          relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
          relativeVerticalPosition: RelativeVerticalPosition.CENTER,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        layout: TableLayoutType.FIXED,
      }),
    ],
    footers: {
      default: new Footer({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Name',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: FONT_STYLE,
                            size: 29,
                            style: 'Strong',
                            text: projectName,
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                          new TextRun({
                            text: 'Report Data',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                          new TextRun({
                            text: endDate,
                            font: FONT_STYLE,
                            style: 'Strong',

                            size: 22,
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Manager',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: ownerName,

                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Due Date',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: dueDateFormat,
                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: fullWidth,
          }),
        ],
      }),
    },
  }

  const pageTtile = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 31,
          font: FONT_STYLE,
        }),
      ],
      thematicBreak: true,
    })
  }
  const leaveoneline = () => {
    return new Paragraph('')
  }
  const heading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 28,
          font: FONT_STYLE,
        }),
      ],
    })
  }
  const normalheading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 20,
          font: FONT_STYLE,
        }),
      ],
    })
  }

  const centerHeading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 30,
          font: FONT_STYLE,
        }),
      ],
      alignment: AlignmentType.CENTER,
    })
  }
  const pageHeader = () => {
    return {
      default: new Header({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: organizationName || '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['Page ', PageNumber.CURRENT],
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['360 Report ', 'Test User'],
                          }),
                          new ImageRun({
                            data: headerImage,
                            transformation: {
                              width: 30,
                              height: 30,
                            },
                          }),
                        ],

                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    verticalAlign: AlignmentType.CENTER,
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: fullWidth,
          }),
        ],
      }),
    }
  }
  const normalText = (text) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          font: FONT_STYLE,
          size: 22,
        }),
      ],
    })
  }

  const pageFooter = () => {
    return {
      default: new Footer({
        children: [
          new Paragraph({
            text: '',
          }),
        ],
      }),
    }
  }
  const normalFontHeading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          size: 30,
          font: FONT_STYLE,
        }),
      ],
    })
  }

  let boldDescribtion = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          size: 20,
          font: FONT_STYLE,
          bold: true,
        }),
      ],
    })
  }
  let scales = []
  scaleList.map((scale) => {
    scales.push(
      new TableCell({
        children: [
          new Paragraph({
            text: `${scale.zValue}`,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            text: scale.zDescription ? scale.zDescription : scale.zName,
            alignment: AlignmentType.CENTER,
          }),
        ],
        indent: {
          size: 600,
          type: WidthType.DXA,
        },
        shading: {
          fill: '7489ab',
        },
      })
    )
  })
  const scaleTable = new Table({
    rows: [
      new TableRow({
        children: [...scales],
        height: { value: 500, rule: HeightRule.ATLEAST },
      }),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  })

  const normalHeadingBold = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text?.split('##')[0] || '',
          bold: true,
          size: 25,
          font: FONT_STYLE,
        }),
      ],
      // thematicBreak: true,
    })
  }
  let threesixtyDate = []
  addIntroduction360Data.forEach((data) => {
    threesixtyDate.push(normalHeadingBold(data.competencyName))
    threesixtyDate.push(normalText(data?.capabilityName?.join(',')))
    threesixtyDate.push(leaveoneline())
  })
  const threeReport = {
    headers: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(threesixtyTitle),
      boldDescribtion(threesixtyDescription),

      leaveoneline(),
      normalFontHeading(participantsHeading),
      normalText(participanttCount + respondentsCount),
      leaveoneline(),
      normalFontHeading(Scale),
      normalText(strIntroScaleText + staleType),
      leaveoneline(),
      scaleTable,
      leaveoneline(),
      normalText(threNote),
      normalFontHeading(modalHeading),
      normalText(modalDescription),
      leaveoneline(),

      ...threesixtyDate,
    ],
  }

  // new Paragraph({
  //   children: [
  //     new TextRun({
  //       text: overall.header,
  //       font: FONT_STYLE,
  //       size: 22,
  //       alignment: AlignmentType.RIGHT,
  //     }),
  //     new TextRun({
  //       text:
  //         overall.raterCount < 3
  //           ? 'Action Orientation		Less than 3 respondents'
  //           : '',
  //       font: FONT_STYLE,
  //       size: 22,
  //       alignment: AlignmentType.LEFT,
  //     }),
  //   ],
  //   alignment: AlignmentType.CENTER,
  // })
  const table3 = (datavalues) => {
    let sizevalue =
      datavalues.raterCount >= 3
        ? (datavalues.average / (ScaleLength + 1)) * 50
        : 50

    let extradata = []
    if (datavalues.raterCount >= 3) {
      extradata.push(
        new TableCell({
          children: [],

          width: {
            size: 50 - sizevalue,
            type: WidthType.PERCENTAGE,
          },
        })
      )
    }
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(datavalues.header)],

              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                new Paragraph(
                  datavalues.raterCount >= 3
                    ? '' + datavalues.average
                    : 'Less than 3 respondents'
                ),
              ],
              shading: {
                fill: '00FF7F',
              },
              width: {
                size: sizevalue,
                type: WidthType.PERCENTAGE,
              },
            }),
            ...extradata,
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    })
  }

  let compentencyContent = []
  addOverallCompetency360Datas.forEach((overall) => {
    compentencyContent.push(table3(overall), leaveoneline())
  })
  const CompentencyReport = {
    headers: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(competencicyTitle),
      boldDescribtion(compentencyDescription),
      leaveoneline(),
      centerHeading(firstCompentancyHeading),
      ...compentencyContent,
    ],
  }
  const tableRow = (text = '', color) => {
    return new TableCell({
      children: [
        new Paragraph({
          text: text,

          alignment: AlignmentType.CENTER,
        }),
      ],
      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },
      indent: {
        size: 20,
        type: WidthType.DXA,
      },
      shading: {
        fill: color ? '303030' : 'FFFFFF',
      },
      verticalAlign: VerticalAlign.CENTER,
    })
  }

  const tableLeft = (text = '', color) => {
    return new TableCell({
      children: [
        new Paragraph({
          text: text,

          alignment: AlignmentType.LEFT,
        }),
      ],
      width: { size: 100, type: WidthType.PERCENTAGE },

      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },
      indent: {
        size: 20,
        type: WidthType.DXA,
      },
      shading: {
        fill: color ? '303030' : 'FFFFFF',
      },
      verticalAlign: VerticalAlign.CENTER,
    })
  }

  let headerToptwobottom = []
  let keyvalueS = {}
  let keyHeading = {}
  let lenghtHeader = Object.keys(keyHeading).length
  addTopBottom2ByRatercat360DataSub.forEach((keyvalue) => {
    keyHeading[keyvalue.competencyName] = {}
    keyvalueS[keyvalue.zName] = [
      ...(keyvalueS[keyvalue?.zName] || []),
      {
        name: keyvalue.zName,
        competencyName: keyvalue.competencyName,
        avg: keyvalue.avg,
      },
    ]
  })

  let tableHeading = [tableLeft('', false)]
  Object.keys(keyHeading).forEach((he) => {
    tableHeading.push(tableLeft(he, false))
    tableHeading.push(tableLeft('', false))
  })
  addTopBottom2ByRatercat360DataSub.forEach((table) => {
    headerToptwobottom.push(
      new TableRow({
        children: [tableLeft(table.zName, false)],
      })
    )
    headerToptwobottom.push(
      new TableRow({
        children: [tableLeft('', false)],
      })
    )
  })

  let allcontent = []

  Object.values(keyvalueS).forEach((contnet) => {
    let contantdata = []

    contnet.forEach((ins, index) => {
      if (index == 0) {
        contantdata.push(tableLeft(ins.name, false))
        contantdata.push(tableLeft(ins.avg, false))
      } else {
        contantdata.push(tableLeft(ins.avg, false))
      }
    })
    allcontent.push(
      new TableRow({
        children: [...contantdata],
      })
    )
    allcontent.push(
      new TableRow({
        children: [tableLeft('', false)],
      })
    )
  })

  let allBottom = {}
  addTopBottom2ByRatercat360DataSub.forEach((alldata) => {
    allBottom[alldata.zName] = {
      name: alldata.zName,
      avg: alldata.avg,
    }
  })

  let allValues = []

  Object.entries(keyvalueS).forEach((value) => {
    let innervalue = []
    console.log(value[1])
    value[1].map((agerage) => {
      innervalue.push(
        new TableCell({
          children: [
            new Paragraph({
              children: [new TextRun({ text: agerage.avg, font: FONT_STYLE })],
              alignment: AlignmentType.CENTER,
            }),
          ],
          //  shading: {
          //     fill: agerage.color ? rgbToHex(agerage.color) : 'DCDCDC',
          //  },
          width: {
            size: 40 / lenghtHeader,
            type: WidthType.PERCENTAGE,
          },
        })
      )
    })

    allValues.push(
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: value[0].replace('ZZZZZZZZZZZZZZZZZZZZ', ''),
                    font: FONT_STYLE,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
            width: {
              size: 40,
              type: WidthType.PERCENTAGE,
            },
            borders: borderNone,
          }),
          ...innervalue,
        ],
      })
    )
  })
  console.log(allValues, 'allValues')
  let finalvalue = []
  if (allValues.length) {
    finalvalue.push(
      new Table({
        rows: [...allValues],

        width: {
          size: 80,
          type: WidthType.PERCENTAGE,
        },
      })
    )
  }

  const toptwobottomtwoReport = {
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      ...[
        pageTtile(toptwoTitle),
        boldDescribtion(toptwoDescription),
        leaveoneline(),
        new Table({
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  width: {
                    size: 40,
                    type: WidthType.PERCENTAGE,
                  },
                  borders: borderNone,
                }),
                ...Object.keys(keyHeading).map((value) => {
                  return new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: value,
                            font: FONT_STYLE,
                          }),
                        ],
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    width: {
                      size: 40 / lenghtHeader,
                      type: WidthType.PERCENTAGE,
                    },
                  })
                }),
              ],
            }),
          ],

          width: {
            size: 80,
            type: WidthType.PERCENTAGE,
          },
        }),
        ...finalvalue,
      ],
      // leaveoneline(),
    ],
  }
  let chartSuportText = async (label, data, index) => {
    return new Paragraph({
      children: [
        // new TextRun({
        //   text: data.cName,
        //   bold: true,
        //   size: 20,
        //   font: FONT_STYLE,
        // }),
        new ImageRun({
          data: await generateChart(
            { label: label, data: data },
            Date.now(),
            { minimumvalue: 1, maximumvalue: ScaleLength },
            'single',
            false
          ),
          transformation: {
            width: 400,
            height: 150,
          },
        }),
      ],
      alignment: AlignmentType.CENTER,
    })
  }
  const slash = () => {
    return new TextRun({
      text: '|',
      size: 16,
      font: FONT_STYLE,
      bold: true,
      color: 'cccccc',
      allCaps: true,
    })
  }
  const compHeading = (text = '') => {
    return new TextRun({
      text: text,
      size: 20,
      font: FONT_STYLE,
      bold: true,
      color: 'cccccc',
      allCaps: true,
    })
  }
  const normalTextRun = (text) => {
    return new TextRun({
      text: `\t${text}\t`,
      size: 18,
      font: FONT_STYLE,
    })
  }
  let supplabel = []
  let suppData = []
  let chartData = []

  const suppcompsTable = (sup) => {
    let average = (sup.avg / (ScaleLength + 1)) * 40
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ text: sup.cName, size: 15 })],
                }),
              ],
              width: { size: 30, type: WidthType.PERCENTAGE },
              borders: borderNone,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ text: sup.zName, size: 15 })],
                }),
              ],
              width: { size: 30, type: WidthType.PERCENTAGE },
              borders: borderNone,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ text: sup?.avg })],
                }),
              ],
              alignment: AlignmentType.CENTER,

              shading: {
                fill: 'ADD8E6',
              },

              width: { size: average, type: WidthType.PERCENTAGE },
              // borders: borderNone,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [],
                }),
              ],

              width: { size: 40 - average, type: WidthType.PERCENTAGE },
              // borders: borderNone,
            }),
          ],
          height: { value: 400, rule: HeightRule.ATLEAST },
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    })
  }

  addSuppComps360Data.map(async (suport, index) => {
    chartData.push(suppcompsTable(suport), leaveoneline())
  })

  const supportingCompentency = {
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(addSuppCompsTitle),
      boldDescribtion(addSuppCompsDescription),
      leaveoneline(),
      ...chartData,
      // await chartSuportText(supplabel, suppData),
    ],
  }

  const behavioryTable = (datavalues) => {
    let sizevalue =
      datavalues.raterCount >= 3
        ? (datavalues.average / (ScaleLength + 1)) * 50
        : 50

    let extradata = []
    if (datavalues.raterCount >= 3) {
      extradata.push(
        new TableCell({
          children: [],

          width: {
            size: 50 - sizevalue,
            type: WidthType.PERCENTAGE,
          },
        })
      )
    }
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ text: 'Average (excl. Self)' })],
                  alignment: AlignmentType.CENTER,
                }),
              ],

              width: {
                size: 40,
                type: WidthType.PERCENTAGE,
              },
              borders: borderNone,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  children: [new TextRun({ text: '' + datavalues.raterCount })],
                  alignment: AlignmentType.CENTER,
                }),
              ],

              width: {
                size: 10,
                type: WidthType.PERCENTAGE,
              },
              borders: borderNone,
            }),
            new TableCell({
              children: [
                new Paragraph(
                  datavalues.raterCount >= 3
                    ? '' + datavalues.average
                    : 'Less than 3 respondents'
                ),
              ],
              shading: {
                fill: '00FF7F',
              },
              width: {
                size: sizevalue,
                type: WidthType.PERCENTAGE,
              },
            }),
            ...extradata,
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    })
  }

  let addBehaviorContent = []
  addBehaviorScores360Data.forEach((value) => {
    const { competencyName, capabilityName, caption, raterCount } = value
    addBehaviorContent.push(
      new Paragraph({
        children: [
          compHeading(competencyName),
          slash(),
          compHeading(capabilityName),
          slash(),
          compHeading(caption),
          leaveoneline(),
        ],
        thematicBreak: true,
      })
    )
    addBehaviorContent.push(
      leaveoneline(),
      behavioryTable(value),
      leaveoneline()
    )
  })

  const behsviorScoreReport = {
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(firstBehaviorCompsTitle),
      boldDescribtion(firstBehaviorCompsDescription),
      leaveoneline(),
      ...addBehaviorContent,
    ],
  }

  const splitName = (text = '', color) => {
    return new TableCell({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: text,
              size: 16,
              font: FONT_STYLE,
              bold: true,
              allCaps: true,
            }),
          ],
        }),
      ],
      borders: {
        top: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        bottom: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        left: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
        right: { style: BorderStyle.NONE, size: 0, color: 'FFFFFF' },
      },

      indent: {
        size: 20,
        type: WidthType.DXA,
      },
      shading: {
        fill: color ? '303030' : 'FFFFFF',
      },
      verticalAlign: VerticalAlign.CENTER,
    })
  }

  let headerHeader = []
  let bodycontent = []
  function componentToHex(c) {
    var hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
  }

  function rgbToHex(value) {
    let splitcode = value?.split(',')

    return (
      componentToHex(parseInt(splitcode[0])) +
      componentToHex(parseInt(splitcode[1])) +
      componentToHex(parseInt(splitcode[2]))
    )
  }
  const tableRowcontent = (datavalue) => {
    console.log(datavalue.colorheader)
    return new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph(datavalue.header)],
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          shading: {
            fill: !(datavalue.colorheader == 'White')
              ? rgbToHex(datavalue.colorheader)
              : 'FFFFFF',
          },
        }),
        new TableCell({
          children: [new Paragraph(datavalue?.value)],
          width: {
            size: 10,
            type: WidthType.PERCENTAGE,
          },
          shading: {
            fill: !(datavalue.colorheader == 'White')
              ? rgbToHex(datavalue.colordata)
              : 'FFFFFF',
          },
        }),
      ],
      height: { value: 500, rule: HeightRule.ATLEAST },
    })
  }

  addIndiScoresByComp360DataLast.forEach((datalast, index) => {
    bodycontent.push(tableRowcontent(datalast))
  })
  let bodyvalue = []
  if (bodycontent.length) {
    bodyvalue.push(
      new Table({
        rows: [...bodycontent],
        width: {
          size: 50,
          type: WidthType.PERCENTAGE,
        },
      })
    )
  }
  console.log(bodycontent, 'bodycontent')
  const individualReport = {
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(individualTitle),
      boldDescribtion(individualDescription),
      leaveoneline(),
      ...bodyvalue,
    ],
  }

  let allPages = []
  if (threesixtyTitle) {
    allPages.push(threeReport)
  }
  if (competencicyTitle) allPages.push(CompentencyReport)
  if (toptwoTitle) allPages.push(toptwobottomtwoReport)
  if (addSuppCompsTitle) allPages.push(supportingCompentency)
  if (firstBehaviorCompsTitle) allPages.push(behsviorScoreReport)
  if (individualTitle) allPages.push(individualReport)

  const doc = new Document({
    sections: [Mailpage, ...allPages],
  })

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, 'GroupReport.docx')
  })
}

export default GroupReport
