import { DialogContent, MenuItem, Select, FormControl } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { IconButton, Box } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import { API } from '../../../../../../helpers/API'
import {
  getUserId,
  getAlertToast,
  authHeader,
} from '../../../../../common/CustomFunction'
import { ADD_ROSTER } from '../../../../../../helpers/constants/ApiRoutes'
import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
}))

export default function AddRoster(props) {
  const classes = useStyles()
  const { submit } = props
  const {
    open = '',
    handleReset,
    raterList,
    respondantList,
    participantListNew,
    LoginUserID,
    projectID,
  } = props

  const validationSchema = Yup.object({
    participant: Yup.string()
      .trim()
      .required('Participant is required'),
    respondant: Yup.string()
      .trim()
      .required('Respondant is required'),
    rater: Yup.string()
      .trim()
      .required('Rater Relationship is required'),
  })

  const handleAddRoster = async (formData) => {
    console.log(formData)
    const { participant, respondant, rater } = formData
    const postConfig = {
      method: 'POST',
      url: ADD_ROSTER,
      headers: authHeader(),

      data: {
        participantID: participant,
        relationshipID: rater,
        respondentID: respondant,
        projectID: parseInt(projectID),
        userID: parseInt(LoginUserID),
      },
    }
    const response = await API(postConfig)
    if (response.status === 200) {
      Swal.fire(getAlertToast('success', 'Added Roster Successfully'))
      handleReset()
      // await handleUpdatePartner()
    }
  }

  //state
  const initialValues = {
    participant: '',
    respondant: '',
    rater: '',
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleAddRoster(values),
  })

  const { participant = '', respondant = '', email = '', rater = '' } = values

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Add Roster
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={6} className={classes.grid}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Participant</InputLabel>
                  <Select
                    labelId="scale-label"
                    value={participant}
                    name="participant"
                    onChange={handleChange}
                    required
                    fullWidth
                    error={errors.participant && touched.participant}
                    helperText={
                      errors.participant && touched.participant
                        ? errors.participant
                        : ''
                    }>
                    {participantListNew &&
                      participantListNew.map((record, i) => {
                        const { zUserFullName = '', zUserID = '' } = record
                        return (
                          <MenuItem value={zUserID}>{zUserFullName}</MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.grid}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Respondant</InputLabel>
                  <Select
                    labelId="scale-label"
                    value={respondant}
                    name="respondant"
                    onChange={handleChange}
                    required
                    fullWidth
                    error={errors.respondant && touched.respondant}
                    helperText={
                      errors.respondant && touched.respondant
                        ? errors.respondant
                        : ''
                    }>
                    {respondantList &&
                      respondantList.map((record, i) => {
                        const { zUserFullName = '', zUserID = '' } = record
                        return (
                          <MenuItem value={zUserID}>{zUserFullName}</MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.grid}>
                <FormControl className={classes.formControl}>
                  <InputLabel>Rater Relationship</InputLabel>
                  <Select
                    labelId="scale-label"
                    value={rater}
                    name="rater"
                    required
                    onChange={handleChange}
                    fullWidth
                    error={errors.rater && touched.rater}
                    helperText={
                      errors.rater && touched.rater ? errors.rater : ''
                    }>
                    {raterList &&
                      raterList.map((record, i) => {
                        const { zName = '', zRelationshipID = '' } = record
                        return (
                          <MenuItem value={zRelationshipID}>{zName}</MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={submit}
                  style={{ float: 'right' }}>
                  Add Roster
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
