import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputLabel,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import {
  FORGOT_PASSWORD_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
} from '../helpers/constants/RoutePaths'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import logo from '../assets/images/logo.png'
import {
  FORGOT_PASSWORD,
  GET_ALL_ORGANIZATION,
} from '../helpers/constants/ApiRoutes'
import { API } from '../helpers/API'
import { authHeader } from './common/CustomFunction'
import TranslateComp from '../helpers/constants/TranslateComp'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useEffect } from 'react'

//Schema
const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .required('Email is required')
    .email('Give valid email'),
  domain: Yup.string()
    .trim()
    .required('Domain is required'),
})

//state
const initialValues = {
  email: '',
  domain: '',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f2f2',
  },
  signinbtn: {
    marginTop: '1rem',
    width: '100%',
    backgroundColor: theme.btnPrimary,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.btnPrimary,
    },
  },
  title: {
    fontWeight: 600,
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  linkBlock: {
    margin: '1rem',
  },
  formBlock: {
    margin: '7px',
  },
  card: {
    boxShadow: theme.outShadow,
  },

  container: {
    padding: '8.5rem 0 8.5rem 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
  logoBlock: {
    width: '125px',
    height: '35px',
    marginBottom: '1.5rem',
  },
}))

export default function ForgotPassword() {
  const classes = useStyles()
  const [passwordReset, setResetFlag] = useState(false)
  const [orgalizationId, setorgalizationId] = useState(0)
  const [organizationList, setorganizationList] = useState([])
  const [orgName, setorgName] = useState('')

  const getOrganization = async (newData) => {
    const config = {
      method: 'GET',
      url: GET_ALL_ORGANIZATION,
      params: {
        // Name: value,
        Email: values.email,
        // count: 20,
      },
    }
    try {
      const response = await API(config)
      console.log(response)
      const { result } = response.data
      const { table1 } = result
      let or = table1.map((value) => ({
        label: value.zOrganizationName,
        title: value.zOrganizationName,
        domain: value?.zDomain,
      }))
      setorganizationList(or)
    } catch (er) {
      console.error(er)
    }
  }

  const organiZationhandle = (e) => {
    // organizationSearch(e.target.value, values)
  }
  const organizationSelect = (e, value) => {
    setValues((val) => ({ ...val, domain: value?.domain || '' }))
    setorgName(value.title)
  }

  const handleLogin = async (formData) => {
    const config = {
      method: 'POST',
      url: FORGOT_PASSWORD,
      data: {
        email: formData.email,
        domain: formData.domain,
        zBaseUrl: '' + window.location.origin + '/reset-password/',
        // zBaseUrl: "",
        // zReturnUrl: ""
      },
    }
    const response = await API(config)
    if (response.status) {
      setResetFlag(true)
      localStorage.setItem('FORGOT_EMAIL', formData.email)
    }
  }

  //Formik
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const { email = '', domain = '' } = values
  const handleBlur = async () => {
    getOrganization()
  }
  useEffect(() => {
    if (email) {
      // getOrganization()
    }
  }, [email])
  return (
    <>
      <div className={classes.root}>
        {/* <div id="google_translate_element"></div> */}
        <TranslateComp />
        {!passwordReset ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className={classes.container}>
            {/* <Grid item lg={4} md={4} sm={6} xs={12}>
              <InputLabel>Translate</InputLabel>
              <div id="google_translate_element"></div>
            </Grid> */}
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <Card className={classes.card}>
                <CardContent>
                  <center>
                    <div className={classes.logoBlock}>
                      <img
                        src={logo}
                        alt="logo"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>

                    <Typography
                      className={classes.title}
                      color="subtitle2"
                      gutterBottom>
                      Forgot Password
                    </Typography>
                  </center>
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} className={classes.formBlock}>
                        <TextField
                          id="email-helper-text"
                          label="Email Address"
                          value={email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          name="email"
                          error={errors.email && touched.email}
                          helperText={
                            errors.email && touched.email ? errors.email : ''
                          }
                        />

                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={organizationList}
                          onChange={organizationSelect}
                          // disabled={true}

                          getOptionLabel={(option) => option.title}
                          // defaultValue={top100Films[13]}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                error={errors.domain && touched.domain}
                                onChange={organiZationhandle}
                                variant="standard"
                                // error={true}
                                label="Select organization"
                                placeholder="Select organization"
                                helperText={
                                  errors.domain && touched.domain
                                    ? errors.domain
                                    : ''
                                }
                              />
                            </>
                          )}
                        />

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.signinbtn}>
                          Send Password Reset
                        </Button>
                        <center>
                          <div className={classes.linkBlock}>
                            <Link
                              to={SIGN_IN_PATH}
                              className={classes.linkStyle}>
                              Back to Login Page
                            </Link>
                          </div>
                        </center>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className={classes.container}>
            <Grid item xs={3}>
              <Card className={classes.card}>
                <CardContent>
                  <center>
                    <div className={classes.logoBlock}>
                      <img
                        src={logo}
                        alt="logo"
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>

                    <Typography
                      className={classes.title}
                      color="subtitle2"
                      gutterBottom>
                      Password Reset Link Sent
                    </Typography>
                  </center>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.formBlock}>
                      Please check your email and click on the link to reset
                      your password
                      <center>
                        <div className={classes.linkBlock}>
                          <Link to={SIGN_IN_PATH} className={classes.linkStyle}>
                            Back to Login Page
                          </Link>
                        </div>
                      </center>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        )}
      </div>
    </>
  )
}
