import { DialogContent } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { API } from '../../../../../helpers/API'
import { SAVE_OPEN_ENDED_QUESTION_URL } from '../../../../../helpers/constants/ApiRoutes'
import CustomButton from '../../../../common/CustomButton'
import Swal from 'sweetalert2'
import { authHeader, getUserId } from '../../../../common/CustomFunction'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default function EditOpenEndedQuestion(props) {
  const classes = useStyles()

  const {
    open,
    handleReset,
    editID = '',
    submitCallBack,
    editQuestion = '',
    editprojectId = '',
  } = props
  const [loading, setLoading] = useState(false)

  //Schema
  const validationSchema = Yup.object({
    question: Yup.string()
      .trim()
      .required('Question is required'),
  })

  //state
  const initialValues = {
    question: editQuestion,
  }

  const saveEditedQuestion = async (values) => {
    setLoading(true)
    const addconfig = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_OPEN_ENDED_QUESTION_URL,
      data: {
        questionText: values.question,
        userId: getUserId(),
        projectTypeId: 2,
        projectID: parseInt(editprojectId),
        oldOpenEndedQuestionID: parseInt(editID),
      },
    }
    try {
      const response = await API(addconfig)
      const { status } = response.data
      if (status == 'Success') {
        // Swal.fire('Success', 'Question updated successfully')
        submitCallBack()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => saveEditedQuestion(values),
  })

  const { question = '' } = values
  const getEditQuestion = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: '',
      params: {
        ProjectID: parseInt(23193),
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getEditQuestion()
  }, [])
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="300">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Edit Open Ended Question
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
              <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
                <Grid item xs={10} className={classes.formBlock}>
                  <TextField
                    id="name-helper-text"
                    value={question}
                    onChange={handleChange}
                    fullWidth
                    name="question"
                    error={errors.question && touched.question}
                    helperText={
                      errors.question && touched.question ? errors.question : ''
                    }
                    label="Open Ended Question:"
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomButton
                    colour="primaryButton"
                    type="submit"
                    onClick={handleSubmit}
                    style={{ marginTop: '1.5rem' }}>
                    Save
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </Typography>
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
