import { React, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Workbook from 'react-excel-workbook'
import {
  Grid,
  ListItem,
  ListItemText,
  List,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
  Button,
} from '@material-ui/core'
import CustomButton from '../common/CustomButton'
import {
  GET_TEAM_PROJECT_STATUS_REPORT,
  GET_TEAM_RESPOTANT,
} from '../../helpers/constants/ApiRoutes'
import { useParams } from 'react-router-dom'
import { authHeader, getUserId } from '../common/CustomFunction'
import { API } from '../../helpers/API'
import Tsmemberlist from './Tsmemberlist'
import { TEAMSURVEYREPORT } from '../../helpers/constants/RoutePaths'
import { CustomMessage } from '../../function/HelperFunction'
import { useSnackbar } from 'notistack'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  selected: {
    fontFamily: 'Roboto-Bold !important',
  },
  unselected: {
    fontFamily: 'Roboto-Regular !important',
  },
  listContainer: {
    padding: '0px',
  },
  buttona: {
    background: '#64B5F6 !important',
  },
}))
export default function Tsreport(props) {
  const { projectDetails } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [memberList, setmemberList] = useState([])

  const [reposrData, setreposrData] = useState({})
  let { tsprojectid } = useParams()

  const getProjectStatusReport = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_PROJECT_STATUS_REPORT,
      params: {
        projectID: tsprojectid,
      },
    }

    try {
      const response = await API(getconfig)

      if (response) {
        setreposrData(response.data)
      }
    } catch (e) {
      console.log('error')
    }
  }
  const ProjectSummaryreport = (props) => {
    const { reposrData } = props

    return (
      <Workbook
        filename={reposrData.filename}
        element={
          <CustomButton className={classes.buttona} colour="primaryButton">
            Download
          </CustomButton>
        }>
        {/* <Workbook.Sheet data={data1} name="Individual Scores All Scores">
        <Workbook.Column label="Foo" value="foo"/>
        <Workbook.Column label="Bar" value="bar"/>
      </Workbook.Sheet> */}
        <Workbook.Sheet
          data={reposrData.statusReport}
          name="Project status report">
          <Workbook.Column label="Email" value="email" />
          <Workbook.Column label="First name" value="first Name" />
          <Workbook.Column label="Last name" value="last Name" />
          {/* <Workbook.Column label="" value='zInd'/> */}
          <Workbook.Column label="Task status" value="task Status" />
          <Workbook.Column label="Url" value="url" />
        </Workbook.Sheet>
      </Workbook>
    )
  }
  const getteamRespotant = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_RESPOTANT,
      params: {
        ProjectID: tsprojectid,
        UserId: getUserId(),
        tab: 'Reports',
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        // setresponantList(data.TabList)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const successCall = () => {
    getAdmintab()
  }

  const getAdmintab = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_RESPOTANT,
      params: {
        ProjectID: tsprojectid,
        UserId: getUserId(),
        tab: 'Reports',
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setmemberList(data.TabList)
        console.log(data)
      }
    } catch (e) {
      console.log(e)
      if (e.response) {
        let { data } = e.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
      }
    }
  }

  useEffect(() => {
    getAdmintab()
  }, [])

  useEffect(() => {
    getProjectStatusReport()
    getteamRespotant()
  }, [])
  let reportViewLink

  const viewReport = (e) => {
    reportViewLink =
      window.location.origin +
      TEAMSURVEYREPORT +
      '/' +
      Buffer.from(
        JSON.stringify({
          ProjectID: tsprojectid,
        }),
        'binary'
      ).toString('base64')
    window.open(
      window.location.origin +
        TEAMSURVEYREPORT +
        '/' +
        Buffer.from(
          JSON.stringify({
            ProjectID: tsprojectid,
          }),
          'binary'
        ).toString('base64')
    )
  }
  return (
    <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
      <Grid item xs={3}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}>
          <Tab
            className={!(value == 0) ? classes.unselected : classes.selected}
            label="Project Reports"
            {...a11yProps(0)}
          />
          <Tab
            className={!(value == 1) ? classes.unselected : classes.selected}
            label="Team Survey Report"
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      <Grid item xs={9}>
        <TabPanel value={value} index={0}>
          {' '}
          <Grid>
            <Grid xs={7}>
              <List component="nav" aria-label="secondary mailbox folder">
                <ListItem>
                  <ListItemText className={classes.unselected}>
                    Project Status Report
                  </ListItemText>
                  <ProjectSummaryreport reposrData={reposrData} />
                </ListItem>
                <Divider />
              </List>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={12}>
              <TabPanel value={1} index={1}>
                <Grid xs={7}>
                  <List component="nav" aria-label="secondary mailbox folder">
                    <ListItem>
                      <ListItemText className={classes.unselected}>
                        Team Survey Report
                      </ListItemText>
                      <Button
                        // data-userid={row.zUserID}
                        onClick={viewReport}
                        variant="contained"
                        color="primary">
                        View Report
                      </Button>
                    </ListItem>
                    <Divider />
                  </List>
                </Grid>
                <Tsmemberlist
                  memberList={memberList}
                  successCall={successCall}
                  projectDetails={projectDetails}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}
