import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { Button, Radio } from '@material-ui/core'
import {
  TEAM_CHANGE_TEAMMEMBER,
  TEAM_SEND_ALL_REPORT,
  TEAM_SEND_TEAMREPORT,
  USER_REPORT_INSERT,
} from '../../helpers/constants/ApiRoutes'
import {
  authHeader,
  getAlertToast,
  getOrganizationname,
  getUserId,
} from '../common/CustomFunction'
import { API } from '../../helpers/API'
import { useParams } from 'react-router-dom'
import {
  TEAMSURVEYREPORT,
  THREESIXTYREPORT,
} from '../../helpers/constants/RoutePaths'
import Swal from 'sweetalert2'
import { FormControl, MenuItem, Select } from '@mui/material'
import moment from 'moment'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
]

export default function Tsmemberlist(props) {
  const classes = useStyles()
  const { memberList, successCall, projectDetails } = props
  let { tsprojectid } = useParams()
  console.log('project', projectDetails, projectDetails.projectName)
  const handleChange = async (e) => {
    //TEAM_CHANGE_TEAMMEMBER
    let id = e.target.name

    let updateValue = memberList.find((va) => va.zUserID == id)
    console.log(updateValue)
    if (!updateValue) {
      return null
    }
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_CHANGE_TEAMMEMBER,
      data: {
        FirstName: updateValue.zFirstName,
        LastName: updateValue.zLastName,
        EmailId: updateValue.zEmail,
        OrganizationName: getOrganizationname(),
        ProjectID: tsprojectid,
        UserID: id,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        successCall()
        console.log(data)
      }
    } catch (e) {
      console.log(e)
    }
  }
  let reportViewLink
  const viewReport = (e) => {
    const { userid } = e.currentTarget.dataset
    reportViewLink =
      window.location.origin +
      TEAMSURVEYREPORT +
      '/' +
      Buffer.from(
        JSON.stringify({
          ProjectID: tsprojectid,
          UserID: userid,
        }),
        'binary'
      ).toString('base64')
    window.open(
      window.location.origin +
        TEAMSURVEYREPORT +
        '/' +
        Buffer.from(
          JSON.stringify({
            ProjectID: tsprojectid,
            UserID: userid,
          }),
          'binary'
        ).toString('base64')
    )
  }
  const sendToAlluser = async () => {
    console.log('list', memberList)
    let nlist = memberList.map((r) => {
      return {
        reportURL:
          window.location.origin +
          TEAMSURVEYREPORT +
          '/' +
          Buffer.from(
            JSON.stringify({
              ProjectID: tsprojectid,
              UserID: r.zUserID,
            }),
            'binary'
          ).toString('base64'),
        projectID: tsprojectid,
        zUserID: r.zUserID,
        ProjectTypeID: '7',
      }
    })
    console.log('nlist', nlist)

    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_SEND_ALL_REPORT,
      data: {
        teamSurveyUserList: nlist,
      },
    }

    try {
      const response = await API(getconfig)
      const { status } = response.data
      if (status == 'Success') {
        Swal.fire(getAlertToast('success', 'Reports sent successfully'))
      }
    } catch (e) {
      Swal.fire(getAlertToast('error', 'Error'))
    }
  }
  const sendReportAll = (e) => {
    // const { userid } = e.currentTarget.dataset
    Swal.fire({
      title: 'Are you want to send report?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        sendToAlluser()
      }
    })
  }
  const sendReport = (e) => {
    const { userid } = e.currentTarget.dataset
    Swal.fire({
      title: 'Are you want to send report?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        sendTouser(userid)
      }
    })
  }
  const sendTouser = async (userid) => {
    let reportlink =
      TEAMSURVEYREPORT +
      '/' +
      Buffer.from(
        JSON.stringify({
          ProjectID: tsprojectid,
          UserID: userid,
        }),
        'binary'
      ).toString('base64')
    let reportLink =
      window.location.origin +
      TEAMSURVEYREPORT +
      '/' +
      Buffer.from(
        JSON.stringify({
          ProjectID: tsprojectid,
          UserID: userid,
        }),
        'binary'
      ).toString('base64')

    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_SEND_TEAMREPORT,
      data: {
        projectID: tsprojectid,
        zUserID: userid,
        reportURL: reportLink,
        ProjectTypeID: '7',
      },
    }
    const config1 = {
      method: 'POST',
      headers: authHeader(),
      url: USER_REPORT_INSERT,
      data: {
        reportName: projectDetails.zName,
        reportType: 'Team project',
        reportDate: moment(new Date()).format('YYYY-MM-DD'),
        projectID: parseInt(tsprojectid),
        userID: parseInt(userid),
        luid: parseInt(getUserId()),
        reportLink: reportlink,
      },
    }
    try {
      const response = await API(getconfig)
      const { status } = response.data
      if (status == 'Success') {
        const response1 = await API(config1)

        Swal.fire(getAlertToast('success', 'Report sent successfully'))
      }
    } catch (e) {
      Swal.fire(getAlertToast('error', 'Error'))
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <br />
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell style={{ minWidth: '102px' }}>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>
              <Button
                onClick={sendReportAll}
                style={{
                  backgroundColor: '#009b62',
                  color: '#FFF',
                  minWidth: '150px !important',
                }}
                className="teambtn"
                variant="contained">
                Send to team
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {memberList.map((row) => (
            <TableRow key={row.name}>
              <TableCell>{row.zFirstName}</TableCell>
              <TableCell>{row.zLastName}</TableCell>
              <TableCell>{row.zEmail}</TableCell>
              <TableCell>
                {' '}
                <TableCell>
                  {' '}
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      data-id={row.zUserID}
                      name={row.zUserID}
                      value={row.zTeamLeader ? 'true' : 'false'}
                      onChange={handleChange}>
                      <MenuItem value={'true'}>Team leader</MenuItem>
                      <MenuItem value={'false'}>Member</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              </TableCell>
              <TableCell>
                {/* <Button
                  data-userid={row.zUserID}
                  onClick={viewReport}
                  variant="contained"
                  color="primary">
                  View Report
                </Button> */}
                {'   '}
                <Button
                  style={{ minWidth: '150px' }}
                  data-userid={row.zUserID}
                  onClick={sendReport}
                  variant="contained"
                  color="primary">
                  Send Report
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
