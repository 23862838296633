import React, { useEffect, useState, memo } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@material-ui/core'
import arrayMove from 'array-move'
import OpenWith from '@material-ui/icons/OpenWith'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import CustomButton from '../../../../common/CustomButton'
import ViewSurveyDetails from './ProjectView/ViewSurveyDetails'
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { useSelector, useDispatch } from 'react-redux'
import {
  SAVE_CURRENT_STEP,
  SELECTED_SUB_CATEGORY,
  SELECTED_SUB_ITEMS,
  API_SELECTED_ITEMS,
  GET_ALL_SELECTED_ITEM,
  DRAGGED_ITEM,
} from '../../../../../redux/action/ActionType'
import { ADD_PROJECT_ITEMS } from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import Swal from 'sweetalert2'
import { CustomMessage } from '../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import { authHeader } from '../../../../common/CustomFunction'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  accHeadSelected: {
    backgroundColor: '#64B5F6',
    color: '#000',
    height: '64px',
    '& .MuiSvgIcon-root': {
      color: '#000',
      // backgroundColor:"#000"
    },
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
    height: '64px',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemSelectedStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#1565C0',
      color: '#ffffff',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1565C0',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#ffff',
      },
      '& $iconStyle': {
        color: '#ffffff',
      },
    },
  },
}))

const DragHandler = sortableHandle(() => (
  <div>
    <span style={{ cursor: 'grab', marginRight: '10px' }}>
      <OpenWith />
    </span>
  </div>
))

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>
})

const CheckboxItem = sortableElement(({ value, label, itemId }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
      <DragHandler />
      <Typography variant="p">{label}</Typography>
    </div>
  )
})

const CategoryItems = ({ categoryId, items, handleSortItem }) => {
  const CheckedItemList = useSelector((state) => state.CheckedItemList)
  const changeSortOrder = ({ oldIndex, newIndex }) => {
    handleSortItem(oldIndex, newIndex)
  }
  return (
    <SortableContainer
      onSortEnd={changeSortOrder}
      useDragHandle
      helperClass="SortableHelper"
      distance={20}>
      {items &&
        items.map((value, index) => {
          if (
            CheckedItemList.find(
              (CheckedItem) =>
                CheckedItem.subCategoryId === 0 &&
                CheckedItem.categoryId === categoryId &&
                value &&
                value.zItemID === CheckedItem.itemId
            )
          ) {
            console.log(value)
            return (
              <CheckboxItem
                key={`checkbox_item${index}`}
                index={index}
                itemId={index}
                value={value.zItemID}
                label={value.zCaption}
              />
            )
          }
        })}
    </SortableContainer>
  )
}

const AccordianInnerItem = sortableElement(
  ({ item, items, classes, value, handleSortItem }) => {
    const changeSortOrder = ({ oldIndex, newIndex }) => {
      handleSortItem(oldIndex, newIndex, item.zCapabilityID)
    }

    return (
      <>
        <Accordion
          key={`acc_inside_${value}`}
          style={{ margin: '0 -14px 0.5rem -15px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`item_inside_${value}-content`}
            id={`item_inside_${value}-header`}
            className={classes.accHeadInside}>
            <DragHandler />
            <Typography className={classes.heading}>{item.zName}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>
              {item.zDescription}
            </Typography>

            <SortableContainer
              onSortEnd={changeSortOrder}
              useDragHandle
              helperClass="SortableHelper"
              distance={20}>
              {items &&
                items.map((value, index) => {
                  if (value && value.zCapabilityID === item.zCapabilityID) {
                    return (
                      <CheckboxItem
                        key={`checkbox_item${index}`}
                        index={index}
                        itemId={index}
                        value={value.zItemID}
                        label={value.zCaption}
                      />
                    )
                  }
                })}
            </SortableContainer>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
)

const AccordianItem = sortableElement(
  ({
    items,
    itemName,
    accordianId,
    subcategory,
    classes,
    value,
    handleSortInnerAccordian,
    handleSortItem,
  }) => {
    const { zCompetencyID, zName, zDescription } = value

    const changeSortOrder = (e) => {
      handleSortInnerAccordian(zCompetencyID, e.oldIndex, e.newIndex)
    }

    return (
      <Accordion key={`acc_${value}`} className={classes.acc}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls={`item_${zCompetencyID}-content`}
          id={`item_${zCompetencyID}-header`}
          className={classes.accHead}>
          <DragHandler />
          <Typography className={classes.heading}>{zName}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <Typography className={classes.accDesc}>{zDescription}</Typography>
          <CategoryItems
            categoryId={zCompetencyID}
            items={items}
            handleSortItem={(oldIndex, newIndex) =>
              handleSortItem(oldIndex, newIndex)
            }
          />

          <SortableContainer
            onSortEnd={(e) => changeSortOrder(e)}
            useDragHandle
            distance={20}
            helperClass="SortableHelper"
            // onSortStart={handleSortStart}
          >
            {subcategory &&
              subcategory.map((itemValue, index) => {
                if (itemValue.zCompetencyID === value.zCompetencyID) {
                  return (
                    <AccordianInnerItem
                      key={`item-${index}`}
                      index={index}
                      value={index}
                      item={itemValue}
                      items={items}
                      classes={classes}
                      handleSortItem={(oldIndex, newIndex, innerAccordian) =>
                        handleSortItem(
                          oldIndex,
                          newIndex,
                          innerAccordian,
                          zCompetencyID
                        )
                      }
                    />
                  )
                }
              })}
          </SortableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }
)

function SelectedItems(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const Selectedcustomcategory = useSelector((state) => state.Selectedcategory)
  const ThinkwiseCustomselectedlist = useSelector(
    (state) => state.ThinkwiseCustomselectedlist
  )

  let allSelecteditem = [
    ...ThinkwiseCustomselectedlist,
    ...Selectedcustomcategory,
  ]

  const Selectedcateg = useSelector((state) => state.Dragableselecteditem)
  let Selectedcategory = [...Selectedcateg]
  const Subcategoryselecteditem = useSelector(
    (state) => state.Subcategoryselecteditem
  )
  const SelectedSubitems = useSelector((state) => state.SelectedSubitems)
  const currentStep = useSelector((state) => state.CurrentStep)

  const CheckedItemList = useSelector((state) => state.CheckedItemList)

  const handleSaveProjectItems = async () => {
    let itemList = []
    Selectedcategory.forEach((cat) => {
      console.log('Selectedcategory', Selectedcategory)
      //  const currentItems = items.filter(item=> item.zCompetencyID === cat.zCompetencyID)

      const currentItems = SelectedSubitems.filter((item) =>
        CheckedItemList.find(
          (checkedItem) =>
            checkedItem.subCategoryId === 0 &&
            checkedItem.categoryId === cat.zCompetencyID &&
            checkedItem.itemId === item.zItemID
        )
      )

      if (currentItems) {
        currentItems.map((item) => {
          itemList.push(item.zItemID)
        })
      }

      Subcategoryselecteditem.forEach((sub) => {
        if (sub.zCompetencyID === cat.zCompetencyID) {
          const currentItems = SelectedSubitems.filter(
            (item) => item.zCapabilityID === sub.zCapabilityID
          )
          if (currentItems) {
            currentItems.map((item) => {
              itemList.push(item.zItemID)
            })
          }
        }
      })
    })
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')

    const postConfig = {
      method: 'POST',
      url: ADD_PROJECT_ITEMS,
      headers: authHeader(),

      data: {
        projectID: parseInt(arr[3]),
        itemIdList: CheckedItemList.map((val) => val.itemId),
      },
    }
    const response = await API(postConfig)
    if (response && response.status === 200) {
      //Swal.fire('Success', response.data.message)
      CustomMessage(response.data.message, 'success', enqueueSnackbar)
      dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
    }
  }
  const [survey, setSurvey] = useState(false)

  const surveyPreview = () => {
    setSurvey(!survey)
  }

  const handleSortStart = ({ node }) => {
    const tds = document.getElementsByClassName('SortableHelper')[0].childNodes

    node.childNodes.forEach(
      (node, idx) => (tds[idx].style.width = `${node.offsetWidth}px`)
    )
  }

  //Change Sort Order
  const changeSortOrder = ({ oldIndex, newIndex }) => {
    let temp = [...Selectedcategory]
    temp = arrayMove(temp, oldIndex, newIndex)
    // console.log(temp)
    dispatch({ type: DRAGGED_ITEM, payload: temp })

    // setCategory(temp)
    let itemList = []
    temp.forEach((cat) => {
      //  const currentItems = items.filter(item=> item.zCompetencyID === cat.zCompetencyID)
      const currentItems = SelectedSubitems.filter((item) =>
        CheckedItemList.find(
          (checkedItem) =>
            checkedItem.subCategoryId === 0 &&
            checkedItem.categoryId === cat.zCompetencyID &&
            checkedItem.itemId === item.zItemID
        )
      )
      if (currentItems) {
        currentItems.map((item) => {
          itemList.push(item)
        })
      }

      Subcategoryselecteditem.forEach((sub) => {
        if (sub.zCompetencyID === cat.zCompetencyID) {
          const currentItems = SelectedSubitems.filter(
            (item) => item.zCapabilityID === sub.zCapabilityID
          )
          if (currentItems) {
            currentItems.map((item) => {
              itemList.push(item)
            })
          }
        }
      })
    })
    dispatch({ type: SELECTED_SUB_ITEMS, payload: itemList })

    // setItems(itemList)
    //  setSelectItemList(temp);
    // dispatch({type: SAVE_SELECTED_ITEMS, payload:temp})
  }

  const handleSortInnerAccordian = (value, oldIndex, newIndex) => {
    let temp = Subcategoryselecteditem
    temp = arrayMove(temp, oldIndex, newIndex)
    // setSubCategory(temp)

    dispatch({ type: SELECTED_SUB_CATEGORY, payload: temp })
    let itemList = []
    Selectedcategory.forEach((cat) => {
      //  const currentItems = items.filter(item=> item.zCompetencyID === cat.zCompetencyID)
      const currentItems = SelectedSubitems.filter((item) =>
        CheckedItemList.find(
          (checkedItem) =>
            checkedItem.subCategoryId === 0 &&
            checkedItem.categoryId === cat.zCompetencyID &&
            checkedItem.itemId === item.zItemID
        )
      )
      if (currentItems) {
        currentItems.map((item) => {
          itemList.push(item)
        })
      }
      temp.forEach((sub) => {
        if (sub.zCompetencyID === cat.zCompetencyID) {
          const currentItems = SelectedSubitems.filter(
            (item) => item.zCapabilityID === sub.zCapabilityID
          )
          if (currentItems) {
            currentItems.map((item) => {
              itemList.push(item)
            })
          }
        }
      })
    })
    dispatch({ type: SELECTED_SUB_ITEMS, payload: itemList })

    // setItems(itemList)
    // let currentAccordian = value
    // let array = SeletedItems
    // const resultArray = []

    // array.forEach((arrayElement) => {
    //   if (arrayElement.zCompetencyID == Number(currentAccordian)) {
    //     let sortArray = arrayElement.subCategory
    //     sortArray = arrayMove(sortArray, oldIndex, newIndex)
    //     resultArray.push({ ...arrayElement, subCategory: sortArray })
    //   } else {
    //     resultArray.push(arrayElement)
    //   }
    // })
    // // setSelectItemList(resultArray)
    // dispatch({type: SAVE_SELECTED_ITEMS, payload:resultArray})
  }
  console.log('selected sub items', SelectedSubitems)
  const handleSortItem = (oldIndex, newIndex, subCategoryId, expanded) => {
    let temp = SelectedSubitems
    temp = arrayMove(temp, oldIndex, newIndex)

    dispatch({ type: SELECTED_SUB_ITEMS, payload: temp })

    // setItems(temp)
  }
  return (
    <form
      noValidate
      autoComplete="off"
      id="form_1"
      onSubmit={(e) => {
        e.preventDefault()
        handleSaveProjectItems(e)
      }}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        style={{ marginTop: '2px' }}>
        <Grid item xs={3}>
          <CustomButton
            colour="primaryButton"
            style={{ marginTop: '1.2rem' }}
            onClick={surveyPreview}>
            Preview Survey
          </CustomButton>
        </Grid>
        <Grid item xs={9}>
          <p>
            Total Items Selected : <b>{SelectedSubitems.length}</b>
          </p>
          <SortableContainer
            onSortEnd={changeSortOrder}
            useDragHandle
            distance={20}
            helperClass="SortableHelper"
            onSortStart={handleSortStart}>
            {Selectedcategory.map((item, index) => {
              return (
                <AccordianItem
                  key={`item-${index}`}
                  index={index}
                  value={item}
                  classes={classes}
                  subcategory={Subcategoryselecteditem}
                  items={SelectedSubitems}
                  handleSortInnerAccordian={(value, oldIndex, newIndex) =>
                    handleSortInnerAccordian(value, oldIndex, newIndex)
                  }
                  handleSortItem={(oldIndex, newIndex, value1, value2) =>
                    handleSortItem(oldIndex, newIndex, value1, value2)
                  }
                />
              )
            })}
          </SortableContainer>
        </Grid>
        {survey && (
          <ViewSurveyDetails
            open={survey}
            category={Selectedcategory}
            subCategory={Subcategoryselecteditem}
            items={SelectedSubitems}
            handleReset={surveyPreview}
            title={'Preview Survey'}
          />
        )}
      </Grid>
    </form>
  )
}
export default memo(SelectedItems)
