import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import {
  NEW_DELETE_THINKWISE,
  NEW_SELECTED_THINKWISE_ID,
  NEW_SELECTED_THINKWISE_ITEMS_ALL_ID,
  NEW_SELECTED_THINKWISE_ITEMS_ID,
  NEW_SELECT_THINKWISE_ITEM,
} from '../../../../../redux/action/ActionType'
import {
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from '../../../../../redux/action/Typeconstent'
import { CustomPageLoading } from '../../../../../function/HelperFunction'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    fontFamily: 'Nunito-Regular !important',

    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  accHeadSelected: {
    fontFamily: 'Nunito-Regular !important',

    backgroundColor: '#64B5F6',
    color: '#000',
    height: '64px',
    '& .MuiSvgIcon-root': {
      color: '#000',
      // backgroundColor:"#000"
    },
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
    height: '64px',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemSelectedStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  listItemStyle: {
    fontFamily: 'Nunito-Regular !important',

    '&.Mui-selected': {
      backgroundColor: '#1565C0 !important',
      color: '#ffffff',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1565C0',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#ffff',
      },
      '& $iconStyle': {
        color: '#ffffff',
      },
    },
  },
}))
function Newthinkswiseitem(props) {
  const { loading } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const NewthinkwiseReducer = useSelector((state) => state.NewthinkwiseReducer)
  const NewthinkwiseSelecteditemReducer = useSelector(
    (state) => state.NewthinkwiseSelecteditemReducer
  )
  let allId = NewthinkwiseReducer.map((v) => v.compentencyid)
  const NewselectedThinkwiseItems = useSelector(
    (s) => s.NewselectedThinkwiseItems
  )

  let thinkWiseSelectedItems = []
  Object.values(NewselectedThinkwiseItems).forEach((value) => {
    Object.values(value).forEach((v) => {
      thinkWiseSelectedItems.push(...v)
    })
  })
  const handleListItemClick = (e) => {
    const { id } = e.currentTarget.dataset
    let selectedid = [...NewthinkwiseSelecteditemReducer]

    if (selectedid.includes(id)) {
      let removedid = selectedid.filter((item) => item !== id)
      dispatch({ type: NEW_DELETE_THINKWISE, payload: { compentencyid: id } })
      dispatch({
        type: NEW_SELECTED_THINKWISE_ID,
        payload: removedid,
      })
    } else {
      selectedid.push(id)
      dispatch({
        type: NEW_SELECTED_THINKWISE_ID,
        payload: selectedid,
      })
    }
  }

  const selectAllItems = (e) => {
    const { checked } = e.target

    if (checked) {
      dispatch({
        type: NEW_SELECTED_THINKWISE_ID,
        payload: allId,
      })
    } else {
      allId.forEach((v) =>
        dispatch({
          type: NEW_DELETE_THINKWISE,
          payload: { compentencyid: v },
        })
      )

      dispatch({
        type: NEW_SELECTED_THINKWISE_ID,
        payload: [],
      })
    }
  }
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      style={{ marginTop: '2px' }}>
      <Grid item xs={3}>
        <List component="nav" aria-label="secondary mailbox folder">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="all"
                // value={selectAll}
                checked={
                  NewthinkwiseSelecteditemReducer.length != 0
                    ? NewthinkwiseSelecteditemReducer.length ==
                      NewthinkwiseReducer.length
                    : false
                }
                onClick={selectAllItems}
              />
            }
            label="Select All"
          />

          {NewthinkwiseReducer.map((category, index) => {
            let valueCheck =
              NewselectedThinkwiseItems[
                THINKWISE_CATEGORY + category.compentencyid
              ] || {}
            return (
              <ListItem
                key={`list_item_${index}`}
                button
                selected={NewthinkwiseSelecteditemReducer.includes(
                  category.compentencyid
                )}
                data-id={category.compentencyid}
                //   selected={selectItemfield.includes(category.zCompetencyID)}
                onClick={handleListItemClick}
                className={`${
                  Object.keys(valueCheck).length
                    ? classes.listItemSelectedStyle
                    : classes.listItemStyle
                }`}>
                <ListItemText primary={category.compentencyname} />
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item xs={9}>
        <p>
          ThinkWise Items Selected : <b>{thinkWiseSelectedItems.length}</b>
        </p>
        {loading && <CustomPageLoading loading={loading} />}
        {NewthinkwiseReducer.map((category, index) => {
          if (
            NewthinkwiseSelecteditemReducer.includes(category.compentencyid)
          ) {
            return (
              <AccordianContainer
                key={`item-${index}`}
                category={category}
                classes={classes}
                // selectedCategory={selectedCategory}
                // handleItem={(e, capabilityId) => handleItem(e, capabilityId, index)}
              />
            )
          }
        })}
      </Grid>
    </Grid>
  )
}

export default Newthinkswiseitem

const AccordianContainer = ({
  category,
  subcategory,

  // items,
  classes,
}) => {
  const NewselectedThinkwiseItems = useSelector(
    (v) => v.NewselectedThinkwiseItems
  )
  let valueCheck =
    NewselectedThinkwiseItems[THINKWISE_CATEGORY + category.compentencyid] || {}
  return (
    <Accordion className={classes.acc}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: true ? '#000' : '#fff',
            }}
          />
        }
        aria-controls={`item_${'value'}-content`}
        id={`item_${'value'}-header`}
        className={`${
          Object.keys(valueCheck).length
            ? classes.accHeadSelected
            : classes.accHead
        }`}>
        <Typography className={classes.heading}>
          {category?.compentencyname}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accDetail}>
        <Typography className={classes.accDesc}>
          {category.zDescription}
        </Typography>

        {category.capablitylist.map((subCategory, index) => {
          return (
            <InnerAccordianContainer
              subCategory={subCategory}
              category={category}
              classes={classes}
              key={`item-${index}`}
            />
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}
const InnerAccordianContainer = ({
  competencyId,
  subCategory,
  classes,
  category,
  // value,
  // items,
  // handleItem,
}) => {
  const dispatch = useDispatch()
  let allItem = subCategory.itemslist.map((valu) => valu.itemid)
  const NewselectedThinkwiseItems = useSelector(
    (s) => s.NewselectedThinkwiseItems
  )
  let CATEGORYNAME = THINKWISE_CATEGORY + category.compentencyid
  let SUBCATEGORYNAME = THINKWISE_SUB_CATEGORY + subCategory.capablityid
  let selectedId =
    NewselectedThinkwiseItems[CATEGORYNAME]?.[SUBCATEGORYNAME] || []

  const selectAllItems = (e) => {
    const { compentencyid, capabilityid } = e.currentTarget.dataset
    console.log(compentencyid, capabilityid)
    const { checked } = e.target
    console.log('chk item', allItem)
    if (checked) {
      dispatch({
        type: NEW_SELECTED_THINKWISE_ITEMS_ALL_ID,
        payload: { allItem, compentencyid, capabilityid },
      })
    } else {
      dispatch({
        type: NEW_SELECTED_THINKWISE_ITEMS_ALL_ID,
        payload: { allItem: [], compentencyid, capabilityid },
      })
    }
  }

  const thinkwiseSelectItem = (e) => {
    const { compentencyid, id, capabilityid } = e.currentTarget.dataset
    dispatch({
      type: NEW_SELECTED_THINKWISE_ITEMS_ID,
      payload: { compentencyid, id, capabilityid },
    })
  }

  return (
    <Accordion
      key={`acc_inside_${1}`}
      style={{ margin: '0 -14px 0.5rem -15px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`item_inside_${'value'}-content`}
        id={`item_inside_${'value'}-header`}
        className={classes.accHeadInside}>
        <Typography className={classes.heading}>
          {' '}
          {subCategory.capablityname}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accDetail}>
        {/* <Typography className={classes.accDesc}>{'zDescription'}</Typography> */}
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  inputProps={{
                    'data-compentencyid': `${category.compentencyid}`,
                    'data-capabilityid': `${subCategory.capablityid}`,
                  }}
                  onChange={selectAllItems}
                  checked={selectedId.length == subCategory.itemslist.length}
                  name="all"
                />
              }
              label="All"
            />
            {subCategory.itemslist.map((item) => {
              return (
                <FormControlLabel
                  key={item.itemid}
                  control={
                    <Checkbox
                      color="primary"
                      name="jason"
                      checked={selectedId.includes('' + item.itemid)}
                      key={item.itemid}
                      onChange={thinkwiseSelectItem}
                      inputProps={{
                        'data-id': `${item.itemid}`,
                        'data-compentencyid': `${category.compentencyid}`,
                        'data-capabilityid': `${subCategory.capablityid}`,
                      }}
                    />
                  }
                  label={item.itemname}
                />
              )
            })}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  )
}
