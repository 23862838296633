import * as types from '../action/ActionType'
import { THINKWISEINITIAL_STORE } from '../store/IntialStore'
export default function ThinkwiseSelectcategory(
  state = THINKWISEINITIAL_STORE,
  action
) {
  const { type, payload } = action
  switch (type) {
    case types.SELECT_THINKWISE_ITEM:
      return [...state, payload]

    case types.DELETE_THINKWISE_ITEM:
      let newState = [...state]
      let updates = newState.filter((item) => item.zCompetencyID != payload)
      return (state = updates)
    case types.ADD_ALL_THINKWISE_ITEM:
      return (state = payload)
    case types.UNCHECK_ALL_THINKWISE_ITEM:
      return (state = [])
    case types.API_DATA_ITEM:
      return (state = payload)

    default:
      return state
  }
}
