import React, { Fragment, useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import ListItems from './ListItems'
import TimeOutPopup from '../../helpers/TimeOutPopup'
import { API, isTimeout } from '../../helpers/API'

export default function Sidebar(props) {
  const history = useHistory()
  //Redux State

  // Current Active Menu
  const currentMenuFromUrl = window.location.pathname.split('/')[1] || '/'
  const currentMenuToActive =
    currentMenuFromUrl === '/' ? 'dashboard' : currentMenuFromUrl
  const { classes } = props

  // Hide drawer if it is mobile view
  const handleDrawerDependsOnScreen = (event) => {
    const { route_path } = event.currentTarget.dataset
    history.push(route_path)
  }
  const [check, setCheck] = useState(false)
  useEffect(() => {
    setCheck(localStorage.getItem('WARNING_MSG'))
  }, [currentMenuFromUrl])
  console.log('check', check)
  useEffect(() => {
    if (isTimeout) {
      setTimeout(() => {
        localStorage.setItem('WARNING_MSG', true)
        window.location.reload(true)
      }, 60 * 60 * 1000) // 1 hour timeout
    }
  }, [])
  return (
    <Fragment>
      {check && <TimeOutPopup />}
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper),
        }}>
        <div style={{ background: '#fff' }}>
          <List className={classes.menuSection}>
            <ListItems
              activeMenu={currentMenuToActive}
              handleDrawerDependsOnScreen={handleDrawerDependsOnScreen}
            />
          </List>
        </div>
        {/* #rbp Menu List */}
      </Drawer>
    </Fragment>
  )
}
