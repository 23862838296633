import {
  AlignmentType,
  BorderStyle,
  Document,
  Footer,
  FrameAnchorType,
  Media,
  Header,
  HeadingLevel,
  HorizontalPositionAlign,
  ImageRun,
  Packer,
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  VerticalPositionAlign,
  PageNumber,
  WidthType,
  OverlapType,
  RelativeVerticalPosition,
  RelativeHorizontalPosition,
  TableAnchorType,
  TableLayoutType,
} from 'docx'

import { saveAs } from 'file-saver'

import logo from '../assets/images/logo.png'
import barchart from '../assets/images/word/barchart.png'
import barchart2 from '../assets/images/word/barchart2.png'
import threesixty from '../assets/images/arrow.png'
import threesixtySurvey from '../assets/images/word/360survey.png'
import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'

export const handleGenerate = async (
  data,
  projectDetails,
  scaleList,
  gapvalue,
  reportSections,
  ParticipantName
) => {
  const { CompItemScores, OpenEnded, OpenEndedLoop } = data
  console.log('reportSections', reportSections)
  console.log('reportSectionstype', typeof reportSections)

  const borderNone = {
    top: {
      style: BorderStyle.NIL,
      space: 2,
    },
    bottom: {
      style: BorderStyle.NIL,
      space: 2,
    },
    left: {
      style: BorderStyle.NIL,
      space: 2,
    },
    right: {
      style: BorderStyle.NIL,
      space: 2,
    },
  }

  const fullWidth = {
    size: 100,
    type: WidthType.PERCENTAGE,
  }

  const {
    zOrganizationName = '',
    zOwnerName = '',
    zName = '',
    zDueDateFormat = '',
  } = projectDetails
  console.log(projectDetails)
  const headerImage = await fetch(threesixty).then((response) =>
    response.blob()
  )
  const surveyLogo = await fetch(threesixtySurvey).then((response) =>
    response.blob()
  )
  const img = await fetch(logo).then((response) => response.blob())
  const img1 = await fetch(barchart).then((response) => response.blob())
  const img2 = await fetch(barchart2).then((response) => response.blob())

  const competecySet = [0, 1, 2, 3]

  let tableRows = [
    new TableRow({
      children: [
        new TableCell({ children: [] }),
        new TableCell({
          children: [new Paragraph({ text: 'self' })],
        }),
      ],
    }),
  ]

  competecySet.map((comp) => {
    tableRows.push(
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'Action Orientation',
              }),
            ],
            shading: {
              fill: 'cfcfcf',
            },
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: '1',
                alignment: AlignmentType.CENTER,
              }),
            ],
            shading: {
              fill: 'd9c300',
            },
          }),
        ],
      })
    )
  })

  const table = new Table({
    rows: [
      ...tableRows,
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'Achievement Orientation',
              }),
            ],
          }),
          new TableCell({
            children: [
              new Paragraph({
                text: '2',
                alignment: AlignmentType.CENTER,
              }),
            ],
            shading: {
              fill: 'd9c300',
            },
          }),
        ],
      }),
    ],
    width: {
      size: 6000,
      type: WidthType.DXA,
    },
  })

  const table2 = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({ text: 'ACTION ORIENTATION' })],
            shading: {
              fill: 'cfcfcf',
            },
            borders: borderNone,
            columnSpan: 3,
          }),
          new TableCell({ children: [], borders: borderNone }),
          new TableCell({ children: [], borders: borderNone }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'Organizational Average',
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [
              new Paragraph({ text: '5', alignment: AlignmentType.CENTER }),
            ],
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [new Paragraph({ text: '3' })],
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'Project Average',
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [
              new Paragraph({ text: '3', alignment: AlignmentType.CENTER }),
            ],
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [new Paragraph({ text: '3' })],
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({ text: 'Self', alignment: AlignmentType.RIGHT }),
            ],
            borders: borderNone,
          }),
          new TableCell({
            children: [
              new Paragraph({ text: '1', alignment: AlignmentType.CENTER }),
            ],
            borders: borderNone,
          }),
          new TableCell({
            children: [new Paragraph({ text: '3' })],
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
        ],
      }),
    ],
  })

  const table3 = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({ text: 'ACTION ORIENTATION' })],
            shading: {
              fill: 'cfcfcf',
            },
            borders: borderNone,
            columnSpan: 3,
          }),
          new TableCell({ children: [], borders: borderNone }),
          new TableCell({ children: [], borders: borderNone }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: '1.	Seeks out and takes the lead on major initiatives.',
              }),
            ],
            borders: borderNone,
            columnSpan: 3,
          }),
          new TableCell({ children: [], borders: borderNone }),
          new TableCell({ children: [], borders: borderNone }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'Organizational Average',
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [
              new Paragraph({ text: '5', alignment: AlignmentType.CENTER }),
            ],
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [new Paragraph({ text: '3' })],
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                text: 'Project Average',
                alignment: AlignmentType.RIGHT,
              }),
            ],
            width: {
              size: 4000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [
              new Paragraph({ text: '3', alignment: AlignmentType.CENTER }),
            ],
            width: {
              size: 2000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
          new TableCell({
            children: [new Paragraph({ text: '3' })],
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({ text: 'Self', alignment: AlignmentType.RIGHT }),
            ],
            borders: borderNone,
          }),
          new TableCell({
            children: [
              new Paragraph({ text: '1', alignment: AlignmentType.CENTER }),
            ],
            borders: borderNone,
          }),
          new TableCell({
            children: [new Paragraph({ text: '3' })],
            width: {
              size: 6000,
              type: WidthType.DXA,
            },
            borders: borderNone,
          }),
        ],
      }),
    ],
  })

  let scales = []
  scaleList.map((scale) => {
    scales.push(
      new TableCell({
        children: [
          new Paragraph({
            text: `${scale.zValue}`,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            text: scale.zDescription ? scale.zDescription : scale.zName,
            alignment: AlignmentType.CENTER,
          }),
        ],
        indent: {
          size: 600,
          type: WidthType.DXA,
        },
        shading: {
          fill: '7489ab',
        },
      })
    )
  })

  const scaleTable = new Table({
    rows: [
      new TableRow({
        children: [...scales],
      }),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  })

  //console.log('ParticipantName',ParticipantName);

  var documents = []

  for (let i = 0; i < ParticipantName.length; i++) {
    var headerItems = {
      headers: {
        default: new Header({
          children: [
            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: zOrganizationName || zName,
                        }),
                      ],
                      borders: borderNone,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              children: ['Page ', PageNumber.CURRENT],
                            }),
                          ],
                          alignment: AlignmentType.RIGHT,
                        }),
                      ],
                      borders: borderNone,
                    }),
                  ],
                }),
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: '',
                        }),
                      ],
                      borders: borderNone,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new TextRun({
                              children: ['360 Report ', ParticipantName[i]],
                            }),
                            new ImageRun({
                              data: headerImage,
                              transformation: {
                                width: 30,
                                height: 30,
                              },
                            }),
                          ],

                          alignment: AlignmentType.RIGHT,
                        }),
                      ],
                      verticalAlign: AlignmentType.CENTER,
                      borders: borderNone,
                    }),
                  ],
                }),
              ],
              width: fullWidth,
            }),
          ],
        }),
      },
      footers: {
        default: new Footer({
          children: [
            new Paragraph({
              text: '',
            }),
          ],
        }),
      },
    }

    if (gapvalue == 'The 10s Section') {
      var size = 10
    } else {
      var size = 5
    }

    var commenItmes = [
      {
        ...headerItems,
        children: [
          new Paragraph({
            text: 'Summary: Top ' + size + '',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Below are the individual items/questions with the highest average score. The related global and supporting competency is shown before the question. The Self rating is shown for comparison.',
          }),
        ],
      },
      {
        children: [
          new Paragraph({
            text: 'Summary: Bottom ' + size + '',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Below are the individual items/questions with the lowest average score. The related global and supporting competency is shown before the question. The Self rating is shown for comparison.',
          }),
        ],
      },
      {
        children: [
          new Paragraph({
            text: 'Summary: ' + size + ' Biggest Gaps',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Below are the individual items/questions with the biggest gap between the average score and the self-rating. The related global and supporting competency is shown before the question.',
          }),
          new Paragraph({
            text: '',
          }),
          new Paragraph({
            text:
              'NOTE: Data is not available for ' +
              size +
              ' Biggest Gaps page due to no Self ratings.',
          }),
        ],
      },
    ]

    var overviewItems = [
      {
        ...headerItems,
        children: [
          new Paragraph({
            text: '360 Top 5, Bottom 5, 5 Biggest Gaps',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'This section of the summary report provides a convenient way for you to see those individual items that were rated highest (Top 5) and lowest (Bottom 5) by the entire group of respondents as well as those items that had the largest gap between your own rating and the average rating from others (5 Biggest Gaps). On each page you will find your own rating for that item as a useful point of reference.',
          }),
          new Paragraph({
            text:
              'As you review this section of the report, look for patterns in the competencies from which the items originate. Are the top or bottom items consistent with the overall competency scores? Or are they anomalies? As with all the results, the focus should not be on the individual items and ratings, but on the overall patterns that help you see yourself more accurately and most importantly help guide your development efforts and career planning.',
          }),

          // image here
          new Paragraph({
            text:
              '1.	Global Competency that the individual item (behavior statement) is related.',
          }),
          new Paragraph({
            text:
              '2.	Supporting Competency that the individual item is related.',
          }),
          new Paragraph({
            text:
              '3.	The individual item (behavior statement) that is noted due to its high, low, or biggest gap score.',
          }),
          new Paragraph({
            text:
              '4.	Each item listed shows the overall Average score (excluding your own Self rating) from all respondents as well as your own Self score.',
          }),
          new Paragraph({
            text:
              '5.	Reflects the number of ratings included in the Average (excl. Self) score. This number can change from item to item if respondents answered an item with a response of N/A (N/A scores are not counted or averaged into your score).',
          }),
          new Paragraph({
            text: 'Additional Detailed Notes:',
            heading: HeadingLevel.HEADING_3,
          }),
          new Paragraph({
            text:
              'The Top 5 page starts with the highest rated item at the top.',
            bullet: {
              level: 0,
            },
          }),
          new Paragraph({
            text:
              'The Bottom 5 page starts with the lowest rated item at the top.',
            bullet: {
              level: 0,
            },
          }),
          new Paragraph({
            text:
              'The 5 Biggest Gaps page starts with the item that has the largest gap between your Self-rating and the Average (excl. Self) rating – regardless of whether the Self-rating is higher or lower than the Average (excl. Self).',
            bullet: {
              level: 0,
            },
          }),
          new Paragraph({
            text:
              'All pages are limited to 5 items. If there is a tie score for several items, items are shown in alphabetical order and the report is cut off after the 5th item regardless of whether there are additional items with the same score.',
            bullet: {
              level: 0,
            },
          }),
        ],
      },
    ]

    var OpenEndedItems = [
      {
        ...headerItems,
        children: [
          new Paragraph({
            text: 'Detailed Item Results',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'The Detailed Item section provides you with the complete set of individual items for all the competencies rated. Results are organized by competency and show the scores/averages for all the respondent categories. While the various summary sections of the report provide a high-level view to help you identify patterns, this section of the report is useful in reviewing the detailed ratings for individual items (behaviors) as rated by the various respondent categories',
          }),
          // img here
          new Paragraph({
            text:
              '1.	Global Competency that the individual item (behavior statement) is related.',
          }),
          new Paragraph({
            text:
              '2.	Supporting Competency that the individual item is related.',
          }),
          new Paragraph({
            text:
              '3.	The individual item (behavior statement) that respondents rated.',
          }),
          new Paragraph({
            text:
              '4.	Average scores/ratings by respondent category. The Project Average reflects all ratings provided to all participants included in the survey. Average (excl. Self) reflects all those respondents who rated you. If included, the Organizational Average, reflects the cumulative average rating throughout the organization (average of all 360’s administered).',
          }),
          new Paragraph({
            text:
              '5.	Reflects the number of ratings included in the calculated scores. This number can change from item to item if respondents answered an item with a response of N/A (N/A scores are not counted or averaged into your score).',
          }),
          new Paragraph({
            text:
              '6.	Score and bar represent the average rating for the corresponding category of respondents. The small gray triangles show the lowest and highest rating. This provides you with information regarding whether there was a wide range of scores you received from respondents, or if the ratings were similar in range. For categories with only 1 respondent, there is no triangle noted.',
          }),
          new Paragraph({
            text:
              '7.	When categories (other than Self or Manager categories) have less than 3 responses, the survey will not show that data to protect the anonymity of the respondents. The data is however included in the Average (excl. Self) scores.',
          }),
        ],
      },

      {
        children: [
          new Paragraph({
            text: 'Detail Item Results – by Respondent Category',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Below are the individual items (with related competency noted) with scores by respondent category. The highest and lowest ratings received are noted by the gray arrows. The number of ratings for each category is shown to the left of the score and bars.',
          }),
          new Paragraph(''),
        ],
      },
      {
        children: [
          new Paragraph({
            text: 'Open-Ended Comments',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'The Open Ended Comments are the written responses to open questions (e.g. that do not utilize a scale rating). These open ended questions were selected/created by the project administrator and were presented to all respondents. Answering the open ended questions is not mandatory – you may notice the number of responses is less than the total number of respondents. Answers provided by yourself and your manager are labeled, while all other answers are randomized to protect anonymity.',
          }),
          // img here
          new Paragraph({
            text:
              '1.	Open ended question. These questions were selected/created by the survey administrator.',
          }),
          new Paragraph({
            text:
              '2.	Responses to open ended questions. The order of the responses are randomized to protect anonymity (exception: any answers provided by yourself and your manager are labeled appropriately).',
          }),
        ],
      },
      {
        children: [
          new Paragraph({
            text: 'Open-Ended Comments',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Responses have been presented in random order for each question to protect anonymity.',
          }),
        ],
      },
    ]

    var summaryItems = [
      {
        ...headerItems,
        children: [
          new Paragraph({
            text: 'Summary Results – By Competency',
            heading: HeadingLevel.HEADING_1,
            underline: {
              type: UnderlineType.SINGLE,
              color: '990011',
            },
          }),
          new Paragraph({
            text:
              'Below are the global competencies for the survey. The two bars show the overall average (excluding the self-rating) and the self-rating. Subsequent summary pages show the overall average scores for global and supporting competencies (if applicable) by respondent category.',
          }),
        ],
      },
      {
        children: [
          new Paragraph({
            text: 'Summary Results – By Respondent Category',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'The Summary Results by Respondent Category section of your 360 Report takes you one step deeper into your scores, providing a summary view of average scores at the Competency and Supporting Competency levels for all respondent categories included in your survey. Both the Grid view and the Bar view allow you to compare your own Self scores to those average scores provided by the various respondents (e.g. Direct Report, Manager, Peer, etc.). Use this section of your report to see differences (or gaps) in your own scores and those from others.',
          }),
          new Paragraph({
            children: [
              new ImageRun({
                data: img2,
                transformation: {
                  width: 600,
                  height: 300,
                },
              }),
            ],
          }),
          new Paragraph({
            text:
              '1.	Competency (sometimes called Global Competency) included in survey.',
          }),
          new Paragraph({
            text:
              '2.	Supporting Competencies (sometimes called Capabilities or Sub-Competencies) related to the Competency.',
          }),
          new Paragraph({
            text: '3.	Respondent categories included in your survey.',
          }),
          new Paragraph({
            text:
              '4.	The average scores from all items rated for the associated competency or supporting competency. Color coding is based on score from the lowest (red) to the highest (deep green).',
          }),
          new Paragraph({
            text: 'Additional Detailed Notes:',
            heading: HeadingLevel.HEADING_2,
          }),
          new Paragraph({
            text:
              'The 360 survey requires at least 3 respondents to show scores (with the exception of Self and Manager categories) – this protects the anonymity of the respondents and ensures candid feedback. If there are less than 3 responses, the survey will not show the results (note that the scores are included in the overall Average (excl. Self) figures).',
            bullet: {
              level: 0,
            },
          }),
          new Paragraph({
            text:
              'Note that some 360 surveys are setup without Supporting Competencies.',
            bullet: {
              level: 0,
            },
          }),
          new Paragraph({
            text:
              'N/A shown in the Grid View indicates that a respondent (Self or Manager) replied N/A to all items within a competency.',
            bullet: {
              level: 0,
            },
          }),
        ],
      },

      {
        children: [
          new Paragraph({
            text: 'Summary Results – By Respondent Category',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Below is a color-coded grid (see color key below that corresponds to the scores) with average scores by competency for all respondent categories. Each respondent category allows you to see differences between your Self score and average respondent scores for each category. Look for large gaps and patterns to help identify areas of potential disconnect.',
          }),
          new Paragraph({ text: 'Color Key' }),
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '*',
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    shading: {
                      fill: '880aa8',
                    },
                    width: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '1',
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    shading: {
                      fill: 'ff5500',
                    },
                    width: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '2',
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    shading: {
                      fill: 'ffd900',
                    },
                    width: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                    height: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '3',
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    shading: {
                      fill: '880aa8',
                    },
                    width: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                    height: {
                      size: 1000,
                      type: WidthType.DXA,
                    },
                  }),
                ],

                // rule: HeightRule
              }),
            ],
            // width: {
            //   size: 9000,
            //   type: WidthType.DXA,
            // },
          }),
          new Paragraph({
            spacing: {
              after: 200,
            },
          }),
          table,
        ],
      },

      {
        children: [
          new Paragraph({
            text: 'Summary Competency Results – by Respondent Category',
            heading: HeadingLevel.HEADING_1,
          }),
          new Paragraph({
            text:
              'Below are the global (highlighted with gray background) and supporting competencies with overall average scores by respondent category. The number of ratings per category is listed',
          }),
          new Paragraph(''),
          table2,
          new Paragraph(''),
          table2,
        ],
      },
    ]

    let DetailedItem = []

    if (reportSections.includes('Report Overview,Legends,and Notes')) {
      DetailedItem.push(...overviewItems)
    }

    if (reportSections.includes('Summary Section')) {
      DetailedItem.push(...summaryItems)
    }

    if (reportSections.includes('Detail and Open Ended Response Section')) {
      DetailedItem.push(...OpenEndedItems)
    }

    DetailedItem.push(...commenItmes)

    let doc = new Document({
      sections: [
        {
          headers: {
            default: new Header({
              children: [new Paragraph(zOrganizationName)],
            }),
          },
          children: [
            new Paragraph({
              text: '360 Report',
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.LEFT,
            }),

            new Table({
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      children: [
                        new Paragraph({
                          children: [
                            new ImageRun({
                              data: surveyLogo,
                              transformation: {
                                width: 100,
                                height: 100,
                              },
                            }),
                          ],
                          verticalAlign: VerticalPositionAlign.CENTER,
                        }),
                      ],
                      width: {
                        size: 30,
                        type: WidthType.DXA,
                      },
                      borders: borderNone,
                    }),
                    new TableCell({
                      children: [
                        new Paragraph({
                          text: '360 Report',
                          heading: HeadingLevel.HEADING_1,
                        }),
                        new Paragraph({
                          text: ParticipantName[i],
                          heading: HeadingLevel.HEADING_1,
                        }),
                        new Paragraph({
                          text: 'PROFESSIONAL AND CONFIDENTIAL',
                          heading: HeadingLevel.HEADING_1,
                        }),
                      ],
                      borders: borderNone,
                      verticalAlign: VerticalPositionAlign.CENTER,
                    }),
                  ],
                }),
              ],
              float: {
                relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
                relativeVerticalPosition: RelativeVerticalPosition.CENTER,
                overlap: OverlapType.NEVER,
              },
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              layout: TableLayoutType.FIXED,
            }),
          ],
          footers: {
            default: new Footer({
              children: [
                new Table({
                  rows: [
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: 'Project Name',
                            }),
                            new Paragraph({
                              text: zName,
                            }),
                          ],
                          borders: borderNone,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: 'Report Data',
                              alignment: AlignmentType.RIGHT,
                            }),
                            new Paragraph({
                              text: '24/01/2022',
                              alignment: AlignmentType.RIGHT,
                            }),
                          ],
                          borders: borderNone,
                        }),
                      ],
                    }),
                    new TableRow({
                      children: [
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: 'Project Manager',
                            }),
                            new Paragraph({
                              text: zOwnerName,
                            }),
                          ],
                          borders: borderNone,
                        }),
                        new TableCell({
                          children: [
                            new Paragraph({
                              text: 'Project Due Date',
                              alignment: AlignmentType.RIGHT,
                            }),
                            new Paragraph({
                              text: zDueDateFormat,
                              alignment: AlignmentType.RIGHT,
                            }),
                          ],
                          borders: borderNone,
                        }),
                      ],
                    }),
                  ],
                  width: fullWidth,
                }),
              ],
            }),
          },
        },
        ...DetailedItem,
      ],
    })
    documents.push([
      doc,
      ` ${zName}_360Reports_` + ParticipantName[i] + '.docx',
    ])
    //saveDocumentFile(doc,'360Reports_'+ParticipantName[i]+'.docx');
  }

  console.log(documents)

  function formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    return [year, month, day].join('_')
  }

  documents.forEach(saveDocumentFileNew)

  var count = 0

  var zipFilename =
    zName + '360Reports_' + '_' + formatDate(new Date()) + '.zip'

  let zip = new JSZip()
  async function saveDocumentFileNew(item, index) {
    console.log(item, 'item')
    await Packer.toBlob(item[0]).then((blob) => {
      if (documents.length > 1) {
        let newblobfile = new File([blob], `filename_${index}`)
        zip.file(item[1], newblobfile, { binary: true })
        count++
        if (index + 1 === documents.length) {
          generateZip(zip)
        }
      } else {
        saveAs(blob, item[1])
      }
    })
  }
  const generateZip = (data) => {
    data.generateAsync({ type: 'blob' }).then(function(content) {
      console.log('in')
      saveAs(content, zipFilename)
    })
  }
  //   const doc2 = new Document();
  // const logo = Media.addImage(doc, fs.readFileSync("myImage.png"), 200, 200); // ERROR HERE: "fs.readFileSync is not a function
  // doc2.addSection({children: [new Paragraph(logo)]});
  // const download = () => {
  //     // download function...
  // };
}
