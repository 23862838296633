import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../../components/common/CustomFunction'
import { API } from '../../helpers/API'
import {
  ADD_CATEGORY_URL,
  ADD_SUB_CATEGORY_URL,
  ALL_CUSTOMITEM,
  ALL_ORG_CUSTOM,
  ALL_SELECTED_LOAD_ITEMS,
  ALL_THINKWISE_ITEMS,
  DELETE_CUSTOME_ITEM,
  DELETE_CUSTOM_CATEGORY_URL,
  DELETE_CUSTOM_SUB_CATEGORY,
  GET_CATEGORY_LIST_URL,
  GET_CUSTOM_CATEGORY_URL,
  GET_CUSTOM_ITEMS_BY_CATEGORY,
  GET_CUSTOM_SUB_CATEGORY_URL,
  GET_SUB_CATEGORY_LIST_URL,
  GET_TASK_LIST,
} from '../../helpers/constants/ApiRoutes'
import {
  ACTION_TYPE,
  NEW_API_CUSTOMITEM,
  NEW_API_SELECTED_DATA,
  NEW_CUSTOM_ITEMS,
  NEW_SELECTED_THINKWISE_ID,
  NEW_SELECT_CUSTOM_ITEMS,
  NEW_THINKWISE_ITEM,
  ORG_SUMMARY,
  SAVESUB_CATEGORY,
  SAVE_CATEGORY,
  SAVE_CATEGORYLIST,
  SAVE_CUSTOM_CATEGORY,
  SAVE_CUSTOM_CATEGORY_LIST,
  SAVE_CUSTOM_SUB_CATEGORY_LIST,
  SAVE_TASK_LIST,
} from './ActionType'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from './Typeconstent'

//state list
// GET_COMPANY_DETAILS_BYID
export function getCategory() {
  return (dispatch) => {
    const options = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CATEGORY_LIST_URL,
    }
    API(options)
      .then((response) => {
        if (response.status === 200) {
          let { data } = response.data
          dispatch({
            type: SAVE_CATEGORYLIST,
            payload: data,
          })

          const getConfig = {
            method: 'GET',
            headers: authHeader(),

            url: GET_SUB_CATEGORY_LIST_URL,
          }
          API(getConfig).then((response) => {
            const { data: subcategory } = response.data

            let subCategorybyid = {}

            data.forEach(
              (val) =>
                (subCategorybyid[
                  'CATEGORY_LIST_' + val.zCompetencyID
                ] = subcategory.filter(
                  (sub) => sub.zCompetencyID == val.zCompetencyID
                ))
            )
            let subCategoryList = []
            data.forEach((val) => {
              const temp = subcategory.filter(
                (sub) => sub.zCompetencyID == val.zCompetencyID
              )
              subCategoryList.push(...temp)
            })
            dispatch({ type: SAVESUB_CATEGORY, payload: subCategoryList })

            dispatch({ type: SAVE_CATEGORY, payload: subCategorybyid })
          })
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response
        }
      })
  }
}

// Get Custom Category
export function getCustomCategory() {
  return (dispatch) => {
    const options = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_CATEGORY_URL,
      params: {
        UserId: getUserId(),
      },
    }
    API(options)
      .then((response) => {
        if (response.status === 200) {
          let { data } = response.data
          console.log('data in save', data)
          dispatch({
            type: SAVE_CUSTOM_CATEGORY_LIST,
            payload: data,
          })

          const getConfig = {
            method: 'GET',
            headers: authHeader(),

            url: GET_CUSTOM_SUB_CATEGORY_URL,
            params: {
              UserId: getUserId(),
            },
          }
          API(getConfig).then((response) => {
            const { data: subcategory } = response.data

            let subCategorybyid = {}

            data.forEach(
              (val) =>
                (subCategorybyid[
                  'CUSTOM_CATEGORY_LIST_' + val.zCompetencyID
                ] = subcategory.filter(
                  (sub) => sub.zCompetencyID == val.zCompetencyID
                ))
            )
            let subCategoryList = []
            data.forEach((val) => {
              const temp = subcategory.filter(
                (sub) => sub.zCompetencyID == val.zCompetencyID
              )
              //console.log(val, subcategory)
              subCategoryList.push(...temp)
            })
            dispatch({
              type: SAVE_CUSTOM_SUB_CATEGORY_LIST,
              payload: subCategoryList,
            })

            dispatch({ type: SAVE_CUSTOM_CATEGORY, payload: subCategorybyid })
          })
        }
      })
      .catch((error) => {
        if (error.response) {
          let { data } = error.response
        }
      })
  }
}

// Delete Custom Category
export function deleteCustomCategory(id) {
  return (dispatch) => {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_CUSTOM_CATEGORY_URL,
      params: {
        CategoryID: id,
      },
    }

    API(deleteConfig).then((response) => {
      if (response.status === 200) {
        dispatch(getCustomCategory())
      }
    })
  }
}

// Delete Custom Sub Category
export function deleteCustomSubCategory(id) {
  return (dispatch) => {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_CUSTOM_SUB_CATEGORY,
      params: {
        SubCategoryID: id,
      },
    }
    API(deleteConfig).then((response) => {
      if (response.status === 200) {
        dispatch(getCustomCategory())
      }
    })
  }
}

// Add Custom Category
export function addCustomCategory(categoryName) {
  //console.log('categoryName', categoryName)
  return (dispatch) => {
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_CATEGORY_URL,
      data: {
        // userID: getUserId(),
        organizationID: getOrganizationId(),

        name: categoryName,
      },
    }
    API(postConfig).then((response) => {
      if (response.status === 200) {
        dispatch(getCustomCategory())
      }
    })
  }
}

// Add Custom Category
export function addCustomSubCategory(competencyId) {
  return (dispatch) => {
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_SUB_CATEGORY_URL,
      data: {
        // userID: getUserId(),
        organizationID: getOrganizationId(),
        competencyID: competencyId,
      },
    }
    API(postConfig).then((response) => {
      if (response.status === 200) {
        dispatch(getCustomCategory())
      }
    })
  }
}

// Task List
export function taskList() {
  return (dispatch) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TASK_LIST,
      params: {
        UserId: getUserId(),
        // ProjectTypeID: 2,
      },
    }
    API(getconfig).then((response) => {
      if (response.status === 200) {
        const { projectTaskList } = response.data
        dispatch({ type: SAVE_TASK_LIST, payload: projectTaskList })
      }
    })
  }
}

export function getAllthinkwise(id) {
  return (dispatch) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: ALL_THINKWISE_ITEMS,
      params: {
        ProjectID: id,
        OrganizationID: getOrganizationId(),
      },
    }
    API(config).then((response) => {
      if (response.status === 200) {
        let newThinkwise = response.data.map((think) => {
          let compentencyidsplit = think.zNameComp.split('##')
          let Itemlist = []

          let capablitylist = think.competencyListData.map((cap) => {
            let capabilitysplit = cap.zNameCap.split('##')

            let itemslist = cap.zCaption.map((item) => {
              let itemsList = item.split('##')
              Itemlist.push(itemsList[0])
              return {
                itemid: itemsList[0],
                itemname: itemsList[1],
                compentencyid: compentencyidsplit[0],
              }
            })
            return {
              compentencyid: compentencyidsplit[0],
              capablityid: capabilitysplit[0],
              capablityname: capabilitysplit[1],
              itemslist,
            }
          })
          return {
            compentencyid: compentencyidsplit[0],
            compentencyname: compentencyidsplit[1],
            capablitylist,
            selectedItemsList: [],
            selectedSubcategoryList: [],
            Itemlist,
          }
        })
        dispatch({ type: NEW_THINKWISE_ITEM, payload: newThinkwise })
      }
    })
  }
}

export function getAllcustomItem(id) {
  return (dispatch) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: ALL_CUSTOMITEM,
      params: {
        ProjectID: id,

        OrganizationID: getOrganizationId(),
        UserID: getUserId(),
      },
    }
    API(config).then((response) => {
      if (response.status === 200) {
        getAllOrgCustomItems()
        const [customItemsData] = response.data
        let allCustomItems = customItemsData.competencyListUser.map((value) => {
          let compentencyNAmeSplit = value.zNameComp.split('##')
          let competencyList = value.competencyListData.map((comp) => {
            let NAmeSplit = comp.zNameCap.split('##')

            let listCompentencItem = comp.zCaption.map((it) => {
              let itemNameSplit = it.split('#')
              return { itemId: itemNameSplit[0], itemname: itemNameSplit[2] }
            })
            return {
              compentencyid: NAmeSplit[0],
              compentencyname: NAmeSplit[1],
              zDescription: comp.zDescription,
              Itemlist: listCompentencItem,
            }
          })
          let listCompentencItem = value?.zCaption?.map((it) => {
            let itemNameSplit = it.split('#')
            return { itemId: itemNameSplit[0], itemname: itemNameSplit[2] }
          })
          return {
            capablityid: compentencyNAmeSplit[0],
            capablityname: compentencyNAmeSplit[1],
            zDescription: value.zDescription,
            compentencylist: competencyList,
            selectedItemsList: [],
            selectedSubcategory: [],
            itemList: listCompentencItem,
          }
        })
        // allCustomItems.push(...allCustomIte)
        console.log(customItemsData.competencyListUser, allCustomItems)

        dispatch({ type: NEW_CUSTOM_ITEMS, payload: allCustomItems })
      }
    })
  }
}
export function getAllOrgCustomItems(id) {
  return (dispatch) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: ALL_ORG_CUSTOM,
      params: {
        ProjectID: id,
        OrganizationID: getOrganizationId(),
        UserID: getUserId(),
      },
    }
    API(config).then((response) => {
      console.log(response, 'responseee')
      if (response.status === 200) {
        const [customItemsData] = response.data

        let allCustomItems = customItemsData.competencyListUser.map((value) => {
          let compentencyNAmeSplit = value.zNameComp.split('##')
          let competencyList = value.competencyListData.map((comp) => {
            let NAmeSplit = comp.zNameCap.split('##')

            let listCompentencItem = comp.zCaption.map((it) => {
              let itemNameSplit = it.split('#')
              return { itemId: itemNameSplit[0], itemname: itemNameSplit[2] }
            })
            return {
              compentencyid: NAmeSplit[0],
              compentencyname: NAmeSplit[1],
              zDescription: comp.zDescription,
              Itemlist: listCompentencItem,
            }
          })
          let listCompentencItem = value?.zCaption?.map((it) => {
            let itemNameSplit = it.split('#')
            return { itemId: itemNameSplit[0], itemname: itemNameSplit[2] }
          })
          return {
            capablityid: compentencyNAmeSplit[0],
            capablityname: compentencyNAmeSplit[1],
            zDescription: value.zDescription,
            compentencylist: competencyList,
            selectedItemsList: [],
            selectedSubcategory: [],
            itemList: listCompentencItem,
          }
        })
        // allCustomItems.push(...allCustomItems)
        console.log(allCustomItems, 'qwertyuiokjhgfdsdfghj')

        console.log(customItemsData.competencyListUser, allCustomItems)

        dispatch({ type: ORG_SUMMARY, payload: allCustomItems })
      }
    })
  }
}

export const getAllsaveItems = (project_id) => {
  return async (dispatch) => {
    const config = {
      url: ALL_SELECTED_LOAD_ITEMS,
      method: 'GET',
      headers: authHeader(),

      params: {
        ProjectID: project_id,
        UserID: getUserId(),
        OrganizationID: getOrganizationId(),
      },
    }
    try {
      const response = await API(config)
      const [responseData] = response.data
      const {
        customSummary,
        thinkwiseCompetencyList,
        orgSummary,
      } = responseData
      const [customitem] = customSummary
      const [loadOrgSummaryselect] = orgSummary
      const { loadOrgSummary } = loadOrgSummaryselect
      const [thinkwiseCompetency] = thinkwiseCompetencyList
      const { competencyList } = thinkwiseCompetency
      const { loadCustomSummary } = customitem
      let competencySelectedItem = []
      let itemData = {}
      competencyList.forEach((co) => {
        let competencyNamesplit = co.zNameComp.split('##')
        competencySelectedItem.push(competencyNamesplit[0])
        let CATEGORYNAME = THINKWISE_CATEGORY + competencyNamesplit[0]
        itemData[CATEGORYNAME] = {
          ...(itemData[CATEGORYNAME] || {}),
        }
        co.competencyListData.forEach((val) => {
          let spliCompname = val.zNameCap.split('##')

          let SUBCATEGORYNAME = THINKWISE_SUB_CATEGORY + spliCompname[0]
          let Items = []
          val.zCaption.forEach((val) => {
            let spliItemname = val.split('##')
            Items.push(spliItemname[0])
          })
          itemData[CATEGORYNAME][SUBCATEGORYNAME] = Items
        })
      })
      console.log('iiitjehjhg', itemData, competencySelectedItem)
      dispatch({ type: NEW_API_SELECTED_DATA, payload: itemData })
      dispatch({
        type: NEW_SELECTED_THINKWISE_ID,
        payload: competencySelectedItem,
      })
      console.log(loadCustomSummary)
      let selectedCustom = []
      let items = {}
      loadCustomSummary.forEach((val) => {
        let categoryNamesplit = val.zNameComp.split('##')
        let ALLCATEGORYNAME = CUSTOM_ITEM_ + categoryNamesplit[0]
        items[ALLCATEGORYNAME] = { ...items[ALLCATEGORYNAME] }
        if (val.zCaptionComp.length) {
          let itemList = []
          val.zCaptionComp.forEach((value) => {
            let itemNAmesplit = value.split('##')
            itemList.push(itemNAmesplit[0])
          })
          items[ALLCATEGORYNAME] = itemList
        }
        if (val.competencyListData.length) {
          val.competencyListData.forEach((com) => {
            let compnamesplit = com.zNameCap.split('##')
            let itemsList = []
            let ALLNAME =
              SUB_CUSTOM_ITEM_ + categoryNamesplit[0] + '_' + compnamesplit[0]
            com.zCaption.forEach((it) => {
              let itemnamesplit = it.split('##')
              itemsList.push(itemnamesplit[0])
            })
            items[ALLCATEGORYNAME][ALLNAME] = itemsList
          })
        }
        selectedCustom.push(categoryNamesplit[0])
      })
      loadOrgSummary.forEach((val) => {
        let categoryNamesplit = val.zNameComp.split('##')
        let ALLCATEGORYNAME = CUSTOM_ITEM_ + categoryNamesplit[0]
        items[ALLCATEGORYNAME] = { ...items[ALLCATEGORYNAME] }
        if (val.zCaptionComp.length) {
          let itemList = []
          val.zCaptionComp.forEach((value) => {
            let itemNAmesplit = value.split('##')
            itemList.push(itemNAmesplit[0])
          })
          items[ALLCATEGORYNAME] = itemList
        }
        if (val.competencyListData.length) {
          val.competencyListData.forEach((com) => {
            let compnamesplit = com.zNameCap.split('##')
            let itemsList = []
            let ALLNAME =
              SUB_CUSTOM_ITEM_ + categoryNamesplit[0] + '_' + compnamesplit[0]
            com.zCaption.forEach((it) => {
              let itemnamesplit = it.split('##')
              itemsList.push(itemnamesplit[0])
            })
            items[ALLCATEGORYNAME][ALLNAME] = itemsList
          })
        }
        selectedCustom.push(categoryNamesplit[0])
      })

      let allCustomItems = orgSummary[0]?.loadOrgSummary.map((value) => {
        let compentencyNAmeSplit = value.zNameComp.split('##')
        let competencyList = value.competencyListData.map((comp) => {
          let NAmeSplit = comp.zNameCap.split('##')
          let listCompentencItem = comp.zCaption.map((it) => {
            let itemNameSplit = it.split('#')
            return { itemId: itemNameSplit[0], itemname: itemNameSplit[2] }
          })
          return {
            compentencyid: NAmeSplit[0],
            compentencyname: NAmeSplit[1],
            zDescription: comp.zDescription,
            Itemlist: listCompentencItem,
          }
        })
        let listCompentencItem = value?.zCaption?.map((it) => {
          let itemNameSplit = it.split('#')
          return { itemId: itemNameSplit[0], itemname: itemNameSplit[2] }
        })
        return {
          capablityid: compentencyNAmeSplit[0],
          capablityname: compentencyNAmeSplit[1],
          zDescription: value.zDescription,
          compentencylist: competencyList,
          selectedItemsList: [],
          selectedSubcategory: [],
          itemList: listCompentencItem,
        }
      })
      dispatch({ type: NEW_SELECT_CUSTOM_ITEMS, payload: selectedCustom })
      dispatch({ type: NEW_API_CUSTOMITEM, payload: items })
      dispatch({ type: ORG_SUMMARY, payload: allCustomItems })
    } catch (er) {
      console.log(er)
    }
  }
}
