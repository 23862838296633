import * as types from '../action/ActionType'
export default function SelectedDragAndDrop(state = { selectComponent : false }, action) {
  const { type, payload } = action
  switch (type) {
    case types.SELECTED_DRAG_DROP:
      return (state = payload)
    default:
      return state
  }
}
