import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete'
import NotificationsIcon from '@material-ui/icons/Notifications'
import {
  DELETE_TEAM_RESPOTANT,
  TEAM_CHANGE_TEAMMEMBER,
} from '../../helpers/constants/ApiRoutes'
import { useParams } from 'react-router-dom'
import { authHeader, getOrganizationname } from '../common/CustomFunction'
import { API } from '../../helpers/API'
import Swal from 'sweetalert2'
import Tsupdateresponant from './Tsupdateresponant'
import EditIcon from '@material-ui/icons/Edit'
import Tsremaildermodal from './Tsremaildermodal'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

const userInitial = { FirstName: '', LastName: '', Email: '', UserId: '' }

function Tsresponant(props) {
  const classes = useStyles()
  const [remindFlag, setremindFlag] = useState(false)
  const { responantList, successCall, projectDetails } = props
  const [remainderUserid, setremainderUserid] = useState('')
  const { tsprojectid } = useParams()
  const [responantEdit, setresponantEdit] = useState(false)
  const [userResponant, setuserResponant] = useState(userInitial)
  const responantToggla = () => setresponantEdit((v) => !v)

  const reminderFlagtoggle = (e) => {
    setremainderUserid(e.currentTarget.dataset.id)
    setremindFlag((v) => !v)
  }

  const editResponant = (e) => {
    const { id, firstname, lastname, email } = e.currentTarget.dataset
    setuserResponant({
      FirstName: lastname,
      LastName: firstname,
      Email: email,
      UserId: id,
    })
    responantToggla()
  }
  const handleResetResponant = () => {
    setuserResponant(userInitial)
    responantToggla()
  }
  const responsuccessCall = () => {
    setuserResponant(userInitial)
    responantToggla()
    successCall()
  }

  const deleteRespondent = async (value) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: DELETE_TEAM_RESPOTANT,
      params: {
        ProjectID: tsprojectid,
        UserId: value,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        successCall()
        // setresponantList(data.TabList)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const deleteMember = (e) => {
    const { id } = e.currentTarget.dataset
    Swal.fire({
      title:
        'Deleting this Respondent will permanently remove it from the project . Are you sure you want to proceed?',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRespondent(id)
        //Swal.fire('Saved!', '', 'success')
      }
      // else if (result.isDenied) {
      //   Swal.fire('Project is not deleted', '', 'info')
      // }
    })
  }
  const remeinderClose = () => {
    setremainderUserid('')
    setremindFlag((v) => !v)
  }
  const handleChange = async (e) => {
    //TEAM_CHANGE_TEAMMEMBER
    let id = e.target.name

    let updateValue = responantList.find((va) => va.zUserID == id)
    console.log(updateValue)
    if (!updateValue) {
      return null
    }
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_CHANGE_TEAMMEMBER,
      data: {
        FirstName: updateValue.zFirstName,
        LastName: updateValue.zLastName,
        EmailId: updateValue.zEmail,
        OrganizationName: getOrganizationname(),
        ProjectID: tsprojectid,
        UserID: id,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        successCall()
        console.log(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>

            <TableCell>Status</TableCell>
            <TableCell> Survey Progress</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {responantList.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.zFirstName}
              </TableCell>
              {console.log(row.zTeamLeader)}
              <TableCell component="th" scope="row">
                {row.zLastName}
              </TableCell>

              <TableCell>{row.zEmail}</TableCell>
              {/* <TableCell>
                {' '}
                <FormControl fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> 
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    data-id={row.zUserID}
                    name={row.zUserID}
                    value={row.zTeamLeader ? 'true' : 'false'}
                    onChange={handleChange}>
                    <MenuItem value={'true'}>Team leader</MenuItem>
                    <MenuItem value={'false'}>Member</MenuItem>
                  </Select>
                </FormControl>
              </TableCell> */}
              <TableCell>{row.zTaskStatusName}</TableCell>
              <TableCell>{row.zProgress + '%' + row.zProgressText}</TableCell>
              <TableCell align="right">
                <EditIcon
                  data-id={row.zUserID}
                  data-firstname={row.zFirstName}
                  data-lastname={row.zLastName}
                  data-email={row.zEmail}
                  onClick={editResponant}
                />
                {/* <NotificationsIcon
                  data-id={row.zUserID}
                  onClick={reminderFlagtoggle}
                /> */}
                <DeleteIcon data-id={row.zUserID} onClick={deleteMember} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Tsupdateresponant
        open={responantEdit}
        handleReset={handleResetResponant}
        formdata={userResponant}
        responsuccessCall={responsuccessCall}
      />
      {remindFlag && (
        <Tsremaildermodal
          open={remindFlag}
          projectDetails={projectDetails}
          remainderUserid={remainderUserid}
          handleReset={remeinderClose}
        />
      )}
    </TableContainer>
  )
}

export default Tsresponant
