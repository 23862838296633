// import {
//   Button,
//   Grid,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
//   makeStyles,
// } from '@material-ui/core'
import {
  Button,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  FormControl,
  MenuItem,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
// import WarningIcon from '@mui/icons-material/Warning'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import { useFormik } from 'formik'
import React from 'react'
import CustomButton from '../../../common/CustomButton'
import {
  FormHelperText,
  Grid,
  InputAdornment,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  POST_UPSERT_BYPASS_ROSTERUSER,
  POST_UPSERT_ROSTER_FORM,
  RELATIONSHIP_LIST_URL,
  GET_RELATIONSHIP_LIST_NEW,
} from '../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../helpers/API'
import { useState } from 'react'
import * as Yup from 'yup'
import { CustomMessage } from '../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { authHeader, getUserId } from '../../../common/CustomFunction'
import CustomLoader from '../../../common/CustomLoader'
// import { Select } from 'formik-material-ui'
import DeleteIcon from '@material-ui/icons/Delete'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom/cjs/react-router-dom'

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.btnPrimary,
    '& .MuiButton-label': {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
      width: '0% ',
    },
  },
  inprogressBtn: {
    marginTop: '2rem',
    padding: '8px 22px',
    backgroundColor: theme.btnPrimary,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  tablecontainer: {
    marginTop: '1rem',
    // maxHeight: 440,
    width: '86% !important',
    minHeight: '20vh',
    //overflowX: "hidden",
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  circularloader: {
    display: 'flex',
    justifyContent: 'center',
    width: '300%',
    paddingTop: '20%',
  },
  heading: {
    // fontWeight: 'bold',
    color: '#000000DE !important',
    fontSize: '24px',
  },
  subheading: {
    fontFamily: 'Nunito-SemiBold  !important',
    // padding:'13px !important'
  },
  subheading1: {
    fontFamily: 'Nunito-SemiBold  !important',
    // padding: '1.4rem !important',
    // bodr
  },
  additem: {
    fontFamily: 'Nunito-SemiBold  !important',
    padding: '1.3rem !important',
    color: '#000 !important',
  },
  inactive: {
    fontFamily: 'Nunito-SemiBold  !important',
    color: '#1565C0 !important',
  },
}))

export default function RosterFormNew(props) {
  const { setThanksSubmit } = props
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const [upsertBypassRoster, setUpsertBypassRoster] = useState()
  const [loading, setLoading] = useState(false)
  const [adminApproval, setAdminApproval] = useState()
  const [raterList, setRaterList] = useState([])
  const [managerList, setManagerList] = useState([
    { managerName: 'Manager', zRelationshipID: 5 },
    { managerName: 'Manager 2', zRelationshipID: 38 },
    { managerName: 'Manager 3', zRelationshipID: 39 },
  ])
  const [warningMsg, setWarningMsg] = useState({
    participantFirstName: false,
    participantLastName: false,
    raterFirstName1_0: false,
    raterLastName1_0: false,
    raterFirstName_0: false,
    raterLastName_0: false,
    raterFirstName_1: false,
    raterLastName_1: false,
    raterFirstName_2: false,
    raterLastName_2: false,
    raterFirstName_3: false,
    raterLastName_3: false,
    raterFirstName_4: false,
    raterLastName_4: false,
    raterFirstName_5: false,
    raterLastName_5: false,
  })
  const { projectIdLocal } = useParams()
  const selector = useSelector((state) => state?.UpdateApprovalRouster)
  console.log(raterList, 'raterList')

  //   const [indexes, setIndexes] = React.useState([])
  //   const [counter, setCounter] = React.useState(0)
  //   const { register, handleSubmit } = useForm()

  //   const addManager = () => {
  //     console.log('sdfghjkl;')
  //     setIndexes((prevIndexes) => [...prevIndexes, counter])
  //     setCounter((prevCounter) => prevCounter + 1)
  //   }
  const organizationID = localStorage.getItem('LOG_ORGANIZATION_ID')
  const projectID = localStorage.getItem('PROJECT_ID')
  const UserID = localStorage.getItem('USER_ROLE_ID')

  const validate = Yup.object().shape({
    participantFirstName: Yup.string().required(
      'Participant first name is required'
    ),
    participantEmail: Yup.string()
      .required('Email is required')
      // .test('unique-email', 'Email is already taken', function (value) {
      //   const { participantEmail } = this.parent;
      //   if (!value) return true; // Allow empty fields
      //   return participantEmail.filter((email) => email === value).length <= 1;
      // })
      .email('Invalid email format'),
    participantLastName: Yup.string().required(
      'Participant last name is required'
    ),
    // .test('not-same', 'First name and last name cannot be the same', function(
    //   value
    // ) {
    //   console.log(value, 'partivlwue')
    //   return value !== this.parent.participantFirstName
    // }),
    manager: Yup.array().of(
      Yup.object().shape({
        raterFirstName1: Yup.string().required('Manger first name is required'),
        raterEmail1: Yup.string()
          .required('Email is required')
          // .test('unique-email', 'Email is already taken', function (value) {
          //   const { raterEmail1 } = this.parent;
          //   console.log(raterEmail1,'raterEmail1');
          //   if (!value) return true; // Allow empty fields
          //   return values.manager?.filter((email) => email.raterEmail1 === value).length <= 1;
          // })
          .email('Invalid email format'),
        // .email(),
        // .email('Give valid email'),
        raterLastName1: Yup.string().required('Manager last name is required'),
        // .test(
        //   'not-same',
        //   'First name and last name cannot be the same',
        //   function(value) {
        //     console.log(value, 'valuevalue')
        //     return value !== this.parent.raterFirstName1
        //   }
        // ),
        raterRelationship1: Yup.string().required('Select Rater Relationship'),
      })
    ),
    rater: Yup.array().of(
      Yup.object().shape({
        // raterFirstName: Yup.string().required('Rater first name is required'),
        raterEmail: Yup.string()
          // .required('Email is required')
          .test('unique-email', 'Email is already taken', function(value) {
            const { raterEmail } = this.parent
            console.log(raterEmail, 'raterEmail')
            if (!value) return true // Allow empty fields
            return (
              values.rater?.filter((email) => email.raterEmail === value)
                .length <= 1
            )
          })
          .email('Invalid email format'),
        // raterLastName: Yup.string()
        //   .required('Rater last name is required'),
        // .test(
        //   'not-same',
        //   'First name and last name cannot be the same',
        //   function(value, index) {
        //     // if ([value].length > 0)
        //     console.log([value].length, index, value, 'valuerater')
        //     return value !== this.parent.raterFirstName
        //   }
        // ),
        raterRelationship: Yup.string()
          // .required('Select Rater Relationship')
          .test('same', ' You have already choosen Self', function(value) {
            console.log(value != 1, 'valueself')
            return value != 1
          }),
      })
    ),
  })

  const initialValues = {
    participantFirstName: '',
    participantEmail: '',
    participantLastName: '',
    manager: [
      {
        raterFirstName1: '',
        raterEmail1: '',
        raterLastName1: '',
        raterRelationship1: '',
      },
    ],
    rater: [
      {
        raterFirstName: '',
        raterEmail: '',
        raterLastName: '',
        raterRelationship: '',
      },
      {
        raterFirstName: '',
        raterEmail: '',
        raterLastName: '',
        raterRelationship: '',
      },
      {
        raterFirstName: '',
        raterEmail: '',
        raterLastName: '',
        raterRelationship: '',
      },

      // {
      //   raterFirstName: '',
      //   raterEmail: '',
      //   raterLastName: '',
      //   raterRelationship: '',
      // },
    ],
  }

  const getRaterRelation = async () => {
    const ratconfig = {
      method: 'GET',

      url: GET_RELATIONSHIP_LIST_NEW,
      headers: authHeader(),
      params: {
        UserID: getUserId(),
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      if (status == 'Success') {
        setRaterList(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const postUpsertRoster = async (id) => {
    const config = {
      method: 'POST',
      url: POST_UPSERT_ROSTER_FORM,
      data: {
        participantID: id,
        projectID: parseInt(projectIdLocal),
        // isApproved: !!!adminApproval,
        isApproved:
          localStorage.getItem('ADMIN_APPROVAL') == 'true' ? false : true,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'responseee')
      const { data } = response
      console.log('response', data)
      if (data.status === 'Success') {
        // setUpsertBypassRoster(data)
        setThanksSubmit(true)
        // CustomMessage(
        //   'Your participation added successfully.',
        //   'success',
        //   enqueueSnackbar
        // ) //call toster
      }
    } catch (error) {
      console.error(error)
    }
  }

  const postRosterUser = async (formData, values, resetForm) => {
    setLoading(true)
    const config = {
      method: 'POST',
      url: POST_UPSERT_BYPASS_ROSTERUSER,
      data: {
        organizationID: parseInt(organizationID),
        projectID: parseInt(projectIdLocal),
        UserID: parseInt(getUserId()),
        participant: {
          participantFirstName: values.participantFirstName,
          participantLastName: values.participantLastName,
          participantEmail: values.participantEmail,
        },
        rater: formData,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'responseee')
      const { data } = response
      console.log('response', data)
      if (data.status === 'Success') {
        localStorage.setItem('PARTICIPANT_ID', data?.ParticipantID)
        setUpsertBypassRoster(data)
        postUpsertRoster(data?.ParticipantID)
        resetForm()
        const obj = {}
        for (let item in warningMsg) {
          obj[item] = false
        }
        setWarningMsg(obj)
        setLoading(false)
      }
    } catch (error) {
      CustomMessage('User Already Exists', 'error', enqueueSnackbar) //call toster
      console.error(error)
      setLoading(false)
    }
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: validate,

    onSubmit: (values, { resetForm }) => {
      const arrayData = []
      const listData = values?.manager?.map((item, index) => {
        if (item.raterEmail1 && item.raterFirstName1 && item.raterLastName1) {
          arrayData.push({
            raterEmail: item.raterEmail1,
            raterFirstName: item.raterFirstName1,
            raterLastName: item.raterLastName1,
            raterRelationship: item.raterRelationship1,
          })
        }
      })

      const raterData = values?.rater?.map((item, index) => {
        if (item.raterEmail && item.raterFirstName && item.raterLastName) {
          console.log('success')
          arrayData.push(item)
        }
      })
      postRosterUser(arrayData, values, resetForm)
    },
  })

  console.log(warningMsg, '2345678')

  const [data, setData] = useState({ ...initialValues })
  console.log(data, 'data')
  const handleBlur = (event, index = -1) => {
    const eventData = event.target.value
    const eventname = event.target.name
    console.log(eventData?.toString()?.toUpperCase(), 'indexvalue')
    // console.log(index, 'indexvalue')

    if (index != -1) {
      if (
        eventData.toString().charAt(0) ===
          eventData
            .toString()
            .charAt(0)
            .toLowerCase() &&
        eventData.length > 0
      ) {
        console.log('lkiujyasdf')
        setWarningMsg({
          ...warningMsg,
          [`${event.target.name.split('.')[1]}_${index}`]: true,
        })
      } else if (
        (eventData.toString() === eventData?.toString()?.toUpperCase() &&
          eventData.length > 1) ||
        (eventData.toString() === eventData.toString()?.toLowerCase() &&
          eventData.length > 0)
      ) {
        setWarningMsg({
          ...warningMsg,
          [`${event.target.name.split('.')[1]}_${index}`]: true,
        })
      } else {
        setWarningMsg({
          ...warningMsg,
          [`${event.target.name.split('.')[1]}_${index}`]: false,
        })
      }
    } else {
      console.log('gggg')
      if (
        eventData.toString().charAt(0) ===
          eventData
            .toString()
            .charAt(0)
            .toLowerCase() &&
        eventData.length > 0
      ) {
        setWarningMsg({ ...warningMsg, [event.target.name]: true })
      } else if (
        (eventData.toString() === eventData?.toString()?.toUpperCase() &&
          eventData.length > 1) ||
        (eventData.toString() === eventData.toString()?.toLowerCase() &&
          eventData.length > 0)
      ) {
        setWarningMsg({ ...warningMsg, [event.target.name]: true })
      } else {
        setWarningMsg({ ...warningMsg, [event.target.name]: false })
      }
    }
  }

  console.log(values, 'values6328738273873')

  const handleDeleteIcon = (objKey, index) => {
    // const listData = values[objKey].filter((item, i) => i !== index && item.raterRelationship1)
    // const listOriginal = listData.reverse()
    // const sortedData = managerList.sort((a, b) => {
    //   const indexOfA = listOriginal.indexOf(a.zRelationshipID);
    //   const indexOfB = listOriginal.indexOf(b.zRelationshipID);

    //   return indexOfB - indexOfA
    // });
    // console.log(sortedData, "sortedData" ,listOriginal);
    //  setManagerList(sortedData)
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Delete',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      console.log(result, 'resulttttt')
      if (result?.isConfirmed) {
        setFieldValue(
          objKey,
          values[objKey].filter((item, i) => i !== index && item)
        )
      }
    })
  }

  console.log(warningMsg, 'warningMsg', localStorage.getItem('ADMIN_APPROVAL'))
  window.addEventListener('storage', () => {
    setAdminApproval(
      JSON.parse(window?.localStorage?.getItem('ADMIN_APPROVAL'))
    )
  })

  useEffect(() => {
    // setAdminApproval()
    // JSON.parse(window?.localStorage?.getItem('ADMIN_APPROVAL'))
    getRaterRelation()
  }, [adminApproval])

  console.log(errors, 'tst', adminApproval, !adminApproval)

  return (
    <div style={{ paddingTop: '0px' }}>
      <Grid>
        <Grid item sm={12} lg={12} md={12}>
          <Paper>
            <Grid style={{ padding: '1rem 3rem' }}>
              <Grid>
                <Grid>
                  <Typography variant="h5">360 Participant Form</Typography>
                  <Typography variant="body2">
                    You have been invited to participate in a 360 survey. A 360
                    survey is a feedback survey where you rate yourself and
                    others rater you on selected categories and items. Please
                    complete the following form to add yourself, a manager or
                    leader that you report to and others that you would ike to
                    receive feedback from.
                    <br />
                    Note: You are required to add yourself in the first section
                    of the form, and a Manager / Leader in the second section of
                    the form, all other groups are optional, however for a full
                    report, we recommend adding a group of 3 additional raters
                    in the last section of the form.
                  </Typography>
                </Grid>
              </Grid>
              <form onSubmit={handleSubmit} className={classes.paper}>
                <Grid style={{ marginTop: '2rem' }}>
                  <Typography variant="h6">1. Self Assessment.</Typography>
                  <Typography variant="body2">
                    Add your name and email address below (required)
                  </Typography>
                  <TableContainer
                    component={Paper}
                    className={classes.tablecontainer}>
                    <Table className={classes.customTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.subheading}>
                            Last Name
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            First Name
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            Email
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            Rater Relationship
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            className={classes.subheading1}
                            style={{ display: 'flex', padding: '17px' }}>
                            <TextField
                              name={`participantLastName`}
                              variant="standard"
                              fullWidth
                              value={values.participantLastName}
                              onChange={(e) => {
                                handleChange(e)
                                handleBlur(e)
                                // Additional logic after the onBlur event
                              }}
                              placeholder="Last Name"
                              error={
                                errors.participantLastName &&
                                touched.participantLastName
                              }
                              helperText={
                                errors.participantLastName &&
                                touched.participantLastName
                                  ? errors.participantLastName
                                  : ''
                              }
                              // onBlur={(e) => handleBlur(e)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {warningMsg.participantLastName && (
                                      <Tooltip
                                        title={
                                          <span>
                                            First letter in uppercase, others in
                                            any case.
                                            <br />
                                            Enter a mix of uppercase and
                                            lowercase letters.
                                          </span>
                                        }>
                                        <ErrorOutlineOutlinedIcon
                                          sx={{ color: '#E9D502' }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.subheading1}>
                            <TextField
                              name={`participantFirstName`}
                              variant="standard"
                              fullWidth
                              value={values.participantFirstName}
                              onChange={(e) => {
                                handleChange(e)
                                handleBlur(e)
                                // Additional logic after the onBlur event
                              }}
                              placeholder="First Name"
                              error={
                                errors.participantFirstName &&
                                touched.participantFirstName
                              }
                              helperText={
                                errors.participantFirstName &&
                                touched.participantFirstName
                                  ? errors.participantFirstName
                                  : ''
                              }
                              // onBlur={(e) => handleBlur(e)}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    {warningMsg.participantFirstName && (
                                      <Tooltip
                                        title={
                                          <span>
                                            First letter in uppercase, others in
                                            any case.
                                            <br />
                                            Enter a mix of uppercase and
                                            lowercase letters.
                                          </span>
                                        }>
                                        <ErrorOutlineOutlinedIcon
                                          sx={{ color: '#E9D502' }}
                                        />
                                      </Tooltip>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                          <TableCell className={classes.subheading1}>
                            <TextField
                              name={`participantEmail`}
                              variant="standard"
                              fullWidth
                              value={values.participantEmail}
                              onChange={handleChange}
                              placeholder="Email"
                              error={
                                errors.participantEmail &&
                                touched.participantEmail
                              }
                              helperText={
                                errors.participantEmail &&
                                touched.participantEmail
                                  ? errors.participantEmail
                                  : ''
                              }
                              // <WarningIcon/>
                            />
                          </TableCell>
                          <TableCell className={classes.subheading1}>
                            <Typography
                              // name={`raterRelationship`}
                              variant="standard"
                              fullWidth>
                              Self
                            </Typography>
                            {/* <TextField
                    name={`raterRelationship`}
                    variant="outlined"
                    fullWidth
                    value={formik.raterRelationship}
                    onChange={formik.handleChange}
                  /> */}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid style={{ marginTop: '4rem' }}>
                  <Typography variant="h6">
                    {' '}
                    2. Manager / Leader Feedback.
                  </Typography>
                  <Typography variant="body2">
                    {' '}
                    Add your manager(s) / leader(s) below (required)
                  </Typography>
                  <TableContainer
                    component={Paper}
                    className={classes.tablecontainer}>
                    <Table className={classes.customTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.subheading}>
                            Last Name
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            First Name
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            Email
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            Rater Relationship
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values.manager.map((manager, index) => {
                          // const touchedLastName = getIn(touched, raterLastName)
                          // const errorLastName = getIn(errors, raterLastName)

                          // const touchedFirstName = getIn(
                          //   touched,
                          //   raterFirstName
                          // )
                          // const errorFirstName = getIn(errors, raterFirstName)

                          // const touchedEmail = getIn(touched, raterFirstName)
                          // const errorEmail = getIn(errors, raterFirstName)
                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  name={`manager[${index}].raterLastName1`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={manager.raterLastName1}
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleBlur(e, index)
                                    // Additional logic after the onBlur event
                                  }}
                                  placeholder="Last Name"
                                  error={
                                    errors.manager?.[index]?.raterLastName1 &&
                                    touched.manager?.[index]?.raterLastName1
                                  }
                                  helperText={
                                    errors.manager?.[index]?.raterLastName1 &&
                                    touched.manager?.[index]?.raterLastName1
                                      ? errors.manager?.[index]?.raterLastName1
                                      : ''
                                  }
                                  // onBlur={(e) => {
                                  //   handleBlur(e, index)
                                  // }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        {/* manager[${index}].raterLastName1 */}
                                        {warningMsg?.[
                                          `raterLastName1_${index}`
                                        ] && (
                                          <Tooltip
                                            title={
                                              <span>
                                                First letter in uppercase,
                                                others in any case.
                                                <br />
                                                Enter a mix of uppercase and
                                                lowercase letters.
                                              </span>
                                            }>
                                            <ErrorOutlineOutlinedIcon
                                              sx={{ color: '#E9D502' }}
                                            />
                                          </Tooltip>
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {/* {warningMsg.[participantFirstName] && (
                                <Tooltip
                                title='First letter in uppercase, others in any case'>
                               <ErrorOutlineOutlinedIcon sx={{color:'#E9D502'}}/>
                              </Tooltip>
                                )} */}
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name={`manager[${index}].raterFirstName1`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={manager.raterFirstName1}
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleBlur(e, index)
                                    // Additional logic after the onBlur event
                                  }}
                                  placeholder="First Name"
                                  error={
                                    errors.manager?.[index]?.raterFirstName1 &&
                                    touched.manager?.[index]?.raterFirstName1
                                  }
                                  helperText={
                                    errors.manager?.[index]?.raterFirstName1 &&
                                    touched.manager?.[index]?.raterFirstName1
                                      ? errors.manager?.[index]?.raterFirstName1
                                      : ''
                                  }
                                  // onBlur={(e) => {
                                  //   handleBlur(e, index)
                                  // }}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        {/* manager[${index}].raterLastName1 */}
                                        {warningMsg?.[
                                          `raterFirstName1_${index}`
                                        ] && (
                                          <Tooltip
                                            title={
                                              <span>
                                                First letter in uppercase,
                                                others in any case.
                                                <br />
                                                Enter a mix of uppercase and
                                                lowercase letters.
                                              </span>
                                            }>
                                            <ErrorOutlineOutlinedIcon
                                              sx={{ color: '#E9D502' }}
                                            />
                                          </Tooltip>
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name={`manager[${index}].raterEmail1`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={manager.raterEmail1}
                                  onChange={handleChange}
                                  placeholder="Email"
                                  error={
                                    errors.manager?.[index]?.raterEmail1 &&
                                    touched.manager?.[index]?.raterEmail1
                                  }
                                  helperText={
                                    errors.manager?.[index]?.raterEmail1 &&
                                    touched.manager?.[index]?.raterEmail1
                                      ? errors.manager?.[index]?.raterEmail1
                                      : ''
                                  }
                                />
                              </TableCell>
                              <TableCell style={{ paddingTop: '0px' }}>
                                {/* <Typography
                                  variant="outlined"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={rater.raterRelationship}>
                                  Other
                                </Typography> */}
                                {/* <Grid item xs={3} className={classes.grid}> */}
                                <FormControl
                                  className={classes.subheading1}
                                  variant="standard"
                                  style={{ width: '100%' }}
                                  // error={
                                  //   errors.rater?.[index]?.raterRelationship &&
                                  //   touched.rater?.[index]?.raterRelationship
                                  // }
                                >
                                  <InputLabel id="scale-label">
                                    Select
                                  </InputLabel>
                                  <Select
                                    labelId="scale-label"
                                    // value={rater.raterRelationship}
                                    name={`manager[${index}].raterRelationship1`}
                                    // defaultValue="Self"
                                    onChange={handleChange}
                                    label="Manager"
                                    fullWidth>
                                    {managerList &&
                                      managerList.map((record, i) => {
                                        console.log(record, 'recordrecord')
                                        const {
                                          managerName = '',
                                          zRelationshipID = '',
                                        } = record
                                        console.log(managerName, 'recoerd')
                                        return (
                                          <MenuItem
                                            key={i}
                                            value={zRelationshipID}>
                                            {managerName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                  <FormHelperText
                                    error={
                                      errors.manager?.[index]
                                        ?.raterRelationship1 &&
                                      touched.manager?.[index]
                                        ?.raterRelationship1
                                    }>
                                    {errors.manager?.[index]
                                      ?.raterRelationship1 &&
                                    touched.manager?.[index]?.raterRelationship1
                                      ? errors.manager?.[index]
                                          ?.raterRelationship1
                                      : ''}
                                  </FormHelperText>
                                </FormControl>
                              </TableCell>
                              {index !== 0 && (
                                <TableCell>
                                  <span
                                    onClick={() =>
                                      handleDeleteIcon('manager', index)
                                    }>
                                    <DeleteIcon />
                                  </span>
                                </TableCell>
                              )}

                              {/* </Grid> */}
                              {/* <TableCell>
                                <Typography
                                  name={`raterRelationship`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth>
                                  Manager
                                // </Typography>
                                {/* <TextField
                      name={`raterRelationship`}
                      variant="outlined"
                      fullWidth
                      value={formik.raterRelationship}
                      onChange={formik.handleChange}
                    /> */}
                              {/* </TableCell>  */}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                      <Button
                        //   variant="contained"
                        className={classes.additem}
                        //   color="primary"
                        onClick={() =>
                          setFieldValue('manager', [
                            ...values.manager,
                            {
                              raterFirstName1: '',
                              raterEmail1: '',
                              raterLastName1: '',
                              raterRelationship1: '',
                            },
                          ])
                        }>
                        + Manager
                      </Button>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid style={{ marginTop: '4rem' }}>
                  <Typography variant="h6">3. Additional Feedback.</Typography>
                  <Typography variant="body2" style={{ marginTop: '10px' }}>
                    Add additional raters below.
                  </Typography>
                  <Typography variant="body2">
                    Note: You must add a minimum of 3 people per rater category
                    in order for their responses to be available in the report.
                    This is to protect rater anonymity. If you have less than 3
                    in each category, for example: 2 Peers, 1 Customer and 2
                    Direct Reports, then label all of them as Others so that the
                    feedback will be added to the report.
                  </Typography>
                  <TableContainer
                    component={Paper}
                    className={classes.tablecontainer}>
                    <Table className={classes.customTable}>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.subheading}>
                            Last Name
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            First Name
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            Email
                          </TableCell>
                          <TableCell className={classes.subheading}>
                            Rater Relationship
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {values?.rater.map((rater, index) => {
                          // const touchedLastName = getIn(touched, raterLastName)
                          // const errorLastName = getIn(errors, raterLastName)

                          // const touchedFirstName = getIn(
                          //   touched,
                          //   raterFirstName
                          // )
                          // const errorFirstName = getIn(errors, raterFirstName)

                          // const touchedEmail = getIn(touched, raterFirstName)
                          // const errorEmail = getIn(errors, raterFirstName)

                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <TextField
                                  name={`rater[${index}].raterLastName`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={rater.raterLastName}
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleBlur(e, index)
                                    // Additional logic after the onBlur event
                                  }}
                                  placeholder="Last Name"
                                  error={
                                    errors.rater?.[index]?.raterLastName &&
                                    touched.rater?.[index]?.raterLastName
                                  }
                                  helperText={
                                    errors.rater?.[index]?.raterLastName &&
                                    touched.rater?.[index]?.raterLastName
                                      ? errors.rater?.[index]?.raterLastName
                                      : ''
                                  }
                                  // onBlur={(e) => handleBlur(e, index)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        {/* manager[${index}].raterLastName1 */}
                                        {warningMsg?.[
                                          `raterLastName_${index}`
                                        ] && (
                                          <Tooltip
                                            title={
                                              <span>
                                                First letter in uppercase,
                                                others in any case.
                                                <br />
                                                Enter a mix of uppercase and
                                                lowercase letters.
                                              </span>
                                            }>
                                            <ErrorOutlineOutlinedIcon
                                              sx={{ color: '#E9D502' }}
                                            />
                                          </Tooltip>
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name={`rater[${index}].raterFirstName`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={rater.raterFirstName}
                                  onChange={(e) => {
                                    handleChange(e)
                                    handleBlur(e, index)
                                    // Additional logic after the onBlur event
                                  }}
                                  placeholder="First Name"
                                  error={
                                    errors.rater?.[index]?.raterFirstName &&
                                    touched.rater?.[index]?.raterFirstName
                                  }
                                  helperText={
                                    errors.rater?.[index]?.raterFirstName &&
                                    touched.rater?.[index]?.raterFirstName
                                      ? errors.rater?.[index]?.raterFirstName
                                      : ''
                                  }
                                  // onBlur={(e) => handleBlur(e, index)}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="start">
                                        {warningMsg?.[
                                          `raterFirstName_${index}`
                                        ] && (
                                          <Tooltip
                                            title={
                                              <span>
                                                First letter in uppercase,
                                                others in any case.
                                                <br />
                                                Enter a mix of uppercase and
                                                lowercase letters.
                                              </span>
                                            }>
                                            <ErrorOutlineOutlinedIcon
                                              sx={{ color: '#E9D502' }}
                                            />
                                          </Tooltip>
                                        )}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <TextField
                                  name={`rater[${index}].raterEmail`}
                                  variant="standard"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={rater.raterEmail}
                                  onChange={handleChange}
                                  placeholder="Email"
                                  error={
                                    errors.rater?.[index]?.raterEmail &&
                                    touched.rater?.[index]?.raterEmail
                                  }
                                  helperText={
                                    errors.rater?.[index]?.raterEmail &&
                                    touched.rater?.[index]?.raterEmail
                                      ? errors.rater?.[index]?.raterEmail
                                      : ''
                                  }
                                />
                              </TableCell>
                              <TableCell style={{ paddingTop: '0px' }}>
                                {/* <Typography
                                  variant="outlined"
                                  className={classes.subheading1}
                                  fullWidth
                                  value={rater.raterRelationship}>
                                  Other
                                </Typography> */}
                                {/* <Grid item xs={3} className={classes.grid}> */}
                                <FormControl
                                  className={classes.subheading1}
                                  variant="standard"
                                  style={{ width: '100%' }}
                                  error={
                                    errors.rater?.[index]?.raterRelationship &&
                                    touched.rater?.[index]?.raterRelationship
                                  }>
                                  <InputLabel id="scale-label">
                                    Other
                                  </InputLabel>
                                  <Select
                                    labelId="scale-label"
                                    value={rater.raterRelationship}
                                    name={`rater[${index}].raterRelationship`}
                                    // defaultValue="Self"
                                    onChange={handleChange}
                                    label="Other"
                                    fullWidth>
                                    {raterList &&
                                      raterList.map((record, i) => {
                                        const {
                                          zName = '',
                                          zRelationshipID = '',
                                        } = record
                                        console.log(record, 'recoerd')
                                        return (
                                          <MenuItem
                                            key={i}
                                            value={zRelationshipID}>
                                            {zName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                  <FormHelperText
                                    error={
                                      errors.rater?.[index]
                                        ?.raterRelationship &&
                                      touched.rater?.[index]?.raterRelationship
                                    }>
                                    {errors.rater?.[index]?.raterRelationship &&
                                    touched.rater?.[index]?.raterRelationship
                                      ? errors.rater?.[index]?.raterRelationship
                                      : ''}
                                  </FormHelperText>
                                </FormControl>
                                {/* </Grid> */}
                              </TableCell>
                              {index > 3 && (
                                <TableCell>
                                  <span
                                    onClick={() =>
                                      handleDeleteIcon('rater', index)
                                    }>
                                    <DeleteIcon />
                                  </span>
                                </TableCell>
                              )}
                            </TableRow>
                          )
                        })}
                      </TableBody>
                      <Button
                        //   variant="contained"
                        //   color="primary"
                        className={classes.additem}
                        onClick={() =>
                          setFieldValue('rater', [
                            ...values.rater,
                            {
                              raterFirstName: '',
                              raterEmail: '',
                              raterLastName: '',
                              raterRelationship: '',
                            },
                          ])
                        }>
                        + Rater
                      </Button>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid>
                  <CustomButton
                    className={classes.inprogressBtn}
                    variant="contained"
                    color="primary"
                    type="submit">
                    Submit
                    {loading && (
                      <CustomLoader loading={loading ? true : false} />
                    )}
                  </CustomButton>
                </Grid>
              </form>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
