import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './helpers/theme'
import ConfigureStore from './redux/store/ConfigureStore'
import { Provider } from 'react-redux'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { SnackbarProvider } from 'notistack'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Formik } from 'formik'
import Sample from './Sample'
const store = ConfigureStore()
const notistackRef = React.createRef()
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key)
}

ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      className="snackbarcustomized"
      ref={notistackRef}
      action={(key) => (
        <CloseIcon
          className="snackbar-closeicon"
          style={{
            borderLeft: '1px solid',
            paddingLeft: 'inherit',
            cursor: 'pointer',
          }}
          onClick={onClickDismiss(key)}
        />
      )}
      // maxSnack={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      {/* <React.StrictMode> */}
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Formik>
              <App />
            </Formik>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      {/* </React.StrictMode> */}
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
