import { Typography, Grid } from '@material-ui/core'
import { Skeleton } from '@mui/material'
import React from 'react'
import who_to_rate from '../../../../../../../../assets/images/step1.png'

const WhoToRate = ({ handleReset }) => {
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        Who am I rating in this survey?
      </Typography>
      <Typography variant="p">
        The names in the list Participant list on the left are the people you
        are being asked to rate.
        <br /> Please rate everyone in the list
      </Typography>
      {who_to_rate ? (
        <img
          src={who_to_rate}
          style={{ height: 'auto', width: '100%', paddingTop: '20px' }}
        />
      ) : (
        <Skeleton width="100%" height={300} />
      )}
    </div>
  )
}

export default WhoToRate
