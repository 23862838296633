import {
  AlignmentType,
  BorderStyle,
  Footer,
  Header,
  HeadingLevel,
  Document,
  ImageRun,
  OverlapType,
  Packer,
  Paragraph,
  RelativeHorizontalPosition,
  RelativeVerticalPosition,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  VerticalPositionAlign,
  WidthType,
  PageNumber,
  ShadingType,
  HeightRule,
  ExternalHyperlink,
  convertMillimetersToTwip,
} from 'docx'
import moment from 'moment'
import React from 'react'
import { saveAs } from 'file-saver'
import threesixty from '../assets/images/arrow.png'
import threesixtySurvey from '../assets/images/word/360survey.png'
import Summerysample from './360images/SummeryResultsByCompetency.png'
import compentencysample from './360images/SummaryResultsByRespondentGroup.png'
import TOPTENIMAGE from './360images/Top10.png'
import openEndimage from './360images/OpenEnded.png'
export async function individualreportgeneration(response) {
  const [data] = response
  const SummerysampleImage = await fetch(Summerysample).then((response) =>
    response.blob()
  )
  const TOPTENIMAGEfetch = await fetch(TOPTENIMAGE).then((response) =>
    response.blob()
  )
  const openEndimageblob = await fetch(openEndimage).then((response) =>
    response.blob()
  )
  const compentencysampleImage = await fetch(
    compentencysample
  ).then((response) => response.blob())
  const {
    strReportTitle,
    strPName,
    strReportDesc,
    organizationName,
    projectName,
    ownerName,
    endDate,
    dueDateFormat,
    addSummaryCompetencyInstructions = [],
    addSummaryResults = [],
    addSummaryRespondentInstructions = [],
    addMatrix = [],
    addCompetencyResults = [],
    addTop10Instructions = [],
    addTop10 = [],
    addDataItems = [],
    addOpenEndedInstructions = [],
    addOpenEnded = [],
  } = data
  const FONT_STYLE = 'Calibri'
  const headerImage = await fetch(threesixty).then((response) =>
    response.blob()
  )
  const [firstSummeryCompetencyInstruction] = addSummaryCompetencyInstructions
  const [firstSummeryResult] = addSummaryResults
  const {
    title: summerySampletitle,
    description: summerySampledescription,
    addSummaryCompetencyInstructionsData = [],
  } = firstSummeryCompetencyInstruction || {}
  const {
    title: summeryresulttitle,
    description: summeryresultdescription,
    addSummaryResultsData = [],
  } = firstSummeryResult || {}

  const [ScaleDetails] = addSummaryCompetencyInstructionsData
  const {
    strAsciScaleIntro,
    strAsciListBp1,
    strAsciListBp2,
    strAsciListBp3,
    data: scaleData,
  } = ScaleDetails || {}
  const [addSummaryRespondentFirstdata] = addSummaryRespondentInstructions
  const {
    title: summeryresultinstructiontitle,
    description: summeryresultinstructiondescription,
    addSummaryRespondentInstructionsData = [],
  } = addSummaryRespondentFirstdata || {}
  const [
    firstDatarespondentinstructionContent,
  ] = addSummaryRespondentInstructionsData
  const {
    strAsriListBp1,
    strAsriListBp2,
    strAsriListBp3,
    strAsriListBp4,
    strAddNotes,
    strAsriAnListBp1,
    strAsriAnListBp2,
    strAsriAnListBp3,
  } = firstDatarespondentinstructionContent || {}
  const [firstaddCompetencyResults] = addCompetencyResults
  const {
    title: firstaddCompetencyResultstitle,
    description: firstaddCompetencyResultsdescription,
    addCompetencyResultsData = [],
  } = firstaddCompetencyResults || {}
  const [firstaddTop10Instructions] = addTop10Instructions
  const {
    title: strAddNotesttitle,
    description: strAddNotestdescription,
    ati10sDesc2,
    reportImagePath,
    strGlobCompBp,
    strSuppCompBp,
    strAtiListBp3,
    strAtiListBp4,
    strAtiListBp5,
    strAddNotes: strAddNotesfirstaddTop10Instructions,
    strAtiAnListBp1,
    strAtiAnListBp2,
    strAtiAnListBp3,
    strAtiAnListBp4,
  } = firstaddTop10Instructions || {}

  const surveyLogo = await fetch(threesixtySurvey).then((response) =>
    response.blob()
  )

  const [addMatrixFirstdata] = addMatrix
  const {
    title: martixtitle,
    description: martixdescription,
    categoryNm = [],
    scale,
    addMatrixDetail = [],

    scale: colordata,
  } = addMatrixFirstdata || {}
  const [firstaddTop10] = addTop10
  const {
    strAtTopName,
    strAtTopDesc,
    strAtBotName,
    strAtBotDesc,
    strAtGapName,
    addSummaryTopTable = [],
    addSummaryTopTableBottom = [],
    addSummaryTopTableGap = [],
    addSummaryTbResources = [],
    strAtGapDesc2,
  } = firstaddTop10 || {}
  const [firstaddDataItems] = addDataItems
  const {
    title: firstaddDataItemstitle,
    description: firstaddDataItemsdescription,
    dataItem = [],
    dataItemsCustom = [],
  } = firstaddDataItems || {}

  const [firstaddOpenEndedInstructions] = addOpenEndedInstructions
  const {
    title: openendtitle,
    description: openenddescription,
    strOeiListBp1,
    strOeiListBp2,
  } = firstaddOpenEndedInstructions || {}
  const [firstaddOpenEnded] = addOpenEnded
  const {
    title: firstaddOpenEndedtitle,
    description: firstaddOpenEndeddescription,
    addOpenEndedData,
  } = firstaddOpenEnded || {}
  const fullWidth = {
    size: 100,
    type: WidthType.PERCENTAGE,
  }
  let properties = {
    page: {
      // size: {
      //   // orientation: PageOrientation.LANDSCAPE,
      //   width: convertMillimetersToTwip(220),
      // },
      margin: {
        // top: 0,
        // bottom: 0,
        right: 800,
        left: 800,
      },
    },
  }
  const borderNone = {
    top: {
      style: BorderStyle.NIL,
      space: 2,
    },
    bottom: {
      style: BorderStyle.NIL,
      space: 2,
    },
    left: {
      style: BorderStyle.NIL,
      space: 2,
    },
    right: {
      style: BorderStyle.NIL,
      space: 2,
    },
  }

  const averageFloat = (value) => {
    return '' + parseFloat(value).toFixed(2)
  }

  const Mailpage = {
    headers: {
      default: new Header({
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: organizationName,
                size: 20,
                font: FONT_STYLE,
              }),
            ],
            // heading: HeadingLevel.TITLE,
            alignment: AlignmentType.LEFT,
          }),
        ],
      }),
    },
    children: [
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: surveyLogo,
                        transformation: {
                          width: 100,
                          height: 100,
                        },
                      }),
                    ],
                    verticalAlign: VerticalPositionAlign.CENTER,
                  }),
                ],
                width: {
                  size: 30,
                  type: WidthType.DXA,
                },
                borders: borderNone,
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    text: '360 Group Report',
                    heading: HeadingLevel.TITLE,
                  }),
                  new Paragraph({
                    text: strPName,
                    heading: HeadingLevel.TITLE,
                  }),
                  new Paragraph({
                    text: strReportDesc,
                    heading: HeadingLevel.TITLE,
                  }),
                ],
                borders: borderNone,
                verticalAlign: VerticalPositionAlign.CENTER,
              }),
            ],
          }),
        ],
        float: {
          relativeHorizontalPosition: RelativeHorizontalPosition.LEFT,
          relativeVerticalPosition: RelativeVerticalPosition.CENTER,
          overlap: OverlapType.NEVER,
        },
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        layout: TableLayoutType.FIXED,
      }),
    ],
    footers: {
      default: new Footer({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Name',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            font: FONT_STYLE,
                            size: 29,
                            style: 'Strong',
                            text: projectName,
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                          new TextRun({
                            text: 'Report Data',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        alignment: AlignmentType.RIGHT,
                        children: [
                          new TextRun({
                            text: moment(new Date()).format('MM/DD/YYY'),
                            font: FONT_STYLE,
                            style: 'Strong',

                            size: 22,
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Manager',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: ownerName,

                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: 'Project Due Date',
                            font: FONT_STYLE,
                            size: 22,
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                      new Paragraph({
                        children: [
                          new TextRun({
                            text: dueDateFormat,
                            font: FONT_STYLE,
                            size: 22,
                            style: 'Strong',
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: fullWidth,
          }),
        ],
      }),
    },
  }
  const pageHeader = () => {
    return {
      default: new Header({
        children: [
          new Table({
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: organizationName || '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['Page ', PageNumber.CURRENT],
                          }),
                        ],
                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    borders: borderNone,
                  }),
                ],
              }),
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: '',
                      }),
                    ],
                    borders: borderNone,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        children: [
                          new TextRun({
                            children: ['360 Report ', 'Test User'],
                          }),
                          new ImageRun({
                            data: headerImage,
                            transformation: {
                              width: 30,
                              height: 30,
                            },
                          }),
                        ],

                        alignment: AlignmentType.RIGHT,
                      }),
                    ],
                    verticalAlign: AlignmentType.CENTER,
                    borders: borderNone,
                  }),
                ],
              }),
            ],
            width: fullWidth,
          }),
        ],
      }),
    }
  }
  let scales = []
  scaleData.map((scale) => {
    scales.push(
      new TableCell({
        children: [
          new Paragraph({
            children: [],
          }),
          new Paragraph({
            text: `${scale.zName}`,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            text: scale?.zDescription || '',
            alignment: AlignmentType.CENTER,
          }),
        ],
        indent: {
          size: 600,
          type: WidthType.DXA,
        },
        shading: {
          fill: rgbToHex(scale.color),
        },
      })
    )
  })

  const scaleTable = new Table({
    rows: [
      new TableRow({
        children: [...scales],

        height: { value: 1000, rule: HeightRule.ATLEAST },
      }),
    ],

    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  })

  const normalText = (text) => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          font: FONT_STYLE,
          size: 22,
        }),
      ],
    })
  }

  const pageFooter = () => {
    return {
      default: new Footer({
        children: [
          new Paragraph({
            text: '',
          }),
        ],
      }),
    }
  }
  const normalFontHeading = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          size: 30,
          font: FONT_STYLE,
        }),
      ],
    })
  }

  let boldDescribtion = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          size: 20,
          font: FONT_STYLE,
          bold: true,
        }),
      ],
    })
  }

  const normalHeadingBold = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text?.split('##')[0] || '',
          bold: true,
          size: 25,
          font: FONT_STYLE,
        }),
      ],
      // thematicBreak: true,
    })
  }
  const pageTtile = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 31,
          font: FONT_STYLE,
        }),
      ],
      thematicBreak: true,
    })
  }
  const leaveoneline = () => {
    return new Paragraph('')
  }
  const summerrUsultbycompentencysample = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(summerySampletitle),
      boldDescribtion(summerySampledescription),
      leaveoneline(),
      leaveoneline(),
      normalHeadingBold('Scale'),
      normalText(strAsciScaleIntro),
      scaleTable,
      new Paragraph({
        children: [
          new ImageRun({
            data: SummerysampleImage,
            transformation: {
              width: 600,
              height: 300,
            },
          }),
        ],
        verticalAlign: VerticalPositionAlign.CENTER,
      }),
      leaveoneline(),
      normalText(`1.  ${strAsciListBp1}`),
      normalText(`2.  ${strAsciListBp2}`),
      normalText(`3.  ${strAsciListBp3}`),

      // await chartSuportText(supplabel, suppData),
    ],
  }
  const table = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 30,
              type: WidthType.PERCENTAGE,
            },
            children: [new Paragraph('Hello1')],
          }),
          new TableCell({
            width: {
              size: 70,
              type: WidthType.PERCENTAGE,
            },
            children: [new Paragraph('Hello1')],
          }),
        ],
      }),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  })
  const table2 = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 50,
              type: WidthType.PERCENTAGE,
            },
            children: [new Paragraph('Hello2')],
          }),
          new TableCell({
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            children: [new Paragraph('Hello2')],
          }),
          new TableCell({
            width: {
              size: 25,
              type: WidthType.PERCENTAGE,
            },
            children: [new Paragraph('Hello2')],
          }),
        ],
      }),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
  })

  let headingTable = (text = '') => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 45,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: text,
                      size: 24,
                      bold: true,
                    }),
                  ],

                  alignment: AlignmentType.RIGHT,
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 55,
                type: WidthType.PERCENTAGE,
              },
              children: [],
            }),
          ],
        }),
      ],
      width: {
        size: 80,
        type: WidthType.PERCENTAGE,
      },
      alignment: AlignmentType.CENTER,
      borders: borderNone,
    })
  }

  let splitAllsummery = {}
  let summarymin = 0
  let summarymax = 0
  addSummaryResultsData.forEach((summ, index) => {
    if (index == 0) {
      summarymin = summ.min
      summarymax = summ.max
    }

    if (summarymin >= summ.min) {
      summarymin = summ.min
    }
    if (summarymax <= summ.max) {
      summarymax = summ.max
    }
    splitAllsummery[summ.campName] = {
      name: summ.campName,
      data: [
        ...(splitAllsummery[summ.campName]?.data || []),
        {
          ...summ,
        },
      ],
    }
  })

  let loopTable = (summ) => {
    let tableData = []
    let Heading = (heading = '', size = 50) => {
      return new TableCell({
        width: {
          size: size,
          type: WidthType.PERCENTAGE,
        },

        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: heading,
                size: 25,
              }),
            ],

            alignment: AlignmentType.RIGHT,
          }),
        ],
      })
    }
    let tablenormalText = (Textdata = '', size = 50, color = 'FFFFFF') => {
      return new TableCell({
        width: {
          size: size,
          type: WidthType.PERCENTAGE,
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: Textdata,
              }),
            ],

            alignment: AlignmentType.CENTER,
          }),
        ],
        shading: {
          fill: color,
        },
      })
    }
    //FFFFFF
    tableData.push(Heading(summ.zCategoryN, 45), Heading('', 5))
    console.log(summ.zCategoryN)
    if (summ.average == 0) {
      tableData.push(tablenormalText('N/A'))
    } else {
      let newSize = (summ.average / summarymax) * 50
      tableData.push(
        tablenormalText(
          averageFloat(summ.average),
          parseInt(newSize),
          rgbToHex(summ?.color || '255,255,255')
        )
      )
      tableData.push(tablenormalText('', parseInt(50 - newSize)))
    }
    return new Table({
      rows: [
        new TableRow({
          children: [...tableData],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: borderNone,
    })
  }

  let AllSummerData = []

  Object.entries(splitAllsummery).forEach((split) => {
    AllSummerData.push(headingTable(split[0]), leaveoneline())
    split[1].data.forEach((summ) => {
      AllSummerData.push(loopTable(summ), leaveoneline())
    })
  })

  const summerrUsultbycompentency = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(summeryresulttitle),
      boldDescribtion(summeryresultdescription),
      leaveoneline(),
      ...AllSummerData,
    ],
  }
  const summeryresultinstruction = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(summeryresultinstructiontitle),
      boldDescribtion(summeryresultinstructiondescription),
      leaveoneline(),
      new Paragraph({
        children: [
          new ImageRun({
            data: compentencysampleImage,
            transformation: {
              width: 600,
              height: 500,
            },
          }),
        ],
        verticalAlign: VerticalPositionAlign.CENTER,
      }),
      normalText(`1.  ${strAsriListBp1}`),

      normalText(`2.  ${strAsriListBp2}`),

      normalText(`3.  ${strAsriListBp3}`),
      normalText(`4.  ${strAsriListBp4}`),
      normalHeadingBold(strAddNotes),
      normalText(`1.  ${strAsriAnListBp1}`),
      normalText(`2.  ${strAsriAnListBp2}`),
      normalText(`3.  ${strAsriAnListBp3}`),
    ],
  }
  let colorRead = {
    '*': 'DCDCDC',
    1: 'FF6347',
    2: 'FFA500',
    3: '7CFC00',
  }

  function componentToHex(c) {
    var hex = c.toString(16)
    return hex.length == 1 ? '0' + hex : hex
  }

  function rgbToHex(value) {
    let splitcode = value?.split(',')

    return (
      componentToHex(parseInt(splitcode[0])) +
      componentToHex(parseInt(splitcode[1])) +
      componentToHex(parseInt(splitcode[2]))
    )
  }

  let ColorKeytable = []
  colordata.forEach((value) => {
    ColorKeytable.push(
      new TableCell({
        children: [
          new Paragraph({ children: [] }),
          new Paragraph({
            children: [
              new TextRun({
                text: value.scale,
                font: FONT_STYLE,
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
        ],
        shading: {
          fill: value.clr ? rgbToHex(value.clr) : 'DCDCDC',
        },
      })
    )
  })

  let matrixDatavalue = {}
  let headerdata = {}
  addMatrixDetail.forEach((value) => {
    console.log(value)
    headerdata[value.categoryNm] = []
    matrixDatavalue[value.strComporCapNm] = [
      ...(matrixDatavalue[value?.strComporCapNm] || []),
      value,
    ]
  })

  let lenghtHeader = Object.keys(headerdata).length
  let allValues = []

  Object.entries(matrixDatavalue).forEach((value, index) => {
    let innervalue = []
    let bold = false
    let color = 'ffffff'
    value[1].map((agerage) => {
      if (agerage.headerFont) {
        bold = true
      }
      if (agerage.headercolor) {
        color = rgbToHex(agerage.headercolor)
      }
      innervalue.push(
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: agerage.avg,
                  font: FONT_STYLE,
                }),
              ],
              alignment: AlignmentType.CENTER,
            }),
          ],
          shading: {
            fill: agerage.color ? rgbToHex(agerage.color) : 'DCDCDC',
          },
          borders: borderNone,

          width: {
            size: 40 / lenghtHeader,
            type: WidthType.PERCENTAGE,
          },
        })
      )
    })

    allValues.push(
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: value[0].replace('ZZZZZZZZZZZZZZZZZZZZ', ''),
                    font: FONT_STYLE,
                    bold: bold,
                  }),
                ],
                alignment: AlignmentType.LEFT,
              }),
            ],
            shading: {
              fill: color,
            },
            width: {
              size: 40,
              type: WidthType.PERCENTAGE,
            },
            borders: borderNone,
          }),
          ...innervalue,
        ],
        height: { value: 500, rule: HeightRule.ATLEAST },
      })
    )
  })

  let matrixCheckeddata = []
  if (allValues.length) {
    matrixCheckeddata.push(
      new Table({
        rows: [...allValues],

        width: {
          size: 75,
          type: WidthType.PERCENTAGE,
        },
      })
    )
  }

  const matriXdata = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(martixtitle),
      boldDescribtion(martixdescription),
      leaveoneline(),
      normalHeadingBold('Color Key'),
      new Table({
        rows: [
          new TableRow({
            children: [...ColorKeytable],
            height: { value: 700, rule: HeightRule.ATLEAST },
          }),
        ],

        width: {
          size: 50,
          type: WidthType.PERCENTAGE,
        },
      }),
      normalText(
        '	*	Indicates a competency with less than 3 respondents. Data is included in Average scores (see other report sections).'
      ),
      leaveoneline(),
      new Table({
        rows: [
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [],
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                width: {
                  size: 40,
                  type: WidthType.PERCENTAGE,
                },
                borders: borderNone,
              }),
              ...Object.keys(headerdata).map((value) => {
                return new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text: value,
                          font: FONT_STYLE,
                        }),
                      ],
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  width: {
                    size: 40 / lenghtHeader,
                    type: WidthType.PERCENTAGE,
                  },
                })
              }),
            ],
          }),
        ],

        width: {
          size: 80,
          type: WidthType.PERCENTAGE,
        },
      }),
      // leaveoneline(),
      ...matrixCheckeddata,
    ],
  }

  let splitcompetencyResult = {}
  let commin = 0
  let commax = 0
  addCompetencyResultsData.forEach((summ, index) => {
    if (index == 0) {
      commin = summ.min
      commax = summ.max
    }

    if (commin >= summ.min) {
      commin = summ.min
    }
    if (commax <= summ.max) {
      commax = summ.max
    }
    splitcompetencyResult[summ.strCompNm + summ.strCapNm] = {
      name: summ.strCompNm + summ.strCapNm,
      subname: summ.strCapNm || '',
      data: [
        ...(splitcompetencyResult[summ.strCompNm + summ.strCapNm]?.data || []),
        {
          ...summ,
        },
      ],
    }
  })

  const competencyHeader = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          bold: true,
          size: 28,
          font: FONT_STYLE,
        }),
      ],
      thematicBreak: true,
    })
  }

  let loopTablecompentency = (summ) => {
    let tableData = []
    let Heading = (heading = '', size = 40, alignment = 'RIGHT') => {
      return new TableCell({
        width: {
          size: size,
          type: WidthType.PERCENTAGE,
        },
        borders: borderNone,

        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: heading,
                size: 18,
                font: FONT_STYLE,
              }),
            ],

            alignment: AlignmentType[alignment],
          }),
        ],
      })
    }
    let normalText = (Textdata = '', size = 50, color = '255,255,255') => {
      return new TableCell({
        width: {
          size: parseInt(size),
          type: WidthType.PERCENTAGE,
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: Textdata,
                font: FONT_STYLE,
              }),
            ],

            alignment: AlignmentType.CENTER,
          }),
        ],
        borders: borderNone,
        shading: {
          fill: rgbToHex(color),
        },
      })
    }
    //FFFFFF
    tableData.push(Heading(summ.zCategoryNm, 35))
    tableData.push(Heading(summ.ratingCount, 5, 'CENTER'))

    if (summ.average == 0) {
      tableData.push(normalText('N/A'))
    } else {
      let newSize = (summ.average / commax) * 50
      tableData.push(
        normalText(averageFloat(summ.average), newSize, summ?.color)
      )
      tableData.push(normalText('', 50 - newSize))
    }
    return new Table({
      rows: [
        new TableRow({
          children: [...tableData],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: borderNone,
    })
  }
  const compentencyResultAlldata = []
  console.log(Object.values(splitcompetencyResult))
  Object.values(splitcompetencyResult).forEach((summ) => {
    compentencyResultAlldata.push(competencyHeader(summ.name))
    summ.data.forEach((values) => {
      compentencyResultAlldata.push(
        loopTablecompentency(values),
        leaveoneline()
      )
    })
  })

  const compentencyresultcontent = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(firstaddCompetencyResultstitle),
      boldDescribtion(firstaddCompetencyResultsdescription),
      leaveoneline(),
      ...compentencyResultAlldata,
    ],
  }

  const topfivegap = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(strAddNotesttitle),
      normalText(strAddNotestdescription),
      leaveoneline(),
      normalText(ati10sDesc2),
      new Paragraph({
        children: [
          new ImageRun({
            data: TOPTENIMAGEfetch,
            transformation: {
              width: 600,
              height: 200,
            },
          }),
        ],
        verticalAlign: VerticalPositionAlign.CENTER,
      }),
      normalText(`1.  ${strGlobCompBp}`),
      normalText(`2.  ${strSuppCompBp}`),
      normalText(`3.  ${strAtiListBp3}`),
      normalText(`4.  ${strAtiListBp4}`),
      normalText(`5.  ${strAtiListBp5}`),
      normalHeadingBold(strAddNotesfirstaddTop10Instructions),
      normalText(`1.  ${strAtiAnListBp1}`),
      normalText(`2.  ${strAtiAnListBp2}`),
      normalText(`3.  ${strAtiAnListBp3}`),
      normalText(`4.  ${strAtiAnListBp4}`),
    ],
  }

  let allToptable = []

  const toptableHeader = (headervalues) => {
    return new Paragraph({
      children: [
        new TextRun({ text: headervalues.competencyName, font: FONT_STYLE }),
        new TextRun({ text: `${'  :  '}`, font: FONT_STYLE }),

        new TextRun({ text: headervalues.capabilityName, font: FONT_STYLE }),
        new TextRun({ text: `${'  :  '}`, font: FONT_STYLE }),

        new TextRun({
          text: headervalues.zCaption,
          bold: true,
          font: FONT_STYLE,
        }),
      ],
      thematicBreak: true,
    })
  }
  let sclaesize = scaleData.length
  const toptableContent = (first, secod, third, color) => {
    console.log(first, secod, third, color)
    let average = (parseInt(third) / (sclaesize - 1)) * 50
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: first,
                }),
              ],
              width: {
                size: 40,
                type: WidthType.PERCENTAGE,
              },
              borders: borderNone,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: '' + secod,
                  alignment: AlignmentType.CENTER,
                }),
              ],

              borders: borderNone,

              width: {
                size: 10,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: third,
                }),
              ],
              shading: {
                fill: color,
              },
              width: {
                size: average,
                type: WidthType.PERCENTAGE,
              },
            }),
            new TableCell({
              children: [],
              width: {
                size: 50 - average,
                type: WidthType.PERCENTAGE,
              },
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    })
  }

  addSummaryTopTable.forEach((toptable) => {
    console.log(toptable)
    allToptable.push(toptableHeader(toptable), leaveoneline())
    allToptable.push(
      toptableContent(
        toptable.zCompareGroupName,
        '' + toptable.averageRatingCount,
        averageFloat(toptable.average),
        rgbToHex(toptable.color)
      )
    )
    allToptable.push(leaveoneline())

    allToptable.push(
      toptableContent(
        toptable.zCompareGroupNameSelf,
        '' + toptable.selfRatingCount,
        '' + toptable.selfRating,
        rgbToHex(toptable.colorself)
      )
    )
    allToptable.push(leaveoneline())
  })

  const [firstvalue] = addSummaryTbResources
  const { addSummaryTbResourceTable = [], strAtRsrcHdr } = firstvalue || {}

  const resouceheading = (firstvalue, secondvalue) => {
    return new Paragraph({
      children: [
        new TextRun({ text: firstvalue, size: 20, font: FONT_STYLE }),
        new TextRun({ text: ' ' }),

        new TextRun({ text: secondvalue, size: 20, font: FONT_STYLE }),
      ],
    })
  }
  let linkdata = (link, number) => {
    return new Paragraph({
      children: [
        new ExternalHyperlink({
          children: [
            new TextRun({
              text: `${number}, ${link.resName}`,
              style: 'Hyperlink',
              font: FONT_STYLE,
            }),
          ],
          link: link.linkurl.replace(
            'Hostname',
            localStorage.getItem('CANTOODOMAIN')
          ),
        }),
        new TextRun({ text: link.zDescription, font: FONT_STYLE }),
      ],
    })
  }
  let resounce = []
  addSummaryTbResourceTable.forEach((value, index) => {
    resounce.push(resouceheading(value.competencyName, value.capabilityName))
    resounce.push(linkdata(value, index + 1))
  })

  const summerytopfivegap = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(strAtTopName),
      normalText(strAtTopDesc),
      leaveoneline(),
      ...allToptable,
      leaveoneline(),
      leaveoneline(),
      normalHeadingBold(strAtRsrcHdr),
      leaveoneline(),
      ...resounce,
    ],
  }
  let allbottomfive = []
  addSummaryTopTableBottom.forEach((toptable) => {
    allbottomfive.push(toptableHeader(toptable), leaveoneline())
    allbottomfive.push(
      toptableContent(
        toptable.zCompareGroupName,
        '' + toptable.averageRatingCount,
        averageFloat(toptable.average),
        rgbToHex(toptable.color)
      )
    )
    allbottomfive.push(leaveoneline())

    allbottomfive.push(
      toptableContent(
        toptable.zCompareGroupNameSelf,
        '' + toptable.selfRatingCount,
        '' + toptable.selfRating,
        rgbToHex(toptable.colorself)
      )
    )
    allbottomfive.push(leaveoneline())
  })

  const summerybuttomfivegap = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(strAtBotName),
      normalText(strAtBotDesc),
      leaveoneline(),
      ...allbottomfive,
    ],
  }
  let allbiggap = []

  addSummaryTopTableGap.forEach((toptable) => {
    allbiggap.push(toptableHeader(toptable), leaveoneline())
    allbiggap.push(
      toptableContent(
        toptable.zCompareGroupName,
        '' + toptable.averageRatingCount,
        averageFloat(toptable.average),
        rgbToHex(toptable.color)
      )
    )
    allbiggap.push(leaveoneline())

    allbiggap.push(
      toptableContent(
        toptable.zCompareGroupNameSelf,
        '' + toptable.selfRatingCount,
        '' + toptable.selfRating,
        rgbToHex(toptable.colorself)
      )
    )
    allbiggap.push(leaveoneline())
  })

  const summerybiggap = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(strAtGapName),
      normalText(strAtGapDesc2),
      leaveoneline(),
      ...allbiggap,
    ],
  }

  let alldetailedResult = []
  let splitdetailedResult = {}
  dataItem.forEach((data) => {
    splitdetailedResult[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResult[data.strCompNm + data.strCapNm]?.datavalues ||
          []),
        ...data.dataItemsData,
      ],
    }
  })
  let customItem = {}
  dataItemsCustom.forEach((data) => {
    splitdetailedResult[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResult[data.strCompNm + data.strCapNm]?.datavalues ||
          []),
        ...data.dataItemsCustomData,
      ],
    }
  })
  console.log(customItem)

  const normalpageline = (text = '') => {
    return new Paragraph({
      children: [
        new TextRun({
          text: text,
          size: 20,
          font: FONT_STYLE,
        }),
      ],
      thematicBreak: true,
    })
  }
  let splitmin = 0
  let splitmax = 0

  let DetailsloopTable = (summ) => {
    let tableData = []
    let Heading = (heading = '') => {
      return new TableCell({
        width: {
          size: 45,
          type: WidthType.PERCENTAGE,
        },
        borders: borderNone,
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: heading,
                font: FONT_STYLE,
              }),
            ],

            alignment: AlignmentType.RIGHT,
          }),
        ],
      })
    }
    let rating = (reting = '') => {
      return new TableCell({
        width: {
          size: 5,
          type: WidthType.PERCENTAGE,
        },
        borders: borderNone,

        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: reting,
                font: FONT_STYLE,
              }),
            ],

            alignment: AlignmentType.CENTER,
          }),
        ],
      })
    }
    let normalText = (Textdata = '', size = 0, color = 'FFFFFF') => {
      return new TableCell({
        width: {
          size: size,
          type: WidthType.PERCENTAGE,
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: Textdata,
                font: FONT_STYLE,
              }),
            ],

            alignment: AlignmentType.CENTER,
          }),
        ],
        shading: {
          fill: color,
        },
      })
    }
    //FFFFFF
    tableData.push(Heading(summ.itemNorm))
    tableData.push(rating(summ.ratingCount))
    if (summ.average == 0) {
      tableData.push(normalText('N/A'))
    } else {
      let newSize = (summ.average / splitmax) * 50

      tableData.push(
        normalText(averageFloat(summ.average), newSize, rgbToHex(summ.color))
      )
      tableData.push(normalText('', 50 - newSize))
    }
    return new Table({
      rows: [
        new TableRow({
          children: [...tableData],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: borderNone,
    })
  }

  let splitdetailed = Object.values(splitdetailedResult).map((sp) => {
    let detailedObject = {}
    sp.datavalues.forEach((da, index) => {
      if (index == 0) {
        splitmin = da.min
        splitmax = da.max
      }

      if (splitmin >= da.min) {
        splitmin = da.min
      }
      if (splitmax <= da.max) {
        splitmax = da.max
      }

      detailedObject[da.zCaption] = {
        zCaption: da.zCaption,
        data: [
          ...(detailedObject[da.zCaption]?.data || []),
          { ...da, splitmin, splitmax },
        ],
      }
    })
    return { ...sp, datavalues: Object.values(detailedObject) }
  })
  let sclaeXaxies = []
  let sccaleInnervalue = (Textdata = '') => {
    return new TableCell({
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: Textdata,
              font: FONT_STYLE,
            }),
          ],

          alignment: AlignmentType.LEFT,
        }),
      ],
      width: {
        size: 50 / splitmax - 1,
        type: WidthType.PERCENTAGE,
      },
    })
  }
  for (let index = splitmin; index < splitmax; index++) {
    sclaeXaxies.push(sccaleInnervalue(index))
  }

  const scaleTableValye = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: '',
                  }),
                ],

                alignment: AlignmentType.LEFT,
              }),
            ],
            width: {
              size: 50,
              type: WidthType.PERCENTAGE,
            },
          }),
          ...sclaeXaxies,
        ],
      }),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: borderNone,
  })

  splitdetailed.forEach((spli) => {
    alldetailedResult.push(
      normalHeadingBold(spli.strCompNm + ':' + spli.strCapNm),
      leaveoneline()
    )
    spli.datavalues.map((dataval, index) => {
      alldetailedResult.push(
        normalpageline(index + 1 + ',' + dataval.zCaption || ''),
        leaveoneline()
      )
      dataval.data.forEach((tab) => {
        alldetailedResult.push(DetailsloopTable(tab), leaveoneline())
      })
      // alldetailedResult.push(scaleTableValye, leaveoneline())
      //scale
    })
  })

  const detailsItemResult = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(firstaddDataItemstitle),
      normalText(firstaddDataItemsdescription),
      leaveoneline(),
      ...alldetailedResult,
    ],
  }
  const openendContentsample = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(openendtitle),
      normalText(openenddescription),
      leaveoneline(),
      new Paragraph({
        children: [
          new ImageRun({
            data: openEndimageblob,
            transformation: {
              width: 600,
              height: 200,
            },
          }),
        ],
        verticalAlign: VerticalPositionAlign.CENTER,
      }),
      normalText(`1.  ${strOeiListBp1}`),
      normalText(`2.  ${strOeiListBp2}`),
    ],
  }

  let openendQuestions = {}

  addOpenEndedData.forEach((add) => {
    openendQuestions[add.zQuestionText] = {
      name: add.zQuestionText,
      data: [
        ...(openendQuestions[add.zQuestionText]?.data || []),
        { ...add.addOpenEndedDataSub[0] },
      ],
    }
  })

  const openendTable = (value) => {
    return new Table({
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 50,
                type: WidthType.PERCENTAGE,
              },
              children: [
                new Paragraph('' + value.zRelationShip),
                new Paragraph('' + value.zAns),
              ],
              shading: {
                fill: '7489ab',
              },
              borders: borderNone,
            }),
          ],
        }),
      ],

      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    })
  }
  let openendQuestionsAlldata = []

  Object.values(openendQuestions).forEach((values) => {
    openendQuestionsAlldata.push(normalHeadingBold(values.name))
    values.data.forEach((indata) => {
      openendQuestionsAlldata.push(openendTable(indata), leaveoneline())
    })
  })

  const openendContent = {
    properties,
    heading: pageHeader(),
    footers: pageFooter(),
    children: [
      pageTtile(firstaddOpenEndedtitle),
      normalText(firstaddOpenEndeddescription),
      leaveoneline(),
      ...openendQuestionsAlldata,
    ],
  }

  //openEndimageblob
  let allDocument = []
  if (summerySampletitle) allDocument.push(summerrUsultbycompentencysample)
  if (summeryresulttitle) allDocument.push(summerrUsultbycompentency)

  if (summeryresultinstructiontitle) allDocument.push(summeryresultinstruction)
  if (martixtitle) allDocument.push(matriXdata)

  if (firstaddCompetencyResultstitle) allDocument.push(compentencyresultcontent)
  if (strAddNotesttitle) allDocument.push(topfivegap)

  if (strAtTopName) allDocument.push(summerytopfivegap)
  if (strAtBotName) allDocument.push(summerybuttomfivegap)

  if (strAtGapName) allDocument.push(summerybiggap)
  if (firstaddDataItemstitle) allDocument.push(detailsItemResult)
  if (openendtitle) allDocument.push(openendContentsample)
  if (firstaddOpenEndedtitle) allDocument.push(openendContent)
  const doc = new Document({
    sections: [Mailpage, ...allDocument],
  })
  return {
    documnt: doc,
    name: ` ${strPName}_360_individual_${Date.now()}` + '.doc',
  }
  // Packer.toBlob(doc).then((blob) => {
  //   saveAs(blob, 'Individual.docx')
  // })
}
