import React, { Suspense, lazy, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  INITIAL_LAYOUT_PATH,
  SIGN_IN_PATH,
  VALID_SIGN_IN_PATH,
  PAGE_NOT_FOUND_PATH,
  SIGN_UP_PATH,
  DASHBOARD_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  NEW_PASSWORD_PATH,
  PROJECTS_PATH,
  TEAMSURVEYREPORT,
  THREESIXTYREPORT,
  TEAM_NEWUSER,
  SIGN_IN_PATH_NEW,
  CHECKING_RUTE,
  VALIDATION_SIGN_IN_PATH,
  ADMIN_SIGN_UP,
  ROSTER_FORM,
  THREESIXTYREPORTSELF,
} from './helpers/constants/RoutePaths'
import Layout from './components/layout'
import ForgotPassword from './components/ForgotPassword'
import { PrivateRoute, PublicRoute } from './components/common/CustomFunction'
import Projects from './components/thinkwise_360/projects'
import TeamReport from './components/Report/TeamReport'
import Threesixtyreport from './components/Report/Threesixtyreport'
import Newusersignup from './components/Newusersignup'

import NewSignin from './components/NewSignin'
import ExcelChk from './components/thinkwise_360/projects/steps/item_selection/ExcelChk'
import Signinold from './components/Signinold'
import TranslateComp from './helpers/constants/TranslateComp'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import AdminSignup from './components/AdminSignup'
import RosterFormNew from './components/thinkwise_360/projects/NewFormRoster/RosterFormNew'
import RosterLayout from './components/thinkwise_360/projects/NewFormRoster'
import { locationParams } from './function/HelperFunction'
import TimeOutPopup from './helpers/TimeOutPopup'
import ThreeSixtySelfReport from './components/Report/ThreeSixtySelfReport'

//Components
const Signin = lazy(() => import('./components/Signin'))
const Signup = lazy(() => import('./components/Signup'))
const PageNotFound = lazy(() => import('./components/PageNotFound'))
const FogotPassword = lazy(() => import('./components/ForgotPassword'))
const ResetPassword = lazy(() => import('./components/ResetPassword'))
const NewPassword = lazy(() => import('./components/NewPassword'))
const SigninNew = lazy(() => import('./components/NewSignin'))
console.log(window.location.origin)

function App() {
  // const [counter, setCounter] = React.useState(0)
  // const Greeting = React.memo((props) => {
  //   console.log('Greeting Comp render')
  //   return <h1>Hi {props.name}!</h1>
  // })

  // React.useEffect(() => {
  //   setInterval(() => {
  //     setCounter(counter + 1)
  //   }, 2000)
  // }, [])

  // console.log('App render')
  // return <Greeting name="Ruben" />
  // useEffect(() => {
  //   const handleTabClose = (event) => {
  //     event.preventDefault()
  //     console.log('beforeunload event triggered')
  //     return (event.returnValue = 'Are you sure you want to exit?')
  //   }

  //   window.addEventListener('beforeunload', handleTabClose)

  //   return () => {
  //     window.addEventListener('beforeunload', handleTabClose)
  //   }
  // }, [])
  // console.log('doc', document.referrer)
  // console.log('index chk historylen', window.history.length)
  // const history = useHistory()
  // const location = useLocation()

  // const data =' http://localhost:3000/signin/26357?taskId=367426/493068fd-3d6b-41e3-8791-707432894bbb'

  const data = window?.location.href
  const dataVAlue = data.split('?')
  const [flag, setFlag] = useState(false)
  useEffect(() => {
    setFlag(localStorage.getItem('WARNING_MSG'))
  }, [flag])
  const splitIndex = dataVAlue[0].split('/')
  const projectIdLocal = splitIndex[splitIndex.length - 1]

  var searchURL = locationParams(window?.location?.search)

  const taskId = searchURL?.taskId?.split('/')
  useEffect(() => {
    if (searchURL.taskId) {
      localStorage.setItem('TASK_ID', taskId[0])
      localStorage.setItem('PROJECT_ID', projectIdLocal)
    }
  }, [])
  return (
    <BrowserRouter>
      {flag && <TimeOutPopup openfl={false} />}

      {/* <div id="google_translate_element"></div> */}
      {/* <TranslateComp /> */}
      <Suspense
        fallback={
          <center>
            <CircularProgress style={{ marginTop: '20rem' }} color="primary" />
          </center>
        }>
        <Switch>
          <Route
            exact
            path={TEAMSURVEYREPORT + '/:data'}
            component={TeamReport}
          />
          <Route
            exact
            path={THREESIXTYREPORT + '/:data'}
            component={Threesixtyreport}
          />
          <Route
            exact
            path={THREESIXTYREPORTSELF + '/:data'}
            component={ThreeSixtySelfReport}
          />
          <Route
            exact
            path={ROSTER_FORM + `/:projectIdLocal`}
            component={RosterLayout}
          />
          <PublicRoute exact path={SIGN_UP_PATH} component={Signup} />
          <PublicRoute exact path={ADMIN_SIGN_UP} component={AdminSignup} />
          <PublicRoute exact path={VALID_SIGN_IN_PATH} component={Signin} />

          <PublicRoute exact path={TEAM_NEWUSER} component={Newusersignup} />
          <Route exact path={CHECKING_RUTE} component={ExcelChk} />
          <PublicRoute exact path={SIGN_IN_PATH} component={Signin} />
          <PublicRoute exact path={SIGN_IN_PATH_NEW} component={NewSignin} />
          <Route exact path={FORGOT_PASSWORD_PATH} component={FogotPassword} />
          <Route exact path={RESET_PASSWORD_PATH} component={ResetPassword} />
          <Route exact path={NEW_PASSWORD_PATH} component={NewPassword} />
          <PrivateRoute exact path={DASHBOARD_PATH} component={Layout} />
          <PrivateRoute path={INITIAL_LAYOUT_PATH} component={Layout} />
          <PrivateRoute path={PAGE_NOT_FOUND_PATH} component={PageNotFound} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
