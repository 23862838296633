import React, { useEffect, useState } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  InputLabel,
  FormLabel,
  Select,
  MenuItem,
  Accordion,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormik } from 'formik'
import DateFnsUtils from '@date-io/date-fns'

import * as Yup from 'yup'
import CustomButton from '../common/CustomButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import FormatSizeIcon from '@material-ui/icons/FormatSize'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import DatePicker from 'react-datepicker'

// import 'react-datepicker/dist/react-datepicker.css'
import SnoozeIcon from '@material-ui/icons/Snooze'
import AlarmIcon from '@material-ui/icons/AddAlarm'
import { InputAdornment } from '@material-ui/core'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers'
import {
  LAUNCH_360_PROJECT_URL,
  GET_USER_RELATIONSHIP_LIST,
  TEAM_SHEDULE,
  TEAM_SURVEYCONFIRM,
  TEAM_GET_SCHEDULELAUNCH,
  TEAM_GET_TIMELINE,
  TEAM_CREATE_LAUNCH_TIME_LINE,
  GET_LAUNCH_DATE,
  UPDATE_LAUNCH_DATE,
  LAUNCH_DATE_URL,
} from '../../helpers/constants/ApiRoutes'
import { API } from '../../helpers/API'
import { useSnackbar } from 'notistack'
import { CustomMessage } from '../../function/HelperFunction'
import { useHistory, useParams } from 'react-router'
import {
  getUserId,
  getOrganizationId,
  authHeader,
} from '../common/CustomFunction'
import moment from 'moment'
import {
  TEAMSURVEYPATH,
  TEAMSURVEYTASK,
} from '../../helpers/constants/RoutePaths'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import { TEAMSURVEY_CURRENTSTEP } from '../../redux/action/ActionType'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  grid: {
    padding: '6px',

    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  boxBorder: {
    borderRight: '2px solid #eee',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: '140',
  },
  launchBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default function Tslaunchproject(props) {
  const classes = useStyles()
  const [isSchudule, setisSchudule] = useState(false)
  const [selectedDate, setselectedDate] = useState('')
  const [launch, setLaunch] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [projectTypeget, setprojectTypeget] = useState({})
  const [lid, setLaunchID] = useState()
  const { projectname } = props
  const { tsprojectid } = useParams()
  const [lflag, setLflag] = useState(false)
  const dispatch = useDispatch()

  const launchdateSave = async () => {
    const config = {
      method: 'POST',
      url: LAUNCH_DATE_URL,
      headers: authHeader(),
      data: {
        projectID: tsprojectid,
        launchDate: moment(new Date()).format('YYYY-MM-DD'),
        launchTime: selectedDate ? moment(new Date(selectedDate)).hours() : '',
      },
    }
    try {
      const response = await API(config)
      console.log('response', response)
    } catch (error) {
      console.log(error)
    }
  }
  const handleLaunch = async () => {
    setLflag(true)
    // if (!isSchudule) {
    //   CustomMessage(
    //     'please schedule the date before launch the project',
    //     'error',
    //     enqueueSnackbar
    //   ) //call toster
    //   return null
    // }
    // setselectedDate(new Date())
    // schududuleLaunch(false)
    launchdateSave()
    setLaunch(true)
    const options = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_SURVEYCONFIRM,
      params: {
        ProjectID: parseInt(tsprojectid),
        UserID: getUserId(),
      },
    }

    try {
      const response = await API(options)
      if (response.status === 200) {
        // history.push(TEAMSURVEYPATH)
        history.push(`/teamsurveyprojects/view/${tsprojectid}`)
        CustomMessage('Successful Project Launch', 'success', enqueueSnackbar)
        //call toster
        dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: 0 })
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
      }
    } finally {
      setLaunch(false)
    }
  }

  const schududuleLaunch = async () => {
    console.log('schedu', selectedDate)
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_SHEDULE,
      params: {
        LaunchID: lid,

        ProjectID: parseInt(tsprojectid),
        ProjectName: projectname,
        LaunchDate: selectedDate
          ? moment(selectedDate).format('YYYY-MM-DD')
          : moment(new Date()).format('YYYY-MM-DD'),
        Active: true,
        UserID: getUserId(),
        LaunchTime: selectedDate
          ? moment(new Date(selectedDate)).format('hh:mm a')
          : '',
      },
    }
    try {
      const responst = await API(config)
      const { data } = responst.data
      const launchconfig = {
        method: 'POST',
        headers: authHeader(),

        url: TEAM_CREATE_LAUNCH_TIME_LINE,
        params: {
          ProjectID: parseInt(tsprojectid),
          UserID: getUserId(),
          TimeLine: projectTypeget.zTImeLineType,
          TimelineType: projectTypeget.zTimeLineTypeID,
        },
      }
      const launchTimeli = await API(launchconfig)
      CustomMessage('Project schuduled successfull', 'success', enqueueSnackbar) //call toster
      history.push(TEAMSURVEYPATH)

      // handleLaunch()
    } catch (error) {
      if (error.response) {
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar)
      }
    }
  }
  const schududuleLaunchConfirm = (e) => {
    console.log('date', selectedDate, moment(new Date(selectedDate)).hours())
    let fd = moment(new Date()).format('YYYY-MM-DD')
    e.stopPropagation()
    if (!isSchudule) {
      CustomMessage('please schedule the date', 'error', enqueueSnackbar) //call toster
      return null
    }
    // const { name, lname, mail, resid, userid } = e.currentTarget.dataset
    Swal.fire({
      title: 'Schedule',
      text: 'Your project is set to launch on the date and time selected.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        schududuleLaunch()
      } else {
      }
    })
  }

  const schuduleHandle = (e) => {
    console.log('value', e)
    setselectedDate(e)
    setisSchudule(true)
  }

  console.log(launch, !selectedDate)
  //TEAM_GET_SCHEDULELAUNCH
  const getSchedule = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_GET_SCHEDULELAUNCH,
      params: {
        ProjectID: parseInt(tsprojectid),
      },
    }
    try {
      const responst = await API(config)
      const { data } = responst.data
      console.log(data[0].zLaunchID, 'date lau')
      setLaunchID(data[0].zLaunchID)
    } catch (er) {
      console.error(er)
    }
  }
  const getTimeline = async () => {
    const addProjectconfig = {
      method: 'GET',
      headers: authHeader(),
      url: TEAM_GET_TIMELINE,
      params: {
        ProjectID: tsprojectid,
      },
    }
    const projectCOnfig = await API(addProjectconfig)
    const { data } = projectCOnfig.data

    const { zTImeLineType, zTimeLineTypeID } = data[0] || {}
    setprojectTypeget(data[0])
  }
  console.log(projectTypeget)
  const getLaunchDate = async () => {
    const config = {
      method: 'GET',
      url: GET_LAUNCH_DATE,
      headers: authHeader(),
      params: {
        ProjectID: tsprojectid,
      },
    }
    const response = await API(config)
    const { data } = response.data
    let dd = `${data[0].zLaunchDate} ${data[0].zLaunchTime}`
    console.log('response', dd, `${data[0].zLaunchDate} ${data[0].zLaunchTime}`)
    var m = new Date(data[0].zLaunchDate)
    m.setHours(data[0].zLaunchTime)
    // time.setHours(data[0].zLaunchTime)
    console.log('time', m)
    setselectedDate(m)
  }

  console.log('select', moment(new Date(selectedDate)).format('hh:mm a'))
  const updateLaunchDate = async () => {
    const config = {
      method: 'POST',
      url: UPDATE_LAUNCH_DATE,
      headers: authHeader(),
      params: {
        ProjectID: tsprojectid,
        ProjectName: projectname,
        LaunchDate: selectedDate
          ? moment(new Date(selectedDate).format('YYYY-MM-DD'))
          : moment(new Date().format('YYYY-MM-DD')),
        Active: true,
        UserID: getUserId(),
        LaunchTime: selectedDate ? moment(new Date(selectedDate)).hours() : '',
      },
    }
    const response = await API(config)
    const { data } = response.data

    console.log('response', data)
  }
  useEffect(() => {
    console.log('useredffect')
    getTimeline()
    getLaunchDate()
    getSchedule()
  }, [])

  return (
    <Grid item xs={12}>
      <div>
        <Paper className={classes.paper}>
          {/* Table tools */}
          <Toolbar className={classes.root}>
            <Typography
              className={classes.tblHead}
              variant="h6"
              id="tableTitle"
              component="div">
              Launch Project
            </Typography>
          </Toolbar>
          <Divider />
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={6} className={classes.boxBorder}>
              <p
                style={{
                  textAlign: 'center',
                  fontWeight: '600',
                }}>
                {'Your Project is Ready to Launch!'}
              </p>
              <div className={classes.launchBtnContainer}>
                <CustomButton
                  className={classes.dateBtn}
                  // disabled={launch || !selectedDate}
                  onClick={handleLaunch}>
                  Launch Now
                </CustomButton>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              className={classes.boxBorder}
              justifyContent="center">
              <center>
                <p style={{ fontWeight: '600' }}>Schedule launch date</p>
                <div style={{ marginTop: '2rem' }}>
                  {/* <TextField
                    id="date"
                    // label="Birthday"
                    min={moment(new Date()).format('YYYY-MM-DD')}
                    type="date"
                    onChange={schuduleHandle}
                    value={moment(selectedDate).format('YYYY-MM-DD')}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDateTimePicker
                        margin="normal"
                        id="date-picker-dialog"
                        // helperText="select hours only"
                        // label="Date picker dialog"
                        minDate={new Date()}
                        minutesStep={0}
                        format="MM/dd/yyyy hh:mm a"
                        ampm={true}
                        // value={null}
                        value={selectedDate ? new Date(selectedDate) : null}
                        onChange={schuduleHandle}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <CustomButton
                    // type="submit"
                    type="button"
                    style={{ marginRight: '21px' }}
                    colour="primaryButton"
                    id="teamsurveyFormred"
                    onClick={schududuleLaunchConfirm}>
                    Schedule Launch
                  </CustomButton>
                </div>
                {/* <CustomButton
                  onClick={schududuleLaunch}
                  disabled={!selectedDate}
                  colour="primaryButton"
                  type="submit">
                  Schedule Launch
                </CustomButton> */}
              </center>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </Grid>
  )
}
