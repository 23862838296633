import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Box,
  TableHead,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../assets/images/company_logo.jpg'
import CustomButton from '../../common/CustomButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CustomTableHeader from '../../common/CustomTableHeader'
import { PROJECT_SETUP_TYPE } from '../../../helpers/constants/RoutePaths'
import CustomLink from '../../common/CustomLink'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import TableSortLabel from '@material-ui/core/TableSortLabel'

import CustomTableEmptyRecord from '../../common/CustomTableEmptyRecord'
import {
  GET_PROJECT_LIST_URL,
  DELETE_PROJECT,
  GET_PROJECT_LIST_ALL_URL,
  GET_THREE_SIXTY_PROJECT_LIST,
} from '../../../helpers/constants/ApiRoutes'
import DeleteIcon from '@material-ui/icons/Delete'
import { API } from '../../../helpers/API'
import { useDispatch } from 'react-redux'
import { getCategory } from '../../../redux/action'
import {
  getOrganizationId,
  getUserId,
  getUserAdminRole,
  authHeader,
} from '../../common/CustomFunction'
import Swal from 'sweetalert2'
import {
  CHECKED_ITEM_LIST,
  SAVE_CURRENT_STEP,
} from '../../../redux/action/ActionType'
import { getComparator, stableSort } from '../../../function/HelperFunction'
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
  headStyle: {
    backgroundColor: theme.palette.common.white,
    padding: 10,
    fontWeight: 'bold',
    fontFamily: 'Nunito-SemiBold !important',
    textAlign: 'left',
    letterSpacing: '0.07px !important',
    color: '#000000DE',
    opacity: 1,
    fontSize: '14px',
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    // fontWeight: '600 !important',
    letterSpacing: '0.06px',
  },
  tablecontainer: {
    marginTop: '1rem',
    // maxHeight: 440,
    minHeight: '40vh',
    //overflowX: 'hidden',
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
  },
  btnWrapper: {
    display: 'flex',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  surveyBtn: {
    backgroundColor: theme.successColor,
    margin: '8px',
  },
  circularloader: {
    display: 'flex',
    justifyContent: 'center',
    width: '700%',
    paddingTop: '40%',
  },
  typograpyhtstyle: {
    // fontWeight: '600 !important',
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  projectName: {
    fontWeight: '500 !important',
  },
  tablecell: {
    fontFamily: 'Nunito-Regular !important',
  },
  navigation: {
    textAlign: 'left',
    letterSpacing: '0.08px',
    color: '#0000008A',
    opacity: 1,
  },
  selectEd: {
    color: '#1565C0',
  },
}))

//Table Columns

export default function Projects() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [allprojectflag, setallprojectflag] = useState(false)
  const [projects, setProjects] = useState([])
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('zName')
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    console.log(property, 'property')
    setOrderBy(property)
  }

  if (allprojectflag == true) {
    var tableColumns = [
      { id: 'zName', title: 'Name', sort: true },
      { id: 'zProgressText', title: 'Progress', sort: true },
      { id: 'zOwnerName', title: 'Owner Name', sort: true },
      //{ id: 'zDateCreatedFormat', title: 'Launch date', sort: true },
      { id: 'zDueDateFormat', title: 'Due Date', sort: true },
      { id: 'zProjectStatusName', title: 'Status', sort: true },
      { id: 'zOwnerName', title: 'Action' },
    ]
  } else {
    var tableColumns = [
      { id: 'zName', title: 'Name', sort: true },
      { id: 'zProgressText', title: 'Progress', sort: true },
      //{ id: 'zDateCreatedFormat', title: 'Launch date', sort: true },
      { id: 'zDueDateFormat', title: 'Due Date', sort: true },
      { id: 'zProjectStatusName', title: 'Status', sort: true },
      { id: 'zOwnerName', title: 'Action' },
    ]
  }

  const getProjectList = async () => {
    const postConfig = {
      url: GET_THREE_SIXTY_PROJECT_LIST,
      method: 'POST',
      headers: authHeader(),
      data: {
        userId: getUserId(),
        organizerId: getUserId(),
        ownerId: getUserId(),
        projectTypeId: '2',
        siteLvl: 'true',
        projectID: 0,
        organizationID: getOrganizationId(),
      },
    }
    setLoading(true)
    try {
      const response = await API(postConfig)
      const resultData = response.data.Data
      // const filterData = resultData.filter(result=> result.zProjectTypeID === 2 && result.zOwnerID === 1)
      const filterData = resultData
        .filter((result) => result.zProjectTypeID === 2)
        .map((val) => ({
          ...val,
          zDueDateFormat:
            moment(val.zDueDateFormat).format('YYYY/MM/DD') || 'N/A',
        }))
      setProjects(filterData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const getProjectListAll = async () => {
    const postConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT_LIST_ALL_URL,
      params: {
        UserID: getUserId(),
        ProjectType: '2',
        SiteLvl: 'true',
        OrganizationID: getOrganizationId(),
      },
    }
    setLoading(true)
    try {
      const response = await API(postConfig)
      const resultData = response.data.Projects

      // const filterData = resultData.filter(result=> result.zProjectTypeID === 2 && result.zOwnerID === 1)
      const filterData = resultData
        .filter((result) => result.zProjectTypeID === 2)
        .map((val) => ({
          ...val,
          zDueDateFormat:
            moment(val.zDueDateFormat).format('YYYY/MM/DD') || 'N/A',
        }))
      console.log('filterData', filterData)
      console.log('filterDatalength', filterData.length)
      setProjects(filterData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getProjectList()
    dispatch({ type: CHECKED_ITEM_LIST, payload: [] })
    dispatch({ type: SAVE_CURRENT_STEP, payload: 0 })
  }, [])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const removeProject = (pid, oid) => {
    console.log()
    Swal.fire({
      title:
        'Deleting this project will permanently remove it from the project list. Are you sure you want to proceed?',
      showDenyButton: true,
      // showCancelButton: true,
      confirmButtonText: 'Yes',
      denyButtonText: 'No',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
        denyButton: 'order-3',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProject(pid, oid)
        //Swal.fire('Saved!', '', 'success')
      } else if (result.isDenied) {
        Swal.fire('Project is not deleted', '', 'info')
      }
    })
  }

  const deleteProject = async (pid, oid) => {
    const postConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_PROJECT,
      params: {
        UserID: getUserId(),
        ownerID: oid,
        ProjectID: pid,
        SessionID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    }
    try {
      const response = await API(postConfig)
      Swal.fire('Your Project was deleted successfully!', '', 'success')
      getProjectList()
    } catch (error) {
      console.error(error)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const changeProjectList = () => {
    setallprojectflag(false)
    getProjectList()
  }
  const changeProjectListAll = () => {
    console.log('all')
    setallprojectflag(true)
    getProjectListAll()
  }

  const tableHeadProps = { tableColumns }

  const handleInsetUp = (id) => {
    console.log(id, 'haiaiis')
    if (id === 'In Setup') {
      localStorage.setItem('PREVIOUS_PROJECT', true)
    } else {
      localStorage.removeItem('PREVIOUS_PROJECT')
    }
  }

  return (
    <div>
      <Grid container spacing={1} item sm={12} lg={12} md={12}>
        {/* <Grid item xs={6} lg={6} md={6}>
          <Typography
            className={classes.typograpyhtstyle}
            variant="h6"
            gutterBottom>
            ThinkWise 360 Projects
          </Typography>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.selectEd} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.selectEd} color="textPrimary">
              ThinkWise 360
            </Typography>
            <Typography className={classes.navigation} color="textPrimary">
              Projects
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <div
            className={classes.sideHead}
            style={{ display: 'flex', justifyContent: 'right' }}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div>
        </Grid>
        <Grid item sm={12} lg={12} md={12}>
          <Divider />
        </Grid> */}
        <Grid item xs={12} className={classes.btnWrapper}>
          <div>
            <CustomLink to={PROJECT_SETUP_TYPE}>
              <CustomButton style={{ marginLeft: 0 }} colour="primaryButton">
                Create New 360{' '}
              </CustomButton>
            </CustomLink>
          </div>
          <div>
            {['ThinkWiseAdmin', 'PartnerAdmin'].includes(
              '' + getUserAdminRole()
            ) && (
              <CustomButton
                style={{ marginLeft: 0 }}
                colour="secondaryButton"
                onClick={
                  allprojectflag ? changeProjectList : changeProjectListAll
                }>
                {allprojectflag ? 'My projects' : 'All Projects'}
              </CustomButton>
            )}
          </div>
          <div className={classes.btnContainer}>
            {/* <CustomButton
              style={{ marginLeft: '' }}
              className={classes.surveyBtn}>
              Purchase Surveys{' '}
            </CustomButton>
            <p style={{ margin: '0px 0 0 10px', fontSize: '12px' }}>
              Surveys Available : <b>10</b>
            </p> */}
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1rem' }}>
          <div>
            <Paper className={classes.paper}>
              {/* Table tools */}
              <Toolbar className={classes.root}>
                <Typography
                  className={classes.tblHead}
                  variant="h6"
                  id="tableTitle"
                  component="div">
                  Projects
                </Typography>
                <Tooltip
                  title={
                    <>
                      <MoreVertIcon />
                      More
                    </>
                  }>
                  <IconButton
                    className={classes.exportBtn}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem>Option</MenuItem>
                </Menu>
              </Toolbar>
              <Divider />

              {/* Table  */}
              <TableContainer
                component={Paper}
                className={classes.tablecontainer}>
                <Table
                  stickyHeader
                  size="small"
                  className={classes.customTable}>
                  {/* Table Header */}
                  <CustomTableHeader
                    {...tableHeadProps}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  {/* Table Header */}

                  {/* Table Body */}
                  {loading && (
                    <TableBody>
                      <div className={classes.circularloader}>
                        <CircularProgress size={28} />
                      </div>
                    </TableBody>
                  )}
                  {!loading && (
                    <TableBody>
                      {stableSort(projects, getComparator(order, orderBy)).map(
                        (project, index) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell className={classes.tablecell}>
                                  <Link
                                    to={`${
                                      project.zProjectStatusID === 1
                                        ? `/projects/edit/${project.zProjectID}`
                                        : `/projects/view/${project.zProjectID}`
                                    }`}
                                    onClick={() =>
                                      handleInsetUp(project?.zProjectStatusName)
                                    }
                                    className={classes.linkStyle}>
                                    {project.zName}
                                  </Link>
                                </TableCell>
                                <TableCell className={classes.tablecell}>
                                  {project.zProgress}% {project.zProgressText}
                                </TableCell>

                                {allprojectflag && (
                                  <TableCell className={classes.tablecell}>
                                    {project.zOwnerName}
                                  </TableCell>
                                )}
                                <TableCell className={classes.tablecell}>
                                  {moment(project.zDueDateFormat).format(
                                    'MM/DD/YYYY'
                                  )}
                                </TableCell>
                                <TableCell className={classes.tablecell}>
                                  {project.zProjectStatusName}
                                </TableCell>
                                <TableCell className={classes.tablecell}>
                                  <IconButton
                                    className={classes.exportBtn}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true">
                                    <DeleteIcon
                                      onClick={removeProject.bind(
                                        null,
                                        project.zProjectID,
                                        project.zOwnerID
                                      )}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </>
                          )
                        }
                      )}

                      {/* <CustomTableEmptyRecord
                      columnsCount={tableColumns.length}
                    /> */}
                    </TableBody>
                  )}
                  {/* Table Body */}
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
