import React, { Fragment, useEffect, useState } from 'react'
import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../assets/images/company_logo.jpg'
import CustomButton from '../../common/CustomButton'
import {
  CREATE_NEW_360_PATH,
  PROJECTS_PATH,
} from '../../../helpers/constants/RoutePaths'
import CustomLink from '../../common/CustomLink'
import { GET_PREVIOUS_PROJECT_LIST_URL } from '../../../helpers/constants/ApiRoutes'
import { authHeader, getUserId } from '../../common/CustomFunction'
import { API } from '../../../helpers/API'
import { useHistory } from 'react-router-dom'
import ProjectInformation from './steps/ProjectInformation'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    // '& .MuiGrid-spacing-xs-2 > .MuiGrid-item ':{
    //     paddingBottom:"8rem"
    // }
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    // flex: '1 1 100%',
    fontSize: '17px',
    fontFamily: 'Nunito-SemiBold !important',
  },
  txtBlock: {
    textAlign: 'center',
  },
  boxBorder: {
    borderRight: '2px solid #eee',
    marginTop: '8px',
    lineHeight: '40px',
    display: 'flex',
    justifyContent: 'start',
    [theme.breakpoints.down('xs')]: {
      borderRight: 'unset !important',
      borderBottom: '2px solid #eee !important',
      marginLeft: '20px',
    },
  },
  boxside: {
    lineHeight: '30px',
    marginTop: '8px',
    [theme.breakpoints.down('xs')]: {
      borderRight: 'unset !important',
      borderBottom: '2px solid #eee !important',
      marginLeft: '20px',
    },
  },
  txtTitle: {
    fontSize: '24px',
    fontFamily: 'Roboto-Regular !important',
    marginBottom: '0px',
  },
  txtDesc: {
    fontSize: '11px',
    fontFamily: 'Roboto-Regular !important',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  custombutton: {
    marginTop: '1.8px',
  },
  typograpyhtstyle: {
    fontFamily: 'Nunito-SemiBold !important',
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  selected: {
    fontFamily: 'Nunito-Regular !important',

    color: '#1565C0 !important',
  },
  unselected: {
    textAlign: 'left',
    letterSpacing: '0.08px !important',
    fontFamily: 'Nunito-Regular !important',
    color: '#0000008A !important',
    opacity: 1,
  },
  boxtable: {
    placeItems: 'self-start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}))

export default function ChooseSetupType(props) {
  const classes = useStyles()
  const history = useHistory()
  const [previousProjects, setPreviousProjects] = useState([])
  const [templateList, setTemplateList] = useState([])
  const [currentProject, setProject] = useState('')
  console.log(currentProject, 'currentProject')
  const [currentTemplate, setTemplate] = useState('')
  const { handleCreateType } = props

  const handleCreateTypeClick = () => {
    localStorage.setItem('PREVIOUS_PROJECT', true)
  }

  const getPreviousProjectList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PREVIOUS_PROJECT_LIST_URL,
      params: {
        UserId: getUserId(),
      },
    }
    const response = await API(config)
    if (response.status) {
      const { previousProject, template } = response.data
      setPreviousProjects(previousProject)
      setTemplateList(template)
    }
  }

  const handlePreProject = async () => {
    currentProject &&
      history.push(`${CREATE_NEW_360_PATH}?projectId=${currentProject}`)
    localStorage.setItem('PREVIOUS_PROJECT', true)
  }

  const handlePreTemplate = async () => {
    currentTemplate &&
      history.push(`${CREATE_NEW_360_PATH}?projectID=${currentTemplate}`)
  }

  useEffect(() => {
    getPreviousProjectList()
  }, [])

  return (
    <Grid container spacing={1} item sm={12} lg={12} md={12}>
      {/* <Grid item xs={6} lg={6} md={6}>
        <Typography
          className={classes.typograpyhtstyle}
          variant="h6"
          gutterBottom>
          ThinkWise 360 Survey Setup
        </Typography>

        <Breadcrumbs aria-label="breadcrumb">
          <Typography className={classes.selected} color="textPrimary">
            Home
          </Typography>
          <Typography className={classes.selected} color="textPrimary">
            ThinkWise 360
          </Typography>
          <Typography className={classes.selected} color="textPrimary">
            Projects
          </Typography>
          <Typography claseeName={classes.unselected} color="textPrimary">
            Survey Setup
          </Typography>
        </Breadcrumbs>
      </Grid> */}
      {/* <Grid item xs={6} lg={6} md={6}> */}
      {/* <div
          className={classes.sideHead}
          style={{ display: 'flex', justifyContent: 'right' }}>
          <img src={logo} alt="logo" width="100" height="60" />
        </div> */}
      {/* </Grid> */}
      {/* <Grid item xs={12} sm={12} lg={12} md={12}>
        <Divider />
      </Grid> */}

      <Fragment>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <CustomLink to={PROJECTS_PATH}>
            <CustomButton colour="secondaryButton" style={{ float: 'right' }}>
              Cancel{' '}
            </CustomButton>
          </CustomLink>
        </Grid>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <div>
            <Paper className={classes.paper}>
              {/* Table tools */}
              <Toolbar className={classes.root}>
                <Typography
                  className={classes.tblHead}
                  variant="h6"
                  id="tableTitle"
                  component="div">
                  Select A Starting Point
                </Typography>
              </Toolbar>
              <Divider />

              <Grid
                container
                spacing={2}
                xs={12}
                lg={12}
                md={12}
                className={classes.boxtable}>
                <Grid item xs={12} lg={4} md={4} className={classes.boxBorder}>
                  <div className={classes.txtBlock}>
                    <h5 className={classes.txtTitle}>Start From Scratch</h5>
                    <p className={classes.txtDesc}>
                      All settings are blank, allowing you to fully customize
                      your survey.
                    </p>
                    <CustomLink to={CREATE_NEW_360_PATH}>
                      <Grid>
                        <CustomButton
                          colour="primaryButton"
                          // style={{ marginTop: '4.5rem' }}
                          onClick={handleCreateTypeClick}
                          data-type="from_scratch">
                          Get Started
                        </CustomButton>
                      </Grid>
                    </CustomLink>
                  </div>
                </Grid>
                {/* <Grid item xs={12} lg={4} md={4} className={classes.boxBorder}>
                  <div className={classes.txtBlock}>
                    <h5 className={classes.txtTitle}>Start From A Template</h5>
                    <p className={classes.txtDesc}>
                      A Template is a base project defined by your organization.
                      This will create a project and allow limited changes.
                    </p>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink id="select-template-label">
                          Select A Template
                        </InputLabel>
                        <Select
                          labelId="select-template-label"
                          id="select-template"
                          displayEmpty
                          onChange={(e) => e && setTemplate(e.target.value)}
                          className={classes.selectEmpty}>
                          {templateList.map((template) => (
                            <MenuItem value={template.zProjectID}>
                              {template.zName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <CustomButton
                      colour="primaryButton"
                      onClick={handlePreTemplate}
                      data-type="from_template">
                      Get Started{' '}
                    </CustomButton>
                  </div>
                </Grid> */}
                <Grid item xs={12} lg={4} md={4} className={classes.boxside}>
                  <div className={classes.txtBlock}>
                    <h5 className={classes.txtTitle}>
                      Start From A Previous Project
                    </h5>
                    <p className={classes.txtDesc}>
                      This will create a project based on one of your previous
                      projects and allow any changes.
                    </p>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink id="select-template-label">
                          Select A Previous Project
                        </InputLabel>
                        <Select
                          labelId="select-template-label"
                          id="select-template"
                          displayEmpty
                          onChange={(e) => e & setProject(e.target.value)}
                          className={classes.selectEmpty}>
                          {previousProjects.map((previousProject) => (
                            <MenuItem value={previousProject.zProjectID}>
                              {previousProject.zName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <Grid
                      style={{
                        /* marginTop: '1.8rem', */ marginBottom: '3rem',
                      }}>
                      <CustomButton
                        colour="primaryButton"
                        onClick={handlePreProject}
                        data-type="from_project">
                        Get Started{' '}
                      </CustomButton>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Fragment>
    </Grid>
  )
}
