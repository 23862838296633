import { TableRow } from "@material-ui/core";
import React from "react";
import TableCell from "@material-ui/core/TableCell";

export default function CustomTableEmptyRecord({ columnsCount }) {
  return (
    <TableRow>
      <TableCell colSpan={columnsCount}>
        <center>No Record Found!</center>
      </TableCell>
    </TableRow>
  );
}
