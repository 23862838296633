import React, { useState, useEffect } from 'react'
import { GET_STATE_LIST_URL } from '../../helpers/constants/ApiRoutes'
import { API } from '../../helpers/API'
import { FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import { authHeader } from './CustomFunction'

export default function State({
  name = 'state',
  handleChange,
  value,
  errors,
  touched,
}) {
  const [states, setStateList] = useState([])
  useEffect(() => {
    getStateList()
  }, [])

  // Get State List
  const getStateList = async () => {
    const getconfig = {
      method: 'GET',
      url: GET_STATE_LIST_URL,
      headers: authHeader(),
    }
    try {
      const response = await API(getconfig)
      if (response && response.data && response.data.partnerList) {
        setStateList(response.data.partnerList)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <InputLabel id="state">State *</InputLabel>
      <Select
        labelId="state"
        id="state"
        name={name}
        value={value}
        onChange={handleChange}
        fullWidth
        label="State"
        error={errors?.state && touched?.state}>
        {states.map((state) => {
          return <MenuItem value={state.zStateID}>{state.zName}</MenuItem>
        })}
      </Select>
      <FormHelperText style={{ color: '#f44336' }}>
        {errors?.state && touched?.state ? errors?.state : ''}
      </FormHelperText>
    </>
  )
}
