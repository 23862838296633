import * as types from '../action/ActionType'
import produce from 'immer'

export default function NewAllselectedItem(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.NEW_ALL_SELECTED_ITEMS:
      return (state = payload)

    default:
      return state
  }
}
