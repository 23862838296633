import * as types from '../action/ActionType'


export default function DueDate360Reducer( state = { dueToggleStatus: true } , action) {
  const { type , payload } = action
  switch (type) {
    case types.DUE_DATE_360:
      return (state = payload)
    default:
      return state
  }
}
