import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import {
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  DatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { API } from '../../../../../../helpers/API'
import {
  UPDATE_PROJECT_URL,
  GET_SURVEY_ADMINSTRATOR_URL,
  ADD_PROJECT_ITEMS,
} from '../../../../../../helpers/constants/ApiRoutes'
import { PROJECT_DETAILS_PATH } from '../../../../../../helpers/constants/RoutePaths'
import CustomButton from '../../../../../common/CustomButton'
import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../../../../../common/CustomFunction'
import {
  getAllcustomItem,
  getAllsaveItems,
  getAllthinkwise,
} from '../../../../../../redux/action'
import { useDispatch } from 'react-redux'
import CustomLoader from '../../../../../common/CustomLoader'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
    '& ..MuiDialog-paperScrollPaper': {
      maxHeight: '400px',
    },
    '& .MuiDialogContent-dividers': {
      borderBottom: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  editsavebtn: {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default function EditProjectInformation(props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const { open = '', handleReset, projectDetails, getProject } = props
  const dispatch = useDispatch()
  const initialValues = {
    projectName: projectDetails ? projectDetails.zName : '',
    projectDescription: projectDetails ? projectDetails.zDescription : '',
    surveyAdmin: projectDetails ? projectDetails.zOwnerID : '',
    dueDate: projectDetails ? projectDetails.zDueDateFormat : '',
    organizationTemplate: projectDetails
      ? projectDetails.zOrganizationTemplateFlag
      : true,
  }
  console.log('initialValues-->', initialValues)

  const [selectedDate, handleDateChange] = useState(
    new Date(initialValues.dueDate)
  )
  const [surveyAdminstratorList, setSurveyAdministrator] = useState()

  //Schema
  const validationSchema = Yup.object({
    projectName: Yup.string()
      .trim()
      .required('Project name is required'),
    surveyAdmin: Yup.string().required('Survey Administrator is required'),
    dueDate: Yup.string()
      .trim()
      .required('Due Date is required'),
  })

  //state
  async function getSurveyAdministrator() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SURVEY_ADMINSTRATOR_URL,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    try {
      const response = await API(getconfig)
      if (response.status === 200) {
        console.log(response)
        setSurveyAdministrator(response.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const updateProject = async (formData) => {
    setLoading(true)
    let {
      projectName,
      projectDescription,
      surveyAdmin,
      organizationTemplate,
      dueDate,
    } = formData
    console.log(projectDetails, formData)
    console.log('orgnaizationTemplate->', organizationTemplate)
    console.log('dueDate-->', dueDate)
    dueDate = new Date(dueDate)
    dueDate.setDate(dueDate.getDate() + 1)
    console.log('dueDate-->', dueDate.toISOString())
    const updateConfig = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_PROJECT_URL,
      data: {
        projectID: projectDetails.zProjectID,
        projectStatusID: 2,
        luid: getUserId(),
        projectName,
        description: projectDescription,
        ownerID: surveyAdmin,
        organizerID: getUserId(),
        scaleID: projectDetails.zScaleID,
        levelID: projectDetails.zLevelID,
        noParticipatingFlag: false,
        organizationTemplateFlag: false,
        // organizationTemplateFlag: organizationTemplate,
        mgrViewReportFlag: projectDetails.zMgrViewReportFlag,
        participantReceiveReportFlag:
          projectDetails.zParticipantReceiveReportFlag,
        dueDate: dueDate.toISOString(),
        twTemplate: false,
      },
    }
    //  if (!(projectInfo.zLevelID == participantLevel)) {
    //    const postConfig = {
    //      method: 'POST',
    //      url: ADD_PROJECT_ITEMS,
    //      data: {
    //        projectID: parseInt(id),
    //        itemIdList: [],
    //      },
    //    }
    //    await API(postConfig)
    //    dispatch(getAllthinkwise(id))
    //    dispatch(getAllcustomItem(id))
    //    dispatch(getAllsaveItems(id))
    //  }
    try {
      const response = await API(updateConfig)
      if (response.status === 200) {
        CustomMessage(response.data.message, 'success', enqueueSnackbar)
        await handleReset()
        getProject()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getSurveyAdministrator()
  }, [])

  //Formik
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => updateProject(values),
  })

  const changeDateField = (date) => {
    setFieldValue('dueDate', new Date(date))
    setFieldTouched('dueDate', true)
  }

  const {
    projectName = '',
    projectDescription = '',
    surveyAdmin = '',
    dueDate = '',
    organizationTemplate = '',
  } = values

  let dueDateValue = null
  if (dueDate) {
    dueDateValue = new Date(dueDate)
  }
  const { question = '' } = values
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Edit Project Information
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={5}>
                <TextField
                  id="name-helper-text"
                  label="Project Name"
                  value={projectName}
                  onChange={handleChange}
                  fullWidth
                  name="projectName"
                  error={errors.projectName && touched.projectName}
                  helperText={
                    errors.projectName && touched.projectName
                      ? errors.projectName
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <InputLabel id="surveyAdministrator-label">
                  Survey Administrator:
                </InputLabel>
                <Select
                  labelId="surveyAdmin-label"
                  id="surveyAdmin"
                  name="surveyAdmin"
                  value={surveyAdmin}
                  onChange={handleChange}
                  fullWidth
                  label="surveyAdminstrator">
                  {surveyAdminstratorList &&
                    surveyAdminstratorList.map((surveyAdmin) => (
                      <MenuItem
                        value={
                          surveyAdmin.zUserID
                        }>{`${surveyAdmin.zFirstName} ${surveyAdmin.zLastName}`}</MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {errors.surveyAdministrator && touched.surveyAdministrator
                    ? errors.surveyAdministrator
                    : ''}
                </FormHelperText>
              </Grid>
              <Grid item xs={2}></Grid>

              <Grid item xs={5}>
                <TextField
                  id="designation-helper-text"
                  label="Project Description"
                  value={projectDescription}
                  onChange={handleChange}
                  fullWidth
                  name="projectDescription"
                  error={
                    errors.projectDescription && touched.projectDescription
                  }
                  helperText={
                    errors.projectDescription && touched.projectDescription
                      ? errors.projectDescription
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={5}>
                {/* <KeyboardDateTimePicker
                  value={selectedDate}
                  variant="inline"
                  onChange={handleDateChange}
                  label="Project due date"
                  onError={console.log}
                  fullWidth
                  //minDate={new Date("2018-01-01T00:00")}
                  format="MM/DD/yyyy hh:mm a"
                /> */}
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    label="Project Due Date:"
                    style={{ width: '100%' }}
                    variant="inline"
                    value={dueDateValue}
                    format="MM/DD/YYYY"
                    name="dueDate"
                    onChange={changeDateField}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <Box>
                  {/*  <FormControlLabel
                    control={
                      <Checkbox
                        value={organizationTemplate}
                        checked={organizationTemplate}
                        color="primary"
                        name="organizationTemplate"
                        onClick={(e) =>
                          setFieldValue(
                            'organizationTemplate',
                            e.target.checked
                          )
                        }
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label="Save as Organizational Template"
                  />*/}

                  <CustomButton
                    className={classes.editsavebtn}
                    colour="primaryButton"
                    type="submit"
                    onClick={handleSubmit}>
                    {loading && <CustomLoader loading={loading} />}Save
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
