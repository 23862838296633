import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  Box,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import {
  DatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { alpha } from '@material-ui/core/styles'
// import {LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import moment from 'moment'
import { Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CustomButton from '../common/CustomButton'
import CloseIcon from '@material-ui/icons/Close'
import { useParams } from 'react-router-dom'
import {
  authHeader,
  getDomain,
  getOrganizationId,
  getUserId,
} from '../common/CustomFunction'
import { API } from '../../helpers/API'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  ADMIN_LIST,
  TEAM_GET_TIMELINE,
  TEAM_SAVETIMELINE,
  UPDATE_TEAMSURVEY,
  UPDATE_TEAM_RESPOTANT_USER,
} from '../../helpers/constants/ApiRoutes'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FormLabel } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '800px',
    },
    '& ..MuiDialog-paperScrollPaper': {
      maxHeight: '400px',
    },
    '& .MuiDialogContent-dividers': {
      borderBottom: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
    },
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  editsavebtn: {
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

function Tsupdateprojectinfor(props) {
  const classes = useStyles()
  const { handleReset, open, formdata, responsuccessCall, dueDate } = props
  const [dueDateEdit, setDueDateEdit] = useState(dueDate)
  const { tsprojectid } = useParams()
  const [projectType, setprojectType] = useState({ id: 0, name: 'Monthly' })
  const [surveyAdminlist, setsurveyAdminlist] = useState([])

  const validationSchema = Yup.object({
    Name: Yup.string()
      .trim()
      .required('Project name is required'),
    Description: Yup.string()
      .trim()
      .required('Descriptionis required'),
  })
  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      ...formdata,
      ...dueDate,
      LUID: getUserId(),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => updateProject(values),
  })

  const onDateChange = (val) => {
    setFieldValue('zDueDateFormat', moment(val).format('YYYY-MM-DD'))
  }
  async function getSurveyAdministrator() {
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADMIN_LIST,
      data: {
        OrganizationID: getOrganizationId(),
        ProjectTypeID: 7,
      },
    }
    try {
      const response = await API(getconfig)
      if (response.status === 200) {
        setsurveyAdminlist(response.data.UserList)
      }
    } catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    getSurveyAdministrator()
  }, [])
  const updateProject = async (values) => {
    const getconfig = {
      method: 'POST',
      headers: authHeader(),

      url: UPDATE_TEAMSURVEY,
      params: {
        ProjectID: tsprojectid,
        Name: values.Name,
        Description: values.Description,
        OwnerID: values.OwnerID,
        OrganizerID: values.OrganizerID,
        DueDate: values.zDueDateFormat,
        LUID: values.LUID,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        // const addProjectconfig = {
        //   method: 'POST',
        //   headers: authHeader(),
        //   url: TEAM_SAVETIMELINE,
        //   params: {
        //     ProjectID: response.data.ProjectId,
        //     UserID: getUserId(),
        //     TimeLineType: projectType.name,
        //   },
        // }
        // const projectCOnfig = await API(addProjectconfig)
        responsuccessCall()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getTimeline = async () => {
    const addProjectconfig = {
      method: 'GET',
      headers: authHeader(),
      url: TEAM_GET_TIMELINE,
      params: {
        ProjectID: tsprojectid,
      },
    }
    const projectCOnfig = await API(addProjectconfig)
    const { data } = projectCOnfig.data

    const { zTImeLineType, zTimeLineTypeID } = data[0] || {}
    setprojectType({ id: zTimeLineTypeID, name: zTImeLineType })
  }

  useEffect(() => {
    getTimeline()
  }, [])
  const projectHandlechange = async (e) => {
    setprojectType((v) => ({ ...v, name: e.target.value }))
    // setprojectType()
  }
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Edit Project Details
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={5}>
                <TextField
                  id="name-helper-text"
                  //   label="Project Name"
                  value={values.Name}
                  onChange={handleChange}
                  fullWidth
                  name="Name"
                  error={errors.Name && touched.Name}
                  helperText={errors.Name && touched.Name ? errors.Name : ''}
                />
              </Grid>

              {/* <Grid item xs={2}></Grid> */}

              <Grid item xs={7}>
                <FormControl
                  className={classes.formControl}
                  error={errors.participantLevel && touched.participantLevel}
                  style={{ width: '-webkit-fill-available' }}>
                  <InputLabel
                    className={classes.labelName}
                    id="surveyAdministrator-label">
                    Survey Administrator:
                  </InputLabel>
                  <Select
                    labelId="surveyAdministrator-label"
                    id="values.OwnerID"
                    className={classes.labelName}
                    name="OwnerID"
                    value={values.OwnerID}
                    onChange={handleChange}
                    disabled={true}
                    fullWidth
                    label="surveyAdminstrator">
                    {surveyAdminlist.map((v) => {
                      return (
                        <MenuItem value={v.zUserID}>{v.zFullName}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  id="name-helper-text"
                  //   label="Description"
                  value={values.Description}
                  onChange={handleChange}
                  fullWidth
                  name="Description"
                  error={errors.Description && touched.Description}
                  helperText={
                    errors.Description && touched.Description
                      ? errors.Description
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={5}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    label="Due Date:"
                    style={{ width: '100%' }}
                    variant="inline"
                    value={values.zDueDateFormat}
                    format="YYYY-MM-DD"
                    name="zDueDateFormat"
                    onChange={onDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={5}>
                {' '}
              </Grid>
              <Grid item xs={5}>
                {/* 
                <FormControl component="fieldset">
                  <FormLabel component="legend">Project Timeline:</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={projectType.name}
                    onChange={projectHandlechange}>
                    <FormControlLabel
                      value="Monthly"
                      control={<Radio />}
                      label="Monthly"
                    />
                    <FormControlLabel
                      value="Bimonthly"
                      control={<Radio />}
                      label="Bimonthly"
                    />
                    <FormControlLabel
                      value="Quarterly"
                      control={<Radio />}
                      label="Quarterly"
                    />
                  </RadioGroup>
                </FormControl> */}
              </Grid>

              <Grid item xs={12}>
                <Box>
                  <CustomButton
                    className={classes.editsavebtn}
                    colour="primaryButton"
                    type="submit">
                    Save
                  </CustomButton>
                </Box>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}

export default Tsupdateprojectinfor
