import Summerysample from './360images/SummeryResultsByCompetency.png'
import compentencysample from './360images/SummaryResultsByRespondentGroup.png'
import TOPTENIMAGE from './360images/Top10.png'
import DetailsamImage from './360images/DetailedItemResults.png'
import openEndimage from './360images/OpenEnded.png'

var image = []
var compentencysampleImageStore = []
var toptensampleImageStore = []
let detailsampleImageStore = []
let openendsampleImageSTore = []
function readFileDataAsBase64(e) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      resolve(event.target.result)
    }

    reader.onerror = (err) => {
      reject(err)
    }

    reader.readAsArrayBuffer(e)
  })
}

function _arrayBufferToBase64(buffer) {
  var binary = ''
  var bytes = new Uint8Array(buffer)
  var len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }

  return window.btoa(binary)
}
export async function Main() {
  const SummerysampleImage = await fetch(Summerysample).then((response) =>
    response.blob()
  )
  let value = await readFileDataAsBase64(SummerysampleImage)
  console.log(_arrayBufferToBase64(value))
  image.push('data:image/jpeg;base64,' + _arrayBufferToBase64(value))
}

export async function compentencysampleimage() {
  const SummerysampleImage = await fetch(compentencysample).then((response) =>
    response.blob()
  )
  let value = await readFileDataAsBase64(SummerysampleImage)
  compentencysampleImageStore.push(
    'data:image/jpeg;base64,' + _arrayBufferToBase64(value)
  )
}

export async function toptenimage() {
  const SummerysampleImage = await fetch(TOPTENIMAGE).then((response) =>
    response.blob()
  )
  let value = await readFileDataAsBase64(SummerysampleImage)
  toptensampleImageStore.push(
    'data:image/jpeg;base64,' + _arrayBufferToBase64(value)
  )
}

export async function detailsimage() {
  const SummerysampleImage = await fetch(DetailsamImage).then((response) =>
    response.blob()
  )
  let value = await readFileDataAsBase64(SummerysampleImage)
  detailsampleImageStore.push(
    'data:image/jpeg;base64,' + _arrayBufferToBase64(value)
  )
}
export async function openendimage() {
  const SummerysampleImage = await fetch(openEndimage).then((response) =>
    response.blob()
  )
  let value = await readFileDataAsBase64(SummerysampleImage)
  openendsampleImageSTore.push(
    'data:image/jpeg;base64,' + _arrayBufferToBase64(value)
  )
}
export const imagebloburl = image
export const compentencysampleImageblob = compentencysampleImageStore
export const toptensampleImageStoreImageblob = toptensampleImageStore
export const detailssampleImageStoreImageblob = detailsampleImageStore
export const openendImageStoreImageblob = openendsampleImageSTore
