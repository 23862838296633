import {
  Box,
  Divider,
  Typography,
  Grid,
  Tabs,
  Tab,
  Breadcrumbs,
} from '@material-ui/core'
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import logo from '../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../common/CustomBreadcrumbs'
import CustomButton from '../../common/CustomButton'
import ChooseSetupType from './ChooseSetupType'
import ProjectInformation from './steps/ProjectInformation'
import Competencies from './steps/Competencies'
import OpenEndedQuestions from './steps/OpenEndedQuestions'
import Roster from './steps/item_selection/Roster'
import RosterNew from './steps/item_selection/RosterNew'
import Invitation from './steps/item_selection/Invitation'
import LaunchProject from './steps/item_selection/LaunchProject'
import { useHistory, useParams } from 'react-router'
import { useFormikContext } from 'formik'

import { useSelector, useDispatch } from 'react-redux'
import {
  ADD_PROJECT_ITEMS,
  GET_PROJECT_ITEM,
  GET_PROJECT_URL,
  GET_USER_RELATIONSHIP_LIST,
  UPSERT_BYPASS_ROSTER,
  SELECTED_DRAG_DROP_GET,
  SELECTED_DRAG_DROP_DELETE,
  SELECTED_DRAG_DROP_INSERT,
  GET_BYPASSROSTER_DETAILS,
} from '../../../helpers/constants/ApiRoutes'
import { API } from '../../../helpers/API'
// import CheckedItemList from '../../../redux/reducers/CheckedItemList'
import {
  CHECKED_ITEM_LIST,
  COMPETENCY_DRAG_DROP,
  DUE_DATE_360,
  SAVE_CURRENT_STEP,
  SAVE_PROJECT_INFO,
} from '../../../redux/action/ActionType'
import { authHeader, getQueryParamsByName } from '../../common/CustomFunction'
import {
  CustomMessage,
  CustomPageLoading,
} from '../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import CustomLoader from '../../common/CustomLoader'
import NewCompentency from './steps/NewCompentency'
import {
  getAllOrgCustomItems,
  getAllcustomItem,
  getAllthinkwise,
  getCategory,
} from '../../../redux/action'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
} from '../../../redux/action/Typeconstent'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  txtBlock: {
    textAlign: 'center',
  },
  boxBorder: {
    borderRight: '2px solid #eee',
  },
  txtTitle: {
    fontSize: '19px',
  },
  txtDesc: {
    fontSize: '11px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  typograpyhtstyle: {
    fontFamily: 'Nunito-SemiBold !important',
    color: ' #000000DE !important',
  },
  tab: {
    fontFamily: 'Roboto-Medium !important',
    textAlign: ' center',
    letterSpacing: ' 1.16px',
    color: ' #000000DE',
    textTransform: ' uppercase',
    opacity: ' 1',
  },

  inactiveTab: {
    textAlign: 'center',
    letterSpacing: '1.16px',
    color: '#0000008A',
    textTransform: 'uppercase',
    opacity: 1,
    fontFamily: 'Roboto-Medium !important',
  },
  selected: {
    color: '#1565C0 !important',
    fontFamily: 'Nunito-Regular !important',
    letterSpacing: '0.08px',
  },
  unselected: {
    textAlign: 'left',
    letterSpacing: '0.08px',
    color: '#0000008A',
    opacity: '1',
  },
}))

const CusTab = withStyles((theme) => ({
  indicator: {
    fontFamily: 'Roboto-Medium !important',

    backgroundColor: theme.btnPrimary,
  },
  '& .Mui-selected': {
    backgroundColor: 'red !important',
    color: 'blue !important',
  },
}))(Tabs)

export default function CreateNew360(props) {
  const history = useHistory()
  const classes = useStyles()
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const { id: projectId } = useParams()
  const PID = props.match.params.id
  console.log(PID, 'idididiudididid')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)
  const [isRosterupload, setisRosterupload] = useState(false)
  // const { submitForm } = useFormikContext()
  const [createType, setCreateType] = useState(null) // Types : from_scratch , from_template and from_project
  const [projectInfo, setProjectInfo] = useState()
  const [isEditProject, setEditProject] = useState(false)
  const [isList, setSubList] = useState([])
  const [isListOpen, setSubListOpne] = useState([])
  const [isRoster, setRoster] = useState(0)
  const [isInfo, setInfo] = useState([])
  const [byPassRosterDetails, setBypassRosterDetails] = useState([])
  const OpenendChecked = useSelector((state) => state.OpenendChecked)
  const NewselectedThinkwiseItems = useSelector(
    (s) => s.NewselectedThinkwiseItems
  )
  const CheckedItemList = useSelector((state) => state.CheckedItemList)
  let newFormtype = useRef()
  const Selectedcategory = useSelector((state) => state.Selectedcategory)
  const SelectedSubitems = useSelector((state) => state.SelectedSubitems)
  const Subcategoryselecteditem = useSelector(
    (state) => state.Subcategoryselecteditem
  )
  // const NewSelectedItemsIdReducer = useSelector(
  //   (s) => s.NewSelectedItemsIdReducer
  // )
  const NewSelectedItemsIdReducer = useSelector(
    (s) => s.NewSelectedItemsIdReducer
  )
  const NewselectedThinkwiseItems1 = useSelector(
    (s) => s.NewselectedThinkwiseItems
  )
  const selectedThinkwiseData = useSelector((item) => item.SelectedItemReducer)
  const currentStep = useSelector((state) => state.CurrentStep)
  const compentencyDragReducer = useSelector(
    (state) => state.CompentencyDragReducer
  )
  const SelectedDragAndDrop = useSelector((pay) => pay?.SelectedDragAndDrop)
  const { selectComponent } = compentencyDragReducer
  console.log('SelectedDragAndDrop', SelectedDragAndDrop)

  // const updatedData = selectedThinkwiseData.map(doc => doc?.itemList || doc?.Itemlist).flat(1).map(doc => typeof doc === "string" ? doc : doc?.itemId)

  //If the user change the drag and drop we will pass the array data in api(ADD_ITEMS) payload

  const array = []
  selectedThinkwiseData?.forEach((element) => {
    if (element?.capablitylist) {
      element?.capablitylist?.forEach((doc) => {
        const select =
          NewselectedThinkwiseItems1[
            'THINKWISE_CATEGORY_' + doc?.compentencyid
          ]?.['THINKWISE_SUB_CATEGORY_' + doc?.capablityid]
        doc?.itemslist.map((item) => {
          select?.filter(
            (element) => element == item?.itemid && array.push(item?.itemid)
          )
        })
      })
    } else {
      element?.compentencylist?.forEach((elemen) => {
        let selectedItem =
          NewSelectedItemsIdReducer['CUSTOM_ITEM_' + element?.capablityid]?.[
            'SUB_CUSTOM_ITEM_' +
              element.capablityid +
              '_' +
              elemen.compentencyid
          ] || []
        elemen?.Itemlist?.map((doc) => {
          selectedItem?.filter(
            (element) => element == doc?.itemId && array.push(doc?.itemId)
          )
        })
      })
    }
  })

  console.log(array, 'updatedData')
  const handleCreateType = (event) => {
    const { type = '' } = event.currentTarget.dataset
    setCreateType(type)
  }

  const handleCallback = (childData) => {
    setSubList(childData)
  }
  const handleCallbackOpen = (childData) => {
    setSubListOpne(childData)
  }
  const handleCallbackRoster = (childData) => {
    console.log(Object.keys(childData).length)
    setRoster(Object.keys(childData).length)
  }
  const handleCallbackInfo = (childData) => {
    setInfo(childData)
  }

  const handleTabChange = async (event, newValue) => {
    // submitForm()
    // event.preventDefault()
    setloading(true)
    // newFormtype.current.submit()
    //console.log(newValue)
    //console.log(isList)
    //console.log(isListOpen)
    //console.log(isRoster)

    if (newValue == 0) {
      dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else if (newValue == 1) {
      //isEditProject &&
      dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })

      var x = document.getElementsByClassName('form1')
      console.log(x)
      if (x.length <= 0) {
        await handleSaveProjectItems(newValue)
      } else {
        var pname = document.forms['form_1']['projectName'].value
        var dueDate = document.forms['form_1']['dueDate'].value
        var participantLevel =
          document.forms['form_1']['participantLevel'].value
        var surveyAdministrator =
          document.forms['form_1']['surveyAdministrator'].value
        var ratingScale = document.forms['form_1']['ratingScale'].value
        var scale = document.forms['form_1']['scale'].value
        if (
          pname == '' ||
          dueDate == '' ||
          participantLevel == '' ||
          surveyAdministrator == '' ||
          ratingScale == '' ||
          scale == ''
        ) {
          newFormtype?.current?.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          ) //handleSaveProjectItems()
        } else if (
          pname != '' &&
          dueDate != '' &&
          participantLevel != '' &&
          surveyAdministrator != '' &&
          ratingScale != '' &&
          scale != ''
        ) {
          newFormtype?.current?.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          )
          await handleSaveProjectItems(newValue)
        }
      }
      //isEditProject && dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else if (newValue == 3) {
      isEditProject &&
        isList.length > 0 &&
        dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else if (newValue == 2) {
      console.log('value2', isListOpen)

      if (isEditProject && isList.length > 0) {
        let previewTotalselecteditem = []

        Object.values(NewselectedThinkwiseItems).forEach((value) => {
          Object.values(value).forEach((v) => {
            previewTotalselecteditem.push(...v)
          })
        })

        Object.values(NewSelectedItemsIdReducer).forEach((value) => {
          if (value.length) {
            previewTotalselecteditem.push(...value)
          } else {
            Object.values(value).forEach((va) => {
              previewTotalselecteditem.push(...va)
            })
          }
        })

        const postConfig = {
          method: 'POST',
          headers: authHeader(),

          url: ADD_PROJECT_ITEMS,
          data: {
            projectID: parseInt(projectId),
            itemIdList: previewTotalselecteditem,
          },
        }
        const response = await API(postConfig)
        if (response && response.status === 200) {
          //Swal.fire('Success', response.data.message)
          CustomMessage(response.data.message, 'success', enqueueSnackbar)
          dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
        }
      }
      // OpenendChecked.length > 0 &&
      // dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else if (newValue == 4) {
      isEditProject &&
        isList.length > 0 &&
        // OpenendChecked.length > 0 &&
        isRoster > 0 &&
        dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else if (newValue == 5) {
      isEditProject &&
        isList.length > 0 &&
        //OpenendChecked.length > 0 &&
        isRoster > 0 &&
        isInfo.length > 0 &&
        dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    }
    setloading(false)
    // newFormtype &&
    // currentStepfunction[currentStep] ||
  }

  const handleNext = () => {
    isEditProject &&
      currentStep < 5 &&
      dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
  }

  const handleReset = () => {
    //setCreateType(null)
    history.push('/projects')
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const TabPanel = useCallback((props) => {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }, [])

  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props
  //   console.log('paretntabpane')
  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}>
  //       {value === index && (
  //         <Box>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   )
  // }

  const dispatch = useDispatch()

  const getProjectInformation = async (projectId) => {
    setloading(true)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT_URL,
      params: {
        ProjectID: projectId,
      },
    }
    try {
      const response = await API(getConfig)
      console.log(response.data.data[0],'datgggg');
      if (response) {
        const { zPreviousProjectID = '',zIsDueDateOn } = response.data.data[0]
        dispatch({
          type: DUE_DATE_360,
          payload: { dueToggleStatus: zIsDueDateOn },
        })
        setProjectInfo(response.data.data[0])
        dispatch({ type: SAVE_PROJECT_INFO, payload: response.data.data[0] })
      }
    } catch (err) {
    } finally {
      setloading(false)
    }
  }
const getBypassRosterDetails = async () => {
  const config = {
    method: 'GET',
    headers: authHeader(),

    url: GET_BYPASSROSTER_DETAILS,
    params: {
      ProjectID: projectId,
    },
  }
  try {
    const response = await API(config)
    const { data, status } = response.data
    if (status == 'Success') {
      setBypassRosterDetails(data)
    }
  } catch (error) {
    console.error(error)
  }
}
  const postUpset = async () => {
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: UPSERT_BYPASS_ROSTER,
      data: {
        projectID: parseInt(projectId),
        byPassRoster:
          isRoster > 0 ? !!byPassRosterDetails[0]?.zByPassRoster : true,
        adminApproval: !!byPassRosterDetails[0]?.zAdminApproval,
      },
    }
    const response = await API(postConfig)
    if (response && response.status === 200) {
      getBypassRosterDetails()

    } else {
      console.log(response, 'response')
    }
  }

  const successCall = () => {
    getProjectInformation(props.match.params.id)
  }

  const handleSaveProjectItems = async (newValue) => {
    console.log('io')
    console.log(newValue)
    setloading(true)
    setloading(true)

    let previewTotalselecteditem = []

    Object.values(NewselectedThinkwiseItems).forEach((value) => {
      Object.values(value).forEach((v) => {
        previewTotalselecteditem.push(...v)
      })
    })

    Object.values(NewSelectedItemsIdReducer).forEach((value) => {
      if (value.length) {
        previewTotalselecteditem.push(...value)
      } else {
        Object.values(value).forEach((va) => {
          previewTotalselecteditem.push(...va)
        })
      }
    })

    const searchPath = window.location.pathname
    const arr = searchPath.split('/')

    //    console.log(itemList.length);
    // console.log(itemList);
    setSubList(previewTotalselecteditem)
    if (newValue == 0) {
      dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else {
      if (newValue == 1) {
        dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
      } else {
        if (previewTotalselecteditem.length <= 0) {
          CustomMessage('Please select the items', 'error', enqueueSnackbar)
          // return null
        } else {
          const postConfig = {
            method: 'POST',
            headers: authHeader(),

            url: ADD_PROJECT_ITEMS,
            data: {
              projectID: parseInt(arr[3]),
              itemIdList: selectComponent ? array : previewTotalselecteditem,
            },
          }
          const response = await API(postConfig)
          if (response && response.status === 200) {
            //Swal.fire('Success', response.data.message)
            CustomMessage(response.data.message, 'success', enqueueSnackbar)
            dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
            dispatch({
              type: COMPETENCY_DRAG_DROP,
              payload: { selectComponent: false },
            })
          }
        }
      }
    }

    setloading(false)
  }

  const selectedDragandDropDelete = async () => {
    const postConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: SELECTED_DRAG_DROP_DELETE,
      params: {
        ProjectID: PID,
      },
    }
    try {
      const response = await API(postConfig)
      selectDragandDropInsert()
      // Swal.fire('Your Project was deleted successfully!', '', 'success')
      // getProjectList()
    } catch (error) {
      console.error(error)
    }
  }

  const selectDragandDropInsert = async () => {
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: SELECTED_DRAG_DROP_INSERT,
      data: {
        competencyList: SelectedDragAndDrop,
      },
    }
    const response = await API(postConfig)
    if (response && response.status === 200) {
    } else {
      console.log(response, 'response')
    }
  }

  const selectDragandDropGET = async (projectId) => {
    setloading(true)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: SELECTED_DRAG_DROP_GET,
      params: {
        ProjectID: projectId,
      },
    }
    try {
      const response = await API(getConfig)
      if (response) {
        // setProjectInfo(response.data.data[0])
        // dispatch({ type: SAVE_PROJECT_INFO, payload: response.data.data[0] })
      }
    } catch (err) {
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    if (props.match.params.id) {
      setEditProject(true)
      getProjectInformation(props.match.params.id)
      selectDragandDropGET(props.match.params.id)
    } else if (getQueryParamsByName('projectId')) {
      getProjectInformation(getQueryParamsByName('projectId'))
      selectDragandDropGET(getQueryParamsByName('projectId'))
    } else if (getQueryParamsByName('projectID')) {
      getProjectInformation(getQueryParamsByName('projectID'))
      selectDragandDropGET(getQueryParamsByName('projectID'))
    }
    // dispatch({ type: SAVE_CURRENT_STEP, payload: 3 })
  }, [window.location.pathname])

  let currentStepfunction = {
    1: () => {
      handleSaveProjectItems()
      selectedDragandDropDelete()
    },
    3: () => {
      postUpset()
      dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
      CustomMessage('Record saved successfull', 'success', enqueueSnackbar)
    },
  }
  useEffect(() => {
    dispatch(getAllthinkwise(projectInfo?.zProjectID))
    dispatch(getAllcustomItem(projectInfo?.zProjectID))
    dispatch(getAllOrgCustomItems(projectInfo?.zProjectID))
  }, [projectInfo && projectInfo])

  return (
    <div>
      <Grid container spacing={1}>
        {loading && <CustomPageLoading />}
        {/* <Grid item xs={6} lg={6} md={6}>
          <Typography
            variant="h6"
            className={classes.typograpyhtstyle}
            // style={{ fontWeight: 'bold' }}
            gutterBottom>
            ThinkWise 360 Survey Setup
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.selected} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              ThinkWise 360
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              Projects
            </Typography>
            <Typography className={classes.unselected} color="textPrimary">
              Survey Setup
            </Typography>
          </Breadcrumbs>
        </Grid> */}
        {/* <Grid item xs={6} lg={6} md={6}> */}
        {/* <div
            className={classes.sideHead}
            style={{ display: 'flex', justifyContent: 'right' }}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={12} lg={12} md={12}>
          <Divider />
        </Grid> */}
        <>
          <Grid item xs={12} lg={10} md={10}>
            <CusTab
              value={currentStep}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              inkBarStyle={{ background: 'yellow' }}
              // scrollButtons="auto"
            >
              <Tab
                className={currentStep == 0 ? classes.tab : classes.inactiveTab}
                style={{ paddingLeft: '0px' }}
                label="INFORMATION"
                {...a11yProps(0)}
                inkBarStyle={{ background: 'yellow' }}
              />
              <Tab
                className={currentStep == 1 ? classes.tab : classes.inactiveTab}
                label="COMPETENCIES"
                // style={{paddingLeft: '27px'}}
                {...a11yProps(0)}
              />
              <Tab
                className={currentStep == 2 ? classes.tab : classes.inactiveTab}
                style={{ padding: '0px 25px 0px 47px' }}
                label="OPEN ENDED QUESTIONS"
                {...a11yProps(0)}
              />
              <Tab
                className={currentStep == 3 ? classes.tab : classes.inactiveTab}
                label="ROSTER"
                // style={{paddingLeft: '38px'}}
                {...a11yProps(0)}
              />
              <Tab
                className={currentStep == 4 ? classes.tab : classes.inactiveTab}
                label="INVITATION"
                style={{ paddingLeft: '0px' }}
                {...a11yProps(0)}
              />
              <Tab
                className={currentStep == 5 ? classes.tab : classes.inactiveTab}
                label="LAUNCH"
                {...a11yProps(0)}
              />
            </CusTab>
          </Grid>
          <Grid item xs={2} className={classes.btnContainer}>
            {/* <CustomButton type="submit" colour="primaryButton" form="form_1">
              Save
            </CustomButton> */}
            <CustomButton colour="secondaryButton" onClick={handleReset}>
              Cancel{' '}
            </CustomButton>
            <CustomButton
              colour="primaryButton"
              // type="submit"
              onClick={currentStepfunction[currentStep] || '#'}
              type={[1, 3].includes(currentStep) ? 'button' : 'submit'}
              form={[1, 3].includes(currentStep) ? '' : 'form_1'}>
              Next
            </CustomButton>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={currentStep} index={0}>
              <ProjectInformation
                newFormtype={newFormtype}
                projectInfo={projectInfo}
                successCall={successCall}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={1}>
              <NewCompentency projectInfo={projectInfo} />
              {/* <Competencies
                projectInfo={projectInfo}
                parentCallback={handleCallback}
              /> */}
            </TabPanel>
            <TabPanel value={currentStep} index={2}>
              <OpenEndedQuestions
                parentCallback={handleCallbackOpen}
                newFormtype={newFormtype}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={3}>
              {/* <Roster /> */}
              <RosterNew
                //checnkRoster={checnkRoster}
                byPassRosterDetails={byPassRosterDetails}
                setBypassRosterDetails={setBypassRosterDetails}
                isRosterupload={isRosterupload}
                parentCallback={handleCallbackRoster}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={4}>
              <Invitation
                isRosterupload={isRosterupload}
                parentCallback={handleCallbackInfo}
                byPassRosterDetails={byPassRosterDetails}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={5}>
              <LaunchProject isRosterupload={isRosterupload} />
            </TabPanel>
          </Grid>
        </>
      </Grid>
    </div>
  )
}
