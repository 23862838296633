import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}))

export default function CustomButton(props) {
  const classes = useStyles()

  const ColorButton = withStyles((theme) => ({
    root: {
      color: '#fff',
      backgroundColor: theme[props.colour],
      '&:hover': {
        backgroundColor: theme[props.colour],
      },
      textTransform: 'none',
    },
  }))(Button)

  return (
    <ColorButton variant="contained" className={classes.margin} {...props}>
      {props.children}
    </ColorButton>
  )
}
