import {
  DialogContent,
  FormHelperText,
  MenuItem,
  Select,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton, Box } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import { useFormik } from 'formik'
import Typography from '@material-ui/core/Typography'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import {
  ADD_PARTNER_URL,
  UPLOAD_LOGO_URL,
  GET_LOGO_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import State from '../../../../../common/State'
import {
  getUserId,
  getAlertToast,
  authHeader,
} from '../../../../../common/CustomFunction'
import Swal from 'sweetalert2'
import CustomLoader from '../../../../../common/CustomLoader'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
}))

export default function EditPartner(props) {
  const classes = useStyles()
  const [selectedImage, setSelectedImage] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [fileUrl, setFileUrl] = useState(null)
  const [zipCode, setzipCode] = useState(props.partnerInfo.zipCode)
  const [loading, setLoading] = useState(false)
  const {
    open = '',
    handleReset,
    handleUpdatePartner,
    title = '',
    partnerInfo = '',
    partnerId,
  } = props
  //Schema
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const validationSchema = Yup.object({
    partnerName: Yup.string()
      .trim()
      .required('Name is required'),
    // address: Yup.string().trim().required("Address is required"),
    // addressLine2: Yup.string().trim().required("Address line 2 is required"),
    // city: Yup.string().required("City is required"),
    state: Yup.string()
      .trim()
      .required('State is required'),
    //zipCode: Yup.string().trim().required("zip code is required"),

    // phoneNumber: Yup.string().matches(new RegExp('[0-9]{7}'))
    // phoneNumber: Yup.string().trim().required("Phone Number is required"),
    // website: Yup.string().trim().required("Website is required"),
  })

  const handleChangeZipcode = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, '')
    if (onlyNums.length < 6) {
      setzipCode(onlyNums)
    } else if (onlyNums.length === 6) {
      setzipCode(onlyNums)
    }
  }

  //state
  const initialValues = {
    partnerName: partnerInfo ? partnerInfo.name : '',
    address: partnerInfo ? partnerInfo.addressLine1 : '',
    addressLine2: partnerInfo ? partnerInfo.addressLine2 : '',
    city: partnerInfo ? partnerInfo.city : '',
    state: partnerInfo ? partnerInfo.stateID : '',
    //zipCode: setzipCode(partnerInfo.zipCode),
    //phoneNumber: partnerInfo ? partnerInfo.phoneNumber : "",
    website: partnerInfo ? partnerInfo.website : '',
  }

  useEffect(() => {
    // getLogo();
  }, [])

  async function getLogo() {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_LOGO_URL,
      params: {
        a_Id: getUserId(),
        ContentType: 'partner',
      },
    }
    const response = await API(getConfig)
    if (response.status === 200) {
      setImageUrl(response.data.fileURL)
    }
  }

  useEffect(async () => {
    if (selectedImage) {
      console.log(selectedImage)
      setImageUrl(URL.createObjectURL(selectedImage))

      const formData = new FormData()
      formData.append('file', selectedImage)

      const imageConfig = {
        method: 'POST',
        headers: authHeader(),

        url: UPLOAD_LOGO_URL,
        headers: {
          accept: '',
          'Content-Type': 'multipart/form-data',
        },
        params: {
          ContentType: 'partner',
          a_Id: getUserId(),
        },
        data: formData,
      }
      const responseUpload = await API(imageConfig)
      if (responseUpload.data.status === 'Success') {
        setFileUrl(responseUpload.fileURL)
      }
    }
  }, [selectedImage])

  const handleResetImage = () => {
    setSelectedImage(null)
  }

  const handleAddPartner = async (formData) => {
    setLoading(true)
    const {
      partnerName,
      address,
      addressLine2,
      city,
      state,
      website,
    } = formData
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_PARTNER_URL,
      data: {
        cmboPartner: '-1',
        name: partnerName,
        addressLine1: address,
        addressLine2: addressLine2,
        city: city,
        stateID: state,
        //LogoURL:fileUrl,
        zipCode: zipCode,
        website: website,
        luid: getUserId(),
      },
    }
    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        Swal.fire(getAlertToast('success', 'Added Partner successfully'))
        const { partnerList } = response.data
        await handleReset(partnerList)

        // await handleUpdatePartner()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const handleEditPartner = async (formData) => {
    setLoading(true)
    const {
      partnerName,
      address,
      addressLine2,
      city,
      state,
      website,
    } = formData
    console.log(zipCode)
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_PARTNER_URL,
      data: {
        cmboPartner: '1',
        name: partnerName,
        addressLine1: address,
        addressLine2: addressLine2,
        city: city,
        stateID: state,
        LogoURL: fileUrl,
        zipCode: zipCode,
        website: website,
        partnerId: partnerId,
        luid: getUserId(),
      },
    }
    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        Swal.fire(getAlertToast('success', 'Updated Partner successfully'))
        const { partnerList } = response.data
        await handleReset(partnerList)
        // await handleUpdatePartner()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (title == 'Add Partner') {
        handleAddPartner(values)
      } else {
        handleEditPartner(values)
      }
    },
  })

  const {
    partnerName = '',
    address = '',
    addressLine2 = '',
    city = '',
    state = '',
    country = '',
    website = '',
  } = values
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Partner Name"
                  value={partnerName}
                  onChange={handleChange}
                  fullWidth
                  required
                  name="partnerName"
                  error={errors.partnerName && touched.partnerName}
                  helperText={
                    errors.partnerName && touched.partnerName
                      ? errors.partnerName
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <div>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                  <label htmlFor="contained-button-file">
                    <Button variant="contained" component="span">
                      Add Logo
                    </Button>
                  </label>
                  {imageUrl && (
                    <>
                      <IconButton
                        aria-label="close"
                        onClick={handleResetImage}
                        className={classes.closeButtonPreview}>
                        <CancelRoundedIcon />
                      </IconButton>
                      <Box mt={2} textAlign="left">
                        <img src={imageUrl} height="100px" width={'100px'} />
                      </Box>
                    </>
                  )}
                </div>
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Address2"
                  value={address}
                  onChange={handleChange}
                  fullWidth
                  name="address"
                  error={errors.address && touched.address}
                  helperText={
                    errors.address && touched.address ? errors.address : ''
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Address Line 2"
                  value={addressLine2}
                  onChange={handleChange}
                  fullWidth
                  name="addressLine2"
                  error={errors.addressLine2 && touched.addressLine2}
                  helperText={
                    errors.addressLine2 && touched.addressLine2
                      ? errors.addressLine2
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="City"
                  value={city}
                  onChange={handleChange}
                  fullWidth
                  name="city"
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city ? errors.city : ''}
                />
              </Grid>
              <Grid item xs={4}>
                <State
                  id="state"
                  name="state"
                  value={state}
                  required
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                />
              </Grid>

              {/* <Grid item xs={4}>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  name="country"
                  value={country}
                  onChange={handleChange}
                  fullWidth
                  label="Country"
                  error={errors.country && touched.country}
                  helperText={
                    errors.country && touched.country ? errors.country : ""
                  }
                >
                  <MenuItem value="">Select Country</MenuItem>
                </Select>
              </Grid> */}
              <Grid item xs={4}>
                <TextField
                  id="name-helper-text"
                  label="Zip Code"
                  value={zipCode}
                  onChange={handleChangeZipcode}
                  fullWidth
                  name="zipCode"
                  error={errors.zipCode && touched.zipCode}
                  helperText={
                    errors.zipCode && touched.zipCode ? errors.zipCode : ''
                  }
                />
              </Grid>

              {/*<Grid item xs={6}>
                <TextField
                  id="name-helper-text"
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={handleChange}
                  fullWidth
                  InputProps={{
                    inputProps: { 
                        max: 10, min: 10 
                    }
                  }}
                  name="phoneNumber"
                  type="number"
                  error={errors.phoneNumber && touched.phoneNumber}
                  helperText={
                    errors.phoneNumber && touched.phoneNumber
                      ? errors.phoneNumber
                      : ""
                  }
                />
                </Grid>   */}
              <Grid item xs={6}>
                <TextField
                  id="name-helper-text"
                  label="Partner Website"
                  value={website}
                  onChange={handleChange}
                  fullWidth
                  name="website"
                  error={errors.website && touched.website}
                  helperText={
                    errors.website && touched.website ? errors.website : ''
                  }
                />
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ float: 'right' }}>
                  {loading && <CustomLoader loading={loading} />} Save
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
