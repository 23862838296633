import * as types from '../action/ActionType'
import { CUSTOM_ITEM_, SUB_CUSTOM_ITEM_ } from '../action/Typeconstent'

export default function NewCustomChecked(state = {}, action) {
  console.log('custom item reducer')
  const { type, payload = {} } = action
  switch (type) {
    case types.TOTAL_SELECTED_COUSTOM_ITEM:
      return (state = payload)
    default:
      return state
  }
}
