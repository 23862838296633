import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  FORGOT_PASSWORD_PATH,
  SIGN_UP_PATH,
  PROJECTS_PATH,
  DASHBOARD_PATH,
  NEW_PASSWORD_PATH,
  TASK_PATH,
  TEAMENDUSER,
  TEAM_NEWUSER,
} from '../helpers/constants/RoutePaths'
import { useFormik } from 'formik'
import { debounce } from 'underscore'
import NewPassword from '../components/NewPassword'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import logo from '../assets/images/logo.png'
import {
  GET_PARTNER_LIST_URL,
  GET_USER_BY_NAME_EMAIL,
  LOGIN_URL,
  ROSTER_WARNING,
  UPDATE_USERNAME_PASSWORD_PROFILE,
  USER_VERIFY_BY_LOGIN_KEY,
  GET_ALL_ORGANIZATION,
  TEAM_GETURL,
  TEAM_ADD_TAST_USER,
  TEAM_GET_ORGANIZATION_ID,
  CHECKTHE_ACCOUNT,
} from '../helpers/constants/ApiRoutes'
import { CustomMessage, locationParams } from '../function/HelperFunction'
import { API } from '../helpers/API'
import { authHeader, getQueryParamsByName } from './common/CustomFunction'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ReCAPTCHA from 'react-google-recaptcha'

// Custom Checkbox
const CustomCheckbox = withStyles((theme) => ({
  root: {
    color: theme.primaryColor,
    '&$checked': {
      color: theme.primaryColor,
    },
  },
  checked: {},
}))((props) => <Checkbox color="default" {...props} />)

//Schema
const validationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Give valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  cpassword: Yup.string()
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Both password need to be the same'
      ),
    })
    .required('Confirm Password Required'),
  domain: Yup.string().required('Organization name is required'),
})
const checkinvalidationSchema = Yup.object({
  email: Yup.string()
    .trim()
    .email('Give valid email')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
})

//state
const initialValues = {
  email: '',
  password: '',
  cpassword: '',
  domain: '',
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f2f2',
  },
  signbg: {
    display: 'flex',
    justifyContent: 'center',
  },
  signinbtn: {
    marginTop: '1rem',
    width: '50%',
    backgroundColor: theme.btnPrimary,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.btnPrimary,
    },
  },
  title: {
    fontFamily: 'Nunito-Regular',
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  linkBlock: {
    margin: '1rem',
  },
  formBlock: {
    margin: '7px',
  },
  card: {
    boxShadow: theme.outShadow,
  },
  container: {
    padding: '8.5rem 0 8.5rem 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
      marginLeft: '4px',
      marginRight: '4px',
    },
  },
  logoBlock: {
    width: '125px',
    height: '35px',
    marginBottom: '1.5rem',
  },
}))

export default function Signinold(props) {
  const classes = useStyles()
  const [isValidId, setValid] = useState(false)
  const [flag, setFlag] = useState(false)
  const [taskUser, setTaskUser] = useState(false)
  const [userDetail, setUserDetail] = useState({})
  const [orgName, setorgName] = useState('')
  const [access, setAccess] = useState('')
  const [emailvalue, setEmailvalue] = useState('')
  const [disAble, setdisAble] = useState(false)
  const [orgalizationId, setorgalizationId] = useState(0)
  const [userVerified, setuserVerified] = useState(true)
  const { id } = useParams()
  const [organizationList, setorganizationList] = useState([])
  const history = useHistory()
  const [verifuBod, setverifuBod] = useState(true)
  var { UrlID, ref } = locationParams(window.location.search) || {}

  const { enqueueSnackbar } = useSnackbar()
  const search = window.location.search

  const handleLogin = async (formData) => {
    if (verifuBod) {
      CustomMessage('Please verify you are not robot', 'error', enqueueSnackbar) //call toster

      return null
    }
    localStorage.setItem('LOG_EMAIL', formData.email)
    const config = {
      method: 'POST',
      url: LOGIN_URL,
      data: {
        userName: formData.email,
        password: formData.password,
        domain: `${formData.domain}`,
      },
    }
    try {
      const response = await API(config)
      const { status, data, token } = response.data
      if (status == 'Success') {
        const {
          zUserID = '',
          zOrganizationID = '',
          zFirstName = '',
          zLastName = '',
          zUserRoleID = '',
          zOrderID = '',
        } = data

        localStorage.setItem('AUTH_TOKEN', token)
        localStorage.setItem('CANTOODOMAIN', formData.domain)
        localStorage.setItem('ORGANIZATION_NAME', orgName)
        localStorage.setItem('LOG_USER_ID', zUserID)
        localStorage.setItem('LOG_ORGANIZATION_ID', zOrganizationID)
        localStorage.setItem('LOG_USER_FIRSTNAME', zFirstName)
        localStorage.setItem('LOG_USER_LASTNAME', zLastName)
        localStorage.setItem('USER_ROLE_ID', zUserRoleID)
        localStorage.setItem('ORDER_ID', zOrderID)

        if (zUserRoleID != 0) {
          const config = {
            method: 'GET',
            url: GET_USER_BY_NAME_EMAIL,
            headers: authHeader(),

            params: {
              Email: formData.email,
            },
          }
          const partnerConfig = {
            method: 'GET',
            url: GET_PARTNER_LIST_URL,
            headers: authHeader(),
          }
          const partnerResponse = await API(partnerConfig)
          const response = await API(config)
          if (response.status === 200) {
            const { data } = response.data
            const currentUser = data.find((user) => user.orderID === zOrderID)
            localStorage.setItem('USER_ADMIN_ROLE', currentUser.userAdminRole)
            if (partnerResponse.status === 200) {
              const { partnerList } = partnerResponse.data
              const partner = partnerList.find(
                (val) => val.zName === currentUser.partner
              )
              localStorage.setItem('PARTNER', partner.zPartnerID)
            }

            if (UrlID) {
              const addUserconfig = {
                method: 'GET',
                url: TEAM_ADD_TAST_USER,
                //  headers: authHeader(),

                params: {
                  ProjectID: formData.userProjectId,
                  UserID: zUserID,
                },
              }
              const adduserResponse = await API(addUserconfig)
              const { Project, TaskID } = adduserResponse.data
              history.push(`${TEAMENDUSER}/${Project}/${TaskID}`)
            } else {
              history.push(DASHBOARD_PATH)
            }
          }
        } else {
          if (UrlID) {
            const addUserconfig = {
              method: 'GET',
              url: TEAM_ADD_TAST_USER,
              //  headers: authHeader(),

              params: {
                ProjectID: formData.userProjectId,
                UserID: zUserID,
              },
            }
            const adduserResponse = await API(addUserconfig)
            const { Project, TaskID } = adduserResponse.data
            history.push(`${TEAMENDUSER}/${Project}/${TaskID}`)
          } else {
            history.push(TASK_PATH)
            return
          }
        }
        CustomMessage('Successful Login', 'success', enqueueSnackbar) //call toster
      }
    } catch (error) {
      if (error.response) {
        console.error('errrorrrr', error.response)
        let { data } = error.response
        CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
        //enqueueSnackbar('Error')
      }
    }
  }
  const checkAccount = async (values) => {
    console.log(values)
    const config = {
      method: 'GET',
      url: CHECKTHE_ACCOUNT,

      params: {
        UserName: values.email,
        Password: values.password,
      },
    }
    try {
      const response = await API(config)
      CustomMessage(
        'User validated, please choose your organization',
        'success',
        enqueueSnackbar
      ) //call toster
      getOrganization()
      setuserVerified(false)
    } catch (er) {
      CustomMessage('Invalid username and password', 'error', enqueueSnackbar) //call toster

      console.log(er)
    }
  }
  const updateUser = async (values) => {
    const { userId, firstName, lastName } = userDetail
    const { password } = values
    const config = {
      method: 'PUT',
      url: UPDATE_USERNAME_PASSWORD_PROFILE,
      headers: authHeader(),

      data: {
        userID: userId,
        firstName,
        lastName,
        password,
        luid: 0,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      handleLogin(values)
    }
  }

  //Formik
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      email: emailvalue,
      domain: '',
    },
    enableReinitialize: true,
    validationSchema: userVerified ? checkinvalidationSchema : validationSchema,
    onSubmit: (values) =>
      userVerified
        ? checkAccount(values)
        : taskUser
        ? updateUser(values)
        : handleLogin(values),
  })

  const { email = '', password = '', cpassword = '', domain = '' } = values

  const getUserDetails = async (email, orgId) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_USER_BY_NAME_EMAIL,
      params: {
        email,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      let { data } = response.data
      data = data.filter((rec) => rec.orgId === orgId)
      if (!data[0].lastLoginDate) {
        setTaskUser(true)
        setuserVerified(false)
        setUserDetail(data[0])
      } else {
        validationSchema.fields.cpassword = ''
      }
    }
  }

  const verifyUserByLoginKey = async (loginKey) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: USER_VERIFY_BY_LOGIN_KEY,
      params: {
        Loginkey: loginKey,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      const { data } = response.data
      getUserDetails(data[0].zEmail, data[0].zOrganizationID)
      setValues((val) => ({
        ...val,
        email: data[0].zEmail,
        password: '',
      }))
    }
  }

  const userVerifyByLoginKey = async (loginKey) => {
    const config = {
      method: 'GET',
      url: USER_VERIFY_BY_LOGIN_KEY,
      params: {
        Loginkey: loginKey,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      const { data } = response.data
      console.log(data[0].zLastLogin)
      if (data[0].zLastLogin == '0001-01-01T00:00:00') {
        setFlag(true)
      } else {
        setEmailvalue(data[0].zEmail)
        setFlag(false)
      }
    }
  }
  const getOrderdata = async (orderid) => {
    setuserVerified(false)
    const config = {
      method: 'GET',
      url: TEAM_GETURL,
      params: {
        UrlID: orderid,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      let splitedData = response.data.data[0]?.zURL?.split('/')
      console.log(splitedData, 'splitedData')

      let userDmoin = splitedData[2]
      let userProjectId = splitedData.slice(-1)[0].split('=')[1]
      setValues((val) => ({ domain: userDmoin, userProjectId }))
    }
  }

  useEffect(() => {
    if (props.match.params.id == 12345) {
      setValid(true)
    }
  }, [props.match.params.id])

  useEffect(() => {
    if (ref) {
      /*setAccess(ref);*/
      userVerifyByLoginKey(ref)
    }

    if (UrlID) {
      getOrderdata(UrlID)
    }
  }, [search])
  const getOrganizationIdbyorg = async (domain) => {
    const config = {
      method: 'GET',
      url: TEAM_GET_ORGANIZATION_ID,
      params: {
        Domain: domain,
      },
    }
    const response = await API(config)
    setorgalizationId(response?.data?.data?.table[0]?.zOrganizationID)
  }
  useEffect(() => {
    if (values.domain) getOrganizationIdbyorg(values.domain)
  }, [values.domain])

  useEffect(() => {
    if (id) {
      verifyUserByLoginKey(id)
      setuserVerified(false)
      setdisAble(true)
    } else {
      validationSchema.fields.cpassword = ''
    }
  }, [])

  const handleSample = async () => {
    const config = {
      method: 'GET',
      url: ROSTER_WARNING,
      params: {
        ProjectId: 23226,
      },
    }
    const response = await API(config)
    var link = document.createElement('a')

    link.href =
      'data:application/octet-stream;base64,' +
      response.data.data[0].zAuditReport
    link.download = 'auditReport.pdf'
    link.click()
  }
  const getOrganization = async (newData) => {
    const config = {
      method: 'GET',
      url: GET_ALL_ORGANIZATION,
      params: {
        // Name: value,
        Email: values.email,
        // count: 20,
      },
    }
    try {
      const response = await API(config)
      console.log(response)
      const { result } = response.data
      const { table1 } = result
      let or = table1.map((value) => ({
        label: value.zOrganizationName,
        title: value.zOrganizationName,
        domain: value?.zDomain,
      }))
      setorganizationList(or)
    } catch (er) {
      console.error(er)
    }
  }

  const organiZationhandle = (e) => {
    // organizationSearch(e.target.value, values)
  }
  const organizationSelect = (e, value) => {
    setValues((val) => ({ ...val, domain: value?.domain || '' }))
    setorgName(value.title)
  }
  const checkWhatsapp = (value) => {
    if (value) {
      setverifuBod(false)
    } else {
      setverifuBod(true)
    }
  }

  return (
    <div className={classes.root}>
      {!flag && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
          className={classes.container}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                <center>
                  <div className={classes.logoBlock}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: '100%', height: '100%' }}
                    />
                  </div>

                  <Typography
                    className={classes.title}
                    color="subtitle2"
                    gutterBottom>
                    Welcome!
                  </Typography>
                  <Typography variant="body2" component="p">
                    Sign in to your account
                  </Typography>
                </center>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.formBlock}>
                      <TextField
                        id="email-helper-text"
                        label="Email Address"
                        value={email}
                        onChange={handleChange}
                        fullWidth
                        name="email"
                        disabled={disAble}
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email ? errors.email : ''
                        }
                      />
                      <TextField
                        type="password"
                        id="password"
                        label="Password"
                        value={password}
                        onChange={handleChange}
                        fullWidth
                        name="password"
                        error={errors.password && touched.password}
                        helperText={
                          errors.password && touched.password
                            ? errors.password
                            : ''
                        }
                      />
                      {!UrlID && !userVerified && (
                        <Autocomplete
                          id="size-small-standard"
                          size="small"
                          options={organizationList}
                          onChange={organizationSelect}
                          // disabled={true}
                          disabled={(errors.email && touched.email) || !email}
                          getOptionLabel={(option) => option.title}
                          // defaultValue={top100Films[13]}
                          renderInput={(params) => (
                            <>
                              <TextField
                                {...params}
                                error={errors.domain && touched.domain}
                                onChange={organiZationhandle}
                                variant="standard"
                                // error={true}
                                label="Select organization"
                                placeholder="Select organization"
                                helperText={
                                  errors.domain && touched.domain
                                    ? errors.domain
                                    : ''
                                }
                              />
                            </>
                          )}
                        />
                      )}
                      {/* <TextField
                      type="text"
                      id="domain"
                      label="Organization Name"
                      value={domain}
                      onChange={handleChange}
                      fullWidth
                      name="domain"
                      error={errors.domain && touched.domain}
                      helperText={
                        errors.domain && touched.domain ? errors.domain : ''
                      }
                    /> */}
                      {taskUser && (
                        <TextField
                          type="password"
                          id="cpassword"
                          label="Confirm Password"
                          value={cpassword}
                          onChange={handleChange}
                          fullWidth
                          name="cpassword"
                          error={errors.cpassword && touched.cpassword}
                          helperText={
                            errors.cpassword && touched.cpassword
                              ? errors.cpassword
                              : ''
                          }
                        />
                      )}
                      <FormControl
                        component="fieldset"
                        style={{ marginTop: '6px' }}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <CustomCheckbox
                                // checked={acceptTerms}
                                // onChange={(event) => {
                                //     const {checked} = event.target;
                                //     setFieldTouched('acceptTerms',true);
                                //     handleChange(event);
                                //     if(checked) {
                                //         dialogToggleFunc();
                                //     }else{
                                //         setDownloadablePdf(null);
                                //     }
                                // }}
                                name="rememberMe"
                                color="primary"
                              />
                            }
                            label="Remember me"
                          />
                        </FormGroup>
                      </FormControl>
                      <Grid>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_NOTA_ROBOT}
                          onChange={checkWhatsapp}
                        />
                      </Grid>

                      <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className={classes.signbg}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.signinbtn}>
                          {userVerified ? 'Check In' : 'Sign In'}
                        </Button>
                      </Grid>
                      {/* <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={()=>handleSample()}
                      >
                      sample
                    </Button> */}
                      <center>
                        <div className={classes.linkBlock}>
                          <Link
                            to={FORGOT_PASSWORD_PATH}
                            className={classes.linkStyle}>
                            Forgot Password
                          </Link>
                        </div>
                        {UrlID && orgalizationId && (
                          <div className={classes.linkBlock}>
                            <Link
                              to={{
                                pathname: TEAM_NEWUSER,
                                state: {
                                  orgid: orgalizationId,

                                  ProjectID: values.userProjectId,
                                  domainname: values.domain,
                                },
                              }}
                              className={classes.linkStyle}>
                              Create An Account
                            </Link>
                          </div>
                        )}
                        {access && (
                          <div className={classes.linkBlock}>
                            <Link
                              to={{
                                pathname: NEW_PASSWORD_PATH,
                                search: `?ref=${access}`,
                              }}
                              className={classes.linkStyle}>
                              Create An Account
                            </Link>
                          </div>
                        )}
                      </center>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {flag && <NewPassword />}
    </div>
  )
}
