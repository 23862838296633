import React, { useEffect } from "react";
import { createRef } from "react";
import {
    ExcelExport,
    ExcelExportColumn,
  } from "@progress/kendo-react-excel-export";
import CustomButton from "../../../../../common/CustomButton";
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  
    buttona: {
      background: '#64B5F6 !important',
    },
  }))
export default function SummaryReportFile(props){
    const classes = useStyles()
  
    const {projectname='',sheet1,sheet2,organizerName,loading=false,expFlag=false}=props
console.log('project',sheet1,sheet2)
    useEffect(()=>{
      sheet1.unshift({
      "Average (excl. Self)"
:"", 
"Direct Report"
:"", 
Manager
:"", 
Other
:"", 
Peer
:"", 
Self
:"", 
oid
:"", 
zAverage
:"", 
zCapabilityID
:"", 
zCapabilityName
:"", 
zCapabilityNm
:"", 
zCategoryNm
:"", 
zCompetencyID
:"", 
zCompetencyNm
:"", 
zFirstName
:"", 
zLastName
:"", 
zManagerFlag
:"", 
zNumberOfRatings
:"", 
zParticipantID
:"", 
zRelationshipID
: 
"",
zRptCap
:"", 
zRptComp
:"", 
zSortOrder
: 
"" })
        sheet1&&sheet1.map((rec,i)=>{
          let tp=''
          if(i>0){
            tp=i
          }
            return(
                rec.zCompetencyNm=rec.zCompetencyNm.toUpperCase(),
                rec.oid=tp
            )
        })
    },[sheet1])

    useEffect(()=>{
      sheet2.unshift({
        zAverage:"",
zCapabilityID
: "",

zCapabilityName
: "",
zCaption
: "",
zCompetencyID
: "",
zCompetencyName
: "",
zInd
: "",
zRelationshipID:""

      })
        sheet2&&sheet2.map((rec,i)=>{
            return(
                rec.zCompetencyName=rec.zCompetencyName.toUpperCase()
            )
        })
    },[sheet2])
   
      const _exporter = React.createRef();
      const _exporter1 = React.createRef();
  const excelExport = () => {
    save(_exporter,_exporter1);
  };
  const save = (component,component1) => {
    const options = component.current.workbookOptions();
    const options1 = component1.current.workbookOptions();
    options.sheets[0]=options.sheets[0]
    options.sheets[1]=options1.sheets[0]

    options.sheets[0].title='Individual Scores All Scores'
    options.sheets[1].title='All Items Stats'
    const rows = options.sheets[1].rows;
    // rows[1].unshift({cells:[{ value: "",
    // background: "#fffff",
    // colSpan: 7,
    // color: "#fff",
    // rowSpan: 1}]})
    rows.forEach(row => {
      console.log("row",row)
      row.height=40
      if (row.type === "data") {
        row.cells.forEach(cell => {
          // cell.height=350;
          // cell.wrap=true
        });
      }
    });
    component.current.save(options);
  };
  useEffect(()=>{
    if(expFlag==true){
      excelExport()
    }
  },[])
    return(
        <>
        
        <div>
          {expFlag==true?<></>:
        <CustomButton className={classes.buttona} colour="primaryButton"
        onClick={excelExport}>
            {!loading ? 'Download' : 'Loading...'}
          </CustomButton>}
      {/* <button
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
        onClick={excelExport}
      >
        Export to Excel
      </button> */}

      <ExcelExport data={sheet1} 
      fileName={`ThinkWise360Summary_${projectname}.xlsx`} 
      ref={_exporter}
      headerPaddingCellOptions={{
        color:'#58a83a',
        background: "#58a83a",
        fontSize:'20px'
      }}>
        <ExcelExportColumn field="zFirstName" title="Participant First Name" width={100} 
        cellOptions={{fontSize:'11px'}} 
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       
        />
        <ExcelExportColumn field="zLastName" title="Participant Last Name" width={100} 
        cellOptions={{fontSize:'11px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="oid" title="Competency Number" width={40} cellOptions={{fontSize:'11px'}}
         headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       /> 
        {/* i+1 for above */}
        <ExcelExportColumn field="zCompetencyNm" title="Competency" cellOptions={{bold:true,fontSize:'12px'}}
         headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="zCapabilityID" title="Category Number" width={40} cellOptions={{fontSize:'11px'}}
         headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="zCapabilityName" title="Category" 
         cellOptions={{bold:true,fontSize:'11px'}} 
         headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="" title="" width={20}  headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="Average (excl. Self)" title="Average (excluding Self)"  
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
        width={30}
       />
        <ExcelExportColumn field="Self" title="Self" width={20} 
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
         <ExcelExportColumn field="Colleague" title="Colleague" width={60} 
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
        <ExcelExportColumn field="Direct Report" title="Direct Report" width={60} 
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
        <ExcelExportColumn field="Manager" title="Manager" width={60} 
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
        <ExcelExportColumn field="Manager" title="Manager 2" width={60} 
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
        <ExcelExportColumn field="Manager" title="Manager 3" width={60} 
        cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
        <ExcelExportColumn field="Peer" title="Peer" width={60}
        cellOptions={{bold:true,fontSize:'12px'}} 
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
        <ExcelExportColumn field="Other" title="Other" width={60} cellOptions={{bold:true,fontSize:'12px'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}/>
      </ExcelExport>
      <ExcelExport data={sheet2} fileName={`${projectname}_SummaryReport.xlsx`}  ref={_exporter1}>
        <ExcelExportColumn field="zCompetencyName" title="Competency"  
        cellOptions={{verticalAlign:'center',
        }} 
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       
         />
        <ExcelExportColumn field="zInd" title="Item Number" cellOptions={{verticalAlign:'center'}} 
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       
       />
        <ExcelExportColumn field="zCapabilityID"  width={60} title="Category Number" 
        cellOptions={{verticalAlign:'center'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="zCapabilityName" title="Category" 
        cellOptions={{verticalAlign:'center'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       
        />
        <ExcelExportColumn field="zCaption" title="Item" 
        cellOptions={{verticalAlign:'center'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        <ExcelExportColumn field="" title="" width={20}/>
        <ExcelExportColumn field="zAverage" title={organizerName} 
        width={30}
        cellOptions={{verticalAlign:'center'}}
        headerCellOptions={{
          textAlign:'center',
          background:'#fffff',
          color:'#111010',
        }}
       />
        
      </ExcelExport>
    </div>
        </>
    )
}