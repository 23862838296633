import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import {
  sortableElement,
  sortableHandle,
  sortableContainer,
} from 'react-sortable-hoc'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import OpenWith from '@material-ui/icons/OpenWith'

import { useDispatch, useSelector } from 'react-redux'
import { values } from 'underscore'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from '../../../../../redux/action/Typeconstent'
import {
  ADD_PROJECT_ITEMS,
  COMPETENCY_MODEL_SORT_INSERT,
  GET_COMPETENCY_MODULE_SORT,
  SELECTED_DRAG_DROP_DELETE,
  SELECTED_DRAG_DROP_GET,
  SELECTED_DRAG_DROP_INSERT,
} from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import { useParams } from 'react-router-dom'
import {
  COMPETENCY_DRAG_DROP,
  NEW_SELECTED_THINKWISE_ID,
  NEW_SELECTED_THINKWISE_ITEMS_ALL_ID,
  NEW_THINKWISE_ITEM,
  SAVE_CURRENT_STEP,
  SELECTED_DRAG_DROP,
  SELECTED_ITEMS_REDUCER,
  SELECTED_SUB_ITEMS,
  TOTAL_SELECTED_COUSTOM_ITEM,
} from '../../../../../redux/action/ActionType'
import {
  CustomMessage,
  getSorting,
  stableSortone,
} from '../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import NewThinkwisepreview from './ProjectView/NewThinkwisepreview'
import CustomButton from '../../../../common/CustomButton'
import { authHeader } from '../../../../common/CustomFunction'
import arrayMove, { arrayMoveImmutable } from 'array-move'
import { getAllsaveItems } from '../../../../../redux/action'

const SortableContainer = sortableContainer(({ children }) => {
  return <div>{children}</div>
})

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 'bold',
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  accHeadSelected: {
    backgroundColor: '#64B5F6',
    color: '#000',
    height: '64px',
    '& .MuiSvgIcon-root': {
      color: '#000',
      // backgroundColor:"#000"
    },
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
    height: '64px',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemSelectedStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#1565C0',
      color: '#ffffff',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1565C0',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#ffff',
      },
      '& $iconStyle': {
        color: '#ffffff',
      },
    },
  },
}))
function NewthinkwisePreview(props) {
  const handleSortItem = async (
    oldIndex,
    newIndex,
    subCategoryId,
    expanded
  ) => {
    console.log('index', oldIndex, newIndex)
    let temp = previewTotalselecteditem
    let cm = 1
    let cid = 1
    console.log('temp before move', temp)
    let arr = ['329', '330', '331', '332', '333', '334']
    let oi = temp.indexOf(oldIndex)
    let ni = temp.indexOf(newIndex)
    console.log('finded index', oldIndex, newIndex, oi, ni)
    temp = arrayMove(temp, oi, ni)
    console.log('temp', temp)
    // dispatch({ type: SELECTED_SUB_ITEMS, payload: temp })
    // dispatch({ type: NEW_THINKWISE_ITEM, payload: temp })
    //   dispatch({
    //   type: NEW_SELECTED_THINKWISE_ITEMS_ALL_ID,
    //   payload: { arr, cm, cid },
    // })
    // dispatch({
    //   type: NEW_SELECTED_THINKWISE_ID,
    //   payload: temp,
    // })
    setOrderList(temp)
    // setItems(temp)
    // Object.values(temp).forEach((value) => {
    // Object.values(value).forEach((v) => {
    // previewTotalselecteditem = temp
    // })
    // })
    console.log('preview', previewTotalselecteditem, orderedList)
    // handleSaveProjectItems()
    // const handleSaveProjectItems = async () => {
    console.log('orderlist', orderedList)
    console.log('orderlisttemp', temp)
    const postConfig = {
      method: 'POST',
      url: ADD_PROJECT_ITEMS,
      headers: authHeader(),

      data: {
        projectID: parseInt(projectId),
        // itemIdList: previewTotalselecteditem,
        itemIdList: temp,
      },
    }
    const response = await API(postConfig)
    if (response && response.status === 200) {
      //Swal.fire('Success', response.data.message)
      // CustomMessage(response.data.message, 'success', enqueueSnackbar)
      // dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
      dispatch(getAllsaveItems(parseInt(projectId)))
    }
    // }
  }
  const thcus = useSelector((state) => state.ThinkandCustomReducer)
  console.log('THCUS', thcus)
  const NewCustomItemsReducer = useSelector(
    (state) => state.NewCustomItemsReducer
  )
  const NewOrgSummary = useSelector((state) => state.NewOrgSummary)
  const ListOfData = [...NewOrgSummary, ...NewCustomItemsReducer]
  // console.log(NewCustomSelected,"NewCustomItemsReducer");

  const NewCustomSelected = useSelector((cs) => cs.NewCustomItem)
  const NewSelectedItemsId = useSelector((val) => val.NewSelectedItemsIdReducer)
  const handleSortInnerAccordian = async (capablityid, oind, nind) => {
    console.log("i'm declared", capablityid, NewCustomSelected)
    let selected
    let selectedd
    if (NewSelectedItemsId) {
      selected = NewSelectedItemsId[CUSTOM_ITEM_ + capablityid] || []
    }
    selectedd = arrayMove(selected, oind, nind)
    // setOrderList(orderedList.concat(selected))
    console.log('selllll', selected, selectedd)
    const postConfig = {
      method: 'POST',
      url: ADD_PROJECT_ITEMS,
      headers: authHeader(),

      data: {
        projectID: parseInt(projectId),
        // itemIdList: previewTotalselecteditem,
        itemIdList: selectedd,
      },
    }
    const response = await API(postConfig)
    if (response && response.status === 200) {
      //Swal.fire('Success', response.data.message)
      // CustomMessage(response.data.message, 'success', enqueueSnackbar)
      // dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
      dispatch(getAllsaveItems(parseInt(projectId)))
    }
    console.log('selected', selectedd, orderedList)
  }
  const { zScaleID = '', prevProjectId = '' } = props
  const classes = useStyles()
  const NewthinkwiseReducer = useSelector((state) => state.NewthinkwiseReducer)
  const NewthinkwiseSelecteditemReducer = useSelector(
    (state) => state.NewthinkwiseSelecteditemReducer
  )

  const NewSelectedCustomitemReducer = useSelector(
    (state) => state.NewSelectedCustomitemReducer
  )

  const TotalItems = [...ListOfData, ...NewthinkwiseReducer]
  const list = []

  const currentStep = useSelector((state) => state.CurrentStep)
  const [survey, setsurvey] = useState(false)
  const [sordedGetData, setSordedGetData] = useState([])
  const [newThinkWisedata, setNewThinkWisedata] = useState([
    ...NewthinkwiseReducer,
    ...ListOfData,
  ])
  const [drag, setDrag] = useState(false)
  const selectedThinkwiseData = useSelector((item) => item.SelectedItemReducer)
  const PreviewClose = () => setsurvey((val) => !val)

  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const NewSelectedItemsIdReducer = useSelector(
    (s) => s.NewSelectedItemsIdReducer
  )
  let allSelectedItem = []

  Object.values(NewSelectedItemsIdReducer).forEach((value) => {
    if (value.length) {
      allSelectedItem.push(...value)
    } else {
      Object.values(value).forEach((value) => {
        allSelectedItem.push(...value)
      })
    }
  })
  const { id: projectId } = useParams()
  let previewTotalcutomitem = []

  const NewselectedThinkwiseItems = useSelector(
    (v) => v.NewselectedThinkwiseItems
  )
  const NewCustomChecked = useSelector((v) => v.NewCustomChecked)

  // console.log(NewCustomSelected[])
  // Object.values(NewCustomSelected).forEach((value) => {
  //   Object.values(value).forEach((v) => {
  //     previewTotalcutomitem.push(...v)
  //   })
  // })
  console.log('new select', NewCustomSelected, previewTotalcutomitem)
  console.log('when sel', NewSelectedItemsIdReducer, NewselectedThinkwiseItems)
  const [orderedList, setOrderList] = useState([])
  const [allCheckedItems, setAllCheckedItems] = useState([])
  let previewTotalselecteditem = []
  let previewTotalselecteditemlist = []
  let previewTotalList = NewthinkwiseSelecteditemReducer.concat(
    NewSelectedCustomitemReducer
  )

  Object.values(NewselectedThinkwiseItems).forEach((value) => {
    previewTotalselecteditemlist.push(value)
    Object.values(value).forEach((v) => {
      previewTotalselecteditem.push(...v)
    })
  })
  const changeSortOrderInvoice = async () => {
    let fieldArray = [...previewTotalselecteditem]
    let oldIndex = 1
    let newIndex = 2
    console.log(oldIndex, newIndex)
    fieldArray = arrayMoveImmutable(fieldArray, oldIndex, newIndex)
    fieldArray.forEach((element, index) => (element.fieldOrder = index + 1))
    // dispatch({ type: REQUIRED_INVOICE_LINE_ITEM, payload: [...fieldArray] });
    Object.values(fieldArray).forEach((value) => {
      Object.values(value).forEach((v) => {
        previewTotalselecteditem.push(...v)
      })
    })
    console.log('chking order', fieldArray, previewTotalselecteditem)
  }
  console.log('preview1', orderedList)
  Object.values(NewSelectedItemsIdReducer).forEach((value) => {
    previewTotalselecteditemlist.push(value)
    if (value.length) {
      previewTotalselecteditem.push(...value)
    } else {
      Object.values(value).forEach((va) => {
        previewTotalselecteditem.push(...va)
      })
    }
  })

  const handleDragandDropItems = async (newValue) => {


    console.log(newValue,'newValllllll')
    const lengthValue = newValue?.length
    const newArray = Array.from(Array(lengthValue).keys()).map((i) => i + 1)

console.log(newArray,'newArraynewArray');

    const searchPath = window.location.pathname
    const arr = searchPath.split('/')

    const payloadValue = newValue?.map((item) =>
      item?.compentencyid ? item?.compentencyid : item?.capablityid
    )    

    console.log(payloadValue,'payloadValue');
    const payloadData = []
    for (let index = 0; index < newArray.length; index++) {
      payloadData.push({
        projectID: parseInt(arr[3]),
        competencyID: parseInt(payloadValue[index]),
        sortID: newArray[index],
      })
    }

    let previewTotalselecteditem11 = []
    Object.values(NewselectedThinkwiseItems).forEach((value) => {
      Object.values(value).forEach((v) => {
        previewTotalselecteditem11.push(...v)
      })
    })

    Object.values(NewSelectedItemsIdReducer).forEach((value) => {
      if (value.length) {
        previewTotalselecteditem11.push(...value)
      } else {
        Object.values(value).forEach((va) => {
          previewTotalselecteditem11.push(...va)
        })
      }
    })

    if (newValue == 0) {
      dispatch({ type: SAVE_CURRENT_STEP, payload: newValue })
    } else {
      if (previewTotalselecteditem11.length <= 0) {
        CustomMessage('Please select the items', 'error', enqueueSnackbar)
        // return null
      } else {
        console.log(payloadData, 'paylodaaaaaaaaaaaa')
        // const filteredVal = payloadData?.filter((item)=>{
        //   return (
        //     item?.competencyID !== null || item?.competencyID !== 'null' ||
        //     item?.competencyID !== NaN || item?.competencyID !== 'NaN'
        //   )
        // })
        // console.log(filteredVal, 'filteredValfilteredVal')
        dispatch({ type: SELECTED_DRAG_DROP, payload: payloadData })
        const postConfigDel = {
          method: 'DELETE',
          headers: authHeader(),

          url: SELECTED_DRAG_DROP_DELETE,
          params: {
            ProjectID: parseInt(projectId),
          },
        }
        const res = await API(postConfigDel)
        const { status } = res
        console.log(res,'jjjjjjjjjjj');
        if (res && res.status === 200) {
          const postConfig = {
            method: 'POST',
            headers: authHeader(),

            url: SELECTED_DRAG_DROP_INSERT,
            data: {
              competencyList: payloadData,
            },
          }
          const response = await API(postConfig)
          console.log(response,'jjjjjjjjjjj');
          if (response && response.status === 200) {
            CustomMessage(response.data.message, 'success', enqueueSnackbar)
            selectDragandDropGET(parseInt(projectId))
            // dispatch(getAllsaveItems(parseInt(projectId)));
          }
        }
          // const postConfig = {
          //   method: 'POST',
          //   headers: authHeader(),

          //   url: SELECTED_DRAG_DROP_INSERT,
          //   data: {
          //     competencyList: filteredVal,
          //   },
          // }
          // const response = await API(postConfig)
          // console.log(response,'jjjjjjjjjjj');
          // if (response && response.status === 200) {
          //   CustomMessage(response.data.message, 'success', enqueueSnackbar)
          //   selectDragandDropGET(parseInt(projectId))
          //   // dispatch(getAllsaveItems(parseInt(projectId)));
          // }
        
      }
    }
  }
  const updateSelectedThinkwiseData = (data) => {
    console.log('sites', selectedThinkwiseData)
    if (selectedThinkwiseData && selectedThinkwiseData.length > 0) {
      const updatedData = selectedThinkwiseData.map((item1) => {
        const matchingItem = data.find(
          (item2) =>
            parseInt(item1.compentencyid) == parseInt(item2.zCompetencyID) ||
            parseInt(item1.capablityid) == parseInt(item2.zCompetencyID)
        )
        console.log('item1.compentencyid:', item1.compentencyid, data)
        console.log('matchingItem:', matchingItem)

        // If a match is found, update item1 with zSortID
        if (matchingItem) {
          console.log('match', matchingItem)

          return { ...item1, zSortID: matchingItem.zSortID }
        } else {
          return { ...item1, zSortID: '' } // If no match is found, keep the item unchanged
        }
      })
      dispatch({
        type: SELECTED_ITEMS_REDUCER,
        payload: updatedData,
      })
      console.log('Updated selectedThinkwiseData:', updatedData)
    } else if (newThinkWisedata && newThinkWisedata.length > 0) {
      const updatedData = newThinkWisedata.map((item1) => {
        const matchingItem = data.find(
          (item2) =>
            parseInt(item1.compentencyid) == parseInt(item2.zCompetencyID)
        )
        console.log('item1.compentencyid:', item1.compentencyid, data)
        console.log('matchingItem:', matchingItem)

        // If a match is found, update item1 with zSortID
        if (matchingItem) {
          console.log('match', matchingItem)

          return { ...item1, zSortID: matchingItem.zSortID }
        } else {
          return { ...item1, zSortID: 0 } // If no match is found, keep the item unchanged
        }
      })
      dispatch({
        type: SELECTED_ITEMS_REDUCER,
        payload: updatedData,
      })
      // Now, updatedData contains selectedThinkwiseData with added zSortID field.
      // You can use the updatedData in your React component state or wherever needed.
    }
  }
  const GetCompetencyModuleSort = async (projectId) => {
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')

    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_COMPETENCY_MODULE_SORT,
      params: {
        ProjectID: projectId ? projectId : parseInt(arr[3]),
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        const sordedList = data.map((item) => item.zCompetencyID)
        setSordedGetData(sordedList)
      }
    } catch (error) {
      console.error(error)
    }
  }
  function compareZCompetencyID(data, data1) {
    // Extract zCompetencyID lists from data and data1
    const zCompetencyIDListData = data.map((item) => item.zCompetencyID)
    const zCompetencyIDListData1 = data1.map((item) => item.zCompetencyID)

    // Sort the lists for comparison
    zCompetencyIDListData.sort()
    zCompetencyIDListData1.sort()

    // Compare the sorted lists
    return (
      JSON.stringify(zCompetencyIDListData) ===
      JSON.stringify(zCompetencyIDListData1)
    )
  }
  const selectDragandDropGETAnother = async (projectId) => {
    console.log('log', prevProjectId)
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')
    // setloading(true)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),
      url: SELECTED_DRAG_DROP_GET,
      params: {
        ProjectID: prevProjectId ? parseInt(prevProjectId) : parseInt(arr[3]),
      },
    }
    try {
      const response = await API(getConfig)
      const { data, status } = response?.data
      console.log(data, 'responseeee')
      if (status == 'Success') {
        if (data) {
          console.log('if part call update')
          const areZCompetencyIDsEqual = compareZCompetencyID(
            data,
            sordedGetData
          )
          console.log(areZCompetencyIDsEqual)
          if (areZCompetencyIDsEqual == true) {
            updateSelectedThinkwiseData(data)
          } else {
            console.log('else part working', newThinkWisedata)
            dispatch({
              type: SELECTED_ITEMS_REDUCER,
              payload: newThinkWisedata,
            })
          }
        }
      }
    } catch (err) {
    } finally {
      // setloading(false)
    }
  }
  const selectDragandDropGET = async (projectId) => {
    console.log('log', prevProjectId)
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')
    // setloading(true)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),
      url: SELECTED_DRAG_DROP_GET,
      params: {
        ProjectID: parseInt(arr[3]),
      },
    }
    try {
      const response = await API(getConfig)
      const { data, status } = response?.data
      console.log(data, 'responseeee')
      if (status == 'Success') {
        if (data.length > 0) {
          console.log('if part call update')
          updateSelectedThinkwiseData(data)
        } else {
          console.log('else part working', newThinkWisedata)
          if (prevProjectId) {
            selectDragandDropGETAnother()
          } else {
            dispatch({
              type: SELECTED_ITEMS_REDUCER,
              payload: newThinkWisedata,
            })
          }
        }
      }
    } catch (err) {
    } finally {
      // setloading(false)
    }
  }

  const handleSaveProjectItems = async () => {
    console.log('orderlist', orderedList)
    const postConfig = {
      method: 'POST',
      url: ADD_PROJECT_ITEMS,
      headers: authHeader(),

      data: {
        projectID: parseInt(projectId),
        // itemIdList: previewTotalselecteditem,
        itemIdList: orderedList ? orderedList : '',
      },
    }
    const response = await API(postConfig)
    if (response && response.status === 200) {
      //Swal.fire('Success', response.data.message)
      CustomMessage(response.data.message, 'success', enqueueSnackbar)
      // dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
      dispatch(getAllsaveItems(parseInt(projectId)))
    }
  }
  console.log('list of items', previewTotalselecteditem)
  const sorter = (a, b) => {
    if (orderedList.includes(a)) {
      return -1
    }
    if (orderedList.includes(b)) {
      return 1
    }
    return 0
  }

  const arrayLiat = []
  console.log(arrayLiat, 'arrayyyyyyyy')
  const handleSortMenuItem = async (e) => {
    console.log(e, 'temp112222')

    let temp = [...arrayLiat]
    console.log(temp, 'temptemptemptemptemp')
    console.log(arrayLiat, '')
    let oldValue = e.oldIndex
    let newValue = e.newIndex
    if (!drag) {
      setDrag(true)
      oldValue = e.oldIndex - 1
      newValue = e.newIndex - 1
    } else {
      oldValue = e.oldIndex
      newValue = e.newIndex
    }

    // Use arrayMove to swap the elements
    temp = arrayMove(temp, oldValue, newValue)
    console.log(temp, 'tempjfkdlsfjfdksjdk')
    console.log(oldValue, 'tempjfkdlsfjfdksjdk', newValue)
    setNewThinkWisedata(temp)
    dispatch({
      type: SELECTED_ITEMS_REDUCER,
      payload: temp,
    })
    handleDragandDropItems(temp, arrayLiat)
  }
  useEffect(() => {
    console.log('all now', allSelectedItem?.length)
    if (allSelectedItem && allSelectedItem.length)
      console.log('total called', allSelectedItem)
    dispatch({
      type: TOTAL_SELECTED_COUSTOM_ITEM,
      payload: allSelectedItem,
    })
    // setAllCheckedItems(allSelectedItem)
  }, [allSelectedItem.length])
  useEffect(() => {
    setAllCheckedItems(() =>
      NewCustomChecked?.length >= 0 ? [...NewCustomChecked, ...list] : [...list]
    )
  }, [NewCustomChecked])
  useEffect(() => {
    setAllCheckedItems(() =>
      NewCustomChecked?.length >= 0 ? [...NewCustomChecked, ...list] : [...list]
    )
    dispatch({
      type: SELECTED_ITEMS_REDUCER,
      payload: newThinkWisedata,
    })
    GetCompetencyModuleSort()
    selectDragandDropGET()
    selectedThinkwiseData.sort(
      (a, b) =>
        sordedGetData.indexOf(a.zCompetencyID) -
        sordedGetData.indexOf(b.zCompetencyID)
    )
  }, [])

  return (
    <form
      noValidate
      autoComplete="off"
      id="form_1"
      onSubmit={(e) => {
        e.preventDefault()
        handleSaveProjectItems(e)
      }}>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        style={{ marginTop: '2px' }}>
        <Grid item xs={3}>
          <CustomButton
            colour="primaryButton"
            style={{ marginTop: '1.2rem' }}
            onClick={PreviewClose}>
            Preview Survey
          </CustomButton>
        </Grid>
        <Grid item xs={9}>
          <p>
            Total Items Selected : <b>{allCheckedItems?.length}</b>
          </p>
          <SortableContainer
            onSortEnd={(e) => {
              handleSortMenuItem(e)
            }}
            useDragHandle
            distance={20}
            helperClass="SortableHelper"
            disableAutoscroll="false"

            // onSortStart={handleSortStart}
          >
            {console.log('sel', selectedThinkwiseData)}
            {selectedThinkwiseData &&
              stableSortone(
                selectedThinkwiseData.filter((item) => item),
                getSorting('asc', 'zSortID')
              ).map((item, index) => {
                console.log(item, 'itemitemitem')
                let valueCheck1 = item || {}
                console.log(valueCheck1, 'valiuiueueeeuueu')
                if (
                  NewthinkwiseSelecteditemReducer?.includes(item?.compentencyid)
                ) {
                  arrayLiat.push(valueCheck1)
                  // console.log(valueCheck1,"valueCheck1",selected);
                  return (
                    <AccordianItem
                      key={`item-${index}`}
                      index={index}
                      value={item}
                      classes={classes}
                      handleSortItem={(oldIndex, newIndex) =>
                        drag
                          ? handleSortItem(oldIndex, newIndex)
                          : handleSortMenuItem(oldIndex, newIndex)
                      }
                      sorter={sorter}
                      list={list}
                      setDrag={setDrag}
                      // handleSortItem={handleSortItem}
                    />
                  )
                } else if (
                  NewSelectedCustomitemReducer.includes(item?.capablityid)
                ) {
                  arrayLiat.push(valueCheck1)
                  return (
                    <AccordianCostomItem
                      key={`item-${index}`}
                      index={index}
                      value={item}
                      handleSortItem={(oldIndex, newIndex) =>
                        drag
                          ? handleSortItem(oldIndex, newIndex)
                          : handleSortMenuItem(oldIndex, newIndex)
                      }
                      classes={classes}
                      setDrag={setDrag}
                      list={list}
                      // handleSortItem={handleSortItem}
                    />
                  )
                }
              })}
          </SortableContainer>
        </Grid>

        <NewThinkwisepreview
          sordedGetData={sordedGetData}
          open={survey}
          handleReset={PreviewClose}
          title={'Preview Survey'}
          zScaleID={zScaleID}
        />
      </Grid>
    </form>
  )
}

export default NewthinkwisePreview

const AccordianItem = sortableElement(
  ({
    items,
    itemName,
    index,
    accordianId,
    subcategory,
    classes,
    value,
    // handleSortInnerAccordian,
    handleSortItem,
    setDrag,
    list,
  }) => {
    const { zCompetencyID, zName, zDescription } = value
    const NewselectedThinkwiseItems = useSelector(
      (v) => v.NewselectedThinkwiseItems
    )
    const changeSortOrder = (e) => {
      handleSortItem(e.oldIndex, e.newIndex)
    }
    console.log(value)
    return (
      <Accordion key={`acc_${index}`} className={classes.acc}>
        <AccordionSummary
          onClick={() => setDrag(true)}
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls={`item_${zCompetencyID}-content`}
          id={`item_${zCompetencyID}-header`}
          className={classes.accHead}>
          <DragHandler />
          <Typography className={classes.heading}>
            {value.compentencyname}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <Typography className={classes.accDesc}>{zDescription}</Typography>
          <CategoryItems
            categoryId={zCompetencyID}
            items={items}
            handleSortItem={(oldIndex, newIndex) => {
              handleSortItem(oldIndex, newIndex)
            }}
          />

          <SortableContainer
            onSortEnd={(e) => changeSortOrder(e)}
            useDragHandle
            distance={20}
            helperClass="SortableHelper"
            // onSortStart={handleSortStart}
            disableAutoscroll="false">
            {value.capablitylist.map((itemValue, index) => {
              let valueCheck =
                NewselectedThinkwiseItems[
                  THINKWISE_CATEGORY + itemValue.compentencyid
                ]?.[THINKWISE_SUB_CATEGORY + itemValue.capablityid] || []

              if (valueCheck) {
                return (
                  <AccordianInnerItem
                    key={`item-${index}`}
                    index={index}
                    compentencyid={value.compentencyid}
                    item={itemValue}
                    classes={classes}
                    selecteditem={value.selectedItemsList}
                    handleSortItem={(oldIndex, newIndex, innerAccordian) => {
                      handleSortItem(
                        oldIndex,
                        newIndex,
                        innerAccordian,
                        zCompetencyID
                      )
                    }}
                    list={list}
                  />
                )
              }
            })}
          </SortableContainer>
        </AccordionDetails>
      </Accordion>
      // </SortableContainer>
    )
  }
)

const AccordianInnerItem = sortableElement(
  ({
    item,
    classes,
    value,
    handleSortItem,
    selecteditem,
    compentencyid,
    sorter,
    list,
  }) => {
    const changeSortOrder = ({ oldIndex, newIndex }) => {
      handleSortItem(oldIndex, newIndex, item.zCapabilityID)
      console.log('cr', oldIndex)
    }
    console.log('iiiiitem', item)
    const NewselectedThinkwiseItems = useSelector(
      (s) => s.NewselectedThinkwiseItems
    )
    let valueCheck =
      NewselectedThinkwiseItems[THINKWISE_CATEGORY + compentencyid]?.[
        THINKWISE_SUB_CATEGORY + item.capablityid
      ] || []
    item.itemslist.map((value, index) => {
      return console.log('sorted', value)
    })
    let mm = []
    valueCheck.map((rec, i) => {
      // console.log('inside map', rec[i])
      mm[i] = item.itemslist.find((val) => val.itemid == valueCheck[i])
    })
    console.log('exactly have', valueCheck, NewselectedThinkwiseItems, mm)
    return (
      <>
        <Accordion
          key={`acc_inside_${value}`}
          style={{ margin: '0 -14px 0.5rem -15px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`item_inside_${value}-content`}
            id={`item_inside_${value}-header`}
            className={classes.accHeadInside}>
            {/* <DragHandler /> */}
            <Typography className={classes.heading}>
              {item.capablityname}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>
              {item.zDescription}
            </Typography>

            <SortableContainer
              onSortEnd={changeSortOrder}
              // useDragHandle
              helperClass="SortableHelper"
              disableAutoscroll="false"
              distance={20}>
              {mm &&
                mm.map((record, idx) => {
                  if (record?.itemname?.length > 0) {
                    list.push(record?.itemname)
                  }
                  return (
                    <CheckboxItem
                      key={`checkbox_item${idx}`}
                      index={record?.itemid}
                      itemId={record?.itemid}
                      value={record?.itemid}
                      label={record?.itemname}
                    />
                  )
                })}
            </SortableContainer>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
)

const CategoryItems = ({ categoryId, items, handleSortItem }) => {
  const CheckedItemList = useSelector((state) => state.CheckedItemList)
  const changeSortOrder = ({ oldIndex, newIndex }) => {
    handleSortItem(oldIndex, newIndex)
  }
  return (
    <SortableContainer
      onSortEnd={changeSortOrder}
      useDragHandle
      helperClass="SortableHelper"
      disableAutoscroll="false"
      distance={20}>
      {items &&
        items.map((value, index) => {
          if (
            CheckedItemList.find(
              (CheckedItem) =>
                CheckedItem.subCategoryId === 0 &&
                CheckedItem.categoryId === categoryId &&
                value &&
                value.zItemID === CheckedItem.itemId
            )
          ) {
            return (
              <CheckboxItem
                key={`checkbox_item${index}`}
                index={value.zItemID}
                itemId={value.zItemID}
                value={value.zItemID}
                label={value.zCaption}
              />
            )
          }
        })}
    </SortableContainer>
  )
}

const CheckboxItem = sortableElement(({ value, label, itemId }) => {
  console.log('having label')
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '60px' }}>
      <DragHandler />
      <Typography variant="p">{label}</Typography>
    </div>
  )
})

const DragHandler = sortableHandle(() => (
  <div>
    <span style={{ cursor: 'grab', marginRight: '10px' }}>
      <OpenWith />
    </span>
  </div>
))

const AccordianCostomItem = sortableElement(
  ({
    itemName,
    index,
    accordianId,
    subcategory,
    classes,
    value,
    // handleSortInnerAccordian,
    handleSortItem,
    setDrag,
  }) => {
    const {
      capablityid,
      capablityname,
      zDescription,
      itemList,
      compentencylist,
    } = value
    console.log(capablityname, 'compentencylist')
    console.log(value, 'compentencylistvaluevalue')
    const NewSelectedItemsIdReducer = useSelector(
      (val) => val.NewSelectedItemsIdReducer
    )
    const changeSortOrder = (e) => {
      handleSortItem(e.oldIndex, e.newIndex)
    }
    let selected = NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid] || []
    let s1 =
      NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid]?.[
        SUB_CUSTOM_ITEM_ + capablityid + '_'
      ]?.NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid]
    let sel1 = []
    console.log('sel638', NewSelectedItemsIdReducer, selected, s1)
    let mm = []
    // selected && selected.map((rec, i) => console.log(rec[i]))
    // console.log(
    //   'when sub',
    //   Object.values(selected).forEach((value) => {
    //     selected.find((sel) => sel.itemId == '2061')
    //   })
    // )
    Object.values(selected).forEach((value) => {
      Object.values(value).forEach((v) => {
        sel1.push(value)
      })
    })
    console.log('mm', mm)
    // if (!sel1) {
    Object?.values(selected)?.forEach((value, i) => {
      mm[i] = itemList?.find((val) => val?.itemId == selected[i])
    })
    // }
    console.log('sel1', sel1)
    return (
      <Accordion key={`acc_${index}`} className={classes.acc}>
        <AccordionSummary
          onClick={() => setDrag(true)}
          expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
          aria-controls={`item_${capablityid}-content`}
          id={`item_${capablityid}-header`}
          className={classes.accHead}>
          <DragHandler />
          <Typography className={classes.heading}>{capablityname}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <Typography className={classes.accDesc}>{zDescription}</Typography>
          <CategoryItems
            categoryId={capablityid}
            handleSortItem={(oldIndex, newIndex) => {
              handleSortItem(oldIndex, newIndex)
            }}
          />

          <SortableContainer
            onSortEnd={(e) => changeSortOrder(e)}
            useDragHandle
            distance={20}
            disableAutoscroll="false"
            helperClass="SortableHelper"
            // onSortStart={handleSortStart}
          >
            {mm &&
              mm.map((rec, i) => {
                if (rec) {
                  return (
                    <CheckboxItem
                      key={`checkbox_item${index}`}
                      index={rec.itemId}
                      itemId={rec.itemId}
                      value={rec.itemId}
                      label={rec.itemname}
                    />
                  )
                }
              })}
            {/* {!!itemList.length &&
              itemList.map((value, index) => {
                if (selected.includes(value.itemId)) {
                  return (
                    <CheckboxItem
                      key={`checkbox_item${index}`}
                      index={value.itemId}
                      itemId={value.itemId}
                      value={value.itemId}
                      label={value.itemname}
                    />
                  )
                }
              })} */}

            {!!compentencylist.length &&
              compentencylist.map((itemValue, index) => {
                let selectedItem =
                  NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid]?.[
                    SUB_CUSTOM_ITEM_ +
                      capablityid +
                      '_' +
                      itemValue.compentencyid
                  ] || []
                console.log(selectedItem, 'selectedItemselectedItem')
                if (selectedItem.length) {
                  return (
                    <CustomAccordianInnerItem
                      key={`item-${index}`}
                      index={itemValue.itemid}
                      capablityid={capablityid}
                      item={itemValue}
                      classes={classes}
                      // selecteditem={value.selectedItemsList}
                      handleSortItem={(oldIndex, newIndex) => {
                        handleSortItem(oldIndex, newIndex)
                      }}
                    />
                  )
                }
              })}
          </SortableContainer>
        </AccordionDetails>
      </Accordion>
    )
  }
)

const CustomAccordianInnerItem = sortableElement(
  ({ item, classes, value, handleSortItem, selecteditem, capablityid }) => {
    const changeSortOrder = ({ oldIndex, newIndex }) => {
      handleSortItem(oldIndex, newIndex)
    }
    const NewSelectedItemsIdReducer = useSelector(
      (sta) => sta.NewSelectedItemsIdReducer
    )
    const { Itemlist, compentencyid, compentencyname, zDescription } = item
    let selectedItem =
      NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid]?.[
        SUB_CUSTOM_ITEM_ + capablityid + '_' + compentencyid
      ] || []

    // Example array of objects
    const arrayOfObjects = [...Itemlist]

    // Array of values to sort by
    const sortOrder = [...selectedItem]

    // Sort the array based on the order in sortOrder array
    arrayOfObjects.sort((a, b) => {
      const aValueIndex = sortOrder.indexOf(a.itemId)
      const bValueIndex = sortOrder.indexOf(b.itemId)

      // Compare the indices to determine the sorting order
      return aValueIndex - bValueIndex
    })

    // Log the sorted array
    console.log(arrayOfObjects, 'arrayOfObjectsarrayOfObjectsarrayOfObjects')

    return (
      <>
        <Accordion
          key={`acc_inside_${value}`}
          style={{ margin: '0 -14px 0.5rem -15px' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`item_inside_${value}-content`}
            id={`item_inside_${value}-header`}
            className={classes.accHeadInside}>
            {/* <DragHandler /> */}
            <Typography className={classes.heading}>
              {compentencyname}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>{zDescription}</Typography>

            <SortableContainer
              onSortEnd={changeSortOrder}
              useDragHandle
              helperClass="SortableHelper"
              disableAutoscroll="false"
              distance={20}>
              {arrayOfObjects?.map((value, index) => {
                if (selectedItem.includes(value.itemId)) {
                  return (
                    <CheckboxItem
                      key={`checkbox_item${index}`}
                      index={value.itemId}
                      itemId={value.itemId}
                      value={value.itemId}
                      label={value.itemname}
                    />
                  )
                }
              })}
            </SortableContainer>
          </AccordionDetails>
        </Accordion>
      </>
    )
  }
)
