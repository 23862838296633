import { Typography } from '@material-ui/core'
import { Skeleton } from '@mui/material'
import React from 'react'
import how_to_rate from '../../../../../../../../assets/images/step2.png'

const HowToRate = ({ handleReset }) => {
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h6" gutterBottom>
        How do I rate someone?
      </Typography>
      <Typography variant="p">
        Select names from the participants list and select a rating from the
        survey for each item.
      </Typography>{' '}
      <br />
      <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
        Hints: You can also drag and drop the name into the rating fields. You
        can also click on the item to highlight the names in the participants
        list that have not been rated for Item.
      </span>
      {how_to_rate ? (
        <img
          src={how_to_rate}
          style={{ height: 'auto', width: '100%', paddingTop: '20px' }}
        />
      ) : (
        <Skeleton width="100%" height={400} />
      )}
    </div>
  )
}

export default HowToRate
