import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import CustomButton from '../common/CustomButton'
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToDraft from 'html-to-draftjs'
import LineWeightIcon from '@material-ui/icons/LineWeight'
import DeleteIcon from '@material-ui/icons/Delete'
import draftToHtml from 'draftjs-to-html'
import { authHeader, getUserId } from '../common/CustomFunction'
import { API } from '../../helpers/API'
import { CustomMessage } from '../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import {
  ADD_TEAM_TEAMMEMBERMAIL,
  GET_EMAIL_PROJECT_URL,
  GET_EMAIL_TEMPLATE_URL,
  GET_INVITATION_NOTE,
  GET_TEAM_INVITAIONMAIL,
  SAVE_EMAIL_PROJECT_URL,
  SAVE_EMAIL_TEMPLATE_URL,
  SAVE_INVITATION_NOTE,
  SEND_TEAM_TESTEMAIL,
  SENT_INVITATION_TEST,
  TEAM_ADD_SCHUDULE_REMAINDER,
  TEAM_ADD_UPDATESCHUDULE,
  TEAM_DELETE_TEAMMAIL,
  TEAM_GET_SCHUDULEREMAIDNER,
} from '../../helpers/constants/ApiRoutes'
import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
import { useParams } from 'react-router-dom'
import { stateToHTML } from 'draft-js-export-html'
import Swal from 'sweetalert2'
import { useDispatch } from 'react-redux'
import {
  TEAMSURVEY_CURRENTSTEP,
  TEAMSURVEY_TEMP_CURRENTSTEP,
} from '../../redux/action/ActionType'
import { useSelector } from 'react-redux'
const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontFamily: 'Nunito-SemiBold !important',
  },
  formControl: {
    width: '100%',
  },
  blackcolor: {
    color: 'black',
  },
  typoheading: {
    margin: '24px',
    background: '#F6F6F6',
  },
}))
const { hasCommandModifier } = KeyBindingUtil
let weekes = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]
let weekelySchedule = [
  { id: '0,2', value: 'Sunday and Tuesday' },
  { id: '3,5', value: 'Wednesday and Friday' },
  { id: '1,3', value: 'Monday and Wednesday' },
  { id: '3,5', value: 'Wednesday and Friday' },
]

function Tsinvitaion(props) {
  const classes = useStyles()
  const [templateInfo, setInfo] = useState([])
  const [templateList, setList] = useState([])
  const [weekelyRemainder, setweekelyRemainder] = useState(1)
  const [schuduleRemaimder, setschuduleRemaimder] = useState('DayOfWeek')
  const tempTAbchange = useSelector((state) => state.Teamtempcurrentstepreducer)

  const [errormail, setError] = useState('')
  const [keyword, setKeyWord] = useState('')
  const [lineSpacing, setlineSpacing] = useState(1)
  const { enqueueSnackbar } = useSnackbar()
  const [Remainderid, setRemainderid] = useState()
  const { tsprojectid } = useParams()
  const dispatch = useDispatch()
  const [teammemberEmail, setteammemberEmail] = useState('')
  const [teamMemberError, setteamMemberError] = useState('')
  const [textAreaValue, setTextArea] = useState()
  const currentStep = useSelector((state) => state.Teamsurveycurrentstepreducer)

  const [teammemberEmaillist, setteammemberEmaillist] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const initialValues = {
    template: templateInfo?.zOriginalEmailTemplateID,
    templateName: '',
    fromname: 'Cantoo',
    fromEmail: 'support@cantoo.us',
    subject: templateInfo?.zSubject,
    testMail: '',
  }
  const handleSaveProjectEmail = async (formData) => {
    const { template, templateName, subject } = formData
    console.log(template, 'template')
    if (!teammemberEmaillist.length) {
      CustomMessage('Please add Teammember', 'error', enqueueSnackbar)

      return null
    }
    // if (!template) {
    //   CustomMessage('Please choose templete', 'error', enqueueSnackbar)
    //   console.log(template, 'template1')

    //   return null
    // }
    console.log(template, !template, 'template2')

    await checkFunction()
    await invitationNoteFun()
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')
    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    const options = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_EMAIL_PROJECT_URL,
      data: {
        emailProjectID: templateInfo ? templateInfo.zEmailProjectID : 0,
        // emailProjectID:  0,
        subject: subject,
        text,
        originalEmailTemplateID: template,
        userSendEmail: 0,
        projectID: parseInt(arr[3]),
        oldEmailTemplateID: templateInfo
          ? templateInfo.zOriginalEmailTemplateID
          : 0,
        // oldEmailTemplateID:0,
        projectTypeID: 7,
        name: '',
        emailTypeID: 1,
        userID: getUserId(),
      },
    }
    API(options).then((response) => {
      if (response.status === 200) {
        const { message } = response.data
        CustomMessage(message, 'success', enqueueSnackbar)
        console.log(currentStep, tempTAbchange)
        if (currentStep == tempTAbchange) {
          dispatch({
            type: TEAMSURVEY_CURRENTSTEP,
            payload: tempTAbchange + 1,
          })
          dispatch({
            type: TEAMSURVEY_TEMP_CURRENTSTEP,
            payload: tempTAbchange + 1,
          })
        } else {
          dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: tempTAbchange })
        }
      }
    })
  }

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (values) => handleSaveProjectEmail(values),
  })
  const {
    template = '',
    templateName = '',
    fromname = '',
    fromEmail = '',
    subject = '',
    testMail = '',
  } = values

  const insertCharacter = (characterToInsert, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection()

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert
    )

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )

    setEditorState(newEditorState)
  }

  const onEditorStateChange = (e) => {
    setEditorState(e)
  }

  const handleKeyWord = (e) => {
    insertCharacter(e.target.value, editorState)
    setKeyWord(e.target.value)
  }
  function handleKeyCommand(command) {
    if (command === 'split-block') {
      inSterSpach(editorState)

      // Perform a request to save your contents, set
      // a new `editorState`, etc.
      // onChange(EditorState.push(editorState, contentState, 'insert-fragment'));

      return 'handled'
    }
    return 'not-handled'
  }

  const inSterSpach = (newState) => {
    const currentContent = newState.getCurrentContent(),
      currentSelection = newState.getSelection()
    const data = '<br/><br/><br/>'
    const ValueData = {
      1: '<br/>',
      2: '<br/><br/>',
      3: '<br/><br/><br/>',
      4: '<br/><br/><br/><br/>',
    }
    // return null
    let { contentBlocks, entityMap } = htmlToDraft(ValueData[lineSpacing])
    const newContent = Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    )

    const newEditorState = EditorState.push(
      newState,
      newContent,
      'insert-fragment'
    )

    setEditorState(newEditorState)
  }
  function myKeyBindingFn(e) {
    if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) {
      return 'myeditor-save'
    }
    return getDefaultKeyBinding(e)
  }

  const CustomDropDown = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
      // inSterSpach(editorState)

      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const getValue = (e) => {
      const { value } = e.currentTarget.dataset
      setlineSpacing(value)
      handleClose()
    }

    return (
      <>
        <Button
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}>
          {lineSpacing} <LineWeightIcon />
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}>
          <MenuItem data-value="1" onClick={getValue}>
            1
          </MenuItem>
          <MenuItem data-value="2" onClick={getValue}>
            2
          </MenuItem>
          <MenuItem data-value="3" onClick={getValue}>
            3
          </MenuItem>
          <MenuItem data-value="4" onClick={getValue}>
            4
          </MenuItem>
        </Menu>
      </>
    )
  }

  const getEmailProject = () => {
    const searchPath = window.location.pathname
    const arr = searchPath.split('/')
    const options = {
      method: 'GET',
      headers: authHeader(),

      url: GET_EMAIL_PROJECT_URL,
      params: {
        ProjectID: parseInt(tsprojectid),
      },
    }
    API(options).then((response) => {
      if (response.status === 200) {
        setInfo(response.data.data[0])

        if (response.data.data[0].zText) {
          const blocksFromHTML = convertFromHTML(response.data.data[0].zText)
          if (blocksFromHTML.contentBlocks !== null) {
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            )
            setEditorState(EditorState.createWithContent(state))
          }
        }
      }
    })
  }
  const getTemplateList = () => {
    const options = {
      method: 'GET',
      headers: authHeader(),

      url: GET_EMAIL_TEMPLATE_URL,
      params: {
        ProjectTypeID: 7,
        UserID: getUserId(),
      },
    }
    API(options).then((response) => {
      if (response.status === 200) {
        setList(response.data.data)
        // props.parentCallback(response.data.data)
      }
    })
  }
  useEffect(() => {
    getTemplateList()
    getEmailProject()
  }, [])
  const handleSaveTemplate = (formData) => {
    const { template, templateName, subject } = formData
    console.log(template, templateName, subject)
    const text = editorState ? stateToHTML(editorState.getCurrentContent()) : ''
    const options = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_EMAIL_TEMPLATE_URL,
      data: {
        subject,
        text,
        oldEmailTemplateID: 0,
        projectTypeID: 7,
        name: templateName,
        emailTypeID: 1,
        userID: getUserId(),
        emailProjectID: templateInfo ? templateInfo.zEmailProjectID : 0,
      },
    }
    templateName &&
      API(options).then((response) => {
        if (response.status === 200) {
          const { message = '' } = response.data
          getTemplateList()
          CustomMessage(message, 'success', enqueueSnackbar)
        }
      })
  }

  useEffect(() => {
    if (values.template) {
      const content = templateList
        ? templateList.find(
            (template) => template.zEmailTemplateID === values.template
          )
        : ''
      if (content && content.zText) {
        const blocksFromHTML = convertFromHTML(content.zText)
        if (blocksFromHTML.contentBlocks !== null) {
          const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          )
          setEditorState(EditorState.createWithContent(state))
        }
      }
    }
  }, [values.template])
  const handleSendtest = () => {
    var testMaild = values['testMail']
    var fsubject = values['subject']
    var ftemplate = values['template']
    if (ftemplate == '') {
      errors.template = 'Select a template'
    } else if (testMaild == '') {
      errors.template = ''
      setError('Test mail required')
    } else if (fsubject == '') {
      errors.template = ''
      setError('')
      errors.subject = 'subject is required'
    } else {
      setError('')
      errors.subject = ''
      handelTestEMail(testMaild, fsubject, ftemplate)
    }
  }
  const handelTestEMail = async (testMaild, fsubject, ftemplate) => {
    if (template == '') {
      errors.template = 'Select a template'
    } else {
      const text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      const searchPath = window.location.pathname
      const arr = searchPath.split('/')
      const config = {
        method: 'POST',
        headers: authHeader(),

        url: SEND_TEAM_TESTEMAIL,
        data: {
          subject: fsubject,
          text: text,
          originalEmailTemplateID: parseInt(ftemplate),
          userSendEmail: true,
          projectID: tsprojectid,
          testEmail: testMaild,
          surveyLinkURL: '' + window.location.origin + '/signin/',
          sendTest: true,
        },
      }
      try {
        const response = await API(config)
        if (response.status === 200) {
          Swal.fire({
            title:
              "<h5 style='color:#fff'>Your test Invitation email was sent successfully</h5>",
            background: '#00cc00',
          }).then((value) => {})
          //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
          //getParticipantList()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const addteammembeHandle = (e) => {
    setteammemberEmail(e.target.value)
    setteamMemberError('')
  }
  const teammemberKeypress = async (e) => {
    if (!EMAIL_REGEX.test(teammemberEmail)) {
      setteamMemberError('Invalid email')
      return null
    }

    // if (e.charCode == 13) {
    if (teammemberEmail) {
      //ADD_TEAM_TEAMMEMBERMAIL
      // setteammemberEmaillist((val) => [...val, teammemberEmail])
      const config = {
        method: 'POST',
        headers: authHeader(),

        url: ADD_TEAM_TEAMMEMBERMAIL,
        params: {
          ProjectID: tsprojectid,
          EmailID: teammemberEmail,
          IsSent: true,
          UserID: getUserId(),
        },
      }
      try {
        const response = await API(config)
        if (response.status === 200) {
          setteammemberEmail('')
          getInvitaionMail()
          //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
          //getParticipantList()
        }
      } catch (error) {
        console.log(error)
      }
    }
    // }
  }

  const getInvitaionMail = async () => {
    //
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_INVITAIONMAIL,
      params: {
        ProjectID: tsprojectid,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        setteammemberEmaillist(response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  //TEAM_GET_SCHUDULEREMAIDNER

  const getSchuduleremainder = async () => {
    //
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_GET_SCHUDULEREMAIDNER,
      params: {
        ProjectID: tsprojectid,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        const { zReminderType, zDayOfWeek, zReminderID } =
          response?.data?.data[0] || {}
        setRemainderid(zReminderID || 0)
        setschuduleRemaimder(zReminderType)
        setweekelyRemainder(zDayOfWeek)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const schuduleHandle = (e) => {
    setschuduleRemaimder(e.target.value)
  }

  const remainderDayhand = (e) => {
    setweekelyRemainder(e.target.value)
  }

  const checkFunction = async () => {
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_ADD_UPDATESCHUDULE,
      params: {
        ProjectID: tsprojectid,
        userID: getUserId(),
        ReminderType: 'DayOfWeek',
        ReminderID: Remainderid,
        Dayofweek: '1',
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        getSchuduleremainder()
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getInvitaionNote = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: GET_INVITATION_NOTE,
      params: {
        ProjectID: tsprojectid,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        console.log(response.data.data[0].zNotes)
        setTextArea(response.data.data[0].zNotes)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const invitationNoteFun = async () => {
    const config = {
      method: 'POST',
      headers: authHeader(),
      url: SAVE_INVITATION_NOTE,
      data: {
        projectID: tsprojectid,
        userID: getUserId(),
        notes: textAreaValue,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        getInvitaionNote()
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getInvitaionNote()
  }, [])
  const deleteTeammail = async (e) => {
    //

    const config = {
      method: 'DELETE',
      headers: authHeader(),

      url: TEAM_DELETE_TEAMMAIL,
      params: {
        ProjectID: tsprojectid,
        Email: e.currentTarget.dataset.email,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        getInvitaionMail()
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    getInvitaionMail()
    getSchuduleremainder()
    checkFunction()
  }, [])
  const editChange = (e) => {
    setTextArea(e.target.value)
  }
  return (
    <div>
      <Paper className={classes.paper}>
        {/* Table tools */}
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div">
            Team Members
          </Typography>
        </Toolbar>
        <Divider />
        <form noValidate autoComplete="off" id="form_3" onSubmit={handleSubmit}>
          <Grid container spacing={2} direction="row">
            {/* <Grid xs={9}>
            <div style={{ marginTop: '1rem' }}>
              <form
                noValidate
                autoComplete="off"
                name="form_3"
                id={'form_3'}
                onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={3}
                  style={{ width: '100%', margin: 0, padding: '0px 30px' }}>
                  <Grid item xs={5}>
                    <FormControl
                      className={classes.formControl}
                      error={errors.template && touched.template}>
                      <InputLabel id="template">
                        Select An Email Template:
                      </InputLabel>
                      <Select
                        labelId="template"
                        id="template"
                        name="template"
                        value={template}
                        onChange={handleChange}
                        fullWidth
                        label="Executive">
                        {templateList.map((temp) => (
                          <MenuItem value={temp.zEmailTemplateID}>
                            {temp.zName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        <p style={{ color: 'red' }}>
                          {errors.template ? errors.template : ''}
                        </p>
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="templateName"
                        label="Save As Email Template:"
                        value={templateName}
                        onChange={handleChange}
                        fullWidth
                        name="templateName"
                        error={errors.templateName && touched.templateName}
                        helperText={
                          errors.templateName && touched.templateName
                            ? errors.templateName
                            : ''
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <CustomButton
                      colour="primaryButton"
                      style={{ float: 'right' }}
                      onClick={handleSaveTemplate}
                      // type="submit"
                    >
                      Save
                    </CustomButton>
                  </Grid>

                  <Grid item xs={5}>
                    <FormControl
                      className={classes.formControl}
                      error={errors.fromname && touched.fromname}>
                      <TextField
                        labelId="fromname"
                        id="fromname"
                        name="fromname"
                        value={fromname}
                        onChange={handleChange}
                        fullWidth
                        label="From Name:"
                        disabled
                        error={errors.fromname && touched.fromname}
                        helperText={
                          errors.fromname && touched.fromname
                            ? errors.fromname
                            : ''
                        }
                      />
                      <FormHelperText>
                        {errors.fromname && touched.fromname
                          ? errors.fromname
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      id="fromMail"
                      label="From Email:"
                      value={fromEmail}
                      onChange={handleChange}
                      fullWidth
                      name="fromEmail"
                      disabled
                      error={errors.fromEmail && touched.fromEmail}
                      helperText={
                        errors.fromEmail && touched.fromEmail
                          ? errors.fromEmail
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>

                  <Grid item xs={5}>
                    <FormControl
                      className={classes.formControl}
                      error={errors.subject && touched.subject}>
                      <TextField
                        labelId="subject"
                        id="subject"
                        name="subject"
                        value={subject}
                        onChange={handleChange}
                        fullWidth
                        label="Subject:"
                        error={errors.subject}
                        helperText={errors.subject}
                      />
                      <FormHelperText>
                        {errors.subject && touched.subject
                          ? errors.subject
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      id="testMail"
                      label="Send Test Email:"
                      value={testMail}
                      onChange={handleChange}
                      fullWidth
                      name="testMail"
                      error={errormail}
                      helperText={errormail}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <CustomButton
                      colour="primaryButton"
                      style={{ float: 'right' }}
                      //type="submit"
                      onClick={() => handleSendtest()}>
                      Send Test
                    </CustomButton>
                  </Grid>
                </Grid>
              </form>
            </div>

            <Grid
              container
              spacing={3}
              direction="row"
              style={{
                marginTop: '2rem',
                marginRight: '2rem',
                padding: '0px 30px',
              }}>
              <Grid item xs={10}>
                <Accordion>
                  <AccordionSummary focusVisibleClassName="invitationFocus">
                    <Grid item xs={12}>
                      <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        toolbarCustomButtons={[
                          <CustomDropDown setlineSpacing={setlineSpacing} />,
                        ]}
                        isSoftReturnDefault
                        onEditorStateChange={onEditorStateChange}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={myKeyBindingFn}
                      />
                      <Typography>
                        <p></p>
                      </Typography>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={2}>
                <InputLabel id="keyword2">Insert Keyword:</InputLabel>
                <Select
                  labelId="keyword2"
                  id="keyword"
                  name="keyword"
                  value={keyword}
                  onChange={(e) => {
                    handleKeyWord(e)
                  }}
                  label="Select Keyword"
                  fullWidth>
                  <MenuItem value="$SurveyLink$">Survey Link</MenuItem>
                  <MenuItem value="$Email$">
                    Respondent's Email Address
                  </MenuItem>
                  <MenuItem value="$DueDate$">Due Date</MenuItem>
                  <MenuItem value="$Participant$">Participant's Name</MenuItem>
                  <MenuItem value="$Participant+Relationship$">
                    Participant's Name/Relation
                  </MenuItem>
                  <MenuItem value="$AdminName$ ">
                    Project Administrator's Name
                  </MenuItem>
                  <MenuItem value="$ProjectName$ ">Project Name</MenuItem>$$
                  <MenuItem value="$FirstName$ ">
                    Respondent's First Name
                  </MenuItem>
                  <MenuItem value="$LastName$ ">
                    Respondent's Last Name
                  </MenuItem>
                  <MenuItem value="$SupportLink$ ">
                    ThinkSupport Email link
                  </MenuItem>
                </Select>
                <p>
                  <b>Keywords</b>
                  <br />
                  <br />
                  <b>
                    <small>Required Keywords:</small>
                  </b>
                  <br />

                  <small>$SurveyLink$</small>
                  <br />

                  <small>$Email$</small>
                  <br />
                  <br />
                  <b>
                    <small>Optional Keywords:</small>
                  </b>
                  <br />

                  <small>$ProjectName$</small>
                  <br />

                  <small>$DueDate$</small>
                </p>
              </Grid>
            </Grid>
          </Grid> */}
            <Grid spacing={1} xs={5}>
              <Grid>
                <Typography className={classes.typoheading} variant="h5">
                  Add Team Members
                </Typography>
                <TextField
                  style={{ left: '31px' }}
                  id="standard-disabled"
                  value={teammemberEmail}
                  error={teamMemberError}
                  label="Team members"
                  helperText={teamMemberError}
                  // onKeyPress={teammemberKeypress}
                  onChange={addteammembeHandle}
                  type="email"
                />{' '}
                <CustomButton
                  // type="submit"
                  type="button"
                  style={{ float: 'right', marginRight: '21px' }}
                  colour="primaryButton"
                  id="teamsurveyFormred"
                  onClick={teammemberKeypress}>
                  Add
                </CustomButton>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start">
                  {teammemberEmaillist.map((ema, index) => {
                    return (
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ padding: '4px 0' }}>
                        <Grid style={{ paddingLeft: '30px' }}>
                          {index + 1}. {ema.zEmail}
                        </Grid>
                        <Grid style={{ padding: '0 40px' }}>
                          <DeleteIcon
                            onClick={deleteTeammail}
                            data-email={ema.zEmail}
                          />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              {/* <Grid>
              <Typography className={classes.typoheading} variant="h5">
                Schedule Reminders:
              </Typography>
              <Grid container direction="column">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={schuduleRemaimder}
                  onChange={schuduleHandle}>
                  <Grid
                    container
                    direction="column"
                    style={{ padding: '7px' }}
                    spacing={2}>
                    <FormControlLabel
                      value="DayOfWeek"
                      control={<Radio />}
                      label="Weekly"
                    />
                    <FormControl>
                      <InputLabel htmlFor="age-native-simple">
                        Select Day Of Week:
                      </InputLabel>
                      <Select
                        native
                        value={weekelyRemainder}
                        onChange={remainderDayhand}
                        inputProps={{
                          name: 'age',
                          id: 'age-native-simple',
                        }}>
                        {weekes.map((va, index) => {
                          return <option value={index}>{va}</option>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    style={{ padding: '7px' }}
                    container
                    direction="column"
                    spacing={2}>
                    <FormControlLabel
                      value="WeeklyTwice"
                      control={<Radio />}
                      label="Twice Weekly"
                    />
                    <FormControl>
                      <InputLabel htmlFor="age-native-simple">
                        Select Days Of Week:
                      </InputLabel>
                      <Select
                        native
                        // value={state.age}
                        value={weekelyRemainder}
                        onChange={remainderDayhand}
                        inputProps={{
                          name: 'age',
                          id: 'age-native-simple',
                        }}>
                        {weekelySchedule.map((va) => {
                          return <option value={va.id}>{va.value}</option>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </RadioGroup>
              </Grid>
            </Grid> */}
            </Grid>
            <Grid spacing={1} xs={5}>
              <Grid>
                <Typography className={classes.typoheading} variant="h5">
                  Invitation Note:
                </Typography>
              </Grid>
              <Grid>
                <textarea
                  // disabled
                  style={{
                    width: '500px',
                    height: '250px',
                    border: 'none',
                    outline: 'none',
                    paddingLeft: '30px',
                  }}
                  placeholder="Type Invitation Note here"
                  onChange={editChange}
                  value={textAreaValue}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  )
}

export default Tsinvitaion
