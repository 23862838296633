import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react'
import CustomButton from '../../../../../../../common/CustomButton';
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { Box } from '@mui/system';
import HowToRate from './HowToRate';
import WhoToRate from './WhoToRate';
import SubmitSurvey from './SubmitSurvey';


const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  nextBtn: {
    backgroundColor: theme.successColor,
    minWidth: "100px",
    '&:hover': {
      backgroundColor: theme.successColor,
    },
  }
}));

const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs);

const SurveyInstruction = (props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleNext = () => {
    if (activeTab < 2) {
      setActiveTab(activeTab + 1);
    }
    else {
      handleReset()
    }

  }

  const handlePrevious = () => {
    setActiveTab(activeTab - 1);
  }

  const { open = "", handleReset } = props;
  return (
    <Dialog
      onClose={handleReset}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
      maxWidth="md"
      fullWidth
    >
      {/* <div> */}
      <DialogTitle id="simple-dialog-title" onClose={handleReset}>
        Survey Instructions
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleReset}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CusTab value={activeTab} onChange={handleTabChange} aria-label="simple tabs example"
            variant="scrollable" scrollButtons="auto">
            <Tab label="WHO TO RATE" {...a11yProps(0)} className={classes.tab} />
            <Tab label="HOW TO RATE" {...a11yProps(0)} className={classes.tab} />
            <Tab label="SUBMIT THE SURVEY" {...a11yProps(0)} className={classes.tab} />
          </CusTab>
          <div>
            {activeTab > 0 && <CustomButton colour="primaryButton"
              onClick={handlePrevious}
            >
              Previous
            </CustomButton>
            }
            <CustomButton className={classes.nextBtn} color="#58A83A"
              onClick={handleNext}
            >
              {activeTab < 2 ? "Next" : "Finished"}
            </CustomButton>

          </div>
        </div>
        <TabPanel value={activeTab} index={0}>
          <WhoToRate handleReset={handleReset} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <HowToRate handleReset={handleReset} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <SubmitSurvey />
        </TabPanel>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}

export default SurveyInstruction;
