import { Box } from '@material-ui/core'
import { lang } from 'moment'
import React, { useState } from 'react'
import { useEffect } from 'react'
export default function TranslateCompRight() {
  const [transformedData, setTransformedData] = useState([])

  useEffect(() => {
    const initialData = [
      { name: 'Organization Average', data: 3.69, colorvalue: '#4CAF50' },
      { name: 'Organization Average', data: 3.69, colorvalue: '#4CAF50' },
      { name: 'Project Average', data: 3.26, colorvalue: '#64b5f6' },
      { name: 'Organization Average', data: 3.69, colorvalue: '#4CAF50' },
      { name: 'Organization Average', data: 3.69, colorvalue: '#4CAF50' },
      { name: 'Project Average', data: 3.26, colorvalue: '#64b5f6' },
      { name: 'Self', data: 4, colorvalue: '#0062FF' },
    ]

    const transformedData = initialData.map((item, index) => ({
      [index]: item,
    }))

    setTransformedData(transformedData)
  }, [])
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: true,
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
      },
      'google_translate_element2'
    )
  }
  // useEffect(() => {
  //   var addScript = document.createElement('script')
  //   addScript.setAttribute(
  //     'src',
  //     '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
  //   )
  //   document.body.appendChild(addScript)
  //   window.googleTranslateElementInit = googleTranslateElementInit
  // }, [])

  useEffect(() => {
    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          autoDisplay: true,
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        'google_translate_element2'
      )
    }

    const addScript = document.createElement('script')
    addScript.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    document.body.appendChild(addScript)

    window.googleTranslateElementInit = googleTranslateElementInit

    const parentContainer = document.getElementById('google_translate_element2')
      .parentNode

    const observer = new MutationObserver(() => {
      const languageSelect = parentContainer.querySelector('.goog-te-combo')
      console.log('get combo', languageSelect)
      if (languageSelect) {
        const newSelectedLanguage = languageSelect.value
        if (newSelectedLanguage !== selectedLanguage) {
          setSelectedLanguage(newSelectedLanguage)
          console.log('Selected Language:', newSelectedLanguage)
        }
        // Disconnect the observer once the languageSelect is found
        observer.disconnect()
      }
    })

    // Start observing the parent container for changes
    observer.observe(parentContainer, { childList: true, subtree: true })

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleCall = (e) => {
    console.log('logggg', e.target.value, e)
  }
  return (
    <>
      <div id="google_translate_element2" style={{ marginTop: '6px' }}></div>
      {/* <pre>{JSON.stringify(transformedData, null, 2)}</pre> */}
    </>
  )
}
