import React from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Checkbox, IconButton } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

export default function CustomTableHeader({
  tableColumns,
  orderBy,
  order,
  columnSortCallBack,
  onRequestSort,
  checkallCallback,
  checkall,
  intermediate,
  tableColumns1,
}) {
  const classes = useStyles()
  //Styled head
  const StyledTableCell = withStyles((theme) => ({
    head: {
      // color: theme.primaryColor,
      backgroundColor: theme.palette.common.white,
      padding: 10,
      fontWeight: 'bold',
      fontFamily: 'Nunito-SemiBold !important',
      textAlign: 'left',
      letterSpacing: '0.07px !important',
      color: '#000000DE',
      opacity: 1,
      fontSize: '14px',
    },
  }))(TableCell)
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>

    { tableColumns1 ? tableColumns1?.map((column, key) => (
          <StyledTableCell
            key={`column_${key}`}
            align={column.align || 'left'}
            sortDirection={orderBy === column.id ? order : false}
            width={column.width}>
            {column.html ? (
              <>
                {' '}
                <TableCell>
                  <IconButton aria-label="expand row" size="small">
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </TableCell>
              </>
            ) : (
              // <Checkbox
              //   indeterminate={intermediate}
              //   checked={checkall}
              //   onChange={checkallCallback}
              //   inputProps={{
              //     'aria-label': 'select all desserts',
              //   }}
              // />
              <TableSortLabel
                disabled={!column.sort}
                style={{ fontFamily: 'Nunito-SemiBold !important' }}
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                data-column_id={column.id}
                onClick={createSortHandler(column.id)}>
                {column.title}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'descending' : 'ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCell>
        )) :  tableColumns?.map((column, key) => (
          <StyledTableCell
            key={`column_${key}`}
            align={column.align || 'left'}
            sortDirection={orderBy === column.id ? order : false}
            width={column.width}>
            {column.html ? (
              <>
                {' '}
                <TableCell>
                  <IconButton aria-label="expand row" size="small">
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </TableCell>
              </>
            ) : (
              // <Checkbox
              //   indeterminate={intermediate}
              //   checked={checkall}
              //   onChange={checkallCallback}
              //   inputProps={{
              //     'aria-label': 'select all desserts',
              //   }}
              // />
              <TableSortLabel
                disabled={!column.sort}
                style={{ fontFamily: 'Nunito-SemiBold !important' }}
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                data-column_id={column.id}
                onClick={createSortHandler(column.id)}>
                {column.title}
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'descending' : 'ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
