import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from '../../helpers/API'
import {
  GET_ORGANIZATION_LOGO_URL,
  PROJECT_INDIVIDUAL_REPORT,
  TEAM_REPORT,
} from '../../helpers/constants/ApiRoutes'
import { authHeader, getOrganizationId } from '../common/CustomFunction'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import { Divider, Grid, Paper } from '@material-ui/core'
import Individual from '../../components/thinkwise_360/projects/steps/item_selection/ProjectView/individualreport.json'
import Image from '../../helpers/favicon.jpg'
import clogo from '../../assets/images/company_logo.jpg'
import { useSelector } from 'react-redux'
import { jsPDF } from 'jspdf'
import { useRef } from 'react'
import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import $ from 'jquery'
import ReactToPrint from 'react-to-print'
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
  ChartSeriesLabels,
  ChartValueAxisItem,
  ChartValueAxis,
  ChartArea,
} from '@progress/kendo-react-charts'
import 'hammerjs'
import circle from '../../assets/images/circle.png'
let othersColor = '#64B5F6'

let colorCodes = {
  'Average (excl. Self)': '#0062FF',
  Self: '#4CAF50',
}
function componentToHex(c) {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}
const float = (val) => parseFloat(val).toFixed(2)

function rgbToHex(value) {
  let splitcode = value?.split(',')

  return (
    componentToHex(parseInt(splitcode[0])) +
    componentToHex(parseInt(splitcode[1])) +
    componentToHex(parseInt(splitcode[2]))
  )
}
const styles = {
  page: {
    marginLeft: '5rem',
    marginRight: '5rem',
    marginBottom: '15rem',
    marginTop: '15rem',
    'page-break-after': 'always',
  },

  columnLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '3rem 0 5rem 0',
    gap: '2rem',
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  spacer2: {
    height: '2rem',
  },

  fullWidth: {
    width: '100%',
  },

  marginb0: {
    marginBottom: 0,
  },
}
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const CusTab = withStyles((theme) => ({
  indicator: {
    fontFamily: 'Roboto-Medium !important',

    backgroundColor: theme.btnPrimary,
  },
  // '& .Mui-selected': {
  //   backgroundColor: 'red !important',
  //   color: 'blue !important',
  // },
}))(Tabs)

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  bold: {
    fontWeight: 600,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height: '80px',
    background: '#F6F6F6',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  projecthearing: {
    fontFamily: 'Roboto-Bold !important',
    fontSize: '16px',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  tab: { background: '#E0E0E0' },
}))
export default function IndividualReportPdf(props) {
  const classes = useStyles()
  const {
    // BarGraph,
    data,
    orgLogo,
  } = props
  //     const {orgLogo,
  //     projectName,
  //     strPName,
  //     dueDateFormat,
  // }=props
  const [reportData, setreportData] = useState('')
  const [flag, setFlag] = useState(false)
  const [activeLeadershiptab, setactiveLeadershiptab] = useState(
    'HIDDEN STRENGTHS'
  )
  const [value, setValue] = React.useState(0)
  const [orgLogoLoading, setOrgLogoLoading] = useState(false)
  const [orgLogoo, setOrgLogo] = useState('')

  const orgLogoSelector = useSelector((state) => state.AccountsUsersReducer)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const {
    strReportTitle,
    strPName,
    strReportDesc,
    organizationName,
    projectName,
    ownerName,
    endDate,
    dueDateFormat,
    addSummaryCompetencyInstructions = [],
    addSummaryResults = [],
    addSummaryRespondentInstructions = [],
    addMatrix = [],
    addCompetencyResults = [],
    addTop10Instructions = [],
    addTop10 = [],
    addDataItems = [],
    addOpenEndedInstructions = [],
    addOpenEnded = [],
    addDetailedInstructions = [],
  } = reportData[0] || {}
  const [firstSummeryResult] = addSummaryResults
  const [firstaddCompetencyResults] = addCompetencyResults
  const [firstSummeryCompetencyInstruction] = addSummaryCompetencyInstructions

  const {
    title: firstaddCompetencyResultstitle,
    description: firstaddCompetencyResultsdescription,
    addCompetencyResultsData = [],
  } = firstaddCompetencyResults || {}
  const {
    title: summerySampletitle,
    description: summerySampledescription,
    addSummaryCompetencyInstructionsData = [],
  } = firstSummeryCompetencyInstruction || {}
  const {
    title: summeryresulttitle,
    description: summeryresultdescription,
    addSummaryResultsData = [],
  } = firstSummeryResult || {}
  const [ScaleDetails] = addSummaryCompetencyInstructionsData
  const {
    strAsciScaleIntro,
    strAsciListBp1,
    strAsciListBp2,
    strAsciListBp3,
    data: scaleData = [],
  } = ScaleDetails || {}
  const [firstaddTop10] = addTop10
  const {
    strAtTopName,
    strAtTopDesc,
    strAtBotName,
    strAtBotDesc,
    strAtGapName,
    addSummaryTopTable = [],
    addSummaryTopTableBottom = [],
    addSummaryTopTableGap = [],
    addSummaryTbResources = [],
    addSummaryTbResourcesBottom = [],
    strAtGapDesc2,
  } = firstaddTop10 || {}
  let sclaesize = scaleData.length
  let cno = 0
  let hid = 0
  console.log('res', addSummaryTbResourcesBottom, addSummaryTbResources)
  const [firstaddOpenEnded] = addOpenEnded
  const {
    title: firstaddOpenEndedtitle,
    description: firstaddOpenEndeddescription,
    addOpenEndedData = [],
  } = firstaddOpenEnded || {}
  const [firstaddDataItems] = addDataItems
  const {
    title: firstaddDataItemstitle,
    description: firstaddDataItemsdescription,
    dataItem = [],
    dataItemsCustom = [],
  } = firstaddDataItems || {}

  let splitdetailedResult = {}
  let splitdetailedResultcus = {}
  console.log('data')
  dataItem.forEach((data) => {
    let innerData = {}
    splitdetailedResult[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResult[data.strCompNm + data.strCapNm]?.datavalues ||
          []),
        ...data.dataItemsData,
      ],
    }
  })
  console.log('item custom', dataItemsCustom)
  dataItemsCustom.forEach((data) => {
    let innerData = {}
    splitdetailedResultcus[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResultcus[data.strCompNm + data.strCapNm]
          ?.datavalues || []),
        ...data.dataItemsCustomData,
      ],
    }
  })
  console.log('cus', splitdetailedResultcus, dataItemsCustom)
  let allDataValues = []
  let allDataValuescus = []
  let splitmin = 0
  let splitmax = 0

  Object.values(splitdetailedResult).forEach((vsp) => {
    let inderClone = {}

    vsp.datavalues.forEach((val, index) => {
      if (index == 0) {
        splitmin = val.min
        splitmax = val.max
      }

      if (splitmin >= val.min) {
        splitmin = val.min
      }
      if (splitmax <= val.max) {
        splitmax = val.max
      }
      inderClone[val.zCaption] = [
        ...(inderClone[val.zCaption] || []),
        { ...val, color: colorCodes[val.itemNorm] || othersColor },
      ]
    })
    allDataValues.push({ ...vsp, inderClone })
  })
  Object.values(splitdetailedResultcus).forEach((vsp) => {
    let inderClone = {}

    vsp.datavalues.forEach((val, index) => {
      if (index == 0) {
        splitmin = val.min
        splitmax = val.max
      }

      if (splitmin >= val.min) {
        splitmin = val.min
      }
      if (splitmax <= val.max) {
        splitmax = val.max
      }
      inderClone[val.newText] = [
        ...(inderClone[val.newText] || []),
        { ...val, color: colorCodes[val.itemNorm] || othersColor },
      ]
    })
    console.log('inder clone', inderClone)
    allDataValuescus.push({ ...vsp, inderClone })
  })
  console.log('All data', allDataValues, allDataValuescus)
  let splitAllsummery = {}
  let summarymin = 0
  let summarymax = 0
  addSummaryResultsData &&
    addSummaryResultsData.forEach((summ, index) => {
      if (index == 0) {
        summarymin = summ.min
        summarymax = summ.max
      }

      if (summarymin >= summ.min) {
        summarymin = summ.min
      }
      if (summarymax <= summ.max) {
        summarymax = summ.max
      }
      splitAllsummery[summ.campName] = {
        name: summ.campName,
        data: [
          ...(splitAllsummery[summ.campName]?.data || []),
          {
            ...summ,
            color: summ.zCategoryN == 'Self' ? '#0062FF' : '#4CAF50',
          },
        ],
      }
    })
  console.log('spli', addSummaryResultsData, splitAllsummery)
  let splitcompetencyResult = {}
  let commin = 0
  let commax = 0
  addCompetencyResultsData.forEach((summ, index) => {
    if (index == 0) {
      commin = summ.min
      commax = summ.max
    }

    if (commin >= summ.min) {
      commin = summ.min
    }
    if (commax <= summ.max) {
      commax = summ.max
    }
    splitcompetencyResult[summ.strCompNm + summ.strCapNm] = {
      name: summ.strCompNm + summ.strCapNm,
      subname: summ.strCapNm || '',
      data: [
        ...(splitcompetencyResult[summ.strCompNm + summ.strCapNm]?.data || []),
        {
          ...summ,
        },
      ],
    }
  })
  Array.from(Array(summarymax + 1).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.splitspan_${c}:before {
content: '' !important;
position: absolute;
display: block;
top: 50%;
height: 42px;
width: 2px;
background: #BDBDBD;
left: ${(c / summarymax) * 100}%;
transform: translatey(-${23}px) !important;
z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })
  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.hiddensplitspan_one_${c}:before {
content: '' !important;
position: absolute;
display: block;
top: 91%;
height: 60px;
width: 2px;
background: #BDBDBD;
left: ${(c / sclaesize) * 100}%;
transform: translatey(-${23}px) !important;
z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize + 1).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.detailitem_${c}:before {
content: '' !important;
position: absolute;
display: block;
top: 91%;
height: 30px;
width: 2px;
background: #BDBDBD;
left: ${(c / splitmax) * 100}%;
transform: translatey(-${23}px) !important;
z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.knowStrength${c}:before {
content: '' !important;
position: absolute;
display: block;
top: 91%;
height: 60px;
width: 2px;
background: #BDBDBD;
left: ${(c / sclaesize) * 120}%;
transform: translatey(-${23}px) !important;
z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.knownarer${c}:before {
content: '' !important;
position: absolute;
display: block;
top: 91%;
height: 60px;
width: 2px;
background: #BDBDBD;
left: ${(c / sclaesize) * 120}%;
transform: translatey(-${23}px) !important;
z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(commax + 1).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.compentenct${c}:before {
content: '' !important;
position: absolute;
display: block;
top: 91%;
height: 34px;
width: 2px;
background: #BDBDBD;
left: ${(c / sclaesize) * 120}%;
transform: translatey(-${23}px) !important;
z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })
  const leadershipHandle = (e) => {
    setactiveLeadershiptab(e.currentTarget.dataset.id)
  }

  const LeadershipComponent = (props) => {
    const { name } = props

    const CompList = {
      'HIDDEN STRENGTHS': Hidden,
      'KNOWN STRENGTHS': Knowstrength,
      'BLIND SPOTS': BlindSpots,
      'KNOWN AREAS FOR DEVELOPMENT': KnownAreas,
    }

    let CompDisplay = CompList[name]
    return <CompDisplay />
  }
  console.log('addsum', addSummaryTopTableGap)
  const Hidden = () => {
    return (
      <Grid>
        <br />
        <Typography variant="h4">Hidden Strengths</Typography>
        <Typography>
          Below are the individual items with the biggest gap between the
          average score and the self-rating, and where the self-rating is lower
          than the average.These competencies are therefore hidden strengths
          because you were not aware that you used them at such a high level
          with consistency.The related global and supporting competency is shown
          before the item.
        </Typography>
        {addSummaryTopTableGap.map((top) => {
          let widthSiz = 400
          let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz //high
          let average2 =
            (parseInt(top.selfRating) / (sclaesize + 0.01)) * widthSiz
          console.log(
            'average',
            top.average >= top.selfRating,
            top.average <= top.selfRating,
            average,
            average2
          )
          const { avg = average, selfRating } = top
          return top.average >= top.selfRating == true ? (
            <div>
              <hr />

              <p>
                {top.competencyName}: {top.capabilityName}
                <br />
                {top.zCaption}
              </p>
              <table>
                <tr>
                  <td> {top.zCompareGroupName}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.averageRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={parseFloat(top.average).toFixed(2) || 'N/A'}
                      color={'#0062FF'}
                      precent={average}
                      chartWidth={widthSiz}
                    />
                    {/* | */}
                    {Array.from(Array(sclaesize).keys()).map((c) => {
                      return (
                        <span
                          aria-current="stedsadp"
                          className={`hiddensplitspan_one_${c}`}></span>
                      )
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{top.zCompareGroupNameSelf}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.selfRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    {' '}
                    <BarGraph
                      value={top.selfRating || 'N/A'}
                      color={'#4CAF50'}
                      precent={average2}
                      chartWidth={widthSiz}
                    />
                    {/* {Array.from(Array(sclaesize).keys()).map((c) => {
                    return (
                      <span
                        aria-current="stedsadp"
                        className={`hiddensplitspan_two_${c}`}></span>
                    )
                  })} */}
                  </td>
                </tr>
              </table>
            </div>
          ) : (
            ''
          )
        })}
      </Grid>
    )
  }

  const Knowstrength = () => {
    return (
      <Grid>
        <br />

        <Typography variant="h4">Known Strengths</Typography>
        <Typography>
          Below are the individual items/questions with the highest average
          score.
        </Typography>

        <Typography>
          The related global and supporting competency is shown before the
          question. The Self rating is shown for comparison.
        </Typography>
        <Grid container direction="row" justifyContent="space-evenly">
          <Grid xm={6} spacing={2} xs={5}>
            {addSummaryTopTable.map((top) => {
              let widthSiz = 400
              let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
              let average2 =
                (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz

              return (
                <div>
                  <hr />

                  <p>
                    {top.competencyName}: {top.capabilityName}
                    <br />
                    {top.zCaption}
                  </p>
                  <table>
                    <tr>
                      <td> {top.zCompareGroupName}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.averageRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                          position: 'relative',
                        }}>
                        {' '}
                        <BarGraph
                          value={float(top.average) || 'N/A'}
                          color={'#0062FF'}
                          precent={average}
                          chartWidth={widthSiz}
                        />
                        {Array.from(Array(sclaesize).keys()).map((c) => {
                          return (
                            <span
                              aria-current="stedsadp"
                              className={`knowStrength${c}`}></span>
                          )
                        })}
                      </td>
                    </tr>
                    <tr>
                      <td>{top.zCompareGroupNameSelf}</td>
                      <td style={{ width: '3rem', 'text-align': 'center' }}>
                        {top.selfRatingCount}
                      </td>
                      <td
                        style={{
                          width: widthSiz,
                          display: 'flex',
                          flexDirection: 'row',
                          position: 'relative',
                        }}>
                        {' '}
                        <BarGraph
                          value={top.selfRating || 'N/A'}
                          color={'#4CAF50'}
                          precent={average2}
                          chartWidth={widthSiz}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              )
            })}
          </Grid>
          <Grid xm={6} spacing={2} xs={5}>
            {addSummaryTbResources
              .map((v) => v.addSummaryTbResourceTable)
              .map((n) => {
                return n.map((re) => {
                  return (
                    <div style={{ padding: '40px 0' }}>
                      <Typography
                        style={{
                          display: 'flex',
                          'align-items': 'center',
                          'font-size': '22px',
                          color: '#00CC01',
                        }}>
                        <img src={Image} />
                        Learning Resource
                      </Typography>
                      <Typography>
                        <a
                          href={re.linkurl.replace(
                            'Hostname',
                            localStorage.getItem('CANTOODOMAIN')
                          )}
                          target="_blank">
                          {re.resName}
                        </a>
                        {re.zDescription}
                      </Typography>
                    </div>
                  )
                })
              })}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const BlindSpots = () => {
    return (
      <Grid>
        <br />

        <Typography variant="h4">Blind Spots</Typography>
        <Typography>
          Below are the individual items with the biggest gap between the
          average score and the self-rating, and where the self-rating is higher
          than the average. These competencies are therefore blind spots that
          may need additional focus for development. The related global and
          supporting competency is shown before the item.
        </Typography>
        {addSummaryTopTableGap.map((top) => {
          let widthSiz = 400
          let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
          let average2 = (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz
          const { avg = average, selfRating } = top
          return top.average <= top.selfRating == true ? (
            <div>
              <hr />

              <p>
                {top.competencyName}: {top.capabilityName}
                <br />
                {top.zCaption}
              </p>
              {/* <table>
              <tr>
                <td> {top.zCompareGroupName}</td>
                <td style={{ width: '3rem', 'text-align': 'center' }}>
                  {top.averageRatingCount}
                </td>
                <td
                  style={{
                    width: widthSiz,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                  }}>
                  <BarGraph
                    value={float(top.average) || 'N/A'}
                    color={'#0062FF'}
                    precent={average}
                    chartWidth={widthSiz}
                  />
                </td>
              </tr>
              <tr>
                <td>{top.zCompareGroupNameSelf}</td>
                <td style={{ width: '3rem', 'text-align': 'center' }}>
                  {top.selfRatingCount}
                </td>
                <td
                  style={{
                    width: widthSiz,
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'relative',
                  }}>
                  {' '}
                  <BarGraph
                    value={float(top.selfRating) || 'N/A'}
                    color={'#4CAF50'}
                    precent={average2}
                    chartWidth={widthSiz}
                  />
                </td>
              </tr>
            </table> */}
              <table>
                <tr>
                  <td> {top.zCompareGroupName}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.averageRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={float(top.average) || 'N/A'}
                      color={'#0062FF'}
                      precent={average}
                      chartWidth={widthSiz}
                    />
                    {Array.from(Array(sclaesize).keys()).map((c) => {
                      return (
                        <span
                          aria-current="stedsadp"
                          className={`knowStrength${c}`}></span>
                      )
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{top.zCompareGroupNameSelf}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.selfRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={top.selfRating || 'N/A'}
                      color={'#4CAF50'}
                      precent={average2}
                      chartWidth={widthSiz}
                    />
                  </td>
                </tr>
              </table>
            </div>
          ) : (
            ''
          )
        })}
      </Grid>
    )
  }
  const KnownAreas = () => {
    return (
      <>
        <Grid>
          <br />

          <Typography variant="h4">Known Areas For Development</Typography>
          <Typography>
            Below are the individual items/questions with the lowest average
            score.
          </Typography>

          <Typography>
            The related global and supporting competency is shown before the
            question. The Self rating is shown for comparison.
          </Typography>
          <Grid container direction="row" justifyContent="space-evenly">
            <Grid xm={6} spacing={2} xs={5}>
              {addSummaryTopTableBottom.map((top) => {
                let widthSiz = 400
                let average =
                  (parseInt(top.average) / (sclaesize - 1)) * widthSiz
                let average2 =
                  (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz

                return (
                  <div>
                    <hr />

                    <p>
                      {top.competencyName}: {top.capabilityName}
                      <br />
                      {top.zCaption}
                    </p>
                    <br />
                    <table>
                      <tr>
                        <td> {top.zCompareGroupName}</td>
                        <td style={{ width: '3rem', 'text-align': 'center' }}>
                          {top.averageRatingCount}
                        </td>
                        <td
                          style={{
                            width: widthSiz,
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                          }}>
                          {' '}
                          <BarGraph
                            value={parseFloat(top.average).toFixed(2) || 'N/A'}
                            color={'#0062FF'}
                            precent={average}
                            chartWidth={widthSiz}
                          />
                          {Array.from(Array(sclaesize).keys()).map((c) => {
                            return (
                              <span
                                aria-current="stedsadp"
                                className={`knownarer${c}`}></span>
                            )
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>{top.zCompareGroupNameSelf}</td>
                        <td style={{ width: '3rem', 'text-align': 'center' }}>
                          {top.selfRatingCount}
                        </td>
                        <td
                          style={{
                            width: widthSiz,
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                          }}>
                          {' '}
                          <BarGraph
                            value={
                              parseFloat(top.selfRating).toFixed(2) || 'N/A'
                            }
                            color={'#4CAF50'}
                            precent={average2}
                            chartWidth={widthSiz}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                )
              })}
            </Grid>
            <Grid xm={6} spacing={2} xs={5}>
              {addSummaryTbResourcesBottom &&
                addSummaryTbResourcesBottom
                  .map((v) => v.addSummaryTbResourceTable)
                  .map((n) => {
                    return n.map((re) => {
                      return (
                        <div style={{ padding: '40px 0' }}>
                          <Typography
                            style={{
                              display: 'flex',
                              'align-items': 'center',
                              'font-size': '22px',
                              color: '#00CC01',
                            }}>
                            <img src={Image} />
                            Learning Resource
                          </Typography>
                          <Typography>
                            <a
                              href={re.linkurl.replace(
                                'Hostname',
                                localStorage.getItem('CANTOODOMAIN')
                              )}
                              target="_blank">
                              {re.resName}
                            </a>
                            {re.zDescription}
                          </Typography>
                        </div>
                      )
                    })
                  })}
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
  let newDataItems = {}
  dataItem.forEach((da) => {
    // console.log(da)
    newDataItems[da.strCompNm + '_' + da.strCapNm] = {
      ...(newDataItems[da.strCompNm + '_' + da.strCapNm] || {}),
      dataItemsData: [
        ...((newDataItems[da.strCompNm + '_' + da.strCapNm] || {})
          .dataItemsData || []),
        ...da.dataItemsData,
      ],
    }
  })
  // let sampleDta = Object.values(newDataItems).map((val) => {
  //   let inData = {}
  //   val.dataItemsData.forEach((ind) => {
  //     inData[ind.zCaption] = { ...(inData[ind.zCaption] || {}), ...ind }
  //   })
  //   console.log(inData)
  //   return { ...val, inData }
  // })
  // console.log(newDataItems)
  console.log('data', data)
  let name = 'dasd'
  async function getReportdata() {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: `${PROJECT_INDIVIDUAL_REPORT}`,
      params: {
        ...JSON.parse(Buffer.from(data, 'base64').toString('binary')),
        ROLN: true,
        DOERS: true,
        SS: true,
        THE5S: true,
        THE10S: true,
      },
    }
    try {
      const response = await API(config)
      const data = response.data
      console.log('response', response.data)
      setreportData(data)
    } catch (error) {
      console.error(error)
    }
  }
  console.log('report data', reportData, reportData[0])
  useEffect(() => {
    getReportdata()
  }, [])
  //eyJJbmNsdWRlT3JnQXZlcmFnZSI6InRydWUiLCJJbmNsdWRlUHJvamVjdEF2ZXJhZ2UiOiJ0cnVlIiwiU2VsZWN0ZWRVc2VycyI6IjIxMDM3MCJ9
  console.log(reportData)
  let inu = 1

  const getOrangizationLogo = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORGANIZATION_LOGO_URL,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    setOrgLogoLoading(true)
    try {
      const response = await API(getconfig)
      const { status, data } = response.data

      if (response.status == 200) {
        setOrgLogoLoading(false)
        setOrgLogo(data[0])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setOrgLogoLoading(false)
    }
  }
  let tval = [
    'average',
    'everagehdhg',
    'sverageksfkdsl',
    'rverage',
    'tverage',
    'iverage',
    'lverage',
    'pverage (excl. self)',
  ]
  useEffect(() => {
    getOrangizationLogo()
  }, [orgLogoSelector])
  function RGBToHex(r, g, b) {
    console.log('rgb', r, g, b)
    r = r.toString(16)
    g = g.toString(16)
    b = b.toString(16)

    if (r.length == 1) r = '0' + r
    if (g.length == 1) g = '0' + g
    if (b.length == 1) b = '0' + b

    return '#' + r + g + b
  }
  let bclrflag = 0
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        // padding:'1.5%',paddingBottom:"1%"
      }}>
      <Grid>
        <div>
          <Grid>
            <div
              style={{
                alignContent: 'center',
                marginTop: '260px',
                marginLeft: '20px',
              }}>
              <img
                src={orgLogo}
                // src={circle}
                // src="https://cantoo.blob.core.windows.net/cantoo/cantoologotypeoutsm_17-03-2023-13-06-23.png"
                // alt="KendoReact PDF Generator embedded image"
                alt="logo"
                width="120px"></img>
              <br />
            </div>
            <div
              style={{
                alignContent: 'center',
                // marginTop: '280px',
                marginLeft: '40px',
              }}>
              {/* <img
              src={orgLogo}
              // src={circle}
                // src="https://cantoo.blob.core.windows.net/cantoo/cantoologotypeoutsm_17-03-2023-13-06-23.png"
                // alt="KendoReact PDF Generator embedded image"
                alt="logo"
                width='120px'></img>
                <br/> */}
              <Typography>Project Name : {projectName}</Typography>
              <Typography>Participant : {strPName}</Typography>
              <Typography> {strReportDesc}</Typography>
              <Typography style={{ fontSize: '10px' }}>
                Due Date : {dueDateFormat}
              </Typography>
              <br />

              {console.log('orglogo', orgLogo)}
            </div>
            <Typography className="page-break">
              Summary
              <p style={{ fontSize: '12px' }}>
                {' '}
                Congratulations on receiving your feedback.
              </p>
              <p style={{ fontSize: '10px' }}>
                This is one of many steps you can take to initiate your personal
                development journey. Understanding feedback requires an openness
                to learn, a willingness to make improvements and adjustments,
                and a dedication to yourself and others. To best prepare you for
                your feedback, consider this diagram on behavioral change.
                Remember that confidence builds as we are actively engaged
                through the Action phase. This phase allows for taking new
                behaviors we learn and putting them into practice.
              </p>
            </Typography>
            <br />
            <br />
            <Typography style={{ fontSize: '15px' }}>
              Summary Results by Competency
            </Typography>
            <Typography style={{ fontSize: '10px' }}>
              Below are the summary of your results by competency.
            </Typography>
            <Typography style={{ fontSize: '10px' }}>
              The top horizontal bar indicates the Average rating, excluding
              self from all statements and respondents under each competency.
            </Typography>
            <Typography style={{ fontSize: '10px' }}>
              The bottom horizontal bar indicates how you rated yourself for
              each competency.
            </Typography>
            <br />
            <br />
            {/* <hr style={{ marginBottom: '0' }} /> */}

            <div style={{ width: '600px' }}>
              {Object.entries(splitAllsummery).map((split, idex) => {
                let gc1 = []
                let gd1 = []
                const displayedCategories = new Set()

                return (
                  <>
                    {idex == 3 ? <p className="page-break"></p> : <></>}
                    {idex == 8 ? <p className="page-break"></p> : <></>}
                    {idex == 13 ? <p className="page-break"></p> : <></>}
                    <Typography style={{ fontSize: '12px' }}>
                      {split[0]}
                    </Typography>
                    {split[1].data.map((summ, i) => {
                      if (displayedCategories.has(summ.zCategoryN)) {
                        return null // Skip rendering this row
                      }
                      displayedCategories.add(summ.zCategoryNm)
                      gc1.push(summ.zCategoryN.trim())

                      let widthSiz = 400
                      let newSize = (summ.average / summarymax) * widthSiz
                      gd1.push(...gd1, {
                        name: summ.zCategoryN,
                        data: summ.average >= 0 ? summ.average : 0,
                        colorvalue: summ.color,
                      })
                      return <></>
                    })}
                    <Chart
                      style={{
                        width: 500,
                        height: 110,
                      }}>
                      {/* <ChartArea background="gainsboro" margin={20} /> */}
                      <ChartValueAxis>
                        <ChartValueAxisItem min={0} max={5} />
                      </ChartValueAxis>
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                          categories={gc1}></ChartCategoryAxisItem>
                      </ChartCategoryAxis>

                      <ChartSeries>
                        <ChartSeriesItem
                          gap={2}
                          type="bar"
                          data={gd1}
                          min={0}
                          categoryField="name"
                          field="data"
                          // color={gcol}
                          colorField="colorvalue"
                          // visibleInLegendField={true}
                        >
                          <ChartSeriesLabels
                            visible={true}
                            color="#ffffff"
                            content="data"
                            position="left"
                            background="none"
                            padding="2"></ChartSeriesLabels>
                        </ChartSeriesItem>
                      </ChartSeries>
                    </Chart>
                  </>
                )
              })}
            </div>
            <br />
            <Typography style={{ fontSize: '14px' }} className="page-break">
              Summary Results – By Respondent Category
            </Typography>

            <Typography style={{ fontSize: '10px' }}>
              In this section, you will find the average ratings at the
              Competency and Supporting Competency levels for all respondent
              categories included in your survey. This section shows insights
              into the Respondent Category view allowing you to compare your own
              Self ratings to those average ratings provided by the various
              respondents (e.g. Direct Report, Manager, Peer, etc.). Use this
              section of your report to see differences in your own ratings and
              those from others.
            </Typography>

            {Object.values(splitcompetencyResult).map((summ, index) => {
              const [, second = ''] = summ.name.split(':') || []
              // console.log(summ)
              const displayedCategories1 = new Set()

              let gc2 = []
              let gd2 = []
              let gcl2 = []
              let gcol = ''
              bclrflag = 1
              return (
                <>
                  <div style={{ padding: '0 20px' }}>
                    <hr style={{ marginBottom: '0' }} />
                    {index == 2 ? <p className="page-break"></p> : <></>}
                    {index == 5 ? <p className="page-break"></p> : <></>}
                    {index == 8 ? <p className="page-break"></p> : <></>}
                    {index == 11 ? <p className="page-break"></p> : <></>}
                    {index == 14 ? <p className="page-break"></p> : <></>}
                    {index == 17 ? <p className="page-break"></p> : <></>}
                    {index == 20 ? <p className="page-break"></p> : <></>}
                    {index == 23 ? <p className="page-break"></p> : <></>}
                    {index == 26 ? <p className="page-break"></p> : <></>}
                    {index == 29 ? <p className="page-break"></p> : <></>}
                    {index == 32 ? <p className="page-break"></p> : <></>}
                    {index == 35 ? <p className="page-break"></p> : <></>}
                    <p style={{ marginBottom: '0', fontSize: '12px' }}>
                      {' '}
                      {summ?.name}
                    </p>

                    {summ.data.map((values, i) => {
                      console.log('values', values)
                      let nnn = tval[i]
                      let clr = values.color.replaceAll(' ', '')
                      if (displayedCategories1.has(values.zCategoryNm)) {
                        return null // Skip rendering this row
                      }
                      displayedCategories1.add(values.zCategoryNm)
                      gc2.push(
                        values.zCategoryNm.trim().toLowerCase()
                        // .concat(' ')
                        // .concat(values.ratingCount)
                      )
                      gd2.push(...gd2, {
                        name: values.zCategoryNm,

                        data:
                          values.ratingCount < 3 &&
                          values.zCategoryNm !== 'Manager' &&
                          values.zCategoryNm !== 'Self'
                            ? 'Not enough responses were provided to show data'
                            : values.average,
                        colorvalue:
                          values.ratingCount < 3 ? '' : rgbToHex(values.color),
                      })

                      let widthSiz = 400
                      let newSize = (values.average / commax) * widthSiz
                      return <></>
                    })}

                    <div style={{ width: '600px' }}>
                      <Chart
                        style={{
                          width: 600,
                        }}>
                        <ChartArea background={'gainsboro'} margin={20} />
                        <ChartValueAxis>
                          <ChartValueAxisItem min={0} max={5} />
                        </ChartValueAxis>
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem
                            categories={gc2}
                            justified={true}></ChartCategoryAxisItem>
                        </ChartCategoryAxis>

                        <ChartSeries>
                          <ChartSeriesItem
                            type="bar"
                            data={gd2}
                            categoryField="name"
                            field="data"
                            alignItems="left"
                            // color={gcol}
                            colorField="colorvalue"
                            // visibleInLegendField={true}
                            min="1"
                            max="5">
                            <ChartSeriesLabels
                              visible={true}
                              color="#ffffff"
                              content="data"
                              position="left"
                              background="none"
                              font="bold 8px Arial, sans-serif"
                              step="1"></ChartSeriesLabels>
                          </ChartSeriesItem>
                        </ChartSeries>
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem categories={gc2} />
                        </ChartCategoryAxis>
                      </Chart>
                    </div>
                  </div>
                </>
              )
            })}
          </Grid>
        </div>
      </Grid>
      {/* LeadershipQu */}
      <Grid>
        <br />
        <Grid>
          <Typography style={{ fontSize: '14px' }} className="page-break">
            LEADERSHIP QUADRANT
          </Typography>
          <Typography style={{ fontSize: '10px' }}>
            This section provides a convenient and quick view of areas you can
            focus on for career development. Development Tip: Look for patterns
            in the competencies from which the items originate. Are the top or
            bottom items consistent with the overall competency ratings? Are
            they anomalies? The focus should not be on the individual items and
            ratings, but on the overall patterns that help you see yourself more
            accurately and most importantly help guide your development efforts
            and career planning.
          </Typography>
        </Grid>
        <br />
      </Grid>

      <Grid>
        <Grid>
          <br />
          <Typography style={{ fontSize: '14px' }}>Hidden Strengths</Typography>
          <Typography style={{ fontSize: '10px' }}>
            Below are the individual items with the biggest gap between the
            average score and the self-rating, and where the self-rating is
            lower than the average.These competencies are therefore hidden
            strengths because you were not aware that you used them at such a
            high level with consistency.The related global and supporting
            competency is shown before the item.
          </Typography>

          {addSummaryTopTableGap.map((top, hto) => {
            let gda = []
            let gca = []
            let gda1 = []
            let gca1 = []
            let widthSiz = 400
            let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz //high
            let average2 =
              (parseInt(top.selfRating) / (sclaesize + 0.01)) * widthSiz
            console.log('hidden', top.color)
            const { avg = average, selfRating } = top
            gda.push(...gda, {
              name: top.zCompareGroupName,
              // .concat(' ')
              // .concat(top.averageRatingCount)
              data: top.average,
              colorvalue: '#0062ff',
            })
            gda.push(...gda, {
              name: top.zCompareGroupNameSelf,
              // .concat(' ')
              // .concat(top.selfRatingCount)
              data: top.selfRating,
              colorvalue: '#4caf50',
            })
            // gda.push(top.average)
            gca.push(
              top.zCompareGroupName
              // .concat(' ').concat(top.averageRatingCount)
            )
            // gda.push(top.selfRating )
            gca.push(
              top.zCompareGroupNameSelf
              // .concat(' ').concat(top.selfRatingCount)
            )
            if (top.average >= top.selfRating == false) {
              hid++
            }
            return
            top.average >= top.selfRating == false && (
              <div>
                <hr />
                {hto == 2 ? <p className="page-break"></p> : <></>}
                {hto == 4 ? <p className="page-break"></p> : <></>}
                {hto == 6 ? <p className="page-break"></p> : <></>}

                <p style={{ fontSize: '13px' }}>
                  {top.competencyName}: {top.capabilityName}
                  <br />
                  {top.zCaption}
                </p>

                <div style={{ width: '600px' }}>
                  <Chart
                    style={{
                      width: 500,
                      height: 110,
                    }}>
                    <ChartValueAxis>
                      <ChartValueAxisItem min={0} max={5} />
                    </ChartValueAxis>

                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={gca}></ChartCategoryAxisItem>
                    </ChartCategoryAxis>
                    <ChartSeries>
                      <ChartSeriesItem
                        gap={2}
                        type="bar"
                        data={gda}
                        categoryField="name"
                        field="data"
                        // color={gcol}
                        colorField="colorvalue"
                        // visibleInLegendField={true}
                      >
                        <ChartSeriesLabels
                          visible={true}
                          color="#ffffff"
                          content="data"
                          position="left"
                          background="none"></ChartSeriesLabels>
                      </ChartSeriesItem>
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            )
          })}
          {hid > 0 ? (
            ''
          ) : (
            <center>
              <p style={{ fontSize: '10px' }}>
                There were no Hidden Strengths identified in the survey data.
              </p>
            </center>
          )}
        </Grid>
      </Grid>
      <Grid>
        <br />

        <Typography style={{ fontSize: '14px' }} className="page-break">
          Known Strengths
        </Typography>
        <Typography style={{ fontSize: '10px' }}>
          Below are the individual items/questions with the highest average
          score.
        </Typography>

        <Typography style={{ fontSize: '10px' }}>
          The related global and supporting competency is shown before the
          question. The Self rating is shown for comparison.
        </Typography>
        <Grid>
          <Grid>
            <div style={{ width: '800px' }}>
              {addSummaryTopTable.map((top, i) => {
                let sgda = []
                let sgca = []
                let widthSiz = 400
                let average =
                  (parseInt(top.average) / (sclaesize - 1)) * widthSiz
                let average2 =
                  (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz
                sgda.push(...sgda, {
                  name: top.zCompareGroupName,
                  // .concat(' ')
                  // .concat(top.averageRatingCount)
                  data: top.average,
                  colorvalue: '#0062ff',
                })
                sgda.push(...sgda, {
                  name: top.zCompareGroupNameSelf,
                  // .concat(' ')
                  // .concat(top.selfRatingCount)
                  data: top.selfRating,
                  colorvalue: '#4caf50',
                })
                sgca.push(
                  top.zCompareGroupName
                  // .concat(' ')
                  // .concat(top.averageRatingCount)
                )
                sgca.push(
                  top.zCompareGroupNameSelf
                  // .concat(' ')
                  // .concat(top.selfRatingCount)
                )
                // sgda.push(top.average)
                // sgda.push(top.selfRating)
                console.log('toppp', top, sgca, sgda)

                return top.average <= top.selfRating ? (
                  <div>
                    <hr />
                    {i == 3 ? <p className="page-break"></p> : <></>}
                    {i == 6 ? <p className="page-break"></p> : <></>}
                    {i == 9 ? <p className="page-break"></p> : <></>}

                    <p style={{ fontSize: '12px' }}>
                      {top.competencyName}: {top.capabilityName}
                      <br />
                      {top.zCaption}
                    </p>
                    <table>
                      <tr>
                        <Chart
                          style={{
                            width: 500,
                            height: 110,
                          }}>
                          <ChartValueAxis>
                            <ChartValueAxisItem min={0} max={5} />
                          </ChartValueAxis>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={sgca}></ChartCategoryAxisItem>
                          </ChartCategoryAxis>

                          <ChartSeries>
                            <ChartSeriesItem
                              gap={2}
                              type="bar"
                              data={sgda}
                              categoryField="name"
                              field="data"
                              // color={gcol}
                              colorField="colorvalue"
                              // visibleInLegendField={true}
                            >
                              <ChartSeriesLabels
                                visible={true}
                                color="#ffffff"
                                content="data"
                                position="left"
                                background="none"></ChartSeriesLabels>
                            </ChartSeriesItem>
                          </ChartSeries>
                        </Chart>
                      </tr>
                    </table>
                  </div>
                ) : (
                  <></>
                )
              })}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <br />
        <Typography style={{ fontSize: '14px' }} className="page-break">
          Blind Spots
        </Typography>
        <Typography style={{ fontSize: '10px' }}>
          Below are the individual items with the biggest gap between the
          average score and the self-rating, and where the self-rating is higher
          than the average. These competencies are therefore blind spots that
          may need additional focus for development. The related global and
          supporting competency is shown before the item.
        </Typography>
        {console.log('summary', addSummaryTopTableGap)}
        {addSummaryTopTableGap.map((top, blindin) => {
          let widthSiz = 400
          let blindsda = []
          let blindscate = []
          let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
          let average2 = (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz
          const { avg = average, selfRating } = top
          blindsda.push(...blindsda, {
            name: top.zCompareGroupName
              .concat(' ')
              .concat(top.averageRatingCount),
            data: top.average,
            colorvalue: '#0062ff',
          })
          blindsda.push(...blindsda, {
            name: top.zCompareGroupNameSelf
              .concat(' ')
              .concat(top.selfRatingCount),
            data: top.selfRating,
            colorvalue: '#4caf50',
          })
          blindscate.push(
            top.zCompareGroupName.concat(' ').concat(top.averageRatingCount)
          )
          // blindsda.push(top.average)
          blindscate.push(
            top.zCompareGroupNameSelf.concat(' ').concat(top.selfRatingCount)
          )
          // blindsda.push(top.selfRating)
          return top.average <= top.selfRating == true ? (
            <div>
              <hr />
              {/* {blindin == 0 ? <p className="page-break"></p> : <></>} */}
              {blindin == 3 ? <p className="page-break"></p> : <></>}
              {blindin == 6 ? <p className="page-break"></p> : <></>}
              {blindin == 9 ? <p className="page-break"></p> : <></>}
              <p style={{ fontSize: '13px' }}>
                {top.competencyName}: {top.capabilityName}
                <br />
                {top.zCaption}
              </p>
              {console.log('bsa', blindsda)}
              <table>
                <tr>
                  <Chart
                    style={{
                      width: 500,
                      height: 110,
                    }}>
                    <ChartValueAxis>
                      <ChartValueAxisItem min={0} max={5} />
                    </ChartValueAxis>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={blindscate}></ChartCategoryAxisItem>
                    </ChartCategoryAxis>

                    <ChartSeries>
                      <ChartSeriesItem
                        gap={2}
                        type="bar"
                        data={blindsda}
                        categoryField="name"
                        field="data"
                        // color={gcol}
                        colorField="colorvalue"
                        // visibleInLegendField={true}
                      >
                        <ChartSeriesLabels
                          visible={true}
                          color="#ffffff"
                          content="data"
                          position="left"
                          background="none"></ChartSeriesLabels>
                      </ChartSeriesItem>
                    </ChartSeries>
                  </Chart>
                </tr>
              </table>
            </div>
          ) : (
            ''
          )
        })}
      </Grid>
      <Grid>
        <br />

        <Typography style={{ fontSize: '14px' }} className="page-break">
          Known Areas For Development
        </Typography>
        <Typography style={{ fontSize: '10px' }}>
          Below are the individual items/questions with the lowest average
          score.
        </Typography>

        <Typography style={{ fontSize: '10px' }}>
          The related global and supporting competency is shown before the
          question. The Self rating is shown for comparison.
        </Typography>
        <Grid>
          <Grid>
            {addSummaryTopTableBottom.map((top, knindex) => {
              let knowda = []
              let knowca = []
              let widthSiz = 400
              let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz
              let average2 =
                (parseInt(top.selfRating) / (sclaesize - 1)) * widthSiz
              knowda.push(...knowda, {
                name: top.zCompareGroupName
                  .concat(' ')
                  .concat(top.averageRatingCount),
                data: top.average,
                colorvalue: '#0062ff',
              })
              knowda.push(...knowda, {
                name: top.zCompareGroupNameSelf
                  .concat(' ')
                  .concat(top.selfRatingCount),
                data: top.selfRating,
                colorvalue: '#4caf50',
              })
              knowca.push(
                top.zCompareGroupName.concat(' ').concat(top.averageRatingCount)
              )
              knowca.push(
                top.zCompareGroupNameSelf
                  .concat(' ')
                  .concat(top.selfRatingCount)
              )
              // knowda.push(top.average)
              // knowda.push(top.selfRating)

              return (
                <div>
                  <hr />
                  {/* {knindex == 0 ? <p className="page-break"></p> : <></>} */}
                  {knindex == 3 ? <p className="page-break"></p> : <></>}
                  {knindex == 6 ? <p className="page-break"></p> : <></>}
                  <p style={{ fontSize: '13px' }}>
                    {top.competencyName}: {top.capabilityName}
                    <br />
                    {top.zCaption}
                  </p>
                  <br />
                  <table>
                    <tr>
                      <div style={{ width: '600px' }}>
                        <Chart
                          style={{
                            width: 500,
                            height: 110,
                          }}>
                          <ChartValueAxis>
                            <ChartValueAxisItem min={0} max={5} />
                          </ChartValueAxis>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={knowca}></ChartCategoryAxisItem>
                          </ChartCategoryAxis>

                          <ChartSeries>
                            <ChartSeriesItem
                              type="bar"
                              data={knowda}
                              categoryField="name"
                              field="data"
                              gap={2}
                              // color={gcol}
                              colorField="colorvalue"
                              // visibleInLegendField={true}
                            >
                              <ChartSeriesLabels
                                visible={true}
                                color="#ffffff"
                                content="data"
                                position="left"
                                background="none"></ChartSeriesLabels>
                            </ChartSeriesItem>
                          </ChartSeries>
                        </Chart>
                      </div>
                    </tr>
                  </table>
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          <Typography style={{ fontSize: '14px' }} className="page-break">
            ITEMS DETAIL
          </Typography>
          <Typography style={{ fontSize: '14px' }}>
            {' '}
            Detail Item Results By Respondent Category
          </Typography>
          <br />
          <Typography style={{ fontSize: '10px' }}>
            The Detailed Item section provides you with the complete set of
            individual items for all the competencies rated. Results are
            organized by competency and show the scores/averages for all the
            respondent categories. While the various summary sections of the
            report provide a high-level view to help you identify patterns, this
            section of the report is useful in reviewing the detailed ratings
            for individual items (behaviors) as rated by the various respondent
            categories..
          </Typography>
          <br />
          <br />

          <Divider />
          <br />
          {console.log('values of all', allDataValues)}
          {allDataValuescus.map((spli, indexs) => {
            console.log('innn', indexs, spli)
            return (
              <div>
                {/* <div style={{ fontWeight: 'bold' }}> */}
                <p>
                  <p
                    style={{ fontSize: '12px' }}
                    className={indexs != 0 ? 'page-break' : ''}>
                    {spli.strCompNm + ':' + ' ' + spli.strCapNm}
                  </p>
                </p>
                {/* </div> */}
                {Object.entries(spli.inderClone).map((da, index) => {
                  cno++
                  let itemda = []
                  let itemca = []
                  let itemcol = []
                  let glcolorfield = ''
                  console.log('da1length', da[1].length)
                  return (
                    <div>
                      {index == 2 ? <p className="page-break"></p> : <></>}
                      {index == 4 ? <p className="page-break"></p> : <></>}
                      {index == 6 ? <p className="page-break"></p> : <></>}
                      {index == 8 ? <p className="page-break"></p> : <></>}
                      {index == 10 ? <p className="page-break"></p> : <></>}
                      {index == 12 ? <p className="page-break"></p> : <></>}
                      {index == 14 ? <p className="page-break"></p> : <></>}
                      {index == 16 ? <p className="page-break"></p> : <></>}
                      {index == 18 ? <p className="page-break"></p> : <></>}
                      {index == 20 ? <p className="page-break"></p> : <></>}
                      <div style={{ fontSize: '10px' }}>
                        {cno}. {da[0]}
                      </div>

                      {/* <table style={{ padding: '0 4rem' }}> */}
                      {/* <tr>
                            <td colSpan={3}></td>
                            <td>Lowest Rating</td>
                            <td>Highest Rating</td>
                          </tr> */}
                      {da[1].map((gr, i) => {
                        let widthSiz = 400
                        console.log('gr', gr)
                        let newSize = (gr.average / splitmax) * widthSiz
                        itemda.push(...itemda, {
                          name: gr.itemNorm.concat(' ').concat(gr.ratingCount),
                          // .concat(' ')
                          // .concat(gr.min)
                          // .concat(' ')
                          // .concat(gr.max),
                          data:
                            gr.ratingCount < 3 &&
                            gr.itemNorm !== 'Manager' &&
                            gr.itemNorm !== 'Self'
                              ? 'Not enough responses were provided to show data'
                              : gr.average,
                          colorvalue:
                            gr.itemNorm == 'Self' ? '#4caf50' : '#0062ff',
                        })
                        itemca.push(
                          gr.itemNorm.concat(' ').concat(gr.ratingCount)
                          // .concat(' ')
                          // .concat(gr.min)
                          // .concat(' ')
                          // .concat(gr.max)
                        )
                        // itemda.push(gr.average)
                        // glcolorfield=gr.color
                        return (
                          <></>
                          // <tr>

                          //   <td style={{ padding: '0 4rem' }}>{gr.min}</td>
                          //   <td style={{ padding: '0 4rem' }}>{gr.max}</td>
                          // </tr>
                        )
                      })}

                      <div style={{ width: '600px' }}>
                        <Chart
                          style={{
                            width: 500,
                          }}>
                          <ChartArea background={'gainsboro'} margin={20} />
                          <ChartValueAxis>
                            <ChartValueAxisItem min={0} max={5} />
                          </ChartValueAxis>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={itemca}
                              justified={true}></ChartCategoryAxisItem>
                          </ChartCategoryAxis>

                          <ChartSeries>
                            <ChartSeriesItem
                              type="bar"
                              data={itemda}
                              categoryField="name"
                              field="data"
                              alignItems="left"
                              // color={gcol}
                              colorField="colorvalue"
                              // visibleInLegendField={true}
                              min="1"
                              max="5">
                              <ChartSeriesLabels
                                visible={true}
                                color="#ffffff"
                                content="data"
                                position="left"
                                background="none"
                                font="bold 8px Arial, sans-serif"
                                step="1"></ChartSeriesLabels>
                            </ChartSeriesItem>
                          </ChartSeries>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={itemca} />
                          </ChartCategoryAxis>
                        </Chart>
                      </div>
                      {/* </table> */}
                      <Divider />
                      <br />
                    </div>
                  )
                })}
              </div>
            )
          })}
          {allDataValues.map((spli, indexs) => {
            console.log('innn', indexs, spli)
            return (
              <div>
                {/* <div style={{ fontWeight: 'bold' }}> */}
                <p>
                  <p
                    style={{ fontSize: '12px' }}
                    className={indexs != 0 ? 'page-break' : ''}>
                    {spli.strCompNm + ':' + ' ' + spli.strCapNm}
                  </p>
                </p>
                {/* </div> */}
                {Object.entries(spli.inderClone).map((da, index) => {
                  cno++
                  let itemda = []
                  let itemca = []
                  let itemcol = []
                  let glcolorfield = ''
                  console.log('da1length', da[1].length)
                  return (
                    <div>
                      {index == 2 ? <p className="page-break"></p> : <></>}
                      {index == 4 ? <p className="page-break"></p> : <></>}
                      {index == 6 ? <p className="page-break"></p> : <></>}
                      {index == 8 ? <p className="page-break"></p> : <></>}
                      {index == 10 ? <p className="page-break"></p> : <></>}
                      {index == 12 ? <p className="page-break"></p> : <></>}
                      {index == 14 ? <p className="page-break"></p> : <></>}
                      {index == 16 ? <p className="page-break"></p> : <></>}
                      {index == 18 ? <p className="page-break"></p> : <></>}
                      {index == 20 ? <p className="page-break"></p> : <></>}
                      <div style={{ fontSize: '10px' }}>
                        {cno}. {da[0]}
                      </div>

                      {/* <table style={{ padding: '0 4rem' }}> */}
                      {/* <tr>
                            <td colSpan={3}></td>
                            <td>Lowest Rating</td>
                            <td>Highest Rating</td>
                          </tr> */}
                      {da[1].map((gr, i) => {
                        let widthSiz = 400
                        console.log('gr', gr)
                        let newSize = (gr.average / splitmax) * widthSiz
                        itemda.push(...itemda, {
                          name: gr.itemNorm.concat(' ').concat(gr.ratingCount),
                          // .concat(' ')
                          // .concat(gr.min)
                          // .concat(' ')
                          // .concat(gr.max),
                          data:
                            gr.ratingCount < 3 &&
                            gr.itemNorm !== 'Manager' &&
                            gr.itemNorm !== 'Self'
                              ? 'Not enough responses were provided to show data'
                              : gr.average,
                          colorvalue:
                            gr.itemNorm == 'Self' ? '#4caf50' : '#0062ff',
                        })

                        itemca.push(
                          gr.itemNorm.concat(' ').concat(gr.ratingCount)
                          // .concat(' ')
                          // .concat(gr.min)
                          // .concat(' ')
                          // .concat(gr.max)
                        )
                        // itemda.push(gr.average)
                        // glcolorfield=gr.color
                        return (
                          <></>
                          // <tr>

                          //   <td style={{ padding: '0 4rem' }}>{gr.max}</td>
                          // </tr>
                        )
                      })}

                      <div style={{ width: '600px' }}>
                        <Chart
                          style={{
                            width: 500,
                          }}>
                          <ChartArea background={'gainsboro'} margin={20} />
                          <ChartValueAxis>
                            <ChartValueAxisItem min={0} max={5} />
                          </ChartValueAxis>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                              categories={itemca}
                              justified={true}></ChartCategoryAxisItem>
                          </ChartCategoryAxis>

                          <ChartSeries>
                            <ChartSeriesItem
                              type="bar"
                              data={itemda}
                              categoryField="name"
                              field="data"
                              alignItems="left"
                              // color={gcol}
                              colorField="colorvalue"
                              // visibleInLegendField={true}
                              min="1"
                              max="5">
                              <ChartSeriesLabels
                                visible={true}
                                color="#ffffff"
                                content="data"
                                position="left"
                                background="none"
                                font="bold 8px Arial, sans-serif"
                                step="1"></ChartSeriesLabels>
                            </ChartSeriesItem>
                          </ChartSeries>
                          <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={itemca} />
                          </ChartCategoryAxis>
                        </Chart>
                      </div>
                      {/* </table> */}
                      <Divider />
                      <br />
                    </div>
                  )
                })}
              </div>
            )
          })}
        </Grid>
      </Grid>
      <Grid>
        <Grid>
          <Typography style={{ fontSize: '16px' }} className="page-break">
            Open-Ended Responses
          </Typography>
          <Typography style={{ fontSize: '10px' }}>
            The Open-Ended Responses are the written responses to open questions
            (e.g. that do not utilize a scale rating). These open-ended
            questions were selected / created by the project administrator and
            were presented to all respondents. Answering the open-ended
            questions is not mandatory – you may notice the number of responses
            is less than the total number of respondents. Answers provided by
            yourself and your manager are labeled, while all other answers are
            randomized to protect anonymity.
          </Typography>
          <br />
          {addOpenEndedData
            .map((val) => {
              let openEndothers = []
              let openEnd = []

              val.addOpenEndedDataSub.forEach((f) => {
                if (['Self', 'Manager'].includes(f.zRelationShip)) {
                  openEnd.push(f)
                } else {
                  openEndothers.push(f)
                }
              })

              return { ...val, openEnd, openEndothers }
            })
            .map((addopen, index) => {
              return (
                <Grid>
                  <Divider />

                  <Typography style={{ fontSize: '11px' }}>
                    {index + 1}. {addopen.zQuestionText}
                  </Typography>
                  <br />
                  {addopen.openEnd.map((sub) => {
                    return (
                      <Grid style={{ padding: '0 40px' }}>
                        <bold>
                          <Typography style={{ fontSize: '11px' }}>
                            {sub.zRelationShip}:
                          </Typography>
                        </bold>
                        <Typography style={{ fontSize: '10px' }}>
                          {sub.zAns}
                        </Typography>
                        <br />
                      </Grid>
                    )
                  })}
                  <Grid style={{ padding: '0 40px' }}>
                    <Typography style={{ fontSize: '11px' }}>
                      Others:
                    </Typography>
                    {addopen.openEndothers.map((sub) => {
                      return (
                        <>
                          <Typography style={{ fontSize: '10px' }}>
                            {sub.zAns}
                          </Typography>
                          <br />
                        </>
                      )
                    })}
                    <br />
                  </Grid>
                </Grid>
              )
            })}
        </Grid>
      </Grid>
    </div>
  )
}

const BarGraph = (props) => {
  const { value = 30, color = 'fffff', precent = 30, chartWidth = 300 } = props
  return (
    <svg
      class="chart"
      style={{ width: chartWidth, zIndex: 9999, height: '100px !Important' }}
      height="17">
      <g transform="translate(0,0)">
        <rect style={{ fill: color }} width={precent} height="17"></rect>
        <text
          style={{
            fill: 'white',
            font: '15px sans-serif',
            fontWeight: '800',
            'text-anchor': 'end',
          }}
          x="47"
          y="9.5"
          dy=".35em">
          {parseFloat(value)}
        </text>
      </g>
    </svg>
  )
}
