import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
} from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import logo from '../../../../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../../../../common/CustomBreadcrumbs'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import CustomButton from '../../../../../common/CustomButton'
import TaskDetails from './TaskDetails'
import moment from 'moment'
import {
  PROJECT_DETAILS_PATH,
  PROJECT_EDIT_PATH,
  TASK_VIEW_PATH,
  TEAMENDUSER,
} from '../../../../../../helpers/constants/RoutePaths'
import {
  GET_PROJECT,
  GET_TASK_LIST,
  UPDATE_TASK_STATUS,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import {
  authHeader,
  getUserId,
  getUserRoleId,
} from '../../../../../common/CustomFunction'
import {
  CLOSE_SURVEY_GET_RESULTS,
  COMPLETE_THINKWISE_PROJECT_SETUP,
  RESPOND_SURVEY_ITEMS,
  COMPLETE_TEAM_PERFORMANCE_SURVEY,
  NEW_RESPOND_SURVEY_ITEMS,
} from '../../../../../../helpers/constants/TaskTypes'
import { useSelector } from 'react-redux'
import {
  getComparator,
  stableSort,
} from '../../../../../../function/HelperFunction'
const useStyles = makeStyles((theme) => ({
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.btnPrimary,
    '& .MuiButton-label': {
      paddingLeft: '1.2rem',
      paddingRight: '1.2rem',
      width: '0% ',
    },
  },
  inprogressBtn: {
    padding: '8px 22px',
    backgroundColor: theme.btnPrimary,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  tablecontainer: {
    marginTop: '1rem',
    // maxHeight: 440,
    minHeight: '50vh',
    //overflowX: "hidden",
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  circularloader: {
    display: 'flex',
    justifyContent: 'center',
    width: '300%',
    paddingTop: '20%',
  },
  heading: {
    // fontWeight: 'bold',
    color: '#000000DE !important',
    fontSize: '24px',
  },
  subheading: {
    fontFamily: 'Nunito-Regular !important',
  },
  inactive: {
    fontFamily: 'Nunito-Regular !important',
    color: '#1565C0 !important',
  },
}))

export default function TaskIndex() {
  const [projectTasksList, setProjectTasksList] = useState([])
  //Table Columns
  const params = useParams()
  const tableColumns = [
    { id: 'zProjectName', title: 'Project Name', sort: true },
    { id: 'zSubject', title: 'Task', sort: true },
    { id: 'zDueDate', title: 'Due Date', sort: true },
    { id: 'zTaskStatusName', title: 'Status', sort: true },
    { id: 'zTaskStatusName', title: '', sort: true },
  ]
  const classes = useStyles()
  const tableHeadProps = { tableColumns }
  const [loading, setLoading] = useState(false)

  async function getTaskList() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TASK_LIST,
      params: {
        UserId: getUserId(),
        ProjectTypeID: getUserRoleId() == 0 ? null : 2,
      },
    }
    setLoading(true)
    try {
      const response = await API(getconfig)
      const { data } = response

      if (response) {
        setProjectTasksList(data.projectTaskList)
        console.log('respone', data)
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getTaskList()
    // getTask()
  }, [])
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('zProjectName')
  const handleRequestSort = (event, property) => {
    console.log(property)
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleClick = async (e) => {
    const { pid, uid } = e.currentTarget.dataset
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: UPDATE_TASK_STATUS,
      params: {
        UserID: getUserId(),
        ProjectID: pid,
        TaskUserID: uid,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      console.log(data)
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <Grid item sm={12} lg={12} md={12}>
      <Grid container spacing={1} item sm={12} lg={12} md={12}>
        {/* <Grid item xs={6} lg={6} md={6}>
          <Typography className={classes.heading} variant="h6" gutterBottom>
            Tasks
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography
              style={{ color: '#1565C0 !important' }}
              className={classes.inactive}
              color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.subheading} color="textPrimary">
              Tasks
            </Typography>
          </Breadcrumbs>
        </Grid> */}
        {/* <Grid item xs={6} lg={6} md={6}> */}
        {/* <div
            className={classes.sideHead}
            style={{ display: 'flex', justifyContent: 'right' }}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={12} lg={12} md={12}>
          <Divider />
        </Grid> */}
      </Grid>

      <Grid item xs={12} style={{ marginTop: '1rem' }}>
        <div>
          <Paper className={classes.paper}>
            {/* Table tools */}
            <Toolbar className={classes.root}>
              <Typography
                className={classes.tblHead}
                variant="h6"
                id="tableTitle"
                component="div">
                Tasks
              </Typography>

              {/* <Tooltip title="More">
                <IconButton
                  className={classes.exportBtn}
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // onClick={handleClick}
                >
                  {/* <MoreVertIcon /> 
                </IconButton>
              </Tooltip> */}
              {/* <Menu
                id="simple-menu"
                // anchorEl={anchorEl}
                keepMounted
                // open={Boolean(anchorEl)}
                // onClose={handleClose}
              >
                <MenuItem>Option</MenuItem>
              </Menu> */}
            </Toolbar>
            <Divider />

            {/* Table  */}
            <TableContainer
              component={Paper}
              className={classes.tablecontainer}>
              <Table stickyHeader size="small" className={classes.customTable}>
                {/* Table Header */}
                <CustomTableHeader
                  {...tableHeadProps}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {/* Table Header */}

                {/* Table Body */}
                {loading && (
                  <TableBody>
                    <div className={classes.circularloader}>
                      <CircularProgress size={28} />
                    </div>
                  </TableBody>
                )}
                {!loading && (
                  <TableBody>
                    {projectTasksList.length > 0 &&
                      stableSort(
                        projectTasksList,
                        getComparator(order, orderBy)
                      ).map((record, index) => {
                        const pushPath = {
                          [COMPLETE_THINKWISE_PROJECT_SETUP]: {
                            pathname: `${PROJECT_EDIT_PATH}/${record.zProjectID}`,
                            search: `?taskId=${record.zTaskUserID}`,
                          },
                          [CLOSE_SURVEY_GET_RESULTS]: {
                            pathname: `projects/view/${record.zProjectID}`,
                            search: `?taskId=${record.zTaskUserID}`,
                          },
                          [NEW_RESPOND_SURVEY_ITEMS]: {
                            pathname: `${TASK_VIEW_PATH}/${record.zProjectID}`,
                            search: `?taskId=${record.zTaskUserID}`,
                          },
                          [COMPLETE_TEAM_PERFORMANCE_SURVEY]: {
                            pathname: `${TEAMENDUSER}/${record.zProjectID}/${record.zTaskUserID}`,
                          },
                        }
                        return (
                          <>
                            <TableRow key={index}>
                              <TableCell className={classes.subheading}>
                                {record.zProjectName}
                              </TableCell>
                              <TableCell className={classes.subheading}>
                                {record.zSubject}
                              </TableCell>
                              <TableCell className={classes.subheading}>
                                {moment(record.zDueDate?.slice(0, 10)).format(
                                  'MM/DD/YYYY'
                                )}
                              </TableCell>
                              <TableCell className={classes.subheading}>
                                {record.zTaskStatusName}
                              </TableCell>
                              <TableCell className={classes.subheading}>
                                <Link
                                  to={{ ...pushPath[record.zSubject] }}
                                  data-pid={record.zProjectID}
                                  data-uid={record.zTaskUserID}
                                  onClick={handleClick}
                                  className={classes.linkStyle}>
                                  {' '}
                                  {record.zTaskStatusName === 'Not Started' ? (
                                    <div style={{ width: '30%' }}>
                                      <CustomButton
                                        className={classes.dateBtn}
                                        type="button">
                                        Start
                                      </CustomButton>
                                    </div>
                                  ) : (
                                    <CustomButton
                                      className={classes.inprogressBtn}
                                      type="button">
                                      Continue
                                    </CustomButton>
                                  )}
                                </Link>
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      })}
                  </TableBody>
                )}
                {/* Table Body */}
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </Grid>
    </Grid>
  )
}
