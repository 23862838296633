import * as types from '../action/ActionType'
export default function CustomSubCategoryList(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.SAVE_CUSTOM_SUB_CATEGORY_LIST:
      return (state = payload)
    default:
      return state
  }
}
