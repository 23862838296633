import { combineReducers } from 'redux'
import ThinkwiseSelectcategory from './ThinkwiseSelectcategory'
import ThinkwiseGroupcategoryReducer from './ThinkwiseGroupcategoryReducer'
import ThinkwiseProjectId from './ThinkwiseProjectId'
import ThinkwisecategoryList from './ThinkwisecategoryList'
import ThinkwisesubcategoryReducer from './ThinkwisesubcategoryReducer'
import ThinkwiseCustomselectedlist from './ThinkwiseCustomselectedlist'
import CustomCategoryList from './CustomCategoryList'
import CustomSubCategoryList from './CustomSubCategoryList'
import CustomGroupcategoryReducer from './CustomGroupCategoryReducer'
import CheckedItemList from './CheckedItemList'
import CustomSubCategoryItems from './CustomSubCategoryItems'
import CustomCategoryItems from './CustomCategoryItems'
import CurrentStep from './CurrentStep'
import CurrentProjectInfo from './CurrentProjectInfo'
import TaskList from './TaskList'
import RosterReducer from './RosterReducer'
import Selectedcategory from './Selectedcategory'
import Subcategoryselecteditem from './Subcategoryselecteditem'
import SelectedSubitems from './SelectedSubitems'
import ThinkwiseselectedItems from './Thinkwisenew/ThinkwiseselectedItems'
import Dragableselecteditem from './Dragableselecteditem'
import AllcheckedItems from './AllcheckedItems'
import OpenendChecked from './OpenendChecked'
import NewthinkwiseReducer from './NewthinkwiseReducer'
import NewthinkwiseSelecteditemReducer from './NewthinkwiseSelecteditemReducer'
import ThinkwiseSelecteditemReducer from './ThinkwiseSelecteditemReducer'
import NewCustomItemsReducer from './NewCustomItemsReducer'
import NewSelectedCustomitemReducer from './NewSelectedCustomitemReducer'
import NewSelectedItemsIdReducer from './NewSelectedItemsIdReducer'
import NewselectedThinkwiseItems from './NewselectedThinkwiseItems'
import NewAllselectedItem from './NewAllselectedItem'
import Teamsurveycurrentstepreducer from './Teamsurveycurrentstepreducer'
import Teamtempcurrentstepreducer from './Teamtempcurrentstepreducer'
import AccountsUsersReducer from './AccountsUsers'
import NewOrgSummary from "./NewOrgSummary"
import NewCustomChecked from "./NewCustomCheckedList"
import SelectedItemReducer from "./SelectedItemReducer"
import DueDate360Reducer from "./DueDate360Reducer"
import CompentencyDragReducer from "./CompetencyDragDrap"
import SelectedDragAndDrop from "./SelectedDragAndDrop"
const RootReducer = combineReducers({
  ThinkwiseSelectcategory,
  ThinkwisesubcategoryReducer,
  ThinkwiseProjectId,
  ThinkwisecategoryList,
  ThinkwiseGroupcategoryReducer,
  ThinkwiseCustomselectedlist,
  CustomCategoryList,
  CustomSubCategoryList,
  CustomGroupcategoryReducer,
  CheckedItemList,
  CustomSubCategoryItems,
  CustomCategoryItems,
  CurrentStep,
  CurrentProjectInfo,
  TaskList,
  RosterReducer,
  Selectedcategory,
  Subcategoryselecteditem,
  SelectedSubitems,
  ThinkwiseselectedItems,
  Dragableselecteditem,
  AllcheckedItems,
  OpenendChecked,
  NewthinkwiseReducer,
  NewthinkwiseSelecteditemReducer,
  ThinkwiseSelecteditemReducer,
  NewCustomItemsReducer,
  NewSelectedCustomitemReducer,
  NewSelectedItemsIdReducer,
  NewselectedThinkwiseItems,
  NewAllselectedItem,
  Teamsurveycurrentstepreducer,
  Teamtempcurrentstepreducer,
  AccountsUsersReducer,
  NewOrgSummary,
  NewCustomChecked,
  SelectedItemReducer,
  DueDate360Reducer,
  CompentencyDragReducer,
  SelectedDragAndDrop
})

export default RootReducer
