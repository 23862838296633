import * as types from '../action/ActionType'
export default function CurrentStep(state = 0, action) {
  const { type, payload } = action
  switch (type) {
    case types.SAVE_CURRENT_STEP:
      return (state = payload)
    default:
      return state
  }
}
