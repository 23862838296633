import {
  Box,
  Divider,
  Typography,
  Grid,
  Tabs,
  Tab,
  Breadcrumbs,
} from '@material-ui/core'
import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import logo from '../../assets/images/company_logo.jpg'
import CustomButton from '../common/CustomButton'

import { useHistory, useParams } from 'react-router'

import { CustomMessage, CustomPageLoading } from '../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import Tsinformation from './Tsinformation'
import { useDispatch, useSelector } from 'react-redux'
import Tsopenend from './Tsopenend'
import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../common/CustomFunction'
import { API } from '../../helpers/API'
import {
  GET_PROJECT_URL,
  TEAM_SURVEY_GETPROJECTDETAILS,
} from '../../helpers/constants/ApiRoutes'
import moment from 'moment'
import {
  TEAMSURVEY_CURRENTSTEP,
  TEAMSURVEY_TEMP_CURRENTSTEP,
} from '../../redux/action/ActionType'
import Tsinvitaion from './Tsinvitaion'
import Tslaunchproject from './Tslaunchproject'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  txtBlock: {
    textAlign: 'center',
  },
  boxBorder: {
    borderRight: '2px solid #eee',
  },
  txtTitle: {
    fontSize: '19px',
  },
  txtDesc: {
    fontSize: '11px',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '90%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  typograpyhtstyle: {
    fontFamily: 'Nunito-SemiBold !important',
    color: ' #000000DE !important',
  },
  tab: {
    fontFamily: 'Roboto-Medium !important',
    textAlign: ' center',
    letterSpacing: ' 1.16px',
    color: ' #000000DE',
    textTransform: ' uppercase',
    opacity: ' 1',
  },

  inactiveTab: {
    textAlign: 'center',
    letterSpacing: '1.16px',
    color: '#0000008A',
    textTransform: 'uppercase',
    opacity: 1,
    fontFamily: 'Roboto-Medium !important',
  },
  selected: {
    color: '#1565C0 !important',
    fontFamily: 'Nunito-Regular !important',
    letterSpacing: '0.08px',
  },
  unselected: {
    textAlign: 'left',
    letterSpacing: '0.08px',
    color: '#0000008A',
    opacity: '1',
  },
}))

const CusTab = withStyles((theme) => ({
  indicator: {
    fontFamily: 'Roboto-Medium !important',

    backgroundColor: theme.btnPrimary,
  },
  '& .Mui-selected': {
    backgroundColor: 'red !important',
    color: 'blue !important',
  },
}))(Tabs)
let formInitila = {
  Name: '',
  Description: '',
  DueDate: '',
  OwnerID: getUserId(),
  OrganizerID: getUserId(),
  LUID: getUserId(),
}

export default function Teamsurveyindex(props) {
  const history = useHistory()
  const classes = useStyles()
  let teamsurveyFormred = useRef(null)
  const currentStep = useSelector((state) => state.Teamsurveycurrentstepreducer)
  const tempTAbchange = useSelector((state) => state.Teamtempcurrentstepreducer)
  const dispatch = useDispatch()
  const [ProjectInfo, setProjectInfo] = useState(formInitila)
  const { tsprojectid } = useParams()
  // const projectId = urlParams.get('projectId')
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setloading] = useState(false)

  //CurrentProjectInfo
  const handleTabChange = async (event, newValue) => {
    // if (newValue == tempTAbchange) {
    //   return null
    // }
    document?.getElementById('teamsurveyFormred')?.click()
    dispatch({ type: TEAMSURVEY_TEMP_CURRENTSTEP, payload: newValue })
    if (tempTAbchange == 3) {
      dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: newValue })
    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const TabPanel = useCallback((props) => {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }, []) // function TabPanel(props) {
  //   const { children, value, index, ...other } = props
  //   console.log('paretntabpane')
  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}>
  //       {value === index && (
  //         <Box>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   )
  // }

  const getProjectInformation = async () => {
    setloading(true)

    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_SURVEY_GETPROJECTDETAILS,
      params: {
        ProjectID: parseInt(tsprojectid),
      },
    }
    try {
      const response = await API(getConfig)
      if (response) {
        const {
          zName,
          zDescription,
          zDueDateFormat,
          zOwnerID,
        } = response.data.projectDetails[0]
        setProjectInfo((val) => ({
          ...val,
          Name: zName,
          OwnerID: zOwnerID,
          Description: zDescription,
          DueDate: zDueDateFormat,
        }))
      }
    } catch (err) {
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    console.log(currentStep, tempTAbchange, 'tempTAbchangeuseecect1')

    if (tsprojectid) getProjectInformation()
  }, [tsprojectid])

  useEffect(() => {
    console.log(currentStep, tempTAbchange, 'tempTAbchangeuseeffect2')

    // dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: 0 })
  }, [])
  console.log(currentStep, tempTAbchange, 'tempTAbchange')

  return (
    <div>
      <Grid container spacing={1}>
        {loading && <CustomPageLoading />}
        {/* <Grid item xs={6} lg={6} md={6}>
          <Typography
            variant="h6"
            className={classes.typograpyhtstyle}
            // style={{ fontWeight: 'bold' }}
            gutterBottom>
            Team Survey Setup
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography className={classes.selected} color="textPrimary">
              Home
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              Team Survey
            </Typography>
            <Typography className={classes.selected} color="textPrimary">
              Projects
            </Typography>
            <Typography className={classes.unselected} color="textPrimary">
              Setup
            </Typography>
          </Breadcrumbs>
        </Grid> */}
        {/* <Grid item xs={6} lg={6} md={6}> */}
        {/* <div
            className={classes.sideHead}
            style={{ display: 'flex', justifyContent: 'right' }}>
            <img src={logo} alt="logo" width="100" height="60" />
          </div> */}
        {/* </Grid> */}
        {/* <Grid item xs={12} sm={12} lg={12} md={12}>
          <Divider />
        </Grid> */}
        <>
          <Grid item xs={12} lg={8} md={8}>
            <CusTab
              value={currentStep}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              inkBarStyle={{ background: 'yellow' }}
              scrollButtons="auto">
              <Tab
                className={currentStep == 0 ? classes.tab : classes.inactiveTab}
                label="INFORMATION"
                name="INFORMATION"
                {...a11yProps(0)}
                inkBarStyle={{ background: 'yellow' }}
              />
              <Tab
                className={currentStep == 1 ? classes.tab : classes.inactiveTab}
                label="OPEN ENDED QUESTIONS "
                name="OPEN ENDED QUESTIONS "
                {...a11yProps(0)}
                inkBarStyle={{ background: 'yellow' }}
              />
              <Tab
                className={currentStep == 2 ? classes.tab : classes.inactiveTab}
                label="TEAM MEMBERS"
                name="TEAM MEMBERS"
                {...a11yProps(0)}
                inkBarStyle={{ background: 'yellow' }}
              />
              <Tab
                className={currentStep == 3 ? classes.tab : classes.inactiveTab}
                label="Launch"
                {...a11yProps(0)}
                inkBarStyle={{ background: 'yellow' }}
              />
            </CusTab>
          </Grid>
          <Grid item xs={4} className={classes.btnContainer}>
            {/* <CustomButton type="submit" colour="primaryButton" form="form_1">
              Save
            </CustomButton> */}
            {/* <CustomButton colour="secondaryButton">Cancel </CustomButton> */}
            {currentStep != 3 && (
              <CustomButton
                type="submit"
                ref={(e) => (teamsurveyFormred.current = e)}
                colour="primaryButton"
                id="teamsurveyFormred"
                form={`form_${currentStep + 1}`}>
                Next
              </CustomButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={currentStep} index={0}>
              <Tsinformation
                ProjectInfo={ProjectInfo}
                getProjectInformation={getProjectInformation}
              />
            </TabPanel>
            <TabPanel value={currentStep} index={1}>
              <Tsopenend />
            </TabPanel>
            <TabPanel value={currentStep} index={2}>
              <Tsinvitaion />
            </TabPanel>
            <TabPanel value={currentStep} index={3}>
              <Tslaunchproject projectname={ProjectInfo?.Name} />
            </TabPanel>
          </Grid>
        </>
      </Grid>
    </div>
  )
}
