import * as types from '../action/ActionType'
export default function SelectedSubitems(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.SELECTED_SUB_ITEMS:
      return (state = payload)
    default:
      return state
  }
}
