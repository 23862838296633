import * as types from '../action/ActionType'
import { CUSTOM_ITEM_, SUB_CUSTOM_ITEM_ } from '../action/Typeconstent'

export default function NewSelectedItemsIdReducer(state = {}, action) {
  const { type, payload = {} } = action
  const { itemid = '', categoryid = '', allId = [], subcategory = '' } = payload
  switch (type) {
    case types.NEW_CUSTOM_CATEGORY_ITEMS:
      let stateClone = { ...state }
      if (stateClone[CUSTOM_ITEM_ + categoryid]?.includes(itemid)) {
        stateClone[CUSTOM_ITEM_ + categoryid] = stateClone[
          CUSTOM_ITEM_ + categoryid
        ].filter((val) => val != itemid)
      } else {
        stateClone[CUSTOM_ITEM_ + categoryid] = [
          ...(stateClone[CUSTOM_ITEM_ + categoryid] || []),
          itemid,
        ]
      }
      if (stateClone[CUSTOM_ITEM_ + categoryid]?.length == 0) {
        delete stateClone[CUSTOM_ITEM_ + categoryid]
      }
      return (state = stateClone)
    case types.NEW_ALL_CUSTOM_CATEGORY_ITEMS:
      let colpyAllstate = { ...state }
      colpyAllstate[CUSTOM_ITEM_ + categoryid] = [...allId]
      if (colpyAllstate[CUSTOM_ITEM_ + categoryid]?.length == 0) {
        delete colpyAllstate[CUSTOM_ITEM_ + categoryid]
      }
      return (state = colpyAllstate)
    case types.NEW_SELCT_SUB_ITEMS:
      let CATEGORYNAME = CUSTOM_ITEM_ + categoryid
      let NAME = SUB_CUSTOM_ITEM_ + categoryid + '_' + subcategory
      let subCatItemclone = { ...state }
      if (subCatItemclone[CATEGORYNAME]?.[NAME]?.includes(itemid)) {
        subCatItemclone[CATEGORYNAME] = {
          ...(subCatItemclone[CATEGORYNAME] || {}),
        }

        subCatItemclone[CATEGORYNAME][NAME] = subCatItemclone[CATEGORYNAME][
          NAME
        ].filter((val) => val != itemid)
      } else {
        subCatItemclone[CATEGORYNAME] = {
          ...(subCatItemclone[CATEGORYNAME] || {}),
        }

        subCatItemclone[CATEGORYNAME][NAME] = [
          ...(subCatItemclone[CATEGORYNAME][NAME] || []),
          itemid,
        ]
      }
      if (subCatItemclone[CATEGORYNAME][NAME].length == 0) {
        delete subCatItemclone[CATEGORYNAME][NAME]
      }

      if (Object.keys(subCatItemclone[CATEGORYNAME]).length == 0) {
        delete subCatItemclone[CATEGORYNAME]
      }

      return (state = subCatItemclone)
    case types.NEW_CUSTOM_ITEM_ALL:
      let ALLCATEGORYNAME = CUSTOM_ITEM_ + categoryid
      let ALLNAME = SUB_CUSTOM_ITEM_ + categoryid + '_' + subcategory
      let colpyAllCustomstate = { ...state }

      colpyAllCustomstate[ALLCATEGORYNAME] = {
        ...(colpyAllCustomstate[ALLCATEGORYNAME] || {}),
      }
      colpyAllCustomstate[ALLCATEGORYNAME][ALLNAME] = [...allId]
      if (colpyAllCustomstate[ALLCATEGORYNAME][ALLNAME]?.length == 0) {
        delete colpyAllCustomstate[ALLCATEGORYNAME][ALLNAME]
      }
      return (state = colpyAllCustomstate)
    case types.NEW_CUSTOM_DELETE_ITEMS:
      let DELETECATEGORYNAME = CUSTOM_ITEM_ + categoryid
      let deleteState = { ...state }
      delete deleteState[DELETECATEGORYNAME]
      return deleteState
    case types.NEW_API_CUSTOMITEM:
      return (state = payload)
    default:
      return state
  }
}
