import {
  DialogContent,
  MenuItem,
  Select,
  FormControl,
  Typography,
  Divider,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { IconButton, Box } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import { API } from '../../../../../../helpers/API'
import {
  getUserId,
  getAlertToast,
  authHeader,
} from '../../../../../common/CustomFunction'
import {
  ADD_PARTICIPANT_USER,
  ADD_PARTICIPANT,
  SAVE_RATER,
  ADD_ROSTER,
  SAVE_MANAGE_ROSTER,
  SAVE_ROSTER_RATER_EXEMAIL,
  ADD_PARTICIPANT_OR_RATER_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import Swal from 'sweetalert2'
import { CustomPageLoading } from '../../../../../../function/HelperFunction'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: '18px !important',
    color: '#000 !important',
  },
  header: {
    display: 'flex',
    margin: '1rem',
  },
  subject: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
}))

export default function EmailInformation(props) {
  console.log(props.projectDetails)
  const classes = useStyles()
  const { submit } = props
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const { open, handleReset, projectID, emailStatusInfomation } = props
  console.log(
    emailStatusInfomation,
    'emailStatusInfomationemailStatusInfomation'
  )
  //state
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    rater: '',
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => console.log(values, 'values'),
    //  handleAddParticipantUser(values),
  })

  // const { zEmail , zsubject} = emailStatusInfomation
  //    console.log(zEmail,zsubject,'zEmail,zsubject');
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="400">
        {emailStatusInfomation?.map((item, index) => {
          console.log('listttttttttttt')
          const { zEmail, zsubject, zEvent } = item
          return (
            <>
              {index == 0 ? (
                <>
                  <DialogTitle id="simple-dialog-title" onClose={handleReset}>
                    Email Information
                    <IconButton
                      aria-label="close"
                      className={classes.closeButton}
                      onClick={handleReset}>
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent dividers>
                    <Grid style={{ paddingBottom: '1rem' }}>
                      <Grid>
                        <Grid style={{ padding: '1rem' }}>
                          <Typography className={classes.heading}>
                            Details
                          </Typography>
                        </Grid>
                        <Divider />
                        <Grid className={classes.header}>
                          <Typography className={classes.heading}>
                            To:
                          </Typography>
                          <Typography
                            className={classes.subject}
                            style={{ paddingLeft: '5.5rem' }}>
                            {zEmail}
                          </Typography>
                        </Grid>
                        <Divider />
                        <Grid className={classes.header}>
                          <Typography className={classes.heading}>
                            From:
                          </Typography>
                          <Typography
                            className={classes.subject}
                            style={{ paddingLeft: '4rem' }}>
                            support@cantoo.us
                          </Typography>
                        </Grid>
                        <Divider />
                        <Grid className={classes.header}>
                          <Typography className={classes.heading}>
                            Subject:
                          </Typography>
                          <Typography
                            className={classes.subject}
                            style={{ paddingLeft: '3rem' }}>
                            {zsubject}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      {/* <Grid style={{ marginTop: '1rem' }}>
                    <Grid style={{ padding: '1rem' }}>
                      <Typography className={classes.heading}>Event History</Typography>
                    </Grid>
                    <Divider/>
                    <Grid className={classes.header}>
                      <Typography>Processed</Typography>
                      <Typography className={classes.subject} style={{paddingLeft:'5rem'}}>
                        2023/09/25 9:11pm UTC+00:00
                      </Typography>
                    </Grid>
                    <Divider/>
                    <Grid className={classes.header}>
                      <Typography>Delivered</Typography>
                      <Typography className={classes.subject} style={{paddingLeft:'5rem'}}>
                        2023/09/25 9:11pm UTC+00:00
                      </Typography>
                    </Grid>
                    <Divider/> */}
                      {/* </Grid> */}
                    </Grid>
                  </DialogContent>
                </>
              ) : (
                ''
              )}
            </>
          )
        })}
        {/* { emailStatusInfomation.length <= 0 ?  
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
                 No Data Found
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleReset}>
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>:""} */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
