import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import SuccessTick from '../../../../assets/images/thanksforsubmit.png'
export default function ThanksForSubmit() {
  return (
    <div style = {{ paddingTop: '0px', display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop:'8rem',background: 'white'}}>
      <Grid>
        <Grid item sm={12} lg={12} md={12}>
            <Grid style={{ padding: '1rem 3rem' }}>
              <Grid>
                <Grid>
                    <img src={SuccessTick} style={{height:'14rem'}}></img>
                  <Typography sx={{color:'black',textAlign:'center',fontSize:'24px'}}>
                  Thanks for submitting!
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
       
        </Grid>
      </Grid>
    </div>
  )
}
