import { DialogContent, MenuItem, Select } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { API } from '../../../../../../helpers/API'
import {
  ADD_ORDER_URL,
  ORDER_TYPES_BY_PARTNER_ID,
  UPDATE_ORDER_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import CustomButton from '../../../../../common/CustomButton'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogWidth: {
    maxWidth: '700px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

//Schema
const validationSchema = Yup.object({
  orderNumber: Yup.string()
    .trim()
    .required('Order Number is required'),
  orderName: Yup.string()
    .trim()
    .required('Order Name is required'),
  product: Yup.string()
    .trim()
    .required('Product Type is required'),
  quantity: Yup.string()
    .trim()
    .required('Quantity is required'),
})

export default function AddEditOrder(props) {
  const classes = useStyles()
  const [orderTypes, setOrderTypes] = useState([])

  const {
    open = '',
    handleReset,
    title = '',
    partnerId = '',
    orgId = '',
    orderInfo = '',
  } = props

  const getOrderTypeList = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: ORDER_TYPES_BY_PARTNER_ID,
      params: {
        PartnerID: partnerId,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        const { OrderType } = response.data
        setOrderTypes(OrderType)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getOrderTypeList()
  }, [])

  //state
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    orderNumber: orderInfo ? orderInfo.zcppInvoice : '',
    orderName: orderInfo ? orderInfo.zName : '',
    quantity: orderInfo ? orderInfo.zNumberOfLicenses : '',
    description: orderInfo ? orderInfo.zDescription : '',
    product: orderInfo ? orderInfo.zOrderTypeID : '',
    city: orderInfo ? orderInfo.zCity : '',
  }

  const handleAdd = async (formData) => {
    const { orderName, product, quantity, description, orderNumber } = formData
    var year = new Date().getFullYear()
    var month = new Date().getMonth()
    var day = new Date().getDate()
    var expiryDate = new Date(year + 10, month, day)
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_ORDER_URL,
      data: {
        organizationID: orgId,
        name: orderName,
        orderID: orderInfo.zOrderID,
        description: description,
        orderTypeID: product,
        numberOfLicenses: quantity,
        startDate: new Date().toISOString(),
        expirationDate: expiryDate.toISOString(),
        contactEmail: '',
        firstName: '',
        lastName: '',
        cppInvoice: orderNumber,
        createdByID: getUserId(),
        transID: '',
        bundleName: '',
        bundleQty: 0,
      },
    }
    const response = await API(config)
    if (response.status) {
      await handleReset()
    }
  }

  const handleUpdate = async (formData) => {
    const { orderName, product, quantity, description, orderNumber } = formData
    const config = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_ORDER_URL,
      data: {
        userID: getUserId(),
        orderID: orderInfo.zOrderID,
        name: orderName,
        description: description,
        numberOfLicenses: quantity,
        orderTypeID: product,
        expirationDate: orderInfo ? orderInfo.zExpirationDate_Date : '',
        contactEmail: '',
        firstName: '',
        lastName: '',
        cppInvoice: orderNumber,
        luid: getUserId(),
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      await handleReset()
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) =>
      title === 'Edit Order' ? handleUpdate(values) : handleAdd(values),
  })

  const {
    orderName = '',
    description = '',
    city = '',
    product = '',
    quantity = '',
    orderNumber = '',
  } = values
  return (
    <div>
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="sm">
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={8}>
                <TextField
                  id="name-helper-text"
                  label="Order Number"
                  value={orderNumber}
                  onChange={handleChange}
                  fullWidth
                  name="orderNumber"
                  error={errors.orderNumber && touched.orderNumber}
                  helperText={
                    errors.orderNumber && touched.orderNumber
                      ? errors.orderNumber
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="name-helper-text"
                  label="Order Name"
                  value={orderName}
                  onChange={handleChange}
                  fullWidth
                  name="orderName"
                  error={errors.orderName && touched.orderName}
                  helperText={
                    errors.orderName && touched.orderName
                      ? errors.orderName
                      : ''
                  }
                />
              </Grid>
              <Grid xs={4}></Grid>
              <Grid item xs={8}>
                <TextField
                  id="name-helper-text"
                  label="Description"
                  value={description}
                  onChange={handleChange}
                  fullWidth
                  name="description"
                  error={errors.description && touched.description}
                  helperText={
                    errors.description && touched.description
                      ? errors.description
                      : ''
                  }
                />
              </Grid>
              <Grid xs={4}></Grid>
              <Grid item xs={8}>
                {/* <TextField
                  id="name-helper-text"
                  label="City"
                  value={city}
                  onChange={handleChange}
                  fullWidth
                  name="city"
                  error={errors.city && touched.city}
                  helperText={errors.city && touched.city ? errors.city : ''}
                /> */}
              </Grid>
              <Grid xs={4}></Grid>
              <Grid item xs={8}>
                <InputLabel id="product-label">Products</InputLabel>
                <Select
                  labelId="product-label"
                  label="Products"
                  value={product}
                  name="product"
                  onChange={handleChange}
                  fullWidth>
                  {orderTypes.map((orderType) => (
                    <MenuItem value={orderType.zOrderTypeID}>
                      {orderType.zName}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid xs={4}></Grid>
              <Grid item xs={8}>
                <TextField
                  id="name-helper-text"
                  label="Quantity"
                  value={quantity}
                  onChange={handleChange}
                  fullWidth
                  name="quantity"
                  error={errors.quantity && touched.quantity}
                  helperText={
                    errors.quantity && touched.quantity ? errors.quantity : ''
                  }
                />
              </Grid>
              <Grid xs={4}></Grid>
              <Grid xs={8}></Grid>
              <Grid item xs={4}>
                <div className={classes.btnContainer}>
                  <CustomButton
                    colour="primaryButton"
                    type="submit"
                    onClick={handleSubmit}>
                    {title == 'Add Partner Admin' ? 'Send Invite' : 'Save'}
                  </CustomButton>
                </div>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
