import {
  Box,
  Dialog,
  DialogTitle,
  FormControl,
  TextField,
  makeStyles,
} from '@material-ui/core'

import React, { memo, useState } from 'react'
import CustomButton from '../../../../../common/CustomButton'
import { Stack } from '@mui/material'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
    '@media (max-width: 768px)': {
      height: 'auto !important',
    },
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',

    // or borderTop: '1px solid red'
  },
  tableRow: {
    height: 50,
  },
}))
export default function LinkPopup(props) {
  const classes = useStyles()

  const { open, handleClose, subject, setSubject, popFun, handleReset } = props
  const handleChange = (e) => {
    setSubject(e.target.value)
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.root}
      fullWidth
      maxWidth="sm">
      <DialogTitle id="simple-dialog-title" onClose={handleReset}>
        Insert hyperlink
      </DialogTitle>

      <FormControl>
        <TextField
          labelId="subject"
          id="subject"
          name="Link"
          value={subject}
          onChange={handleChange}
          fullWidth
          label="Link:"
        />
      </FormControl>
      <br />
      <Box style={{ marginLeft: '26rem' }}>
        <Stack direction="row" spacing={1}>
          <CustomButton
            colour="primaryButton"
            style={{ float: 'right' }}
            // type="submit"
            onClick={popFun}>
            Insert
          </CustomButton>
          <CustomButton
            colour="primaryButton"
            style={{ float: 'right' }}
            // type="submit"
            onClick={handleReset}>
            Cancel
          </CustomButton>
        </Stack>
      </Box>
      <br />
    </Dialog>
  )
}
