import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { API } from '../../helpers/API'
import {
  TEAM_ADD_TAST_USER,
  TEAM_GETURL,
} from '../../helpers/constants/ApiRoutes'
import { TEAMENDUSER } from '../../helpers/constants/RoutePaths'
import {
  ORGANIZATION_ADMIN,
  ORGANIZATION_LP_ADMIN,
  PARTNER_ADMIN,
  PARTNER_LP_ADMIN,
  PROJECT_ADMIN,
  LEARNING_PACK_ADMIN,
  THINKWISE_ADMIN,
} from '../../helpers/constants/UserAdminRole'

// Get Query Params by Name
export function getQueryParamsByName(name) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const queryParams = urlParams.get(name)
  return queryParams
}

export function PublicRoute({ component, ...rest }) {
  const token = localStorage.getItem('AUTH_TOKEN')
  console.log('publi')
  if (!token) return <Route {...rest} component={component} />
  else return <Redirect to={{ pathname: '/dashboard' }} />
}

const locationParams = (search) => {
  var searchParams = new URLSearchParams(search)
  let ob = {}
  searchParams.forEach(function(value, key) {
    ob[key] = value
  })
  return ob
}

export function PrivateRoute({ component, ...rest }) {
  const history = useHistory()
  const location = window.location.href

  var searchURL = locationParams(rest.location.search)
  const token = localStorage.getItem('AUTH_TOKEN')
  const [value, setValues] = useTeamtask(searchURL?.UrlID)

  if (token) {
    return <Route {...rest} component={component} />
  } else {
    if (rest.location.search) {
      let searChobj = {
        access: 'ref',
      }
      let Search = Object.entries(searchURL).map(
        (v) => (searChobj[v[0]] || v[0]) + '=' + v[1]
      )
      console.log(Search)
      return (
        <Redirect
          to={{
            pathname: '/signin',
            search: `?${Search.join('&')}`,
          }}
        />
      )
    } else {
      return <Redirect to={'/signin'} />
    }
  }
}

export function authHeader() {
  const token = localStorage.getItem('AUTH_TOKEN')
  return { Authorization: token }
}

export function getUserId() {
  const userId = localStorage.getItem('LOG_USER_ID')
  return userId
}
export function getAdminRoleId() {
  const adminRoleId = localStorage.getItem('ADMIN_ROLE_ID')
  return adminRoleId
}
export function getUserRoleId() {
  const userRoleId = localStorage.getItem('USER_ROLE_ID')
  return userRoleId
}
export function getUserAdminRole() {
  const userAdminRole = localStorage.getItem('USER_ADMIN_ROLE')
  return userAdminRole
}
export function getOrganizationId() {
  const organizationId = localStorage.getItem('LOG_ORGANIZATION_ID')
  return organizationId
}
export function getOrderId() {
  return localStorage.getItem('ORDER_ID')
}
export function getPartner() {
  return localStorage.getItem('PARTNER')
}

export function getFirstName() {
  const fname = localStorage.getItem('LOG_USER_FIRSTNAME')
  return fname
}
export function getLastName() {
  const lname = localStorage.getItem('LOG_USER_LASTNAME')
  return lname
}
export function getEmail() {
  const email = localStorage.getItem('LOG_EMAIL')
  return email
}
export function getDomain() {
  return localStorage.getItem('CANTOODOMAIN')
}

export function getOrganizationname() {
  return localStorage.getItem('ORGANIZATION_NAME')
}
// Admin Role  Functions
export function isThinkwiseAdmin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === THINKWISE_ADMIN ? true : false
}

export function isParterAdmin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === PARTNER_ADMIN ? true : false
}

export function isOrganizationAdmin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === ORGANIZATION_ADMIN ? true : false
}

export function isProjectAdmin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === PROJECT_ADMIN ? true : false
}

export function isLearningPackAdmin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === LEARNING_PACK_ADMIN ? true : false
}

export function isPartner_Lp_Admin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === PARTNER_LP_ADMIN ? true : false
}

export function isOrg_Lp_Admin() {
  const userAdminRole = getUserAdminRole()

  return userAdminRole && userAdminRole === ORGANIZATION_LP_ADMIN ? true : false
}

// Alert Functions
export const getAlertToast = (type = 'success', text = '', timer = 5000) => ({
  toast: true,
  position: 'bottom',
  titleText: text,
  type: type,
  showConfirmButton: false,
  timer: timer,
})

const useTeamtask = (UrlID) => {
  const [taskdata, setTaskdata] = useState('')
  const getTaskid = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: TEAM_GETURL,
      params: {
        UrlID: UrlID,
      },
    }
    const response = await API(config)
    if (response?.status === 200) {
      let splitedData = response.data?.data[0]?.zURL?.split('//')
      let userDmoin = splitedData[1]
      let userProjectId = splitedData.slice(-1)[0].split('=')[1]

      const addUserconfig = {
        method: 'GET',
        url: TEAM_ADD_TAST_USER,
        headers: authHeader(),

        params: {
          ProjectID: userProjectId,
          UserID: getUserId(),
        },
      }
      const adduserResponse = await API(addUserconfig)
      const { Project, TaskID } = adduserResponse.data
      setTaskdata(`${TEAMENDUSER}/${Project}/${TaskID}`)
    }
  }
  useEffect(() => {
    getTaskid()
  }, [])

  return [taskdata, setTaskdata]
}
