import { Chart } from 'chart.js'

export const generateChart = async (
  value,
  i,
  minMax,
  type,
  mirror = true,
  Axiex = 'y'
) => {
  const { label, data,clrdata } = value
  console.log(value)
  const { minimumvalue, maximumvalue } = minMax
  var canvas = document.createElement('canvas')
  canvas.id = `CursorLayer_${i}`
  // const chartType={single:{axycs}}
  canvas.width = 1000
  canvas.height = 700
  // canvas.width = '100% !important'
  // canvas.height = '40% !important'
  //   canvas.height = 400
  //   canvas.style.height = '100% !important'
  //   canvas.style.width = '20% !important'

  canvas.style.zIndex = 8
  canvas.style.position = 'relative'
  canvas.style.border = '1px solid'
  canvas.style.display = 'flex'
  canvas.style.justifyContent = 'center'

  var newData = document.getElementsByTagName('div')

  // console.log(document.getElementById('dia'))
  // document
  //   .getElementsByTagName('div')
  //   .namedItem('dia')
  //   .appendChild(canvas)
  //   console.log(document.getElementsByTagName('div'))
  let index = 0
  let canvasindex = 0
  for (let cell of newData) {
    if (cell.id == 'canvasgraph') {
      canvasindex = index
    }
    index++
  }
  var body = document.getElementsByTagName('div')[canvasindex]

  body.appendChild(canvas)

  let cursorLayer = document.getElementById(`CursorLayer_${i}`)

  var myChart = new Chart(cursorLayer.getContext('2d'), {
    type: 'bar',
    data: {
      labels: label,
      datasets: [
        {
          label: '',
          data: data,
          backgroundColor:[
            'rgba(255,99,132,0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      indexAxis: Axiex,
      plugins: {
        tooltip: {
          // Disable the on-canvas tooltip
          enabled: false,
        },
      },

      hover: {
        animationDuration: 0,
      },

      tooltips: {
        enabled: false,
      },
      animation: {
        duration: 0,
      },
      scales: {
        x: {
          // suggestedMin: minimumvalue,
          // suggestedMax: maximumvalue,
          min: minimumvalue,

          max: maximumvalue,

          title: {
            font: {
              size: 14,
              weight: 'bold',
            },
            color: 'black',
          },

          ticks: {
            stepSize: 1,
            mirror: mirror,
            font: {
              size: 50,
              weight: 'bold',
            },
            color: 'black',
          },
        },

        y: {
          min: minimumvalue,
          max: maximumvalue,

          title: {
            font: {
              size: 14,
              weight: 'bold',
            },
            color: 'white',
          },

          ticks: {
            mirror: true,
            stepSize: 1,

            beginAtZero: true,

            font: function(context) {
              var width = context.chart.width
              var size = Math.round(width / 32)
              return {
                weight: 'bold',
                size: size,
                
              }
            },
            color:'black',
          },
        },
      },
    },
  })
  return await delay(myChart, cursorLayer)
}

function delay(myChart, cursorLayer) {
  return new Promise(function(resolve) {
    setTimeout(() => {
      var a = document.createElement('a')
      resolve(myChart.toBase64Image())
      // a.href = myChart.toBase64Image()
      // a.download = 'my_file_name.png'
      // a.click()
      var el = cursorLayer
      el.remove()
    }, 1000)
  })
}

export const generatePrepostchart = async (
  value,
  i,
  minMax,
  type,
  Axiex = 'y'
) => {
  var canvas = document.createElement('canvas')
  canvas.id = `CursorLayer_${i}`
  canvas.width = 1000
  canvas.height = 600
  const { label, data,clldata } = value
  console.log("label data's",value)
  const { minimumvalue, maximumvalue } = minMax
  console.log(minMax)
  var newData = document.getElementsByTagName('div')
  let index = 0
  let canvasindex = 0
  for (let cell of newData) {
    if (cell.id == 'canvasgraph') {
      canvasindex = index
    }
    index++
  }
  var body = document.getElementsByTagName('div')[canvasindex]

  body.appendChild(canvas)

  let cursorLayer = document.getElementById(`CursorLayer_${i}`)
  const myChart = new Chart(cursorLayer.getContext('2d'), {
    type: 'bar',
    data: {
      labels: label,
      datasets: [
        {
          label: '',
          data: data,
          backgroundColor: clldata,
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      indexAxis: 'y',
      responsive: true,

      animation: {
        duration: 1500,
      },
      scales: {
        x: {
          max: maximumvalue,
          min: minimumvalue,
          ticks: {
            mirror: true,
            stepSize: 1,

            font: function(context) {
              var width = context.chart.width
              var size = Math.round(width / 32)
              return {
                weight: 'bold',
                size: size,
              }
            },
            color: 'black',
          },
        },
        y: {
          ticks: {
            mirror: false,
            stepSize: 1,

            font: function(context) {
              var width = context.chart.width
              var size = Math.round(width / 32)
              return {
                weight: 'bold',
                size: size,
              }
            },
            color: 'black',
          },
        },
      },
    },
  })

  return await delay(myChart, cursorLayer)
}


export const generatethreechart = async (
  value,
  i,
  minMax,
  type,
  Axiex = 'y'
) => {
  var canvas = document.createElement('canvas')
  canvas.id = `CursorLayer_${i}`
  canvas.width = 1000
  canvas.height = 600
  const { label, data,clldata } = value
  console.log("label data's",value)
  const { minimumvalue, maximumvalue } = minMax
  console.log(minMax)
  var newData = document.getElementsByTagName('div')
  let index = 0
  let canvasindex = 0
  for (let cell of newData) {
    if (cell.id == 'canvasgraph') {
      canvasindex = index
    }
    index++
  }
  var body = document.getElementsByTagName('div')[canvasindex]

  body.appendChild(canvas)

  let cursorLayer = document.getElementById(`CursorLayer_${i}`)
  const myChart = new Chart(cursorLayer.getContext('2d'), {
    type: 'bar',
    data: {
      labels: label,
      datasets: [
        {
          label: '',
          data: data,
          backgroundColor: clldata,
          borderColor: 'rgba(255,99,132,1)',
          borderWidth: 1,
        },
      ],
    },
    options: {
      indexAxis: 'y',
      responsive: true,

      animation: {
        duration: 1500,
      },
      scales: {
        x: {
          max: maximumvalue,
          min: minimumvalue,
          ticks: {
            mirror: true,
            stepSize: 1,

            font: function(context) {
              var width = context.chart.width
              var size = Math.round(width / 32)
              return {
                weight: 'bold',
                size: size,
              }
            },
            color: 'black',
          },
        },
        y: {
          ticks: {
            mirror: false,
            stepSize: 1,

            font: function(context) {
              var width = context.chart.width
              var size = Math.round(width / 32)
              return {
                weight: 'bold',
                size: size,
              }
            },
            color: 'black',
          },
        },
      },
    },
  })

  return await delay(myChart, cursorLayer)
}