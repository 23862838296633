import { React, createRef, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Workbook from 'react-excel-workbook'
import {
  Grid,
  ListItem,
  ListItemText,
  List,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from '@material-ui/core'
import CustomButton from '../../../../../common/CustomButton'
import DetailsIndividualReport from './DetailsIndividualReport'
import CsvDownloader from 'react-csv-downloader'
import {
  PROJECT_360_ITEM_REPORT,
  PROJECT_SUMMARY_REPORT,
  SELFMANAGER_RATINGS_REPORT,
  PROJECT_GROUP_REPORT,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { object } from 'yup/lib/locale'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { handleGenerate } from '../../../../../../function/GroupWord'
import projectsummary from './projectsummary.json'
import GroupReport from '../../../../../../function/GroupReport'
import ReactExport from 'react-data-export'
import { keyframes } from 'styled-components'
import { PDFExport } from '@progress/kendo-react-pdf'
import ReportLogo from '../../../../../../assets/images/word/360survey.png'
import ReportHeaderLogo from '../../../../../../assets/images/arrow.png'
import moment from 'moment'
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
  ChartSeriesLabels,
  ChartValueAxisItem,
  ChartValueAxis,
  ChartArea,
} from '@progress/kendo-react-charts'
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export'
import SummaryReportFile from '../ProjectReport/SummaryReportFile'
import { load } from '@progress/kendo-react-intl'
// import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  selected: {
    fontFamily: 'Roboto-Bold !important',
  },
  unselected: {
    fontFamily: 'Roboto-Regular !important',
  },
  listContainer: {
    padding: '0px',
  },
  buttona: {
    background: '#64B5F6 !important',
  },
}))
export default function DetailsReport({
  csvReport,
  projectDetails,
  scaleTypeLists,
  projectname,
}) {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  let { id = '' } = useParams()
  const [selfMangerReport, setSelfManagerReport] = useState([])
  const [summaryReportSheet1, setSummaryReportsheet1] = useState([])
  const [summaryReportSheet2, setSummaryReportsheet2] = useState([])
  const [threesixtyItemSheet1, setThreeSixtyItemSheet1] = useState([])
  const [threesixtyItemSheet2, setThreeSixtyItemSheet2] = useState([])
  const [projRelationship, setProjectRelationship] = useState([])
  const [reportLoading, setreportLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

  //   const data1 = [
  //   {
  //     foo: '123',
  //     bar: '456',
  //     baz: '789'
  //   },
  //   {
  //     foo: 'abc',
  //     bar: 'dfg',
  //     baz: 'hij'
  //   },
  //   {
  //     foo: 'aaa',
  //     bar: 'bbb',
  //     baz: 'ccc'
  //   }
  // ]

  const columns = [
    { id: 'participantFirstName', displayName: 'Participant FirstName' },
    { id: 'participantLastName', displayName: 'Participant LastName' },
    { id: 'participantEmail', displayName: 'Participant Email' },
    { id: 'raterFirstName', displayName: 'Rater FirstName' },
    { id: 'raterLastName', displayName: 'Rater LastName' },
    { id: 'raterEmail', displayName: 'Rater Email' },
    { id: 'relationship', displayName: 'Relationship' },
    { id: 'competency', displayName: 'Competency' },
    { id: 'capability', displayName: 'Capability' },
    { id: 'zCaption', displayName: 'Caption' },
    { id: 'zSelectedValue', displayName: 'SelectedValue' },
  ]

  const selfManagerCsv = {
    filename: `${projectname}_Self/ManagerRatingsReport.csv`,
    columns: columns,
    datas: selfMangerReport,
  }

  let params = useParams()
  const pdfExportComponent = useRef(null)
  const [groupData, setGroupDate] = useState()
  const [sclaLis, setScaleLis] = useState()
  const [flag, setFlag] = useState(false)

  const handleGroupReport = async () => {
    setreportLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: PROJECT_GROUP_REPORT,
      params: {
        count: true,
        ProjectID: params?.id,
        // ProjectID: 14180,
        //ParticipantID: 84719,
        //CompetencyID: 357,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        //console.log(response.data);
        // GroupReport(response.data, scaleTypeLists)
        setGroupDate(response.data)
        setScaleLis(scaleTypeLists)
        // handleGenerate(response.data, projectDetails, scaleTypeLists)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setreportLoading(false)
    }
  }

  const getProjectSummaryReport = async () => {
    setLoading(true)
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: PROJECT_SUMMARY_REPORT,
      params: {
        UserID: getUserId(),
        ProjectID: id,
      },
    }
    try {
      const response = await API(getconfig)
      // const response = projectsummary
      const { data } = response
      if (response) {
        let userArray = []
        data.ReportUser_LstByProjectt.map((el, i) => {
          let nameObj = {}
          nameObj['firstName'] = el.zFirstName
          nameObj['lastName'] = el.zLastName

          userArray.push(nameObj)
        })
        const result = userArray.filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              (t) =>
                t.firstName === thing.firstName && t.lastName === thing.lastName
            )
        )
        // const objEntries = Object.entries(data.ReportAssessmentScoring360_GetItem);
        // console.log("objectEnt", objEntries)
        // const objectEntriesss = Object.fromEntries(objEntries)
        // console.log("ObjectEntriesss",objectEntriesss);
        let resultArray = []
        result.map((el, i) => {
          //let resultObj = {...objectEntriesss}
          // resultObj['firstName'] = el.firstName
          // resultObj['lastName'] = el.lastName
          // resultObj['ind'] = i+1
          resultArray.push(el)
        })
        let allItemsStat = []
        data.ReportAssessmentScoring360_GetItem.map((el, i) => {
          let newObj = {}
          newObj['zInd'] = i + 1
          newObj['zCompetencyName'] = el.zCompetencyName
          newObj['zCompetencyID'] = el.zCompetencyID
          newObj['zCapabilityName'] =
            el.zCapabilityName == null ? '' : el.zCapabilityName
          newObj['zCapabilityID'] = el.zCapabilityID
          newObj['zCaption'] = el.zCaption
          newObj['zRelationshipID'] = el.zRelationshipID
          newObj['zAverage'] = el.zAverage

          allItemsStat.push(newObj)
        })

        const projectRelationshipProject = data.ReportRelationship_LstByProject
        const projectUsers = data.ReportUser_LstByProjectt.filter(
          (rec) =>
            rec.zProjectUserTypeID === 3 && rec.zProjectUserStatusID === 3
        )
        const item = data.ReportAssessmentScoring360_GetItem
        const customItem = data.ReportAssessmentScoring360_GetCustomItem
        console.log(projectRelationshipProject)
        // setProjectRelationship(projectRelationshipProject)

        item.map((el, i) => {
          let newObj = {}
          newObj['zInd'] = i + 1
          newObj['zCompetencyName'] = el.zCompetencyName
          newObj['zCompetencyID'] = el.zCompetencyID
          newObj['zCapabilityName'] =
            el.zCapabilityName == 'null' ? '' : el.zCapabilityName
          newObj['zCapabilityID'] = el.zCapabilityID
          newObj['zCaption'] = el.zCaption
          newObj['zRelationshipID'] = el.zRelationshipID
          newObj['zAverage'] = el.zAverage

          allItemsStat.push(newObj)
        })
        setSummaryReportsheet2(allItemsStat)

        let temp = {}
        // sheet 1 data forming
        console.log(projectUsers)
        let individualItemStat = []
        let responseData = []
        await Promise.all(
          projectUsers.map(async (projectUser, index) => {
            if (projectUser.zProjectUserTypeName === 'Subject') {
              const getconfig = {
                method: 'GET',
                headers: authHeader(),

                url: PROJECT_SUMMARY_REPORT,
                params: {
                  ProjectID: id,
                  UserID: projectUser.zUserID,
                },
              }

              const response = await API(getconfig)
              responseData.push({
                response: response,
                projectUser: projectUser,
              })
            }
          })
        )
        let allRelationShip = {}
        responseData.map((el, i) => {
          const { data } = el.response
          const { FinalCalc, ReportRelationship_LstByProject } = data
          const { projectUser } = el
          const { zFirstName, zLastName } = projectUser
          let relationshipData = {
            Average: [],
          }
          ReportRelationship_LstByProject.forEach((value) => {
            relationshipData[value.zName] = []
            allRelationShip[value.zName] = []
          })

          let newFinalcalc = FinalCalc.filter(
            (value) =>
              value.zRptComp == 0 && value.zParticipantID == projectUser.zUserID
          )
          let finalvalueSplit = {}
          newFinalcalc.forEach((value) => {
            relationshipData[value.zCategoryNm] = [
              ...(relationshipData[value?.zCategoryNm] || []),
              { ...value, zCapabilityNm: value.zCapabilityNm || 'Total' },
            ]
            finalvalueSplit[value?.zCapabilityNm || 'Total'] = {
              ...(finalvalueSplit[value?.zCapabilityNm] || {}),
              ...value,
              zCapabilityNm: value?.zCapabilityNm || 'Total',
            }
          })
          Object.entries(finalvalueSplit).forEach((value) => {
            // console.log(
            //   relationshipData[value[1].zCategoryNm].find(
            //     (el) => el.zCapabilityNm === value[1].zCapabilityNm
            //   )
            // )
            let data = []
            let rel = {}
            Object.entries(relationshipData).forEach((el) => {
              el[1].forEach((datapoint) => {
                console.log(datapoint)
                if (datapoint.zCapabilityNm == value[0]) {
                  if (el[0] == 'Self') {
                    rel[el[0]] = datapoint.zAverage
                  } else {
                    rel[el[0]] =
                      datapoint.zNumberOfRatings < 3
                        ? datapoint.zAverage
                        : datapoint.zAverage
                  }
                }
              })
              // console.log(nevalue, projectUser.zUserID)
              // data.push(el[1].filter((value) => value.zCategoryNm === value[0]))
            })

            // console.log(relationshipData, projectUser.zUserID, value[0], rel)

            individualItemStat.push({
              ...value[1],
              zCapabilityName: value[0] == null ? '' : value[0],
              zFirstName,
              zLastName,

              ...rel,
            })
          })
        })

        setProjectRelationship(Object.keys(allRelationShip))
        setSummaryReportsheet1(individualItemStat)
        let narr = []
        individualItemStat.map((rex, i) => {
          console.log('rec.zfirstName', rex.zFirstName)
          const { zFirstName, zLastName } = rex
          narr.push({ zFirstName, zLastName })
        })
        console.log('firstName', narr)
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      setLoading(false)
    }
  }

  const selManagerRatingsReport = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: SELFMANAGER_RATINGS_REPORT,
      params: {
        projectID: id,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        data.Report.map((rec, i) => {
          rec.zCaption = rec.zCaption.replace(/,/g, ' ')
        })
        data.Report.map((rec, i) => {
          console.log('data report', rec.zCaption)
        })
        setSelfManagerReport(data.Report)
      }
    } catch (e) {
      console.log('error')
    }
  }

  const getThreeSixtyItemReport = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: PROJECT_360_ITEM_REPORT,
      params: {
        projectID: id,
      },
    }

    try {
      const response = await API(getconfig)
      const { Projectdetailslist, Questionlist } = response.data
      if (response) {
        const items = Questionlist.filter((rec) => rec.zQuestionType === 1)
        const oeq = Questionlist.filter((rec) => rec.zQuestionType === 2)
        setThreeSixtyItemSheet1(items)
        setThreeSixtyItemSheet2(oeq)
      }
    } catch (e) {
      console.log('error')
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // const addingIndex = () => {
  //   for (let i=1; i<=data2.length; i++){
  //       return i+1;
  //   }
  // }

  useEffect(() => {
    getProjectSummaryReport()
    getThreeSixtyItemReport()
    selManagerRatingsReport()
  }, [])

  const ProjectSummaryreport = (props) => {
    const { sheet1, sheet2 } = props
    console.log('sheet1', sheet1, sheet2)

    const selectCol = ['zFirstName', 'zLastName', 'zCompetencyNm']
    const newData = sheet1.map((rec, i) => {
      console.log('direct', rec, rec['Direct Report'])
      const {
        zFirstName,
        zLastName,
        zCompetencyNm,
        zCapabilityID,
        zCapabilityName,
        zCompetencyID,
        zAverage,
        Self,
        zInd,
        Manager,
        Other,
        Peer,
      } = rec
      const rowValue = [
        {
          value: zFirstName,
        },
        {
          value: zLastName,
        },
        { value: i + 1 },
        {
          value: zCompetencyNm,
          style: { font: { bold: true } },
        },
        {
          value: zCapabilityID,
        },
        { value: zCapabilityName, style: { font: { bold: true } } },
        { value: rec['Average (excl. Self)'], style: { font: { bold: true } } },
        { value: parseFloat(Self).toFixed(2), style: { font: { bold: true } } },
        {
          value: rec['Direct Report'] ? rec['Direct Report'] : '',
          style: { font: { bold: true } },
        },
        { value: Manager ? Manager : '', style: { font: { bold: true } } },
        { value: Peer, style: { font: { bold: true } } },
        { value: Other ? Other : '', style: { font: { bold: true } } },
      ]

      // for (var key in element) {
      //   if (element.hasOwnProperty(key) && selectCol.includes(key)) {
      //     rowValue.push(
      //       {
      //       value: element[key],
      //       style: ['zCompetencyNm', 'zLastName'].includes(key)
      //         ? { font: { sz: '24', bold: true } }
      //         : '',
      //     })
      //   }
      // }
      return rowValue
    })
    console.log('new Data', newData)
    const sheet1customize = [
      {
        columns: [
          { title: '' }, //pixels width
          { title: '' }, //char width
          { title: '' }, //char width
          { title: '', width: { wpx: 150 } },
          { title: '' },
          { title: '', width: { wpx: 150 } },
          { title: 'Average (excluding Self)' },
          { title: 'Self' },
          { title: 'Direct Report' },
          { title: 'Manager' },
          { title: 'Peer' },
          { title: 'Other' },
        ],

        data: newData,
      },
    ]
    console.log('sheet', sheet2)
    const newData1 = sheet2.map(
      ({
        zCompetencyName,
        zInd,
        zCapabilityID,
        zCapabilityName,
        zCaption,
        zAverage,
      }) => {
        console.log('cap name', zCapabilityName)
        const rowValue1 = [
          {
            value: zCompetencyName,
          },
          {
            value: zInd,
          },
          { value: zCapabilityID },
          {
            value: zCapabilityName,
          },
          {
            value: zCaption,
          },
          { value: zAverage },
        ]

        // for (var key in element) {
        //   if (element.hasOwnProperty(key) && selectCol.includes(key)) {
        //     rowValue.push(
        //       {
        //       value: element[key],
        //       style: ['zCompetencyNm', 'zLastName'].includes(key)
        //         ? { font: { sz: '24', bold: true } }
        //         : '',
        //     })
        //   }
        // }
        return rowValue1
      }
    )
    console.log('data1', newData1)
    const sheet2customize = [
      {
        columns: [
          { title: '', width: { wpx: 250 } }, //pixels width
          { title: '' }, //char width
          { title: '' }, //char width
          { title: '', width: { wpx: 200 } },
          { title: '', width: { wpx: 750 } },
          { title: '' },
        ],

        data: newData1,
      },
    ]
    console.log(newData1, 'newData1')

    return (
      <>
        <ExcelFile
          element={
            <CustomButton className={classes.buttona} colour="primaryButton">
              {!loading ? 'Download' : 'Loading...'}
            </CustomButton>
          }>
          <ExcelSheet
            dataSet={sheet1customize}
            name="Individual Scores All Scores"
          />
          <ExcelSheet dataSet={sheet2customize} name="All Items Stats" />
        </ExcelFile>
      </>
      // <Workbook
      //   filename={`${projectname}_SummaryReport.xlsx`}
      //   element={
      //     <CustomButton className={classes.buttona} colour="primaryButton">
      //       Download
      //     </CustomButton>
      //   }>

      //   <Workbook.Sheet data={sheet1} name="Individual Scores All Scores">
      //     <Workbook.Column label="" value="zFirstName" />
      //     <Workbook.Column label="" value="zLastName" />
      //     <Workbook.Column label="" value={(row) => row.zCompetencyNm} />

      //     <Workbook.Column label="" value="zCapabilityID" />
      //     <Workbook.Column label="" value="zCapabilityName" />
      //     <Workbook.Column
      //       label="Average (excl. Self)"
      //       value="Average (excl. Self)"
      //     />

      //     {projRelationship.map((relationship) => (
      //       <Workbook.Column
      //         label={relationship}
      //         value={relationship}
      //         style={{ fontWeight: 'bold', color: 'red' }}
      //       />
      //     ))}
      //   </Workbook.Sheet>
      //   <Workbook.Sheet data={sheet2} name="All Items Stats">
      //     <Workbook.Column label="" value="zCompetencyName" />
      //     <Workbook.Column label="" value="zInd" />
      //     <Workbook.Column label="" value="zCapabilityID" />
      //     <Workbook.Column label="" value="zCapabilityName" />
      //     <Workbook.Column label="" value="zCaption" />
      //   </Workbook.Sheet>
      // </Workbook>
    )
  }

  useEffect(() => {
    if (groupData) {
      setFlag(true)
    }
  }, [groupData])
  useEffect(() => {
    setTimeout(() => {
      if (flag == true) {
        if (pdfExportComponent.current) {
          pdfExportComponent.current.save()
        }
      }
    }, 5000)
  }, [flag])

  let Scale = 'Scale'
  // let ScaleLength = scaleList.length
  const {
    addIntroduction360 = [],
    addOverallCompetency360 = [],
    addTopBottom2ByRatercat360 = [],
    addSuppComps360 = [],
    addBehaviorScores360 = [],
    addIndiScoresByComp360 = [],
  } = groupData || {}
  const [threesixtyHearing] = addIntroduction360
  const [Compentency] = addOverallCompetency360
  const [toptwobottomtwo] = addTopBottom2ByRatercat360
  const {
    title: toptwoTitle = '',
    description: toptwoDescription = '',
    addTopBottom2ByRatercat360DataSub = [],
  } = toptwobottomtwo || {}

  const [firstSuportingdata] = addSuppComps360
  const {
    title: addSuppCompsTitle = '',
    description: addSuppCompsDescription = '',
    addSuppComps360Data = [],
  } = firstSuportingdata || {}
  const {
    title: competencicyTitle = '',
    description: compentencyDescription = '',
    addOverallCompetency360Datas = [],
  } = Compentency || {}
  const [firstBehavior] = addBehaviorScores360
  const [firstIndividual] = addIndiScoresByComp360
  const {
    title: individualTitle = '',
    description: individualDescription = '',
    addIndiScoresByComp360DataLastArr = [],
  } = firstIndividual || {}
  const [firstDatainlastArr] = addIndiScoresByComp360DataLastArr
  const { addIndiScoresByComp360DataLast = [] } = firstDatainlastArr || {}
  const {
    title: firstBehaviorCompsTitle = '',
    description: firstBehaviorCompsDescription = '',
    addBehaviorScores360Data = [],
  } = firstBehavior || {}
  const [firstCompentancy] = addOverallCompetency360Datas
  const { strAocHdr: firstCompentancyHeading = '' } = firstCompentancy || {}
  const {
    organizationName = '',
    projectName = '',
    ownerName = '',
    endDate = '',
    dueDateFormat = '',
    title = '',
    description = '',
    title: threesixtyTitle = '',
    description: threesixtyDescription = '',
    strIntroPartRespHdr = '',
    strIntroPartCount = '',
    strIntroRespCount = '',
    scaleIdName = '',
    scaleRatingIdDesc: scaleS = '',
    scaleRatingIdName = '',
    strIntroScaleFtr: threNote = '',
    strIntroModelHdr: modalHeading = '',
    strIntroModelComps: modalDescription = '',
    addIntroduction360Data = [],
  } = threesixtyHearing || {}
  let sl = []
  if (scaleRatingIdName) {
    let rl = scaleRatingIdName.replaceAll('<b>', '')
    let rl1 = rl.replaceAll('</b>', '')
    sl = rl1.split('##')
  }
  const PDFExportPageTemplate = (props) => {
    return (
      <>
        {props.pageNum == 1 ? (
          <></>
        ) : (
          <>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                left: '10px',
                // bottom: '80px',
                fontSize: '10px',
              }}>
              {organizationName}
            </div>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                left: '400px',
                // bottom: '80px',
                fontSize: '10px',
                display: 'block',
              }}>
              <div>{projectName}</div>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '40px',
                left: '400px',
                // bottom: '40px',
                fontSize: '12px',
                display: 'flex',
              }}>
              <div>{title}</div>

              <div style={{ marginLeft: '10px' }}>
                <img src={ReportHeaderLogo} alt="logo" width="15px"></img>
              </div>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '785px',
                left: '500px',
                bottom: '80px',
                fontSize: '10px',
              }}>
              Page {props.pageNum}
            </div>
          </>
        )}
      </>
    )
  }
  let compData = []
  let labelData = []
  let suppData = []
  let supplabel = []
  let topData = []
  let dtop = [] //aveg self
  let sel = [] // self
  let mana = [] //manager
  let ft
  let topLabel = []
  let topNameLabel = []
  return (
    // <div className={classes.root}>
    <>
      <div
        style={{
          position: 'absolute',
          left: '-5000px',
          top: 0,
        }}>
        <PDFExport
          forcePageBreak=".page-break"
          paperSize="A4"
          margin={{ top: '1.5cm', left: '1cm', right: '1cm', bottom: '1cm' }}
          fileName={`360GroupReport_${projectName}.pdf`}
          pageTemplate={PDFExportPageTemplate}
          ref={pdfExportComponent}>
          <div
            style={{
              width: '500px ',
            }}>
            {/* <div
      style={{
        width: '100%',
        height: '100%',
        // padding:'1.5%',paddingBottom:"1%"
      }}> */}
            <Grid>
              <div>
                <Grid>
                  <div
                    style={{
                      alignContent: 'center',
                      marginTop: '20px',
                      marginLeft: '20px',
                    }}>
                    <Typography style={{ fontSize: '12px' }}>
                      {organizationName}
                    </Typography>

                    <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                  </div>
                  <div
                    style={{
                      alignItems: 'center',
                      marginTop: '200px',
                      marginLeft: '20px',
                      display: 'flex',
                    }}>
                    <img
                      // src={orgLogo}
                      src={ReportLogo}
                      alt="logo"
                      width="70px"></img>
                    <div style={{ marginLeft: '20px' }}>
                      <Typography style={{ fontSize: '12px' }}>
                        {title}
                      </Typography>
                      <Typography style={{ fontSize: '15px' }}>
                        {projectName}
                      </Typography>
                      <Typography style={{ fontSize: '15px' }}>
                        {'CONFIDENTIAL'}
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: '160px',
                      marginLeft: '10px',
                    }}>
                    <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                    <div
                      style={{
                        // marginTop: '60px',
                        marginLeft: '10px',
                        display: 'flex',
                      }}>
                      <div>
                        <Typography
                          style={{ fontSize: '10px', marginTop: '20px' }}>
                          {'Project Name'}
                        </Typography>
                        <bold>
                          <Typography style={{ fontSize: '11px' }}>
                            {projectName}
                          </Typography>
                        </bold>
                      </div>
                      <div>
                        <Typography
                          style={{ marginLeft: '280px', fontSize: '10px' }}>
                          {'Report Date'}
                        </Typography>
                        <Typography
                          style={{ marginLeft: '280px', fontSize: '11px' }}>
                          {moment(new Date()).format('MM/DD/YYYY')}
                        </Typography>
                      </div>
                    </div>
                    <br />
                    <div
                      style={{
                        // marginTop: '60px',
                        marginLeft: '10px',
                        display: 'flex',
                      }}>
                      <div>
                        <Typography style={{ fontSize: '10px' }}>
                          {'Project Manager'}
                        </Typography>
                        <Typography style={{ fontSize: '11px' }}>
                          {ownerName}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          style={{ marginLeft: '260px', fontSize: '10px' }}>
                          {'Project Due Date'}
                        </Typography>
                        <Typography
                          style={{ marginLeft: '280px', fontSize: '11px' }}>
                          {dueDateFormat}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <Typography
                    className="page-break"
                    style={{ fontSize: '16px', marginTop: '50px' }}>
                    {title}
                  </Typography>
                  <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                  <bold>
                    <Typography variant="h6" style={{ fontSize: '10px' }}>
                      {description}
                    </Typography>
                  </bold>
                  <br />
                  <Typography style={{ fontSize: '13px' }}>
                    {strIntroPartRespHdr}
                  </Typography>
                  <Typography style={{ fontSize: '10px' }}>
                    {strIntroPartCount};{strIntroRespCount}
                  </Typography>
                  <br />
                  <Typography style={{ fontSize: '13px' }}>
                    {'Scale'}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '10px',
                    }}>{`The scale utilized by all respondents on all 360's was a ${scaleIdName} :`}</Typography>
                  <br />
                  <table>
                    <tr>
                      {sl &&
                        sl.map((rec) => {
                          return (
                            <th style={{ backgroundColor: '#dddddd' }}>
                              {rec}
                            </th>
                          )
                        })}
                    </tr>
                  </table>
                  <br />
                  <Typography style={{ fontSize: '10px' }}>
                    {threNote}
                  </Typography>
                  <br />
                  <Typography style={{ fontSize: '12px' }}>
                    {modalHeading}
                  </Typography>
                  <Typography style={{ fontSize: '10px' }}>
                    {modalDescription}
                  </Typography>
                  {addIntroduction360Data &&
                    addIntroduction360Data.map((rec, i) => {
                      const { capabilityName = [] } = rec
                      console.log('cap name1', capabilityName)
                      let mt = '0px'
                      if (i == 5) {
                        mt = '40px'
                      }
                      return (
                        <>
                          <br />
                          <Typography
                            style={{ fontSize: '12px', marginTop: mt }}
                            className={i == 5 ? 'page-break' : ''}>
                            {rec.competencyName
                              ? rec.competencyName.split('##')[0]
                              : ''}
                          </Typography>
                          <div style={{ display: 'flex' }}>
                            {capabilityName &&
                              capabilityName.map((r, i) => {
                                let ds
                                if (capabilityName.length == i) {
                                  ds = r.concat('  ').concat(' ')
                                } else {
                                  ds = r
                                    .concat(';')
                                    .concat('  ')
                                    .concat(' ')
                                }
                                console.log('des', ds)
                                return (
                                  <Typography style={{ fontSize: '10px' }}>
                                    {ds}{' '}
                                  </Typography>
                                )
                              })}
                          </div>
                        </>
                      )
                    })}
                  {console.log(
                    'comp',
                    Compentency,
                    Compentency && Compentency.title,
                    Compentency && Compentency.description
                  )}
                  {addOverallCompetency360Datas &&
                  addOverallCompetency360Datas.length > 0 ? (
                    <>
                      <Typography
                        style={{ fontSize: '15px' }}
                        className="page-break">
                        {Compentency ? Compentency.title : ''}{' '}
                      </Typography>
                      <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                      <Typography style={{ fontSize: '10px' }}>
                        {Compentency ? Compentency.description : ''}{' '}
                      </Typography>
                      <br />
                      {addOverallCompetency360Datas &&
                        addOverallCompetency360Datas.map((record, i) => {
                          compData.push({
                            name: record.header,
                            average: parseFloat(record.average).toFixed(2),
                          })
                          labelData.push({ name: record.header })
                          return <></>
                        })}
                      {console.log('compdata', compData)}
                      <Typography
                        style={{ fontSize: '10px', marginLeft: '280px' }}>
                        {'Group Average (excl. Self) by Competency'}{' '}
                      </Typography>

                      <Chart
                        style={{
                          width: 500,
                          height: 230,
                        }}>
                        <ChartValueAxis>
                          <ChartValueAxisItem min={0} max={7} />
                        </ChartValueAxis>
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem
                            categories={labelData}></ChartCategoryAxisItem>
                        </ChartCategoryAxis>

                        <ChartSeries>
                          <ChartSeriesItem
                            gap={1}
                            type="bar"
                            data={compData}
                            min={0}
                            categoryField="name"
                            field="average"
                            // colorField="colorvalue"
                          >
                            <ChartSeriesLabels
                              visible={true}
                              color="#ffffff"
                              content="average"
                              position="left"
                              background="none"
                              padding="3"
                              font="bold 8px Arial, sans-serif"></ChartSeriesLabels>
                          </ChartSeriesItem>
                        </ChartSeries>
                      </Chart>
                    </>
                  ) : (
                    <></>
                  )}
                  {console.log('add', addTopBottom2ByRatercat360)}
                  {addTopBottom2ByRatercat360 &&
                  addTopBottom2ByRatercat360.length > 0 ? (
                    <>
                      <Typography
                        style={{ fontSize: '15px', marginTop: '40px' }}
                        className="page-break">
                        {toptwoTitle}{' '}
                      </Typography>
                      <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />

                      <Typography style={{ fontSize: '10px' }}>
                        {toptwoDescription}{' '}
                      </Typography>
                      <br />
                      {addTopBottom2ByRatercat360 &&
                        addTopBottom2ByRatercat360.map((res, ix) => {
                          const { addTopBottom2ByRatercat360DataSub = [] } = res
                          console.log(
                            'res',
                            res,
                            addTopBottom2ByRatercat360DataSub
                          )
                          {
                            addTopBottom2ByRatercat360DataSub &&
                              addTopBottom2ByRatercat360DataSub.map(
                                (resin, dx) => {
                                  if (resin.zName == 'Average (excl. Self)') {
                                    console.log(
                                      'valllueu',
                                      resin.zName,
                                      resin.avg
                                    )
                                    dtop.push(resin.avg)
                                  }
                                  if (resin.zName == 'Self') {
                                    sel.push(resin.avg)
                                  }
                                  if (resin.zName == 'Manager') {
                                    mana.push(resin.avg)
                                  }
                                  {
                                    console.log('sel', sel, dtop)
                                  }

                                  if (ix == 0) {
                                    if (topLabel.length > 0) {
                                      let fi = topLabel.filter(
                                        (f) => f == resin.competencyName
                                      )
                                      console.log('fi len', fi.length)
                                      if (fi.length == 0) {
                                        topLabel.push(resin.competencyName)
                                      }
                                    } else {
                                      topLabel.push(resin.competencyName)
                                    }
                                    if (topNameLabel.length > 0) {
                                      let fi = topNameLabel.filter(
                                        (f) => f == resin.zName
                                      )
                                      console.log('fi len', fi.length)
                                      if (fi.length == 0) {
                                        topNameLabel.push(resin.zName)
                                      }
                                    } else {
                                      topNameLabel.push(resin.zName)
                                    }
                                    console.log('tlab', topLabel, topNameLabel)
                                    topData.push({
                                      name: resin.zName,
                                      compname: resin.competencyName,
                                      value: resin.avg,
                                      value1: '',
                                      value2: '',
                                      ch:
                                        resin.color == 'White'
                                          ? resin.color
                                          : 'rgba('
                                              .concat(resin.color)
                                              .concat(',1)'),
                                    })
                                  } else {
                                    topData.filter(
                                      (f) =>
                                        f.name == resin.zName &&
                                        f.compname == resin.competencyName
                                    ).value1 = resin.value
                                  }
                                  return <></>
                                }
                              )
                          }
                          return <></>
                        })}

                      {console.log(
                        'top data',
                        topData,
                        (ft = topData.find(
                          (f) =>
                            f.name == 'Average (excl. Self)' &&
                            f.compname == 'ACTION ORIENTATION'
                        ))
                      )}
                      {/* // {ft?<p>{ft.value}</p>:<p></p>} */}
                      <br />
                      <br />

                      <table>
                        <tr style={{ fontSize: '10px' }}>
                          <td style={{ width: '40%' }}></td>
                          {topLabel &&
                            topLabel.map((r, i) => {
                              return (
                                <td
                                  style={{
                                    // writingMode:'vertical-lr',textOrientation:'mixed',
                                    transform: 'rotate(270deg)',
                                    fontSize: '9px',
                                    width: '30%',
                                    backgroundColor: [1, 3, 5].includes(i)
                                      ? '#f1f1f1'
                                      : 'none',
                                  }}>
                                  {r}
                                </td>
                              )
                            })}
                          <tr>{''}</tr>
                        </tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        {topNameLabel &&
                          topNameLabel.map((re, i1) => {
                            return (
                              <tr>
                                <td style={{ width: '30%', fontSize: '10px' }}>
                                  {re}
                                </td>
                                {topLabel &&
                                  topLabel.map((tr, i2) => {
                                    let avgval = 0
                                    let clr = 'none'
                                    let fl = topData.filter(
                                      (fr) => fr.name == re && fr.compname == tr
                                    )
                                    if (fl) {
                                      console.log('fl', fl[0])
                                      avgval = fl[0].value
                                      clr = fl[0].ch
                                    }
                                    return (
                                      <td
                                        style={{
                                          fontSize: '10px',
                                          backgroundColor: clr,
                                          width: '10%',
                                          textAlign: 'center',
                                        }}>
                                        {avgval}
                                      </td>
                                    )
                                  })}
                              </tr>
                            )
                          })}
                      </table>
                    </>
                  ) : (
                    <></>
                  )}
                  {addSuppCompsTitle ? (
                    <>
                      <Typography
                        style={{ fontSize: '15px', marginTop: '40px' }}
                        className="page-break">
                        {addSuppCompsTitle}{' '}
                      </Typography>
                      <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                      <Typography style={{ fontSize: '10px' }}>
                        {addSuppCompsDescription}{' '}
                      </Typography>
                      {addSuppComps360Data &&
                        addSuppComps360Data.map((supp, i) => {
                          suppData.push({
                            name: supp.cName + '               ' + supp.zName,
                            average: parseFloat(supp.avg).toFixed(2),
                          })
                          let widthdy = supp.avg * 50
                          return (
                            <>
                              <table style={{ width: '100%' }}>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: '8px',
                                      width: '25%',
                                      textAlign: 'right',
                                    }}>
                                    {supp.cName}
                                  </td>
                                  <td style={{ width: '5%' }}> </td>
                                  <td
                                    style={{
                                      fontSize: '9px',
                                      width: '25%',
                                      textAlign: 'left',
                                    }}>
                                    {supp.zName}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: '10px',
                                      width: '45%',
                                      textAlign: 'left',
                                      alignItems: 'left',
                                    }}>
                                    <svg width={widthdy} height="30">
                                      <rect
                                        x="50"
                                        y="15"
                                        width={widthdy}
                                        height="100"
                                        style={{ fill: '#80bdee' }}
                                      />
                                      <text
                                        style={{
                                          fill: 'white',
                                          font: '10px sans-serif',
                                          fontWeight: '800',
                                          'text-anchor': 'start',
                                        }}
                                        x="65"
                                        y="22"
                                        dy=".35em">
                                        {parseFloat(supp.avg).toFixed(2)}
                                      </text>
                                    </svg>
                                  </td>
                                </tr>
                              </table>
                            </>
                          )
                        })}
                    </>
                  ) : (
                    <></>
                  )}
                  {/* <Chart
  style={{
    width: 500,
    height: 500,
  }}>
  <ChartValueAxis>
    <ChartValueAxisItem min={0} max={7} />
  </ChartValueAxis>
 
  <ChartSeries>
    <ChartSeriesItem
      gap={1}
      type="bar"
      data={suppData}
      min={0}
      categoryField="name"
      field="average"
      // colorField="colorvalue"
      autoFit={true}
    >
      <ChartSeriesLabels
        visible={true}
        color="#ffffff"
        content="average"
        position="left"
        background="none"
        padding="3"
        font="bold 8px Arial, sans-serif"></ChartSeriesLabels>
    </ChartSeriesItem>
  </ChartSeries>
</Chart> */}
                  {firstBehaviorCompsTitle ? (
                    <>
                      <Typography
                        style={{ fontSize: '15px', marginTop: '40px' }}
                        className="page-break">
                        {firstBehaviorCompsTitle}
                      </Typography>
                      <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                      <Typography style={{ fontSize: '10px' }}>
                        {firstBehaviorCompsDescription}{' '}
                      </Typography>
                      <br />
                      {console.log('addindi', addIndiScoresByComp360DataLast)}
                      {addBehaviorScores360Data &&
                        addBehaviorScores360Data.map((scr, idx) => {
                          let m = '0px'
                          if (
                            [
                              5,
                              10,
                              15,
                              20,
                              25,
                              30,
                              35,
                              40,
                              45,
                              50,
                              55,
                              60,
                              65,
                              70,
                              75,
                              80,
                              85,
                              90,
                              95,
                              100,
                              105,
                              110,
                              115,
                            ].includes(idx)
                          ) {
                            m = '40px'
                          }
                          let widthdy = scr.average * 75
                          console.log('width', widthdy)
                          return (
                            <>
                              <div style={{ display: 'flex', marginTop: m }}>
                                <Typography
                                  style={{ fontSize: '8px' }}
                                  className={
                                    [
                                      5,
                                      10,
                                      15,
                                      20,
                                      25,
                                      30,
                                      35,
                                      40,
                                      45,
                                      50,
                                      55,
                                      60,
                                      65,
                                      70,
                                      75,
                                      80,
                                      85,
                                      90,
                                      95,
                                      100,
                                      105,
                                      110,
                                      115,
                                    ].includes(idx)
                                      ? 'page-break'
                                      : ''
                                  }>
                                  {scr.competencyName}
                                </Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  {' '}
                                </Typography>
                                <Typography style={{ fontSize: '12px' }}>
                                  |
                                </Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  {' '}
                                </Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  {scr.capabilityName}
                                </Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  |
                                </Typography>
                                <Typography style={{ fontSize: '10px' }}>
                                  {scr.caption}
                                </Typography>
                              </div>
                              <hr
                                style={{ color: '#d1d1d9', marginTop: '2px' }}
                              />
                              <table style={{ width: '100%' }}>
                                <tr style={{ fontSize: '10px' }}>
                                  <td
                                    style={{
                                      width: '20%',
                                      textAlign: 'right',
                                    }}>
                                    Average (excl. Self)
                                  </td>
                                  <td style={{ width: '5%' }}></td>
                                  <td style={{ width: '3%' }}>
                                    {scr.raterCount}
                                  </td>
                                  <td style={{ width: '72%' }}>
                                    <svg width={widthdy} height="30">
                                      <rect
                                        x="50"
                                        y="15"
                                        width={widthdy}
                                        height="100"
                                        style={{ fill: '#80bdee' }}
                                      />
                                      <text
                                        style={{
                                          fill: 'white',
                                          font: '10px sans-serif',
                                          fontWeight: '800',
                                          'text-anchor': 'start',
                                        }}
                                        x="65"
                                        y="22"
                                        dy=".35em">
                                        {parseFloat(scr.average).toFixed(2)}
                                      </text>
                                    </svg>
                                  </td>
                                </tr>
                              </table>
                              <br />
                            </>
                          )
                        })}
                    </>
                  ) : (
                    <></>
                  )}
                  {individualTitle ? (
                    <>
                      <Typography
                        style={{ fontSize: '15px', marginTop: '40px' }}
                        className="page-break">
                        {individualTitle}
                      </Typography>
                      <hr style={{ color: '#d1d1d9', marginTop: '2px' }} />
                      <Typography style={{ fontSize: '10px' }}>
                        {individualDescription}{' '}
                      </Typography>
                      <br />
                      {addIndiScoresByComp360DataLastArr &&
                        addIndiScoresByComp360DataLastArr.map((res, i) => {
                          const { addIndiScoresByComp360DataLast = [] } = res
                          console.log(
                            'res',
                            res,
                            addIndiScoresByComp360DataLast
                          )
                          {
                            addIndiScoresByComp360DataLast &&
                              addIndiScoresByComp360DataLast.map((resin, d) => {
                                if (i == 0) {
                                  supplabel.push({
                                    name: resin.header,
                                    value: resin.value,
                                    value1: '',
                                    ch:
                                      resin.colorheader == 'White'
                                        ? resin.colorheader
                                        : 'rgba('
                                            .concat(resin.colorheader)
                                            .concat(',1)'),
                                    cd: 'rgba('
                                      .concat(resin.colordata)
                                      .concat(',1)'),
                                  })
                                } else {
                                  supplabel.filter(
                                    (f) => f.name == resin.header
                                  ).value1 = resin.value
                                }
                                return <></>
                              })
                          }
                          return <></>
                        })}
                      {console.log('sub label', supplabel)}
                      <br />
                      <br />
                      <table style={{ fontSize: '10px', width: '100%' }}>
                        <tr>
                          {supplabel &&
                            supplabel.map((indivi, ix) => {
                              let clr = ''
                              if (indivi.ch == 'rgba(64, 64, 64,1)') {
                                clr = 'White'
                              }
                              return (
                                <>
                                  {/* <tr  style={{writingMode:'vertical-rl',textOrientation:'mixed'}}> */}
                                  <td
                                    style={{
                                      transform: 'rotate(270deg)',
                                      fontSize: '9px',
                                      width: '40%',
                                      backgroundColor: indivi.ch,
                                      color: clr,
                                      textAlign: 'left',
                                    }}>
                                    {indivi.name}
                                  </td>

                                  {/* </tr> */}
                                </>
                              )
                            })}
                        </tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr></tr>
                        <tr>
                          {supplabel &&
                            supplabel.map((indivi, ix) => {
                              let clr = ''
                              if (indivi.ch == 'rgba(64, 64, 64,1)') {
                                clr = 'White'
                              }
                              return (
                                <>
                                  {/* <tr  style={{writingMode:'vertical-rl',textOrientation:'mixed'}}> */}
                                  <td
                                    style={{
                                      width: '10%',
                                      backgroundColor: indivi.cd,
                                      color: clr,
                                      textAlign: 'center',
                                    }}>
                                    {indivi.value == ''
                                      ? 'N/A'
                                      : indivi.value == '*'
                                      ? '*'
                                      : parseFloat(indivi.value).toFixed(2) ==
                                        'NaN'
                                      ? 'N/A'
                                      : parseFloat(indivi.value).toFixed(2)}
                                  </td>

                                  {/* </tr> */}
                                </>
                              )
                            })}
                        </tr>
                        <tr>
                          {supplabel &&
                            supplabel.map((indivi, ix) => {
                              let clr = ''
                              if (indivi.ch == 'rgba(64, 64, 64,1)') {
                                clr = 'White'
                              }
                              return (
                                <>
                                  {/* <tr  style={{writingMode:'vertical-rl',textOrientation:'mixed'}}> */}
                                  <td
                                    style={{
                                      width: '10%',
                                      backgroundColor: indivi.cd,
                                      color: clr,
                                      textAlign: 'center',
                                    }}>
                                    {indivi.value1 == ''
                                      ? 'N/A'
                                      : indivi.value1 == '*'
                                      ? '*'
                                      : parseFloat(indivi.value1).toFixed(2)}
                                  </td>

                                  {/* </tr> */}
                                </>
                              )
                            })}
                        </tr>
                      </table>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </div>
            </Grid>
            {/* </div> */}
          </div>
        </PDFExport>
      </div>
      <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}>
            <Tab
              className={!(value == 0) ? classes.unselected : classes.selected}
              label="Individual Reports"
              {...a11yProps(0)}
            />
            <Tab
              className={!(value == 1) ? classes.unselected : classes.selected}
              label="Group Reports"
              {...a11yProps(2)}
            />
            <Tab
              className={!(value == 2) ? classes.unselected : classes.selected}
              label="Project Reports"
              {...a11yProps(0)}
            />
          </Tabs>
        </Grid>
        <Grid item xs={9}>
          <TabPanel value={value} index={2}>
            <Grid>
              <Grid xs={7}>
                <List component="nav" aria-label="secondary mailbox folder">
                  <ListItem>
                    <ListItemText className={classes.unselected}>
                      Project Status Report
                    </ListItemText>
                    <CsvDownloader {...csvReport}>
                      <CustomButton
                        className={classes.buttona}
                        colour="primaryButton">
                        Download
                      </CustomButton>
                    </CsvDownloader>
                  </ListItem>
                  <Divider />
                  <ListItem>
                    <ListItemText className={classes.unselected}>
                      360 Items Report
                    </ListItemText>
                    <Workbook
                      filename={`${projectname}_Project360itemReport.xlsx`}
                      element={
                        <CustomButton
                          className={classes.buttona}
                          colour="primaryButton">
                          Download
                        </CustomButton>
                      }>
                      {/* <Workbook.Sheet data={data1} name="Individual Scores All Scores">
        <Workbook.Column label="Foo" value="foo"/>
        <Workbook.Column label="Bar" value="bar"/>
      </Workbook.Sheet> */}
                      <Workbook.Sheet
                        data={threesixtyItemSheet1}
                        name="Rated Items">
                        <Workbook.Column
                          label="Competency"
                          value="competency"
                        />
                        <Workbook.Column
                          label="Capability"
                          value="capability"
                        />
                        <Workbook.Column
                          label="Question Text"
                          value="zQuestionText"
                        />
                      </Workbook.Sheet>
                      <Workbook.Sheet
                        data={threesixtyItemSheet2}
                        name="Open Ended Items">
                        <Workbook.Column
                          label="Question Text"
                          value="zQuestionText"
                        />
                      </Workbook.Sheet>
                    </Workbook>
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText className={classes.unselected}>
                      Self/Manager Rating Report
                    </ListItemText>
                    <CsvDownloader {...selfManagerCsv}>
                      <CustomButton
                        className={classes.buttona}
                        colour="primaryButton">
                        Download
                      </CustomButton>
                    </CsvDownloader>
                  </ListItem>
                  <Divider />

                  <ListItem>
                    <ListItemText className={classes.unselected}>
                      Project Summary Report
                    </ListItemText>
                    <SummaryReportFile
                      projectname={projectname}
                      sheet1={summaryReportSheet1}
                      sheet2={summaryReportSheet2}
                      organizerName={projectDetails.zOrganizerName}
                      loading={loading}
                    />
                    {/* <CustomButton colour="primaryButton">Download</CustomButton> */}
                    {/* <ProjectSummaryreport
                    projectname={projectname}
                    sheet1={summaryReportSheet1}
                    sheet2={summaryReportSheet2}
                  /> */}
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={0}>
            <DetailsIndividualReport
              projectname={projectname}
              projectDetails={projectDetails}
              scaleTypeLists={scaleTypeLists}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Divider />
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={3}>
                <p>Group Report</p>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>Translation</InputLabel>
                <Select labelId="scale-label" label="Translation" fullWidth>
                  <MenuItem value="English">English</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <CustomButton
                  className={classes.buttona}
                  colour="primaryButton"
                  onClick={handleGroupReport}>
                  {reportLoading && <CircularProgress color="info" size={30} />}
                  Download
                </CustomButton>
              </Grid>
            </Grid>
            <Divider />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  )
}
