import { DialogContent } from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UPDATE_SUB_CATEGORY } from '../../../../../helpers/constants/ApiRoutes'
import { getCustomCategory } from '../../../../../redux/action'
import CustomButton from '../../../../common/CustomButton'
import { useDispatch } from 'react-redux'
import { API } from '../../../../../helpers/API'
import { authHeader } from '../../../../common/CustomFunction'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

export default function CustomAddSubCategoryDescription(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { open = '', handleReset, subCategory } = props
  //Schema
  const validationSchema = Yup.object({
    subCategoryDescription: Yup.string().required(
      'Category description is required'
    ),
  })

  //state
  const initialValues = {
    subCategoryDescription: '',
  }

  const handleLogin = async (formData) => {
    const { subCategoryDescription } = formData
    const putConfig = {
      method: 'PUT',
      url: UPDATE_SUB_CATEGORY,
      headers: authHeader(),

      data: {
        subCategoryID: subCategory.zCapabilityID,
        name: subCategory ? subCategory.zName : '',
        description: subCategoryDescription || '',
      },
    }
    try {
      const response = await API(putConfig)
      if (response && response.data) {
        dispatch(getCustomCategory())
        await handleReset()
      }
    } catch (e) {
      console.log(e)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const { subCategoryDescription = '' } = values
  const { question = '' } = values
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Add Description
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid item xs={10}>
                <TextField
                  id="designation-helper-text"
                  label="Sub Category Description"
                  value={subCategoryDescription}
                  onChange={handleChange}
                  fullWidth
                  name="subCategoryDescription"
                  error={
                    errors.subCategoryDescription &&
                    touched.subCategoryDescription
                  }
                  helperText={
                    errors.subCategoryDescription &&
                    touched.subCategoryDescription
                      ? errors.subCategoryDescription
                      : ''
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  onClick={handleSubmit}
                  style={{ marginTop: '1.5rem' }}>
                  Add
                </CustomButton>
              </Grid>
            </Grid>
          </form>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
