import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import { GET_SCALE_RATING_BY_SCALE_ID } from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { minWidth } from '@mui/system'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from '../../../../../../redux/action/Typeconstent'
import { authHeader } from '../../../../../common/CustomFunction'
import { setSelectedState } from '@progress/kendo-react-data-tools'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    fontWeight: 'bold',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '40px',
  },
  acc: {
    marginBottom: '1rem',
    '&.MuiAccordionSummary-content .Mui-expanded': {
      margin: '0px !important',
    },
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    height: '40px',
    backgroundColor: '#eee',
    margin: 'unset !important',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',

    // or borderTop: '1px solid red'
  },
  tableRow: {
    height: 25,
  },
}))

export default function NewThinkwisepreview(props) {
  console.log(props, ' ggggggg')
  const classes = useStyles()
  const [scaleRating, setScaleRatings] = useState([])
  const currentProjectInfo = useSelector((state) => state.CurrentProjectInfo)

  const { open = '', handleReset, zScaleID = '', title = '' } = props
  const NewthinkwiseReducer = useSelector((s) => s.NewthinkwiseReducer)
  const NewSelectedItemsIdReducer = useSelector(
    (s) => s.NewSelectedItemsIdReducer
  )
  const NewselectedThinkwiseItems = useSelector(
    (s) => s.NewselectedThinkwiseItems
  )
  const newSelectedSort = useSelector((v) => v.SelectedItemReducer)
  const listId = newSelectedSort.map((item) =>
    item?.capablityid ? item.capablityid : item?.compentencyid
  )
  console.log('new', scaleRating)
  console.log('newredu', NewthinkwiseReducer)
  console.log('newselect', NewSelectedItemsIdReducer)
  console.log('newselthink', NewselectedThinkwiseItems)
  const NewCustomItemsReducer = useSelector((s) => s.NewCustomItemsReducer)
  const tableColumns = [
    { id: 'fname', title: 'Strongly Disagree', sort: true },
    { id: 'lname', title: 'Disagree', sort: true },
    { id: 'email', title: 'Neutral', sort: true },
    { id: 'rater', title: 'Agree', sort: true },
    { id: 'status', title: 'Strongly Agree', sort: true },
  ]
  const tableHeadProps = { tableColumns }

  useEffect(() => {
    if (zScaleID) {
      getScaleRatingByScaleId(zScaleID)
    }
  }, [zScaleID])

  async function getScaleRatingByScaleId(scaleId) {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SCALE_RATING_BY_SCALE_ID,
      params: {
        ScaleID: scaleId,
      },
    }
    try {
      const response = await API(getConfig)
      console.log('response', response)
      if (response && response.data) {
        setScaleRatings(response.data.data)
      }
    } catch (e) {}
  }
  console.log('scale', scaleRating)
  const SurveyTable = useCallback((value) => {
    const { zName, zDescription } = value
    return (
      <>
        {' '}
        <p style={{ margin: '0px', padding: '0px' }}>
          {zName}
          <br />
          {zDescription}
        </p>
      </>
    )
  }, [])
  let questionNumber = 0

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        // open={true}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {/* Preview Survey */}
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            style={{ marginTop: '1rem' }}>
            {/* <div> */}
            <Grid item xs={10}>
              {newSelectedSort.map((cat, key) => {
                let valueCheck =
                  NewselectedThinkwiseItems[
                    THINKWISE_CATEGORY + cat?.compentencyid
                  ] || {}

                let selected =
                  NewSelectedItemsIdReducer[CUSTOM_ITEM_ + cat?.capablityid] ||
                  []
                  console.log(selected,'selettttt');
                if (Object.keys(valueCheck).length) {
                  return (
                    <Accordion
                      key={`accor_${key}`}
                      expanded={true}
                      className={classes.acc}>
                      <AccordionSummary
                        aria-controls={`item_${key}-content`}
                        id={`item_${key}-header`}
                        className={classes.accHead}>
                        <Grid item xs={10}>
                          <Typography className={classes.heading}>
                            {cat.compentencyname}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accDetail}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justifyContent="center"
                          style={{ marginTop: '2px' }}>
                          <Grid item xs={12}>
                            <p style={{ margin: '0px' }}>{cat.zDescription}</p>
                          </Grid>
                          {/* {cat.capablitylist.map((item, index) => {
                            return (
                              <Grid item xs={12}>
                                {' '}
                                <p>
                                  {index + 1}. {item.zCaption}
                                </p>
                                <TableContainer
                                  component={Paper}
                                  className={classes.tablecontainer}>
                                  <Table
                                    stickyHeader
                                    size="small"
                                    className={classes.tableCell}>
                                    <TableHead>
                                      <TableRow>
                                        {scaleRating.map((record) => (
                                          <TableCell
                                            className={classes.tableCell}
                                            style={{
                                              backgroundColor: '#D0E9FC',
                                              maxWidth: '100px',
                                              width: '100px',
                                              padding: '0px',
                                            }}>
                                            {SurveyTable(record)}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>

                                    {/* Table Header */}
                          {/* Table Body 
                                    <TableBody>
                                      <TableRow className={classes.tableRow}>
                                        {scaleRating.map((record) => (
                                          <TableCell
                                            align="left"
                                            className={classes.tableCell}>
                                            {' '}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            )
                          })} */}
                          {cat.capablitylist.map((sub) => {
                            let valueCheck =
                              NewselectedThinkwiseItems[
                                THINKWISE_CATEGORY + cat.compentencyid
                              ]?.[THINKWISE_SUB_CATEGORY + sub.capablityid] ||
                              []
                            const customSort = (a, b) => {
                              const indexA = valueCheck.indexOf(a.itemid)
                              const indexB = valueCheck.indexOf(b.itemid)
                              return indexA - indexB
                            }

                            const sordingPrivewListData = sub.itemslist?.sort(
                              customSort
                            )
                            if (valueCheck.length) {
                              return (
                                <Grid item xs={12}>
                                  <Accordion
                                    key={`accor_${key}`}
                                    expanded={true}
                                    className={classes.acc}>
                                    <AccordionSummary
                                      aria-controls={`item_${key}-content`}
                                      id={`item_${key}-header`}
                                      className={classes.accHeadInside}>
                                      {' '}
                                      <p>{sub.capablityname}</p>
                                    </AccordionSummary>
                                  </Accordion>

                                  <p>{sub.zDescription}</p>
                                  {sordingPrivewListData?.map((item, index) => {
                                    if (valueCheck.includes(item.itemid)) {
                                      questionNumber++
                                      return (
                                        <Grid item xs={12}>
                                          <b>
                                            {' '}
                                            <p>
                                              {questionNumber}. {item.itemname}
                                            </p>
                                          </b>
                                          <TableContainer
                                            component={Paper}
                                            className={classes.tablecontainer}>
                                            <Table
                                              stickyHeader
                                              size="small"
                                              className={classes.tableCell}>
                                              <TableHead>
                                                <TableRow>
                                                  {scaleRating?.map(
                                                    (record) => (
                                                      <TableCell
                                                        className={
                                                          classes.tableCell
                                                        }
                                                        style={{
                                                          backgroundColor:
                                                            '#D0E9FC',
                                                          maxWidth: '100px',
                                                          width: '100px',
                                                          padding: '0px 2px',
                                                          textAlign: 'center',
                                                        }}>
                                                        {SurveyTable(record)}
                                                      </TableCell>
                                                    )
                                                  )}
                                                </TableRow>
                                              </TableHead>

                                              <TableBody>
                                                <TableRow
                                                  className={classes.tableRow}>
                                                  {scaleRating.map((record) => (
                                                    <TableCell
                                                      align="left"
                                                      className={
                                                        classes.tableCell
                                                      }>
                                                      {' '}
                                                    </TableCell>
                                                  ))}
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </Grid>
                                      )
                                    }
                                  })}
                                </Grid>
                              )
                            }
                          })}{' '}
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                } else if (selected?.length || Object.keys(selected)?.length) {
                  const customSort = (a, b) => {
                    const indexA = Array.from(selected)?.indexOf(a.itemId)
                    const indexB = Array.from(selected)?.indexOf(b.itemId)
                    return indexA - indexB
                  }

                  const sordingPrivewListData = cat?.itemList?.sort(customSort)
                  return (
                    <Accordion
                      key={`accor_${key}`}
                      expanded={true}
                      className={classes.acc}>
                      <AccordionSummary
                        aria-controls={`item_${key}-content`}
                        id={`item_${key}-header`}
                        className={classes.accHead}>
                        <Grid item xs={10}>
                          <Typography className={classes.heading}>
                            {cat.capablityname}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}></Grid>
                      </AccordionSummary>
                      <AccordionDetails className={classes.accDetail}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justifyContent="center"
                          style={{ marginTop: '2px' }}>
                          <Grid item xs={12}>
                            {/* <p style={{ margin: '0px' }}>{cat.zDescription}</p> */}
                          </Grid>
                          {!!cat?.itemList?.length &&
                            cat?.itemList?.map((item, index) => {
                              if (
                                Array.from(sordingPrivewListData)?.includes(
                                  item?.itemId
                                )
                              ) {
                                questionNumber++
                                return (
                                  <Grid item xs={12}>
                                    {' '}
                                    <p>
                                      {questionNumber}. {item.itemname}
                                    </p>
                                    <TableContainer
                                      component={Paper}
                                      className={classes.tablecontainer}>
                                      <Table
                                        stickyHeader
                                        size="small"
                                        className={classes.tableCell}>
                                        <TableHead>
                                          <TableRow>
                                            {scaleRating.map((record) => (
                                              <TableCell
                                                className={classes.tableCell}
                                                style={{
                                                  backgroundColor: '#D0E9FC',
                                                  maxWidth: '100px',
                                                  width: '100px',
                                                  padding: '0px',
                                                  textAlign: 'center',
                                                }}>
                                                {SurveyTable(record)}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableHead>

                                        {/* Table Header */}
                                        <TableBody>
                                          <TableRow
                                            className={classes.tableRow}>
                                            {scaleRating.map((record) => (
                                              <TableCell
                                                align="left"
                                                className={classes.tableCell}>
                                                {' '}
                                              </TableCell>
                                            ))}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Grid>
                                )
                              }
                            })}
                          {!!cat.compentencylist.length &&
                            cat.compentencylist.map((sub) => {
                              console.log('jjjjjjgggg', sub.Itemlist)
                              let selectedItem =
                                NewSelectedItemsIdReducer[
                                  CUSTOM_ITEM_ + cat.capablityid
                                ]?.[
                                  SUB_CUSTOM_ITEM_ +
                                    cat.capablityid +
                                    '_' +
                                    sub.compentencyid
                                ] || []

                              if (selectedItem.length) {
                                return (
                                  <Grid item xs={12}>
                                    <Accordion
                                      key={`accor_${key}`}
                                      expanded={true}
                                      className={classes.acc}>
                                      <AccordionSummary
                                        aria-controls={`item_${key}-content`}
                                        id={`item_${key}-header`}
                                        className={classes.accHeadInside}>
                                        {' '}
                                        <p>{sub.compentencyname}</p>
                                      </AccordionSummary>
                                    </Accordion>

                                    <p>{sub.zDescription}</p>
                                    {sub.Itemlist.map((item, index) => {
                                      console.log(item, selectedItem)
                                      if (selectedItem.includes(item.itemId)) {
                                        questionNumber++
                                        return (
                                          <Grid item xs={12}>
                                            <b>
                                              {' '}
                                              <p>
                                                {questionNumber}.{' '}
                                                {item.itemname}
                                              </p>
                                            </b>
                                            <TableContainer
                                              component={Paper}
                                              className={
                                                classes.tablecontainer
                                              }>
                                              <Table
                                                stickyHeader
                                                size="small"
                                                className={classes.tableCell}>
                                                <TableHead>
                                                  <TableRow>
                                                    {scaleRating.map(
                                                      (record) => (
                                                        <TableCell
                                                          className={
                                                            classes.tableCell
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              '#D0E9FC',
                                                            maxWidth: '100px',
                                                            width: '100px',
                                                            padding: '0px 2px',
                                                            textAlign: 'center',
                                                          }}>
                                                          {SurveyTable(record)}
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                  <TableRow
                                                    className={
                                                      classes.tableRow
                                                    }>
                                                    {scaleRating.map(
                                                      (record) => (
                                                        <TableCell
                                                          align="left"
                                                          className={
                                                            classes.tableCell
                                                          }>
                                                          {' '}
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Grid>
                                        )
                                      }
                                    })}
                                  </Grid>
                                )
                              }
                            })}
                          <Grid item xs={12}></Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )
                }
              })}
              {/* {NewCustomItemsReducer.map((cat, key) => {
                let selected =
                  NewSelectedItemsIdReducer[CUSTOM_ITEM_ + cat?.capablityid] ||
                  []

                  console.log(NewSelectedItemsIdReducer[CUSTOM_ITEM_ + cat?.capablityid],"NewSelectedItemsIdReducer");
                  const customSort = (a, b) => {
                    const indexA = selected?.indexOf(a.itemId);
                    const indexB = selected?.indexOf(b.itemId);
                    return indexA - indexB;
                  };

                  const sordingPrivewListData =  cat?.itemList?.sort(customSort)
                  console.log(sordingPrivewListData,"sordingPrivewListData",cat?.itemList);
                if (selected.length || Object.keys(selected).length) {
              
                }
              })} */}
            </Grid>
            {/* </div> */}
          </Grid>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
