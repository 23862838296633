import {
  Divider,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import arrow from '../../assets/images/arrow.png'
import cube from '../../assets/images/cube.png'
import circle from '../../assets/images/circle.png'
import square from '../../assets/images/square.png'
import {
  BASICINFORMATION,
  PROJECTS_PATH,
  PROJECT_SETUP_TYPE,
  TEAMSURVEYVIEW,
} from '../../helpers/constants/RoutePaths'
import CustomButton from '../common/CustomButton'
import Paper from '@material-ui/core/Paper'

import {
  GET_PROJECT_LIST_URL,
  GET_INVENTORY_COUNT,
  GET_PROJECT_LIST_ALL_URL,
  GET_THREE_SIXTY_PROJECT_LIST,
} from '../../helpers/constants/ApiRoutes'
import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../common/CustomFunction'
import { API, isTimeout } from '../../helpers/API'
import CircularProgress from '@mui/material/CircularProgress'

import moment from 'moment'
import { getComparator, stableSort } from '../../function/HelperFunction'
import CustomTableHeader from '../common/CustomTableHeader'
import TranslateComp from '../../helpers/constants/TranslateComp'
import TimeOutPopup from '../../helpers/TimeOutPopup'

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: theme.outShadow,
  },
  title: {
    fontSize: '16px',
    fontFamily: 'Roboto-Bold !important',
    margin: 0,
    letterSpacing: '0px',
    color: '#EEAF1F',
    opacity: 1,
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  paper: {
    // padding: theme.spacing(2),
    height: '12rem',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    boxShadow: 'unset',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'flex-direction': 'column',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  surveyBtn: {
    backgroundColor: theme.successColor,
    minWidth: 'max-content',
  },
  heading: {
    fontFamily: 'Nunito-SemiBold',
    textAlign: 'left',
    fontSize: '24px !important',
    letterApacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  headingbody: {
    textAlign: 'left',
    letterSpacing: '0.08px',
    color: '#0000008A',
    opacity: 1,
    fontSize: '14px !important',
  },
  dashboardcontent: {
    height: '10rem',
    margin: 'unset',
  },
  tblHead: {
    flex: '1 1 100%',
    // fontWeight: '600 !important',
    letterSpacing: '0.06px',
  },
  tablecontainer: {
    marginTop: '1rem',
    // maxHeight: 440,
    minHeight: '40vh',
    //overflowX: 'hidden',
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '18px 2px 6px 10px',
    },
  },
  bodycont: {
    background: 'white',
    color: 'black',
    marginTop: '2rem',
  },
  tablecell: {
    padding: '5px 0',
  },
  bodyheading: {
    padding: '1rem',
  },
  dashboardallign: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'flex-direction': 'column',
  },
}))

export default function Dashboard() {
  const classes = useStyles()
  const [survey360Count, setSurvey360] = useState(0)
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('zName')
  const [projects, setProjects] = useState([])
  const [loading, setloading] = useState(false)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    console.log(property, 'property')
    setOrderBy(property)
  }
  const dates = [
    new Date('March 20, 2022 20:17:40'),
    new Date('July 20, 2022 20:17:40'),
    new Date('August 1, 2021 23:15:30'),
    new Date('March 13, 2024 04:20'),
    new Date('October 14, 2021 11:05'),
  ]

  dates.sort((date1, date2) => date1 - date2)
  console.log('date sort', dates)
  const [TeamPerformanceSurveys, setTeamPerformanceSurveys] = useState(0)
  const history = useHistory()
  const getAvailableCount = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: GET_INVENTORY_COUNT,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    try {
      const response = await API(config)
      const resultData = response.data
      const { FeedbackSurveys360, TeamPerformanceSurveys } = resultData
      setSurvey360(FeedbackSurveys360)
      setTeamPerformanceSurveys(TeamPerformanceSurveys)
      console.log('feed', FeedbackSurveys360, TeamPerformanceSurveys)
    } catch (error) {
      console.error(error)
    }
  }
  const getProjectList = async () => {
    setloading(true)
    const postConfig = {
      // url: GET_THREE_SIXTY_PROJECT_LIST,
      // params: {
      //   UserId: getUserId(),
      //   SiteLvl: 'true',
      //   OrganizationID: getUserId(),

      //   ProjectType: '2',
      // },
      url: GET_THREE_SIXTY_PROJECT_LIST,
      method: 'POST',
      headers: authHeader(),
      data: {
        userId: getUserId(),
        organizerId: getUserId(),
        ownerId: getUserId(),
        projectTypeId: '',
        siteLvl: 'true',
        projectID: 0,
        organizationID: getOrganizationId(),
      },
    }
    try {
      const response = await API(postConfig)
      const resultData = response.data
      const { Survey360Count, TeamPulseCount, Data } = resultData
      // setSurvey360(Survey360Count)
      // setTeamPerformanceSurveys(TeamPulseCount)
      console.log('data dashboard', Data)
      // const filterData = Data
      //   .filter((result) => result.zProjectTypeID === 2)
      //   .map((val) => ({ ...val, zDateCreatedFormat:moment(val.zDateCreatedFormat).format('YYYY/MM/DD') || 'N/A' }))
      setProjects(Data)
      // setSurvey360(resultData.length)
    } catch (error) {
      console.error(error)
    } finally {
      setloading(false)
    }
  }
  console.log('project', projects)
  const goTo360 = () => {
    history.push(PROJECT_SETUP_TYPE)
  }
  const goToteam = () => {
    history.push(BASICINFORMATION)
  }
  useEffect(() => {
    console.log('dashboard call')
    getProjectList()
    getAvailableCount()
  }, [])
  useEffect(() => {
    if (isTimeout) {
      setTimeout(() => {
        localStorage.setItem('WARNING_MSG', true)
        window.location.reload(true)
      }, 60 * 60 * 1000) // 1 hour timeout
    }
  }, [])

  var tableColumns = [
    { id: 'zName', title: 'Name', sort: true },
    { id: 'zProjectTypeID', title: 'Type', sort: true },
    { id: 'zProgressText', title: 'Progress', sort: true },
    { id: 'zDateCreatedFormat', title: 'Launch date', sort: true },
    { id: 'zProjectStatusName', title: 'Status', sort: true },
    { id: 'zOwnerName', title: 'Owner Name', sort: true },
  ]
  const tableHeadProps = { tableColumns }
  console.log(process.env, 'Emv')
  const projectType = {
    2: '360',
    7: 'Team',
  }

  const handleInsetUp = (id) => {
    console.log(id, 'haiaiis')
    if (id === 'In Setup') {
      localStorage.setItem('PREVIOUS_PROJECT', true)
    } else {
      localStorage.removeItem('PREVIOUS_PROJECT')
    }
  }

  return (
    <div>
      <TimeOutPopup openfl={isTimeout} />
      {/* <TranslateComp /> */}
      {/* <Typography variant="h6" className={classes.heading} gutterBottom>
        Welcome!
      </Typography>

      <Typography className={classes.headingbody} variant="body2" gutterBottom>
        Select a tool to get started (available inventory shown below tool
        buttons).
      </Typography>
      <Divider /> */}
      <Grid
        // spacing={5}
        container
        direction="row"
        justifyContent="space-around"
        style={{ marginTop: '1rem', display: 'flex' }}
        alignItems="center">
        <Grid container xs={5}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              <img src={arrow} alt="logo" width="60" height="60" />
              <center>
                <h4 className={classes.title} style={{ color: '#EEAF1F' }}>
                  360{' '}
                  <sup style={{ fontSize: '10px', marginLeft: '-3px' }}>o</sup>{' '}
                  Survey
                </h4>
              </center>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper
              style={{
                background: '#FFC7001A',

                display: 'flex',

                'align-items': 'center',
                'justify-content': 'center',
                'flex-direction': 'column',
              }}
              className={classes.paper}>
              <Button
                style={{ backgroundColor: '#1565C0' }}
                size="large"
                onClick={goTo360}
                variant="contained"
                color="primary">
                Create New 360 Project
              </Button>
              <div>Surveys Available:{survey360Count}</div>
            </Paper>
          </Grid>
        </Grid>
        <Grid container xs={5}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>
              {' '}
              <div>
                <center>
                  <img src={circle} alt="logo" width="60" height="60" />
                </center>
              </div>
              <center>
                <h4
                  className={classes.title}
                  style={{ color: '#658193', maxHeight: '20px' }}>
                  Team Performance
                </h4>
              </center>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            <Paper
              style={{
                background: '#6581931A',

                display: 'flex',

                'align-items': 'center',
                'justify-content': 'center',
                'flex-direction': 'column',
              }}
              className={classes.paper}>
              <Button
                style={{ backgroundColor: '#1565C0' }}
                size="large"
                onClick={goToteam}
                variant="contained"
                color="primary">
                Create New Team Project
              </Button>
              <div>Surveys Available:{TeamPerformanceSurveys}</div>
            </Paper>
          </Grid>
        </Grid>

        <Grid className={classes.bodycont} container xs={12}>
          <Grid container xs={12}>
            <Typography variant="h5" className={classes.bodyheading}>
              All projects
            </Typography>

            <TableContainer
              component={Paper}
              className={classes.tablecontainer}>
              <Table stickyHeader size="small" className={classes.customTable}>
                {/* Table Header */}
                {/* <TableHead>
                  <TableRow>
                    {tableColumns.map((t) => {
                      return <TableCell>{t.title}</TableCell>
                    })}
                  </TableRow>
                </TableHead> */}
                <CustomTableHeader
                  {...tableHeadProps}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                {/* Table Header */}

                {/* Table Body */}
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <div className={classes.circularloader}>
                          <CircularProgress size={28} />
                        </div>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {!loading && (
                  <TableBody>
                    {stableSort(projects, getComparator(order, orderBy)).map(
                      (project, index) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell className={classes.tablecell}>
                                <Link
                                  to={`${
                                    project.zProjectTypeID == 7
                                      ? project.zProjectStatusID === 1
                                        ? `/teamsurveyprojects/setup/${project.zProjectID}`
                                        : `/teamsurveyprojects/view/${project.zProjectID}`
                                      : project.zProjectStatusID === 1
                                      ? `/projects/edit/${project.zProjectID}`
                                      : `/projects/view/${project.zProjectID}`
                                  }`}
                                  onClick={() =>
                                    handleInsetUp(project?.zProjectStatusName)
                                  }
                                  className={classes.linkStyle}>
                                  {project.zName}
                                </Link>
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {projectType[project.zProjectTypeID]}
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {project.zProgress}% {project.zProgressText}
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {moment(project.zDateCreatedFormat).format(
                                  'MM/DD/YYYY'
                                )}
                              </TableCell>

                              <TableCell className={classes.tablecell}>
                                {project.zProjectStatusName}
                              </TableCell>
                              <TableCell className={classes.tablecell}>
                                {project.zOwnerName}
                              </TableCell>
                            </TableRow>
                          </>
                        )
                      }
                    )}

                    {/* <CustomTableEmptyRecord
                      columnsCount={tableColumns.length}
                    /> */}
                  </TableBody>
                )}
                {/* Table Body */}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
