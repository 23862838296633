import { React, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {
  Grid,
  ListItem,
  ListItemText,
  List,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from '@material-ui/core'
import CustomButton from '../../../../../common/CustomButton'
import {
  GET_SCALE_RATING_BY_SCALE_ID,
  TASK_FINALIZE,
  TASK_RESET,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import ViewSurveyDetails from './ViewSurveyDetails'
import NewThinkwisepreview from './NewThinkwisepreview'
import { getAllthinkwise } from '../../../../../../redux/action'
import CustomLoader from '../../../../../common/CustomLoader'
import { setLocale } from 'yup'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },

  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  listContainer: {
    padding: '0px',
  },
  closesurveybtn: {
    marginTop: '8px',
  },
  viewsurveybtn: {
    marginTop: '30px',
  },
  boxunderstatus: {
    marginTop: '40px',
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      padding: '6px 2px 6px 10px',
    },
    borderWidth: 1,
    borderColor: '#DCDCDC',
    borderStyle: 'solid',
    height: '100%',
  },
  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    width: '5%',
    borderWidth: 1,
    borderColor: '#DCDCDC',
    borderStyle: 'solid',
    height: '100%',
    backgroundColor: 'white',

    // or borderTop: '1px solid red'
  },
  tableRow: {
    height: 50,
  },
  heading: {
    fontFamily: 'Nunito-Bold !important',
  },
  subheading: {
    fontFamily: 'Roboto-Regular !important',
  },
}))

const DetailsAdmin = ({ projectDetails, getProject, scaleTypeLists }) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  // const [scaleTypeLists, setScaleTypeLists] = useState([])
  const [checked, setChecked] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [opLoading, setOpLoading] = useState(false)
  const { id = '' } = useParams()
  const taskList = useSelector((state) => state.TaskList)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleCheckChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleCloseSurvey = async () => {
    setLoading(true)
    const task = taskList.find((rec) => rec.zProjectID == id)
    const config = {
      url: TASK_FINALIZE,
      method: 'PUT',
      headers: authHeader(),

      data: {
        taskUserID: task.zTaskUserID,
        //taskUserID: 3,
        projectID: id,
        userID: getUserId(),
      },
    }
    try {
      const response = await API(config)

      if (response.status === 200) {
        const { message } = response.data
        getProject()
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  const handleReopen = async () => {
    setOpLoading(true)
    const config = {
      url: TASK_RESET,
      method: 'GET',
      headers: authHeader(),

      params: {
        ProjectID: id,
        UserID: getUserId(),
      },
    }
    try {
      const response = await API(config)
      if (response.status === 200) {
        const { message } = response.data
        getProject()
        CustomMessage(message, 'success', enqueueSnackbar) //call toster
      }
    } catch (error) {
      console.error(error)
    } finally {
      setOpLoading(false)
    }
  }
  const [survey, setSurvey] = useState(false)
  const viewSurvey = () => {
    dispatch(getAllthinkwise(id))

    setSurvey(!survey)
  }

  return (
    <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
      <Grid item xs={3}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}>
          <Tab label="Project Status" {...a11yProps(0)} />
          <Tab label="Scale & View Survey" {...a11yProps(1)} />
        </Tabs>
      </Grid>
      <Grid item xs={9}>
        <TabPanel value={value} index={0}>
          <Grid>
            <p className={classes.heading}>
              <b>Project Status:</b>{' '}
              {projectDetails && projectDetails.zProjectStatusName}
            </p>
            <Box className={classes.boxunderstatus}>
              <p className={classes.subheading}>
                {projectDetails &&
                projectDetails.zProjectStatusName == 'In Progress'
                  ? 'Upon closing this survey any outstanding respondents will not be able to finish their survey.'
                  : 'Upon reopening this survey any outstanding respondents will be able to finish their survey.'}
              </p>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                      checked={checked}
                      onChange={handleCheckChange}
                    />
                  }
                  className={classes.subheading}
                  //label="I understand and wish to close this survey"
                  label={
                    projectDetails.zProjectStatusName === 'In Progress'
                      ? 'I understand and wish to close this survey.'
                      : 'I understand and wish to reopen this survey to allow for additional responses.'
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CustomButton
                  className={classes.closesurveybtn}
                  disabled={!checked}
                  colour={'primaryButton'}
                  type="button"
                  onClick={() =>
                    projectDetails.zProjectStatusName === 'In Progress'
                      ? handleCloseSurvey()
                      : handleReopen()
                  }>
                  {loading && <CustomLoader loading={loading} />}
                  {opLoading && <CustomLoader loading={opLoading} />}
                  {projectDetails.zProjectStatusName === 'In Progress'
                    ? 'Close Survey'
                    : 'Reopen Survey'}
                </CustomButton>
              </Grid>
            </Box>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid>
            <p>
              <b>Project Scale:</b> {projectDetails.zScaleName}
            </p>
            <Grid item>
              <TableContainer className={classes.tablecontainer}>
                <Table
                  stickyHeader
                  size="small"
                  className={classes.customTable}>
                  <TableHead>
                    <TableRow>
                      {scaleTypeLists.map((list) => (
                        <TableCell className={classes.tableCell}>
                          {list.zName}
                          <br />
                          {list.zDescription}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
              <Grid item xs={12}>
                <CustomButton
                  className={classes.viewsurveybtn}
                  colour="primaryButton"
                  type="button"
                  onClick={viewSurvey}>
                  View Survey
                </CustomButton>
                {/* {survey && ( */}
                <NewThinkwisepreview
                  open={survey}
                  handleReset={viewSurvey}
                  title={'View Survey'}
                  // items={projectDetails}
                  zScaleID={projectDetails.zScaleID}
                />
                {/* )} */}
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </Grid>
  )
}

export default DetailsAdmin
