import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import DeleteIcon from '@material-ui/icons/Delete'
import * as Yup from 'yup'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import EditIcon from '@material-ui/icons/Edit'

import {
  EDIT_ROSTER_FIELD,
  DELETE_ROSTER_FIELD,
  ROSTER_LIST,
} from '../../../../../../redux/action/ActionType'
import { useDispatch, useSelector } from 'react-redux'
import {
  GET_USER_RELATIONSHIP_LIST,
  SEND_GRID_RESPONDENT_INSERT,
  SEND_ORIGINAL_EMAIL_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { useParams } from 'react-router'
import ReminderEmail from './ReminderEmail'
import Editrater from './Editrater'
import Deleterater from './Deleterater'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
    width: '100%',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  customTable: {
    '& .MuiTableCell-sizeSmall': {
      maxWidth: '150px',
      padding: '6px 2px 6px 10px',
    },
  },
  tablegrid: {
    width: '100%',
  },
}))
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

const ParticipantInitial = [
  {
    firstName: 'sathish',
    lastName: 'k',
    rater: 'rather',
    list: [
      {
        firstName: 'name1',
        lastName: 'name2',
        email: 'sample@123',
        status: 'Not Started 0%-0 of 109 items',
        relationship: 'Rater Relationship',
      },
    ],
  },
]
const validationSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .trim()
    .required('Email is required'),
  rater: Yup.string()
    .trim()
    .required('Rater relationship is required'),
})
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  list: [
    {
      firstName: 'name1',
      lastName: 'name2',
      email: 'sample@123',
      status: 'Not Started 0%-0 of 109 items',
      relationship: 'Rater Relationship',
    },
  ],
  rater: 'Self',
}
export default function ListParticipant(props) {
  const dispatch = useDispatch()
  let params = useParams()
  console.log(props, 'paarrrrrra')
  const tableColumns = [
    { id: 'lname', title: 'Last Name', sort: true },
    { id: 'fname', title: 'First Name', sort: true },
    { id: 'email', title: 'Email', sort: true },
    { id: 'invitation', title: 'Invitation', sort: true },
    { id: 'action', title: '', sort: true },
  ]

  const tableHeadProps = { tableColumns }
  const classes = useStyles()
  const [expandedList, setExpanded] = useState([])
  const [deleterater, setDeleterater] = useState(false)
  const [roster, setRoster] = useState(0)
  const [remindFlag, setRemindFlag] = useState(false)
  const [editOption, seteditOption] = useState(false)
  const rosterList = useSelector((s) => s.RosterReducer)
  console.log('rosterlist', rosterList)
  console.log(editOption, 'editOption')
  const [pid, setPid] = useState(0)

  const {
    participantList,
    raterList,
    respondantList,
    getRespondant,
    respondentSendGridAllDdetails,
    informationShow,
    getParticipantList,
    getParticipant,
    getCountParticipant,
  } = props
  useEffect(() => {
    getRespondant()
  }, [])
  console.log('rater', raterList)
  console.log('participant', participantList)
  console.log('respondant', respondantList)

  // const sendReminder = (e,zUserID) => {
  //   console.log(zUserID,'zUserIDzUserIDzUserID');
  //   e.stopPropagation()
  //   Swal.fire('Are you sure send Reminder?', {
  //     buttons: ['No', 'Yes'],
  //   }).then((value) => {})
  //   // newRosterList[rosterid] = [...newRosterList[rosterid]]
  //   // setid('7')
  // }

  const sendReminder = (e, zUserID) => {
    console.log(zUserID, 'zUserIDzUserIDzUserID')
    e.stopPropagation()
    // const { rosterid } = e.currentTarget.dataset
    const rosterid = zUserID
    // console.log('id', rosterid, e.currentTarget.dataset.rosterid)
    setRoster(rosterid)
    setRemindFlag(!remindFlag)
  }

  const resetFun = (value) => {
    if (value == 'sent') {
      Swal.fire({
        title: "<h5 style='color:#fff'>Email was sent successfully...</h5>",
        background: '#00cc00',
      })
      setRemindFlag(!remindFlag)
      setRoster(0)
    } else {
      setRemindFlag(!remindFlag)
      setRoster(0)
    }
  }

  const deleteraterDialog = (value) => {
    if (value == 'sent') {
      // CustomMessage('User Invited', 'success', enqueueSnackbar)
      alert.success('Invitation sent')
    }
    setDeleterater(!deleterater)
  }

  const sendOriginal = (e, zUserID, zParticipantID) => {
    console.log(e, zUserID, zParticipantID, 'e.currentTarget.dataset')
    e.stopPropagation()
    Swal.fire('Are you sure send Original?', {
      buttons: ['No', 'Yes'],
    }).then((result) => {
      console.log(result, 'vlaaaaaaaa')
      if (result.isConfirmed) {
        postSendGridRespondentInsert(zUserID, zParticipantID)
      }
    })
    // newRosterList[rosterid] = [...newRosterList[rosterid]]
    // setid('7')
  }

  const editRater = (e, zUserID) => {
    e.stopPropagation()
    console.log(e.currentTarget.dataset, 'dddddddddd')
    console.log(e.currentTarget, 'oooooooo')
    console.log(e, zUserID, 'lllllllll')
    // const { rosterid, rid } = e.currentTarget.dataset
    seteditOption(!editOption)
    setPid(zUserID)
    // dispatch({
    //   type: EDIT_ROSTER_FIELD,
    //   payload: zUserID,
    //   actionnew: editOption,
    // })
  }

  const cancelEdit = (e, zUserID) => {
    console.log(e.currentTarget.dataset)
    seteditOption(!editOption)
    setPid(pid)
    // dispatch({
    //   type: EDIT_ROSTER_FIELD,
    //   payload: rosterid,
    //   actionnew: editOption,
    // })
  }

  const deleteRater = (e, zUserID) => {
    e.stopPropagation()
    setDeleterater(true)
    // console.log(e.currentTarget);
    const { rosterid, rid } = e.currentTarget.dataset
    setPid(zUserID)
    console.log('ros delete', rosterid)
    dispatch({ type: DELETE_ROSTER_FIELD, payload: zUserID })
    // newRosterList[rosterid] = [...newRosterList[rosterid]]
    // setid('7')
  }

  const handleAccordion = (value) => {
    let currentList = [...expandedList]
    if (!currentList.includes(value)) {
      currentList.push(value)
      setExpanded(currentList)
    } else {
      currentList = currentList.filter((list) => list != value)
      setExpanded(currentList)
    }
  }

  const postSendGridRespondentInsert = async (zUserID, zParticipantID) => {
    // setLoading(true)
    const config = {
      method: 'POST',
      url: SEND_GRID_RESPONDENT_INSERT,
      data: {
        zParticipantID: 0,
        zProjectID: params?.id,
        zRespondentID: zUserID,
      },
    }
    try {
      const response = await API(config)
      console.log(response, 'responseee')
      const { data } = response
      console.log('.response', data)
      sendOrginalmail(zUserID, zParticipantID, data?.data)
      // if (data.status === 'Success') {

      // }
      // setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  const sendOrginalmail = async (resid, userid, SendgridID) => {
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: SEND_ORIGINAL_EMAIL_URL,
      params: {
        UserID: userid,
        respondentID: resid,
        BaseUrl: '' + window.location.origin + '/signin/',
        ProjectID: params?.id,
        RespondentSendgridID: SendgridID,
        // testEmail: mail,
        // surveyLinkURL: '' + window.location.origin + '/signin/',
        // sendTest: false,
      },
    }
    try {
      const response = await API(config)
      if (response.status === 204) {
        Swal.fire({
          title: "<h5 style='color:#fff'>Email was sent successfully</h5>",
          background: '#00cc00',
        }).then((value) => {})
        //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
        //getParticipantList()
      }
    } catch (error) {
      CustomMessage('Email Not Send', 'error') //call toster
      console.log(error)
    }
  }

  // Get Participant List

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      style={{ marginTop: '2px', padding: '0px 30px' }}>
      <Accordion className={classes.acc} fullWidth>
        <AccordionDetails style={{ padding: '0px' }}>
          <TableContainer component={Paper} className={classes.tablecontainer}>
            <Table stickyHeader size="small">
              {/* Table Header */}
              {/* Table Header */}

              {/* Table Body */}
              <TableBody>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">First Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Status</TableCell>
                {/* <TableCell align="center">Email Status</TableCell> */}
                <TableCell align="center">Invitation</TableCell>
                <TableCell align="left"></TableCell>
                <TableRow></TableRow>
                {Object.values(respondantList).map((element, key) => {
                  const {
                    zUserFullName,
                    zParticipantID,
                    zFirstName,
                    zLastName,
                    zUserID,
                    zEmail,
                    zTaskStatusName,
                    zProgressText,
                  } = element
                  const data12 = []
                  const array = []
                  const completeListSplit = zProgressText.split('-')
                  const ZprogressValue =
                    completeListSplit[completeListSplit.length - 1]

                  console.log(element, 'ele,eeneteu')
                  console.log(zUserID, pid, 'data12data12')
                  // console.log(data1, 'data12data12')
                  return (
                    <>
                      <TableRow>
                        <TableCell align="left">{zLastName}</TableCell>
                        <TableCell align="left">{zFirstName}</TableCell>
                        <TableCell align="left">{zEmail}</TableCell>
                        <TableCell align="left">
                          {zTaskStatusName}
                          <br />
                          {ZprogressValue}
                        </TableCell>
                        {/* <TableCell align="center">
                          {respondentSendGridAllDdetails.map((data, index) => {
                            console.log(data, 'dataaaaaaaa')
                            if (
                              array.every((item) => item != data.zRespondentID)
                            ) {
                              array.push(data.zRespondentID)
                            }
                            if (
                              array.every((item) => item == data.zRespondentID)
                            ) {
                              data12.push(data)
                            }
                            if (
                              // data.zRespondentID == zRespondentID &&
                              data.zRespondentID == zUserID
                            ) {
                              // multiple email status condition
                              let responce = data12?.filter((item) => {
                                console.log(item, 'itemmmmmm')
                                if (item?.zEvent === 'delivered') {
                                  return item?.zEvent
                                }
                                // else if(data12.every(
                                //   (data) =>
                                //     data.zEvent == "processed" && data.zEvent != "delivered"
                                //     )){
                                //       return item?.zEvent
                                // }
                              })
                              return (
                                <>
                                  {responce.length > 0 ? (
                                    <Button
                                      style={{
                                        padding: '0px !important',
                                      }}
                                      onClick={() =>
                                        informationShow(
                                          // zRespondentID,
                                          // zParticipantID
                                          zUserID
                                        )
                                      }>
                                      <Grid
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}>
                                        <Box
                                          sx={{
                                            width: '10px',
                                            height: '10px',
                                            borderRadius: '50%',
                                            backgroundColor:
                                              data?.zEvent === 'dropped'
                                                ? 'red'
                                                : data?.zEvent === 'processed'
                                                ? 'yellow'
                                                : data?.zEvent === 'delivered'
                                                ? 'green'
                                                : data?.zEvent === 'bounce'
                                                ? 'blue'
                                                : data?.zEvent === 'deferred '
                                                ? 'blue'
                                                : 'blue',
                                            marginRight: '8px',
                                          }}></Box>
                                        <span
                                          style={{
                                            textTransform: 'capitalize',
                                          }}>
                                          {data?.zEvent === 'dropped'
                                            ? 'Not Delivered'
                                            : data?.zEvent}
                                        </span>
                                      </Grid>
                                    </Button>
                                  ) : (
                                    <>
                                      {responce[0]?.zEvent.length > 0 ? (
                                        <Button
                                          style={{
                                            padding: '0px !important',
                                          }}
                                          onClick={() =>
                                            informationShow(
                                              // zRespondentID,
                                              zParticipantID
                                            )
                                          }>
                                          <Grid
                                            style={{
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center',
                                            }}>
                                            <Box
                                              sx={{
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                backgroundColor:
                                                  data?.zEvent === 'dropped'
                                                    ? 'red'
                                                    : data?.zEvent ===
                                                      'processed'
                                                    ? 'yellow'
                                                    : data?.zEvent ===
                                                      'delivered'
                                                    ? 'green'
                                                    : data?.zEvent === 'bounce'
                                                    ? 'blue'
                                                    : data?.zEvent ===
                                                      'deferred '
                                                    ? 'blue'
                                                    : 'blue',
                                                marginRight: '8px',
                                              }}></Box>
                                            <span
                                              style={{
                                                textTransform: 'capitalize',
                                              }}>
                                              {responce[0]?.zEvent}
                                            </span>
                                          </Grid>
                                        </Button>
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  )}
                                </>
                              )
                            }
                          })}
                        </TableCell> */}
                        <TableCell align="center">
                          <Button
                            size="sm"
                            color="default"
                            style={{
                              textTransform: 'none',
                              padding: '2px',
                            }}
                            data-title="Send Original"
                            data-name={zFirstName}
                            data-lname={zLastName}
                            data-mail={zEmail}
                            data-resid={zUserID}
                            data-userid={zParticipantID}
                            onClick={
                              // e.stopPropagation()
                              (e) => sendOriginal(e, zUserID, zParticipantID)
                            }>
                            <span>Send Original</span>
                          </Button>
                          <Divider variant="fullWidth" />
                          <Button
                            size="sm"
                            color="default"
                            style={{
                              textTransform: 'none',
                              padding: '2px',
                            }}
                            data-title="Send Remainder"
                            data-resid={zUserID}
                            onClick={
                              // e.stopPropagation()
                              (e) => sendReminder(e, zUserID)
                            }>
                            <span>Send Reminder</span>
                          </Button>
                        </TableCell>
                        {/* <TableCell align="left">
                          <IconButton aria-label="edit" size="small">
                            <EditIcon
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              data-rosterid={zUserID}
                              onClick={
                                // e.stopPropagation()
                                (e) => editRater(e, zUserID)
                              }
                              fontSize="inherit"
                            />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            size="small"
                            style={{ marginLeft: '1rem' }}>
                            <DeleteIcon
                              onKeyPress={(e) => {
                                e.key === 'Enter' && e.preventDefault()
                              }}
                              data-rosterid={zUserID}
                              onClick={
                                // e.stopPropagation()
                               (e)=> deleteRater(e,zUserID)
                              }
                              fontSize="inherit"
                            />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                      {/* {editOption && zUserID == pid && 
                      <TableRow>
                      <TableCell
                        align="left"
                        colSpan={12}>
                        <Grid
                          className=""
                          container
                          direction="row">
                          <Editrater
                            classes={classes}
                            raterList={raterList}
                            firstName={
                              zFirstName
                            }
                            lastName={
                              zLastName
                            }
                            email={
                              zEmail
                            }
                            // relatonshipName={
                            //   zRelationshipName
                            // }
                            // relationshipName={
                            //   zRelationshipName
                            // }
                            // relationshipID={
                            //   zProjectUserRelationshipID
                            // }
                            getParticipantList = {getParticipantList}
                            getParticipant = {getParticipant}
                            getCountParticipant = {getCountParticipant}
                            userID={zUserID}
                            RespondantID={
                              zUserID
                            }
                            projectDetails={
                              props.projectDetails
                            }
                            projectID={params.id}
                            LoginUserID={getUserId()}
                            editRater={editRater}
                            cancelEdit = {cancelEdit}
                          />
                        </Grid>
                      </TableCell>
                    </TableRow>}
                    {deleterater && zUserID == pid &&
                     <TableRow>
                     <TableCell
                       align="left"
                       colSpan={12}>
                       <Grid
                         className=""
                         container
                         direction="row">
                         <Deleterater
                           classes={classes}
                           raterList={raterList}
                           firstName={
                             zFirstName
                           }
                           lastName={
                             zUserID
                           }
                           email={
                             zEmail
                           }
                          //  relationshipName={
                          //    zRelationshipName
                          //  }
                           open={deleterater}
                           projectName={
                             props.projectName
                           }
                           projectDetails={
                             props.projectDetails
                           }
                          //  UserRelationshipID={
                          //    zProjectUserRelationshipID
                          //  }
                           userID={zUserID}
                           handleReset={(
                             value
                           ) => {
                             deleteraterDialog(
                               value
                             )
                             getParticipantList()
                             getParticipant()
                             getCountParticipant()
                           }}
                         />
                       </Grid>
                     </TableCell>
                   </TableRow>} */}
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {remindFlag && (
        <ReminderEmail
          open={remindFlag}
          projectDetails={props.projectDetails}
          handleReset={(value) => resetFun(value)}
          rosterid={roster}
        />
      )}
    </Grid>
  )
}
