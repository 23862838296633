import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  DialogContent,
} from '@material-ui/core'
import prettier from 'prettier'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import html2canvas from 'html2canvas'
import { useEffect, useState } from 'react'
import { IconButton, Box } from '@material-ui/core'
import { Link, useParams } from 'react-router-dom'
import { handleGenerate } from '../../../../../../function/Word'
import { API } from '../../../../../../helpers/API'
import CloseIcon from '@material-ui/icons/Close'
import {
  GET_ORG_INFO_URL,
  GET_USER_RELATIONSHIP_LIST,
  LIST_PARTICIPANT,
  PROJECT_INDIVIDUAL_REPORT,
  TEAM_SEND_TEAMREPORT,
  USER_REPORT_INSERT,
} from '../../../../../../helpers/constants/ApiRoutes'
import { jsPDF } from 'jspdf'
import * as quillToWord from 'quill-to-word'

import CustomButton from '../../../../../common/CustomButton'
import SectionHeading from './innerComponents/SectionHeading'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Individual from './individualreport.json'
import { individualreportgeneration } from '../../../../../../function/individualreportgeneration'
import JSZip from 'jszip'
import { Packer } from 'docx'
import { saveAs } from 'file-saver'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../../../../../common/CustomFunction'
import Individualreporthtml from '../../../../../../function/Individualreporthtml'
import {
  compentencysampleimage,
  detailsimage,
  Main,
  openendimage,
  toptenimage,
} from '../../../../../../function/imageblob'
import SampleHtml from './SampleHtml'
import {
  THREESIXTYREPORT,
  THREESIXTYREPORTSELF,
} from '../../../../../../helpers/constants/RoutePaths'
import Swal from 'sweetalert2'
import moment from 'moment'
import CustomLoader from '../../../../../common/CustomLoader'
const useStyles = makeStyles((theme) => ({
  label: { fontFamily: 'Roboto-Bold !important' },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
  selectParticipant: {
    display: 'flex',
    '@media (max-width: 544px)': {
      display: 'block !important',
    },
  },
}))
export default function DetailsIndividualReport({
  projectDetails,
  scaleTypeLists,
  projectname,
}) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  // States
  const [userList, setUserList] = useState({})
  const [reportSections, setReportSections] = useState([])
  const [checkBtnValue, setCheckBtnValue] = useState(
    "5's section with learning Resources"
  )
  const [reportValues, setreportValues] = useState({
    IncludeOrgAverage: true,
    IncludeProjectAverage: true,
    SelfAssessment: true,
  })
  const [reportLinke, setreportLinke] = useState({})

  const [ParticipantID, setParticipantID] = useState([])
  const [ParticipantName, setParticipantName] = useState([])
  const [CompetencyID, setCompetencyID] = useState()
  const [progress, setProgress] = useState(10)

  const [reportLoading, setreportLoading] = useState(false)

  let params = useParams()
  console.log(params, 'paramsparams')
  // Get Participant List
  const getParticipantList = async () => {
    const config = {
      method: 'GET',
      // url: GET_USER_RELATIONSHIP_LIST,
      url: LIST_PARTICIPANT,

      headers: authHeader(),

      params: {
        ProjectID: params?.id,
      },
    }
    try {
      const response = await API(config)
      const { status, data } = response.data
      console.log('testjp', response.data)
      if (status == 'Success') {
        const userRelationShipList = {}
        data.map((rec) => {
          if (userRelationShipList && userRelationShipList[rec.zUserID]) {
            userRelationShipList[rec.zUserID].push(rec)
          } else {
            userRelationShipList[rec.zUserID] = [rec]
          }
        })
        console.log(userRelationShipList)
        setUserList(userRelationShipList)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const sendreport = (e) => {
    e.stopPropagation()
    const { name, userid, reportlink } = e.currentTarget.dataset
    Swal.fire({
      title: 'ARE YOU SURE?',
      text:
        'You have selected to send an email to ' +
        name +
        ' to download the 360 report . ',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        sendOrginalmail(userid, reportlink)
      } else {
      }
    })
  }

  const sendOrginalmail = async (userid, reportlink) => {
    let reportLink = window.location.origin + reportlink
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: TEAM_SEND_TEAMREPORT,
      data: {
        projectID: params?.id,
        zUserID: userid,
        reportURL: reportLink,
        ProjectTypeID: '2',
      },
    }
    const config1 = {
      method: 'POST',
      headers: authHeader(),
      url: USER_REPORT_INSERT,
      data: {
        reportName: projectDetails.zName,
        reportType: 'Individual',
        reportDate: moment(new Date()).format('YYYY-MM-DD'),
        projectID: params?.id,
        userID: userid,
        luid: getUserId(),
        reportLink: reportlink,
      },
    }
    try {
      const response = await API(config)
      console.log('respkns', response)
      if (response.status === 200) {
        const res = await API(config1)
        Swal.fire({
          title: "<h5 style='color:#fff'>Email was sent successfully</h5>",
          background: '#00cc00',
        }).then((value) => {})
        //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
        //getParticipantList()
      }
    } catch (error) {
      console.log(error)
    }
  }
  function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress style={{ height: '15px' }} />
        </Box>
      </Box>
    )
  }
  async function render(valueDate) {
    // console.log(valueDate)
    const [firstData] = valueDate
    var zip = new JSZip()

    // let html = await ReactDOMServer.renderToStaticMarkup(<SampleHtml />)

    let html = await ReactDOMServer.renderToStaticMarkup(
      <Individualreporthtml Individual={Individual} />
    )

    let htmlWDoc = '<!DOCTYPE html>' + html
    // let prettyHtml = prettier.format(htmlWDoc, { parser: 'html' })
    let outputFile = './output.html'
    var element = document.createElement('a')
    element.setAttribute(
      'href',
      'data:text/html;charset=utf-8, ' + encodeURIComponent(htmlWDoc)
    )
    return {
      documnt: htmlWDoc,
      name: `${firstData?.strPName}.html`,
    }

    zip.file(outputFile, htmlWDoc)
    zip.generateAsync({ type: 'blob' }).then(function(content) {
      // see FileSaver.js
      saveAs(content, 'example.zip')
    })
    element.setAttribute('download', `${firstData?.strPName}.html`)
    // document.body.appendChild(element)
    element.click()
    // <a onclick="this.href='data:text/html;charset=UTF-8,'+encodeURIComponent(document.documentElement.outerHTML)" href="#" download="page.html">Download</a>

    console.log(`Wrote ${outputFile}`)
  }

  function HelloWorldPage() {
    const [data] = Individual
    const {
      strReportTitle,
      strPName,
      strReportDesc,
      organizationName,
      projectName,
      ownerName,
      endDate,
      dueDateFormat,
      addSummaryCompetencyInstructions = [],
      addSummaryResults = [],
      addSummaryRespondentInstructions = [],
      addMatrix = [],
      addCompetencyResults = [],
      addTop10Instructions = [],
      addTop10 = [],
      addDataItems = [],
      addOpenEndedInstructions = [],
      addOpenEnded = [],
    } = data
    let marginnnone = { margin: 'unset' }

    return (
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <title>Hello world</title>
        </head>
        <body>
          <div>
            <div style={{ height: '3rem' }}> {organizationName}</div>
            <div
              style={{
                height: '15rem',
                display: 'flex',
                'align-items': 'center',
              }}>
              <div></div>
              <div>
                <h3 style={marginnnone}>360 Report</h3>
                <h3 style={marginnnone}>{strPName}</h3>
                <h3 style={marginnnone}>{strReportDesc}</h3>
              </div>
            </div>
            <div style={{ height: '3rem' }}>
              {projectName} {ownerName}
              {endDate} {dueDateFormat}
            </div>
          </div>
        </body>
      </html>
    )
  }
  var zip = new JSZip()
  const [orgInfo, setorgInfo] = useState(false)
  const organizationInfo = async (id) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORG_INFO_URL,
      params: {
        OrganizationId: getOrganizationId(),
      },
    }

    try {
      const response = await API(config)
      const { data } = response.data
      console.log('org data', data, data.zViewIncomplete360Report)
      setorgInfo(data.zViewIncomplete360Report)
      // setVal(data.zOrganizationID)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    organizationInfo()
  }, [])

  const handleGetReport = async () => {
    var lengthprogress = ParticipantID.length
    if (!lengthprogress) {
      CustomMessage(
        'Please choose atleast one participant',
        'error',
        enqueueSnackbar
      )
      return null
    }
    // if (Object.values(reportValues).every((v) => v == false)) {
    //   CustomMessage(
    //     'Please choose atleast one Report Section',
    //     'error',
    //     enqueueSnackbar
    //   )
    //   return null
    // }

    setreportLoading(true)
    let reportData = {}
    ParticipantID.map((dataid, index) => {
      let obj = {
        ProjectID: params?.id,
        IncludeOrgAverage: '' + reportValues.IncludeOrgAverage,
        IncludeProjectAverage: '' + reportValues.IncludeProjectAverage,
        SelfAssessment: '' + reportValues.SelfAssessment,
        SelectedUsers: dataid.id,
      }
      let reportLink
      console.log('report', reportValues)
      if (reportValues.SelfAssessment == true) {
        console.log('if reportself')
        reportLink =
          THREESIXTYREPORTSELF +
          '/' +
          Buffer.from(JSON.stringify(obj), 'binary').toString('base64')
        reportData[dataid.id] = reportLink
        console.log(dataid.name)
        setTimeout(() => {
          setreportLoading(false)
        }, 500)

        // zip.file(`${dataid.name}.txt`, `${reportLink}\n`)
      } else {
        reportLink =
          THREESIXTYREPORT +
          '/' +
          Buffer.from(JSON.stringify(obj), 'binary').toString('base64')
        reportData[dataid.id] = reportLink
        console.log(dataid.name)
      }
      setTimeout(() => {
        setreportLoading(false)
      }, 500)

      // zip.file(`${dataid.name}.txt`, `${reportLink}\n`)
    })
    setreportLinke(reportData)
    // zip.generateAsync({ type: 'blob' }).then(function(content) {
    //   // see FileSaver.js
    //   saveAs(content, '360Report.zip')
    // })
  }
  const generateZip = async (data) => {
    console.log(data)
    await data.generateAsync({ type: 'blob' }).then(function(content) {
      console.log('in')
      saveAs(content, '360Report')
    })
  }

  const handleReportSection = (e) => {
    const { name, checked } = e.target

    setreportValues((value) => ({ ...value, [name]: checked }))
  }

  const handleReportGapSection = (e) => {
    setCheckBtnValue(e.target.name)
  }

  useEffect(() => {
    getParticipantList()
  }, [])

  const getParticpent = (e) => {
    const { id, name } = e.currentTarget.dataset
    console.log(id, name)
    setParticipantID((val) => {
      let newvalue = [...val].map((va) => va.id)
      let compyvalue = [...val]
      if (newvalue.includes(id)) {
        return compyvalue.filter((item) => item.id !== id)
      } else {
        compyvalue.push({ id: id, name: name })
        return compyvalue
      }
    })
  }

  console.log('hi', ParticipantID)
  useEffect(() => {
    Main()
    compentencysampleimage()
    toptenimage()
    detailsimage()
    openendimage()
  }, [])

  const handleReset = () => {
    setreportLoading(false)
  }
  return (
    <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
      {/* <Grid item xs={12}>
        <Individualreporthtml Individual={Individual} />
      </Grid> */}
      <Grid item xs={12} md={6} lg={6}>
        <SectionHeading title="Select Participants" />
        {/* {<CustomLoader loading={reportLoading ? true : false} />} */}

        <div>
          <Dialog
            onClose={handleReset}
            aria-labelledby="simple-dialog-title"
            open={false}
            fullWidth="500"
            className={classes.root}>
            {/* <div> */}
            <DialogTitle id="simple-dialog-title" onClose={handleReset}>
              Report Progress
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleReset}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              dividers
              style={{ padding: '50px 30px 0px 30px', minHeight: '200px' }}>
              <LinearProgressWithLabel style={{ height: '30px' }} />
              <Typography style={{ marginTop: '30px' }}>
                This window will automatically close when all selected reports
                have been built. You can also close by clicking on the X.
              </Typography>
            </DialogContent>
          </Dialog>
        </div>
        <div style={{ padding: '15px' }}>
          {/*} <FormControlLabel
            control={
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
            label="Select All"
          />
          <br /> */}
          {Object.keys(userList).map((rec) => {
            const { zUserID, zUserFullName, zRespondentID } = userList[rec][0]
            let link = reportLinke[zUserID] || ''
            return (
              <>
                <div
                  className={classes.selectParticipant}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        inputProps={{
                          'aria-label': 'secondary checkbox',
                          'data-id': zUserID,
                          'data-name': zUserFullName,
                        }}
                        // disabled={
                        //   (projectDetails &&
                        //     projectDetails.zProjectStatusName == 'Completed') ||
                        //   orgInfo == true
                        //     ? projectDetails &&
                        //       projectDetails.zMgrViewReportFlag == true
                        //       ? false
                        //       : true
                        //     : true
                        // }
                        onChange={getParticpent}
                      />
                    }
                    label={zUserFullName}
                  />
                  {console.log('open ', link)}
                  {link ? (
                    <>
                      <Link to={link} target="_blank">
                        Open report
                      </Link>
                      <CustomButton
                        colour="#f9f9fc"
                        onClick={sendreport}
                        data-name={zUserFullName}
                        data-userid={zUserID}
                        data-reportlink={link}>
                        Share Report
                      </CustomButton>
                    </>
                  ) : (
                    ''
                  )}
                  <br />
                </div>
              </>
            )
          })}
        </div>
      </Grid>
      <Grid item xs={12} md={5} lg={5}>
        <SectionHeading title="Select Report Section" />
        <div style={{ padding: '15px' }}>
          {/*  <FormControlLabel
            control={
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
            label="Select All"
          />
          <br />*/}
          <FormControlLabel
            control={
              <Checkbox
                className={{ label: classes.label }}
                color="primary"
                inputProps={{
                  'aria-label': 'secondary checkbox',
                }}
                checked={reportValues.IncludeOrgAverage}
                name="IncludeOrgAverage"
                // disabled={
                //   (projectDetails &&
                //     projectDetails.zProjectStatusName == 'Completed') ||
                //   orgInfo == true
                //     ? projectDetails &&
                //       projectDetails.zMgrViewReportFlag == true
                //       ? false
                //       : true
                //     : true
                // }
                onChange={handleReportSection}
              />
            }
            label="Organizational Average"
          />
          <FormHelperText>
            Includes average for the organization in the Detail Section of the
            report.
          </FormHelperText>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                    fontFamily: 'Roboto-Bold !important',
                  }}
                  name="IncludeProjectAverage"
                  checked={reportValues.IncludeProjectAverage}
                  onChange={handleReportSection}
                  // disabled={
                  //   (projectDetails &&
                  //     projectDetails.zProjectStatusName == 'Completed') ||
                  //   orgInfo == true
                  //     ? projectDetails &&
                  //       projectDetails.zMgrViewReportFlag == true
                  //       ? false
                  //       : true
                  //     : true
                  // }
                />
              </>
            }
            label="Project Average"
          />
          <FormHelperText>
            Includes average for the project in the summary competency Results
            and Detail section
          </FormHelperText>
          <FormControlLabel
            control={
              <>
                <Checkbox
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                    fontFamily: 'Roboto-Bold !important',
                  }}
                  // disabled={
                  //   (projectDetails &&
                  //     projectDetails.zProjectStatusName == 'Completed') ||
                  //   orgInfo == true
                  //     ? projectDetails &&
                  //       projectDetails.zMgrViewReportFlag == true
                  //       ? false
                  //       : true
                  //     : true
                  // }
                  name="SelfAssessment"
                  checked={reportValues.SelfAssessment}
                  onChange={handleReportSection}
                />
              </>
            }
            label="Self Assessment"
          />
          <FormHelperText>
            {/* Includes average for the project in the summary competency Results
            and Detail section */}
          </FormHelperText>
          <Grid container>
            {/* {(projectDetails &&
              projectDetails.zProjectStatusName == 'Completed') ||
            orgInfo == true ? (
              projectDetails && projectDetails.zMgrViewReportFlag == true ? ( */}
            <>
              <Grid xs={6}></Grid>

              <Grid xs={12} md={7} lg={6} justifyContent="center">
                <>
                  <div
                    style={
                      {
                        // display: 'flex',
                        // justifyContent: 'flex-end',
                      }
                    }>
                    <CustomButton
                      style={{ width: '100%' }}
                      colour="primaryButton"
                      onClick={handleGetReport}>
                      {reportLoading && (
                        <CustomLoader loading={reportLoading ? true : false} />
                      )}
                      Get Reports
                    </CustomButton>
                  </div>
                </>
              </Grid>
            </>
            {/* ) : (
                <Typography>
                  Survey reports are not available for download
                </Typography>
              )
            ) : (
              <Typography>
                Participant and Group reports are unavailable until the project
                is finalized
              </Typography>
            )} */}
          </Grid>
        </div>
      </Grid>
    </Grid>
  )
}
