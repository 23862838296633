import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { EditorState, Modifier } from 'draft-js'
import { ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

export class CustomOption extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  }

  addStar = () => {
    const data = '<h3>Dear member!</h3><p>This is a <b>TEST</b></p>'
    let { contentBlocks, entityMap } = htmlToDraft(data)
    const { editorState, onChange } = this.props

    let contentState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    )

    onChange(EditorState.push(editorState, contentState, 'insert-fragment'))
  }

  render() {
    return <div onClick={this.addStar}>⭐</div>
  }
}
