import {
  DialogContent,
  MenuItem,
  Select,
  FormControl,
  Typography,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { IconButton, Box } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../common/CustomButton'
import { useParams } from 'react-router-dom'
import CustomLoader from '../../common/CustomLoader'
import {
  getUserId,
  getAlertToast,
  authHeader,
  getDomain,
} from '../../common/CustomFunction'
import { API } from '../../../helpers/API'
import {
  ADD_TEAM_MEMBERS_USER_CHANCHES,
  POST_TEAM_RESPOTANT_USER_EMAIL,
} from '../../../helpers/constants/ApiRoutes'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
}))

export default function AddTeamMember(props) {
  console.log(props, '')
  const { tsprojectid } = useParams()
  const classes = useStyles()
  // const { submit } = props
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  console.log(tsprojectid, 'tsprojectid')

  const {
    open,
    handleReset,
    raterList,
    LoginUserID,
    projectID,
    addMembers,
    teamsurveyLink,
  } = props

  const validationSchema = Yup.object({
    email: Yup.string()
      .trim()
      .required('Email is required'),
  })

  const handleAddParticipantUser = async (formData) => {
    setLoading(true)

    const { email } = formData

    const { projectID, LoginUserID, OrganizationID } = props

    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_TEAM_MEMBERS_USER_CHANCHES,
      params: {
        EmailID: email,
        IsSent: true,
        ProjectID: tsprojectid,
        UserID: getUserId(),
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        // handleAddRaterNew(response.data)
        addParticipantUserNew(email)
        // await handleUpdatePartner()
      }
    } catch (error) {
      handleReset()
      // setError('This email already in participant, Try with other email')
      setError('Participant is already on the roster, Try with other email')
    } finally {
      // setLoading(false)
    }
  }
  const addParticipantUserNew = async (emailData) => {
    // setLoading(true)
    console.log('logging', emailData)
    // const { UserID, zUserID } = data.data[0]

    // const { projectID, LoginUserID, OrganizationID } = props

    const project_id = parseInt(tsprojectid)
    const postConfig = {
      method: 'POST',
      headers: authHeader(),

      url: POST_TEAM_RESPOTANT_USER_EMAIL,
      data: {
        emailID: emailData,
        projectID: project_id,
        surveyLink: teamsurveyLink,
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        handleReset()
        // await handleUpdatePartner()
      }
    } catch (error) {
      //handleReset();
      // setError('This email already in participant, Try with other email')
      setError('Team Member is already on the roster, Try with other email')
    } finally {
      //   setLoading(false)
    }
  }

  //   const handleSaveRoster = async () => {
  //     setLoading(true)
  //     const {
  //       projectID,
  //       LoginUserID,
  //       ParticipantID,
  //       handleReset,
  //       getParticipantList,
  //     } = props
  //     const postConfig = {
  //       method: 'POST',
  //       headers: authHeader(),

  //     //   url: SAVE_MANAGE_ROSTER,
  //       data: {
  //         userID: '',
  //         projectID: projectID,
  //         dueDate: props.projectDetails.zDueDateFormat,
  //         chkSendEmail: true,
  //         baseURL: window.location.origin,
  //       },
  //     }
  //     try {
  //       const response = await API(postConfig)
  //       if (response.status === 200) {
  //         // setLoading(false)
  //         // Swal.fire({
  //         //   title:
  //         //     "<h5 style='color:#fff'>Participant was added successfully.</h5>",
  //         //   background: '#00cc00',
  //         // }).then((value) => {})
  //         //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
  //         //getParticipantList();
  //         handleReset()
  //       }
  //     } catch (error) {
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

  //   const handleAddParticipant = async (userId) => {
  //     // console.log("useIdwhile adding", userId)
  //     const postConfig = {
  //       method: 'POST',
  //       headers: authHeader(),

  //       url: ADD_PARTICIPANT,
  //       data: {
  //         respondentORParticipant: 2,
  //         projectID: projectID,
  //         userID: userId,
  //       },
  //     }
  //     const response = await API(postConfig)
  //     if (response.status === 200) {
  //       Swal.fire(getAlertToast('success', 'New user Added Successfully'))
  //       handleReset()
  //     }
  //   }

  //state
  const initialValues = {
    email: '',
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleAddParticipantUser(values),
  })

  const { email = '' } = values

  return (
    <div>
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth="500">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Add Team Member
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              {/* <Grid item xs={6} className={classes.grid}>
                  <TextField
                    id="firstName"
                    label="First Name:"
                    value={firstName}
                    onChange={handleChange}
                    fullWidth
                    required
                    name="firstName"
                    error={errors.firstName && touched.firstName}
                    helperText={
                      errors.firstName && touched.firstName
                        ? errors.firstName
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={6} className={classes.grid}>
                  <TextField
                    id="lastName"
                    label="Last Name:"
                    value={lastName}
                    onChange={handleChange}
                    fullWidth
                    required
                    name="lastName"
                    error={errors.lastName && touched.lastName}
                    helperText={
                      errors.lastName && touched.lastName ? errors.lastName : ''
                    }
                  />
                </Grid> */}
              <Grid item xs={6} className={classes.grid}>
                <TextField
                  id="email"
                  label="Email:"
                  value={email}
                  onChange={handleChange}
                  fullWidth
                  required
                  name="email"
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email ? errors.email : ''}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography style={{ color: 'red' }}>{error}</Typography>
              </Grid>

              <Grid item xs={4}>
                {/* {loading && <CustomPageLoading />}
                 */}
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  style={{ float: 'right' }}>
                  Send Invite
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}
