import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Card,
  CardContent,
  Box,
  Container,
  AppBar,
  Button,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import defaultAvatar from '../../../../../../assets/images/defaultAvatar.png'
import logo from '../../../../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../../../../common/CustomBreadcrumbs'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import CustomButton from '../../../../../common/CustomButton'
import { TASK_VIEW_PATH } from '../../../../../../helpers/constants/RoutePaths'
import Avatar from 'react-avatar'
import Fab from '@material-ui/core/Fab'
import ProfileEdit from './ProfileEdit'

const useStyles = makeStyles((theme) => ({
  skillBtn: {
    width: '60px',
    height: '20px',
    marginLeft: '1rem',
    marginTop: '1rem',
  },
  cardStyle: {
    display: 'block',
    width: '19vw',
    transitionDuration: '0.3s',
    height: '23vw',
  },
  demo: {
    backgroundColor: theme.btnPrimary,
  },
  linkStyle: {
    color: theme.btnPrimary,
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '14px',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  paper: {
    // width: "100%",
    // marginBottom: theme.spacing(2),
    height: 340,
    width: 300,
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
  },
}))

export default function ProfileIndex(props) {
  let flag = 'false'
  const [editFlag, setEditFlag] = useState(false)

  // if (props.location.state) {
  //   let { editFlag } = props.location.state;
  //   flag = editFlag;
  // }
  const classes = useStyles()
  const handleReset = () => {
    setEditFlag(!editFlag)
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography variant="h6" gutterBottom>
            Dashboard
          </Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="textPrimary">Home</Typography>
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
        <Grid item xs={3}>
          <Card>
            <CardContent>
              <div>
                {' '}
                <Typography>
                  <b>Profile Details</b>
                </Typography>
                <center>
                  <img src={defaultAvatar} alt="logo" width="60" height="60" />
                </center>
              </div>
              <center>
                <p className={classes.title}>Lucy Lavender</p>
              </center>
              <center>
                <p className={classes.title}>Lead Developer</p>
              </center>
              <center>
                <CustomButton
                  colour="primaryButton"
                  type="submit"
                  style={{ marginTop: '1.5rem' }}
                  onClick={handleReset}>
                  Edit
                </CustomButton>
              </center>
            </CardContent>
          </Card>
          <br />
          <Card>
            <CardContent>
              <div>
                <Typography className={classes.title}>Skills</Typography>
                <Fab variant="extended" className={classes.skillBtn}>
                  HTML
                </Fab>

                {/* <Fab variant="extended" className={classes.skillBtn}>
                  Java Script
                </Fab> */}
                <Fab variant="extended" className={classes.skillBtn}>
                  Sass
                </Fab>
                <Fab variant="extended" className={classes.skillBtn}>
                  React
                </Fab>
                <Fab variant="extended" className={classes.skillBtn}>
                  Redux
                </Fab>
                <Fab variant="extended" className={classes.skillBtn}>
                  Next
                </Fab>
                <Fab variant="extended" className={classes.skillBtn}>
                  Ui
                </Fab>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {/* <Toolbar className={classes.root}> */}
        <Grid item xs={3}>
          <Card className={classes.cardStyle}>
            <CardContent>
              <div>
                {' '}
                <Typography>
                  <b>Career Path</b>
                  <br />
                  Click to view details and to edit
                </Typography>
                <center></center>
              </div>
              <br />

              <div
              // className={classes.demo}
              // style={{ backgroundColor: "#F6F6F6" }}
              >
                <List>
                  <ListItem style={{ backgroundColor: '#F6F6F6' }}>
                    <ListItemText
                      primary="CURRENT POSITION"
                      secondary={'Lead Developer'}
                    />
                    <ListItemSecondaryAction>
                      <CustomButton colour="primaryButton" type="submit">
                        Edit
                      </CustomButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <br />
                  <ListItem style={{ backgroundColor: '#F6F6F6' }}>
                    <ListItemText
                      primary="FUTURE POSITION"
                      secondary={'Chief Technology officer'}
                    />
                    <ListItemSecondaryAction>
                      <CustomButton colour="primaryButton" type="submit">
                        Edit
                      </CustomButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.cardStyle}>
            <CardContent>
              <div>
                {' '}
                <Typography>
                  <b>Personality Profile</b>
                  <br />
                  Click to view details
                </Typography>
                <center></center>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card className={classes.cardStyle}>
            <CardContent>
              <div>
                {' '}
                <Typography>
                  <b>Interests</b>
                  <br />
                  Click to view details
                </Typography>
                <center></center>
              </div>
            </CardContent>
          </Card>
        </Grid>
        {/* </Toolbar> */}
      </Grid>
      {editFlag == true && (
        <ProfileEdit open={editFlag} handleReset={handleReset} />
      )}
    </div>
  )
}
