import React, { useEffect, useState, useCallback } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import {
  GET_CAPABILITIES_BY_COMPETENCY_MODAL,
  GET_CATEGORY_LIST_URL,
  GET_ITEMS_BY_SUBCATEGORY_URL,
  GET_PROJECT_ITEM,
  GET_SUB_CATEGORY_LIST_URL,
} from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import { useSelector, useDispatch } from 'react-redux'
import {
  SELECT_THINKWISE_ITEM,
  DELETE_THINKWISE_ITEM,
  ADD_ALL_THINKWISE_ITEM,
  UNCHECK_ALL_THINKWISE_ITEM,
  SAVE_CATEGORY,
  SAVE_PROJECTVALUES,
  CHECKED_PROJECT_LIST,
  CHECKED_ITEM_LIST,
} from '../../../../../redux/action/ActionType'

const CATEGORY_LIST = 'CATEGORY_LIST_'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    fontFamily: 'Nunito-Regular !important',

    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  accHeadSelected: {
    fontFamily: 'Nunito-Regular !important',

    backgroundColor: '#64B5F6',
    color: '#000',
    height: '64px',
    '& .MuiSvgIcon-root': {
      color: '#000',
      // backgroundColor:"#000"
    },
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
    height: '64px',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemSelectedStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  listItemStyle: {
    fontFamily: 'Nunito-Regular !important',

    '&.Mui-selected': {
      backgroundColor: '#1565C0',
      color: '#ffffff',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1565C0',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#ffff',
      },
      '& $iconStyle': {
        color: '#ffffff',
      },
    },
  },
}))

const InnerAccordianContainer = ({
  competencyId,
  subCategory,
  classes,
  // value,
  // items,
  // handleItem,
}) => {
  const ThinkwiseProject = useSelector((state) => state.ThinkwiseProjectId)
  const CheckedItemList = useSelector((state) => state.CheckedItemList)
  const dispatch = useDispatch()
  const { zCapabilityID, zName, zDescription } = subCategory
  let newProject = ThinkwiseProject['PROJECT_LIST_' + zCapabilityID] || []
  const ThinkwiseSelectitem = useSelector(
    (state) => state.ThinkwiseSelectcategory
  )
  // console.log(newProject)

  const handleItem = (e, item) => {
    //console.log(e, item, 'hi')

    if (e.target.checked) {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        const temp2 = newProject
        temp2.forEach((item) => {
          if (!temp.find((val) => val.itemId === item.zItemID)) {
            temp.push({
              itemId: item.zItemID,
              categoryId: competencyId,
              subCategoryId: zCapabilityID,
            })
          }
        })
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        console.log('ThinkwiseSelectitem', ThinkwiseSelectitem)
        console.log('CheckedItemList', CheckedItemList)
        //return null;
        //ThinkwisecategoryList
        let temp = [...CheckedItemList]

        const arr = {
          itemId: item.zItemID,
          subCategoryId: item.zCapabilityID,
          categoryId: competencyId,
        }
        temp.push(arr)

        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
        // dispatch({ type: ADD_ALL_THINKWISE_ITEM, payload: ThinkwiseSelectitem })
      }
    } else {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        temp = temp.filter(
          (checkedItem) => checkedItem.subCategoryId !== zCapabilityID
        )
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: item.zCapabilityID,
          categoryId: competencyId,
        }
        temp = temp.filter((val) => val.itemId != arr.itemId)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    }
  }

  return (
    <Accordion
      key={`acc_inside_${zCapabilityID}`}
      style={{ margin: '0 -14px 0.5rem -15px' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`item_inside_${'value'}-content`}
        id={`item_inside_${'value'}-header`}
        className={classes.accHeadInside}>
        <Typography className={classes.heading}> {zName}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accDetail}>
        <Typography className={classes.accDesc}>{zDescription}</Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    CheckedItemList
                      ? CheckedItemList.filter(
                          (checkedItem) =>
                            checkedItem.subCategoryId === zCapabilityID
                        ).length === newProject.length
                        ? true
                        : false
                      : false
                  }
                  onClick={(e) => handleItem(e)}
                  name="all"
                />
              }
              label="All"
            />
            {newProject.map((item) => {
              return (
                <FormControlLabel
                  key={item.zItemID}
                  control={
                    <Checkbox
                      color="primary"
                      name="jason"
                      key={item.zItemID}
                      checked={
                        CheckedItemList
                          ? CheckedItemList.find(
                              (checkedItem) =>
                                checkedItem.itemId === item.zItemID
                            )
                            ? true
                            : false
                          : false
                      }
                      id={item.zItemID}
                      onClick={(e) => handleItem(e, item)}
                    />
                  }
                  label={item.zCaption}
                />
              )
            })}
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  )
}

const AccordianContainer = ({
  category,
  subcategory,

  // items,
  classes,
}) => {
  let selectedCategoryList =
    subcategory[CATEGORY_LIST + category.zCompetencyID] || []
  const CheckedItemList = useSelector((state) => state.AllcheckedItems)
  //  console.log(CheckedItemList)
  return (
    <Accordion className={classes.acc}>
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: CheckedItemList.map(
                (checkedItem) => checkedItem.categoryId
              ).includes(category.zCompetencyID)
                ? '#000'
                : '#fff',
            }}
          />
        }
        aria-controls={`item_${'value'}-content`}
        id={`item_${'value'}-header`}
        className={`${
          CheckedItemList.find(
            (checkedItem) => checkedItem.categoryId == category.zCompetencyID
          )
            ? classes.accHeadSelected
            : classes.accHead
        }`}>
        <Typography className={classes.heading}>{category?.zName}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accDetail}>
        <Typography className={classes.accDesc}>
          {category.zDescription}
        </Typography>

        {selectedCategoryList.map((subCategory, index) => {
          return (
            <InnerAccordianContainer
              key={`item-${index}`}
              competencyId={category.zCompetencyID}
              // items={items.filter(
              //   (item) => item.zCapabilityID === subCategory.zCapabilityID
              // )}
              subCategory={subCategory}
              classes={classes}
              // handleItem={handleItem}
            />
          )
        })}
      </AccordionDetails>
    </Accordion>
  )
}

export default function ThinkWiseItems({ competencyModalValue }) {
  const classes = useStyles()
  const ThinkwiseSelectitem = useSelector(
    (state) => state.ThinkwiseSelectcategory
  )
  const ThinkwiseGroupcategoryReducer = useSelector(
    (state) => state.ThinkwiseGroupcategoryReducer
  )
  const ThinkwisecategoryList = useSelector(
    (state) => state.ThinkwisecategoryList
  )

  const ThinkwisesubcategoryReducer = useSelector(
    (state) => state.ThinkwisesubcategoryReducer
  )
  let ThinkwiseProjectList = useSelector((state) => state.ThinkwiseProjectId)
  const Thinkwisesubcategory = useSelector(
    (state) => state.ThinkwisesubcategoryReducer
  )
  const CustomSubCategory = useSelector((state) => state.CustomSubCategoryList)
  let allSubCategory = [...Thinkwisesubcategory, ...CustomSubCategory]
  const CustomCategoryList = useSelector((state) => state.CustomCategoryList)
  const CheckedItemList = useSelector((state) => state.AllcheckedItems)
  // const CheckedItemList = useSelector((state) => state.CheckedItemList)
  const [selectAll, setselectAll] = useState(false)
  let selectItemfield = ThinkwiseSelectitem.map((val) => val.zCompetencyID)
  const CustomSubCategoryItems = useSelector(
    (state) => state.CustomSubCategoryItems
  )
  const CustomCategoryItems = useSelector((state) => state.CustomCategoryItems)

  const dispatch = useDispatch()
  const [selectedCategory, setCategory] = useState([])
  const [items, setItems] = useState([])
  let ThinkwiseCategory = useSelector((state) => state.ThinkwisecategoryList)
  let customCategory = useSelector((state) => state.CustomCategoryList)
  let allCategory = [...ThinkwiseCategory, ...customCategory]

  //Get Project Items

  const handleListItemClick = (event, type) => {
    const { id } = event.currentTarget.dataset

    let newcategoryList = [...ThinkwisecategoryList]

    let selectedCategory = newcategoryList.find(
      (val) => val.zCompetencyID == id
    )

    if (selectItemfield.includes(parseInt(id))) {
      dispatch({ type: DELETE_THINKWISE_ITEM, payload: id })
    } else {
      dispatch({ type: SELECT_THINKWISE_ITEM, payload: selectedCategory })
    }
  }

  const selectAllItems = (e) => {
    const value = e.target.value

    setselectAll(value == 'false' ? true : false)
    if (value == 'false') {
      dispatch({ type: ADD_ALL_THINKWISE_ITEM, payload: ThinkwisecategoryList })
    } else {
      dispatch({ type: UNCHECK_ALL_THINKWISE_ITEM })
    }
  }

  // Get Capability List based on Competency Modal
  // const getCapabilityList = async (value) => {
  //   const config = {
  //     method: 'GET',
  //     url: GET_CAPABILITIES_BY_COMPETENCY_MODAL,
  //     params: {
  //       CompetencyModelID: value,
  //     },
  //   }
  //   const response = await API(config)
  //   if (response.status === 200) {
  //     const { data } = response.data
  //     const capabilities = ThinkwisesubcategoryReducer.filter((rec) =>
  //       data.find((val) => val.zCapabilityID === rec.zCapabilityID)
  //     )
  //     let compCheckedItems = []
  //     capabilities.map((data) => {
  //       const items = ThinkwiseProjectList[`PROJECT_LIST_${data.zCapabilityID}`]
  //       if (items.length) {
  //         items.map((item) => {
  //           compCheckedItems.push({
  //             itemId: item.zItemID,
  //             categoryId: data.zCompetencyID,
  //             subCategoryId: data.zCapabilityID,
  //           })
  //         })
  //       }
  //     })
  //     compCheckedItems.length &&
  //       dispatch({ type: CHECKED_ITEM_LIST, payload: compCheckedItems })
  //   }
  // }

  // useEffect(() => {
  //   if (competencyModalValue) {
  //     getCapabilityList(competencyModalValue)
  //   }
  // }, [competencyModalValue])

  const thinkwiseCheckedItems =
    CheckedItemList &&
    CheckedItemList.filter((checkedItem) =>
      ThinkwisesubcategoryReducer.find(
        (sub) => sub.zCapabilityID === checkedItem.subCategoryId
      )
    )
  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      style={{ marginTop: '2px' }}>
      <Grid item xs={3}>
        <List component="nav" aria-label="secondary mailbox folder">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                value={selectAll}
                name="all"
                checked={selectAll}
                onClick={selectAllItems}
              />
            }
            label="Select All"
          />

          {ThinkwisecategoryList.map((category, index) => (
            <ListItem
              key={`list_item_${index}`}
              button
              data-id={category.zCompetencyID}
              selected={selectItemfield.includes(category.zCompetencyID)}
              onClick={handleListItemClick}
              className={`${
                selectItemfield.includes(category.zCompetencyID)
                  ? CheckedItemList.find(
                      (checkedItem) =>
                        checkedItem.categoryId == category.zCompetencyID
                    )
                    ? classes.listItemSelectedStyle
                    : classes.listItemStyle
                  : ''
              }`}>
              <ListItemText primary={category.zName} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={9}>
        <p>
          ThinkWise Items Selected : <b>{thinkwiseCheckedItems.length}</b>
        </p>

        {ThinkwiseSelectitem.map((category, index) => (
          <AccordianContainer
            key={`item-${index}`}
            category={category}
            subcategory={ThinkwiseGroupcategoryReducer}
            classes={classes}
            // selectedCategory={selectedCategory}
            // handleItem={(e, capabilityId) => handleItem(e, capabilityId, index)}
          />
        ))}
      </Grid>
    </Grid>
  )
}
