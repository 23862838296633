import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import { Editor } from 'react-draft-wysiwyg'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import AddIcon from '@material-ui/icons/Add'
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import { useSnackbar } from 'notistack'
import { stateToHTML } from 'draft-js-export-html'
import {
  DElETE_RATER,
  RATER_EMAIL,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { CustomMessage } from '../../../../../../function/HelperFunction'

import { useState } from 'react'
import { authHeader } from '../../../../../common/CustomFunction'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  dateBtn: {
    backgroundColor: theme.successColor,
    marginLeft: 20,
  },
  deleteBtn: {
    backgroundColor: theme.primaryColor,
    marginLeft: 20,
  },
  cancelBtn: {
    backgroundColor: theme.btnPrimary,
    marginLeft: 20,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',

    // or borderTop: '1px solid red'
  },
  tableRow: {
    height: 50,
  },
}))

function Deleterater(props) {
  console.log(props)
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedDate, handleDateChange] = useState(
    new Date('2019-01-01T18:54')
  )
  const {
    open = '',
    handleReset,
    projectDetails,
    firstName,
    lastName,
    email,
    relationshipName,
    projectName,
    userID,
    UserRelationshipID,
  } = props
  const [addreminDate, setAdd] = useState([{ dateval: '' }])
  const [keyword, setKeyWord] = useState('')
  const blocksFromHTML = convertFromHTML(
    'You have been removed as a respondent from the ThinkWise 360 Project named ' +
      projectName +
      ' and are not required to respond.'
  )
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(state)
  )

  // const {id = ""} = useParams();

  const addExtraReminder = () => {
    setAdd((prevItems) => [
      ...prevItems,
      {
        dateval: '',
      },
    ])
  }

  const onEditorStateChange = (e) => {
    setEditorState(e)
  }

  const insertCharacter = (characterToInsert, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection()

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert
    )

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )

    setEditorState(newEditorState)
  }

  const handleKeyWord = (e) => {
    insertCharacter(e.target.value, editorState)
    setKeyWord(e.target.value)
  }

  const DeleteRator = async () => {
    const text = editorState.getCurrentContent().getBlocksAsArray()
    let finalText = ''
    text.map((item) => {
      finalText = item.getText() + finalText
    })
    console.log('relation', UserRelationshipID)
    const config = {
      method: 'GET',
      url: DElETE_RATER,
      headers: authHeader(),

      params: {
        ProjectID: projectDetails.zProjectID,
        UserID: userID,
        ProjectUserRelationshipID: UserRelationshipID,
        // EmailText: finalText,
        // UserID: "",
        // UserIdentityName: "",
        // ProjectName: projectDetails.zName,
        // DueDate: projectDetails.zDueDateFormat,
        // BaseUrl: window.location.origin,
        // RemindAllUser: true,
        // sendTest: false,
      },
    }

    const response = await API(config)

    if (response.status === 200) {
      const { message } = response.data
      CustomMessage('Deleted user sucessfully', 'success', enqueueSnackbar)
      handleReset()
    }
  }

  const sendEmail = async () => {
    const text = editorState.getCurrentContent().getBlocksAsArray()
    let finalText = ''
    text.map((item) => {
      finalText = item.getText() + finalText
    })
    const config = {
      method: 'GET',
      url: RATER_EMAIL,
      headers: authHeader(),

      params: {
        ProjectID: projectDetails.zProjectID,
        UserID: userID,
        ProjectUserRelationshipID: UserRelationshipID,
        EmailText: finalText,
        // UserID: "",
        // UserIdentityName: "",
        ProjectName: projectDetails.zName,
        DueDate: projectDetails.zDueDateFormat,
        BaseUrl: window.location.origin,
        RemindAllUser: true,
        sendTest: false,
      },
    }

    const response = await API(config)

    DeleteRator()

    /* if (response.status === 200) {
      const { message } = response.data
      CustomMessage('Mail sent sucessfully', 'success', enqueueSnackbar)
      handleReset();
    } */
  }

  //Schema
  const validationSchema = Yup.object({
    template: Yup.string()
      .trim()
      .required('Template is required'),
    templateName: Yup.string().required('Template name is required'),
    fromname: Yup.string()
      .trim()
      .required('Name is required'),
    fromEmail: Yup.string()
      .trim()
      .required('Email is required'),
    subject: Yup.string()
      .trim()
      .required('Subject is required'),
    testMail: Yup.string()
      .trim()
      .required('Test email is required'),
  })

  //state
  const initialValues = {
    template: '',
    templateName: '',
    fromname: '',
    fromEmail: '',
    subject: '',
    testMail: '',
  }

  const handleLogin = (formData) => {}

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const {
    template = '',
    templateName = '',
    fromname = '',
    fromEmail = '',
    subject = '',
    testMail = '',
  } = values

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Delete Rater and Send Notification Email
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              <Grid xs={12}>
                <Typography>
                  You are about to delete the rater for {firstName} {lastName}{' '}
                  If you click "Delete Only", no notification email will be
                  sent. Clicking "Cancel" will close this window without
                  removing the rater or sending an email.
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography style={{ lineHeight: '70px' }}>
                  Email Subject
                </Typography>
              </Grid>
              <Grid xs={8}>
                <TextField
                  id="testMail"
                  label="Enter email subject"
                  value={testMail}
                  onChange={handleChange}
                  fullWidth
                  name="testMail"
                  error={errors.testMail && touched.testMail}
                  helperText={
                    errors.testMail && touched.testMail ? errors.testMail : ''
                  }
                />
              </Grid>
              <Grid xs={2}></Grid>
              <Grid xs={12}>
                <Typography>Email Body</Typography>
              </Grid>
              <Grid xs={12} style={{ paddingTop: '30px' }}>
                <Editor
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="rdw-editor-toolbar"
                  editorClassName="editorClassName"
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid
                item
                xs={6}
                style={{ textAlign: 'right', paddingRight: '0px' }}>
                <CustomButton
                  className={classes.cancelBtn}
                  onClick={() => {
                    handleReset()
                  }}>
                  Cancel
                </CustomButton>
                <CustomButton
                  className={classes.deleteBtn}
                  onClick={() => {
                    DeleteRator()
                  }}>
                  Delete Only
                </CustomButton>
                <CustomButton
                  className={classes.dateBtn}
                  onClick={() => {
                    sendEmail('sent')
                  }}>
                  Send Notification
                </CustomButton>
              </Grid>
              <Grid item xs={6}>
                {/* <h2>Schedule Reminders</h2>
                <CustomButton
                  type="submit"
                  colour="primaryButton"
                  onClick={handleReset}
                >
                  Set Reminder Schedule
                </CustomButton>
                {addreminDate.map((record, index) => {
                  return (
                    <div style={{ marginTop: "2rem" }} key={index}>
                      <KeyboardDateTimePicker
                        value={selectedDate}
                        variant="inline"
                        onChange={handleDateChange}
                        label="Reminder Send Date:"
                        onError={console.log}
                        minDate={new Date("2018-01-01T00:00")}
                        format="MM/DD/yyyy hh:mm a"
                      />
                    </div>
                  );
                })}
                <Button
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none" }}
                  onClick={addExtraReminder}
                >
                  Add Reminder
                </Button> */}
              </Grid>
            </Grid>
            {/* </Typography> */}
          </form>
        </DialogContent>

        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}

export default Deleterater
