import * as types from '../action/ActionType'
export default function CompentencyDragReducer(state = { selectComponent : false }, action) {
  const { type, payload } = action
  switch (type) {
    case types.COMPETENCY_DRAG_DROP:
      return (state = payload)
    default:
      return state
  }
}
