// export const BASE_api_URL = ' https://backend.cantoo.us/'
// dev urll
// export const BASE_api_URL = 'https://api-dev-cantoo.azurewebsites.net/'
//live urllll
export const BASE_api_URL = ' https://api-cantoo.azurewebsites.net/'
//'http://104.239.144.7:8080/'--old
// export const BASE_api_URL = 'https://cors-anywhere.herokuapp.com/http://104.239.144.7:8080/'
const BaseUrl = (url) => {
  return `${BASE_api_URL}${url}`
}
export const LOGIN_URL = BaseUrl('api/Login/login')
export const USER_VERIFY_BY_LOGIN_KEY = BaseUrl('api/Login/user_VerifyLoginkey')
export const ADMIN_ORG_USER_SIGNUP = BaseUrl('api/User/userSignUpByOrgID')
// Partner api Url
export const GET_PARTNER_LIST_URL = BaseUrl('api/Partner/getPartnerList')
export const GET_PARTNER_INFO_URL = BaseUrl('api/Partner/getPartnerInfo')
export const ADD_PARTNER_URL = BaseUrl('api/Partner/addPartner')
export const EDIT_PARTNER_URL = BaseUrl('api/Partner/UpdatePartner')
export const UPLOAD_LOGO_URL = BaseUrl('api/Upload/uploadLogo')
export const GET_LOGO_URL = BaseUrl('api/Upload/getLogo')
export const LOGO_UPLOAD_PARTNER_ORG_URL = BaseUrl('api/Upload/uploadFile')
export const GET_PARNTER_LOGO_URL = BaseUrl('api/Upload/getPartnerLogo')
export const GET_ORGANIZATION_LOGO_URL = BaseUrl(
  'api/Upload/getOrganizationLogo'
)

// State api Url
export const GET_STATE_LIST_URL = BaseUrl('api/Partner/getStateList')
export const GET_ORG_PARTNER_ID = BaseUrl('api/Organization/orgListByPartnerId')
export const CREATE_ORG_URL = BaseUrl('api/Organization/upsertOrganization')
export const GET_ORG_INFO_URL = BaseUrl('api/Organization/orgGetByOrgId')
export const GET_USER_LIST_BY_ORG_URL = BaseUrl('api/Organization/userLstByOrg')
// export const GET_STATE_LIST_URL = BaseUrl('api/Partner/GetStateList')

// Survey 360 api Url
export const GET_PARTICIPANT_LEVEL_URL = BaseUrl(
  'api/Survey360/getParticipantLevel'
)
export const GET_SURVEY_ADMINSTRATOR_URL = BaseUrl('api/Survey360/surveyAdmin')
export const GET_SCALE_TYPES_URL = BaseUrl('api/Survey360/scaleTypeList')
export const GET_SCALE_LIST_URL = BaseUrl('api/Survey360/scaleListByScaleID')
export const GET_SCALE_RATING_BY_SCALE_ID = BaseUrl(
  'api/Survey360/scaleRatingByScaleID'
)
export const CREATE_PROJECT_FROM_SCRATCH_URL = BaseUrl(
  'api/Survey360/startFromScratch'
)
export const CREATE_FROM_PREVIOUS_PROJECT_URL = BaseUrl(
  'api/Survey360/startFromPreviousProject'
)
export const CREATE_FROM_PREVIOUS_TEMPLATE_URL = BaseUrl(
  'api/Survey360/startFromTemplate'
)
export const UPDATE_PROJECT_URL = BaseUrl('api/Survey360/updateProject')
export const ADD_CATEGORY_URL = BaseUrl('api/Survey360/addCategory')
export const ADD_SUB_CATEGORY_URL = BaseUrl('api/Survey360/addSubCategory')
export const ADD_ITEM_URL = BaseUrl('api/Survey360/addItem')
export const UPDATE_ITEM_URL = BaseUrl('api/Survey360/updateItem')
export const GET_COMPETENCY_MODAL = BaseUrl('api/Survey360/competencyModel')
export const GET_CAPABILITIES_BY_COMPETENCY_MODAL = BaseUrl(
  'api/Survey360/competencyModelCapability_Lst'
)
export const GET_CATEGORY_LIST_URL = BaseUrl('api/Survey360/listCategory')
export const GET_SUB_CATEGORY_LIST_URL = BaseUrl(
  'api/Survey360/listSubCategory'
)
export const GET_ITEMS_BY_SUBCATEGORY_URL = BaseUrl(
  'api/Survey360/listItemBySubCategory'
)
export const GET_CUSTOM_CATEGORY_URL = BaseUrl(
  'api/Survey360/listCategoryByUser'
)
export const GET_CUSTOM_SUB_CATEGORY_URL = BaseUrl(
  'api/Survey360/listSubCategoryByUser'
)
export const GET_CUSTOM_ITEMS_BY_CATEGORY = BaseUrl(
  'api/Survey360/projectItemByCategory'
)
export const GET_CUSTOM_ITEMS_BY_SUB_CATEGORY = BaseUrl(
  'api/Survey360/projectItemBySubCategory'
)
export const GET_CUSTOM_ITEMS_URL = BaseUrl('api/Survey360/projectItemByUser')
export const DELETE_CUSTOM_CATEGORY_URL = BaseUrl(
  'api/Survey360/deleteCategory'
)
export const DELETE_CUSTOM_SUB_CATEGORY = BaseUrl(
  'api/Survey360/deleteSubCategory'
)
export const DELETE_CUSTOME_ITEM = BaseUrl('api/Survey360/deleteItem')
export const UPDATE_CATEGORY = BaseUrl('api/Survey360/updateCategory')
export const UPDATE_SUB_CATEGORY = BaseUrl('api/Survey360/updateSubCategory')
export const GET_PROJECT_URL = BaseUrl('api/survey360/getProject')
export const GET_PROJECT_LIST_URL = BaseUrl(
  'api/survey360/survey360ProjectList'
)

export const GET_PROJECT_LIST_ALL_URL = BaseUrl('api/Survey360/allProjects')

export const GET_INVENTORY_COUNT = BaseUrl('api/survey360/setInventoryCounts')
export const DELETE_PROJECT = BaseUrl('api/survey360/deleteProject')
export const GET_PREVIOUS_PROJECT_LIST_URL = BaseUrl(
  'api/Survey360/projectList'
)
export const OPEN_ENDED_QUESTION_LISTUSER_URL = BaseUrl(
  'api/Survey360/openEndedQuestionLstByUser'
)
export const SAVE_OPEN_ENDED_QUESTION_URL = BaseUrl(
  'api/Survey360/saveOpenEndedQuestions'
)
export const DELETE_OPEN_ENDED_QUESTION_URL = BaseUrl(
  'api/Survey360/openEndedQuestionDelete'
)

export const SAVE_OPEN_ENDED_QUESTION_PROJECT_URL = BaseUrl(
  'api/Survey360/saveOpenEndedQuestionProject'
)
export const SAVED_OPEN_QUESTION_URL = BaseUrl(
  '/api/Survey360/openEndedQuestionLstByProject'
)
export const GET_PROJECT_ITEM = BaseUrl('api/Survey360/projectItem')
export const ADD_PROJECT_ITEMS = BaseUrl('api/Survey360/addProjectItems')
export const SAVE_PARTICIPANT_URL = BaseUrl('api/Survey360/saveParticipant')
export const GET_USER_RELATIONSHIP_LIST = BaseUrl(
  'api/Survey360/userRelationshipList'
)
export const GET_RELATIONSHIP_LIST_NEW = BaseUrl(
  'api/Survey360/relationshipList'
)
export const SAVE_ORGANIZATION_TEMPLATE_URL = BaseUrl(
  'api/Survey360/saveOrganizationTemplate'
)
// 360 EMAIL TEMPLATE
export const GET_EMAIL_TEMPLATE_URL = BaseUrl(
  'api/Survey360/emailTemplateLstByUserProjectType'
)
export const GET_EMAIL_PROJECT_URL = BaseUrl('api/Survey360/emailProjectGet')
export const SAVE_EMAIL_PROJECT_URL = BaseUrl('api/Survey360/saveEmailProject')
export const SAVE_EMAIL_TEMPLATE_URL = BaseUrl(
  'api/Survey360/saveEmailTemplateInsByUser'
)
export const UPDATE_EMAIL_TEMPLATE_URL = BaseUrl(
  'api/Survey360/SaveEmailTemplate_Upd'
)
export const SENT_INVITATION_TEST = BaseUrl('api/Survey360/sendTestInvitation')

// Launch Project
export const LAUNCH_360_PROJECT_URL = BaseUrl('api/Survey360/launchProject')

//ProjectTasks url
export const GET_TASK_LIST = BaseUrl('api/Task/userTaskList')
export const UPDATE_TASK_STATUS = BaseUrl('api/Task/taskStatusUpdate')
export const GET_TASK_PARTICIPANT_LIST = BaseUrl('api/Task/participantsList')
export const GET_TASK_SCALETYPE_LIST = BaseUrl('api/Task/getScaleTypeList')
export const GET_PROJECT_OE_QUESTION_USER_LIST = BaseUrl(
  'api/Task/projectOpenEndedQuestionUserGet'
)
export const SAVE_TASK_OE_QUESTION = BaseUrl(
  'api/Task/saveOpenendedQuestionText'
)
export const GET_SCALE_RATING_BY_PROJECT = BaseUrl(
  'api/Task/scaleRatingByProject'
)
export const GET_SAVED_PROJECT_USER_ITEM = BaseUrl(
  'api/Task/getCapabilityAndProjectUserItem'
)
export const UPDATE_PROJECT_USER_ITEM = BaseUrl(
  'api/Task/updateProjectUserItem'
)
export const SAVE_TASK_CONFIRMATION = BaseUrl('api/Task/saveTaskConfirmations')
export const TASK_FINALIZE = BaseUrl('api/Task/taskFinalize')
export const TASK_RESET = BaseUrl('api/Task/taskResetOnly')

//Reports
export const PROJECT_STATUS_REPORT = BaseUrl('api/Report/projectStatusReport')
export const PROJECT_SUMMARY_REPORT = BaseUrl('api/Report/projectSummaryReport')
export const SELFMANAGER_RATINGS_REPORT = BaseUrl(
  'api/Report/selfManagerRatings'
)
export const PROJECT_THREE_SIXTY_REPORT_URL = BaseUrl(
  'api/Report/project360ListByOrganization'
)
export const PROJECT_360_ITEM_REPORT = BaseUrl('api/Report/360ItemReport')
export const PROJECT_INDIVIDUAL_REPORT = BaseUrl(
  'api/IndividualReport/generateIindividualReport'
)
export const PROJECT_GROUP_REPORT = BaseUrl(
  'api/Report360Service/generateGroupReport'
)
//Profile
export const UPDATE_USERNAME_PASSWORD_PROFILE = BaseUrl(
  'api/User/updNamePassword'
)
export const PRE_POST_REPORT_USER_SEARCH = BaseUrl(
  'api/Report/prePostReportList'
)

// User
export const ADD_ORGANISATION_USER = BaseUrl('api/User/upsertUser')
export const SEARCH_USER_BY_EMAIL = BaseUrl('api/User/searchUserByEmail')
export const GET_USER_BY_NAME_EMAIL = BaseUrl('api/User/userGetByNameOREmail')
export const FORGOT_PASSWORD = BaseUrl('api/User/forgotPassword')
export const RESET_PASSWORD = BaseUrl('api/User/changePassword')
export const ACCOUNT_USER_SETUP = BaseUrl('api/User/accountUserSetup')
export const INSERT_USER_BY_ORDER_ID = BaseUrl('api/User/insertUserByOrderID')
export const UPDATE_USER_BY_USER_ID = BaseUrl('api/User/updateUser')

// Orders
export const ADD_ORDER_URL = BaseUrl('api/Organization/insertOrder')
export const UPDATE_ORDER_URL = BaseUrl('api/Organization/updateOrder')
export const DELETE_ORDER_URL = BaseUrl('api/Organization/deleteOrder')
export const ORDER_BY_ORG_URL = BaseUrl('api/Organization/orderLstByOrgID')
export const ORDER_TYPES_BY_PARTNER_ID = BaseUrl(
  'api/Organization/orderTypeLstBypartnerID'
)

export const RELATIONSHIP_LIST_URL = BaseUrl('api/Survey360/relationshipList')

// Roster Upload
export const ROSTER_AUDIT = BaseUrl('api/RosterUpload/auditRoster')
export const ROSTER_SUBMIT = BaseUrl('api/Survey360/submitRoster')
export const ROSTER_WARNING = BaseUrl('api/RosterUpload/rosterAuditErrors')

export const LIST_PARTICIPANT = BaseUrl('api/RosterUpload/addParticipant')
export const LIST_RESPONDANT = BaseUrl('api/RosterUpload/addRespondent')
export const COUNT_PARTICIPANT = BaseUrl('api/Survey360/project360Count')
export const ADD_ROSTER = BaseUrl('api/RosterUpload/addToRoster')
export const SAVE_MANAGE_ROSTER = BaseUrl('api/RosterUpload/saveManageRoster')
export const REMOVE_PARTICIPANT = BaseUrl('api/Survey360/participantClear')
export const SAVE_RATER = BaseUrl('api/RosterUpload/saveRater')

export const ADD_PARTICIPANT_USER = BaseUrl(
  'api/RosterUpload/manageRosterNewUser'
)
export const ADD_PARTICIPANT = BaseUrl('api/RosterUpload/addManageRoster')

export const UPDATE_RATOR = BaseUrl('api/Survey360/updateRater')
export const UPDATE_RATOR_RELATIONSHIP = BaseUrl(
  'api/Survey360/updateRaterRelationship'
)
export const ADD_RATOR = BaseUrl('api/Survey360/addRater')
export const DElETE_RATER = BaseUrl('api/Survey360/deleteRater')
export const RATER_EMAIL = BaseUrl('api/Survey360/raterDeleteEmail')

// Reminder Email
export const REMINDER_EMAIL = BaseUrl('api/Survey360/reminderEmail')
//
export const PREPOST_REPORT = BaseUrl('api/Report/ProjectSummaryReportGenerate')
export const GET_ALL_ORGANIZATION = BaseUrl(
  'api/Organization/searchOrganization'
)
export const PROJECT_TASK_LIST = BaseUrl('api/Task/projectTaskList')
export const GET_PROJECT = BaseUrl('api/Survey360/getProject')
export const GET_CUSTOM_CATEGORY_BYPROJECT_URL = BaseUrl(
  'api/Survey360/customItem'
)
export const GET_COMPENTENCY_MODAL_DROPDOWN = BaseUrl(
  'api/Survey360/competencyModelTemp'
)
export const GET_SUB_CATEGORY = BaseUrl('api/Survey360/projectItemByCategory')
export const GET_SUB_CATEGORY_ITEM = BaseUrl(
  'api/Survey360/projectItemBySubCategory'
)
export const GET_SUB_CATEGORY_BYID = BaseUrl(
  'api/Survey360/listSubCategoryByCategoryID'
)
export const GET_PREPOST_REPORTDATA = BaseUrl(
  'api/Report/PrePostReportGenerate'
)
export const ALL_THINKWISE_ITEMS = BaseUrl('api/Survey360/loadThinkWise')
export const ALL_CUSTOMITEM = BaseUrl('api/Survey360/loadMyCustom')
export const ALL_SELECTED_LOAD_ITEMS = BaseUrl('api/Survey360/loadSelectedItem')
export const ALL_ORG_CUSTOM = BaseUrl('api/Survey360/loadOrgCustom')
//teamsurvey

export const SAVE_TEAMSURVEY = BaseUrl('api/TeamSurvey/saveChanges')
export const TEAM_SURVEY_GETPROJECTDETAILS = BaseUrl(
  'api/TeamSurvey/getProjectDetails'
)
export const UPDATE_TEAMSURVEY = BaseUrl('api/TeamSurvey/updateChanges')
export const GET_TEAM_OPENEND = BaseUrl('api/TeamSurvey/openEndedQuestions')
export const ADD_TEAM_OPENEDNQUESTION = BaseUrl(
  'api/TeamSurvey/customQuestionsAdd'
)
export const SAVE_TEAM_OPENED = BaseUrl('api/TeamSurvey/saveOpenTypeQuestions')
export const GET_TEAM_DETAILS = BaseUrl('api/TeamSurvey/getProjectDetails')
export const GET_TEAM_PROGRESS = BaseUrl('api/TeamSurvey/progress')
export const GET_TEAM_SURVEYLINK = BaseUrl('api/TeamSurvey/teamSurveyLink')
export const GET_TEAM_RESPOTANT = BaseUrl('api/TeamSurvey/respondentTabList')
export const GET_TEAM_PROJECT_STATUS_REPORT = BaseUrl(
  'api/TeamSurvey/statusReport'
)
export const DELETE_TEAM_RESPOTANT = BaseUrl(
  'api/TeamSurvey/teamRespondentDelete'
)
export const UPDATE_TEAM_RESPOTANT_USER = BaseUrl(
  'api/TeamSurvey/respondentUserChanges'
)
export const GET_TEAM_SCALE = BaseUrl('api/TeamSurvey/getScale')
export const UPDATE_TEAM_PROJECTINFO = BaseUrl('api/TeamSurvey/saveChanges')
export const GET_TEAM_PROJECT = BaseUrl('api/TeamSurvey/projectTaskList')
export const SEND_TEAM_TESTEMAIL = BaseUrl('api/TeamSurvey/sendTestInvitation')
export const ADD_TEAM_TEAMMEMBERMAIL = BaseUrl(
  'api/TeamSurvey/addTeamMembersEmail'
)

export const GET_TEAM_INVITAIONMAIL = BaseUrl(
  'api/TeamSurvey/getTeamMembersEmail'
)
export const DELETE_TEAM_OPENEND = BaseUrl(
  'api/TeamSurvey/openEndedQuestionsDelete'
)
export const EDIT_TEAM_OPENEDN = BaseUrl(
  'api/TeamSurvey/openEndedQuestionsUpdate'
)
export const TEAM_PROJECT_TASKLIST = BaseUrl('api/TeamSurvey/projectTaskList')
export const TEAM_TASK_FINILIZE = BaseUrl('api/TeamSurvey/finalizeProject')
export const TEAM_GET_QUESTION = BaseUrl('api/TeamSurvey/getPerformanceScale')
export const TEAM_UPDATE_QUESTION = BaseUrl(
  'api/TeamSurvey/updatePerformanceScale'
)
export const TEAM_GET_OPENEND = BaseUrl('api/TeamSurvey/openEndedQuestions')
export const TEAM_GET_ASWERED_OPENEND = BaseUrl(
  'api/TeamSurvey/getPerformOpenEndedQuestions'
)
export const TEAM_UPDATE_OPENENDANSWER = BaseUrl(
  'api/TeamSurvey/updatePerformOpenEndedQuestions'
)
export const TEAM_SUBMIT_SUYVEY = BaseUrl('api/TeamSurvey/performConfirmation')
export const TEAM_REMAINDERMAIL = BaseUrl('api/TeamSurvey/reminderEmail')
export const TEAM_CHANGE_TEAMMEMBER = BaseUrl('api/TeamSurvey/changeTeamLeader')
export const TEAM_SHEDULE = BaseUrl('api/TeamSurvey/scheduleLaunch')
export const TEAM_SURVEYCONFIRM = BaseUrl('api/TeamSurvey/surveyConfirmation')
export const TEAM_GET_SCHUDULEREMAIDNER = BaseUrl(
  'api/TeamSurvey/getScheduleReminders'
)
export const TEAM_ADD_SCHUDULE_REMAINDER = BaseUrl(
  'api/TeamSurvey/addScheduleReminders'
)
export const TEAM_ADD_UPDATESCHUDULE = BaseUrl(
  'api/TeamSurvey/upsertScheduleReminder'
)
export const TEAM_REPORT = BaseUrl('api/TeamSurvey/teamPerformanceSurveyReport')
export const TEAM_GETURL = BaseUrl('api/TeamSurvey/getTeamSurveyLink')
export const TEAM_GET_SCHEDULELAUNCH = BaseUrl(
  'api/TeamSurvey/getScheduleLaunch'
)
export const TEAM_SAVE_SCHEDULELAUNCH = BaseUrl('api/TeamSurvey/scheduleLaunch')
export const TEAM_DELETE_TEAMMAIL = BaseUrl(
  'api/TeamSurvey/teamMembersEmailDelete'
)
export const TEAM_ADD_TAST_USER = BaseUrl(
  'api/TeamSurvey/projectAndTaskInitialize'
)
export const TEAM_GET_ORGANIZATION_ID = BaseUrl(
  'api/Organization/getOrganizationIDByDomain'
)
export const TEAM_ADD_ORGAN_USER = BaseUrl('api/User/userSignUpByOrgID')
export const CHECKTHE_ACCOUNT = BaseUrl('api/Login/checkUserEmailAndPassword')
export const TEAM_SEND_TEAMREPORT = BaseUrl('api/TeamSurvey/sendReport')
export const TEAM_SEND_ALL_REPORT = BaseUrl('api/TeamSurvey/sendReportAll')
export const TEAM_REMAINDER_ALL = BaseUrl('api/TeamSurvey/remindAllIncomplete')
export const TEAM_SAVETIMELINE = BaseUrl('api/TeamSurvey/timeLineTypeCreate')
export const TEAM_GET_TIMELINE = BaseUrl('api/TeamSurvey/timeLineTypeGet')
export const TEAM_UPDATE_TIMELINE = BaseUrl('api/TeamSurvey/timeLineTypeUpd')
export const TEAM_TIMELINE_TYPE_GET = BaseUrl('api/TeamSurvey/timeLineTypeGet')
export const TEAM_CREATE_LAUNCH_TIME_LINE = BaseUrl(
  'api/TeamSurvey/createTimeLine'
)
export const TEAM_GET_PULSE = BaseUrl('api/TeamSurvey/timeLineGet')
export const ADMIN_LIST = BaseUrl('api/TeamSurvey/adminUserList')

export const TEAM_RESPONS_USER_CHANGE = BaseUrl(
  'api/TeamSurvey/respondentUserChanges'
)
export const SAVE_ROSTER_RATER_EXEMAIL = BaseUrl('api/Survey360/saveRater')
export const GET_THREE_SIXTY_PROJECT_LIST = BaseUrl(
  'api/Survey360/myProjectListAll'
)
export const ADD_PARTICIPANT_OR_RATER_URL = BaseUrl(
  'api/Survey360/addParticipantOrRespondent'
)
export const SEND_ORIGINAL_EMAIL_URL = BaseUrl(
  'api/Survey360/send360RespondentSingle'
)

export const SEND_GRID_RESPONDENT_INSERT = BaseUrl(
  'api/Survey360/sendgridRespondentInsert'
)

//Team survey invitation note
export const GET_INVITATION_NOTE = BaseUrl('api/TeamSurvey/notesGet')
export const SAVE_INVITATION_NOTE = BaseUrl('api/TeamSurvey/saveNotes')

//Team survey GET api
export const ADD_TEAM_MEMBERS_USER_CHANCHES = BaseUrl(
  'api/TeamSurvey/addTeamMembersEmail'
)
export const POST_TEAM_RESPOTANT_USER_EMAIL = BaseUrl(
  'api/TeamSurvey/sendTeamRespondentEmail'
)

//Team survey launch date
export const GET_LAUNCH_DATE = BaseUrl('api/TeamSurvey/launchDateGet')
export const UPDATE_LAUNCH_DATE = BaseUrl('api/TeamSurvey/scheduleLaunch')
export const LAUNCH_DATE_URL = BaseUrl('api/TeamSurvey/launchDateAndTimeUpdate')

//360 update user relationship
export const UPDATE_RELATIONSHIP_URL = BaseUrl(
  'api/Survey360/updateUserRelationship'
)
//bypassRoster api
export const UPSERT_BYPASS_ROSTER = BaseUrl('api/Survey360/upsertByPassRoster')
export const ROSTER_VERIFIED_INVITATION = BaseUrl(
  'api/Survey360/sendTestInvitationNew'
)
export const GET_BYPASSROSTER_DETAILS = BaseUrl(
  'api/Survey360/getByPassRosterDetails'
)
//admin approval details
export const GET_ADMIN_APPROVAL_DETAILS = BaseUrl(
  'api/Survey360/getAdminApprovalDetails'
)
export const POST_UPSERT_BYPASS_ROSTERUSER = BaseUrl(
  'api/Survey360/upsertByPassRosterUser'
)
export const GET_PARTICIPANT_APPROVE_DETAILS = BaseUrl(
  'api/Survey360/getParticipantApprovalDetails'
)
export const POST_UPSERT_ROSTER_FORM = BaseUrl(
  'api/Survey360/upsertParticipantApproval'
)
export const UPSERT_ADMIN_APPROVEL = BaseUrl(
  'api/Survey360/upsertAdminApprovalForRoster'
)

// Refresh token api url

export const REFRESH_TOKEN_API = BaseUrl('api/Login/refreshToken')

//Email Status api url

export const GET_RESPONDENT_SEND_GRID_ALL_DETAILS = BaseUrl(
  'api/Survey360/getRespondentSendgridAllDetails'
)
export const GET_RESPONDENT_SEND_GRID_DETAILS = BaseUrl(
  'api/Survey360/getRespondentSendgridDetails'
)

//Competencies Tab Selected items Drag and Drop url`s
export const COMPETENCY_MODEL_SORT_INSERT = BaseUrl(
  'api/Survey360/competencyModuleSortInsert'
)
export const GET_COMPETENCY_MODULE_SORT = BaseUrl(
  'api/Survey360/getCompetencyModuleSort'
)

// Selected Items Parent Accordion Drag & Drop url's

export const SELECTED_DRAG_DROP_GET = BaseUrl(
  'api/Survey360/competencySort360Get'
)
export const SELECTED_DRAG_DROP_DELETE = BaseUrl(
  'api/Survey360/competencySort360Delete'
)
export const SELECTED_DRAG_DROP_INSERT = BaseUrl(
  'api/Survey360/competencySort360Ins'
)
export const GET_USER_SEND_REPORT = BaseUrl('api/Survey360/userSentReportGet')
export const USER_REPORT_INSERT = BaseUrl('api/Survey360/userSentReportInsert')
