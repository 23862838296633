import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { API } from '../../helpers/API'
import {
  GET_ORGANIZATION_LOGO_URL,
  PROJECT_INDIVIDUAL_REPORT,
  TEAM_REPORT,
} from '../../helpers/constants/ApiRoutes'
import { authHeader, getOrganizationId } from '../common/CustomFunction'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Divider, Grid, Paper } from '@material-ui/core'
import Individual from '../../components/thinkwise_360/projects/steps/item_selection/ProjectView/individualreport.json'
import Image from '../../helpers/favicon.jpg'
import clogo from '../../assets/images/company_logo.jpg'
import { useSelector } from 'react-redux'
import { jsPDF } from 'jspdf'
import { useRef } from 'react'
import html2pdf from 'html2pdf.js'
import html2canvas from 'html2canvas'
import { createCanvas } from 'canvas'
import $ from 'jquery'
import ReactToPrint from 'react-to-print'
import IndividualReportPdf from './IndividualReportPdf'
import CustomButton from '../common/CustomButton'
import { generatethreechart } from '../../function/GenerateChart'
import { PDFExport } from '@progress/kendo-react-pdf'
import { savePDF } from '@progress/kendo-react-pdf'
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisTitle,
  ChartCategoryAxisItem,
  ChartArea,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartSeriesLabels,
} from '@progress/kendo-react-charts'
import 'hammerjs'
import circle from '../../assets/images/circle.png'
import { Stack } from '@mui/material'
import TranslateCompRight from '../../helpers/constants/TranslateCompRight'

let othersColor = '#64B5F6'

let colorCodes = {
  'Average (excl. Self)': '#0062FF',
  Self: '#4CAF50',
}
function componentToHex(c) {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}
const float = (val) => parseFloat(val).toFixed(2)

function rgbToHex(value) {
  let splitcode = value?.split(',')

  return (
    componentToHex(parseInt(splitcode[0])) +
    componentToHex(parseInt(splitcode[1])) +
    componentToHex(parseInt(splitcode[2]))
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridGap: theme.spacing(3),
  },
  bold: {
    fontWeight: 600,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    height: '80px',
    background: '#F6F6F6',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  projecthearing: {
    fontFamily: 'Roboto-Bold !important',
    fontSize: '16px',
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  tab: { background: '#E0E0E0' },
}))
const CusTab = withStyles((theme) => ({
  indicator: {
    fontFamily: 'Roboto-Medium !important',

    backgroundColor: theme.btnPrimary,
  },
  // '& .Mui-selected': {
  //   backgroundColor: 'red !important',
  //   color: 'blue !important',
  // },
}))(Tabs)

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

function Threesixtyreport() {
  const [firstSeries, secondSeries, thirdSeries, fourthSeries] = [
    [0],
    [5],
    [2],
    [1],
  ]
  const categories = ['Q1']
  const { data } = useParams()
  const [reportData, setreportData] = useState('')
  const [flag, setFlag] = useState(false)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const pdfExportComponent = React.useRef(null)
  const [activeLeadershiptab, setactiveLeadershiptab] = useState(
    'HIDDEN STRENGTHS'
  )
  const [value, setValue] = React.useState(0)
  const [orgLogoLoading, setOrgLogoLoading] = useState(false)
  const [orgLogo, setOrgLogo] = useState('')

  const orgLogoSelector = useSelector((state) => state.AccountsUsersReducer)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const {
    strReportTitle,
    strPName,
    strReportDesc,
    organizationName,
    projectName,
    ownerName,
    endDate,
    dueDateFormat,
    addSummaryCompetencyInstructions = [],
    addSummaryResults = [],
    addSummaryRespondentInstructions = [],
    addMatrix = [],
    addCompetencyResults = [],
    addTop10Instructions = [],
    addTop10 = [],
    addDataItems = [],
    addOpenEndedInstructions = [],
    addOpenEnded = [],
    addDetailedInstructions = [],
  } = reportData[0] || {}
  const [firstSummeryResult] = addSummaryResults
  const [firstaddCompetencyResults] = addCompetencyResults
  const [firstSummeryCompetencyInstruction] = addSummaryCompetencyInstructions

  const {
    title: firstaddCompetencyResultstitle,
    description: firstaddCompetencyResultsdescription,
    addCompetencyResultsData = [],
  } = firstaddCompetencyResults || {}
  const {
    title: summerySampletitle,
    description: summerySampledescription,
    addSummaryCompetencyInstructionsData = [],
  } = firstSummeryCompetencyInstruction || {}
  const {
    title: summeryresulttitle,
    description: summeryresultdescription,
    addSummaryResultsData = [],
  } = firstSummeryResult || {}
  const [ScaleDetails] = addSummaryCompetencyInstructionsData
  const {
    strAsciScaleIntro,
    strAsciListBp1,
    strAsciListBp2,
    strAsciListBp3,
    data: scaleData = [],
  } = ScaleDetails || {}
  const [firstaddTop10] = addTop10
  const {
    strAtTopName,
    strAtTopDesc,
    strAtBotName,
    strAtBotDesc,
    strAtGapName,
    addSummaryTopTable = [],
    addSummaryTopTableBottom = [],
    addSummaryTopTableGap = [],
    addSummaryTbResources = [],
    addSummaryTbResourcesBottom = [],
    strAtGapDesc2,
  } = firstaddTop10 || {}
  let sclaesize = scaleData.length
  let cno = 0
  console.log(
    'res',
    addSummaryTbResourcesBottom,
    addSummaryTbResources,
    sclaesize
  )
  const [firstaddOpenEnded] = addOpenEnded
  const {
    title: firstaddOpenEndedtitle,
    description: firstaddOpenEndeddescription,
    addOpenEndedData = [],
  } = firstaddOpenEnded || {}
  const [firstaddDataItems] = addDataItems
  const {
    title: firstaddDataItemstitle,
    description: firstaddDataItemsdescription,
    dataItem = [],
    dataItemsCustom = [],
  } = firstaddDataItems || {}

  let splitdetailedResult = {}
  let splitdetailedResultcus = {}
  console.log('data')
  dataItem.forEach((data) => {
    let innerData = {}
    splitdetailedResult[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResult[data.strCompNm + data.strCapNm]?.datavalues ||
          []),
        ...data.dataItemsData,
      ],
    }
  })
  console.log('item custom', dataItemsCustom)
  dataItemsCustom.forEach((data) => {
    let innerData = {}
    splitdetailedResultcus[data.strCompNm + data.strCapNm] = {
      strCapNm: data.strCapNm,
      strCompNm: data.strCompNm,
      datavalues: [
        ...(splitdetailedResultcus[data.strCompNm + data.strCapNm]
          ?.datavalues || []),
        ...data.dataItemsCustomData,
      ],
    }
  })
  let hid = 0

  console.log('cus', splitdetailedResultcus, dataItemsCustom)
  let allDataValues = []
  let allDataValuescus = []
  let splitmin = 0
  let splitmax = 0

  Object.values(splitdetailedResult).forEach((vsp) => {
    let inderClone = {}

    vsp.datavalues.forEach((val, index) => {
      if (index == 0) {
        splitmin = val.min
        splitmax = val.max
      }

      if (splitmin >= val.min) {
        splitmin = val.min
      }
      if (splitmax <= val.max) {
        splitmax = val.max
      }
      inderClone[val.zCaption] = [
        ...(inderClone[val.zCaption] || []),
        { ...val, color: colorCodes[val.itemNorm] || othersColor },
      ]
    })
    allDataValues.push({ ...vsp, inderClone })
  })
  Object.values(splitdetailedResultcus).forEach((vsp) => {
    let inderClone = {}

    vsp.datavalues.forEach((val, index) => {
      if (index == 0) {
        splitmin = val.min
        splitmax = val.max
      }

      if (splitmin >= val.min) {
        splitmin = val.min
      }
      if (splitmax <= val.max) {
        splitmax = val.max
      }
      inderClone[val.newText] = [
        ...(inderClone[val.newText] || []),
        { ...val, color: colorCodes[val.itemNorm] || othersColor },
      ]
    })
    console.log('inder clone', inderClone)
    allDataValuescus.push({ ...vsp, inderClone })
  })
  console.log('All data', allDataValues, allDataValuescus)
  let splitAllsummery = {}
  let summarymin = 0
  let summarymax = 0
  addSummaryResultsData &&
    addSummaryResultsData.forEach((summ, index) => {
      if (index == 0) {
        summarymin = summ.min
        summarymax = summ.max
      }

      if (summarymin >= summ.min) {
        summarymin = summ.min
      }
      if (summarymax <= summ.max) {
        summarymax = summ.max
      }
      splitAllsummery[summ.campName] = {
        name: summ.campName,
        data: [
          ...(splitAllsummery[summ.campName]?.data || []),
          {
            ...summ,
            color: summ.zCategoryN == 'Self' ? '#0062FF' : '#4CAF50',
          },
        ],
      }
    })
  console.log('spli', addSummaryResultsData, splitAllsummery)
  let splitcompetencyResult = {}
  let commin = 0
  let commax = 0
  addCompetencyResultsData.forEach((summ, index) => {
    if (index == 0) {
      commin = summ.min
      commax = summ.max
    }

    if (commin >= summ.min) {
      commin = summ.min
    }
    if (commax <= summ.max) {
      commax = summ.max
    }
    splitcompetencyResult[summ.strCompNm + summ.strCapNm] = {
      name: summ.strCompNm + summ.strCapNm,
      subname: summ.strCapNm || '',
      data: [
        ...(splitcompetencyResult[summ.strCompNm + summ.strCapNm]?.data || []),
        {
          ...summ,
        },
      ],
    }
  })
  Array.from(Array(summarymax + 1).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.splitspan_${c}:before {
  content: '' !important;
  position: absolute;
  display: block;
  top: 50%;
  height: 42px;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / summarymax) * 100}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })
  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.hiddensplitspan_one_${c}:before {
  content: '' !important;
  position: absolute;
  display: block;
  top: 91%;
  height: 60px;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 100}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize + 1).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.detailitem_${c}:before {
  content: '' !important;
  position: absolute;
  display: block;
  top: 91%;
  height: 30px;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / splitmax) * 100}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.knowStrength${c}:before {
  content: '' !important;
  position: absolute;
  display: block;
  top: 91%;
  height: 60px;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 100}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(sclaesize).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.knownarer${c}:before {
  content: '' !important;
  position: absolute;
  display: block;
  top: 91%;
  height: 60px;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 150}%;
  transform: translatey(-${20}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  Array.from(Array(commax + 1).keys()).map((c) => {
    var style = document.createElement('style')
    style.type = 'text/css'
    style.innerHTML = `
.compentenct${c}:before {
  content: '' !important;
  position: absolute;
  display: block;
  top: 91%;
  height: 34px;
  width: 2px;
  background: #BDBDBD;
  left: ${(c / sclaesize) * 120}%;
  transform: translatey(-${23}px) !important;
  z-index: 0;
}`
    document.getElementsByTagName('head')[0].appendChild(style)
  })

  const PDFExportPageTemplate = (props) => {
    return (
      <>
        {props.pageNum == 1 ? (
          <></>
        ) : (
          <>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                left: '350px',
                bottom: '80px',
                fontSize: '10px',
              }}>
              {projectName}, {strPName}
            </div>
            <div
              style={{
                position: 'absolute',
                top: '785px',
                left: '500px',
                bottom: '80px',
                fontSize: '10px',
              }}>
              Page {props.pageNum}
            </div>
          </>
        )}
      </>
    )
  }
  {
    /* <span>Page {props.pageNum} of {props.totalPages}</span> */
  }
  const leadershipHandle = (e) => {
    setactiveLeadershiptab(e.currentTarget.dataset.id)
  }

  const LeadershipComponent = (props) => {
    const { name } = props

    const CompList = {
      'HIDDEN STRENGTHS': Hidden,
      'KNOWN STRENGTHS': Knowstrength,
      'BLIND SPOTS': BlindSpots,
      'KNOWN AREAS FOR DEVELOPMENT': KnownAreas,
    }

    let CompDisplay = CompList[name]
    return <CompDisplay />
  }
  console.log('addsum', addSummaryTopTableGap)
  const Hidden = () => {
    return (
      <Grid>
        <br />
        <Typography variant="h4">Hidden Strengths</Typography>
        <Typography>
          Below are the individual items with the biggest gap between the
          average score and the self-rating, and where the self-rating is lower
          than the average.These competencies are therefore hidden strengths
          because you were not aware that you used them at such a high level
          with consistency.The related global and supporting competency is shown
          before the item.
        </Typography>
        {addSummaryTopTableGap.map((top) => {
          let widthSiz = 400
          let average = (parseInt(top.average) / (sclaesize - 1)) * widthSiz //high
          let average2 =
            (parseInt(top.selfRating) / (sclaesize + 0.01)) * widthSiz
          console.log(
            'average',
            top.average >= top.selfRating,
            top.average <= top.selfRating,
            average,
            average2
          )
          const { avg = average, selfRating } = top
          if (top.average >= top.selfRating ) {
            hid++
          }
          console.log(top.average, top.selfRating, 'top.selfRating')
          return top.average >= top.selfRating  ? (
            <div>
              <hr />

              <p>
                {top.competencyName}: {top.capabilityName}
                <br />
                {top.zCaption}
              </p>
              <table>
                <tr>
                  <td> {top.zCompareGroupName}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.averageRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={parseFloat(top.average).toFixed(2) || 'N/A'}
                      color={'#0062FF'}
                      precent={average}
                      chartWidth={widthSiz}
                    />
                    {/* | */}
                    {Array.from(Array(sclaesize).keys()).map((c) => {
                      return (
                        <span
                          aria-current="stedsadp"
                          className={`hiddensplitspan_one_${c}`}></span>
                      )
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{top.zCompareGroupNameSelf}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.selfRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                    }}>
                    {' '}
                    <BarGraph
                      value={top.selfRating || 'N/A'}
                      color={'#4CAF50'}
                      precent={average2}
                      chartWidth={widthSiz}
                    />
                    {/* {Array.from(Array(sclaesize).keys()).map((c) => {
                      return (
                        <span
                          aria-current="stedsadp"
                          className={`hiddensplitspan_two_${c}`}></span>
                      )
                    })} */}
                  </td>
                </tr>
              </table>
            </div>
          ) : (
            ''
          )
        })}
        {hid > 0 ? (
          ''
        ) : (
          <center>
            <p>There were no Hidden Strengths identified in the survey data.</p>
          </center>
        )}
      </Grid>
    )
  }

  const Knowstrength = () => {
    return (
      <Grid>
        <br />

        <Typography variant="h4">Known Strengths</Typography>
        <Typography>
          Below are the individual items/questions with the highest average
          score.
        </Typography>

        <Typography>
          The related global and supporting competency is shown before the
          question. The Self rating is shown for comparison.
        </Typography>

        <Grid
          container
          direction="row"
          // justifyContent="space-evenly"
        >
          
          {/* <Grid xm={6} spacing={2} xs={5}> */}
          {addSummaryTopTable.map((top) => {
            let widthSiz = 400
            let average = (top.average / (sclaesize-1)) * widthSiz
            let average2 =
              (top.selfRating / (sclaesize - 1)) * widthSiz
          return top.average <= top.selfRating  ? (

           
              <>
                <Grid xm={6} spacing={2} xs={5}>
                  <div>
                    <hr />

                    <p>
                      {top.competencyName}: {top.capabilityName}
                      <br />
                      {top.zCaption}
                    </p>
                    <table>
                      <tr>
                        <td> {top.zCompareGroupName}</td>
                        <td style={{ width: '3rem', 'text-align': 'center' }}>
                          {top.averageRatingCount}
                        </td>
                        <td
                          style={{
                            width: widthSiz,
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                          }}>
                          {' '}
                          <BarGraph
                            value={float(top.average) || 'N/A'}
                            color={'#0062FF'}
                            precent={average}
                            chartWidth={widthSiz}
                          />
                          {Array.from(Array(sclaesize).keys()).map((c) => {
                            return (
                              <span
                                aria-current="stedsadp"
                                className={`knowStrength${c}`}></span>
                            )
                          })}
                        </td>
                      </tr>
                      <tr>
                        <td>{top.zCompareGroupNameSelf}</td>
                        <td style={{ width: '3rem', 'text-align': 'center' }}>
                          {top.selfRatingCount}
                        </td>
                        <td
                          style={{
                            width: widthSiz,
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                          }}>
                          {' '}
                          <BarGraph
                            value={top.selfRating || 'N/A'}
                            color={'#4CAF50'}
                            precent={average2}
                            chartWidth={widthSiz}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </Grid>
                <Grid xm={6} spacing={2} xs={7}>
                  <div>
                    <hr />

                    {addSummaryTopTable.map((n) => {
                      return n.capabilityName == top.capabilityName &&
                        n.competencyName == top.competencyName &&
                        n.zCaption == top.zCaption ? (
                        <div style={{ padding: '40px 0' }}>
                          <Typography
                            style={{
                              display: 'flex',
                              'align-items': 'center',
                              'font-size': '22px',
                              color: '#00CC01',
                            }}>
                            <img src={Image} />
                            Learning Resource
                          </Typography>
                          <Typography>
                            <a href={n.zResourceLink} target="_blank">
                              {n.zName}
                            </a>
                            <br />
                            {n.zDescription}
                          </Typography>
                        </div>
                      ) : (
                        <div></div>
                      )
                    })}
                  </div>
                </Grid>
              </>)
            :<></>
          })}
          {/* </Grid> */}
          {/* <Grid xm={6} spacing={2} xs={5}>
            {addSummaryTbResources
              .map((v) => v.addSummaryTbResourceTable)
              .map((n) => {
                return n.map((re) => {
                  return (
                    <div style={{ padding: '40px 0' }}>
                      <Typography
                        style={{
                          display: 'flex',
                          'align-items': 'center',
                          'font-size': '22px',
                          color: '#00CC01',
                        }}>
                        <img src={Image} />
                        Learning Resource
                      </Typography>
                      <Typography>
                        <a
                          href={re.linkurl}
                          target="_blank">
                          {re.resName}
                        </a>
                        {re.zDescription}
                      </Typography>
                    </div>
                  )
                })
              })}
          </Grid> */}
        </Grid>
      </Grid>
    )
  }
  const BlindSpots = () => {
    return (
      <Grid>
        <br />

        <Typography variant="h4">Blind Spots</Typography>
        <Typography>
          Below are the individual items with the biggest gap between the
          average score and the self-rating, and where the self-rating is higher
          than the average. These competencies are therefore blind spots that
          may need additional focus for development. The related global and
          supporting competency is shown before the item.
        </Typography>
        {addSummaryTopTableGap.map((top) => {
          let widthSiz = 400
          let average = (top.average / (sclaesize - 1)) * widthSiz
          let average2 = (top.selfRating / (sclaesize - 1)) * widthSiz
          const { avg = average, selfRating } = top
          return top.average <= top.selfRating == true ? (
            <div>
              <hr />

              <p>
                {top.competencyName}: {top.capabilityName}
                <br />
                {top.zCaption}
              </p>
              {/* <table>
                <tr>
                  <td> {top.zCompareGroupName}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.averageRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    <BarGraph
                      value={float(top.average) || 'N/A'}
                      color={'#0062FF'}
                      precent={average}
                      chartWidth={widthSiz}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{top.zCompareGroupNameSelf}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.selfRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={float(top.selfRating) || 'N/A'}
                      color={'#4CAF50'}
                      precent={average2}
                      chartWidth={widthSiz}
                    />
                  </td>
                </tr>
              </table> */}
              <table>
                <tr>
                  <td> {top.zCompareGroupName}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.averageRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={float(top.average) || 'N/A'}
                      color={'#0062FF'}
                      precent={average}
                      chartWidth={widthSiz}
                    />
                    {Array.from(Array(sclaesize).keys()).map((c) => {
                      return (
                        <span
                          aria-current="stedsadp"
                          className={`knowStrength${c}`}></span>
                      )
                    })}
                  </td>
                </tr>
                <tr>
                  <td>{top.zCompareGroupNameSelf}</td>
                  <td style={{ width: '3rem', 'text-align': 'center' }}>
                    {top.selfRatingCount}
                  </td>
                  <td
                    style={{
                      width: widthSiz,
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                    }}>
                    {' '}
                    <BarGraph
                      value={top.selfRating || 'N/A'}
                      color={'#4CAF50'}
                      precent={average2}
                      chartWidth={widthSiz}
                    />
                  </td>
                </tr>
              </table>
            </div>
          ) : (
            ''
          )
        })}
      </Grid>
    )
  }
  const KnownAreas = () => {
    return (
      <>
        <Grid>
          <br />

          <Typography variant="h4">Known Areas For Development</Typography>
          <Typography>
            Below are the individual items/questions with the lowest average
            score.
          </Typography>

          <Typography>
            The related global and supporting competency is shown before the
            question. The Self rating is shown for comparison.
          </Typography>
          <Grid container direction="row">
            {addSummaryTopTableBottom.map((top) => {
              let widthSiz = 400
              let average = (top.average / (sclaesize - 1)) * widthSiz
              let average2 =
                (top.selfRating / (sclaesize - 1)) * widthSiz

              return top.average >= top.selfRating == true ? (
                <>
                  <Grid xm={6} spacing={2} xs={5}>
                    <div>
                      <hr />

                      <p>
                        {top.competencyName}: {top.capabilityName}
                        <br />
                        {top.zCaption}
                      </p>
                      <br />
                      <table>
                        <tr>
                          <td> {top.zCompareGroupName}</td>
                          <td style={{ width: '3rem', 'text-align': 'center' }}>
                            {top.averageRatingCount}
                          </td>
                          <td
                            style={{
                              width: widthSiz,
                              display: 'flex',
                              flexDirection: 'row',
                              position: 'relative',
                            }}>
                            {' '}
                            <BarGraph
                              value={
                                parseFloat(top.average).toFixed(2) || 'N/A'
                              }
                              color={'#0062FF'}
                              precent={average}
                              chartWidth={widthSiz}
                            />
                            {Array.from(Array(sclaesize).keys()).map((c) => {
                              return (
                                <span
                                  aria-current="stedsadp"
                                  className={`knownarer${c}`}></span>
                              )
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>{top.zCompareGroupNameSelf}</td>
                          <td style={{ width: '3rem', 'text-align': 'center' }}>
                            {top.selfRatingCount}
                          </td>
                          <td
                            style={{
                              width: widthSiz,
                              display: 'flex',
                              flexDirection: 'row',
                              position: 'relative',
                            }}>
                            {' '}
                            <BarGraph
                              value={
                                parseFloat(top.selfRating).toFixed(2) || 'N/A'
                              }
                              color={'#4CAF50'}
                              precent={average2}
                              chartWidth={widthSiz}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Grid>
                  <Grid xm={6} spacing={2} xs={7}>
                    <hr />
                    {addSummaryTopTableBottom &&
                      addSummaryTopTableBottom.map((n) => {
                        console.log(n.zResourceLink, 'zResourceLink')
                        return n.capabilityName == top.capabilityName &&
                          n.competencyName == top.competencyName &&
                          n.zCaption == top.zCaption ? (
                          <div style={{ padding: '40px 0' }}>
                            <Typography
                              style={{
                                display: 'flex',
                                'align-items': 'center',
                                'font-size': '22px',
                                color: '#00CC01',
                              }}>
                              <img src={Image} />
                              Learning Resource
                            </Typography>
                            <Typography>
                              <a href={n.zResourceLink} target="_blank">
                                {n.zName}
                              </a>
                              <br />
                              {n.zDescription}
                            </Typography>
                          </div>
                        ) : (
                          <div></div>
                        )
                      })}
                  </Grid>
                </>
              ) : (
                ''
              )
            })}
          </Grid>
          {/* <Grid xm={6} spacing={2} xs={5}> */}

          {/* </Grid> */}
          {/* </Grid> */}
        </Grid>
      </>
    )
  }
  let newDataItems = {}
  dataItem.forEach((da) => {
    // console.log(da)
    newDataItems[da.strCompNm + '_' + da.strCapNm] = {
      ...(newDataItems[da.strCompNm + '_' + da.strCapNm] || {}),
      dataItemsData: [
        ...((newDataItems[da.strCompNm + '_' + da.strCapNm] || {})
          .dataItemsData || []),
        ...da.dataItemsData,
      ],
    }
  })
  // let sampleDta = Object.values(newDataItems).map((val) => {
  //   let inData = {}
  //   val.dataItemsData.forEach((ind) => {
  //     inData[ind.zCaption] = { ...(inData[ind.zCaption] || {}), ...ind }
  //   })
  //   console.log(inData)
  //   return { ...val, inData }
  // })
  // console.log(newDataItems)
  console.log('data', data)
  let name = 'dasd'
  async function getReportdata() {
    setLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),
      url: `${PROJECT_INDIVIDUAL_REPORT}`,
      params: {
        ...JSON.parse(Buffer.from(data, 'base64').toString('binary')),
        ROLN: true,
        DOERS: true,
        SS: true,
        THE5S: true,
        THE10S: true,
      },
    }
    try {
      const response = await API(config)
      const data = response.data
      console.log('response', response.data)
      setreportData(data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  console.log('report data', reportData, reportData[0])
  useEffect(() => {
    getReportdata()
  }, [])
  //eyJJbmNsdWRlT3JnQXZlcmFnZSI6InRydWUiLCJJbmNsdWRlUHJvamVjdEF2ZXJhZ2UiOiJ0cnVlIiwiU2VsZWN0ZWRVc2VycyI6IjIxMDM3MCJ9
  console.log(reportData)
  let inu = 1

  const getOrangizationLogo = async () => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_ORGANIZATION_LOGO_URL,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    setOrgLogoLoading(true)
    try {
      const response = await API(getconfig)
      const { status, data } = response.data

      if (response.status == 200) {
        setOrgLogoLoading(false)
        setOrgLogo(data[0])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setOrgLogoLoading(false)
    }
  }

  useEffect(() => {
    getOrangizationLogo()
  }, [orgLogoSelector])
  const contentRef = useRef(null)
  const componentRef = useRef(null)
  // const samplechk=useRef(null)
  // const samplechk1=useRef(null)
  const samplechk2 = useRef(null)
  //  const splitHtmlToMutiPagePdf=async()=>{
  //   var content=document.querySelector('.sample-rep')
  //   var htmlWidth = $(".sample-rep").width();
  // 	var htmlHeight = $(".sample-rep").height();
  // 	var pdfWidth = htmlWidth + (15 * 2);
  // 	var pdfHeight = (pdfWidth * 1.5) + (15 * 2);

  // 	var doc = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);

  // 	var pageCount = Math.ceil(htmlHeight / pdfHeight) - 1;
  //   html2canvas($(".sample-rep")[0], { allowTaint: true }).then(function(canvas) {
  // 		canvas.getContext('2d');
  //     var image = canvas.toDataURL("image/png", 1.0);
  // 		doc.addImage(image, 'PNG', 15, 15, htmlWidth, htmlHeight);

  // 		for (var i = 1; i <= pageCount; i++) {
  // 			doc.addPage(pdfWidth, pdfHeight);
  // 			doc.addImage(image, 'PNG', 15, -(pdfHeight * i)+15, htmlWidth, htmlHeight);
  // 		}

  // 		doc.save("split-to-multi-page.pdf");
  //   })
  //  }
  const genPdf1 = async () => {
    var element = document.getElementById('simp')
    var clonedElement = element.cloneNode(true)

    // change display of cloned element
    $(clonedElement).css('display', 'block')
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'pt',
      format: 'a4',
    })

    const opt = {
      margin: [5, 10, 15, 0],
      filename: `output.pdf`,
      enableLinks: false,
      pagebreak: {
        avoid: ['tr'],
        mode: ['css', 'legacy'],
      },
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 2,
        scrollX: 0,
        scrollY: 0,
      },
      jsPDF: { doc },
    }

    html2pdf()
      .from(clonedElement)
      .set(opt)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const totalPages = pdf.internal.getNumberOfPages()

        for (let i = 1; i < totalPages + 1; i++) {
          pdf.setPage(i)
          pdf.setFontSize(8)
          pdf.text(
            `${i}/${totalPages}`,
            pdf.internal.pageSize.getWidth() - 10,
            pdf.internal.pageSize.getHeight() - 5
          )
        }
      })
      .save()
  }

  const genPdf3 = async () => {
    const content = document.getElementById('simp')

    var HTML_Width = $('.simp').width()
    var HTML_Height = $('.simp').height()
    var top_left_margin = 15
    var PDF_Width = HTML_Width + top_left_margin * 2
    var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2
    var canvas_image_width = HTML_Width
    var canvas_image_height = HTML_Height

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1

    html2canvas($('.simp')[0], { allowTaint: true }).then(function(canvas) {
      canvas.getContext('2d')

      console.log(canvas.height + '  ' + canvas.width)

      var imgData = canvas.toDataURL('image/jpeg', 1.0)
      var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
      pdf.addImage(
        imgData,
        'JPG',
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      )

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height)
        pdf.addImage(
          imgData,
          'JPG',
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        )
      }

      pdf.save('HTML-Document.pdf')
    })
  }
  const genPdf2 = async () => {
    const content = document.getElementById('simp')

    var pdf = new jsPDF('p', 'pt', 'a4')
    pdf.html($('#simp'), 15, 15, {
      width: 400,
    })
    pdf.save('myPdf.pdf')
  }
  const genPdf = async () => {
    var doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      // format: [800,1750],
      format: 'a4',
      compress: true,
      // putOnlyUsedFonts: true,
    })
    // const content=document.querySelector('#samplechk')
    const content1 = document.querySelector('#samplechk')
    const content2 = document.querySelector('#details1')
    const content = document.getElementById('simp')
    const content4 = document.querySelector('#si')
    // doc.fromHTML(content.html()+content1.html(),15,15)
    //     doc.save("output.pdf");
    const contents = content + content1
    // const opt = {
    //   margin: 1,
    //   filename: 'myfile.pdf',
    //   image: { type: 'jpeg', quality: 0.98 },
    //   html2canvas: { scale: 2.5 },
    //   jsPDF: { unit: 'in', format: 'a1', orientation: 'portrait' },
    // };

    // // New Promise-based usage:
    // html2pdf()
    //   .set(opt)
    //   .from(content)
    //   .save();
    // var imgData;
    // html2canvas(content1,{
    //   useCORS:true,
    //   onrendered:function(canvas){
    //     imgData=canvas.toDataUrl('image/png')
    //     doc.addImage(imgData,'PNG',10,10)
    //   }
    // })
    // const opt = {
    //   margin: [5, 0, 8, 0],
    //   filename: `output.pdf`,
    //   enableLinks: false,
    //   pagebreak: {
    //     avoid: ["tr"],
    //     mode: ["css", "legacy"],
    //   },
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: {
    //     allowTaint: true,
    //     dpi: 300,
    //     letterRendering: true,
    //     logging: false,
    //     scale: 2,
    //     scrollX: 0,
    //     scrollY: 0,
    //   },
    //   jsPDF: { doc },
    // };

    // await html2pdf()
    //   .from(content)
    //   .set(opt)
    //   .toPdf()
    //   .get("pdf")
    //   .then((pdf) => {
    //     const totalPages = pdf.internal.getNumberOfPages();

    //     for (let i = 1; i < totalPages + 1; i++) {
    //       pdf.setPage(i);
    //       pdf.setFontSize(8);
    //       pdf.text(
    //         `${i}/${totalPages}`,
    //         pdf.internal.pageSize.getWidth() - 10,
    //         pdf.internal.pageSize.getHeight() - 5
    //       );
    //     }
    //   })
    //   .output("datauristring")
    //   .save("sp.pdf")
    //single page perfect one
    // const pdf = new jsPDF();
    // window.scrollTo(0,0);
    // html2pdf(content)
    const divHeight = content.clientHeight
    const divWidth = content.clientWidth
    const ratio = divHeight / divWidth
    const options = {
      scale: 1,
      height: content.offsetHeight + 100, // add 100px for top and bottom spacing
    }
    var header = function(currentPage, pageCount) {
      return {
        text: currentPage.toString() + ' of ' + pageCount,
        alignment: 'center',
        margin: [0, 30, 0, 0], // specify bottom margin of 30 units
      }
    }
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      margin: [20, 20, 20, 20],
      precision: 20,
    })

    html2canvas(
      content,
      {
        onclone: function(clonedDoc) {
          clonedDoc.getElementById('simp').style.display = 'block'
        },
      },
      { useCORS: true, allowTaint: true, scrollY: 0, scale: 0.5 }
    ).then((canvas, options) => {
      canvas.getContext('2d')
      const imgData = canvas.toDataURL('image/png')
      var margin = 10
      var imgWidth = 210 - 2 * margin
      // var imgWidth = 210;
      var pageHeight = 295
      var imgHeight = (canvas.height * imgWidth) / canvas.width
      var heightLeft = imgHeight - 2 * margin
      // var margin=10
      var width = pdf.internal.pageSize.getWidth()
      var height = pdf.internal.pageSize.getHeight()
      pdf.setFontSize(12)
      const marginTop = 60 // in mm
      const marginBottom = 60 // in mm
      const options1 = {
        top: marginTop,
        bottom: marginBottom,
      }
      // pdf.setMargins(0, marginTop, 0, marginBottom);
      var position = 15
      var page = 1 // use this as a counter.
      //   pdf.margin = {
      //     horiz: 15,
      //     vert: 20
      // };
      // pdf.addPage()
      footer()

      pdf.setFontSize(20)
      pdf.text(`${projectName}`, 20, 120)
      pdf.text(`${strReportDesc}`, 20, 130)
      pdf.text(`Due Date:${dueDateFormat}`, 20, 140)
      // pdf.text("LOGO:",10,180)
      pdf.addImage(orgLogo ? orgLogo.zLogoURL : '', 'JPEG', 20, 150, 50, 20)
      pdf.setFontSize(12)

      pdf.addPage()
      pdf.setPage(page, options)
      function footer() {
        pdf.setFontSize(8)
        pdf.text(10, 10, projectName + '  ' + strPName) //print number bottom right

        pdf.addImage(orgLogo ? orgLogo.zLogoURL : '', 'JPEG', 120, 10, 10, 5)

        pdf.text(180, 10, 'page ' + page) //print number bottom right
        page++
      }
      pdf.autoPaging = 'text'
      footer()
      //  pdf.setPage(page, options1);
      pdf.setPage(page, options)

      pdf.addImage(
        imgData,
        'JPEG',
        margin,
        position,
        imgWidth,
        imgHeight + 140,
        '',
        'FAST'
      )
      heightLeft -= pageHeight

      while (heightLeft >= 0) {
        console.log('wonder,', heightLeft, imgHeight, pageHeight, position)
        position = heightLeft - imgHeight
        pdf.addPage()
        pdf.text('for space', 20, 40)
        //  pdf.setPage(page, options1);

        footer()
        pdf.setPage(page, options)

        pdf.addImage(
          imgData,
          'JPEG',
          margin,
          position,
          imgWidth - 20,
          imgHeight,
          '',
          'FAST'
        )
        // position=position-20
        heightLeft -= pageHeight
        console.log('wonder1', heightLeft, pageHeight, position)

        //  pdf.autoTable({footer: header});
        // position=position+15
      }
      // var pageSize = pdf.internal.pageSize;
      // const pc=pdf.internal.getNumberOfPages()
      //     for(var i=1;i<=pc;i++){
      //       pdf.setPage(i)
      //       pdf.text('Page '+i,280,pageSize.getHeight()-20)
      //     }
      pdf.save(`Individual Report_${strPName}.pdf`)
    })
    // let jsPdf = new jsPDF('p', 'pt', 'letter');
    // var htmlElement = document.getElementById('simp');
    // // you need to load html2canvas (and dompurify if you pass a string to html)
    // jsPdf.html(htmlElement, {
    //   callback: function(doc) {
    //     jsPdf.save("output.pdf");
    //   },
    //   x: 10,
    //   y: 10
    // });
    // jsPdf.save("sample.pdf")
    // pdf.save("download.pdf");
    // var opt = {
    //   margin: 0,
    //   filename: 'myfile.pdf',
    //   image: {type: 'jpeg',quality: 0.98},
    //   html2canvas: {scale: 2},
    //   jsPDF: {
    //     unit: 'mm',
    //     format: [280, 350],
    //     orientation: 'portrait'
    //   }
    // };

    // html2pdf().set(opt).from(content).save();
    // const opt = {
    //   margin: [5, 0, 8, 0],
    //   filename: `output.pdf`,
    //   enableLinks: false,
    //   pagebreak: {
    //     avoid: ["tr"],
    //     mode: ["css", "legacy"],
    //   },
    //   image: { type: "jpeg", quality: 1 },
    //   html2canvas: {
    //     allowTaint: true,
    //     dpi: 300,
    //     letterRendering: true,
    //     logging: false,
    //     scale: 2,
    //     scrollX: 0,
    //     scrollY: 0,
    //   },
    //   jsPDF: { doc },
    // };
    // html2pdf()
    //       .from(content4)
    //       .set(opt)
    //       .toPdf()
    //       .get("pdf")
    //       .then((pdf) => {
    //         const totalPages = pdf.internal.getNumberOfPages();

    //         for (let i = 1; i < totalPages + 1; i++) {
    //           pdf.setPage(i);
    //           pdf.setFontSize(8);
    //           pdf.text(
    //             `${i}/${totalPages}`,
    //             pdf.internal.pageSize.getWidth() - 10,
    //             pdf.internal.pageSize.getHeight() - 5
    //           );
    //         }
    //       })
    //       .output();
    // document.getElementById('simp').parentNode.style.overflow = 'visible';

    //     html2canvas( document.getElementById('#simp'), {
    //         onrendered: function(canvas) {
    //             document.getElementById('simp').parentNode.style.overflow = 'hidden';
    //             var dataUrl = canvas.toDataURL();
    //             window.saveAs(dataUrl, "toDataURL() image", "width=800, height=800");
    //             //Canvas2Image.saveAsPNG(canvas);
    //             const pdf = new jsPDF();
    //         pdf.addImage(dataUrl, 'JPEG', 0, 0);
    //         pdf.save("download.pdf");
    //         }
    //      });
    //last working one
    // doc.html(content, { html2canvas: { scale: 0.57 } }).then(() => {
    //   doc.save("test.pdf");
    // });
    // await doc.html(content, {
    //   margin: [40, 60, 40, 60],
    //   // autoPaging: 'text',
    //   callback: function(doc) {

    //    doc.setFontSize(10)
    //   var pageSize = doc.internal.pageSize;

    //     const pc=doc.internal.getNumberOfPages()
    //     for(var i=1;i<=pc;i++){
    //       doc.setPage(i)
    //       doc.text('Page '+i,280,pageSize.getHeight()-20)
    //     }
    //   return doc
    //   },

    // });
    //
    // await doc.html(content1, {
    //   callback: function(doc) {

    //     // doc.save("output.pdf");

    //   return doc
    //   },

    // });
    // await doc.html(content2, {
    //   callback: function(doc) {

    //     // doc.save("output.pdf");

    //   return doc
    //   },

    // });
    // doc.save('Individual Report.pdf')
    // doc.html(document.getElementById("samplechk"), {
    //   callback: function (pdf) {
    //     pdf.addPage("a4", "l");
    //     pdf.html(document.getElementById("samplechk1"), {
    //       callback: function (pdf2) {
    //         pdf2.addPage("a4", "l");
    //         pdf2.html(document.getElementById("samplechk2"), {
    //           callback: function (pdf3) {
    //             pdf3.save("multipage.pdf")
    //           },

    //         });
    //       },

    //     });
    //   },

    // });
    // doc.save('op.pdf')
    // const content = contentRef.current;

    // const doc = new jsPDF({
    //   orientation: "portrait",
    //   unit: "pt",
    //   format: "a4",
    // });
    // var pdfContent = document.querySelector("#samplechk");
    // var optionArray = {
    //   margin:       10,
    //   filename:     'output.pdf',
    //   jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    // };

    // // html to pdf generation with the reference of PDF worker object
    // html2pdf().set(optionArray).from(pdfContent).save();
  }
  const capturePDF = async () => {
    const content = document.getElementById('simp')
    let jsPdf = new jsPDF('p', 'pt', 'letter')
    // you need to load html2canvas (and dompurify if you pass a string to html)
    const opt = {
      callback: function(jsPdf) {
        jsPdf.save('Test.pdf')
        // to open the generated PDF in browser window
        // window.open(jsPdf.output('bloburl'));
      },
      margin: [72, 72, 72, 72],
      autoPaging: 'text',
      html2canvas: {
        allowTaint: true,
        dpi: 300,
        letterRendering: true,
        logging: false,
        scale: 0.8,
      },
    }

    jsPdf.html(content, opt)
  }

  const genPdf4 = async () => {
    var doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      // format: [800,1750],
      format: 'a4',
      // putOnlyUsedFonts: true,
    })
    // const content=document.querySelector('#samplechk')
    const content1 = document.querySelector('#samplechk')
    const content2 = document.querySelector('#details1')
    const content = document.getElementById('simp')
    const content4 = document.querySelector('#si')
    // doc.fromHTML(content.html()+content1.html(),15,15)
    //     doc.save("output.pdf");
    const contents = content + content1

    const divHeight = content.clientHeight
    const divWidth = content.clientWidth
    const ratio = divHeight / divWidth
    const options = {
      scale: 1,
      height: content.offsetHeight + 100, // add 100px for top and bottom spacing
    }
    var header = function(currentPage, pageCount) {
      return {
        text: currentPage.toString() + ' of ' + pageCount,
        alignment: 'center',
        margin: [0, 30, 0, 0], // specify bottom margin of 30 units
      }
    }
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      margin: { top: 70, bottom: 70 },
    })

    html2canvas(
      content1,
      {
        onclone: function(clonedDoc) {
          clonedDoc.getElementById('simp').style.display = 'block'
        },
      },
      { useCORS: true, allowTaint: true, scrollY: 0, scale: 2 }
    )
      .then((canvas, options) => {
        canvas.getContext('2d')
        const imgData = canvas.toDataURL('image/png')
        var margin = 10
        var imgWidth = 210 - 2 * margin
        // var imgWidth = 210;
        var pageHeight = 295
        var imgHeight = (canvas.height * imgWidth) / canvas.width
        var heightLeft = imgHeight
        // var margin=10
        var width = pdf.internal.pageSize.getWidth()
        var height = pdf.internal.pageSize.getHeight()
        pdf.setFontSize(12)
        const marginTop = 60 // in mm
        const marginBottom = 60 // in mm
        const options1 = {
          top: marginTop,
          bottom: marginBottom,
        }
        // pdf.setMargins(0, marginTop, 0, marginBottom);
        var position = 15
        var page = 1 // use this as a counter.
        //   pdf.margin = {
        //     horiz: 15,
        //     vert: 20
        // };
        // pdf.addPage()
        footer()

        pdf.setFontSize(20)
        pdf.text(`${projectName}`, 20, 120)
        pdf.text(`${strReportDesc}`, 20, 130)
        pdf.text(`Due Date:${dueDateFormat}`, 20, 140)
        // pdf.text("LOGO:",10,180)
        pdf.addImage(orgLogo ? orgLogo.zLogoURL : '', 'JPEG', 20, 150, 50, 20)
        pdf.setFontSize(12)

        pdf.addPage()
        function footer() {
          pdf.setFontSize(8)
          pdf.text(10, 10, projectName + '  ' + strPName) //print number bottom right

          pdf.addImage(orgLogo ? orgLogo.zLogoURL : '', 'JPEG', 120, 10, 10, 5)

          pdf.text(180, 10, 'page ' + page) //print number bottom right
          page++
        }
        //  pdf.autoPaging = 'slice';
        footer()
        //  pdf.setPage(page, options1);
        pdf.addImage(
          imgData,
          'JPEG',
          margin,
          position,
          imgWidth,
          imgHeight + 140
        )
        heightLeft -= pageHeight

        while (heightLeft >= 0) {
          console.log('wonder,', heightLeft, imgHeight, pageHeight, position)
          position = heightLeft - imgHeight
          pdf.addPage()
          //  pdf.setPage(page, options1);

          footer()
          pdf.addImage(
            imgData,
            'JPEG',
            margin,
            15,
            imgWidth - 50,
            imgHeight - 400
          )
          // position=position-20
          heightLeft -= pageHeight
          console.log('wonder1', heightLeft, pageHeight, position)
        }
      })
      .then(() => {
        pdf.addPage()
        html2canvas(content1).then((canvasDiv2) => {
          //...
          const imgData1 = canvasDiv2.toDataURL('image/png')
          pdf.addImage(imgData1, 'JPEG', 20, 150, 50, 20)

          // save on the last element
          pdf.save('download.pdf')
        })
      })
  }
  const generatePDF = async () => {
    // const input = document.getElementById('simp');
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF('p', 'pt', 'a4');
    //   pdf.addImage(imgData, 'JPEG', 0, 0, 595.28, 841.89);
    //   pdf.autoTable({ startY: 600, html: '#simp' });
    //   pdf.save('example.pdf');
    // });
    // Get the dynamic content element
    const dynamicContent = document.getElementById('simp')

    // Set the top and bottom margins (in millimeters)
    const topMargin = 10
    const bottomMargin = 10

    // Set the page width and height (in millimeters)
    const pageWidth = 210
    const pageHeight = 297

    // Calculate the available height for content (excluding top and bottom margins)
    const availableHeight = pageHeight - topMargin - bottomMargin

    // Create a canvas for the dynamic content
    const contentCanvas = await html2canvas(dynamicContent, {
      windowWidth: dynamicContent.offsetWidth,
      windowHeight: dynamicContent.offsetHeight,
      scrollY: -window.scrollY,
    })

    // Calculate the scaling factor to fit the content onto the available height
    const scale = parseInt(availableHeight / contentCanvas.height)

    // Create a new PDF instance
    const pdf = new jsPDF('p', 'mm', [pageWidth, pageHeight])

    // Add the dynamic content to the PDF with top and bottom margins
    pdf.addImage(
      contentCanvas.toDataURL('image/png'),
      'JPEG',
      0,
      topMargin,
      contentCanvas.width * scale,
      contentCanvas.height * scale
    )

    // Save the PDF
    pdf.save('example.pdf')
  }
  let gc = []
  let gd = []
  const ExportWrapper = React.useRef(null)

  return (
    <>
      {/* 
   <div ref={ExportWrapper}>
        <div>cantoo loggooo</div>
        <img
          src="https://cantoo.blob.core.windows.net/cantoo/cantoologotypeoutsm_17-03-2023-13-06-23.png"
          alt="KendoReact PDF Generator embedded image"
          width="75px"
        />
      </div> */}
      <div
        style={{
          position: 'absolute',
          left: '-5000px',
          top: 0,
        }}>
        <PDFExport
          forcePageBreak=".page-break"
          paperSize="A4"
          margin={{ top: '1.5cm', left: '1cm', right: '1cm', bottom: '1cm' }}
          fileName={`Individual Report_${strPName}.pdf`}
          ref={pdfExportComponent}
          pageTemplate={PDFExportPageTemplate}>
          <div
            style={{
              width: '100%',
            }}>
            <img
              // src={orgLogo}
              src={''}
              // src="https://cantoo.blob.core.windows.net/cantoo/cantoologotypeoutsm_17-03-2023-13-06-23.png"
              // alt="KendoReact PDF Generator embedded image"
              alt="logo"
              width="70px"></img>
            <IndividualReportPdf
              orgLogo={orgLogo ? orgLogo.zLogoURL : ''}
              projectName={projectName}
              strPName={strPName}
              dueDateFormat={dueDateFormat}
              BarGraph={BarGraph}
              data={data}
            />
          </div>
        </PDFExport>
      </div>

      <div
        style={{
          background: 'white',
          width: '100%',
          padding: '0px 20px',
        }}
        className="sample-rep">
        <br />
        <Stack
          direction="row"
          spacing={1}
          className="dld-report"
          style={{ float: 'right', marginRight: '2rem' }}>
          <CustomButton
            // onClick={genPdf}
            onClick={() => {
              if (pdfExportComponent.current) {
                pdfExportComponent.current.save()
                // savePDF(pdfExportComponent.current)
              }
            }}
            // onClick={() => {
            //   if (ExportWrapper.current) {
            //     savePDF(ExportWrapper.current, {
            //       imageResolution: 36,
            //     });
            //   }
            // }}
            // onClick={downloadPdf}
            disabled={loading}
            colour="primaryButton"
            // style={{ float: 'right', marginRight: '2rem' }}
          >
            Download Report
          </CustomButton>
          <TranslateCompRight />
        </Stack>
        <Box
          sx={{
            margin: '1rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant="h5">360 Report</Typography>

          <Box>
            {orgLogo ? (
              <img
                src={orgLogo?.zLogoURL || ''}
                alt="logo"
                width="auto"
                height="40"
              />
            ) : (
              ''
            )}
          </Box>
          <Box></Box>
        </Box>
        <Divider className={classes.divider} />

        <Grid container style={{ margin: 0, padding: '0px 20px' }} spacing={3}>
          <Grid item xs={12} md={2}>
            <p style={{ fontWeight: 'bold' }}>
              <b className={classes.projecthearing}>Project Name</b>
            </p>
            <p>{projectName}</p>
          </Grid>
          <Grid item xs={12} md={2}>
            <p style={{ fontWeight: 'bold' }}>
              <b className={classes.projecthearing}>Participant Name </b>
            </p>
            <span>{strPName}</span>
          </Grid>{' '}
          <Grid item xs={12} md={2}>
            <p style={{ fontWeight: 'bold' }}>
              <b className={classes.projecthearing}>Due Date:</b>
            </p>
            <span>{dueDateFormat}</span>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} md={8}>
          {' '}
          <CusTab
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            inkBarStyle={{ background: 'yellow' }}
            scrollButtons="auto">
            <Tab className={classes.tab} label="SUMMARY" {...a11yProps(0)} />
            <Tab
              className={classes.tab}
              label="LEADERSHIP QUADRANT"
              {...a11yProps(1)}
            />
            <Tab
              className={classes.tab}
              label="ITEMS DETAIL"
              {...a11yProps(2)}
            />
            <Tab
              className={classes.tab}
              label="OPEN-ENDED RESPONSES"
              {...a11yProps(2)}
            />
          </CusTab>
        </Grid>
        <TabPanel value={value} index={0}>
          <Grid>
            <Grid>
              <div>
                <Typography>
                  <span style={{ fontSize: '1.3rem' }}>
                    {' '}
                    Congratulations on receiving your feedback.
                  </span>
                  This is one of many steps you can take to initiate your
                  personal development journey. Understanding feedback requires
                  an openness to learn, a willingness to make improvements and
                  adjustments, and a dedication to yourself and others. To best
                  prepare you for your feedback, consider this diagram on
                  behavioral change. Remember that confidence builds as we are
                  actively engaged through the Action phase. This phase allows
                  for taking new behaviors we learn and putting them into
                  practice.
                </Typography>
                <br />
                <Typography variant="h4">
                  Summary Results by Competency
                </Typography>
                <Typography>
                  Below are the summary of your results by competency.
                </Typography>
                <Typography>
                  The top horizontal bar indicates the Average rating, excluding
                  self from all statements and respondents under each
                  competency.
                </Typography>
                <Typography>
                  The bottom horizontal bar indicates how you rated yourself for
                  each competency.
                </Typography>
              </div>
              {/* <div id="simp" style={{width:"5"}}> */}

              <table style={{ width: '50%' }} id="sampleone">
                <tr>
                  <th colSpan={3}>
                    <Divider className={classes.divider} />
                  </th>
                </tr>
                {Object.entries(splitAllsummery).map((split) => {
                  return (
                    <>
                      <tr>
                        <th style={{ 'text-align': 'right' }}>{split[0]}</th>
                        <th></th>
                      </tr>
                      {split[1].data.map((summ) => {
                        let widthSiz = 400
                        let newSize = (summ.average / summarymax) * widthSiz

                        return (
                          <tr
                            style={{
                              position: 'relative',
                            }}>
                            <td style={{ 'text-align': 'right', width: '40%' }}>
                              {summ.zCategoryN}
                            </td>

                            <td
                              // className="dividerclasd"
                              style={{
                                width: widthSiz,
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                              }}>
                              {summ.average >= 0 ? (
                                <>
                                  <BarGraph
                                    value={summ.average}
                                    color={summ.color}
                                    chartWidth={widthSiz}
                                    // color={rgbToHex(summ?.color || '255,255,255')}
                                    precent={newSize || widthSiz}
                                  />
                                  {Array.from(Array(summarymax + 1).keys()).map(
                                    (c) => {
                                      return (
                                        <span
                                          aria-current="stedsadp"
                                          className={`splitspan_${c}`}></span>
                                      )
                                    }
                                  )}
                                </>
                              ) : (
                                <p
                                  style={{
                                    'font-size': '8px',
                                    'font-weight': '700',
                                  }}>
                                  Average (excl. Self) 1 Less than 3
                                  respondents, rating included in Average'
                                </p>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                      <tr>
                        <th colSpan={3}>
                          <Divider className={classes.divider} />
                        </th>
                      </tr>
                    </>
                  )
                })}
              </table>
              {/* </div> */}
              <br />
              <Typography variant="h4">
                Summary Results – By Respondent Category
              </Typography>
              <Typography>
                In this section, you will find the average ratings at the
                Competency and Supporting Competency levels for all respondent
                categories included in your survey. This section shows insights
                into the Respondent Category view allowing you to compare your
                own Self ratings to those average ratings provided by the
                various respondents (e.g. Direct Report, Manager, Peer, etc.).
                Use this section of your report to see differences in your own
                ratings and those from others.
              </Typography>
              {Object.values(splitcompetencyResult).map((summ, index) => {
                const [, second = ''] = summ.name.split(':') || []
                // console.log(summ)
                return (
                  <div style={{ padding: '0 20px' }}>
                    <hr style={{ marginBottom: '0' }} />

                    <p style={{ marginBottom: '0' }}> {summ.name}</p>
                    {/* <hr style={{ marginTop: '0' }} /> */}
                    <table
                      style={{
                        width: '100%',
                        background: !second ? 'gainsboro' : '#fffff',
                        padding: '0 10rem',
                      }}>
                      {/* <tr>
                      <td colSpan={3}></td>
                      <td style={{ textAlign: 'center' }}>Lowest Rating</td>
                      <td style={{ textAlign: 'center' }}>Highest Rating</td>
                    </tr> */}
                      {summ.data.map((values) => {
                        let widthSiz = 400
                        let newSize = (values.average / commax) * widthSiz
                        return (
                          <tr>
                            <td>{values.zCategoryNm}</td>
                            <td>{values.ratingCount}</td>
                            {!(
                              values.ratingCount < 3 &&
                              values.zCategoryNm !== 'Manager' &&
                              values.zCategoryNm !== 'Manager 2' &&
                              values.zCategoryNm !== 'Manager 3' &&
                              values.zCategoryNm !== 'Self'
                            ) ? (
                              <td
                                style={{
                                  width: widthSiz,
                                  display: 'flex',
                                  flexDirection: 'row',
                                  position: 'relative',
                                }}>
                                {' '}
                                <BarGraph
                                  value={values.average || 'N/A'}
                                  color={
                                    colorCodes[values.zCategoryNm] ||
                                    othersColor
                                  }
                                  precent={newSize}
                                  chartWidth={widthSiz}
                                />
                                {Array.from(Array(commax + 1).keys()).map(
                                  (c) => {
                                    return (
                                      <span
                                        aria-current="stedsadp"
                                        className={`compentenct${c}`}></span>
                                    )
                                  }
                                )}
                              </td>
                            ) : (
                              'Not enough responses were provided to show data'
                            )}
                            {/* <td style={{ textAlign: 'center' }}>{values.min}</td> */}
                            {/* <td style={{ textAlign: 'center' }} >{values.max}</td> */}
                          </tr>
                        )
                      })}
                    </table>
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid>
            <Grid>
              <Typography>
                This section provides a convenient and quick view of areas you
                can focus on for career development. Development Tip: Look for
                patterns in the competencies from which the items originate. Are
                the top or bottom items consistent with the overall competency
                ratings? Are they anomalies? The focus should not be on the
                individual items and ratings, but on the overall patterns that
                help you see yourself more accurately and most importantly help
                guide your development efforts and career planning.
              </Typography>
            </Grid>
            <br />
            <Grid>
              <Grid
                container
                // justifyContent="center"
                alignItems="center"
                spacing={3}>
                <Grid
                  onClick={leadershipHandle}
                  data-id="HIDDEN STRENGTHS"
                  item
                  xs={6}
                  md={3}
                  lg={3}>
                  {' '}
                  <Paper
                    style={{
                      background:
                        activeLeadershiptab == 'HIDDEN STRENGTHS'
                          ? '#64B5F6'
                          : '#F6F6F6',
                      lineBreak: 'anywhere',
                      textWrap: 'balance',
                    }}
                    className={classes.paper}>
                    HIDDEN STRENGTHS
                  </Paper>
                </Grid>
                <Grid
                  onClick={leadershipHandle}
                  data-id="KNOWN STRENGTHS"
                  item
                  xs={6}
                  md={3}
                  lg={3}>
                  {' '}
                  <Paper
                    style={{
                      background:
                        activeLeadershiptab == 'KNOWN STRENGTHS'
                          ? '#64B5F6'
                          : '#F6F6F6',
                      lineBreak: 'anywhere',
                      textWrap: 'balance',
                    }}
                    className={classes.paper}>
                    KNOWN STRENGTHS
                  </Paper>
                </Grid>
              </Grid>
              <Grid
                container
                // justifyContent="center"
                alignItems="center"
                spacing={3}>
                <Grid
                  onClick={leadershipHandle}
                  data-id="BLIND SPOTS"
                  item
                  xs={6}
                  md={3}
                  lg={3}>
                  {' '}
                  <Paper
                    style={{
                      background:
                        activeLeadershiptab == 'BLIND SPOTS'
                          ? '#64B5F6'
                          : '#F6F6F6',
                      lineBreak: 'anywhere',
                      textWrap: 'balance',
                    }}
                    className={classes.paper}>
                    BLIND SPOTS
                  </Paper>
                </Grid>
                <Grid
                  onClick={leadershipHandle}
                  data-id="KNOWN AREAS FOR DEVELOPMENT"
                  item
                  xs={6}
                  md={3}
                  lg={3}>
                  {' '}
                  <Paper
                    style={{
                      background:
                        activeLeadershiptab == 'KNOWN AREAS FOR DEVELOPMENT'
                          ? '#64B5F6'
                          : '#F6F6F6',
                      lineBreak: 'anywhere',
                      textWrap: 'balance',
                    }}
                    className={classes.paper}>
                    KNOWN AREAS FOR DEVELOPMENT
                  </Paper>
                </Grid>
              </Grid>

              <LeadershipComponent name={activeLeadershiptab} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid>
            <Grid>
              <Typography variant="h4">
                {' '}
                Detail Item Results By Respondent Category
              </Typography>
              <br />
              <Typography>
                The Detailed Item section provides you with the complete set of
                individual items for all the competencies rated. Results are
                organized by competency and show the scores/averages for all the
                respondent categories. While the various summary sections of the
                report provide a high-level view to help you identify patterns,
                this section of the report is useful in reviewing the detailed
                ratings for individual items (behaviors) as rated by the various
                respondent categories..
              </Typography>
              <br />
              <br />

              <Divider />

              {console.log('values of all', allDataValues)}
              {allDataValues.map((spli) => {
                return (
                  <div>
                    <br />
                    {/* <div style={{ fontWeight: 'bold' }}> */}
                    <p>
                      <h4>{spli.strCompNm + ':' + ' ' + spli.strCapNm}</h4>
                    </p>
                    {/* </div> */}
                    {Object.entries(spli.inderClone).map((da, index) => {
                      cno++
                      return (
                        <div>
                          <div>
                            {cno}. {da[0]}
                          </div>

                          <table style={{ padding: '0 4rem' }}>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Lowest Rating</td>
                              <td>Highest Rating</td>
                            </tr>
                            {da[1].map((gr, i) => {
                              let widthSiz = 400
                              let newSize = (gr.average / splitmax) * widthSiz
                              return (
                                <tr>
                                  <td
                                    style={{
                                      'text-align': 'right',
                                    }}>
                                    {gr.itemNorm}
                                  </td>
                                  <td
                                    style={{
                                      width: '3rem',
                                      'text-align': 'center',
                                      whiteSpace: 'nowrap',
                                      paddingLeft: '3rem',
                                    }}>
                                    {gr.ratingCount}
                                  </td>
                                  {!(
                                    gr.ratingCount < 3 &&
                                    gr.itemNorm !== 'Manager' &&
                                    gr.itemNorm !== 'Manager 2' &&
                                    gr.itemNorm !== 'Manager 3' &&
                                    gr.itemNorm !== 'Self'
                                  ) ? (
                                    <>
                                      <td
                                        style={{
                                          width: widthSiz,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          position: 'relative',
                                        }}>
                                        <BarGraph
                                          value={gr.average || 'N/A'}
                                          color={gr?.color}
                                          precent={newSize}
                                          chartWidth={widthSiz}
                                        />
                                        {Array.from(
                                          Array(sclaesize).keys()
                                        ).map((c) => {
                                          return (
                                            <span
                                              aria-current="stedsadp"
                                              className={`detailitem_${c}`}></span>
                                          )
                                        })}
                                      </td>
                                      <td style={{ padding: '0 4rem' }}>
                                        {gr.min}
                                      </td>
                                      <td style={{ padding: '0 4rem' }}>
                                        {gr.max}
                                      </td>
                                    </>
                                  ) : (
                                    'Not enough responses were provided to show data'
                                  )}
                                </tr>
                              )
                            })}
                          </table>
                          <Divider />

                          <br />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
              {allDataValuescus.map((spli) => {
                return (
                  <div>
                    <br />
                    {/* <div style={{ fontWeight: 'bold' }}> */}
                    <p>
                      <h4>{spli.strCompNm + ':' + ' ' + spli.strCapNm}</h4>
                    </p>
                    {/* </div> */}
                    {Object.entries(spli.inderClone).map((da, index) => {
                      cno++
                      return (
                        <div>
                          <div>
                            {cno}. {da[0]}
                          </div>

                          <table style={{ padding: '0 4rem' }}>
                            <tr>
                              <td colSpan={3}></td>
                              <td>Lowest Rating</td>
                              <td>Highest Rating</td>
                            </tr>
                            {da[1].map((gr, i) => {
                              let widthSiz = 400
                              let newSize = (gr.average / splitmax) * widthSiz
                              return (
                                <tr>
                                  <td
                                    style={{
                                      'text-align': 'right',
                                    }}>
                                    {gr.itemNorm}
                                  </td>
                                  <td
                                    style={{
                                      width: '3rem',
                                      'text-align': 'center',
                                      //  whiteSpace: 'nowrap',
                                      //  paddingLeft:'3rem'
                                    }}>
                                    {gr.ratingCount}
                                  </td>
                                  {!(
                                    gr.ratingCount < 3 &&
                                    gr.itemNorm !== 'Manager' &&
                                    gr.itemNorm !== 'Manager 2' &&
                                    gr.itemNorm !== 'Manager 3' &&
                                    gr.itemNorm !== 'Self'
                                  ) ? (
                                    <>
                                      <td
                                        style={{
                                          width: widthSiz,
                                          display: 'flex',
                                          flexDirection: 'row',
                                          position: 'relative',
                                        }}>
                                        <BarGraph
                                          value={gr.average || 'N/A'}
                                          color={gr?.color}
                                          precent={newSize}
                                          chartWidth={widthSiz}
                                        />
                                        {Array.from(
                                          Array(sclaesize).keys()
                                        ).map((c) => {
                                          return (
                                            <span
                                              aria-current="stedsadp"
                                              className={`detailitem_${c}`}></span>
                                          )
                                        })}
                                      </td>
                                      <td style={{ padding: '0 4rem' }}>
                                        {gr.min}
                                      </td>
                                      <td style={{ padding: '0 4rem' }}>
                                        {gr.max}
                                      </td>{' '}
                                    </>
                                  ) : (
                                    'Not enough responses were provided to show data'
                                  )}
                                </tr>
                              )
                            })}
                          </table>
                          <Divider />

                          <br />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div>
            <Grid>
              <Grid>
                <Typography variant="h4">Open-Ended Responses</Typography>
                <Typography>
                  The Open-Ended Responses are the written responses to open
                  questions (e.g. that do not utilize a scale rating). These
                  open-ended questions were selected / created by the project
                  administrator and were presented to all respondents. Answering
                  the open-ended questions is not mandatory – you may notice the
                  number of responses is less than the total number of
                  respondents. Answers provided by yourself and your manager are
                  labeled, while all other answers are randomized to protect
                  anonymity.
                </Typography>
                <br />
                {addOpenEndedData
                  .map((val) => {
                    let openEndothers = []
                    let openEnd = []

                    val.addOpenEndedDataSub.forEach((f) => {
                      if (['Self', 'Manager'].includes(f.zRelationShip)) {
                        openEnd.push(f)
                      } else {
                        openEndothers.push(f)
                      }
                    })

                    return { ...val, openEnd, openEndothers }
                  })
                  .map((addopen, index) => {
                    return (
                      <Grid>
                        <Divider />

                        <Typography variant="h6">
                          {index + 1}. {addopen.zQuestionText}
                        </Typography>
                        <br />
                        {addopen.openEnd.map((sub) => {
                          return (
                            <Grid style={{ padding: '0 40px' }}>
                              <bold>
                                <Typography variant="h6">
                                  {sub.zRelationShip}:
                                </Typography>
                              </bold>
                              <Typography>{sub.zAns}</Typography>
                              <br />
                            </Grid>
                          )
                        })}
                        <Grid style={{ padding: '0 40px' }}>
                          <Typography variant="h6">Others:</Typography>
                          {addopen.openEndothers.map((sub) => {
                            return (
                              <>
                                <Typography>{sub.zAns}</Typography>
                                <br />
                              </>
                            )
                          })}
                          <br />
                        </Grid>
                      </Grid>
                    )
                  })}
              </Grid>
            </Grid>
          </div>
        </TabPanel>
      </div>
    </>
  )
}

export default Threesixtyreport

const BarGraph = (props) => {
  const { value = 30, color = 'fffff', precent = 30, chartWidth = 300 } = props
  return (
    <svg class="chart" style={{ width: chartWidth, zIndex: 9999 }} height="17">
      <g transform="translate(0,0)">
        <rect style={{ fill: color }} width={precent} height="17"></rect>
        <text
          style={{
            fill: 'white',
            font: '15px sans-serif',
            fontWeight: '800',
            'text-anchor': 'end',
          }}
          x="47"
          y="9.5"
          dy=".35em">
          {parseFloat(value)}
        </text>
      </g>
    </svg>
  )
}
