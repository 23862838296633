import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ThinkWiseItems from '../item_selection/ThinkWiseItems'
import CustomItems from './CustomItems'
import SelectedItems from '../item_selection/SelectedItems'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import CustomButton from '../../../../common/CustomButton'
import DeleteIcon from '@material-ui/icons/Delete'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import EditIcon from '@material-ui/icons/Edit'
import TableHead from '@material-ui/core/TableHead'
import CustomTableHeader from '../../../../common/CustomTableHeader'
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'
import ErrorIcon from '@material-ui/icons/Error'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'
import {
  GET_BYPASSROSTER_DETAILS,
  GET_USER_RELATIONSHIP_LIST,
  RELATIONSHIP_LIST_URL,
  ROSTER_AUDIT,
  ROSTER_SUBMIT,
  ROSTER_WARNING,
  SAVE_PARTICIPANT_URL,
} from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import { useParams } from 'react-router'
import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../../../../common/CustomFunction'
import { CustomMessage } from '../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import XLX from '../../../../../assets/images/Template for Roster Upload.xlsx'
import RosterUpload from '../../../../../assets/images/RosterUpload.pdf'
import CustomLoader from '../../../../common/CustomLoader'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
    padding: '0px 20px',
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  uploadfilebox: {
    display: 'flex',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
    marginLeft: '10px',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  tblHead: {
    fontFamily: 'Nunito-SemiBold !important',
  },
  headChooseBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
    marginLeft: '10px',
  },
  hideGrid: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
}))
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

export default function Roster(props) {
  const classes = useStyles()
  let { id = '' } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { id: paramsId } = useParams()
  console.log(paramsId, 'paramsd')
  const { checnkRoster, setBypassRosterDetails, byPassRosterDetails } = props
  const [activeTab, setActiveTab] = useState(0)
  const [uploadFile, setUploadFile] = useState({})
  const [raterList, setRaterList] = useState([])
  const [filename, setFilename] = useState('')
  const [previousProjectLocal, setPreviousProjectLocal] = useState(null)
  const [loading, setLoading] = useState(false)

  // const [byPassRosterDetails, setBypassRosterDetails] = useState([])
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const chooseFileHandler = (e) => {
    setUploadFile(e.target.files[0])
    console.log(e.target.files[0]?.name)
    setFilename(e.target.files[0]?.name)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }
  //state
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    rater: '',
  }
  const accordianInitial = {
    participantName: '',
  }
  const [accor, setAccor] = useState([])
  const [data, setDataValue] = useState([initialValues])
  const [addFlag, setFlag] = useState(false)
  const [isErrorAudit, setisErrorAudit] = useState(false)
  const [uploadFlag, setUploadFlag] = useState(false)
  const [rosterResponse, setRosterResponse] = useState({})
  const [relationshipList, setRelationshipList] = useState([])
  console.log(relationshipList, 'relatiopn')
  const addParticipant = () => {
    setAccor((prevItems) => [
      ...prevItems,
      {
        participantName: '',
      },
    ])
    setFlag(true)
    setUploadFlag(false)
  }
  const addRater = () => {
    setDataValue((prevItems) => [
      ...prevItems,
      {
        firstName: '',
        lastName: '',
        email: '',
        rater: '',
      },
    ])

    setFlag(true)
    setUploadFlag(false)
  }

  const uploadRosterFun = async () => {
    setLoading(true)
    if (!uploadFile?.name) {
      CustomMessage('Please choose file', 'error', enqueueSnackbar)
      return null
    }
    let formData = new FormData()
    formData.append('file', uploadFile)
    setFlag(false)
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: ROSTER_AUDIT,
      params: {
        ProjectId: id,
        OrganizationId: getOrganizationId(),
        UserID: getUserId(),
      },
      data: formData,
    }

    try {
      const response = await API(config)
      console.log('response', response.status)
      if (response.status === 200) {
        setRosterResponse(response.data)

        setRelationshipList({})
        setUploadFlag(true)
        setisErrorAudit(false)
        CustomMessage(
          'Roster has been audited successfully',
          'success',
          enqueueSnackbar
        )
      }
      setLoading(false)
    } catch (error) {
      setUploadFlag(true)
      setRelationshipList({})
      setisErrorAudit(true)
      CustomMessage(
        'Error in uploaded file. Please fix the issue and re-audit the roster.',
        'error',
        enqueueSnackbar
      )

      setRosterResponse(error.response.data)
    }
  }

  const sumbitRoster = async () => {
    if (!uploadFlag) {
      CustomMessage('Please audit your file', 'error', enqueueSnackbar)
      return
    }
    if (isErrorAudit) {
      CustomMessage(
        'Your audit contain error please review the audit',
        'error',
        enqueueSnackbar
      )
      return null
    }
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: ROSTER_SUBMIT,
      data: {
        userID: getUserId(),
        sessionID: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        event: 'string',
        projectID: id,
        luid: getUserId(),
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      setUploadFlag(false)
      const { RelationshipList } = response.data
      const userRelationShipList = {}
      RelationshipList.map((rec) => {
        if (userRelationShipList && userRelationShipList[rec.zParticipantID]) {
          userRelationShipList[rec.zParticipantID].push(rec)
        } else {
          userRelationShipList[rec.zParticipantID] = [rec]
        }
      })
      setRelationshipList(userRelationShipList)
      props.parentCallback(userRelationShipList)
    }
  }
  // let signupSchema

  //Schema
  const validationSchema = Yup.object({
    firstName_1: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required'),
    rater: Yup.string()
      .trim()
      .required('Rater relationship is required'),
  })
  const [logsuccess, setLog] = useState(false)
  const handleLogin = async (values) => {
    setLoading(true)
    console.log('rousernew')
    setLog(true)
    const { firstName = '', lastName = '', email = '', rater = '' } = values
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_PARTICIPANT_URL,
      data:
        data.length > 1
          ? {
              participantFirstName: '',
              participantLastName: '',
              articipantEmail: '',
              raterFirstName: firstName,
              raterLastName: lastName,
              raterEmail: email,
              relationship: rater,
              projectID: parseInt(id),
            }
          : {
              participantFirstName: firstName,
              participantLastName: lastName,
              articipantEmail: email,
              raterFirstName: '',
              raterLastName: '',
              raterEmail: '',
              relationship: rater,
              projectID: parseInt(id),
            },
    }
    try {
      const response = await API(config)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    // validationSchema: signupSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const { firstName = '', lastName = '', email = '', rater = '' } = values

  //Table Columns
  const tableColumns = [
    { id: 'fname', title: 'First Name', sort: true },
    { id: 'lname', title: 'Last Name', sort: true },
    { id: 'email', title: 'Email', sort: true },
    { id: 'rater', title: 'Rater Relationship', sort: true },
  ]
  const tableHeadProps = { tableColumns }
  // Get Participant List
  const getParticipantList = async () => {
    setLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_USER_RELATIONSHIP_LIST,
      params: {
        ProjectID: id,
      },
    }
    try {
      const response = await API(config)
      const { status, data } = response.data
      if (status == 'Success') {
        const userRelationShipList = {}
        data.map((rec) => {
          if (
            userRelationShipList &&
            userRelationShipList[rec.zParticipantID]
          ) {
            userRelationShipList[rec.zParticipantID].push(rec)
          } else {
            userRelationShipList[rec.zParticipantID] = [rec]
          }
        })
        setRelationshipList(userRelationShipList)
        props.parentCallback(userRelationShipList)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  //rater list
  const getRaterRelation = async () => {
    setLoading(true)
    const ratconfig = {
      method: 'GET',
      url: RELATIONSHIP_LIST_URL,
      headers: authHeader(),
      params: {
        UserID: getUserId(),
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      if (status == 'Success') {
        setRaterList(data)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }
  const handleWarning = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: ROSTER_WARNING,
      params: {
        ProjectId: paramsId,
      },
    }
    const response = await API(config)
    var link = document.createElement('a')

    link.href =
      'data:application/octet-stream;base64,' +
      response.data.data[0].zAuditReport
    link.download = 'AuditReport.pdf'
    link.click()
  }
  const getBypassRosterDetails = async () => {
    setLoading(true)
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_BYPASSROSTER_DETAILS,
      params: {
        ProjectID: paramsId,
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        setBypassRosterDetails(data)
      }
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getParticipantList()
    getRaterRelation()
    setPreviousProjectLocal(localStorage.getItem('PREVIOUS_PROJECT'))
    if (previousProjectLocal) {
      console.log('string')
      getBypassRosterDetails()
    }
  }, [previousProjectLocal])

  // const toggleChange = (e) => {
  //   console.log(e.target.checked, '123456789')
  //   setBypassRosterDetails([
  //     {
  //       zByPassRoster: e.target.checked,
  //       zAdminApproval:  false,
  //     },
  //   ])
  // }

  // const checkboxOnChange = (e) => {
  //   setBypassRosterDetails([
  //     { ...byPassRosterDetails[0], zAdminApproval: e.target.checked },
  //   ])
  // }
  const [togleOpan, settogleOpan] = useState(false)
  const handleChangeRoaster = (e, type) => {
    settogleOpan(!togleOpan)
    console.log(e, 'eeeee')
    if (type === 'switch') {
      if (e.target.checked) {
        setBypassRosterDetails([
          { ...byPassRosterDetails[0], zByPassRoster: e.target.checked },
        ])
      } else {
        setBypassRosterDetails([
          {
            zByPassRoster: e.target.checked,
            zAdminApproval: e.target.checked,
          },
        ])
      }
    } else {
      setBypassRosterDetails([
        { ...byPassRosterDetails[0], zAdminApproval: e.target.checked },
      ])
    }
  }

  console.log(byPassRosterDetails, ' byPassRosterDetails ')

  return (
    <Grid>
      <div>
        <CustomLoader loading={loading ? true : false} />
        {addFlag ? (
          <Paper className={classes.paper}>
            {/* Table tools */}
            <Toolbar className={classes.root}>
              <Typography
                className={classes.tblHead}
                variant="h6"
                id="tableTitle"
                component="div">
                Roster
              </Typography>
            </Toolbar>
            <Divider />

            <Grid
              container
              spacing={3}
              direction="row"
              style={{ marginTop: '2px', marginLeft: '30px' }}>
              <Grid item xs={uploadFlag ? '3' : '5'}>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.headBtn}
                  startIcon={<PublishIcon />}
                  onClick={sumbitRoster}>
                  Upload Roster
                </Button>
              </Grid>
              {uploadFlag ? (
                <Grid item xs={4}>
                  <center>
                    <p>
                      <b style={{ marginRight: '1rem' }}>Audit: </b>
                      Errors:
                      <span style={{ color: '#D32F2F', marginRight: '1rem' }}>
                        1
                      </span>
                      Warning:<span style={{ color: '#FFCC00' }}>1</span>
                    </p>
                  </center>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={uploadFlag ? '3' : '5'}>
                <Button
                  variant="contained"
                  color="default"
                  className={classes.headBtn}
                  startIcon={<AddIcon />}
                  onClick={(e) => {
                    e.stopPropagation()
                    addParticipant()
                  }}
                  style={{ float: 'right' }}>
                  Add Participant
                </Button>
              </Grid>
            </Grid>
            {addFlag ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                style={{ marginTop: '2rem' }}>
                {/* <div> */}
                <Grid item xs={10}>
                  {accor.map((element, key) => (
                    <Accordion
                      key={`acc_${key}`}
                      // expanded={true}
                      className={classes.acc}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: '#fff' }} />
                        }
                        aria-controls={`item_${key}-content`}
                        id={`item_${key}-header`}
                        className={classes.accHead}>
                        <Grid item xs={10}>
                          <Typography className={classes.heading}>
                            First Name Last Name
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={2}>
                        <Button
                          className={classes.toggleDescBtn}
                          startIcon={
                            <AddIcon className={classes.toggleDescIcon} />
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            addRater()
                          }}>
                          Add Rater
                        </Button>
                      </Grid> */}
                      </AccordionSummary>
                      <AccordionDetails className={classes.accDetail}>
                        <Grid
                          container
                          spacing={3}
                          direction="row"
                          justifyContent="center"
                          style={{ marginTop: '2px' }}>
                          <Grid item xs={2}>
                            First Name
                          </Grid>
                          <Grid item xs={2}>
                            Last Name
                          </Grid>
                          <Grid item xs={2}>
                            Email
                          </Grid>
                          <Grid item xs={2}>
                            Rater Relationship
                          </Grid>
                          <Grid item xs={2}></Grid>
                          <Grid item xs={2}></Grid>
                        </Grid>
                        {data &&
                          data.map((record, index) => {
                            console.log(
                              'index',
                              index,
                              errors[`firstName_${index}`]
                            )
                            return (
                              <Grid
                                container
                                spacing={3}
                                // direction="row"
                                // justifyContent="center"
                                style={{ marginTop: '2px' }}>
                                <Grid item xs={2} className={classes.grid}>
                                  <TextField
                                    id="firstName"
                                    label="First Name:"
                                    value={firstName}
                                    onChange={handleChange}
                                    fullWidth
                                    name="firstName"
                                    error={
                                      errors.firstName && touched.firstName
                                    }
                                    helperText={
                                      errors.firstName && touched.firstName
                                        ? errors.firstName
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2} className={classes.grid}>
                                  <TextField
                                    id="lastName"
                                    label="Last Name:"
                                    value={lastName}
                                    onChange={handleChange}
                                    fullWidth
                                    name="lastName"
                                    error={errors.lastName && touched.lastName}
                                    helperText={
                                      errors.lastName && touched.lastName
                                        ? errors.lastName
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={2} className={classes.grid}>
                                  <TextField
                                    id="email"
                                    label="Email:"
                                    value={email}
                                    onChange={handleChange}
                                    fullWidth
                                    name="email"
                                    error={errors.email && touched.email}
                                    helperText={
                                      errors.email && touched.email
                                        ? errors.email
                                        : ''
                                    }
                                  />
                                </Grid>
                                <Grid item xs={3} className={classes.grid}>
                                  <FormControl
                                    className={classes.formControl}
                                    error={errors.scale && touched.scale}>
                                    <InputLabel>Rater Relationship</InputLabel>
                                    <Select
                                      // labelId="scale-label"
                                      name="rater"
                                      label="SelectRater Relationship"
                                      // defaultValue={'Self'}
                                      value={rater}
                                      onChange={handleChange}
                                      fullWidth>
                                      {raterList &&
                                        raterList.map((ratrecord, index) => {
                                          const {
                                            zName = '',
                                            zRelationshipID = '',
                                          } = ratrecord
                                          return (
                                            <MenuItem value="Self">
                                              {zName}
                                            </MenuItem>
                                          )
                                        })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                  <CustomButton
                                    colour="primaryButton"
                                    type="submit"
                                    onClick={handleSubmit}>
                                    Save
                                  </CustomButton>
                                  {logsuccess ? (
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<EditIcon />}></Button>
                                  ) : (
                                    ''
                                  )}
                                  <Button
                                    className={classes.addCatBtn}
                                    style={{ float: 'right' }}
                                    startIcon={<DeleteIcon />}></Button>
                                </Grid>
                              </Grid>
                            )
                          })}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
                {/* </div> */}
              </Grid>
            ) : (
              // <div>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                style={{ marginTop: '2px' }}>
                <Grid item xs={10}>
                  {[0, 1].map((element, key) => (
                    <Accordion
                      key={`acc_${key}`}
                      // expanded={false
                      className={classes.acc}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon style={{ color: '#fff' }} />
                        }
                        aria-controls={`item_${key}-content`}
                        id={`item_${key}-header`}
                        className={classes.accHead}>
                        <Grid item xs={10}>
                          <Typography className={classes.heading}>
                            {`Participant Name ${key + 1}`}
                            {key + 1 == 2 ? (
                              <IconButton>
                                <ErrorIcon style={{ color: '#FFCC00' }} />
                              </IconButton>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </Grid>
                        {/* <Grid item xs={4}>
                        <Button
                          className={classes.toggleDescBtn}
                          startIcon={
                            <AddIcon className={classes.toggleDescIcon} />
                          }
                          style={{ marginLeft: '8rem' }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          Add Rater
                        </Button>
                      </Grid> */}
                      </AccordionSummary>
                      <AccordionDetails className={classes.accDetail}>
                        <Grid item xs={12}>
                          <TableContainer
                            component={Paper}
                            className={classes.tablecontainer}>
                            <Table stickyHeader size="small">
                              {/* Table Header */}
                              <CustomTableHeader {...tableHeadProps} />
                              {/* Table Header */}

                              {/* Table Body */}
                              <TableBody>
                                <TableRow>
                                  <TableCell align="left">
                                    Rater FirstName
                                  </TableCell>
                                  <TableCell align="left">
                                    Rater LastName
                                  </TableCell>
                                  <TableCell align="left">
                                    rater@gmail.com
                                  </TableCell>
                                  <TableCell align="left">
                                    Rater Relationship
                                  </TableCell>
                                  <TableCell align="left">
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<EditIcon />}></Button>
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<DeleteIcon />}></Button>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell align="left">smith</TableCell>
                                  <TableCell align="left">James</TableCell>
                                  <TableCell align="left">
                                    sj@gmail.com
                                  </TableCell>
                                  <TableCell align="left">
                                    Direct Report
                                  </TableCell>
                                  <TableCell align="left">
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<EditIcon />}></Button>
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<DeleteIcon />}></Button>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              </Grid>
            )}
          </Paper>
        ) : (
          <Paper>
            {/* Table tools */}
            <Toolbar className={classes.root}>
              <Typography
                className={classes.tblHead}
                variant="h6"
                id="tableTitle"
                component="div">
                Roster
              </Typography>
            </Toolbar>
            <Divider />
            {previousProjectLocal ? (
              <div style={{ display: 'flex' }}>
                <Grid
                  xs={6}
                  md={8}
                  disabled={byPassRosterDetails[0]?.zByPassRoster}
                  className={
                    byPassRosterDetails[0]?.zByPassRoster
                      ? classes.hideGrid
                      : ''
                  }
                  style={{
                    display: ' flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <h3
                    style={{
                      marginTop: '2rem',
                      marginLeft: '40px',
                      fontFamily: 'Roboto-Regular !important',
                      textAlign: 'center !important',
                    }}>
                    Option 1: Upload Roster
                  </h3>
                  <Typography
                    className={classes.tblHead}
                    variant="body2"
                    gutterBottom
                    style={{
                      // marginTop: '1rem',
                      marginLeft: '40px',
                      fontFamily: 'Roboto-Regular !important',
                    }}>
                    To add participants and raters please upload your completed
                    roster file or add individually
                    <br />
                    <a target="_blank" href={RosterUpload}>
                      Roster Instructions
                    </a>
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="center"
                    style={{
                      marginTop: '2px',
                      marginLeft: '40px',
                    }}>
                    <Grid item style={{ padding: 0 }}>
                      <List
                        component="nav"
                        aria-label="secondary mailbox folder">
                        <ListItem style={{ padding: 0 }}>
                          <Button
                            style={{ marginLeft: '0px' }}
                            variant="contained"
                            href={XLX}
                            color="default"
                            className={classes.headBtn}
                            startIcon={<GetAppIcon />}>
                            <a
                              style={{
                                'text-decoration': 'none',
                                whiteSpace: 'nowrap',
                                marginLeft: '0px !important',
                              }}>
                              Download Roster Template
                            </a>
                          </Button>
                        </ListItem>
                        {/* <ListItem>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<GetAppIcon />}>
                    Download Roster Template
                  </Button>
                </ListItem> */}
                      </List>
                      <Grid item>
                        <List
                          component="nav"
                          aria-label="secondary mailbox folder">
                          <ListItem>
                            Step 1:{' '}
                            <Box className={classes.uploadfilebox}>
                              <Button
                                variant="contained"
                                color="default"
                                component="label"
                                className={classes.headChooseBtn}

                                // startIcon={<PublishIcon />}
                              >
                                {' '}
                                Choose File
                                <input
                                  name="upload-photo"
                                  type="file"
                                  hidden
                                  onChange={(e) => {
                                    chooseFileHandler(e, 'document')
                                  }}
                                />
                              </Button>
                            </Box>
                            <Typography style={{ marginLeft: '20px' }}>
                              {' '}
                              {filename}
                            </Typography>
                            {/* <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<AddIcon />}
                    onClick={(e) => {
                      e.stopPropagation()
                      addParticipant()
                    }}>
                    Add Participant
                  </Button> */}
                          </ListItem>
                          <ListItem>
                            Step 2:{' '}
                            <Button
                              variant="contained"
                              color="default"
                              className={classes.headBtn}
                              // startIcon={<PublishIcon />}
                              onClick={uploadRosterFun}
                              disabled={filename ? false : true}>
                              Audit Roster
                            </Button>
                            {uploadFlag ? (
                              <>
                                <Grid item sx={{ padding: '0px 0px 0px 2rem' }}>
                                  {rosterResponse.RecordsRead && (
                                    <p style={{ paddingLeft: '15px' }}>
                                      {rosterResponse.RecordsRead}
                                    </p>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    paddingLeft: '1rem',
                                    whiteSpace: 'nowrap',
                                  }}>
                                  {/* <center> */}
                                  <p>
                                    <b style={{ marginRight: '1rem' }}>
                                      Audit:{' '}
                                    </b>
                                    Errors:
                                    <span
                                      style={{
                                        color: '#D32F2F',
                                        marginRight: '1rem',
                                      }}>
                                      {rosterResponse.totalErrorCount}
                                    </span>
                                    Warning:
                                    <span style={{ color: '#FFCC00' }}>
                                      {rosterResponse.totalWarningCount}
                                    </span>
                                  </p>
                                  {rosterResponse.totalWarningCount && (
                                    <Button
                                      variant="contained"
                                      color="default"
                                      className={classes.headBtn}
                                      onClick={handleWarning}
                                      startIcon={<GetAppIcon />}>
                                      Review Roster Audit
                                    </Button>
                                  )}
                                  {/* {rosterResponse.totalWarningCount &&<p><a href='#' onClick={handleWarning}>Download Report</a></p>} */}
                                  {/* </center> */}
                                </Grid>
                              </>
                            ) : (
                              ''
                            )}
                          </ListItem>
                          <ListItem>
                            Step 3:
                            <Button
                              variant="contained"
                              color="default"
                              className={classes.headBtn}
                              startIcon={<PublishIcon />}
                              onClick={sumbitRoster}
                              disabled={!uploadFlag}>
                              Upload Roster
                            </Button>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                    {/* <Grid item xs={5}> */}
                    {/* <Grid container spacing={2}> */}

                    {/* Upload Roster Section */}

                    <Grid item xs={10}>
                      {Object.keys(relationshipList).map((element, key) => {
                        const {
                          zParticipantFullName,
                          zParticipantEmail,
                        } = relationshipList[element][0]
                        return (
                          <Accordion
                            key={`acc_${key}`}
                            // expanded={false
                            className={classes.acc}>
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon style={{ color: '#fff' }} />
                              }
                              aria-controls={`item_${key}-content`}
                              id={`item_${key}-header`}
                              className={classes.accHead}>
                              <Grid item xs={10}>
                                <Typography className={classes.heading}>
                                  {zParticipantFullName}
                                  {/* {key + 1 == 2 ? (
                            <IconButton>
                              <ErrorIcon style={{ color: '#FFCC00' }} />
                            </IconButton>
                          ) : (
                            ''
                          )} */}
                                </Typography>
                              </Grid>
                              {/* <Grid item xs={4}>
                        <Button
                          className={classes.toggleDescBtn}
                          startIcon={
                            <AddIcon className={classes.toggleDescIcon} />
                          }
                          style={{ marginLeft: '8rem' }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          Add Rater
                        </Button>
                      </Grid> */}
                            </AccordionSummary>
                            <AccordionDetails className={classes.accDetail}>
                              <Grid item xs={12}>
                                <TableContainer
                                  component={Paper}
                                  className={classes.tablecontainer}>
                                  <Table stickyHeader size="small">
                                    {/* Table Header */}
                                    <CustomTableHeader {...tableHeadProps} />
                                    {/* Table Header */}

                                    {/* Table Body */}
                                    <TableBody>
                                      {relationshipList[element].map((rec) => (
                                        <TableRow>
                                          <TableCell align="left">
                                            {rec.zRespondantFirstName}
                                          </TableCell>
                                          <TableCell align="left">
                                            {rec.zRespondantLastName}
                                          </TableCell>
                                          <TableCell align="left">
                                            {rec.zRespondantEmail}
                                          </TableCell>
                                          <TableCell align="left">
                                            {rec.zRelationshipName}
                                          </TableCell>
                                          {/*<TableCell align="left">
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<EditIcon />}></Button>
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<DeleteIcon />}></Button>
                                   </TableCell>*/}
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    background: 'darkgrey',
                    width: '2px',
                    height: '20rem',
                    marginTop: '1.5rem',
                    opacity: 0.5,
                  }}></Grid>
                <Grid
                  xs={6}
                  md={6}
                  disabled={Object.keys(relationshipList).length > 0}
                  className={
                    Object.keys(relationshipList).length > 0
                      ? classes.hideGrid
                      : ''
                  }>
                  <h3
                    style={{
                      marginTop: '2rem',
                      marginLeft: '40px',
                      fontFamily: 'Roboto-Regular !important',
                      display: ' flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    Option 2: Bypass Roster
                  </h3>
                  <Typography
                    className={classes.tblHead}
                    variant="body2"
                    gutterBottom
                    style={{
                      marginTop: '1rem',
                      marginLeft: '40px',
                      fontFamily: 'Roboto-Regular !important',
                    }}>
                    Bypass roster upload to allow Participants to enter their
                    roster information.
                    <br />
                    {/* <a target="_blank" href={RosterUpload}>
                  Roster Instructions
                </a> */}
                  </Typography>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justifyContent="center"
                    style={{ marginTop: '2px', marginLeft: '30px' }}>
                    <Grid style={{ marginBottom: '12px' }}>
                      <Typography
                        style={{
                          fontSize: '0.8rem',
                          fontFamily:
                            'Roboto, Helvetica, Arial, sans-serif !important',
                          color: 'rgba(0, 0, 0, 0.54)',
                        }}>
                        Bypass Roster:
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        //  disabled={!byPassRosterDetails[0]?.zByPassRoster}
                        //  className={
                        //   !byPassRosterDetails[0]?.zByPassRoster
                        //     ? classes.hideGrid
                        //     : ''}
                      >
                        <AntSwitch
                          inputProps={{ 'aria-label': 'ant design' }}
                          onChange={(e) => handleChangeRoaster(e, 'switch')}
                          checked={!!byPassRosterDetails[0]?.zByPassRoster}
                        />
                        <Typography
                          className={classes.labelName}
                          style={{
                            color:
                              byPassRosterDetails[0]?.zByPassRoster == true
                                ? '#000'
                                : 'darkgray',
                          }}>
                          {byPassRosterDetails[0]?.zByPassRoster == true
                            ? 'On'
                            : 'Off'}
                        </Typography>
                      </Stack>
                      <Grid
                        disabled={!byPassRosterDetails[0]?.zByPassRoster}
                        className={
                          !byPassRosterDetails[0]?.zByPassRoster
                            ? classes.hideGrid
                            : ''
                        }>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={!!byPassRosterDetails[0]?.zAdminApproval}
                              onChange={(e) =>
                                handleChangeRoaster(e, 'checkbox')
                              }
                              color="primary"
                              disabled={!byPassRosterDetails[0]?.zByPassRoster}
                            />
                          }
                          label=" Require Roster Approval by Project Admin"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <>
                <Typography
                  className={classes.tblHead}
                  variant="body2"
                  gutterBottom
                  style={{
                    marginTop: '2rem',
                    marginLeft: '40px',
                    fontFamily: 'Roboto-Regular !important',
                  }}>
                  To add participants and raters please upload your completed
                  roster file or add individually. <br />
                  <a target="_blank" href={RosterUpload}>
                    Roster Instructions
                  </a>
                </Typography>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justifyContent="center"
                  style={{ marginTop: '2px', marginLeft: '30px' }}>
                  <Grid item xs={4}>
                    <List component="nav" aria-label="secondary mailbox folder">
                      <ListItem>
                        <Button
                          variant="contained"
                          href={XLX}
                          color="default"
                          className={classes.headBtn}
                          startIcon={<GetAppIcon />}>
                          <a style={{ 'text-decoration': 'none' }}>
                            Download Roster Template
                          </a>
                        </Button>
                      </ListItem>
                      {/* <ListItem>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<GetAppIcon />}>
                    Download Roster Template
                  </Button>
                </ListItem> */}
                    </List>
                  </Grid>
                  {/* <Grid item xs={5}> */}
                  {/* <Grid container spacing={2}> */}
                  <Grid item xs={8}>
                    <List component="nav" aria-label="secondary mailbox folder">
                      <ListItem>
                        Step 1:{' '}
                        <Box className={classes.uploadfilebox}>
                          <Button
                            variant="contained"
                            color="default"
                            component="label"
                            className={classes.headChooseBtn}

                            // startIcon={<PublishIcon />}
                          >
                            {' '}
                            Choose File
                            <input
                              name="upload-photo"
                              type="file"
                              hidden
                              onChange={(e) => {
                                chooseFileHandler(e, 'document')
                              }}
                            />
                          </Button>
                        </Box>
                        <Typography style={{ marginLeft: '20px' }}>
                          {' '}
                          {filename}
                        </Typography>
                        {/* <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<AddIcon />}
                    onClick={(e) => {
                      e.stopPropagation()
                      addParticipant()
                    }}>
                    Add Participant
                  </Button> */}
                      </ListItem>
                      <ListItem>
                        Step 2:{' '}
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.headBtn}
                          // startIcon={<PublishIcon />}
                          onClick={uploadRosterFun}
                          disabled={filename ? false : true}>
                          Audit Roster
                        </Button>
                        {uploadFlag ? (
                          <>
                            <Grid item xs={4}>
                              {rosterResponse.RecordsRead && (
                                <p style={{ paddingLeft: '15px' }}>
                                  {rosterResponse.RecordsRead}
                                </p>
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              {/* <center> */}
                              <p>
                                <b style={{ marginRight: '1rem' }}>Audit: </b>
                                Errors:
                                <span
                                  style={{
                                    color: '#D32F2F',
                                    marginRight: '1rem',
                                  }}>
                                  {rosterResponse.totalErrorCount}
                                </span>
                                Warning:
                                <span style={{ color: '#FFCC00' }}>
                                  {rosterResponse.totalWarningCount}
                                </span>
                              </p>
                              {rosterResponse.totalWarningCount && (
                                <Button
                                  variant="contained"
                                  color="default"
                                  className={classes.headBtn}
                                  onClick={handleWarning}
                                  startIcon={<GetAppIcon />}>
                                  Review Roster Audit
                                </Button>
                              )}
                              {/* {rosterResponse.totalWarningCount &&<p><a href='#' onClick={handleWarning}>Download Report</a></p>} */}
                              {/* </center> */}
                            </Grid>
                          </>
                        ) : (
                          ''
                        )}
                      </ListItem>
                      <ListItem>
                        Step 3:
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.headBtn}
                          startIcon={<PublishIcon />}
                          onClick={sumbitRoster}
                          disabled={!uploadFlag}>
                          Upload Roster
                        </Button>
                      </ListItem>
                    </List>
                  </Grid>

                  {/* Upload Roster Section */}

                  <Grid item xs={10}>
                    {Object.keys(relationshipList).map((element, key) => {
                      const {
                        zParticipantFullName,
                        zParticipantEmail,
                      } = relationshipList[element][0]
                      return (
                        <Accordion
                          key={`acc_${key}`}
                          // expanded={false
                          className={classes.acc}>
                          <AccordionSummary
                            expandIcon={
                              <ExpandMoreIcon style={{ color: '#fff' }} />
                            }
                            aria-controls={`item_${key}-content`}
                            id={`item_${key}-header`}
                            className={classes.accHead}>
                            <Grid item xs={10}>
                              <Typography className={classes.heading}>
                                {zParticipantFullName}
                                {/* {key + 1 == 2 ? (
                            <IconButton>
                              <ErrorIcon style={{ color: '#FFCC00' }} />
                            </IconButton>
                          ) : (
                            ''
                          )} */}
                              </Typography>
                            </Grid>
                            {/* <Grid item xs={4}>
                        <Button
                          className={classes.toggleDescBtn}
                          startIcon={
                            <AddIcon className={classes.toggleDescIcon} />
                          }
                          style={{ marginLeft: '8rem' }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          Add Rater
                        </Button>
                      </Grid> */}
                          </AccordionSummary>
                          <AccordionDetails className={classes.accDetail}>
                            <Grid item xs={12}>
                              <TableContainer
                                component={Paper}
                                className={classes.tablecontainer}>
                                <Table stickyHeader size="small">
                                  {/* Table Header */}
                                  <CustomTableHeader {...tableHeadProps} />
                                  {/* Table Header */}

                                  {/* Table Body */}
                                  <TableBody>
                                    {relationshipList[element].map((rec) => (
                                      <TableRow>
                                        <TableCell align="left">
                                          {rec.zRespondantFirstName}
                                        </TableCell>
                                        <TableCell align="left">
                                          {rec.zRespondantLastName}
                                        </TableCell>
                                        <TableCell align="left">
                                          {rec.zRespondantEmail}
                                        </TableCell>
                                        <TableCell align="left">
                                          {rec.zRelationshipName}
                                        </TableCell>
                                        {/*<TableCell align="left">
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<EditIcon />}></Button>
                                    <Button
                                      className={classes.addCatBtn}
                                      startIcon={<DeleteIcon />}></Button>
                                   </TableCell>*/}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        )}
      </div>
    </Grid>
  )
}
