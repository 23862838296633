import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  ADD_TEAM_OPENEDNQUESTION,
  DELETE_TEAM_OPENEND,
  GET_TEAM_OPENEND,
  SAVE_TEAM_OPENED,
} from '../../helpers/constants/ApiRoutes'
import { authHeader, getUserId } from '../common/CustomFunction'
import { API } from '../../helpers/API'
import { useParams } from 'react-router-dom'
import CustomButton from '../common/CustomButton'
import CustomLoader from '../common/CustomLoader'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import {
  TEAMSURVEY_CURRENTSTEP,
  TEAMSURVEY_TEMP_CURRENTSTEP,
} from '../../redux/action/ActionType'
import Swal from 'sweetalert2'
import Tseditopenend from './Tseditopenend'
import { useSnackbar } from 'notistack'
import { CustomMessage } from '../../function/HelperFunction'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontFamily: 'Nunito-SemiBold !important',
  },
  formControl: {
    width: '100%',
  },
  blackcolor: {
    color: 'black',
  },
}))
function Tsopenend(props) {
  const classes = useStyles()
  const [loading, setloading] = useState(false)
  const [openendQuestionlist, setopenendQuestionlist] = useState([])
  const { tsprojectid } = useParams()
  const tempTAbchange = useSelector((state) => state.Teamtempcurrentstepreducer)

  const [openendEditform, setopenendEditform] = useState({
    OpenEndedQuestionID: '',

    QuestionText: '',
  })
  const { enqueueSnackbar } = useSnackbar()

  const [openEndquestion, setopenEndquestion] = useState('')
  const [openendModal, setopenendModal] = useState(false)
  const openendEdittoggle = () => {
    setopenendModal((c) => !c)
  }
  const editOpenend = (e) => {
    const { name, id } = e.currentTarget.dataset
    setopenendEditform({
      OpenEndedQuestionID: id,

      QuestionText: name,
    })
    openendEdittoggle()
  }
  const currentStep = useSelector((state) => state.Teamsurveycurrentstepreducer)
  const dispatch = useDispatch()
  const [openendError, setopenendError] = useState('')
  const questionList = async (id) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_OPENEND,
      params: {
        UserID: getUserId(),
        ProjectID: tsprojectid,
      },
    }
    try {
      const response = await API(config)
      const {
        data,
        status,
        openEndedQuestionsListByUser,
        openEndedQuestionsList,
      } = response.data
      if (status == 'Success') {
        setopenendQuestionlist(openEndedQuestionsListByUser)
        setValues(openEndedQuestionsList.map((val) => val.zOpenEndedQuestionID))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const addQuestion = async () => {
    if (!openEndquestion.trim()) {
      setopenendError('Required')
      return null
    }
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: ADD_TEAM_OPENEDNQUESTION,
      params: {
        UserID: getUserId(),
        ProjectID: tsprojectid,
        QuestionText: openEndquestion,
        strSelections: '',
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        questionList()
        setopenEndquestion('')
      }
    } catch (error) {
      console.error(error)
    }
  }
  const saveopenEndValues = async (values) => {
    console.log(values)
    // if (!values.length) {
    //   CustomMessage(
    //     'Please choose atleast one open end',
    //     'error',
    //     enqueueSnackbar
    //   )

    //   return null
    // }
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: SAVE_TEAM_OPENED,
      params: {
        UserID: getUserId(),
        ProjectID: tsprojectid,

        strSelections: values.join(','),
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        console.log(currentStep, tempTAbchange)
        if (currentStep == tempTAbchange) {
          dispatch({
            type: TEAMSURVEY_CURRENTSTEP,
            payload: tempTAbchange + 1,
          })
          dispatch({
            type: TEAMSURVEY_TEMP_CURRENTSTEP,
            payload: 2,
          })
        } else {
          dispatch({ type: TEAMSURVEY_CURRENTSTEP, payload: tempTAbchange })
          dispatch({
            type: TEAMSURVEY_TEMP_CURRENTSTEP,
            payload: tempTAbchange,
          })
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues: [],
    enableReinitialize: true,

    onSubmit: (values) => saveopenEndValues(values),
  })
  const openEndHandle = (e) => {
    setopenEndquestion(e.target.value)
    setopenendError('')
  }
  useEffect(() => {
    questionList()
  }, [])

  const selectOpenendhandle = (e) => {
    const { id, checked } = e.target
    if (checked) {
      setValues((va) => [...va, parseInt(id)])
    } else {
      setValues((va) => va.filter((val) => val != id))
    }
  }
  const deleteOpenend = async (e) => {
    const id = e.currentTarget.dataset.id
    Swal.fire({
      title: 'Are you want to delete open end?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(id)
      }
    })
    //DELETE_TEAM_OPENEND
  }
  const deleteItem = async (idvalue) => {
    const config = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_TEAM_OPENEND,
      params: {
        OpenEndedQuestionID: idvalue,
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        questionList()
      }
    } catch (error) {
      console.error(error)
    }
  }
  const submitCallBack = () => {
    questionList()

    openendEdittoggle()
  }
  return (
    <div>
      <Paper className={classes.paper}>
        {/* Table tools */}
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div">
            Open Ended Questions
          </Typography>
        </Toolbar>
        <Divider />
        <form noValidate autoComplete="off" id="form_2" onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            direction="row"
            style={{
              marginTop: '2px',
              marginLeft: '30px',
            }}>
            <Grid item xs={8}>
              <p
                style={{
                  fontSize: '14px',
                  fontFamily: 'Roboto-Regular !important',
                  color: 'black',
                }}>
                What open ended questions would you like to use on this team
                performance survey?
              </p>
              <form>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}>
                  <FormGroup>
                    {/* <FormControlLabel
                  className={classes.blackcolor}
                  control={
                    <Checkbox
                      color="primary"
                      name="all"
                      // onClick={handleToggle('*')}
                      // checked={allFlag}
                      // onChange={handleMultiSelectChange}
                    />
                  }
                  label="All"
                /> */}
                    <Grid container spacing={1}>
                      <>
                        <List
                          className={classes.root}
                          style={{ width: '200%' }}>
                          {openendQuestionlist.map((record, index) => {
                            const {
                              compName = '',
                              zCapabilityID = '',
                              zCompetencyID = '',
                              zGoalRelatedFlag = '',
                              zLevelID = '',
                              zOpenEndedQuestionID = '',
                              zOrganizationID = '',
                              zQuestionText = '',
                              zSortOrder = '',
                              zUserID = '',
                            } = record

                            const labelId = `checkbox-list-label-${index}`
                            return (
                              <>
                                {/* <Grid item xs={9}> */}
                                <ListItem
                                  key={index}
                                  role={undefined}
                                  dense
                                  button>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{
                                      display: 'flex',
                                    }}>
                                    <ListItemIcon
                                      style={{
                                        marginBottom: '1rem',
                                      }}>
                                      <Checkbox
                                        edge="start"
                                        tabIndex={-1}
                                        onChange={selectOpenendhandle}
                                        disableRipple
                                        checked={values.includes(
                                          zOpenEndedQuestionID
                                        )}
                                        id={zOpenEndedQuestionID}
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      style={{
                                        marginTop: '1rem',
                                      }}
                                      id={labelId}
                                      className={classes.blackcolor}
                                      primary={zQuestionText}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    {zUserID === 0 ? (
                                      ''
                                    ) : (
                                      <ListItemSecondaryAction>
                                        <IconButton
                                          style={{
                                            marginBottom: '3rem',
                                          }}
                                          edge="end"
                                          data-id={zOpenEndedQuestionID}
                                          aria-label="delete"
                                          onClick={editOpenend}
                                          data-name={zQuestionText}
                                          size="small">
                                          <EditIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                          aria-label="delete"
                                          onClick={deleteOpenend}
                                          data-id={zOpenEndedQuestionID}
                                          size="small"
                                          style={{
                                            marginLeft: '1rem',
                                            marginBottom: '3rem',
                                          }}>
                                          <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    )}
                                  </Grid>
                                </ListItem>
                                {/* </Grid> */}
                              </>
                            )
                          })}
                        </List>
                      </>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={openendError}
                fullWidth
                id="standard-error-helper-text"
                label="Enter new open ended question"
                value={openEndquestion}
                onChange={openEndHandle}
                helperText={openendError}
              />
              <CustomButton
                type="button"
                colour="primaryButton"
                onClick={addQuestion}>
                Add
                {loading && <CustomLoader loading={loading ? true : false} />}
              </CustomButton>
            </Grid>
          </Grid>
        </form>
        <Tseditopenend
          open={openendModal}
          formdata={openendEditform}
          handleReset={openendEdittoggle}
          submitCallBack={submitCallBack}
        />
      </Paper>
    </div>
  )
}

export default Tsopenend
