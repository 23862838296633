import React, { useCallback, useEffect, useState } from 'react'
import { CustomPageLoading } from '../../../../function/HelperFunction'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Box,
  MenuItem,
} from '@material-ui/core'
import Newthinkswiseitem from './item_selection/Newthinkswiseitem'
import NewthinkwisePreview from './item_selection/NewthinkwisePreview'
import NewcustomItems from './item_selection/NewcustomItems'
import {
  ALL_SELECTED_LOAD_ITEMS,
  GET_CAPABILITIES_BY_COMPETENCY_MODAL,
  GET_COMPENTENCY_MODAL_DROPDOWN,
} from '../../../../helpers/constants/ApiRoutes'
import { useParams } from 'react-router-dom'
import {
  authHeader,
  getOrganizationId,
  getUserId,
} from '../../../common/CustomFunction'
import { API } from '../../../../helpers/API'
import { useDispatch, useSelector } from 'react-redux'
import {
  NEW_API_CUSTOMITEM,
  NEW_API_SELECTED_DATA,
  NEW_SELECTED_THINKWISE_ID,
  NEW_SELECT_CUSTOM_ITEMS,
} from '../../../../redux/action/ActionType'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
  THINKWISE_CATEGORY,
  THINKWISE_SUB_CATEGORY,
} from '../../../../redux/action/Typeconstent'
import { getAllsaveItems } from '../../../../redux/action'
import SelectedItems from './item_selection/SelectedItems'
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontFamily: 'Nunito-SemiBold !important',
  },
  formControl: {
    width: '100%',
  },
  tab: {
    fontFamily: 'Roboto-Medium !important',
    '&.MuiTab-root': {
      backgroundColor: '#BDBDBD',
      color: '#fff',
    },
    '&.Mui-selected': {
      backgroundColor: theme.primaryButton,
      color: '#fff',
    },
  },
}))
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)
function NewCompentency(props) {
  const classes = useStyles()
  const { projectInfo } = props
  const { zLevelID, zScaleID, zPreviousProjectID } = projectInfo || {}
  const { id: projectid } = useParams()
  const [loading, setloading] = useState(false)
  const NewthinkwiseReducer = useSelector((state) => state.NewthinkwiseReducer)
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(0)
  const [competencyModal, setCompetencyModal] = useState([])
  const [competencyModalValue, setCompetencyModalValue] = useState('')
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const TabPanel = useCallback((props) => {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }, [])
  useEffect(() => {
    dispatch(getAllsaveItems(projectid))
  }, [])
  const getCompetencyModal = async () => {
    setloading(true)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_COMPENTENCY_MODAL_DROPDOWN,
      params: {
        userId: getUserId(),
        ProjectID: parseInt(projectid),
      },
    }
    try {
      const response = await API(getConfig)
      if (response && response.data && response.data.data) {
        setCompetencyModal(response.data.data)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setloading(false)
    }
  }

  useEffect(() => {
    getCompetencyModal()
  }, [])

  const compentencyIdgetItem = async (value) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CAPABILITIES_BY_COMPETENCY_MODAL,
      params: {
        CompetencyModelID: value,
      },
    }

    try {
      const response = await API(config)
      const { data } = response.data
      let itemData = {}
      let selectedCategory = []
      console.log('in')
      let allSubcategoryId = data.map((va) => va.zCapabilityID)
      NewthinkwiseReducer.forEach((value) => {
        let CATEGORYNAME = THINKWISE_CATEGORY + value.compentencyid
        value.capablitylist.forEach((subcat) => {
          let SUBCATEGORYNAME = THINKWISE_SUB_CATEGORY + subcat.capablityid
          if (allSubcategoryId.includes(parseInt(subcat.capablityid))) {
            if (!selectedCategory.includes(value.compentencyid)) {
              selectedCategory.push(value.compentencyid)
            }
            itemData[CATEGORYNAME] = { ...(itemData[CATEGORYNAME] || {}) }
            itemData[CATEGORYNAME][SUBCATEGORYNAME] = subcat.itemslist.map(
              (it) => it.itemid
            )
          }
        })
      })
      console.log(selectedCategory, itemData)
      dispatch({ type: NEW_API_SELECTED_DATA, payload: itemData })
      dispatch({
        type: NEW_SELECTED_THINKWISE_ID,
        payload: selectedCategory,
      })
    } catch (er) {
      console.log(er)
    } finally {
    }
  }

  const handleCompetencyModal = (e) => {
    const { value } = e.target
    console.log(value)
    let idSplit = value.split('_')
    if (idSplit[0] == 'null') {
      dispatch(getAllsaveItems(idSplit[1]))
    } else {
      compentencyIdgetItem(idSplit[0])
    }
    setCompetencyModalValue(value)
  }
  return (
    <div>
      {' '}
      <Paper className={classes.paper}>
        {/* Table tools */}
        {loading && <CustomPageLoading />}
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div">
            Competencies
          </Typography>
        </Toolbar>
        <Divider />
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ marginTop: '2px', marginLeft: '30px' }}>
          <Grid item xs={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="participantModel-label">
                Competency Model:
              </InputLabel>
              <Select
                labelId="participantModel-label"
                id="participantModel"
                name="participantModel"
                fullWidth
                label="Competency Model"
                onChange={handleCompetencyModal}
                value={competencyModalValue}>
                {competencyModal.map((modal) => {
                  const { zCompetencyModelID = '', source = '' } = modal
                  let idSplit = source.split('_')
                  return (
                    <MenuItem
                      id={zCompetencyModelID}
                      value={zCompetencyModelID + '_' + idSplit[1]}>
                      {' '}
                      {modal.zName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <CusTab
              value={activeTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto">
              <Tab
                label="THINKWISE ITEMS"
                {...a11yProps(0)}
                className={classes.tab}
              />
              <Tab
                label="CUSTOM ITEMS"
                {...a11yProps(0)}
                className={classes.tab}
              />
              <Tab
                label="SELECTED ITEMS"
                {...a11yProps(0)}
                className={classes.tab}
              />
            </CusTab>
            <TabPanel value={activeTab} index={0}>
              <Newthinkswiseitem loading={loading} />
              {/* <ThinkWiseItems competencyModalValue={competencyModalValue} /> */}
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <NewcustomItems zLevelID={zLevelID} />
              {/* <CustomItems zLevelID={zLevelID} /> */}
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <NewthinkwisePreview
                zScaleID={zScaleID}
                prevProjectId={zPreviousProjectID}
              />
              {/* <SelectedItems /> */}
            </TabPanel>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default NewCompentency
