import React, { useState, useEffect, useCallback } from 'react'
import {
  AccordionDetails,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from '@material-ui/core'
import CustomButton from '../../../../../common/CustomButton'
import {
  TEAM_RESPONS_USER_CHANGE,
  UPDATE_RATOR,
  UPDATE_RATOR_RELATIONSHIP,
  UPDATE_RELATIONSHIP_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { useFormik } from 'formik'
import DeleteIcon from '@material-ui/icons/Delete'
import CancelIcon from '@material-ui/icons/Cancel'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import { authHeader, getAlertToast } from '../../../../../common/CustomFunction'

function Editrater(props) {
  //console.log('jpprops',props);
  const {
    classes,
    raterList,
    cancelEdit,
    relationshipID,
    userID,
    relatonshipName = '',
  } = props
  let oldRelationshipId
  // if (zRelationshipName != '') {
  console.log(raterList, 'rater')
  // }
  oldRelationshipId = raterList.find((rec) => rec.zName == relatonshipName)
    .zRelationshipID

  console.log('find', oldRelationshipId)
  const [raterID, setRaterID] = useState('')
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required'),
    rater: Yup.string()
      .trim()
      .required('Rater relationship is required'),
  })

  const handleEditRater = async (formData) => {
    const { firstName, lastName, email, rater } = formData
    let newrelid
    if (typeof rater == 'string') {
      newrelid = raterList.find((rec) => rec.zName == rater).zRelationshipID
    }
    console.log(formData, 'form', newrelid)
    const {
      userID,
      projectID,
      LoginUserID,
      relationshipID,
      getParticipantList,
      RespondantID,
    } = props
    console.log(relationshipID)
    console.log(rater)
    const configrl = {
      method: 'POST',
      url: UPDATE_RELATIONSHIP_URL,
      headers: authHeader(),
      data: {
        userID: RespondantID,
        projectID: parseInt(projectID),
        projectUserRelationshipID: relationshipID,
        relationshipID: newrelid ? newrelid : rater,
        relationshipOldID: oldRelationshipId,
        participantID: userID,
      },
    }
    try {
      const response = await API(configrl)
    } catch (error) {
      console.log(error)
    }
    const postConfig = {
      method: 'GET',
      headers: authHeader(),

      url: TEAM_RESPONS_USER_CHANGE,
      params: {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        BaseUrl: 'http://localhost:3000/',
        UserID: RespondantID,
        LUID: LoginUserID,
        ProjectID: projectID,
        ResendInvite: false,
        //ParticipantID:211631,
        //RespondentID:RespondantID,
        ProjectType: '2',
      },
    }

    try {
      const response = await API(postConfig)
      if (response.status === 200) {
        if (typeof rater != 'string') {
          handleEditRaterRelationship(
            rater,
            projectID,
            relationshipID,
            getParticipantList
          )
        } else {
          Swal.fire({
            title:
              "<h5 style='color:#fff'>Changes were saved successfully..</h5>",
            background: '#00cc00',
          }).then((value) => {})
          //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
          getParticipantList()
        }

        // await handleUpdatePartner()
      }
    } catch (err) {
      Swal.fire(getAlertToast('success', 'Changes were saved successfully.'))
      getParticipantList()
    }
  }

  const handleEditRaterRelationship = async (
    rater,
    projectID,
    relationshipID,
    getParticipantList
  ) => {
    const postConfig = {
      method: 'GET',
      headers: authHeader(),

      url: UPDATE_RATOR_RELATIONSHIP,
      params: {
        ProjectUserRelationshipID: relationshipID,
        //ProjectID: projectID,
        RelationshipID: rater,
      },
    }
    const response = await API(postConfig)
    if (response.status === 200) {
      Swal.fire({
        title: "<h5 style='color:#fff'>Changes were saved successfully..</h5>",
        background: '#00cc00',
      }).then((value) => {})
      //Swal.fire(getAlertToast('success', 'Changes was saved successfully.'))
      getParticipantList()
      // await handleUpdatePartner()
    }
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
    resetForm,
  } = useFormik({
    initialValues: {
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      rater: props.relationshipName,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleEditRater(values),
  })

  var {
    firstName = '',
    lastName = '',
    email = '',
    rater = props.relationshipName,
  } = values || {}
  const relationShipHandle = (e) => {
    console.log(e)
  }

  const sendRequest = (e) => {
    resetForm()
    cancelEdit(e)
  }

  return (
    <AccordionDetails className={classes.accDetail}>
      {/* {data &&
                  data.map((record, index) => {
                    return ( */}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={3}
          // direction="row"
          // justifyContent="center"

          style={{ marginTop: '2px' }}>
          <Grid item xs={2} className={classes.grid}>
            <TextField
              id="lastName"
              label="Last Name:"
              value={lastName}
              onChange={handleChange}
              fullWidth
              name="lastName"
              error={errors.lastName && touched.lastName}
              helperText={
                errors.lastName && touched.lastName ? errors.lastName : ''
              }
            />
          </Grid>
          <Grid item xs={2} className={classes.grid}>
            <TextField
              id="firstName"
              label="First Name:"
              value={firstName}
              onChange={handleChange}
              fullWidth
              name="firstName"
              error={errors.firstName && touched.firstName}
              helperText={
                errors.firstName && touched.firstName ? errors.firstName : ''
              }
            />
          </Grid>

          <Grid item xs={2} className={classes.grid}>
            <TextField
              id="email"
              label="Email:"
              value={email}
              onChange={handleChange}
              fullWidth
              name="email"
              error={errors.email && touched.email}
              helperText={errors.email && touched.email ? errors.email : ''}
            />
          </Grid>
          <Grid item xs={3} className={classes.grid}>
            <FormControl
              className={classes.formControl}
              error={errors.scale && touched.scale}>
              <InputLabel>Rater Relationship</InputLabel>
              <Select
                labelId="scale-label"
                value={rater}
                name="rater"
                // defaultValue="Self"
                onChange={handleChange}
                label="SelectRater Relationship"
                fullWidth>
                <MenuItem value={rater}>{rater}</MenuItem>
                {raterList &&
                  raterList.map((record, i) => {
                    const { zName = '', zRelationshipID = '' } = record
                    return <MenuItem value={zRelationshipID}>{zName}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <CustomButton colour="primaryButton" type="submit">
              Save
            </CustomButton>
            {/* {logsuccess ? (
              <IconButton aria-label="edit" size="small">
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : (
              ''
            )} */}
            <IconButton
              aria-label="delete"
              size="small"
              style={{ marginLeft: '1rem' }}>
              <CancelIcon
                data-rosterid={userID}
                data-rid={relationshipID}
                onClick={
                  // e.stopPropagation()
                  sendRequest
                }
                fontSize="inherit"
              />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      {/* );
                  })} */}
    </AccordionDetails>
  )
}

export default Editrater
