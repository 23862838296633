import React, { memo, useCallback, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ThinkWiseItems from './item_selection/ThinkWiseItems'
import SelectedItems from './item_selection/SelectedItems'
import {
  GET_CAPABILITIES_BY_COMPETENCY_MODAL,
  GET_COMPENTENCY_MODAL_DROPDOWN,
  GET_COMPETENCY_MODAL,
  GET_CUSTOM_CATEGORY_BYPROJECT_URL,
  GET_CUSTOM_ITEMS_BY_CATEGORY,
  GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
  GET_ITEMS_BY_SUBCATEGORY_URL,
  GET_PROJECT_ITEM,
} from '../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../helpers/API'
import CustomItems from './item_selection/CustomItems'
import {
  CHECKED_ITEM_LIST,
  SAVE_CUSTOM_CATEGORY_ITEMS,
  SAVE_CUSTOM_SUB_CATEGORY_ITEMS,
  SAVE_PROJECTVALUES,
  SELECTED_SUB_CATEGORY,
  SELECTED_SUB_ITEMS,
  API_SELECTED_ITEMS,
  SELECT_THINKWISE_ITEM,
  APICUSTOM_SELCTEDITEM,
  API_DATA_ITEM,
  GET_ALL_SELECTED_ITEM,
  MARKCHE_CHECKEDITEM,
} from '../../../../redux/action/ActionType'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { authHeader, getUserId } from '../../../common/CustomFunction'
import { useParams } from 'react-router-dom'
import { CustomPageLoading } from '../../../../function/HelperFunction'
const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontFamily: 'Nunito-SemiBold !important',
  },
  formControl: {
    width: '100%',
  },
  tab: {
    fontFamily: 'Roboto-Medium !important',
    '&.MuiTab-root': {
      backgroundColor: '#BDBDBD',
      color: '#fff',
    },
    '&.Mui-selected': {
      backgroundColor: theme.primaryButton,
      color: '#fff',
    },
  },
}))

const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

function Competencies(props) {
  const classes = useStyles()
  const { projectInfo } = props
  const { zLevelID } = projectInfo || {}
  const [activeTab, setActiveTab] = useState(0)
  const [checkTab, setcheckTab] = useState(0)
  const [loading, setloading] = useState(false)
  const CheckedItemList = useSelector((state) => state.CheckedItemList)
  const [competencyModal, setCompetencyModal] = useState('')
  const [competencyModalValue, setCompetencyModalValue] = useState('')
  const CustomSubCategoryList = useSelector(
    (state) => state.CustomSubCategoryList
  )
  const [selectedCUstomItem, setselectedCUstomItem] = useState([])
  const [selectedProjectItem, setselectedProjectItem] = useState([])
  const CategoryItems = useSelector((state) => state.CustomCategoryItems)
  const CustomSubCategoyItems = useSelector(
    (state) => state.CustomSubCategoryItems
  )
  let ThinkwiseCategory = useSelector((state) => state.ThinkwisecategoryList)
  let customCategory = useSelector((state) => state.CustomCategoryList)
  let allCategory = [...ThinkwiseCategory, ...customCategory]
  let CustomCategoryList = useSelector((state) => state.CustomCategoryList)
  const Thinkwisesubcategory = useSelector(
    (state) => state.ThinkwisesubcategoryReducer
  )
  const CustomSubCategory = useSelector((state) => state.CustomSubCategoryList)
  let allSubCategory = [...Thinkwisesubcategory, ...CustomSubCategory]

  let ThinkwiseProjectList = useSelector((state) => state.ThinkwiseProjectId)
  // ThinkwiseProjectList = { ...ThinkwiseProjectList }

  const CustomCategoryItems = useSelector((state) => state.CustomCategoryItems)
  const CustomSubCategoryItems = useSelector(
    (state) => state.CustomSubCategoryItems
  )
  const { id: projectId } = useParams()
  const dispatch = useDispatch()
  const ThinkwisesubcategoryReducer = useSelector(
    (state) => state.ThinkwisesubcategoryReducer
  )
  let AllSubCategoryList = [
    ...ThinkwisesubcategoryReducer,
    ...CustomSubCategoryList,
  ]
  const handleTabChange = (event, newValue) => {
    setcheckTab(newValue)
    setActiveTab(newValue)
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const TabPanel = useCallback((props) => {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }, [])

  const getCompetencyModal = async () => {
    // setloading(true)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_COMPENTENCY_MODAL_DROPDOWN,
      params: {
        userId: getUserId(),
        ProjectID: parseInt(projectId),
      },
    }
    try {
      const response = await API(getConfig)
      if (response && response.data && response.data.data) {
        setCompetencyModal(response.data.data)
      }
    } catch (err) {
      console.log(err)
    } finally {
      //setloading(false)
    }
  }

  const handleCompetencyModal = (e) => {
    console.log(e.target.value)
    setCompetencyModalValue(e.target.value)
  }

  const getSelectedProjectItems = async () => {
    setloading(true)

    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT_ITEM,
      params: {
        ProjectID: parseInt(projectId),
      },
    }
    try {
      const response = await API(getConfig)
      if (response.status === 200) {
        const { data } = response.data
        setselectedProjectItem(data)
        props.parentCallback(data)
        let checkeItems = []
        if (data.length) {
          data.forEach((projectItem) => {
            const category = AllSubCategoryList.find(
              (sub) => sub.zCapabilityID === projectItem.zCapabilityID
            )

            checkeItems.push({
              itemId: projectItem.zItemID,
              subCategoryId: projectItem.zCapabilityID,
              categoryId: category?.zCompetencyID || projectItem.zCompetencyID,
            })
          })
        }
        console.log(checkeItems, data)
        dispatch({ type: CHECKED_ITEM_LIST, payload: checkeItems })
      }
    } catch (er) {
      console.error(er)
    } finally {
      setloading(false)
    }
  }

  // useEffect(() => {
  //   let checkeItems = []

  // }, [AllSubCategoryList, selectedProjectItem])
  const getProjectitem = async () => {
    let projectitemsbyid = {}
    setloading(true)

    await Promise.all(
      ThinkwisesubcategoryReducer.map(async (subCategory) => {
        const getConfig = {
          method: 'GET',
          headers: authHeader(),

          url: GET_ITEMS_BY_SUBCATEGORY_URL,
          params: {
            CapabilityID: subCategory.zCapabilityID,
            LevelID: 3,
          },
        }

        try {
          const response = await API(getConfig)
          const { data } = response.data
          if (response.status == 200) {
            projectitemsbyid['PROJECT_LIST_' + subCategory.zCapabilityID] = data
          }
        } catch (e) {
          console.warn(e)
        }
      })
    )

    dispatch({
      type: SAVE_PROJECTVALUES,
      payload: projectitemsbyid,
    })
    setloading(false)
  }
  //importnat
  // useEffect(() => {
  //   let customSeletciem = []
  //   selectedProjectItem.forEach((sele) => {
  //     console.log(sele)
  //     let seleCteditem = customCategory.find(
  //       (custom) => custom.zCompetencyID == sele.zCompetencyID
  //     )
  //     console.log(customCategory, sele, seleCteditem)
  //     if (seleCteditem) customSeletciem.push(seleCteditem)
  //     // console.log(seleCteditem)
  //   })
  //   console.log(customSeletciem)
  //   dispatch({ type: APICUSTOM_SELCTEDITEM, payload: customSeletciem })
  // }, [selectedProjectItem, customCategory])

  useEffect(() => {
    if (ThinkwisesubcategoryReducer.length) {
      getProjectitem()
    }
  }, [ThinkwisesubcategoryReducer])
  useEffect(() => {
    const timer = setTimeout(() => {
      getCompetencyModal()
    }, 7000)
    return () => clearTimeout(timer)
    // getCustomItemByCategory();
    // getCustomItemBySubCategory();
  }, [])

  useEffect(() => {
    console.log(ThinkwisesubcategoryReducer, CustomSubCategoryList)
    if (ThinkwisesubcategoryReducer.length) {
      getSelectedProjectItems()
    }
  }, [ThinkwisesubcategoryReducer])

  useEffect(() => {
    console.log('test')
    let tempCategory = []
    let tempSubCategory = []
    let tempItem = []
    let selected = []
    let markeddata = []
    let customCategory = []
    let customAllcategoryAll = []

    CheckedItemList.forEach((checkedItem) => {
      // console.log(checkedItem, 'checkedItem')
      const category = ThinkwiseCategory.find(
        (twItem) => twItem.zCompetencyID === checkedItem.categoryId
      )
      if (category) {
        if (!tempCategory.includes(category)) {
          tempCategory.push(category)
        }
      }
      if (!(checkedItem.categoryId == 0)) {
        markeddata.push(checkedItem)
      }
      //
      if (checkedItem.categoryId === 0) {
        const subCategory = allSubCategory.find(
          (twItem) => twItem.zCapabilityID === checkedItem.subCategoryId
        )
        const category = ThinkwiseCategory.find(
          (twItem) => twItem.zCompetencyID === subCategory?.zCompetencyID
        )

        Object.values(ThinkwiseProjectList).forEach((prokect) => {
          let finededValue = prokect.find(
            (value) => value.zItemID == checkedItem.itemId
          )
          if (finededValue?.zCapabilityID) {
            markeddata.push({
              ...checkedItem,
              categoryId: finededValue?.zCapabilityID,
            })
          }
        })

        if (category) {
          if (!tempCategory.includes(category)) {
            // console.log(category, 'category')
            tempCategory.push(category)
          }
        }
      }
      const subCategory = allSubCategory.find(
        (twItem) => twItem.zCapabilityID === checkedItem.subCategoryId
      )
      if (subCategory) {
        if (!tempSubCategory.includes(subCategory)) {
          tempSubCategory.push(subCategory)
        }
      }

      const item = ThinkwiseProjectList[
        'PROJECT_LIST_' + checkedItem.subCategoryId
      ]
        ? ThinkwiseProjectList[
            'PROJECT_LIST_' + checkedItem.subCategoryId
          ].find((item) => item.zItemID === checkedItem.itemId)
        : ''
      if (item) {
        tempItem.push(item)
      } else {
        if (checkedItem.categoryId == 0) {
          const item =
            CustomSubCategoryItems[
              'SUB_CATEGORY_ITEMS_' + checkedItem.subCategoryId
            ] &&
            CustomSubCategoryItems[
              'SUB_CATEGORY_ITEMS_' + checkedItem.subCategoryId
            ].find((item) => item.zItemID === checkedItem.itemId)
          if (item) {
            console.log(item, 'item')
            tempItem.push(item)
          }
        }

        if (checkedItem.subCategoryId == 0) {
          const item =
            CustomCategoryItems['CATEGORY_ITEMS_' + checkedItem.categoryId] &&
            CustomCategoryItems[
              'CATEGORY_ITEMS_' + checkedItem.categoryId
            ].find((item) => item.zItemID === checkedItem.itemId)

          let findData = CustomCategoryList.find(
            (value) => value.zCompetencyID == checkedItem.categoryId
          )

          if (!customAllcategoryAll.includes(checkedItem.categoryId)) {
            customAllcategoryAll.push(checkedItem.categoryId)
            if (findData) customCategory.push(findData)
          }
          if (item) {
            console.log(item, 'item')
            tempItem.push(item)
          }
        } else if (!(checkedItem.subCategoryId == 0)) {
          let findData = CustomCategoryList.find(
            (value) => value.zCompetencyID == checkedItem.categoryId
          )

          let newSelectedItem = selectedProjectItem.find(
            (value) => value.zItemID == checkedItem.itemId
          )
          console.log(selectedProjectItem, checkedItem, 'newSelectedItem')
          if (newSelectedItem) {
            tempItem.push(newSelectedItem)
          }
          if (!customAllcategoryAll.includes(checkedItem.categoryId)) {
            customAllcategoryAll.push(checkedItem.categoryId)
            if (findData) customCategory.push(findData)
          }
        }
      }
    })

    dispatch({ type: API_SELECTED_ITEMS, payload: [...tempCategory] })

    dispatch({
      type: API_DATA_ITEM,
      payload: tempCategory,
    })

    dispatch({
      type: SELECTED_SUB_CATEGORY,
      payload: tempSubCategory,
    })

    dispatch({ type: MARKCHE_CHECKEDITEM, payload: markeddata })
    dispatch({ type: SELECTED_SUB_ITEMS, payload: tempItem })

    setselectedCUstomItem(customCategory)
  }, [
    CheckedItemList,
    ThinkwiseCategory,
    ThinkwiseProjectList,
    Thinkwisesubcategory,
    CustomSubCategory,
    CategoryItems,
  ])

  const Selectedcustomcategory = useSelector((state) => state.Selectedcategory)

  const ThinkwiseCustomselectedlist = useSelector(
    (state) => state.ThinkwiseCustomselectedlist
  )

  useEffect(() => {
    dispatch({
      type: APICUSTOM_SELCTEDITEM,
      payload: selectedCUstomItem,
    })

    dispatch({
      type: GET_ALL_SELECTED_ITEM,
      payload: [...Selectedcustomcategory, ...selectedCUstomItem],
    })
  }, [selectedCUstomItem, Selectedcustomcategory])

  const getCustomItemBySubCategory = async () => {
    setloading(true)

    const subCategoryItems = {}
    let getSubCatItemsConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
      params: {
        LevelID: 3,
      },
    }
    await CustomSubCategoryList.forEach(async (customSubCategory) => {
      getSubCatItemsConfig.params.SubCategoryId =
        customSubCategory.zCapabilityID
      try {
        const response = await API(getSubCatItemsConfig)
        if (response.status === 200) {
          const { data } = response.data
          subCategoryItems[
            'SUB_CATEGORY_ITEMS_' + customSubCategory.zCapabilityID
          ] = data
        }
      } catch (err) {
        console.log(err)
      } finally {
        setloading(false)
      }
    })

    dispatch({
      type: SAVE_CUSTOM_SUB_CATEGORY_ITEMS,
      payload: subCategoryItems,
    })
  }

  const getCustomItemByCategory = async () => {
    setloading(true)

    const categoryItems = {}
    let getCatItemsConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_ITEMS_BY_CATEGORY,
      params: {
        LevelID: zLevelID,
      },
    }
    await Promise.all(
      customCategory.map(async (customCategory) => {
        getCatItemsConfig.params.CategoryId = customCategory.zCompetencyID
        try {
          const response = await API(getCatItemsConfig)
          if (response.status === 200) {
            const { data } = response.data
            //  categoryItems.push(...data);
            categoryItems[
              'CATEGORY_ITEMS_' + customCategory.zCompetencyID
            ] = data
          }
        } catch (err) {
          console.log(err)
        } finally {
          // setloading(false)
        }
      })
    )
    setloading(false)

    dispatch({ type: SAVE_CUSTOM_CATEGORY_ITEMS, payload: categoryItems })
  }

  const getCapabilityList = async (value) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CAPABILITIES_BY_COMPETENCY_MODAL,
      params: {
        CompetencyModelID: value,
      },
    }
    const response = await API(config)
    if (response.status === 200) {
      const { data } = response.data
      const capabilities = ThinkwisesubcategoryReducer.filter((rec) =>
        data.find((val) => val.zCapabilityID === rec.zCapabilityID)
      )
      let compCheckedItems = []
      capabilities.map((data) => {
        const items = ThinkwiseProjectList[`PROJECT_LIST_${data.zCapabilityID}`]
        if (items.length) {
          items.map((item) => {
            compCheckedItems.push({
              itemId: item.zItemID,
              categoryId: data.zCompetencyID,
              subCategoryId: data.zCapabilityID,
            })
          })
        }
      })
      compCheckedItems.length &&
        dispatch({ type: CHECKED_ITEM_LIST, payload: compCheckedItems })
    }
  }

  const GetCustomItem = async () => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_CUSTOM_CATEGORY_BYPROJECT_URL,
      params: {
        UserId: getUserId(),
        projectId: projectId,
      },
    }
    try {
      const response = await API(config)
      console.log(response)
    } catch (er) {
      console.error(er)
    }
  }

  // useEffect(() => {
  //   GetCustomItem()
  // }, [])

  useEffect(() => {
    if (competencyModalValue) {
      getCapabilityList(competencyModalValue)
    }
  }, [competencyModalValue])

  useEffect(() => {
    Object.keys(CategoryItems).length === 0 && getCustomItemBySubCategory()
    Object.keys(CustomSubCategoyItems).length === 0 && getCustomItemByCategory()
  }, [customCategory])
  return (
    <div>
      <Paper className={classes.paper}>
        {/* Table tools */}
        {loading && <CustomPageLoading />}
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div">
            Competencies
          </Typography>
        </Toolbar>
        <Divider />
        <Grid
          container
          spacing={3}
          direction="row"
          style={{ marginTop: '2px', marginLeft: '30px' }}>
          <Grid item xs={2}>
            <FormControl className={classes.formControl}>
              <InputLabel id="participantModel-label">
                Competency Model:
              </InputLabel>
              <Select
                labelId="participantModel-label"
                id="participantModel"
                name="participantModel"
                fullWidth
                label="Competency Model"
                onChange={handleCompetencyModal}
                value={competencyModalValue}>
                {competencyModal &&
                  competencyModal.length &&
                  competencyModal.map((modal) => {
                    const { zCompetencyModelID = '', source } = modal
                    var pid = source.split('_')
                    return <MenuItem value={pid[1]}> {modal.zName}</MenuItem>
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <CusTab
              value={activeTab}
              onChange={handleTabChange}
              aria-label="simple tabs example"
              variant="scrollable"
              scrollButtons="auto">
              <Tab
                label="THINKWISE ITEMS"
                {...a11yProps(0)}
                className={classes.tab}
              />
              <Tab
                label="CUSTOM ITEMS"
                {...a11yProps(0)}
                className={classes.tab}
              />
              <Tab
                label="SELECTED ITEMS"
                {...a11yProps(0)}
                className={classes.tab}
              />
            </CusTab>
            <TabPanel value={activeTab} index={0}>
              <ThinkWiseItems competencyModalValue={competencyModalValue} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <CustomItems zLevelID={zLevelID} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <SelectedItems />
              {/* <NewItem /> */}
            </TabPanel>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
export default memo(Competencies)
