import { useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import { GET_SCALE_RATING_BY_SCALE_ID } from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { minWidth } from '@mui/system'
import { authHeader } from '../../../../../common/CustomFunction'
const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    fontWeight: 'bold',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '40px',
  },
  acc: {
    marginBottom: '1rem',
    '&.MuiAccordionSummary-content .Mui-expanded': {
      margin: '0px !important',
    },
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    height: '40px',
    backgroundColor: '#eee',
    margin: 'unset !important',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',

    // or borderTop: '1px solid red'
  },
  tableRow: {
    height: 25,
  },
}))

export default function ViewSurveyDetails(props) {
  const classes = useStyles()
  const [scaleRating, setScaleRatings] = useState([])
  const currentProjectInfo = useSelector((state) => state.CurrentProjectInfo)
  const CheckedItemList = useSelector((state) => state.CheckedItemList)

  const {
    open = '',
    handleReset,
    title = '',
    category,
    subCategory,
    items,
  } = props
  console.log('jpcategory', category)
  console.log('jpsubCategory', subCategory)
  console.log('jpitems', items)

  function compare(a, b) {
    if (a.zItemID < b.zItemID) {
      return -1
    }
    if (a.zItemID > b.zItemID) {
      return 1
    }
    return 0
  }

  items.sort(compare)

  const tableColumns = [
    { id: 'fname', title: 'Strongly Disagree', sort: true },
    { id: 'lname', title: 'Disagree', sort: true },
    { id: 'email', title: 'Neutral', sort: true },
    { id: 'rater', title: 'Agree', sort: true },
    { id: 'status', title: 'Strongly Agree', sort: true },
  ]
  const tableHeadProps = { tableColumns }

  useEffect(() => {
    if (currentProjectInfo.zScaleID) {
      getScaleRatingByScaleId(currentProjectInfo.zScaleID)
    }
  }, [currentProjectInfo])

  async function getScaleRatingByScaleId(scaleId) {
    const getConfig = {
      method: 'GET',
      url: GET_SCALE_RATING_BY_SCALE_ID,
      headers: authHeader(),

      params: {
        ScaleID: scaleId,
      },
    }
    try {
      const response = await API(getConfig)
      console.log('response', response)
      if (response && response.data) {
        setScaleRatings(response.data.data)
      }
    } catch (e) {}
  }

  const SurveyTable = useCallback((value) => {
    const { zName, zDescription } = value
    return (
      <>
        {' '}
        <p style={{ margin: '0px', padding: '0px' }}>{zName}</p>
      </>
    )
  }, [])

  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            style={{ marginTop: '1rem' }}>
            {/* <div> */}
            <Grid item xs={10}>
              {category &&
                category.map((cat, key) => (
                  <Accordion
                    key={`accor_${key}`}
                    expanded={true}
                    className={classes.acc}>
                    <AccordionSummary
                      aria-controls={`item_${key}-content`}
                      id={`item_${key}-header`}
                      className={classes.accHead}>
                      <Grid item xs={10}>
                        <Typography className={classes.heading}>
                          {cat.zName}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accDetail}>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        style={{ marginTop: '2px' }}>
                        <Grid item xs={12}>
                          <p style={{ margin: '0px' }}>{cat.zDescription}</p>
                        </Grid>
                        {items &&
                          items.map((item, index) => {
                            if (
                              CheckedItemList.find(
                                (CheckedItem) =>
                                  CheckedItem.subCategoryId === 0 &&
                                  CheckedItem.categoryId ===
                                    cat.zCompetencyID &&
                                  item &&
                                  item.zItemID === CheckedItem.itemId
                              )
                            ) {
                              return (
                                <Grid item xs={12}>
                                  {' '}
                                  <p>
                                    {index + 1}. {item.zCaption}
                                  </p>
                                  <TableContainer
                                    component={Paper}
                                    className={classes.tablecontainer}>
                                    <Table
                                      stickyHeader
                                      size="small"
                                      className={classes.tableCell}>
                                      <TableHead>
                                        <TableRow>
                                          {scaleRating.map((record) => (
                                            <TableCell
                                              className={classes.tableCell}
                                              style={{
                                                backgroundColor: '#D0E9FC',
                                                maxWidth: '100px',
                                                width: '100px',
                                                padding: '0px',
                                              }}>
                                              {SurveyTable(record)}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      </TableHead>

                                      {/* Table Header */}

                                      {/* Table Body */}
                                      <TableBody>
                                        <TableRow className={classes.tableRow}>
                                          {scaleRating.map((record) => (
                                            <TableCell
                                              align="left"
                                              className={classes.tableCell}>
                                              {' '}
                                            </TableCell>
                                          ))}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Grid>
                              )
                            }
                          })}
                        {subCategory &&
                          subCategory.map((sub) => {
                            if (sub.zCompetencyID === cat.zCompetencyID) {
                              return (
                                <Grid item xs={12}>
                                  <Accordion
                                    key={`accor_${key}`}
                                    expanded={true}
                                    className={classes.acc}>
                                    <AccordionSummary
                                      aria-controls={`item_${key}-content`}
                                      id={`item_${key}-header`}
                                      className={classes.accHeadInside}>
                                      {' '}
                                      <p>{sub.zName}</p>
                                    </AccordionSummary>
                                  </Accordion>

                                  <p>{sub.zDescription}</p>
                                  {items &&
                                    items.map((item, index) => {
                                      if (
                                        item &&
                                        item.zCapabilityID === sub.zCapabilityID
                                      ) {
                                        return (
                                          <Grid item xs={12}>
                                            <b>
                                              {' '}
                                              <p>
                                                {index + 1}. {item.zCaption}
                                              </p>
                                            </b>
                                            <TableContainer
                                              component={Paper}
                                              className={
                                                classes.tablecontainer
                                              }>
                                              <Table
                                                stickyHeader
                                                size="small"
                                                className={classes.tableCell}>
                                                {/* Table Header */}
                                                {/* <CustomTableHeader
                                {...tableHeadProps}
                                className={classes.tableCell}
                              /> */}
                                                <TableHead>
                                                  <TableRow>
                                                    {scaleRating.map(
                                                      (record) => (
                                                        <TableCell
                                                          className={
                                                            classes.tableCell
                                                          }
                                                          style={{
                                                            backgroundColor:
                                                              '#D0E9FC',
                                                            maxWidth: '100px',
                                                            width: '100px',
                                                            padding: '0px 2px',
                                                            textAlign: 'center',
                                                          }}>
                                                          {SurveyTable(record)}
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableHead>

                                                {/* Table Header */}

                                                {/* Table Body */}
                                                <TableBody>
                                                  <TableRow
                                                    className={
                                                      classes.tableRow
                                                    }>
                                                    {scaleRating.map(
                                                      (record) => (
                                                        <TableCell
                                                          align="left"
                                                          className={
                                                            classes.tableCell
                                                          }>
                                                          {' '}
                                                        </TableCell>
                                                      )
                                                    )}
                                                  </TableRow>
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </Grid>
                                        )
                                      }
                                    })}
                                </Grid>
                              )
                            }
                          })}

                        <Grid item xs={12}>
                          {/* <b>
                          {" "}
                          <p>
                            2.Balances facts/data with intution and experience
                            when making decisions
                          </p>
                        </b> */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>
            {/* </div> */}
          </Grid>
          {/* </Typography> */}
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
