import { InputAdornment, TextField, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar'
import { API } from '../../../../../../helpers/API'
import {
  GET_PROJECT_OE_QUESTION_USER_LIST,
  SAVE_TASK_OE_QUESTION,
} from '../../../../../../helpers/constants/ApiRoutes'
import CustomButton from '../../../../../common/CustomButton'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  avatarStyle: {
    width: '30px',
    height: '30px',
    lineHeight: '30px',
    textAlign: 'center',
    color: 'rgb(255, 255, 255)',
    borderRadius: '50%',
    // background: 'rgb(255, 64, 128)',
    // background: theme.avasty[1],
    fontSize: '12px',
    display: 'inline-block',
  },
}))
const TaskOpenEndedQuestions = ({
  participantList,
  taskOEQuestions,
  projectId,
  ref,
}) => {
  const [projectOEQnList, setProjectOEQnList] = useState([])
  const classes = useStyles()

  const capLetFun = (name) => {
    const words = name.split(' ')
    console.log('props words', words)
    // const finalWord =
    const newData = words
      .map((word) => {
        if (word[0]) {
          return word[0].toUpperCase()
        }
      })
      .join('')
    console.log('props words final', newData)

    return newData
  }

  const getOEQuestions = async (id) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT_OE_QUESTION_USER_LIST,
      params: {
        ProjectID: parseInt(projectId),
        RaterUserID: parseInt(getUserId()),
        initialFlags: true,
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        setProjectOEQnList(data)
      }
    } catch (error) {
      console.error(error)
      setProjectOEQnList([])
    }
  }

  const handleSave = async (values) => {
    Object.keys(values).map(async (value) => {
      const temp = value.split('_')
      const config = {
        method: 'POST',
        headers: authHeader(),

        url: SAVE_TASK_OE_QUESTION,
        data: {
          projectID: projectId,
          raterUserID: getUserId(),
          participantID: Number(temp[0]),
          openEndedQuestionID: Number(temp[1]),
          text: values[value],
        },
      }
      await API(config)
    })
  }
  let initialValues = {}

  taskOEQuestions.forEach((qn) => {
    participantList.forEach((participant) => {
      const answer = projectOEQnList.find(
        (oeQn) =>
          oeQn.zOpenEndedQuestionID == qn.zOpenEndedQuestionID &&
          oeQn.zParticipantID == participant.zParticipantID
      )

      initialValues[
        `${participant.zParticipantID}_${qn.zOpenEndedQuestionID}`
      ] = answer ? answer.zText : ''
    })
  })

  useEffect(() => {
    getOEQuestions()
  }, [])

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => handleSave(values),
  })
  console.log(ref)
  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Open Ended Questions
      </Typography>

      <form onSubmit={handleSubmit}>
        {taskOEQuestions
          ? taskOEQuestions.map((oeQuestion, index) => (
              <div
                ref={(e) => {
                  if (ref) ref.current = e
                }}
                style={{ padding: '20px' }}>
                <Typography variant="p">
                  {index + 1}. {oeQuestion.zQuestionText}
                </Typography>
                <br />
                {participantList.map((participant) => {
                  return (
                    <>
                      <TextField
                        id="open-ended-text"
                        value={
                          values[
                            `${participant.zParticipantID}_${oeQuestion.zOpenEndedQuestionID}`
                          ]
                        }
                        multiline
                        rows={5}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* <Avatar
                                name={participant.zParticipantFullName}
                                size="30"
                                round={true}
                              />{' '} */}
                              <div
                                className={classes.avatarStyle}
                                style={{
                                  backgroundColor: participant.bgcolor,
                                }}>
                                {capLetFun(participant.zParticipantFullName)}
                              </div>
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleChange}
                        defalultValue
                        fullWidth
                        name={`${participant.zParticipantID}_${oeQuestion.zOpenEndedQuestionID}`}
                      />
                    </>
                  )
                })}
              </div>
            ))
          : ''}
        <CustomButton type="submit" colour="primaryButton">
          Save
        </CustomButton>
      </form>
    </div>
  )
}

export default TaskOpenEndedQuestions
