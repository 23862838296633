export const SELECT_THINKWISE_ITEM = 'SELECT_THINKWISE_ITEM'
export const DELETE_THINKWISE_ITEM = 'DELETE_THINKWISE_ITEM'
export const ADD_ALL_THINKWISE_ITEM = 'ADD_ALL_THINKWISE_ITEM'
export const UNCHECK_ALL_THINKWISE_ITEM = 'UNCHECK_ALL_THINKWISE_ITEM'
export const SAVE_CATEGORY = 'SAVE_CATEGORY'
export const SAVE_PROJECTVALUES = 'SAVE_PROJECTVALUES'
export const SAVE_CATEGORYLIST = 'SAVE_CATEGORYLIST'
export const SAVESUB_CATEGORY = 'SAVESUB_CATEGORY'
export const SELECT_CUSTOM_CATEGORY = 'SELECT_CUSTOM_CATEGORY'
export const DELETE_CUSTOM_CATEGORY = 'DELETE_CUSTOM_CATEGORY'
export const ADD_ALL_CUSTOM_CATEGORY = 'ADD_ALL_CUSTOM_CATEGORY'
export const UNCHECK_ALL_CUSTOM_CATEGORY = 'UNCHECK_ALL_CUSTOM_CATEGORY'
export const CHECKED_PROJECT_LIST = 'CHECKED_PROJECT_LIST'
export const CHECKED_ITEM_LIST = 'CHECKED_ITEM_LIST'
export const SAVE_CUSTOM_CATEGORY = 'SAVE_CUSTOM_CATEGORY'
export const SAVE_CUSTOM_CATEGORY_LIST = 'SAVE_CUSTOM_CATEGORY_LIST'
export const SAVE_CUSTOM_SUB_CATEGORY_LIST = 'SAVE_CUSTOM_SUB_CATEGORY_LIST'
export const SAVE_CUSTOM_PROJECT_VALUES = 'SAVE_CUSTOM_PROJECT_VALUES'
export const SAVE_CUSTOM_CATEGORY_ITEMS = 'SAVE_CUSTOM_CATEGORY_ITEMS'
export const SAVE_CUSTOM_SUB_CATEGORY_ITEMS = 'SAVE_CUSTOM_SUB_CATEGORY_ITEMS'
export const SAVE_SELECTED_ITEMS = 'SAVE_SELECTED_ITEMS'
export const SAVE_CURRENT_STEP = 'SAVE_CURRENT_STEP'
export const SAVE_PROJECT_INFO = 'SAVE_PROJECT_INFO'
export const SAVE_TASK_LIST = 'SAVE_TASK_LIST'
export const CHECKED_SORTED_LIST = 'CHECKED_SORTED_LIST'
export const ROSTER_LIST = 'ROSTER_LIST'
export const ADD_ROSTER_FIELD = 'ADD_ROSTER_FIELD'
export const REMOVE_ROSTER_FIELD = 'REMOVE_ROSTER_FIELD'
export const API_SELECTED_ITEMS = 'API_SELECTED_ITEMS'
export const EDIT_ROSTER_FIELD = 'EDIT_ROSTER_FIELD'
export const DELETE_ROSTER_FIELD = 'DELETE_ROSTER_FIELD'
export const SORTING_SELECTED_ITEMS = 'SORTING_SELECTED_ITEMS'
export const SELECTED_SUB_CATEGORY = 'SELECTED_SUB_CATEGORY'
export const SELECTED_SUB_ITEMS = 'SELECTED_SUB_ITEMS'
export const API_DATA_ITEM = 'API_DATA_ITEM'
export const APICUSTOM_SELCTEDITEM = 'APICUSTOM_SELCTEDITEM'
export const GET_ALL_SELECTED_ITEM = 'GET_ALL_SELECTED_ITEM'
export const DRAGGED_ITEM = 'DRAGGED_ITEM'
export const MARKCHE_CHECKEDITEM = 'MARKCHE_CHECKEDITEM'
export const OPEN_END_QUESTION = 'OPEN_END_QUESTION'
export const NEW_THINKWISE_ITEM = 'NEW_THINKWISE_ITEM'
export const NEW_SELECTED_THINKWISE_ITEM = 'NEW_SELECTED_THINKWISE_ITEM'
export const NEW_SELECTED_THINKWISE = 'NEW_SELECTED_THINKWISE'
export const NEW_SELECT_THINKWISE_ITEM = 'NEW_SELECT_THINKWISE_ITEM'
export const SELCTED_PREVIEW_THINKWISE = 'SELCTED_PREVIEW_THINKWISE'
export const NEW_CUSTOM_ITEMS = 'NEW_CUSTOM_ITEMS'
export const NEW_SELECT_CUSTOM_ITEMS = 'NEW_SELECT_CUSTOM_ITEMS'
export const SELECT_CATEGORYITEM = 'SELECT_CATEGORYITEM'
export const NEW_CUSTOM_CATEGORY_ITEMS = 'NEW_CUSTOM_CATEGORY_ITEMS'
export const NEW_ALL_CUSTOM_CATEGORY_ITEMS = 'NEW_ALL_CUSTOM_CATEGORY_ITEMS'
export const NEW_SELCT_SUB_ITEMS = 'NEW_SELCT_SUB_ITEMS'
export const NEW_CUSTOM_ITEM_ALL = 'NEW_CUSTOM_ITEM_ALL'
export const NEW_CUSTOM_DELETE_ITEMS = 'NEW_CUSTOM_DELETE_ITEMS'
export const NEW_SELECTED_THINKWISE_ID = 'NEW_SELECTED_THINKWISE_ID'
export const NEW_SELECTED_THINKWISE_ITEMS_ID = 'NEW_SELECTED_THINKWISE_ITEMS_ID'
export const NEW_SELECTED_THINKWISE_ITEMS_ALL_ID =
  'NEW_SELECTED_THINKWISE_ITEMS_ALL_ID'
export const NEW_ALL_SELECTED_ITEMS = 'NEW_ALL_SELECTED_ITEMS'
export const NEW_API_SELECTED_DATA = 'NEW_API_SELECTED_DATA'
export const NEW_API_CUSTOMITEM = 'NEW_API_CUSTOMITEM'
export const TOTAL_SELECTED_COUSTOM_ITEM = 'TOTAL_SELECTED_ITEM'
export const NEW_DELETE_THINKWISE = 'NEW_DELETE_THINKWISE'
export const TEAMSURVEY_CURRENTSTEP = 'TEAMSURVEY_CURRENTSTEP'
export const TEAMSURVEY_TEMP_CURRENTSTEP = 'TEAMSURVEY_TEMP_CURRENTSTEP'
export const GET_ORGANIZATION_LOGO = 'GET_ORGANIZATION_LOGO'
export const ORG_SUMMARY = 'ORG_SUMMARY'
export const SELECTED_ITEMS_REDUCER = 'SELECTED_ITEMS_REDUCER'
export  const DUE_DATE_360 = "DUE_DATE_360"
export const COMPETENCY_DRAG_DROP = "COMPETENCY_DRAG_DROP"
export const SELECTED_DRAG_DROP = 'SELECTED_DRAG_DROP'
