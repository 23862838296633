import {
  Divider,
  Typography,
  Grid,
  Paper,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Link,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Tab,
  Tabs,
  Box,
  Breadcrumbs,
  Snackbar,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import logo from '../../../../../../assets/images/company_logo.jpg'
import CustomBreadcrumbs from '../../../../../common/CustomBreadcrumbs'
import EditIcon from '@material-ui/icons/Edit'
import CustomButton from '../../../../../common/CustomButton'
import DetailsRoster from './DetailsRoster'
import DetailsReport from './DetailsReport'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import EditProjectInformation from './EditProjectInformation'
import ViewSurveyDetails from './ViewSurveyDetails'
import ReminderEmail from './ReminderEmail'
import { useAlert } from 'react-alert'
import {
  BASE_API_URL,
  GET_PROJECT_URL,
  GET_SCALE_RATING_BY_SCALE_ID,
  GET_TEAM_PROGRESS,
  PROJECT_STATUS_REPORT,
  SAVE_ORGANIZATION_TEMPLATE_URL,
} from '../../../../../../helpers/constants/ApiRoutes'
import { useParams } from 'react-router'
import { API } from '../../../../../../helpers/API'
import DetailsAdmin from './DetailsAdmin'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import { authHeader, getUserId } from '../../../../../common/CustomFunction'
import { useDispatch } from 'react-redux'
import { getAllsaveItems } from '../../../../../../redux/action'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded'
import { ROSTER_FORM } from '../../../../../../helpers/constants/RoutePaths'
import CustomLoader from '../../../../../common/CustomLoader'
const useStyles = makeStyles((theme) => ({
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  projectinfotab: {
    borderBottom: '1px solid #dee2e7',
  },
  title: {
    fontSize: '16px',
    margin: 0,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: '10px',
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontWeight: '600',
  },
  tablecontainer: {
    marginTop: '1rem',
    maxHeight: 440,
    overflowX: 'hidden',
  },
  mainheading: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#000000DE',
    opacity: 1,
  },
  inactive: {
    fontFamily: 'Nunito-Regular !important',
    color: '#1565C0 !important',
  },
  projecthearing: {
    fontFamily: 'Roboto-Bold !important',
    fontSize: '16px',
  },
  tab: {
    fontFamily: 'Roboto-Medium !important',
  },
}))
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

export default function ViewProjectDetails() {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [survey, setSurvey] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const [projectStatusReportData, setProjectStatusReportData] = useState([])
  const [scaleTypeLists, setScaleTypeLists] = useState([])
  const [templateCheckValue, setTemplateCheckValue] = useState(false)
  let { id = '' } = useParams()
  const logOrganizationID = localStorage.getItem('LOG_ORGANIZATION_ID')
  console.log(logOrganizationID, id, 'logOrganizatinID')
  const [progressval, setteamProgress] = useState()
  const dispatch = useDispatch()
  const [copied, setCopied] = useState(false)
  const [additionLink, setAdditionLink] = useState([])
  const [remLoading, setRemLoading] = useState(false)

  console.log(additionLink, 'addtionLiink')
  const columns = [
    { id: 'participantFirstName', displayName: 'Participant FirstName' },
    { id: 'participantLastName', displayName: 'Participant LastName' },
    { id: 'raterEmail', displayName: 'Rater Email' },
    { id: 'raterFirstName', displayName: 'Rater FirstName' },
    { id: 'raterLastName', displayName: 'Rater LastName' },
    { id: 'raterRelationship', displayName: 'Rater Relationship' },
    { id: 'taskStatus', displayName: 'Task Status' },
    { id: 'url', displayName: 'url' },
  ]

  const csvReport = {
    filename: `${projectDetails.zName}_ProjectStatus.csv`,
    columns: columns,
    datas: projectStatusReportData,
  }

  const getProject = async () => {
    console.log('id', id)
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PROJECT_URL,
      params: {
        projectID: id,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        console.log('project details', data.data[0])
        setProjectDetails(data.data[0])
        setTemplateCheckValue(data.data[0].zOrganizationTemplateFlag)
        getScaleList(data.data[0].zScaleID)
        localStorage.setItem('PROJECT_ID', data.data[0].zProjectID)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getteamProgress = async (id) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_TEAM_PROGRESS,
      params: {
        projectID: id,
        UserId: getUserId(),
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        console.log('result', response.data.ProgressText)
        setteamProgress(response.data.ProgressText)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getProjectStatusReport = async () => {
    console.log('id', id)
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: PROJECT_STATUS_REPORT,
      params: {
        projectID: id,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        let newDatalite = data.Report.map((item) => {
          let newUrl = item.url.split('=')

          return { ...item, url: `https://app.cantoo.us/${newUrl[1]}` }
        })
        setProjectStatusReportData(newDatalite)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getScaleList = async (scaleId) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SCALE_RATING_BY_SCALE_ID,
      params: {
        // ProjectID: id,
        ScaleID: scaleId,
      },
    }
    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        setScaleTypeLists(data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const viewSurvey = () => {
    setSurvey(!survey)
  }
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }
  const { enqueueSnackbar } = useSnackbar()
  const alert = useAlert()
  const [remindFlag, setRemindFlag] = useState(false)
  const reminderFun = (value) => {
    if (value == 'sent') {
      // CustomMessage('User Invited', 'success', enqueueSnackbar)
      alert.success('Invitation sent')
    }
    setRemindFlag(!remindFlag)
  }
  const [editFlag, setFlag] = useState(false)
  const editProject = () => {
    setFlag(true)
  }
  const handleReset = () => {
    setFlag(false)
  }
  console.log('project BEfore onchange', templateCheckValue)
  // const checkBoxHandle = (e) => {
  //   console.log('project e', e)
  //   console.log('project checked', e.target.checked)
  //   setTemplateCheckValue(e.target.checked)
  //   saveTemplateCheckbox(e.target.checked)
  // }
  const history = useHistory()
  console.log('hh', history)
  const saveTemplateCheckbox = async (tempFlag) => {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: SAVE_ORGANIZATION_TEMPLATE_URL,
      params: {
        projectID: id,
        Templateflag: tempFlag,
      },
    }

    try {
      const response = await API(getconfig)
      const { data } = response
      if (response) {
        console.log('data on checkbox', response)
      }
    } catch (e) {
      console.log(e)
    }
  }
  const addtionUrl = `${'' + window.location.origin + ROSTER_FORM}/${id} `

  const copy = () => {
    const el = document.createElement('input')
    el.value = addtionUrl
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setCopied(true)
    CustomMessage('Link Copied', 'success', enqueueSnackbar) //call toster
  }

  useEffect(() => {
    getProject()
    getProjectStatusReport()
    dispatch(getAllsaveItems(id))
    getteamProgress(id)
  }, [])

  return (
    <>
      <div>
        <Grid container spacing={1}>
          {/* <Grid item xs={6} lg={6} md={6}>
            <Typography
              className={classes.mainheading}
              variant="h6"
              gutterBottom>
              ThinkWise 360 Projects
            </Typography>

            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.inactive} color="textPrimary">
                Home
              </Typography>
              <Typography className={classes.inactive} color="textPrimary">
                ThinkWise 360
              </Typography>
              <Typography style={{ color: '#0000008A' }} color="textPrimary">
                Projects
              </Typography>
            </Breadcrumbs>
          </Grid> */}
          {/* <Grid item xs={6} lg={6} md={6}> */}
          {/* <div
              className={classes.sideHead}
              style={{ display: 'flex', justifyContent: 'right' }}>
              <img src={logo} alt="logo" width="100" height="60" />
            </div> */}
          {/* </Grid> */}
          {/* <Grid item xs={2}> */}
          {/* {isInvitationSent ? (
            <>
            <CustomButton
              className={classes.dateBtn}
              type="submit"
              startIcon={<CheckCircleOutlineIcon />}
              endIcon={<CloseIcon onClick={reminderFun} />}
            >
              Invitation Sent
            </CustomButton>
            </>
          ) : (
            ""
          )} */}
          {/* </Grid> */}
          {/* <Grid item xs={12}>
            <Divider />
          </Grid> */}
        </Grid>
        <Paper className={classes.paper}>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '1rem',
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            {/* Table tools */}
            <Toolbar className={classes.root}>
              <Typography
                className={classes.tblHead}
                variant="h6"
                id="tableTitle"
                component="div">
                Project Information
                <> </>
                <IconButton
                  aria-label="delete"
                  className={classes.icoBtnInside}
                  size="small"
                  onClick={editProject}>
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Typography>
            </Toolbar>
            <Grid item xs={2}>
              {projectDetails &&
              projectDetails.zProjectStatusName == 'Completed' ? (
                ''
              ) : (
                <CustomButton
                  className={classes.dateBtn}
                  type="submit"
                  onClick={reminderFun}>
                  Remind All Incomplete
                </CustomButton>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid
            container
            spacing={3}
            style={{ width: '100%', margin: 0, padding: '0px 20px' }}>
            <Grid item xs={12}>
              <p style={{ fontWeight: 'bold' }}>
                <b className={classes.projecthearing}>
                  {projectDetails ? projectDetails.zName : 'N/A'}
                </b>
              </p>
              <span>
                {projectDetails ? projectDetails.zDescription : 'N/A'}
              </span>
            </Grid>
            <Grid item xs={2}>
              <p>
                <b className={classes.projecthearing}>Launch Date:</b>
              </p>
              <span>
                {projectDetails ? projectDetails.zDateCreatedFormat : 'N/A'}
              </span>
            </Grid>
            <Grid item xs={2}>
              <b>
                <p className={classes.projecthearing}>Due Date:</p>
              </b>
              <span>
                {projectDetails ? projectDetails.zDueDateFormat : 'N/A'}
              </span>
            </Grid>
            <Grid item xs={2}>
              <b>
                <p className={classes.projecthearing}>Survey Adminstrator:</p>
              </b>
              <span>
                {projectDetails ? projectDetails.zOrganizerName : 'N/A'}
              </span>
            </Grid>
            <Grid item xs={2}>
              <b>
                <p className={classes.projecthearing}>Project Status:</p>
              </b>
              <span>
                {projectDetails ? projectDetails.zProjectStatusName : 'N/A'}
              </span>
            </Grid>
            <Grid item xs={2}>
              <b>
                <p className={classes.projecthearing}>Progress:</p>
              </b>
              <span style={{ whiteSpace: 'nowrap' }}>
                {/* {projectDetails
                  ? `${projectDetails.zTotalParticipantFinalizedCount} of ${projectDetails.zTotalParticipantCount} completed`
                  : 'N/A'} */}
                {progressval ? progressval : 'N/A'}
              </span>
            </Grid>

            {/* <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={viewSurvey}
            >
              View Survey
            </Button>
          </Grid> 
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              }
              label="Save as Organizational Template"
            />
            </Grid>*/}
            {/* <Grid item xs={10}>
              {projectDetails && (
                <FormControlLabel
                  control={
                    <Checkbox
                      //value={projectDetails.zOrganizationTemplateFlag}
                      // checked={projectDetails.zOrganizationTemplateFlag}
                      checked={templateCheckValue}
                      color="primary"
                      name="organizationTemplate"
                      onChange={checkBoxHandle}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  }
                  label="Save as Organizational Template11"
                />
              )}
                </Grid> */}
            {!!additionLink[0]?.zByPassRoster && (
              <Grid item xs={10}>
                <Typography>Participant Link:</Typography>
                <Grid style={{ display: 'flex', gap: '1rem' }}>
                  <a href={addtionUrl} target="_blank">
                    https://app.cantoo.us/projectspecificurlforparticipantaddition
                  </a>
                  <Grid style={{ display: 'flex' }}>
                    <Typography style={{ display: 'flex' }}>
                      <p
                        onClick={copy}
                        style={{ margin: '0px', cursor: 'pointer' }}>
                        <FileCopyRoundedIcon />
                      </p>
                      Copy
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* <Grid item xs={2}>
              {projectDetails &&
              projectDetails.zProjectStatusName == 'Completed' ? (
                ''
              ) : (
                <CustomButton
                  className={classes.dateBtn}
                  type="submit"
                  onClick={reminderFun}>
                  Remind All Incomplete
                </CustomButton>
              )}
            </Grid> */}

            <Grid item xs={12}>
              <CusTab
                value={activeTab}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.projectinfotab}>
                <Tab className={classes.tab} label="ROSTER" {...a11yProps(0)} />
                <Tab
                  className={classes.tab}
                  label="REPORTS"
                  {...a11yProps(0)}
                />
                <Tab className={classes.tab} label="ADMIN" {...a11yProps(0)} />
              </CusTab>
            </Grid>
            <Grid item xs={12}>
              <TabPanel value={activeTab} index={0}>
                <DetailsRoster
                  projectDetails={projectDetails}
                  OrganizationID={projectDetails.zOrganizationID}
                  projectName={projectDetails ? projectDetails.zName : 'N/A'}
                  projectStatus={
                    projectDetails ? projectDetails.zProjectStatusName : 'N/A'
                  }
                  setAdditionLink={setAdditionLink}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <DetailsReport
                  csvReport={csvReport}
                  projectname={projectDetails.zName}
                  projectDetails={projectDetails}
                  scaleTypeLists={scaleTypeLists}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <DetailsAdmin
                  scaleTypeLists={scaleTypeLists}
                  projectDetails={projectDetails}
                  getProject={getProject}
                  viewSurvey={viewSurvey}
                />
              </TabPanel>
            </Grid>
          </Grid>
        </Paper>
        {editFlag && (
          <EditProjectInformation
            open={editFlag}
            handleReset={handleReset}
            projectDetails={projectDetails}
            getProject={getProject}
          />
        )}
        {survey && (
          <ViewSurveyDetails
            open={survey}
            handleReset={viewSurvey}
            title={'View Survey'}
          />
        )}
        {remindFlag && (
          <ReminderEmail
            open={remindFlag}
            projectDetails={projectDetails}
            handleReset={(value) => reminderFun(value)}
            rosterid={0}
          />
        )}
      </div>
    </>
  )
}
