import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useEffect, useState } from 'react'
import CircleIcon from '@mui/icons-material/Circle'
const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  closeButtonPreview: {
    float: 'right',
    position: 'relative',
    right: 30,
    bottom: 10,
  },
}))
export default function ActionPopup(props) {
  const { aflag, title = '', handleReset, actionText = '' } = props
  const classes = useStyles()
  let result
  const [quest, setQuest] = useState()
  const [patext, setAte] = useState()
  const [saptext, setreplaceStr] = useState()
  useEffect(() => {
    console.log('actual', actionText)
    if (actionText != '' && actionText.includes('?')) {
      // let a = actionText.replace(/.'a'/g, '$&M')
      let a = actionText.replaceAll('?', '$&M')
      console.log('incl', a)

      let at = actionText.split(':')[1]
      let at1 = at.split('.')[1]
      let at2 = at1.split('?')
      setQuest(at2)
      //   console.log('len', at2.length)
      //   at2.map((rec, i) => console.log('spp', rec))
    }
    setAte(actionText.split(':')[1])
    // setreplaceStr(actionText.split(':')[1].replaceAll('?', '*'))
  }, [])
  let sss = actionText.substring(0, actionText.indexOf('.'))

  console.log('ss', actionText.replaceAll('?', '\n').replaceAll('.', '\n'), sss)
  console.log('aaa', actionText.split(/[.?]/))
  if (patext) {
    result = patext
      .split('')
      // .map((s) => '#0' + s)
      .map((s) => (s == '.' ? s + '\n' : s == '?' ? s + '\n' : s))
      .join('')

    console.log(result.split('\n'))
    // setreplaceStr(result.split('\n'))
    result = result.split('\n')
  }
  return (
    <div>
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={aflag}
        className={classes.root}
        fullWidth="500"
        style={{ zIndex: '9999' }}>
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          {title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{actionText.split(':')[0]}:</Typography>
          {/* <div>{actionText.split(':')[1].split('.')[0]}.</div> */}

          <List className="price-list" sx={{ mt: 2 }}>
            {result?.map((s) => (
              <ListItem>
                {s.includes('?') ? (
                  <>
                    <ListItemIcon>
                      <CircleIcon
                        style={{ color: '#0f0e0ec9', fontSize: 'medium' }}
                      />
                    </ListItemIcon>
                  </>
                ) : (
                  ''
                )}
                <ListItemText primary={s} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  )
}
