import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Autocomplete } from '@mui/material'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

import { makeStyles } from '@material-ui/core/styles'
import {
  DatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { useFormik } from 'formik'
import { API } from '../../../../helpers/API'
import {
  ADD_PROJECT_ITEMS,
  CREATE_FROM_PREVIOUS_PROJECT_URL,
  CREATE_FROM_PREVIOUS_TEMPLATE_URL,
  CREATE_PROJECT_FROM_SCRATCH_URL,
  GET_PARTICIPANT_LEVEL_URL,
  GET_RATING_SCALE_LIST_URL,
  GET_RATING_SCALE_TYPES_URL,
  GET_SCALE_LIST_URL,
  GET_SCALE_RATING_BY_SCALE_ID,
  GET_SCALE_TYPES_URL,
  GET_SURVEY_ADMINSTRATOR_URL,
  UPDATE_PROJECT_URL,
} from '../../../../helpers/constants/ApiRoutes'
import { useHistory } from 'react-router'
import {
  BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_TEXT,
  BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE,
  PARTICIPANT_TEXT,
  PARTICIPANT_VALUE,
  SURVEY_ADMINISTRATOR_TEXT,
  SURVEY_ADMINISTRATOR_VALUE,
} from '../../../../helpers/constants/Survey360'
import {
  getOrganizationId,
  getQueryParamsByName,
  getUserId,
  getFirstName,
  getLastName,
  authHeader,
} from '../../../common/CustomFunction'
import {
  DUE_DATE_360,
  SAVE_CURRENT_STEP,
} from '../../../../redux/action/ActionType'
import { CustomMessage } from '../../../../function/HelperFunction'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import {
  getAllcustomItem,
  getAllsaveItems,
  getAllthinkwise,
} from '../../../../redux/action'
import ChooseSetupType from '../ChooseSetupType'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontWeight: '600',
  },
  formControl: {
    width: '100%',
  },
  labelName: {
    fontFamily: 'Nunito-Regular !important',
  },
  menuBlock: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',

    listStyleType: 'none',
    margin: 0,
    padding: 0,
    marginTop: '10px',
  },
  menuItem: {
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '130px',
    border: '2px solid #b9b6b6',
    padding: '6px',
  },
}))

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}))

export default function ProjectInformation({
  projectInfo,
  newFormtype,
  successCall,
}) {
  const classes = useStyles()
  const [surveyAdminstratorList, setSurveyAdministrator] = useState([])
  const [participantLevelList, setParticipantLevel] = useState()
  const [ratingScaleList, setRatingScaleList] = useState()
  const [scaleTypes, setScalesTypes] = useState()
  const [scaleRatings, setScaleRatings] = useState([])
  const history = useHistory()
  const dispatch = useDispatch()
  const [dateValue, setDateValue] = useState(null)

  const ProjectID = projectInfo?.zProjectID
  console.log(ProjectID, 'currentProject')

  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const currentStep = useSelector((state) => state.CurrentStep)
  const dueToggle = useSelector((state) => state.DueDate360Reducer)
  const { dueToggleStatus } = dueToggle

  // const currentProjectInfo = useSelector(state => state.CurrentProjectInfo)

  //Schema
  const validationSchema = Yup.object().shape({
    projectName: Yup.string()
      .trim()
      .required('Project Name is required'),
    dueDate:
      dueToggleStatus == true
        ? Yup.string()
            .trim()
            .required('Due Date is required')
        : Yup.string().trim(),
    participantLevel: Yup.string()
      .trim()
      .required('Participant Level is required'),
    ratingScale: Yup.string()
      .trim()
      .required('Scale Type is required'),
    scale: Yup.string()
      .trim()
      .required('Scale is required'),
    surveyAdministrator: Yup.string().required('Administrator required'),
  })
  console.log(
    projectInfo?.zDueDateFormat,
    'projectInfo.zDueDateFormat',
    projectInfo
  )
  const initialValues = {
    projectName: projectInfo ? projectInfo.zName : '',
    description: projectInfo ? projectInfo.zDescription : '',
    dueDate: projectInfo ? new Date(projectInfo.zDueDateFormat) : '',
    participantLevel: projectInfo ? projectInfo.zLevelID : '',
    //surveyAdminstrator:projectInfo ? projectInfo.zOrganizerID :'',
    surveyAdministrator: projectInfo ? projectInfo.zOwnerID : getUserId(),
    recipient: projectInfo
      ? projectInfo.zMgrViewReportFlag
        ? projectInfo.zParticipantReceiveReportFlag
          ? 3
          : 2
        : 1
      : '',
    ratingScale: projectInfo ? projectInfo.zScaleTypeID : '',
    scale: projectInfo ? projectInfo.zScaleID : '',
  }

  //Sign Up
  const saveFormValues = async (formValues) => {
    let {
      projectName,
      description,
      dueDate,
      participantLevel,
      surveyAdministrator,
      recipient,
      ratingScale,
      scale,
    } = formValues

    dueDate = new Date(dueDateValue)
    console.log('due change', dueDate, dueDate)
    console.log(dueDate, 'formValuesss')
    const currentDate = new Date()
    const currentDatefun = new Date(dueDate)
    console.log('fin', currentDatefun)

    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    )

    const enteredDateObj = new Date(dueDateValue)
    const enteredDateOnly = new Date(
      enteredDateObj.getFullYear(),
      enteredDateObj.getMonth(),
      enteredDateObj.getDate()
    )
    console.log(
      'duedatefinalchk',
      currentDate,
      enteredDateObj,
      enteredDateObj < currentDate
    )

    if (enteredDateOnly < currentDateOnly && dueToggleStatus == true) {
      CustomMessage('Invalid due date', 'error', enqueueSnackbar)
    } else {
      if (getQueryParamsByName('projectId')) {
        const createPreviousProjectConfig = {
          method: 'POST',
          headers: authHeader(),

          url: CREATE_FROM_PREVIOUS_PROJECT_URL,
          data: {
            prevProjectID: parseInt(getQueryParamsByName('projectId')),
            luid: getUserId(),
            projectName,
            description,
            ownerID: surveyAdministrator,
            organizerID: getUserId(),
            scaleID: scale,
            levelID: participantLevel,
            noParticipatingFlag: false,
            organizationTemplateFlag: false,
            mgrViewReportFlag:
              recipient ===
              (SURVEY_ADMINISTRATOR_VALUE ||
                BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
                ? true
                : false,
            participantReceiveReportFlag:
              recipient ===
              (PARTICIPANT_VALUE ||
                BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
                ? true
                : false,
            dueDate: dueDate
              ? moment(new Date(dueDate)).format('MM/DD/YYYY')
              : '',
            twTemplate: false,
            isDueDateOn: dueToggleStatus,
          },
        }
        const response = await API(createPreviousProjectConfig)
        if (response.status === 200) {
          await history.push(`/projects/edit/${response.data.projectID}`)
          dispatch({
            type: SAVE_CURRENT_STEP,
            payload: currentStep + 1,
          })
        }
      } else if (getQueryParamsByName('projectID')) {
        const createPreviousTempConfig = {
          method: 'POST',
          headers: authHeader(),

          url: CREATE_FROM_PREVIOUS_TEMPLATE_URL,
          data: {
            prevProjectID: parseInt(getQueryParamsByName('projectID')),
            luid: getUserId(),
            projectName,
            description,
            ownerID: surveyAdministrator,
            organizerID: getUserId(),
            scaleID: scale,
            levelID: participantLevel,
            noParticipatingFlag: false,
            organizationTemplateFlag: false,
            mgrViewReportFlag:
              recipient ===
              (SURVEY_ADMINISTRATOR_VALUE ||
                BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
                ? true
                : false,
            participantReceiveReportFlag:
              recipient ===
              (PARTICIPANT_VALUE ||
                BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
                ? true
                : false,
            dueDate: dueDateValue ? dueDateValue : '',
            twTemplate: false,
            isDueDateOn: dueToggleStatus,
          },
        }
        const response = await API(createPreviousTempConfig)

        if (response.status === 200) {
          await history.push(`/projects/edit/${response.data.ProjectID}`)
          dispatch({
            type: SAVE_CURRENT_STEP,
            payload: currentStep + 1,
          })
        }
      } else {
        try {
          const createConfig = {
            method: 'POST',
            headers: authHeader(),

            url: CREATE_PROJECT_FROM_SCRATCH_URL,
            data: {
              luid: getUserId(),
              projectName,
              description,
              ownerID: surveyAdministrator,
              organizerID: getUserId(),
              scaleID: scale,
              levelID: participantLevel,
              noParticipatingFlag: false,
              organizationTemplateFlag: false,
              mgrViewReportFlag:
                recipient ===
                (SURVEY_ADMINISTRATOR_VALUE ||
                  BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
                  ? true
                  : false,
              participantReceiveReportFlag:
                recipient ===
                (PARTICIPANT_VALUE ||
                  BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
                  ? true
                  : false,
              dueDate: dueDate.toString() === 'Invalid Date' ? '' : dueDate,
              twTemplate: false,
              isDueDateOn: dueToggleStatus,
            },
          }
          const response = await API(createConfig)
          if (response && response.data) {
            await history.push(`/projects/edit/${response.data.ProjectID}`)
            dispatch({
              type: SAVE_CURRENT_STEP,
              payload: currentStep + 1,
            })
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
  console.log(projectInfo)
  const updateProject = async (formValues) => {
    let {
      projectName,
      description,
      dueDate,
      participantLevel,
      surveyAdministrator,
      recipient,
      ratingScale,
      scale,
    } = formValues
    //dueDate.setDate(dueDate.getDate() + 1)

    console.log('duedatefinal', dueDate, dueDateValue)
    console.log('due change', dueDate)

    const currentDate = new Date()
    const currentDatefun = new Date(dueDate)
    console.log('fin', currentDatefun)

    const currentDateOnly = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    )

    const enteredDateObj = new Date(dueDateValue)
    const enteredDateOnly = new Date(
      enteredDateObj.getFullYear(),
      enteredDateObj.getMonth(),
      enteredDateObj.getDate()
    )
    console.log(
      'duedatefinalchk',
      currentDate,
      enteredDateObj,
      enteredDateObj < currentDate
    )

    if (enteredDateOnly < currentDateOnly && dueToggleStatus == true) {
      CustomMessage('Invalid due date', 'error', enqueueSnackbar)
    } else {
      console.log('dueDateelse', dueDate, dueDateValue)
      const updateConfig = {
        method: 'PUT',
        headers: authHeader(),

        url: UPDATE_PROJECT_URL,
        data: {
          projectID: id,
          projectStatusID: 1,
          luid: getUserId(),
          projectName,
          description,
          ownerID: surveyAdministrator,
          organizerID: getUserId(),
          scaleID: scale,
          levelID: participantLevel,
          noParticipatingFlag: false,
          organizationTemplateFlag: false,
          mgrViewReportFlag:
            recipient ===
            (SURVEY_ADMINISTRATOR_VALUE ||
              BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
              ? true
              : false,
          participantReceiveReportFlag:
            recipient ===
            (PARTICIPANT_VALUE ||
              BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE)
              ? true
              : false,
          dueDate: dueDate
            ? moment(new Date(dueDate)).format('MM/DD/YYYY')
            : '',
          twTemplate: false,
          isDueDateOn: dueToggleStatus,
        },
      }
      console.log('config', updateConfig)
      const response = await API(updateConfig)
      console.log(projectInfo.zOwnerID, surveyAdministrator)
      if (!(projectInfo.zLevelID == participantLevel)) {
        const postConfig = {
          method: 'POST',
          headers: authHeader(),

          url: ADD_PROJECT_ITEMS,
          data: {
            projectID: parseInt(id),
            itemIdList: [],
          },
        }
        await API(postConfig)
        dispatch(getAllthinkwise(id))
        dispatch(getAllcustomItem(id))
        dispatch(getAllsaveItems(id))
      }
      if (response.status === 200) {
        CustomMessage(response.data.message, 'success', enqueueSnackbar)
        successCall()
        dispatch({
          type: SAVE_CURRENT_STEP,
          payload: currentStep + 1,
        })
      }
    }
  }

  const changeDateField = (date) => {
    console.log(date, 'selected date')
    // setFieldValue('dueDate', new Date(date))
    dueDateValue = new Date(date)
    if (!date) {
      console.log(date, 'dateeeeee')
      setValues((val) => ({ ...val, dueDate: '' }))
    } else if (date) {
      console.log('datelast', date, date._d)
      setValues((val) => ({ ...val, dueDate: new Date(date._d) }))
    }
    // setFieldTouched('dueDate', true)
  }

  const handleScale = (scaleId) => {
    getScaleRatingByScaleId(scaleId)
  }

  async function getScaleRatingByScaleId(scaleId) {
    console.log('scaleId', scaleId)
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SCALE_RATING_BY_SCALE_ID,
      params: {
        ScaleID: scaleId,
      },
    }
    try {
      const response = await API(getConfig)
      if (response && response.data) {
        setScaleRatings(response.data.data)
      }
    } catch (e) {}
  }

  async function getPartipantLevel() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_PARTICIPANT_LEVEL_URL,
    }
    try {
      const response = await API(getconfig)
      if (response && response.data) {
        setParticipantLevel(response.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getScaleTypes() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SCALE_TYPES_URL,
    }
    try {
      const response = await API(getconfig)
      if (response && response.data) {
        setScalesTypes(response.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function getScaleList(scaleTypeId) {
    const zOrganizationID = localStorage.getItem('LOG_ORGANIZATION_ID')
    console.log(zOrganizationID, 'zOrganizationID')
    if (scaleTypeId) {
      const getconfig = {
        method: 'GET',
        headers: authHeader(),

        url: GET_SCALE_LIST_URL,
        params: {
          ScaleTypeID: scaleTypeId,
          zOrganizationID,
        },
      }
      try {
        const response = await API(getconfig)
        if (response && response.data) {
          setRatingScaleList(response.data.data)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  async function getSurveyAdministrator() {
    const getconfig = {
      method: 'GET',
      headers: authHeader(),

      url: GET_SURVEY_ADMINSTRATOR_URL,
      params: {
        OrganizationID: getOrganizationId(),
      },
    }
    try {
      const response = await API(getconfig)
      if (response.status === 200) {
        setSurveyAdministrator(response.data.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    getPartipantLevel()
    getScaleTypes()
    getSurveyAdministrator()
  }, [])

  useEffect(() => {
    if (projectInfo && projectInfo?.zScaleID) {
      getScaleRatingByScaleId(projectInfo.zScaleID)
    }
  }, [projectInfo?.zScaleID])

  //Formik
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setValues,
    handleBlur,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => (id ? updateProject(values) : saveFormValues(values)),
  })
  console.log(values, 'valuesssssss')
  useEffect(() => {
    getScaleList(values.ratingScale)
  }, [values.ratingScale])

  const {
    projectName = '',
    description = '',
    dueDate = '',
    participantLevel = '',
    recipient = '',
    ratingScale = '',
    surveyAdministrator = '',
    scale = '',
  } = values

  let dueDateValue = null
  console.log(dueDate, 'dueDatedueDatedueDate')
  if (dueDate) {
    dueDateValue = new Date(dueDate)
  }
  const projectSubmit = () => {
    console.log('project')
  }
  //  console.log(scaleRatings)

  const toggleChange = () => {
    console.log('yaah')
    dispatch({
      type: DUE_DATE_360,
      payload: { dueToggleStatus: !dueToggleStatus },
    })
    // setToggleStatus(!dueToggleStatus);
  }
  console.log(dueDateValue, 'dueDateValue')
  const handleDate = (e) => {
    console.log('date', e)
    setDateValue(e)
    setValues({
      ...values,
      dueDate: moment(new Date(e)),
    })
  }
  return (
    <>
      <div>
        <Paper className={classes.paper}>
          {/* Table tools */}
          <Toolbar className={classes.root}>
            <Typography
              className={classes.tblHead}
              variant="h6"
              id="tableTitle"
              component="div">
              Project Information
            </Typography>
          </Toolbar>
          <Divider />
          <form
            ref={newFormtype}
            noValidate
            autoComplete="off"
            name="form_1"
            className="form1"
            id="form_1"
            onSubmit={handleSubmit}>
            <Grid
              container
              spacing={5}
              direction="row"
              style={{ marginTop: '2px', marginLeft: '30px' }}>
              <Grid item xs={5}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="projectName"
                      label="Project Name:"
                      value={projectName}
                      className={classes.labelName}
                      onChange={handleChange}
                      fullWidth
                      name="projectName"
                      error={errors.projectName && touched.projectName}
                      helperText={
                        errors.projectName && touched.projectName
                          ? errors.projectName
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="description"
                      label="Survey Description:"
                      value={description}
                      onChange={handleChange}
                      className={classes.labelName}
                      fullWidth
                      name="description"
                      error={errors.description && touched.description}
                      helperText={
                        errors.description && touched.description
                          ? errors.description
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {/* <KeyboardDateTimePicker
                    value={dueDateValue}
                    variant="inline"
                    onChange={changeDateField}
                    label="Project Due Date"
                    onError={console.log}
                    minDate={new Date('2018-01-01T00:00')}
                    format="MM/DD/yyyy"
                    error={errors.dueDate && touched.dueDate}
                    helperText={
                      errors.dueDate && touched.dueDate ? errors.dueDate : ''
                    }
                  /> */}
                    <Grid style={{ marginBottom: '12px' }}>
                      <Typography
                        style={{
                          fontSize: '0.8rem',
                          fontFamily:
                            'Roboto, Helvetica, Arial, sans-serif !important',
                          color: 'rgba(0, 0, 0, 0.54)',
                        }}>
                        Set Project Due date:
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <AntSwitch
                          defaultChecked
                          inputProps={{ 'aria-label': 'ant design' }}
                          onChange={toggleChange}
                          checked={dueToggleStatus}
                        />
                        <Typography
                          className={classes.labelName}
                          style={{
                            fontSize: '0.8rem',
                            fontFamily:
                              'Roboto, Helvetica, Arial, sans-serif !important',
                            color: 'rgba(0, 0, 0, 0.54)',
                          }}>
                          {dueToggleStatus === true ? 'On' : 'Off'}
                        </Typography>
                      </Stack>
                    </Grid>

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        disabled={dueToggleStatus === false}
                        label="Project Due Date:"
                        // style={{ width: "100%" }}
                        variant="inline"
                        className={classes.labelName}
                        value={dueDateValue}
                        format="MM/DD/YYYY"
                        name="dueDate"
                        autoOk={true}
                        onBlur={handleBlur}
                        minDate={new Date()}
                        error={
                          dueToggleStatus === true
                            ? errors.dueDate && touched.dueDate
                            : ''
                        }
                        helperText={
                          dueToggleStatus === true
                            ? errors.dueDate && touched.dueDate
                              ? errors.dueDate
                              : ''
                            : ''
                        }
                        onChange={(newvalue) => changeDateField(newvalue)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid xs={6}></Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControl}
                      error={
                        errors.participantLevel && touched.participantLevel
                      }>
                      <InputLabel
                        className={classes.labelName}
                        id="participantLevel-label">
                        Participant Level:
                      </InputLabel>
                      <Select
                        labelId="participantLevel-label"
                        id="participantLevel"
                        name="participantLevel"
                        value={participantLevel}
                        className={classes.labelName}
                        onChange={handleChange}
                        fullWidth
                        label="Participant Level">
                        {participantLevelList &&
                          participantLevelList.map((pLevel) => (
                            <MenuItem value={pLevel.zItemLevelID}>
                              {pLevel.zName}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        {errors.participantLevel && touched.participantLevel
                          ? errors.participantLevel
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={6}></Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControl}
                      error={
                        errors.surveyAdministrator &&
                        touched.surveyAdministrator
                      }>
                      <InputLabel
                        className={classes.labelName}
                        id="surveyAdministrator-label">
                        Survey Administrator:
                      </InputLabel>
                      <Select
                        labelId="surveyAdministrator-label"
                        id="surveyAdministrator"
                        className={classes.labelName}
                        name="surveyAdministrator"
                        value={surveyAdministrator}
                        onChange={handleChange}
                        disabled={true}
                        fullWidth
                        label="surveyAdminstrator">
                        <MenuItem value={surveyAdministrator}>
                          {projectInfo
                            ? projectInfo.zOrganizerName
                            : '' + getFirstName() + ',' + getLastName() + ''}
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors.surveyAdministrator &&
                        touched.surveyAdministrator
                          ? errors.surveyAdministrator
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={6}></Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControl}
                      error={errors.recipient && touched.recipient}>
                      <InputLabel
                        className={classes.labelName}
                        id="recipient-label">
                        Report Recipient:
                      </InputLabel>
                      <Select
                        labelId="recipient-label"
                        id="recipient"
                        name="recipient"
                        value={recipient}
                        className={classes.labelName}
                        onChange={handleChange}
                        fullWidth
                        label="Report Recipient">
                        <MenuItem value={SURVEY_ADMINISTRATOR_VALUE}>
                          {SURVEY_ADMINISTRATOR_TEXT}
                        </MenuItem>
                        <MenuItem value={PARTICIPANT_VALUE}>
                          {PARTICIPANT_TEXT}
                        </MenuItem>
                        <MenuItem
                          value={
                            BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_VALUE
                          }>
                          {BOTH_SURVEY_ADMINSTRATOR_AND_PARTICIPANT_TEXT}
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors.recipient && touched.recipient
                          ? errors.recipient
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid xs={6}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControl}
                      error={errors.ratingScale && touched.ratingScale}>
                      <InputLabel id="ratingScale-label">
                        Rating Scale:
                      </InputLabel>
                      <Select
                        labelId="ratingScale-label"
                        id="ratingScale"
                        name="ratingScale"
                        value={ratingScale}
                        onChange={handleChange}
                        fullWidth
                        label="Rating Scale">
                        {scaleTypes &&
                          scaleTypes.map((scaleType) => (
                            <MenuItem value={scaleType.zScaleTypeID}>
                              {scaleType.zName}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        {errors.ratingScale && touched.ratingScale
                          ? errors.ratingScale
                          : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className={classes.formControl}
                      error={errors.scale && touched.scale}>
                      <InputLabel id="scale-label">Select Scale:</InputLabel>
                      <Select
                        labelId="scale-label"
                        id="scale"
                        name="scale"
                        value={scale}
                        onChange={(e) => {
                          handleChange(e)
                          handleScale(e.target.value)
                        }}
                        fullWidth
                        label="Select Scale"
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}>
                        {ratingScaleList &&
                          ratingScaleList.length &&
                          ratingScaleList.map((scale) => (
                            <MenuItem value={scale.zScaleID}>
                              {scale.zName}
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText>
                        {errors.scale && touched.scale ? errors.scale : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel id="ss-label">
                      Example of Selected Scale:
                    </InputLabel>
                    <div>
                      <ul className={classes.menuBlock}>
                        {scaleRatings.map((scaleRating) => (
                          <>
                            <li className={classes.menuItem}>
                              {/* {scaleRating.zName === 'N/A'
                              ? 'N/A'
                              : scaleRating.zDescription} */}
                              {/* This Condition written due to mismatching the scaleRating response */}
                              <span>{scaleRating.zName} </span>
                              <br />
                              {scaleRating.zDescription}
                            </li>
                          </>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    </>
  )
}
