import * as types from '../action/ActionType'
export default function ThinkwisesubcategoryReducer(state = [], action) {
  const { type, payload } = action
  switch (type) {
    case types.SAVESUB_CATEGORY:
      return (state = payload)
    default:
      return state
  }
}
