import React from 'react';

const Name = ({name}) => {
    return <><b>{name}</b><br /></>;
}

const AddressLine = ({title}) => {
    return <><span>{title}</span><br /></>
}

const Address = ({ name, addressLine1, addressLine2, city, zipcode, country, websiteUrl }) => {
    return (
        <p>
            {name && <Name name={name} />}
            {addressLine1 && <AddressLine title={addressLine1} />}
            {addressLine2 && <AddressLine title={addressLine2} />}
            {city && <AddressLine title={city} />}
            {country && <AddressLine title={country} />}
            {zipcode && <AddressLine title={zipcode} />}
            {websiteUrl && <AddressLine title={websiteUrl} />}
        </p>
    )
}
export default Address;
