import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ThinkWiseItems from '../item_selection/ThinkWiseItems'
import CustomItems from './CustomItems'
import SelectedItems from '../item_selection/SelectedItems'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'
import CustomButton from '../../../../common/CustomButton'
import DeleteIcon from '@material-ui/icons/Delete'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import EditIcon from '@material-ui/icons/Edit'
import TableHead from '@material-ui/core/TableHead'
import CustomTableHeader from '../../../../common/CustomTableHeader'
import PublishIcon from '@material-ui/icons/Publish'
import GetAppIcon from '@material-ui/icons/GetApp'
import ErrorIcon from '@material-ui/icons/Error'
import { Link } from 'react-router-dom'
import {
  RELATIONSHIP_LIST_URL,
  SAVE_PARTICIPANT_URL,
} from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import { useParams } from 'react-router'
import { authHeader, getUserId } from '../../../../common/CustomFunction'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
    padding: '0px 20px',
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  uploadfilebox: {
    display: 'flex',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
    marginLeft: '10px',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
}))
const CusTab = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.btnPrimary,
  },
}))(Tabs)

export default function Roster() {
  const classes = useStyles()
  let { id = '' } = useParams()

  const [activeTab, setActiveTab] = useState(0)
  const [uploadFile, setUploadFile] = useState({})
  const [raterList, setRaterList] = useState([])
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const chooseFileHandler = (e) => {
    setUploadFile(e.target.files[0])
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }
  //state
  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    rater: '',
  }
  const accordianInitial = {
    participantName: '',
  }
  const [accor, setAccor] = useState([])
  const [data, setDataValue] = useState([initialValues])
  const [addFlag, setFlag] = useState(false)
  const [uploadFlag, setUploadFlag] = useState(false)
  const da = [data]

  const addParticipant = () => {
    setAccor((prevItems) => [
      ...prevItems,
      {
        participantName: '',
      },
    ])
    setFlag(true)
    setUploadFlag(false)
  }
  const addRater = () => {
    setDataValue((prevItems) => [
      ...prevItems,
      {
        firstName: '',
        lastName: '',
        email: '',
        rater: '',
      },
    ])

    setFlag(true)
    setUploadFlag(false)
  }

  const uploadRosterFun = () => {
    const formData = new FormData()
    formData.append('file', uploadFile)
    setUploadFlag(true)
    setFlag(false)
  }
  let signupSchema

  //Schema
  const validationSchema = Yup.object({
    firstName_1: Yup.string()
      .trim()
      .required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .trim()
      .required('Email is required'),
    rater: Yup.string()
      .trim()
      .required('Rater relationship is required'),
  })
  const [logsuccess, setLog] = useState(false)
  const handleLogin = async (values) => {
    setLog(true)
    const { firstName = '', lastName = '', email = '', rater = '' } = values
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_PARTICIPANT_URL,
      data:
        data.length > 1
          ? {
              participantFirstName: '',
              participantLastName: '',
              articipantEmail: '',
              raterFirstName: firstName,
              raterLastName: lastName,
              raterEmail: email,
              relationship: rater,
              projectID: parseInt(id),
            }
          : {
              participantFirstName: firstName,
              participantLastName: lastName,
              articipantEmail: email,
              raterFirstName: '',
              raterLastName: '',
              raterEmail: '',
              relationship: rater,
              projectID: parseInt(id),
            },
    }
    try {
      const response = await API(config)
    } catch (error) {
      console.error(error)
    }
  }

  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: signupSchema,
    onSubmit: (values) => handleLogin(values),
  })

  const { firstName = '', lastName = '', email = '', rater = '' } = values

  //Table Columns
  const tableColumns = [
    { id: 'fname', title: 'First Name', sort: true },
    { id: 'lname', title: 'Last Name', sort: true },
    { id: 'email', title: 'Email', sort: true },
    { id: 'rater', title: 'Rater Relationship', sort: true },
    { id: 'action', title: '', sort: true },
  ]
  const tableHeadProps = { tableColumns }
  const getParticipantList = async () => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),

      url: '',
      params: {},
    }
    try {
      const response = await API(getConfig)
    } catch (error) {
      console.error(error)
    }
  }

  //rater list
  const getRaterRelation = async () => {
    const ratconfig = {
      method: 'GET',
      url: RELATIONSHIP_LIST_URL,
      headers: authHeader(),
      params: {
        UserID: getUserId(),
      },
    }
    try {
      const response = await API(ratconfig)
      const { status, data } = response.data
      if (status == 'Success') {
        setRaterList(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    getParticipantList()
    getRaterRelation()
  }, [])
  return (
    <div>
      {addFlag || uploadFlag ? (
        <Paper className={classes.paper}>
          {/* Table tools */}
          <Toolbar className={classes.root}>
            <Typography
              className={classes.tblHead}
              variant="h6"
              id="tableTitle"
              component="div">
              Roster
            </Typography>
          </Toolbar>
          <Divider />

          <Grid
            container
            spacing={3}
            direction="row"
            style={{ marginTop: '2px', marginLeft: '30px' }}>
            <Grid item xs={uploadFlag ? '3' : '5'}>
              <Button
                variant="contained"
                color="default"
                className={classes.headBtn}
                startIcon={<PublishIcon />}
                onClick={uploadRosterFun}>
                Upload Roster
              </Button>
            </Grid>
            {uploadFlag ? (
              <Grid item xs={4}>
                <center>
                  <p>
                    <b style={{ marginRight: '1rem' }}>Audit: </b>
                    Errors:
                    <span style={{ color: '#D32F2F', marginRight: '1rem' }}>
                      1
                    </span>
                    Warning:<span style={{ color: '#FFCC00' }}>1</span>
                  </p>
                </center>
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={uploadFlag ? '3' : '5'}>
              <Button
                variant="contained"
                color="default"
                className={classes.headBtn}
                startIcon={<AddIcon />}
                onClick={(e) => {
                  e.stopPropagation()
                  addParticipant()
                }}
                style={{ float: 'right' }}>
                Add Participant
              </Button>
            </Grid>
          </Grid>
          {addFlag ? (
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              style={{ marginTop: '2rem' }}>
              {/* <div> */}
              <Grid item xs={10}>
                {accor.map((element, key) => (
                  <Accordion
                    key={`acc_${key}`}
                    // expanded={true}
                    className={classes.acc}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                      aria-controls={`item_${key}-content`}
                      id={`item_${key}-header`}
                      className={classes.accHead}>
                      <Grid item xs={10}>
                        <Typography className={classes.heading}>
                          First Name Last Name
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={2}>
                        <Button
                          className={classes.toggleDescBtn}
                          startIcon={
                            <AddIcon className={classes.toggleDescIcon} />
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            addRater()
                          }}>
                          Add Rater
                        </Button>
                      </Grid> */}
                    </AccordionSummary>
                    <AccordionDetails className={classes.accDetail}>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justifyContent="center"
                        style={{ marginTop: '2px' }}>
                        <Grid item xs={2}>
                          First Name
                        </Grid>
                        <Grid item xs={2}>
                          Last Name
                        </Grid>
                        <Grid item xs={2}>
                          Email
                        </Grid>
                        <Grid item xs={2}>
                          Rater Relationship
                        </Grid>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                      {data &&
                        data.map((record, index) => {
                          console.log(
                            'index',
                            index,
                            errors[`firstName_${index}`]
                          )
                          return (
                            <Grid
                              container
                              spacing={3}
                              // direction="row"
                              // justifyContent="center"
                              style={{ marginTop: '2px' }}>
                              <Grid item xs={2} className={classes.grid}>
                                <TextField
                                  id="firstName"
                                  label="First Name:"
                                  value={firstName}
                                  onChange={handleChange}
                                  fullWidth
                                  name="firstName"
                                  error={errors.firstName && touched.firstName}
                                  helperText={
                                    errors.firstName && touched.firstName
                                      ? errors.firstName
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={2} className={classes.grid}>
                                <TextField
                                  id="lastName"
                                  label="Last Name:"
                                  value={lastName}
                                  onChange={handleChange}
                                  fullWidth
                                  name="lastName"
                                  error={errors.lastName && touched.lastName}
                                  helperText={
                                    errors.lastName && touched.lastName
                                      ? errors.lastName
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={2} className={classes.grid}>
                                <TextField
                                  id="email"
                                  label="Email:"
                                  value={email}
                                  onChange={handleChange}
                                  fullWidth
                                  name="email"
                                  error={errors.email && touched.email}
                                  helperText={
                                    errors.email && touched.email
                                      ? errors.email
                                      : ''
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} className={classes.grid}>
                                <FormControl
                                  className={classes.formControl}
                                  error={errors.scale && touched.scale}>
                                  <InputLabel>Rater Relationship</InputLabel>
                                  <Select
                                    // labelId="scale-label"
                                    name="rater"
                                    label="SelectRater Relationship"
                                    // defaultValue={'Self'}
                                    value={rater}
                                    onChange={handleChange}
                                    fullWidth>
                                    {raterList &&
                                      raterList.map((ratrecord, index) => {
                                        const {
                                          zName = '',
                                          zRelationshipID = '',
                                        } = ratrecord
                                        return (
                                          <MenuItem value="Self">
                                            {zName}
                                          </MenuItem>
                                        )
                                      })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                <CustomButton
                                  colour="primaryButton"
                                  type="submit"
                                  onClick={handleSubmit}>
                                  Save
                                </CustomButton>
                                {logsuccess ? (
                                  <Button
                                    className={classes.addCatBtn}
                                    startIcon={<EditIcon />}></Button>
                                ) : (
                                  ''
                                )}
                                <Button
                                  className={classes.addCatBtn}
                                  style={{ float: 'right' }}
                                  startIcon={<DeleteIcon />}></Button>
                              </Grid>
                            </Grid>
                          )
                        })}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
              {/* </div> */}
            </Grid>
          ) : (
            // <div>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              style={{ marginTop: '2px' }}>
              <Grid item xs={10}>
                {[0, 1].map((element, key) => (
                  <Accordion
                    key={`acc_${key}`}
                    // expanded={false
                    className={classes.acc}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                      aria-controls={`item_${key}-content`}
                      id={`item_${key}-header`}
                      className={classes.accHead}>
                      <Grid item xs={10}>
                        <Typography className={classes.heading}>
                          {`Participant Name ${key + 1}`}
                          {key + 1 == 2 ? (
                            <IconButton>
                              <ErrorIcon style={{ color: '#FFCC00' }} />
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          className={classes.toggleDescBtn}
                          startIcon={
                            <AddIcon className={classes.toggleDescIcon} />
                          }
                          style={{ marginLeft: '8rem' }}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}>
                          Add Rater
                        </Button>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accDetail}>
                      <Grid item xs={12}>
                        <TableContainer
                          component={Paper}
                          className={classes.tablecontainer}>
                          <Table stickyHeader size="small">
                            {/* Table Header */}
                            <CustomTableHeader {...tableHeadProps} />
                            {/* Table Header */}

                            {/* Table Body */}
                            <TableBody>
                              <TableRow>
                                <TableCell align="left">
                                  Rater FirstName
                                </TableCell>
                                <TableCell align="left">
                                  Rater LastName
                                </TableCell>
                                <TableCell align="left">
                                  rater@gmail.com
                                </TableCell>
                                <TableCell align="left">
                                  Rater Relationship
                                </TableCell>
                                <TableCell align="left">
                                  <Button
                                    className={classes.addCatBtn}
                                    startIcon={<EditIcon />}></Button>
                                  <Button
                                    className={classes.addCatBtn}
                                    startIcon={<DeleteIcon />}></Button>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left">smith</TableCell>
                                <TableCell align="left">James</TableCell>
                                <TableCell align="left">sj@gmail.com</TableCell>
                                <TableCell align="left">
                                  Direct Report
                                </TableCell>
                                <TableCell align="left">
                                  <Button
                                    className={classes.addCatBtn}
                                    startIcon={<EditIcon />}></Button>
                                  <Button
                                    className={classes.addCatBtn}
                                    startIcon={<DeleteIcon />}></Button>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          )}
        </Paper>
      ) : (
        <Paper className={classes.paper}>
          {/* Table tools */}
          <Toolbar className={classes.root}>
            <Typography
              className={classes.tblHead}
              variant="h6"
              id="tableTitle"
              component="div">
              Roster
            </Typography>
          </Toolbar>
          <Divider />
          <Typography
            className={classes.tblHead}
            variant="body2"
            gutterBottom
            style={{ marginTop: '2rem', marginLeft: '40px' }}>
            To add participants and raters please upload your completed roster
            file or add individually. <br />
            <a href="https://apptomate.thinkwiseinc.com/templates/Roster Upload Instructions.docx">
              Roster Instruction
            </a>
          </Typography>
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            style={{ marginTop: '2px', marginLeft: '30px' }}>
            <Grid item xs={4}>
              <List component="nav" aria-label="secondary mailbox folder">
                <ListItem>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<GetAppIcon />}>
                    Download Roster Template
                  </Button>
                </ListItem>
                {/* <ListItem>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<GetAppIcon />}>
                    Download Roster Template
                  </Button>
                </ListItem> */}
              </List>
            </Grid>
            {/* <Grid item xs={5}> */}
            {/* <Grid container spacing={2}> */}
            <Grid item xs={8}>
              <List component="nav" aria-label="secondary mailbox folder">
                <ListItem>
                  <Box className={classes.uploadfilebox}>
                    <TextField
                      name="upload-photo"
                      type="file"
                      onChange={(e) => {
                        chooseFileHandler(e, 'document')
                      }}
                    />

                    <Button
                      variant="contained"
                      color="default"
                      className={classes.headBtn}
                      startIcon={<PublishIcon />}
                      onClick={uploadRosterFun}>
                      Upload Roster File
                    </Button>
                  </Box>
                  <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<AddIcon />}
                    onClick={(e) => {
                      e.stopPropagation()
                      addParticipant()
                    }}>
                    Add Participant
                  </Button>
                </ListItem>
                <ListItem>
                  {/* <Button
                    variant="contained"
                    color="default"
                    className={classes.headBtn}
                    startIcon={<AddIcon />}
                    onClick={(e) => {
                      e.stopPropagation()
                      addParticipant()
                    }}>
                    Add Participant
                  </Button> */}
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  )
}
