import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { DialogActions } from '@material-ui/core'
import htmlToDraft from 'html-to-draftjs'

import { makeStyles } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import { InputLabel } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { DialogTitle } from '@material-ui/core'
import { Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomButton from '../../../../../common/CustomButton'
import CustomTableHeader from '../../../../../common/CustomTableHeader'
import { Editor } from 'react-draft-wysiwyg'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import AddIcon from '@material-ui/icons/Add'
import draftToHtml from 'draftjs-to-html'
import Menu from '@material-ui/core/Menu'
import {
  EditorState,
  Modifier,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import { useSnackbar } from 'notistack'
import { stateToHTML } from 'draft-js-export-html'
import { REMINDER_EMAIL } from '../../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../../helpers/API'
import { CustomMessage } from '../../../../../../function/HelperFunction'
import LineWeightIcon from '@material-ui/icons/LineWeight'
import { useState } from 'react'
import { CustomOption } from './CustomToolbar'
import { getDefaultKeyBinding, KeyBindingUtil } from 'draft-js'
import {
  authHeader,
  getEmail,
  getUserId,
} from '../../../../../common/CustomFunction'
import CustomLoader from '../../../../../common/CustomLoader'
const { hasCommandModifier } = KeyBindingUtil

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  dateBtn: {
    backgroundColor: theme.successColor,
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  grid: {
    padding: '6px',
    // textAlign: "center",
    // color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  btnTxtWhite: {
    color: theme.primaryColor,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
  },
  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },
  listItemStyle: {
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '20px',
  },
  headBtn: {
    textTransform: 'none',
    backgroundColor: '#fff',
  },
  errorBtn: {
    backgroundColor: theme.errorIcon,
  },
  tableCell: {
    // padding: "0px 8px",

    // borderWidth: 0,
    borderWidth: 1,
    borderColor: 'none',
    borderStyle: 'solid',

    // or borderTop: '1px solid red'
  },
  tableRow: {
    height: 50,
  },
}))

export default function ReminderEmail(props) {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [selectedDate, handleDateChange] = useState(
    new Date('2019-01-01T18:54')
  )
  const { open = '', handleReset, projectDetails, rosterid } = props
  const [addreminDate, setAdd] = useState([{ dateval: '' }])
  const [keyword, setKeyWord] = useState('')
  const [loading, setLoading] = useState(false)
  const [remLoading, setRemLoading] = useState(false)
  const emailContent =
    '<h4><strong>We still need your input!</strong></h4><br/ ><br />' +
    '<p>Your task for the ThinkWise 360 survey is currently listed as incomplete.<br/ >' +
    'Please use the link below to complete the survey at your next available opportunity.</p><br/><br/>' +
    'You have been selected to provide feedback for $Participant+Relationship$<br/><br/>' +
    '<b>Link to the ThinkWise 360 Survey:</b> $SurveyLink$<br/>' +
    '<b>Email address to login with:</b> $Email$<br/><br/>' +
    'Thank you,<br/>' +
    '$AdminName$<br/><br/>' +
    'Technical Requirements: High speed internet connection.<br/>' +
    'Internet Explorer, Firefox, Google Chrome, or Safari.<br/>' +
    '(ThinkWise will support up to 2 versions prior to the current release.)<br/><br/>' +
    'Technical Support:<br/>' +
    'If for any reason you are having issues accessing or responding to the survey, contact the ThinkWise Technical Support team ($SupportLink$) and a member of their support team will be happy to assist you.'
  const blocksFromHTML = convertFromHTML(emailContent)
  const [lineSpacing, setlineSpacing] = useState(1)
  const [sendRemainder, setsendRemainder] = useState({
    RemindAllUser: false,
    sendTest: true,
  })
  let state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  )
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(state)
  )

  // const {id = ""} = useParams();

  const addExtraReminder = () => {
    setAdd((prevItems) => [
      ...prevItems,
      {
        dateval: '',
      },
    ])
  }

  const onEditorStateChange = (e) => {
    setEditorState(e)
  }

  const insertCharacter = (characterToInsert, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection()

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      characterToInsert
    )

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    )

    setEditorState(newEditorState)
  }

  const inSterSpach = (newState) => {
    const currentContent = newState.getCurrentContent(),
      currentSelection = newState.getSelection()
    const data = '<br/><br/><br/>'
    const ValueData = {
      1: '<br/>',
      2: '<br/><br/>',
      3: '<br/><br/><br/>',
      4: '<br/><br/><br/><br/>',
    }
    // return null
    let { contentBlocks, entityMap } = htmlToDraft(ValueData[lineSpacing])
    const newContent = Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
    )

    const newEditorState = EditorState.push(
      newState,
      newContent,
      'insert-fragment'
    )

    setEditorState(newEditorState)
  }

  const handleKeyWord = (e) => {
    insertCharacter(e.target.value, editorState)
    setKeyWord(e.target.value)
  }

  //Schema
  const validationSchema = Yup.object({
    /*template: Yup.string()
      .trim()
      .required('Template is required'),
    templateName: Yup.string().required('Template name is required'),
    fromname: Yup.string()
      .trim()
      .required('Name is required'),
    fromEmail: Yup.string()
      .trim()
      .required('Email is required'),*/
    subject: Yup.string()
      .trim()
      .required('Subject is required'),
    /* testMail: Yup.string()
      .trim()
      .required('Test email is required'),*/
  })

  //state
  const initialValues = {
    template: '',
    templateName: '',
    fromname: '',
    fromEmail: '',
    subject:
      'Reminder: ' +
      projectDetails?.zName +
      ' Due ' +
      projectDetails?.zDueDateFormat +
      '',
    testMail: '',
  }

  const sendEmail = async (formData) => {
    setRemLoading(true)
    try {
      const { subject } = formData
      // const text = editorState.getCurrentContent().getBlocksAsArray()
      let finalText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      // text.map((item) => {
      //   finalText = item.getText() + finalText
      // })
      // console.log(text)

      if (rosterid != 0) {
        const config = {
          method: 'POST',
          url: REMINDER_EMAIL,
          headers: authHeader(),

          data: {
            ProjectID: projectDetails.zProjectID,
            // EmailText: '<h1 >hello</h1>',

            EmailText: finalText,
            subject: subject,
            UserID: rosterid,
            // UserIdentityName: "",
            ProjectName: projectDetails.zName,
            DueDate: projectDetails.zDueDateFormat,
            BaseUrl: window.location.origin + '/tasks/view/',
            RemindAllUser: false,
            sendTest: false,
            sendTestEmail: '',
          },
        }
        const response = await API(config)

        if (response.status === 200) {
          const { message } = response.data
          /*CustomMessage('Invitation Sent', 'success', enqueueSnackbar)*/
          handleReset('sent')
        }
      } else {
        const config = {
          method: 'POST',
          url: REMINDER_EMAIL,
          headers: authHeader(),

          data: {
            ProjectID: projectDetails.zProjectID,
            // EmailText: '<h1 >hello</h1>',

            EmailText: finalText,
            subject: subject,
            UserID: getUserId(),
            // UserIdentityName: "",
            ProjectName: projectDetails.zName,
            DueDate: projectDetails.zDueDateFormat,
            BaseUrl: window.location.origin + '/tasks/view/',

            ...sendRemainder,
          },
        }
        const response = await API(config)

        if (response.status === 200) {
          const { message } = response.data
          CustomMessage('Invitation Sent', 'success', enqueueSnackbar)
          handleReset()
        }
      }
    } catch (error) {
      console.error(error)
       if (error.response) {
         console.error('errrorrrr', error.response)
         let { data } = error.response
         CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
         //enqueueSnackbar('Error')
       }
    } finally {
      setRemLoading(false)
    }
  }
  const sendTestEmail = async (formData) => {
    setLoading(true)
    const { subject } = formData
    // const text = editorState.getCurrentContent().getBlocksAsArray()
    let finalText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // text.map((item) => {
    //   finalText = item.getText() + finalText
    // })
    // console.log(text)
    try {
      if (rosterid != 0) {
        const config = {
          method: 'POST',
          url: REMINDER_EMAIL,
          headers: authHeader(),

          data: {
            ProjectID: projectDetails.zProjectID,
            // EmailText: '<h1 >hello</h1>',

            EmailText: finalText,
            subject: subject,
            UserID: rosterid,
            // UserIdentityName: "",
            ProjectName: projectDetails.zName,
            DueDate: projectDetails.zDueDateFormat,
            BaseUrl: window.location.origin + '/tasks/view/',
            RemindAllUser: false,
            sendTest: true,
            sendTestEmail: getEmail(),
          },
        }
        const response = await API(config)

        if (response.status === 200) {
          const { message } = response.data
          /*CustomMessage('Invitation Sent', 'success', enqueueSnackbar)*/
          handleReset('sent')
        }
      } else {
        const config = {
          method: 'POST',
          url: REMINDER_EMAIL,
          headers: authHeader(),

          data: {
            ProjectID: projectDetails.zProjectID,
            // EmailText: '<h1 >hello</h1>',

            EmailText: finalText,
            subject: subject,
            UserID: getUserId(),
            // UserIdentityName: "",
            ProjectName: projectDetails.zName,
            DueDate: projectDetails.zDueDateFormat,
            BaseUrl: window.location.origin + '/tasks/view/',

            ...sendRemainder,
          },
        }
        const response = await API(config)

        if (response.status === 200) {
          const { message } = response.data
          CustomMessage('Invitation Sent', 'success', enqueueSnackbar)
          handleReset()
        }
      }
    } catch (error) {
      console.error(error)
       if (error.response) {
         console.error('errrorrrr', error.response)
         let { data } = error.response
         CustomMessage(data.message, 'error', enqueueSnackbar) //call toster
         //enqueueSnackbar('Error')
       }
    } finally {
      setLoading(false)
    }
  }
  function myKeyBindingFn(e) {
    if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) {
      return 'myeditor-save'
    }
    return getDefaultKeyBinding(e)
  }

  const CustomDropDown = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
      // inSterSpach(editorState)

      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const getValue = (e) => {
      const { value } = e.currentTarget.dataset
      setlineSpacing(value)
      handleClose()
    }

    return (
      <>
        <Button
          aria-controls="fade-menu"
          aria-haspopup="true"
          onClick={handleClick}>
          {lineSpacing} <LineWeightIcon />
        </Button>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}>
          <MenuItem data-value="1" onClick={getValue}>
            1
          </MenuItem>
          <MenuItem data-value="2" onClick={getValue}>
            2
          </MenuItem>
          <MenuItem data-value="3" onClick={getValue}>
            3
          </MenuItem>
          <MenuItem data-value="4" onClick={getValue}>
            4
          </MenuItem>
        </Menu>
      </>
    )
  }
  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => sendEmail(values),
  })

  function handleKeyCommand(command) {
    if (command === 'split-block') {
      inSterSpach(editorState)

      // Perform a request to save your contents, set
      // a new `editorState`, etc.
      // onChange(EditorState.push(editorState, contentState, 'insert-fragment'));

      return 'handled'
    }
    return 'not-handled'
  }

  const {
    template = '',
    templateName = '',
    fromname = '',
    fromEmail = '',
    subject = '',
    testMail = '',
  } = values

  const sendTest = (e) => {
    // e.preventDefault()
    setsendRemainder({
      RemindAllUser: false,
      sendTest: true,
    })
    handleSubmit(e)
  }
  const sendRemainderAlluser = (e) => {
    // setRemLoading(true)

    e.preventDefault()
    setsendRemainder({
      RemindAllUser: true,
      sendTest: false,
    })
    handleSubmit()
  }
  return (
    <div>
      {/* <Paper className={classes.paper}> */}
      {/* Table tools */}
      <Dialog
        onClose={handleReset}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.root}
        fullWidth
        maxWidth="md">
        {/* <div> */}
        <DialogTitle id="simple-dialog-title" onClose={handleReset}>
          Reminder Email
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleReset}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom> */}
          <form noValidate onSubmit={sendTest} autoComplete="off">
            <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
              {/* <Grid item xs={4}> */}
              {/* <FormControl
                  className={classes.formControl}
                  error={errors.template && touched.template}
                >
                  <FormLabel id="template">
                    Select A Reminder Email Template:
                  </FormLabel>
                  <Select
                    labelId="template"
                    id="template"
                    name="template"
                    value={template}
                    onChange={handleChange}
                    fullWidth
                    label="Executive"
                  >
                    <MenuItem value="">Executive</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.template && touched.template ? errors.template : ""}
                  </FormHelperText>
                </FormControl> */}
              {/* </Grid> */}
              {/* <Grid item xs={4}> */}
              {/* <FormControl className={classes.formControl}>
                  <TextField
                    id="templateName"
                    label="Save As Reminder Email Template:"
                    value={template}
                    onChange={handleChange}
                    fullWidth
                    name="tempalteName"
                    error={errors.templateName && touched.templateName}
                    helperText={
                      errors.templateName && touched.templateName
                        ? errors.templateName
                        : ""
                    }
                  />
                </FormControl> */}
              {/* </Grid> */}
              {/* <Grid item xs={2}>
                <CustomButton
                  colour="primaryButton"
                  style={{ float: "right" }}
                  type="submit"
                >
                  Save
                </CustomButton>
              </Grid> */}
              {/* <Grid item xs={2}></Grid> */}
              {/* <Grid item xs={4}>
                <FormControl
                  className={classes.formControl}
                  error={errors.fromname && touched.fromname}
                >
                  <TextField
                    labelId="fromname"
                    id="fromname"
                    name="fromname"
                    value={fromname}
                    onChange={handleChange}
                    fullWidth
                    label="From Name:"
                  />
                  <FormHelperText>
                    {errors.fromname && touched.fromname ? errors.fromname : ""}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="fromMail"
                  label="From Email:"
                  value={fromEmail}
                  onChange={handleChange}
                  fullWidth
                  name="description"
                  error={errors.fromEmail && touched.fromEmail}
                  helperText={
                    errors.fromEmail && touched.fromEmail
                      ? errors.fromEmail
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={4}></Grid> */}
              <Grid item xs={4}>
                <FormControl
                  className={classes.formControl}
                  error={errors.subject && touched.subject}>
                  <TextField
                    labelId="subject"
                    id="subject"
                    name="subject"
                    value={subject}
                    onChange={handleChange}
                    fullWidth
                    label="Subject:"
                  />
                  <FormHelperText>
                    {errors.subject && touched.subject ? errors.subject : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  id="testMail"
                  label="Send Test Email:"
                  value={testMail}
                  onChange={handleChange}
                  fullWidth
                  name="testMail"
                  error={errors.testMail && touched.testMail}
                  helperText={
                    errors.testMail && touched.testMail ? errors.testMail : ''
                  }
                />
              </Grid> */}
              <Grid item xs={3}>
                <CustomButton
                  colour="primaryButton"
                  style={{ float: 'right' }}
                  // type="submit"
                  onClick={sendTestEmail}>
                  {loading && <CustomLoader loading={loading} />}Send Test
                </CustomButton>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Accordion>
                  <AccordionSummary>
                    <Grid item xs={12}>
                      {/* <Typography className={classes.accHead}>
                        Sans Self
                      </Typography> */}
                      <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        editorState={editorState}
                        toolbarCustomButtons={[
                          <CustomDropDown setlineSpacing={setlineSpacing} />,
                        ]}
                        onEditorStateChange={onEditorStateChange}
                        handleKeyCommand={handleKeyCommand}
                        keyBindingFn={myKeyBindingFn}
                      />
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails></AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={2}>
                <InputLabel id="keyword2">Insert Keyword:</InputLabel>
                <Select
                  labelId="keyword2"
                  id="keyword"
                  name="keyword"
                  value={keyword}
                  onChange={(e) => {
                    handleKeyWord(e)
                  }}
                  label="Select Keyword"
                  fullWidth>
                  <MenuItem value="$SurveyLink$">Survey Link</MenuItem>
                  <MenuItem value="$Email$">
                    Respondent's Email Address
                  </MenuItem>
                  <MenuItem value="$DueDate$">Due Date</MenuItem>
                  <MenuItem value="$Participant$">Participant's Name</MenuItem>
                  <MenuItem value="$Participant+Relationship$">
                    Participant's Name/Relation
                  </MenuItem>
                  <MenuItem value="$AdminName$ ">
                    Project Administrator's Name
                  </MenuItem>
                  <MenuItem value="$ProjectName$ ">Project Name</MenuItem>$$
                  <MenuItem value="$FirstName$ ">
                    Respondent's First Name
                  </MenuItem>
                  <MenuItem value="$LastName$ ">
                    Respondent's Last Name
                  </MenuItem>
                  <MenuItem value="$SupportLink$ ">
                    ThinkSupport Email link
                  </MenuItem>
                </Select>
                <p>
                  <b>Keywords</b>
                  <br />
                  <br />
                  <b>
                    <small>Required Keywords:</small>
                  </b>
                  <br />

                  <small>$SurveyLink$</small>
                  <br />

                  <small>$Email$</small>
                  <br />
                  <br />
                  <b>
                    <small>Optional Keywords:</small>
                  </b>
                  <br />

                  <small>$ProjectName$</small>
                  <br />

                  <small>$DueDate$</small>
                </p>
              </Grid>
              <Grid item xs={6}>
                <center>
                  <h2>Send Reminder Now</h2>
                  <CustomButton
                    className={classes.dateBtn}
                    data-remainder="all"
                    onClick={sendRemainderAlluser}>
                    {remLoading && <CustomLoader loading={remLoading} />}Send
                    Now
                  </CustomButton>
                </center>
              </Grid>
              <Grid item xs={6}>
                {/* <h2>Schedule Reminders</h2>
                <CustomButton
                  type="submit"
                  colour="primaryButton"
                  onClick={handleReset}
                >
                  Set Reminder Schedule
                </CustomButton>
                {addreminDate.map((record, index) => {
                  return (
                    <div style={{ marginTop: "2rem" }} key={index}>
                      <KeyboardDateTimePicker
                        value={selectedDate}
                        variant="inline"
                        onChange={handleDateChange}
                        label="Reminder Send Date:"
                        onError={console.log}
                        minDate={new Date("2018-01-01T00:00")}
                        format="MM/DD/yyyy hh:mm a"
                      />
                    </div>
                  );
                })}
                <Button
                  startIcon={<AddIcon />}
                  style={{ textTransform: "none" }}
                  onClick={addExtraReminder}
                >
                  Add Reminder
                </Button> */}
              </Grid>
            </Grid>
            {/* </Typography> */}
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
        {/* </div> */}
      </Dialog>
      {/* </Paper> */}
    </div>
  )
}
