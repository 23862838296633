import React, { useEffect, useState } from 'react'
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CustomButton from '../../../common/CustomButton'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import EditOpenEndedQuestion from './item_selection/EditOpenEndedQuestion'
import {
  DELETE_OPEN_ENDED_QUESTION_URL,
  OPEN_ENDED_QUESTION_LISTUSER_URL,
  SAVED_OPEN_QUESTION_URL,
  SAVE_OPEN_ENDED_QUESTION_PROJECT_URL,
  SAVE_OPEN_ENDED_QUESTION_URL,
} from '../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../helpers/API'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import CustomLoader from '../../../common/CustomLoader'
import Swal from 'sweetalert2'
import { authHeader, getUserId } from '../../../common/CustomFunction'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import {
  OPEN_END_QUESTION,
  SAVE_CURRENT_STEP,
} from '../../../../redux/action/ActionType'
import { useSnackbar } from 'notistack'
import { CustomMessage } from '../../../../function/HelperFunction'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  tblHead: {
    flex: '1 1 100%',
    fontFamily: 'Nunito-SemiBold !important',
  },
  formControl: {
    width: '100%',
  },
}))

//Schema
const validationSchema = Yup.object({
  question: Yup.string().required('Question is required'),
})

export default function OpenEndedQuestions(props) {
  const { newFormtype } = props
  const classes = useStyles()
  let { id = '' } = useParams()
  const [questionset, setQuestionList] = useState()
  const [prefillSet, setPrefilledQues] = useState([])
  const [quesIds, setQuestIds] = useState()
  const [selected, setSelect] = useState(true)
  const [allFlag, setAllFlag] = useState(false)
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState([])
  const [selectedOption, setSelectedOption] = useState([])

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const currentStep = useSelector((state) => state.CurrentStep)
  const handleToggle = (value, selid) => () => {
    const currentIndex = checked.indexOf(value)
    let newChecked = [...checked]
    let count = parseInt(selectedOption.length) + 1
    if (parseInt(selectedOption.length) + 1 === questionset.length) {
      setAllFlag(true)
    }
    if (parseInt(selectedOption.length) == 1) {
      setAllFlag(false)
    }

    if (value == '*') {
      setAllFlag(!allFlag)
      if (currentIndex === -1 && selected) {
        setSelect(false)
        questionset.map((value, index) => {
          return newChecked.push(index)
        })
        let na = []
        questionset.map((value, index) => {
          return na.push(value.zOpenEndedQuestionID)
        })
        setSelectedOption(na)
      } else {
        setSelect(true)
        newChecked = ''
        setSelectedOption('')
        setAllFlag(false)
      }
    } else {
      if (currentIndex === -1) {
        newChecked.push(value)
        setSelectedOption([...selectedOption, selid])
      } else {
        newChecked.splice(currentIndex, 1)
        const re = selectedOption.filter((selop) => selop !== selid)
        setSelectedOption(re)
      }
    }
    dispatch({ type: OPEN_END_QUESTION, payload: newChecked })

    setChecked(newChecked)
  }
  const deleteQuestion = async (e) => {
    const deleteId = e.currentTarget.dataset.deleteid
    const deleteconfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_OPEN_ENDED_QUESTION_URL,
      params: { OpenEndedQuestionID: parseInt(deleteId) },
    }
    try {
      const response = await API(deleteconfig)
      const { status } = response.data
      if (status == 'Success') {
        // Swal.fire('Success', 'Question deleted successfully')
        CustomMessage(
          'Question deleted successfully',
          'success',
          enqueueSnackbar
        ) //call toster

        questionList(id)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const userID = getUserId()
  const [addFlag, setFlag] = useState(false)
  const handleChangeFun = (e) => {
    setFlag(false)
    setNewQues(e.target.value)
  }
  const addQuestion = async (values) => {
    if (newQues == '') {
      setFlag(true)
    } else {
      setLoading(true)
      const addconfig = {
        method: 'POST',
        headers: authHeader(),

        url: SAVE_OPEN_ENDED_QUESTION_URL,
        data: {
          questionText: newQues,
          userId: getUserId(),
          projectTypeId: 2,
          projectID: parseInt(id),
          oldOpenEndedQuestionID: 0,
        },
      }
      try {
        const response = await API(addconfig)
        const { status, OpenEndedQuestionID = '' } = response.data
        if (status == 'Success') {
          // Swal.fire('Success', 'Question added successfully')
          CustomMessage(
            'Question added successfully',
            'success',
            enqueueSnackbar
          )
          setPrefilledQues((val) => [
            ...val,
            { zOpenEndedQuestionID: OpenEndedQuestionID },
          ])
          props.parentCallback((val) => [
            ...val,
            { zOpenEndedQuestionID: OpenEndedQuestionID },
          ])
          questionList(id)

          setNewQues('')
        }
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }
  console.log('prefilset-->', prefillSet)

  const questionList = async (id) => {
    const config = {
      method: 'GET',
      headers: authHeader(),

      url: OPEN_ENDED_QUESTION_LISTUSER_URL,
      params: {
        UserID: getUserId(),
      },
    }
    try {
      const response = await API(config)
      const { data, status } = response.data
      if (status == 'Success') {
        setQuestionList(data)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const preFilledQuestion = async () => {
    const prefillConfig = {
      method: 'GET',
      headers: authHeader(),

      url: SAVED_OPEN_QUESTION_URL,
      params: {
        ProjectID: parseInt(id),
      },
    }
    try {
      const response = await API(prefillConfig)
      const { data, status } = response.data
      if (status == 'Success') {
        setPrefilledQues(data)
        props.parentCallback(data)
        data.map((record, i) => {
          return setQuestIds(record.zOpenEndedQuestionID)
        })
      }
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    questionList(id)
    preFilledQuestion(id)
  }, [id])

  const preFillSetting = async () => {
    let newChecked = [...checked]

    let options = [...selectedOption]
    questionset &&
      questionset.map((record, index) => {
        return (
          prefillSet &&
          prefillSet.map((rec, i) => {
            console.log('rec-->', rec)
            return rec.zOpenEndedQuestionID === record.zOpenEndedQuestionID
              ? newChecked.push(index)
              : ''
          })
        )
      })
    dispatch({ type: OPEN_END_QUESTION, payload: newChecked })
    setChecked(newChecked)
  }

  useEffect(() => {
    preFillSetting()
  }, [prefillSet, questionset])

  const preFillSettingone = async () => {
    let options = [...selectedOption]
    questionset &&
      questionset.map((record, index) => {
        return (
          prefillSet &&
          prefillSet.map((rec, i) => {
            return rec.zOpenEndedQuestionID === record.zOpenEndedQuestionID
              ? options.push(record.zOpenEndedQuestionID)
              : ''
          })
        )
      })
    setSelectedOption(options)
  }

  useEffect(() => {
    preFillSettingone()
  }, [prefillSet])
  const submitCallBack = () => {
    setEditFlag(false)
    questionList(id)
  }

  const saveQuestionForm = async (values) => {
    /*if (selectedOption.length <= 0) {
      CustomMessage('please select question', 'error', enqueueSnackbar)
      return null
    }*/
    console.log(selectedOption, 'selectedOption')

    if (selectedOption.length <= 0) {
      //CustomMessage('Choose open end question', 'error', enqueueSnackbar)
      dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
      return null
    }

    const saveconfig = {
      method: 'POST',
      headers: authHeader(),

      url: SAVE_OPEN_ENDED_QUESTION_PROJECT_URL,
      data: {
        openEndQuestionId: selectedOption,
        projectID: parseInt(id),
      },
    }
    try {
      const response = await API(saveconfig)
      const { status } = response.data
      if (status == 'Success') {
        // Swal.fire('Success', 'Details saved successfully')
        CustomMessage('Details saved successfully', 'success', enqueueSnackbar)
        dispatch({ type: SAVE_CURRENT_STEP, payload: currentStep + 1 })
      }
    } catch (error) {
      console.error(error)
    }
  }
  //Formik
  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      question: '',
    },
    enableReinitialize: true,
    // validationSchema,
    onSubmit: (values) => saveQuestionForm(values),
  })

  const { question = '' } = values
  const [newQues, setNewQues] = useState('')
  const [editFlag, setEditFlag] = useState(false)
  const [editQuest, setQues] = useState()
  const [editID, setEditId] = useState()
  const editQuestion = (e) => {
    const editableid = e.currentTarget.dataset.editid
    const editQuestion = e.currentTarget.dataset.editques
    setEditId(editableid)
    setQues(editQuestion)
    setEditFlag(true)
  }
  const handleReset = () => {
    setEditFlag(!editFlag)
  }

  return (
    <div>
      <Paper className={classes.paper}>
        {/* Table tools */}
        <Toolbar className={classes.root}>
          <Typography
            className={classes.tblHead}
            variant="h6"
            id="tableTitle"
            component="div">
            Open Ended Questions
          </Typography>
        </Toolbar>
        <Divider />
        <form
          noValidate
          autoComplete="off"
          id="form_1"
          ref={newFormtype}
          onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ marginTop: '2px', marginLeft: '30px' }}>
            <Grid item xs={8}>
              <p
                style={{
                  fontSize: '14px',
                  fontFamily: 'Roboto-Regular !important',
                }}>
                Select the open ended questions you would like to add to the
                survey. Use this fields below to add custom questions
              </p>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="all"
                        onClick={handleToggle('*')}
                        checked={allFlag}
                        // onChange={handleMultiSelectChange}
                      />
                    }
                    label="All"
                  />
                  <Grid container spacing={1}>
                    <>
                      <List className={classes.root} style={{ width: '200%' }}>
                        {questionset &&
                          questionset.map((record, index) => {
                            const {
                              compName = '',
                              zCapabilityID = '',
                              zCompetencyID = '',
                              zGoalRelatedFlag = '',
                              zLevelID = '',
                              zOpenEndedQuestionID = '',
                              zOrganizationID = '',
                              zQuestionText = '',
                              zSortOrder = '',
                              zUserID = '',
                            } = record

                            const labelId = `checkbox-list-label-${index}`
                            return (
                              <>
                                {/* <Grid item xs={9}> */}
                                <ListItem
                                  key={index}
                                  role={undefined}
                                  dense
                                  button>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{ display: 'flex' }}>
                                    <ListItemIcon
                                      style={{ marginBottom: '1rem' }}>
                                      <Checkbox
                                        edge="start"
                                        onClick={handleToggle(
                                          index,
                                          zOpenEndedQuestionID
                                        )}
                                        data-selectid={zOpenEndedQuestionID}
                                        checked={checked.indexOf(index) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                          'aria-labelledby': labelId,
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      style={{ marginTop: '1rem' }}
                                      id={labelId}
                                      primary={zQuestionText}
                                    />
                                  </Grid>

                                  <Grid item xs={2}>
                                    {zUserID === 0 ? (
                                      ''
                                    ) : (
                                      <ListItemSecondaryAction>
                                        <IconButton
                                          style={{ marginBottom: '3rem' }}
                                          edge="end"
                                          aria-label="delete"
                                          size="small"
                                          data-editid={zOpenEndedQuestionID}
                                          data-editques={zQuestionText}
                                          onClick={editQuestion}>
                                          <EditIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton
                                          aria-label="delete"
                                          size="small"
                                          data-deleteid={zOpenEndedQuestionID}
                                          onClick={deleteQuestion}
                                          style={{
                                            marginLeft: '1rem',
                                            marginBottom: '3rem',
                                          }}>
                                          <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    )}
                                  </Grid>
                                </ListItem>
                                {/* </Grid> */}
                              </>
                            )
                          })}
                      </List>
                    </>
                  </Grid>
                </FormGroup>
              </FormControl>
              {/* <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              style={{ marginTop: '2rem' }}> */}
              <TextField
                id="question-helper-text"
                label="Enter New Open Ended Question:"
                value={newQues}
                required
                onChange={handleChangeFun}
                fullWidth
                name="newQues"
                // error={errors.question && touched.question}
                helperText={addFlag ? 'Question Required' : ''}
              />
              <CustomButton
                type="submit"
                colour="primaryButton"
                onClick={addQuestion}>
                Add
                {loading && <CustomLoader loading={loading ? true : false} />}
              </CustomButton>
              {/* </form> */}
            </Grid>
            {editFlag && (
              <EditOpenEndedQuestion
                open={editFlag}
                handleReset={handleReset}
                editID={editID}
                submitCallBack={submitCallBack}
                editQuestion={editQuest}
                editprojectId={id}
              />
            )}
          </Grid>
        </form>
      </Paper>
    </div>
  )
}
