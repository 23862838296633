import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  closeIcon: {
    color: '#fff',
    float: 'right',
    margin: '-8px',
  },
}))

export const CustomMessage = (message, type, enqueueSnackbar) => {
  enqueueSnackbar(message, {
    variant: type,
    persist: ['success', 'info', 'error'].includes(type) ? false : true,
    autoHideDuration: ['success', 'info', 'error'].includes(type) && 3000,
  })
}

export const CustomPageLoading = () => {
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
function descendingComparator(a, b, orderBy) {
  //let dateInclude = ['zDueDateFormat']
  let valueA
  if (Number.isInteger(a[orderBy])) {
    valueA = a[orderBy]
  } else {
    valueA = a[orderBy]?.toLowerCase()
  }
  let valueB
  if (Number.isInteger(b[orderBy])) {
    valueB = b[orderBy]
  } else {
    valueB = b[orderBy]?.toLowerCase()
  }
  if (valueB < valueA) {
    return -1
  }
  if (valueB > valueA) {
    return 1
  }
  return 0
}

export const locationParams = (search) => {
  var searchParams = new URLSearchParams(search)
  let ob = {}
  searchParams.forEach(function(value, key) {
    ob[key] = value
  })
  return ob
}

export function getComparator(order, orderBy) {
  console.log(order, orderBy, 'by')
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
export function stableSort(array, comparator) {
  console.log('comparator', comparator)
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  console.log(stabilizedThis.map((el) => el[0]))
  return stabilizedThis.map((el) => el[0])
}
const desc = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
const asc = (a, b, orderBy) => {
  if (b[orderBy] > a[orderBy]) {
    return -1
  }
  if (b[orderBy] < a[orderBy]) {
    return 1
  }
  return 0
}
export const getSorting = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => -asc(a, b, orderBy)
    : (a, b) => asc(a, b, orderBy)
}
export const stableSortone = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}
