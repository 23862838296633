import { createTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createTheme({
  btnPrimary: '#1E88E5',
  outShadow: '0 1px 5px 0 #222222',
  primaryColor: '#1B2430',
  secondaryColor: '#232F3E',
  primaryButton: '#1565C0',
  newsignButton: '#63f663',
  secondaryButton: '#FF4081',
  successColor: '#58A83A',
  errorIcon: '#D32F2F',
  avasty: ['#D32F2F', '#58A83A'],
})

export default theme
