import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { DASHBOARD_PATH, TASK_VIEW_PATH } from './constants/RoutePaths'
import {
  GET_USER_BY_NAME_EMAIL,
  LOGIN_URL,
  REFRESH_TOKEN_API,
} from './constants/ApiRoutes'
import { API } from './API'
import CustomLoader from '../components/common/CustomLoader'
import { useState } from 'react'
import { useEffect } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: '1rem',
}

export default function TimeOutPopup(props) {
  const { openfl } = props
  console.log('openfl', openfl)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  console.log(!!open, 'openopen')
  const handleRefreshLogin = async () => {
    const userID = localStorage.getItem('LOG_USER_ID')
    const config = {
      method: 'GET',
      url: REFRESH_TOKEN_API,
      params: {
        UserID: userID,
      },
    }
    try {
      const response = await API(config)
      const { status, token } = response.data
      console.log('status', status)
      if (status == 'Success') {
        localStorage.setItem('AUTH_TOKEN', token)
        setOpen(!open)
        if (token) {
          let apitime = new Date(localStorage.getItem('API_TIME')) || new Date()
          var endDate = new Date()
          var seconds =
            (endDate.getTime() - (apitime?.getTime() || new Date())) / 1000
          console.log(seconds, 'seconds')
          if (60 * 60 * 1000 < seconds) {
            console.log('doc', document.referrer)
            localStorage.setItem('WARNING_MSG', true)
          }
          setTimeout(() => {
            window.location.reload(true)
          }, 200)
          localStorage.setItem('WARNING_MSG', '')
        }
      }
    } catch (error) {
    } finally {
      localStorage.removeItem('WARNING_MSG')
    }
  }
  const handleClose = () => {
    setOpen(!open)
    localStorage.clear()
    sessionStorage.setItem('PREVIOUS_PATH', '')

    if (!window.location.href.includes('signin')) {
      window.location.replace('/signin')
    }
  }

  useEffect(() => {
    // setTimeout(() => {
    //     handleClose()
    // }, 30000);
    setOpen(localStorage.getItem('WARNING_MSG'))
  }, [])

  return (
    <div>
      {loading && <CustomLoader loading={loading ? true : false} />}

      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      {open || openfl ? (
        <Modal
          open={open || openfl}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: 'center' }}>
              Session expired
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Your session is about to expire. Would you like to extend your
              session?
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                padding: '3rem',
              }}>
              <Button
                style={{
                  color: '#fff',
                  background: '#1565C0',
                  marginRight: '2rem',
                }}
                onClick={handleRefreshLogin}>
                YES
              </Button>

              <Button
                style={{
                  color: '#fff',
                  background: 'orange',
                  border: '2px solid orange',
                }}
                onClick={handleClose}>
                NO
              </Button>
            </div>
          </Box>
        </Modal>
      ) : (
        ''
      )}
    </div>
  )
}
