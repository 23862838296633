import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  TextField,
} from '@material-ui/core'
import * as Yup from 'yup'

import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CustomAddCategory from './CustomAddCategory'
import CustomAddDescription from './CustomAddDescription'
import CustomAddSubCategoryDescription from './CustomAddSubCategoryDescription'
import EditCustomCategory from './EditCustomCategory'
import EditCustomSubCategory from './EditCustomSubCategory'
import CustomItemModal from './CustomItemModal'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import {
  ADD_ITEM_URL,
  ADD_SUB_CATEGORY_URL,
  DELETE_CUSTOME_ITEM,
  DELETE_CUSTOM_CATEGORY_URL,
  DELETE_CUSTOM_SUB_CATEGORY,
  GET_CUSTOM_CATEGORY_URL,
  GET_CUSTOM_ITEMS_BY_CATEGORY,
  GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
  GET_CUSTOM_SUB_CATEGORY_URL,
  GET_ITEMS_BY_SUBCATEGORY_URL,
  GET_SUB_CATEGORY,
  GET_SUB_CATEGORY_BYID,
  GET_SUB_CATEGORY_ITEM,
  UPDATE_ITEM_URL,
  UPDATE_SUB_CATEGORY,
} from '../../../../../helpers/constants/ApiRoutes'
import { API } from '../../../../../helpers/API'
import { useDispatch, useSelector } from 'react-redux'
import {
  ADD_ALL_CUSTOM_CATEGORY,
  CHECKED_ITEM_LIST,
  DELETE_CUSTOM_CATEGORY,
  SAVE_CUSTOM_CATEGORY,
  SAVE_CUSTOM_CATEGORY_ITEMS,
  SAVE_CUSTOM_CATEGORY_LIST,
  SAVE_CUSTOM_SUB_CATEGORY_ITEMS,
  SAVE_CUSTOM_SUB_CATEGORY_LIST,
  SAVE_SELECTED_ITEMS,
  SELECT_CUSTOM_CATEGORY,
  UNCHECK_ALL_CUSTOM_CATEGORY,
  UNCHECK_ALL_THINKWISE_ITEM,
  SELECTED_SUB_ITEMS,
  NEW_SELECT_CUSTOM_ITEMS,
  SELECT_CATEGORYITEM,
  NEW_CUSTOM_CATEGORY_ITEMS,
  NEW_ALL_CUSTOM_CATEGORY_ITEMS,
  NEW_SELCT_SUB_ITEMS,
  NEW_CUSTOM_ITEM_ALL,
  NEW_CUSTOM_DELETE_ITEMS,
  TOTAL_SELECTED_COUSTOM_ITEM,
} from '../../../../../redux/action/ActionType'
import {
  addCustomCategory,
  addCustomSubCategory,
  deleteCustomCategory,
  deleteCustomSubCategory,
  getAllcustomItem,
  getAllOrgCustomItems,
  getAllsaveItems,
  getCustomCategory,
} from '../../../../../redux/action'
import { unstable_batchedUpdates } from 'react-dom'
import { authHeader, getOrganizationId, getUserId } from '../../../../common/CustomFunction'
import { useFormik } from 'formik'
import CustomButton from '../../../../common/CustomButton'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
import {
  CUSTOM_ITEM_,
  SUB_CUSTOM_ITEM_,
} from '../../../../../redux/action/Typeconstent'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  accDetail: {
    display: 'block',
  },
  accHead: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    height: '64px',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
  },

  accHeadSelected: {
    backgroundColor: '#64B5F6',
    color: '#000',
    height: '64px',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
    '& .MuiSvgIcon-root': {
      color: '#000',
    },
  },

  //   accHeadSelected :{
  //     '&.MuiAccordionSummary-content' :{
  // justifyContent : "space-between",
  // alignItems: "center"
  //     }

  //   },

  acc: {
    marginBottom: '1rem',
  },
  accDesc: {
    fontSize: '14px',
    marginBottom: '1rem',
  },
  accHeadInside: {
    backgroundColor: '#eee',
    height: '64px',
    '& .MuiAccordionSummary-content': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
  },
  subHead: {
    backgroundColor: '#eee',
    height: '64px',
  },
  toggleDescBtn: {
    backgroundColor: theme.primaryButton,
    color: '#fff',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnSelected: {
    backgroundColor: '#64B5F6',
    color: '#000',
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescBtnInside: {
    textTransform: 'none',
    fontSize: '10px',
    marginRight: '10px',
  },
  toggleDescIcon: {
    fontSize: '15px !important',
  },

  listItemfont: {
    fontFamily: 'Nunito-Regular !important',
  },

  listItemStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#1565C0',
      color: '#fff',
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#1565C0',
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#fff',
      },
      '& $iconStyle': {
        color: '#fff',
      },
    },
  },
  listItemSelectedStyle: {
    fontFamily: 'Nunito-Regular !important',
    '&.Mui-selected': {
      backgroundColor: '#64B5F6',
      fontFamily: 'Nunito-Regular !important',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#64B5F6',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
    '&:hover': {
      backgroundColor: '#eee',
      '& $listTextStyle': {
        color: '#000',
      },
      '& $iconStyle': {
        color: '#000',
      },
    },
  },
  addCatBtn: {
    textTransform: 'none',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  icoBtn: {
    marginRight: '10px',
    color: '#fff',
  },
  icoBtnInside: {
    marginRight: '10px',
  },
  accordiandetailsBtn: {
    '& .MuiBox-root': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}))
function NewcustomItems(props) {
  const { zLevelID } = props
  console.log(props)
  const [selectedCustomItem, setselectedCustomItem] = useState([])
  const [updateFlag, setUpdateFlag] = useState(false)
  const [openModal, setopenModal] = useState(false)
  const NewSelectedItemsIdReducer = useSelector(
    (state) => state.NewSelectedItemsIdReducer
  )

  let allSelectedItem = []

  Object.values(NewSelectedItemsIdReducer).forEach((value) => {
    if (value.length) {
      allSelectedItem.push(...value)
    } else {
      Object.values(value).forEach((value) => {
        allSelectedItem.push(...value)
      })
    }
  })
  const NewCustomItemsReducer = useSelector(
    (state) => state.NewCustomItemsReducer
  )
  const NewOrgSummary = useSelector((state) => state.NewOrgSummary)

  const ListOfData = [...NewOrgSummary, ...NewCustomItemsReducer]
  let allCustom = ListOfData?.map((v) => v.capablityid)
  const NewSelectedCustomitemReducer = useSelector(
    (state) => state.NewSelectedCustomitemReducer
  )
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleListItemClick = (e) => {
    const { id } = e.currentTarget.dataset
    let cloneSlectedId = [...NewSelectedCustomitemReducer]
    if (cloneSlectedId.includes(id)) {
      dispatch({ type: NEW_CUSTOM_DELETE_ITEMS, payload: { categoryid: id } })

      dispatch({
        type: NEW_SELECT_CUSTOM_ITEMS,
        payload: NewSelectedCustomitemReducer.filter((valu) => valu != id),
      })
      dispatch({
        type: TOTAL_SELECTED_COUSTOM_ITEM,
        payload: allSelectedItem,
      })
    } else {
      dispatch({
        type: NEW_SELECT_CUSTOM_ITEMS,
        payload: [...NewSelectedCustomitemReducer, id],
      })
      dispatch({
        type: TOTAL_SELECTED_COUSTOM_ITEM,
        payload: allSelectedItem,
      })
    }
  }

  const successCall = () => {
    setopenModal(false)
  }
  const addCategory = () => {
    setUpdateFlag(false)
    setopenModal(true)
  }

  const selectAllItems = (e) => {
    const { checked } = e.target

    if (checked) {
      dispatch({
        type: NEW_SELECT_CUSTOM_ITEMS,
        payload: allCustom,
      })
    } else {
      allCustom.forEach((id) =>
        dispatch({
          type: NEW_CUSTOM_DELETE_ITEMS,
          payload: { categoryid: id },
        })
      )

      dispatch({
        type: NEW_SELECT_CUSTOM_ITEMS,
        payload: [],
      })
    }
  }
  useEffect(() => {
    dispatch(
      {
        type: TOTAL_SELECTED_COUSTOM_ITEM,
        payload: allSelectedItem,
      },
      []
    )
  })

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="center"
      style={{ marginTop: '2px' }}>
      <Grid item xs={3}>
        <Button
          className={classes.addCatBtn}
          startIcon={<AddIcon />}
          onClick={addCategory}>
          Add Category
        </Button>
        <List
          component="nav"
          aria-label="secondary mailbox folder"
          className={classes.listItemfont}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="all"
                checked={
                  allCustom.length == NewSelectedCustomitemReducer.length
                }
                onClick={selectAllItems}
              />
            }
            label="Select All"
          />
          {ListOfData?.map((category, index) => {
            let selected =
              NewSelectedItemsIdReducer[CUSTOM_ITEM_ + category.capablityid] ||
              []
            return (
              <ListItem
                key={`list_item_${index}`}
                button
                selected={NewSelectedCustomitemReducer.includes(
                  category.capablityid
                )}
                data-id={category.capablityid}
                onClick={handleListItemClick}
                className={`${
                  selected.length || Object.keys(selected).length
                    ? classes.listItemSelectedStyle
                    : classes.listItemStyle
                }`}>
                <ListItemText primary={category.capablityname} />
              </ListItem>
            )
          })}
        </List>
      </Grid>
      <Grid item xs={9}>
        <p>
          Custom Items Selected : <b>{allSelectedItem?.length}</b>
        </p>
        {ListOfData?.map((category, index) => {
          if (NewSelectedCustomitemReducer.includes(category?.capablityid)) {
            return (
              <AccordionContainer
                category={category}
                classes={classes}
                // key={`item-${index}`}
                // value={index}x
                zLevelID={zLevelID}

                // accordianId={category.zCompetencyID}
                // handleItemModal={handleItemModal}
                // selectedIndex={selectedIndex}
                // selectedCategory={selectedCategory}
                // handleItem={(e) => handleItem(e, category.accordian)}
              />
            )
          }
        })}
      </Grid>

      <EditCustomCategory
        open={openModal}
        zLevelID={zLevelID}
        updateFlag={false}
        handleReset={successCall}
      />
    </Grid>
  )
}

export default NewcustomItems

const AccordionContainer = ({
  category,
  subcategory,
  CategoryItems,
  zLevelID,
}) => {
  const classes = useStyles()
  const [openModal, setopenModal] = useState(false)
  const [isDescModal, setisDescModal] = useState(false)
  const [openItem, setopenItem] = useState(false)
  const [updateItem, setupdateItem] = useState({})
  const [subCategoryOpen, setsubCategoryOpen] = useState(false)
  const [updateFlag, setupdateFlag] = useState(false)

  const {
    zDescription,
    capablityname,
    compentencylist,
    capablityid,
    itemList,
    selectedItemsList,
  } = category
  const NewSelectedItemsIdReducer = useSelector(
    (state) => state.NewSelectedItemsIdReducer
  )

  const NewOrgSummary = useSelector((state) => state.NewOrgSummary)
  let selected = NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid] || []
  console.log(selected)
  const dispatch = useDispatch()
  const { id: projectId } = useParams()

  const EditCustomitem = () => {
    setopenModal(true)
  }
  const successCall = () => {
    setopenModal(false)
  }
  const addDescription = () => {
    setisDescModal(true)
  }
  const descriptionSuccess = () => {
    setisDescModal(false)
  }
  const subCategoryAdd = () => {
    setsubCategoryOpen(true)
  }

  const subCategorySuccess = () => {
    setsubCategoryOpen(false)
  }

  const deleteAlertS = (e) => {
    const { capablityid } = e.currentTarget.dataset
    Swal.fire({
      title: 'Are you want to delete Item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory(capablityid)
      }
    })
  }
  function deleteCategory(id) {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_CUSTOM_CATEGORY_URL,
      params: {
        CategoryID: id,
      },
    }

    API(deleteConfig).then((response) => {
      if (response.status === 200) {
        dispatch(getAllcustomItem(projectId))
        dispatch(getAllOrgCustomItems(projectId))
      }
    })
  }

  const addItemforcategory = (e) => {
    setupdateFlag(false)
    setopenItem(true)
  }
  const subitemClose = () => {
    setopenItem(false)
  }

  const updateCategoryItem = (e) => {
    const { itemid, itemname } = e.currentTarget.dataset
    console.log(itemid, itemname)
    setupdateFlag(true)
    setopenItem(true)
    setupdateItem({ caption: itemname, id: itemid })
  }
  const deleteItem = async (id) => {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),

      url: DELETE_CUSTOME_ITEM,
      params: {
        ItemID: id,
      },
    }
    try {
      const response = await API(deleteConfig)
      dispatch(getAllcustomItem(projectId))
      dispatch(getAllOrgCustomItems(projectId))
    } catch (er) {
      console.error(er)
    }
  }
  const deleteAlert = (e) => {
    const { id } = e.currentTarget.dataset

    Swal.fire({
      title: 'Are you want to delete Item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(id)
      }
    })
  }

  let allId = itemList?.map((value) => value.itemId)
  const selecteItems = (e) => {
    const { itemid, categoryid } = e.currentTarget.dataset
    const { name, checked } = e.target
    if (name == 'all') {
      console.log(checked, name)
      if (checked) {
        dispatch({
          type: NEW_ALL_CUSTOM_CATEGORY_ITEMS,
          payload: { categoryid: categoryid, allId },
        })
      } else {
        dispatch({
          type: NEW_ALL_CUSTOM_CATEGORY_ITEMS,
          payload: { categoryid: categoryid, allId: [] },
        })
      }
    } else {
      dispatch({
        type: NEW_CUSTOM_CATEGORY_ITEMS,
        payload: { itemid, categoryid },
      })
    }
  }
  return (
    <>
      <Accordion
        key={`acc_${'value'}`}
        className={classes.acc}
        // style={{
        //   display: selectedIndex.includes(`list_${value}`) ? '' : 'none',
        // }}
      >
        <AccordionSummary
          className={`${
            selected.length || Object.keys(selected).length
              ? classes.accHeadSelected
              : classes.accHead
          }`}
          // expandIcon={
          //   <ExpandMoreIcon
          //     style={{
          //       color: `${
          //         checkedCategory.includes(zCompetencyID) ? '#000' : '#fff'
          //       }`,
          //     }}
          //   />
          // }
        >
          <Typography className={classes.heading}>{capablityname}</Typography>

          <Box>
            {!zDescription && (
              <Button
                className={`${
                  selected.length || Object.keys(selected).length
                    ? classes.toggleDescBtnSelected
                    : classes.toggleDescBtn
                }`}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                onClick={(e) => {
                  e.stopPropagation()
                  addDescription()
                }}>
                Add Description
              </Button>
            )}
            {!(itemList?.length > 0) && (
              <Button
                className={`${
                  selected.length || Object.keys(selected).length
                    ? classes.toggleDescBtnSelected
                    : classes.toggleDescBtn
                }`}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                data-title="Add Sub Category"
                onClick={(e) => {
                  e.stopPropagation()
                  subCategoryAdd()
                }}
                onFocus={(e) => e.stopPropagation()}>
                Add Subcategory
              </Button>
            )}
            {!(compentencylist?.length > 0) && (
              <Button
                className={`${
                  selected.length || Object.keys(selected).length
                    ? classes.toggleDescBtnSelected
                    : classes.toggleDescBtn
                }`}
                data-capablityid={capablityid}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                onClick={(e) => {
                  e.stopPropagation()
                  addItemforcategory(e)
                }}
                // onFocus={(e) => e.stopPropagation()}
              >
                Add Item
              </Button>
            )}

            <IconButton
              aria-label="edit-category"
              className={classes.icoBtn}
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                EditCustomitem()
              }}
              onFocus={(e) => e.stopPropagation()}>
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.icoBtn}
              size="small"
              data-capablityid={capablityid}
              onClick={(e) => {
                e.stopPropagation()
                deleteAlertS(e)
              }}
              // onFocus={(e) => e.stopPropagation()}
            >
              <DeleteIcon fontSize="inherit" />
            </IconButton>

            <IconButton>
              <ExpandMoreIcon
                style={{
                  color: `${[].includes(capablityid) ? '#000' : '#fff'}`,
                }}
              />
            </IconButton>
          </Box>
        </AccordionSummary>

        {compentencylist.length ? (
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>{zDescription}</Typography>
            {compentencylist.map((subCat) => {
              return (
                <SubcategoryAccodian
                  subCat={subCat}
                  classes={classes}
                  capablityid={capablityid}
                  zLevelID={zLevelID}
                  //   successCallsubcategory={successCall}
                />
              )
            })}
          </AccordionDetails>
        ) : (
          <AccordionDetails className={classes.accDetail}>
            <Typography className={classes.accDesc}>{zDescription}</Typography>

            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup>
                {!!itemList?.length && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="all"
                        data-categoryid={capablityid}
                        checked={allId.length == selected?.length}
                        onClick={selecteItems}
                      />
                    }
                    label="All"
                  />
                )}
                {itemList?.map((categoryItem) => {
                  return (
                    <Grid container spacing={12}>
                      <Grid item xs={10}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={selected.includes(categoryItem.itemId)}
                              data-categoryid={capablityid}
                              data-itemid={categoryItem.itemId}
                              data-itemname={categoryItem.itemname}
                              onClick={selecteItems}
                            />
                          }
                          label={categoryItem.itemname}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.accordiandetailsBtn}>
                        <Box>
                          <IconButton
                            aria-label="edit"
                            size="small"
                            data-itemId={categoryItem.itemId}
                            data-itemname={categoryItem.itemname}
                            onClick={updateCategoryItem}>
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            data-id={categoryItem.itemId}
                            onClick={deleteAlert}
                            // onClick={removeCustomSeleteditem}
                            style={{ marginLeft: '1rem' }}>
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  )
                })}
              </FormGroup>
            </FormControl>

            {/* {[1, 2].map((sub, index) => {
              if (1 == 1) {
                return (
                  <InnerAccordianItem
                    key={`item-${index}`}
                    index={index}
                    value={index}
                    competencyId={1}
                    subCategory={sub}
                    zLevelID={zLevelID}
                    classes={classes}
                    // expandedInside={expandedInside}
                    // handleSubDescModal={handleSubDescModal}
                    // handleEditSubCategoryModal={handleEditSubCategoryModal}
                    // handleItem={handleItem}
                  />
                )
              }
            })} */}
          </AccordionDetails>
        )}
      </Accordion>
      <EditCustomCategory
        open={openModal}
        zLevelID={zLevelID}
        updateFlag={true}
        handleReset={successCall}
        value={{
          capablityid: capablityid,
          categoryName: capablityname,
          categoryDescription: zDescription,
        }}
      />
      <CustomAddDescription
        open={isDescModal}
        category={category}
        handleReset={descriptionSuccess}
      />
      <SubCategoryModal
        open={subCategoryOpen}
        //   handleClose={subCategoryClose}
        itemState={{}}
        classes={classes}
        category={category}
        successCall={subCategorySuccess}
      />
      <SubCategoryItemModal
        open={openItem}
        itemState={{
          compentencyid: 0,
          zLevelID,
          capablityid,
          ...updateItem,
        }}
        classes={classes}
        updateflage={updateFlag}
        handleClose={subitemClose}
        // successCall={successCall}
      />
      {/*  <CustomItemModal
        open={isItemModal}
        title={modalTitle}
        itemData={itemData}
        zLevelID={zLevelID}
        categoryId={zCompetencyID}
        handleReset={() => setItemModal(false)}
      />
      <CustomAddDescription
        open={isDescModal}
        category={category}
        handleReset={() => {
          getCustomCategoryList()
          setDescModal(false)
        }}
      /> */}
    </>
  )
}
const itamSchema = Yup.object({
  caption: Yup.string().required('Item name is required'),
})
const SubCategoryModal = (props) => {
  const {
    open,
    handleClose,
    itemState,
    category,
    classes,
    successCall,
    updateFlag,
  } = props
  const dispatch = useDispatch()
  const { id: projectId } = useParams()

  const addSubcategory = async (values) => {
    console.log(category)
    const config = {
      method: 'POST',
      headers: authHeader(),

      url: ADD_SUB_CATEGORY_URL,
      data: {
        competencyID: category.capablityid,
        // userID: getUserId(),
        organizationID: getOrganizationId(),

        name: values.caption,
      },
    }

    try {
      await API(config)
      successCall()
      dispatch(getAllcustomItem(projectId))
      dispatch(getAllOrgCustomItems(projectId))
      setValues((val) => ({ ...val, caption: '' }))
    } catch (er) {
      console.error(er)
    }
  }
  const updateSubcategory = async (data) => {
    const config = {
      method: 'PUT',
      headers: authHeader(),

      url: UPDATE_SUB_CATEGORY,
      data: {
        subCategoryID: data.subCategoryID,
        name: data.caption,
      },
    }
    try {
      await API(config)
      successCall()
      dispatch(getAllcustomItem(projectId))
      dispatch(getAllOrgCustomItems(projectId))
    } catch (er) {
      console.error(er)
    }
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: { ...itemState },
    enableReinitialize: true,
    validationSchema: itamSchema,
    onSubmit: (values) =>
      updateFlag ? updateSubcategory(values) : addSubcategory(values),
  })

  return (
    <Dialog
      open={open}
      onClose={successCall}
      aria-labelledby="alert-dialog-title"
      fullWidth="500"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {updateFlag ? 'Update sub category' : 'Add Sub Category'}{' '}
      </DialogTitle>
      <DialogContent dividers>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={10}>
              <TextField
                id="designation-helper-text"
                label="Sub Category Name"
                value={values.caption}
                onChange={handleChange}
                fullWidth
                name="caption"
                error={errors.caption && touched.caption}
                helperText={
                  errors.caption && touched.caption ? errors.caption : ''
                }
              />
            </Grid>
            <Grid item xs={2}>
              <CustomButton
                colour="primaryButton"
                type="submit"
                onClick={handleSubmit}
                style={{ marginTop: '1.5rem' }}>
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
const InnerAccordianItem = ({
  subCategory,
  classes,
  value,
  competencyId,
  handleSubDescModal,
  zLevelID,
  // handleEditSubCategoryModal,
  // handleItem,
}) => {
  const { zCapabilityID, zDescription, zName } = subCategory
  const dispatch = useDispatch()
  const SubCategoryItems = useSelector((state) => state.CustomSubCategoryItems)
  const [isEditSubCategory, setEditSubCategory] = useState(false)
  const [isItemModal, setItemModal] = useState(false)
  const [itemModalTitle, setItemModalTitle] = useState('')
  const [itemData, setItemData] = useState('')
  const [isDescModal, setDescModal] = useState(false)
  const subCategoryItems =
    SubCategoryItems['SUB_CATEGORY_ITEMS_' + zCapabilityID]

  const deleteItem = (itemId) => {}

  const getSubCategoryItems = async (subCategoryId) => {
    const getConfig = {
      method: 'GET',
      headers: authHeader(),
      url: GET_CUSTOM_ITEMS_BY_SUB_CATEGORY,
      params: {
        SubCategoryId: subCategoryId,
        LevelID: 3,
      },
    }
    const response = await API(getConfig)
    if (response.status == 200) {
      const { data } = response.data
      let currentItems = { ...SubCategoryItems }
      currentItems['SUB_CATEGORY_ITEMS_' + subCategoryId] = data

      dispatch({ type: SAVE_CUSTOM_SUB_CATEGORY_ITEMS, payload: currentItems })
    }
  }

  const CheckedItemList = useSelector((state) => state.CheckedItemList)

  const handleItem = (e, item) => {
    if (e.target.checked) {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        const temp2 = subCategoryItems

        temp2.forEach((item) => {
          if (!temp.find((val) => val.itemId === item.zItemID)) {
            temp.push({
              itemId: item.zItemID,
              categoryId: 0,
              subCategoryId: zCapabilityID,
            })
          }
        })
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: zCapabilityID,
          categoryId: 0,
        }
        temp.push(arr)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    } else {
      if (e.target.name === 'all') {
        let temp = [...CheckedItemList]
        temp = temp.filter(
          (checkedItem) => checkedItem.subCategoryId !== zCapabilityID
        )
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      } else {
        let temp = [...CheckedItemList]
        const arr = {
          itemId: item.zItemID,
          subCategoryId: zCapabilityID,
          categoryId: 0,
        }
        temp = temp.filter((val) => val.itemId != arr.itemId)
        dispatch({ type: CHECKED_ITEM_LIST, payload: temp })
      }
    }
  }

  const unCheckSeletedItem = () => {}

  return (
    <>
      <Accordion
        key={`acc_inside_${value}`}
        style={{ margin: '0 -14px 0.5rem -15px' }}>
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls={`item_inside_${value}-content`}
          id={`item_inside_${value}-header`}
          className={classes.accHeadInside}>
          <Typography className={classes.heading}>{zName}</Typography>
          <Box>
            {!zDescription ? (
              <Button
                className={classes.toggleDescBtnInside}
                startIcon={<AddIcon className={classes.toggleDescIcon} />}
                onClick={(e) => {
                  e.stopPropagation()
                  setDescModal(true)
                }}>
                Add Description
              </Button>
            ) : (
              ''
            )}
            <Button
              className={classes.toggleDescBtnInside}
              startIcon={<AddIcon className={classes.toggleDescIcon} />}
              onClick={(e) => {
                e.stopPropagation()
                setItemData('')
                setItemModalTitle('Add Item')
                setItemModal(true)
              }}>
              Add Item
            </Button>
            <IconButton
              aria-label="edit-subCategory"
              className={classes.icoBtnInside}
              size="small"
              data-title="Edit Sub Category"
              onClick={(e) => {
                e.stopPropagation()
                setEditSubCategory(true)
              }}>
              <EditIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.icoBtnInside}
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                dispatch(deleteCustomSubCategory(subCategory.zCapabilityID))
              }}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            <IconButton>
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <Typography className={classes.accDesc}>{zDescription}</Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {subCategoryItems && subCategoryItems.length && (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="all"
                      checked={
                        CheckedItemList
                          ? CheckedItemList.filter(
                              (checkedItem) =>
                                checkedItem.subCategoryId === zCapabilityID
                            ).length === subCategoryItems.length
                            ? true
                            : false
                          : false
                      }
                      onClick={(e) => handleItem(e)}
                    />
                  }
                  label="All"
                />
              )}
              {subCategoryItems
                ? subCategoryItems.map((subCategoryItem) => {
                    return (
                      <Grid container spacing={1}>
                        <Grid item xs={9}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                name={subCategoryItem.zItemID}
                                checked={
                                  CheckedItemList
                                    ? CheckedItemList.find(
                                        (checkedItem) =>
                                          checkedItem.itemId ===
                                          subCategoryItem.zItemID
                                      )
                                      ? true
                                      : false
                                    : false
                                }
                                onClick={(e) => handleItem(e, subCategoryItem)}
                              />
                            }
                            label={subCategoryItem.zCaption}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation()
                              setItemData(subCategoryItem)
                              setItemModalTitle('Edit Item')
                              setItemModal(true)
                            }}>
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              deleteItem(subCategoryItem.zItemID)

                              // if(response) {
                              //   getSubCategoryItems(subCategory.zCapabilityID)
                              // }
                            }}
                            style={{ marginLeft: '1rem' }}>
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  })
                : ''}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <CustomItemModal
        open={isItemModal}
        title={itemModalTitle}
        itemData={itemData}
        zLevelID={zLevelID}
        subCategoryId={zCapabilityID}
        handleReset={() => setItemModal(false)}
      />

      <EditCustomSubCategory
        open={isEditSubCategory}
        handleReset={() => setEditSubCategory(false)}
        title={'Edit SubCategory'}
        capabilityId={zCapabilityID}
        description={zDescription}
        name={zName}
      />

      <CustomAddSubCategoryDescription
        open={isDescModal}
        subCategory={subCategory}
        handleReset={() => setDescModal(false)}
      />
    </>
  )
}

const SubcategoryAccodian = (props) => {
  const {
    classes,
    subCat,
    zLevelID,
    successCallsubcategory,
    capablityid,
  } = props
  const { id: projectId } = useParams()

  const [opensubcategory, setopensubcategory] = useState(false)
  const [openSubItem, setopenSubItem] = useState(false)
  const [updateflage, setupdateflage] = useState(false)
  const [updateItem, setupdateItem] = useState({})
  const NewSelectedItemsIdReducer = useSelector(
    (state) => state.NewSelectedItemsIdReducer
  )
  console.log(NewSelectedItemsIdReducer, 'NewSelectedItemsIdReducer')

  let setsubCategoryItems =
    NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid]
  console.log(setsubCategoryItems, 'setsubCategoryItems')

  let ItemInitialValue = {
    caption: '',
    subCategoryId: subCat.zCapabilityID,
    UserId: getUserId(),
    itemLevelID: zLevelID,
  }
  const dispatch = useDispatch()

  const updateSubcategory = () => {
    setopensubcategory(true)
  }
  const updateSuccessCall = () => {
    setopensubcategory(false)
  }

  const addItem = () => {
    setopenSubItem(true)
    setupdateflage(false)
  }
  const subitemClose = () => {
    setopenSubItem(false)
    setupdateItem({})
  }
  const deleteItem = async (id) => {
    const deleteConfig = {
      method: 'DELETE',
      headers: authHeader(),
      url: DELETE_CUSTOME_ITEM,
      params: {
        ItemID: id,
      },
    }
    try {
      const response = await API(deleteConfig)
      dispatch(getAllcustomItem(projectId))
      dispatch(getAllOrgCustomItems(projectId))
    } catch (er) {
      console.error(er)
    }
  }
  const deleteAlert = (e) => {
    const { id } = e.currentTarget.dataset
    Swal.fire({
      title: 'Are you want to delete Item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteItem(id)
      }
    })
  }
  const editItem = (e) => {
    const { name, id } = e.currentTarget.dataset
    setupdateItem({ caption: name, id })
    setopenSubItem(true)
    setupdateflage(true)
  }
  let allId = subCat.Itemlist.map((iem) => iem.itemId)
  let selectedItem =
    NewSelectedItemsIdReducer[CUSTOM_ITEM_ + capablityid]?.[
      SUB_CUSTOM_ITEM_ + capablityid + '_' + subCat.compentencyid
    ] || []
  const selectSubcatItem = (e) => {
    const {
      capablityid: categoryid,
      itemid,
      subcategoryid: subcategory,
    } = e.currentTarget.dataset

    const { name, checked } = e.target
    if (name == 'all') {
      if (checked) {
        dispatch({
          type: NEW_CUSTOM_ITEM_ALL,
          payload: { categoryid, allId, subcategory },
        })
      } else {
        dispatch({
          type: NEW_CUSTOM_ITEM_ALL,
          payload: { categoryid, allId: [], subcategory },
        })
      }
    } else {
      dispatch({
        type: NEW_SELCT_SUB_ITEMS,
        payload: { categoryid, itemid, subcategory },
      })
    }
  }

  return (
    <>
      <Accordion
        key={`acc_inside_${''}`}
        style={{ margin: '0 -14px 0.5rem -15px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`item_inside_${'value'}-content`}
          id={`item_inside_${'value'}-header`}
          className={classes.subHead}>
          <Typography className={classes.heading}>
            {' '}
            {subCat.compentencyname}
          </Typography>
          <Box>
            {' '}
            <Button
              className={classes.toggleDescBtnInside}
              onClick={(e) => {
                e.stopPropagation()

                addItem()
              }}
              startIcon={<AddIcon className={classes.toggleDescIcon} />}>
              Add Item
            </Button>
            <Button
              className={classes.toggleDescBtnInside}
              data-name={subCat.zName}
              data-id={subCat.zCapabilityID}
              onClick={(e) => {
                e.stopPropagation()
                updateSubcategory(e)
              }}
              startIcon={<EditIcon className={classes.toggleDescIcon} />}>
              Update sub category
            </Button>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.accDetail}>
          <Typography className={classes.accDesc}>
            {subCat.zDescription}
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            {/* <List
              component="nav"
              aria-label="secondary mailbox folder"
              className={classes.listItemfont}> */}
            {!!subCat.Itemlist.length && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="all"
                      checked={allId.length == selectedItem?.length}
                      data-capablityid={capablityid}
                      data-subcategoryid={subCat.compentencyid}
                      onClick={selectSubcatItem}
                    />
                  }
                  label="All"
                />
              </FormGroup>
            )}

            {subCat.Itemlist.map((item, index) => {
              return (
                <>
                  {' '}
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            data-itemid={item.itemId}
                            data-capablityid={capablityid}
                            data-subcategoryid={subCat.compentencyid}
                            onClick={selectSubcatItem}
                            checked={selectedItem?.includes(item.itemId)}
                            // checked={marked}
                            // onChange={(e) => checkSubitem(e, subCat, item)}
                          />
                        }
                        label={item.itemname}
                      />
                    </Grid>

                    <Grid item xs={2}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-name={item.itemname}
                        data-id={item.itemId}
                        onClick={editItem}>
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        data-id={item.itemId}
                        onClick={deleteAlert}
                        style={{ marginLeft: '1rem' }}>
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </>
              )
            })}
            {/* </List> */}
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <SubCategoryModal
        open={opensubcategory}
        handleClose={{}}
        itemState={{
          subCategoryID: subCat.compentencyid,
          caption: subCat.compentencyname,
        }}
        successCall={updateSuccessCall}
        classes={classes}
        updateFlag={true}
      />

      <SubCategoryItemModal
        open={openSubItem}
        itemState={{
          compentencyid: subCat.compentencyid,
          zLevelID,
          capablityid,
          ...updateItem,
        }}
        classes={classes}
        updateflage={updateflage}
        handleClose={subitemClose}
        // successCall={successCall}
      />
    </>
  )
}

const subCategorySchema = Yup.object({
  caption: Yup.string().required('Category name is required'),
})
const SubCategoryItemModal = (props) => {
  const { open, handleClose, itemState, classes, updateflage } = props
  const { id: projectId } = useParams()

  const dispatch = useDispatch()
  const AddItem = async (values) => {
    console.log(itemState)
    const config = {
      method: 'POST',
      url: ADD_ITEM_URL,
      headers: authHeader(),

      data: {
        // userID: getUserId(),
        organizationID: getOrganizationId(),

        caption: values.caption,
        itemLevelID: itemState.zLevelID,
        categoryId: itemState.capablityid,
        subCategoryId: itemState.compentencyid,
      },
    }

    try {
      await API(config)
      console.log('success')
      handleClose()
      dispatch(getAllcustomItem(projectId))
      dispatch(getAllOrgCustomItems(projectId))
      setValues((val) => ({ ...val, caption: '' }))
    } catch (er) {
      console.error(er)
    }
  }
  const updateItem = async (values) => {
    console.log(values)
    const config = {
      method: 'PUT',
      url: UPDATE_ITEM_URL,
      headers: authHeader(),
      data: {
        itemID: values.id,
        caption: values.caption,
      },
    }
    try {
      const response = await API(config)
      handleClose()
      setValues((val) => ({ ...val, caption: '' }))
      dispatch(getAllcustomItem(projectId))
      dispatch(getAllOrgCustomItems(projectId))
    } catch (er) {
      console.error(er)
    }
  }
  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    touched,
    setValues,
  } = useFormik({
    initialValues: { ...itemState },
    enableReinitialize: true,
    validationSchema: subCategorySchema,
    onSubmit: (values) => (updateflage ? updateItem(values) : AddItem(values)),
  })
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      className={classes.root}
      fullWidth="500"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {updateflage ? 'Update Item' : 'Add Item'}{' '}
      </DialogTitle>
      <DialogContent dividers>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ width: '100%', margin: 0 }}>
            <Grid item xs={10}>
              <TextField
                id="designation-helper-text"
                label="Item Name"
                value={values.caption}
                onChange={handleChange}
                fullWidth
                name="caption"
                error={errors.caption && touched.caption}
                helperText={
                  errors.caption && touched.caption ? errors.caption : ''
                }
              />
            </Grid>
            <Grid item xs={2}>
              <CustomButton
                colour="primaryButton"
                type="submit"
                onClick={handleSubmit}
                style={{ marginTop: '1.5rem' }}>
                Save
              </CustomButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  )
}
