import { sanitize } from 'dompurify'
import React, { useMemo } from 'react'
import serialize from 'serialize-javascript'
import { Helmet } from 'react-helmet'

function SampleHtml() {
  var newX =
    '<html><scr' +
    'ipt>function pClicked() {console.log("p is clicked");}</scr' +
    'ipt><body><p onClick="myThirdFunction()">Hello</p></body></html>'

  var extractscript = /<script>(.+)<\/script>/gi.exec(newX)
  newX = newX.replace(extractscript[0], '')
  useMemo(() => {
    window.eval(extractscript[1])
  }, [])

  const container = document.createElement('div')
  document.body.appendChild(container)
  container.innerHTML = '<script>console.log("test");</script>'
  console.log(container, 'container')
  return (
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <style
          dangerouslySetInnerHTML={{
            __html: sanitize(`

      body {
        font-family: Arial;
      }

      .tab {
        overflow: hidden;
        border: 1px solid #ccc;
        background-color: #f1f1f1;
      }

      .tab button {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 17px;
      }

      .tab button:hover {
        background-color: #ddd;
      }

      .tab button.active {
        background-color: #ccc;
      }

     
      .tabcontent {
        display: none;
        padding: 6px 12px;
        border: 1px solid #ccc;
        border-top: none;
      }

`),
          }}
        />
        {/* */}
      </head>
      <body>
        <button id="myBtn">Try it</button>

        <p id="demo"></p>

        <h2>Tabs</h2>
        <p>Click on the buttons inside the tabbed menu:</p>

        <div class="tab">
          <button class="tablinks" onclick="openCity(event, 'London')">
            London
          </button>
          <button class="tablinks" onclick="openCity(event, 'Paris')">
            Paris
          </button>
          <button class="tablinks" onclick="openCity(event, 'Tokyo')">
            Tokyo
          </button>
        </div>

        <div id="London" class="tabcontent">
          <h3>London</h3>
          <p>London is the capital city of England.</p>
        </div>

        <div id="Paris" class="tabcontent">
          <h3>Paris</h3>
          <p>Paris is the capital of France.</p>
        </div>

        <div id="Tokyo" class="tabcontent">
          <h3>Tokyo</h3>
          <p>Tokyo is the capital of Japan.</p>
        </div>

        <div dangerouslySetInnerHTML={{ __html: newX }} />
        {/* <script
          dangerouslySetInnerHTML={{
            __html: `window.__data=${serialize()};`,
          }}
          charSet="UTF-8"
        />
         */}

        <script
          dangerouslySetInnerHTML={{
            __html: `${sanitize(`
                      var x = document.getElementById("myBtn");
x.addEventListener("mouseover", myFunction);
x.addEventListener("click", mySecondFunction);
x.addEventListener("mouseout", myThirdFunction);

function myFunction() {

  document.getElementById("demo").innerHTML += "Moused over!<br>";
}

function mySecondFunction() {
  document.getElementById("demo").innerHTML += "Clicked!<br>";
}

function myThirdFunction() {
    console.log("da")
  document.getElementById("demo").innerHTML += "Moused out!<br>";
}
      function openCity(evt, cityName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(cityName).style.display = "block";
        evt.currentTarget.className += " active";
      }`)}`,
          }}
          charSet="UTF-8"
        />
      </body>
    </html>
  )
}

export default SampleHtml
